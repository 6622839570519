import {ContextMessage as Message} from '@epic-core/localization';
import PropTypes from 'prop-types';
import React from 'react';

export default class RetailBundleSection extends React.PureComponent {
  static propTypes = {
    data: PropTypes.object,
  };
  render() {
    const {title, description, images} = this.props.data;
    const imageArray = images || [];
    const bundleImages = imageArray.map((image, index) => {
      const key = `image-${index}`;
      if (index === 0) return null;
      return (
        <div key={key} className="col-xs-6">
          <img src={image} alt={title} className="img-responsive" />
        </div>
      );
    });
    return (
      <div id="bundleSection" className="retail-bundle-section text-center">
        <div className="container bundle">
          <div className="row">
            <div className="col-xs-12">
              <h1>{title}</h1>
              <div className="description" dangerouslySetInnerHTML={{__html: description}} />
            </div>
          </div>
          <div className="row">
            <div className="col-md-5">
              <div className="img-container">
                {imageArray.length > 0 ? <img src={images[0]} alt={title} /> : null}
              </div>
            </div>
            <div className="col-md-7 no-padding">{bundleImages}</div>
          </div>
          <div className="row">
            <div className="col-xs-12">
              <h2>
                <Message code="epic.fortnite.retailBundle.platforms.dontHaveCode" />
              </h2>
              <h3>
                <Message code="epic.fortnite.retailBundle.bundle.available" />
              </h3>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
