import styled from 'styled-components';

import {breakpoints} from '../../../../constants/Breakpoints';

const MaskDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  color: white;
  width: 100%;

  @media ${breakpoints.mobileL} {
    position: absolute;
    bottom: 15%;
  }
`;

export default MaskDetailsWrapper;
