import axios from 'axios';
import root from 'window-or-global';

import SearchActions from '../actions/SearchActions';
import Url from '../constants/Url';

/**
 * SearchSource
 *
 * Ajax calls for searches. Just applies to Bing right now.
 *
 * For more information on source usage:
 * http://alt.js.org/docs/async/
 *
 */

const SearchSource = {
  bingSearch: {
    remote(state) {
      const params = new URLSearchParams({
        keyword: state.query,
        lang: state.locale,
        offset: state.offset,
      });

      if (state.filter) {
        params.set('filter', state.filter);
      }

      return axios.get(`${Url().SEARCH.BING}?${params}`);
    },
    success: SearchActions.bingSearchSuccess,
    error: SearchActions.bingSearchFailure,
    shouldFetch() {
      return !root.__server_side_render;
    },
  },
};

export default SearchSource;
