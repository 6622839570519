import axios from 'axios';
import root from 'window-or-global';

import BattlePassActions from '../actions/BattlePassActions';
import Url from '../constants/Url';

const BattlePassSource = {
  getPage: {
    remote(state, locale) {
      return axios.get(Url().BATTLEPASS.GET(locale));
    },
    success: BattlePassActions.getPageSuccess,
    error: BattlePassActions.getPageFailure,

    shouldFetch() {
      return !root.__server_side_render;
    },
  },
};

export default BattlePassSource;
