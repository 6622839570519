import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Message from '../components/Message';
import BING_SEARCH_PRIVACY_LINK from '../constants/Links';
import Themes from '../constants/Themes';
import styled from 'styled-components';
import whiteLogo from '../../../images/bingsearch-logo-white.png';
import darkLogo from '../../../images/bingsearch-logo-black.png';

const Branding = styled.div`
    text-align: left;
    .logo {
        position: relative;
        top: 4px;
        width: 50px;
        margin-right: 0.5em;
    }

    .logo-container, .link {
        display: inline-block;
    }

    .link {
        font-size: 0.9em;
        margin: 1.1em auto;
        border-left: 1px solid ${p => p.theme.bingSearch.bingBrandSecondary};
        padding-left: 0.5em;
    }

    &.dark {
        .logo-container .logo {
            background: url(${p => p.whiteLogo || ''});
            height: 20px;
        }
        .link {
            color: ${p => p.theme.bingSearch.white};
            border-color: ${p => p.theme.bingSearch.white};
        }
    }

    &.light {
        .logo-container .logo {
            background: url(${p => p.darkLogo || ''});
            height: 22px;
        }
        .link {
            color: ${p => p.theme.bingSearch.bingBrandSecondary};
        }
    }
`;

class BingBranding extends React.Component {
    static propTypes = {
        theme: PropTypes.oneOf([
            Themes.DARK,
            Themes.LIGHT
        ]),
        privacyStatement: PropTypes.string
    };
    static defaultProps = {
        theme: Themes.DARK
    };
    render() {
        const {theme, privacyStatement = ''} = this.props;
        return (
            <Branding className={classNames(theme.toLowerCase())} whiteLogo={whiteLogo} darkLogo={darkLogo}>
                <div className="logo-container">
                    <div className="logo" />
                </div>
                <a
                    className="link"
                    href={BING_SEARCH_PRIVACY_LINK}
                    rel="noopener noreferrer"
                    target="_blank">
                    {privacyStatement || <Message
                        code="epic.bingSearch.microsoft_privacy_statement" />}
                </a>
            </Branding>
        );
    }
}

export default BingBranding;
