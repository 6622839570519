import axios from 'axios';
import root from 'window-or-global';

import CosplayActions from '../actions/CosplayActions';
import Url from '../constants/Url';

const CosplaySource = {
  getOverview: {
    remote(state, locale) {
      return axios.get(Url().COSPLAY.OVERVIEW(locale));
    },

    success: CosplayActions.getOverviewSuccess,
    error: CosplayActions.getOverviewFailure,

    shouldFetch() {
      return !root.__server_side_render;
    },
  },
};

export default CosplaySource;
