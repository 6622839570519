import React from 'react';

interface Props {
  styles?: React.CSSProperties;
  id?: string;
}

const SpeechTail = ({styles, id}: Props) => (
  <svg id={id} style={styles} viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
    <path fill="#0260a2" d="M.4 33.9V12.4l21.3-5.6v8.7l18-2.2-22.6 20.1-2.6-8L.6 33.9" />
  </svg>
);

export default SpeechTail;
