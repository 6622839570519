import styled from 'styled-components';

const VBucksContentWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 1280px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 1em;

  @media screen and (max-width: 940px) {
    padding: 6em 1em 2em;
    align-items: center;
  }
`;

export default VBucksContentWrapper;
