import {getLocale} from '@epic-core/common';
import HttpErrorScreen from 'epic-fortnite-react/components/error/HttpErrorScreen';
import {getInitialCmsPageSharedValues, PageData, useCmsData} from 'epic-fortnite-shared-values';
import React from 'react';
import Helmet from 'react-helmet';
import {RouteChildrenProps} from 'react-router';
import {extractSlugLocale} from '@epic-mw/localization';

import LoadScreen from '../../components/loadScreen/LoadScreen';

interface Props extends RouteChildrenProps {}

interface LegalPageData extends PageData {
  content?: string;
  image?: string;
  metaTitle?: string;
  metaDescription?: string;
  metaImage?: string;
}

const GenericLegalView = ({location}: Props) => {
  const locale = getLocale();
  const pathname = location.pathname || '';
  const {pageSlug} = extractSlugLocale(pathname, locale);

  const {pageData: data, isLoading, error} = useCmsData<LegalPageData>(pageSlug);

  if (isLoading) {
    return <LoadScreen show={true} />;
  }

  if (error) {
    return <HttpErrorScreen statusCode={error.response?.status ?? 500} />;
  }

  if (!data) {
    return <HttpErrorScreen statusCode={404} />;
  }

  const {content, image, metaTitle, metaDescription, metaImage} = data;
  const style = {
    backgroundImage: `url(${image})`,
  };
  const tags = [
    {itemProp: 'name', content: metaTitle},
    {itemProp: 'description', content: metaDescription},
    {itemProp: 'image', content: metaImage},
    {name: 'description', content: metaDescription},
    {name: 'twitter:title', content: metaTitle},
    {name: 'twitter:description', content: metaDescription},
    {name: 'twitter:image', content: metaImage},
    {property: 'og:title', content: metaTitle},
    {property: 'og:image', content: metaImage},
    {property: 'og:description', content: metaDescription},
  ];

  return (
    <div className="genericLegalView">
      {
        // only override default meta if there is meta data in CMS
        metaTitle && <Helmet meta={tags} title={metaTitle} />
      }
      <div className="header-image" style={style} />
      <div className="content" dangerouslySetInnerHTML={{__html: content ?? ''}} />
    </div>
  );
};

GenericLegalView.getInitialSharedValues = getInitialCmsPageSharedValues();

export default GenericLegalView;
