import styled from 'styled-components';

import {Device} from '../../../constants';

const HeaderContent = styled.div`
  width: 100%;
  text-align: center;
  margin-bottom: 10rem;
  margin-top: 0;

  @media screen and ${Device.desktop} {
    margin-bottom: 11rem;
  }

  @media screen and ${Device.laptop} {
    margin-bottom: 9.5rem;
  }

  @media screen and ${Device.mobileL} {
    margin-top: 6rem;
  }

  @media screen and ${Device.mobileM} {
    margin-top: 6rem;
  }

  @media screen and ${Device.mobileS} {
    margin-top: 6rem;
  }

  .animated-content {
    button,
    a {
      margin-top: 0;
      @media screen and ${Device.mobileL} {
        margin-top: 1vw;
        margin-bottom: 0;
      }
    }
    h1,
    h3 {
      opacity: 0;
    }
    @media screen and ${Device.mobileL} {
      text-align: center;
    }
    h3 {
      font-size: 2em;
      letter-spacing: 6px;
      @media screen and ${Device.mobileL} {
        font-size: 1.2em;
        letter-spacing: 2px;
      }
    }
  }
  &.active {
    .animated-content {
      h1,
      h3 {
        transform: none;
        transition: 0.5s ease-in-out;
        animation: textAnimateIn 1.5s cubic-bezier(0.68, -0.55, 0.265, 1.55) 0.6s forwards;
      }
    }
  }
`;

export default HeaderContent;
