import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Result = styled.div`
    margin: 1em 0;
    padding: 0.2em 0;
    background-color: #fff;

    ${(p) => p.theme.breakpoints.down('xs')} {
        padding: 1em;
    }

    a {
        transition: color 0.3s ease-in-out;
        color: ${p => p.theme.bingSearch.darkGray};
        text-decoration: ;
    }

    .resultTitle {
        a {
            display: inline-block;
            font-size: 1.2em;
            font-weight: 400;
            font-family: OpenSans, sans-serif;
            line-height: 1.2;
            margin-bottom: 0;
            padding-bottom: 0;
            border: none;
            transition: text-decoration 0.3s ease-in-out;
            color: ${p => p.theme.bingSearch.titleBlue};
            ${(p) => p.theme.breakpoints.down('xs')} {
                font-size: 16px;
            }
            &:hover {
                text-decoration: underline;
            }
        }
    }

    .resultLink {
        a {
            display: inline-block;
            margin: 5px 0 3px;
            font-size: 13px;
            word-break: break-all;
            font-weight: 400;
            line-height: 1.1;
            color: ${p => p.theme.bingSearch.linkGreen};
        }
    }

    .resultDescription {
        font-size: 14px;
        font-weight: 400;
        line-height: 1.4em;
        color: ${p => p.theme.bingSearch.darkGray};
        ${(p) => p.theme.breakpoints.down('xs')} {
            font-size: 13px;
        }
    }
`;

const BingResult = function BingResult(props) {
    return (
        <Result>
            <div className="resultTitle"><a href={props.url}>{props.title}</a></div>
            <div className="resultLink"><a href={props.url}>{props.displayUrl}</a></div>
            <p className="resultDescription">{props.description}</p>
        </Result>
    );
};

BingResult.propTypes = {
    title: PropTypes.string,
    url: PropTypes.string,
    displayUrl: PropTypes.string,
    description: PropTypes.string
};

export default BingResult;
