import {withLocalization} from '@epic-core/localization';
import Proptypes from 'prop-types';
import React from 'react';
import Select from 'react-select';

import {Option} from './CountriesContentContext';
import dropdownStyles from './dropdownStyles';

const CountrySelect = ({
  countryOptions,
  setCountry,
  placeholder,
  getMessage,
  initValue,
  ...rest
}) => {
  const [value, setValue] = React.useState<Option>(initValue);
  const onChange = React.useCallback(
    (item) => {
      setCountry(item.value);
      setValue(item);
    },
    [setCountry, setValue]
  );
  const noOptionsMessage = React.useCallback(() => getMessage('no_results'), [getMessage]);
  React.useEffect(() => {
    setValue(initValue);
  }, [initValue, setValue]);
  return (
    <Select
      placeholder={placeholder}
      isOptionDisabled={(option) => option.disabled}
      onChange={onChange}
      noOptionsMessage={noOptionsMessage}
      options={countryOptions}
      styles={dropdownStyles}
      value={value}
      defaultValue={initValue}
      {...rest}
    />
  );
};

CountrySelect.propTypes = {
  countryOptions: Proptypes.arrayOf(
    Proptypes.shape({
      label: Proptypes.string,
      value: Proptypes.string,
    })
  ),
  setCountry: Proptypes.func,
  placeholder: Proptypes.string,
  getMessage: Proptypes.func,
  styles: Proptypes.object,
  width: Proptypes.string,
  className: Proptypes.string,
};

export default withLocalization(CountrySelect);
