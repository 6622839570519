import React from 'react';

import FeatureCarouselItemContainer from './FeatureCarouselItemContainer';
import Hero from './Hero';
import HeroLoadout from './HeroLoadout';
import {HeroSlide, ImageArray} from './Types';

interface Props {
  data: HeroSlide;
  active: boolean;
  isVisible: boolean;
  mobile: boolean;
  tablet: boolean;
}

const FeatureCarouselItem = ({data, active, isVisible, mobile, tablet}: Props) => {
  const {hero, heroItems, key, sound} = data;
  const leftItems = heroItems.reduce((acc, item, index) => {
    if (index % 2 === 0) {
      acc.push(item);
    }
    return acc;
  }, [] as ImageArray[]);
  const rightItems = heroItems.reduce((acc, item, index) => {
    if (index % 2 === 1) {
      acc.push(item);
    }
    return acc;
  }, [] as ImageArray[]);
  const animate = active && isVisible;
  return (
    <FeatureCarouselItemContainer active={active}>
      <HeroLoadout
        mobile={mobile}
        tablet={tablet}
        keyId={key}
        reverse
        active={animate}
        items={leftItems}
      />
      <Hero
        sound={sound}
        mobile={mobile}
        tablet={tablet}
        keyId={key}
        active={animate}
        images={hero}
      />
      <HeroLoadout
        mobile={mobile}
        tablet={tablet}
        keyId={key}
        active={animate}
        items={rightItems}
      />
    </FeatureCarouselItemContainer>
  );
};

export default FeatureCarouselItem;
