import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import root from 'window-or-global';

import animateScrollToY from '../../utils/AnimateScrollToY';

export default class PatchNotesNavigation extends React.PureComponent {
  static propTypes = {
    firefox: PropTypes.bool,
    msie: PropTypes.bool,
    content: PropTypes.object,
    headerSections: PropTypes.array,
  };

  //function that animates scroll
  animateScroll = (id, speed) => {
    const section = `${id}-anchor`;
    const el = root.document.getElementById(section);
    const parentElement = this.props.firefox ? root.document.documentElement : root.document.body;
    const bodyTop = parentElement.getBoundingClientRect().top;
    const eScrollOffset = el.getBoundingClientRect().top - bodyTop;

    if (this.props.msie) {
      root.scrollTo(0, eScrollOffset);
    } else {
      animateScrollToY(eScrollOffset, speed, 'easeInOutCubic');
    }
  };

  scrollToSection = (e) => {
    const speed = 700;
    this.animateScroll(e.currentTarget.id, speed);
  };

  render() {
    const {content, headerSections} = this.props;
    if (!content) return <div />;
    const headerLinks = headerSections.map((item) => {
      const linkClass = classNames('quick-link', item.color);
      return (
        <li
          key={item.id}
          id={item.id}
          role="presentation"
          className={linkClass}
          onClick={this.scrollToSection}>
          <span className="link-jump">{content.jump}</span>
          <h3>{item.title}</h3>
        </li>
      );
    });
    const navStyle = content.navColor ? {backgroundColor: `#${content.navColor}`} : {};
    return (
      <div className="patch-notes-navigation" style={navStyle}>
        <div className="container-fluid patch-container">
          <div className="row">
            <div className="col-sm-12 col-md-6">
              <h1>{content.title}</h1>
              <div dangerouslySetInnerHTML={{__html: content.description}} />
            </div>
            <div className="col-sm-12 col-md-6 text-right">
              <ul>{headerLinks}</ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
