import {getLocale} from '@epic-core/common';
import {generateRouteHref} from 'epic-fortnite-react';
import React from 'react';
import {useLocation} from 'react-router-dom';

import Pagination from '../Pagination';

import {ArchiveItem, ArchiveViewWrapper, Grid, LoadingIconWrapper} from './components';

interface Page {
  name?: string;
  _slug?: string;
  archive?: {
    publishedAt?: string;
    image?: string;
    difficulty?: string;
  };
  carousel?: {
    time?: string;
    difficulty?: string;
  };
}

interface Props {
  pages: Page[];
  totalItems: number;
  pageSize: number;
  difficultyLabels: {[key: string]: string};
  loading?: boolean;
}

const ArchiveView = ({
  pages = [],
  difficultyLabels,
  totalItems,
  pageSize,
  loading = false,
}: Props) => {
  const locale = getLocale();
  const location = useLocation();

  return (
    <ArchiveViewWrapper>
      {loading === true ? (
        <LoadingIconWrapper>
          <div className="loading-icon" />
        </LoadingIconWrapper>
      ) : null}

      {loading === false && pages?.length === 0 ? <h1>No masks found</h1> : null}

      {loading === false && pages?.length > 0 ? (
        <>
          <Grid>
            {pages?.map((page) => (
              <ArchiveItem
                key={page._slug}
                image={page.archive?.image}
                name={page.name}
                publishDate={page.archive?.publishedAt}
                difficulty={page.archive?.difficulty}
                difficultyLabels={difficultyLabels}
                timeRequired={page.carousel?.time}
                url={generateRouteHref(page._slug as string, location, locale)}
              />
            ))}
          </Grid>
          <Pagination pagesToShow={5} itemsPerPage={pageSize} totalItemsCount={totalItems} />
        </>
      ) : null}
    </ArchiveViewWrapper>
  );
};

export default ArchiveView;
