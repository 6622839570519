import styled from 'styled-components';

const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  flex-direction: row;

  @media (max-width: 560px) {
    & {
      flex-direction: column;
    }
  }
`;

export default Row;
