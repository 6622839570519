import PropTypes from 'prop-types';
import React, {useState} from 'react';
import VisibilitySensor from 'react-visibility-sensor';

import AnimateString from '../common/AnimateString';

import EmoteVideo from './EmoteVideo';

const Emotes = ({data, tablet, mobile, backgroundVids}) => {
  const {title} = data;
  const [visible, setVisibility] = useState(false);
  const vids = backgroundVids ? backgroundVids.background : [];
  return (
    <VisibilitySensor
      onChange={(visibility) => setVisibility(visibility)}
      partialVisibility
      minTopValue={400}>
      <div className={`battlepass-emotes ${visible ? 'visible' : ''}`}>
        <div className="emotes-content">
          <AnimateString
            key={`emotes-h1`}
            text={title}
            type="h1"
            speed={1.5}
            delay={0.3}
            autoAlpha={0}
            yPos={100}
            minTop={20}
            scaleFrom={1}
            staggerDelay={0}
          />
        </div>
        <div className="container-fluid emote-videos-container">
          <div className="emote-videos-wrapper row">
            {vids.map((video, index) => {
              return (
                <EmoteVideo
                  key={`battlePass-emote-${index}`}
                  autoPlay={data.autoPlayEmotes}
                  useClick={tablet || mobile}
                  useBackground
                  visible={visible}
                  video={video}
                />
              );
            })}
          </div>
        </div>
      </div>
    </VisibilitySensor>
  );
};

Emotes.propTypes = {
  data: PropTypes.object,
  emotes: PropTypes.object,
  mobile: PropTypes.bool,
  tablet: PropTypes.bool,
  backgroundVids: PropTypes.object,
};

export default Emotes;
