export const size = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  tablet: '768px',
  laptop: '1024px',
  laptopL: '1440px',
  desktopS: '2048px',
  desktop: '2560px',
};

const device = {
  mobileS: `(max-width: ${size.mobileS})`,
  mobileM: `(max-width: ${size.mobileM})`,
  mobileL: `(max-width: ${size.mobileL})`,
  tablet: `(max-width: ${size.tablet})`,
  laptop: `(max-width: ${size.laptop})`,
  laptopL: `(max-width: ${size.laptopL})`,
  desktopS: `(max-width: ${size.desktopS})`,
  desktop: `(max-width: ${size.desktop})`,
  desktopL: `(max-width: ${size.desktop})`,
};

const numericSizes = {
  mobileS: 320,
  mobileM: 375,
  mobileL: 425,
  tablet: 768,
  laptop: 1024,
  laptopL: 1440,
  desktopS: 2048,
  desktop: 2560,
};

export const breakpoints = {
  mobileS: `(max-width: ${numericSizes.mobileM - 1}px)`,
  mobileM: `(max-width: ${numericSizes.mobileL - 1}px)`,
  mobileL: `(max-width: ${numericSizes.tablet - 1}px)`,
  tablet: `(max-width: ${numericSizes.laptop - 1}px)`,
  laptop: `(max-width: ${numericSizes.laptopL - 1}px)`,
  laptopL: `(max-width: ${numericSizes.desktop - 1}px)`,
  desktopS: `(max-width: ${numericSizes.desktopS - 1}px)`,
  desktop: `(max-width: ${numericSizes.desktop}px)`,
  desktopL: `(max-width: ${numericSizes.desktop}px)`,
};

export default device;
