import {generateRouteTo} from 'epic-fortnite-react';
import classNames from 'classnames';
import {getMatchGuide} from 'epic-fortnite-react/utils/FortniteUtils';
import PropTypes from 'prop-types';
import React from 'react';
import {Link, withRouter} from 'react-router-dom';

import GuideSubMenu from './GuideSubMenu';
import GuideSubTab from './GuideSubTab';

class GuideMenu extends React.Component {
  static propTypes = {
    slug: PropTypes.string,
    menu: PropTypes.object,
    guides: PropTypes.array,
    mobile: PropTypes.bool,
    match: PropTypes.object,
    history: PropTypes.object,
    activeTab: PropTypes.number,
    locale: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.state = {
      activeMenu: props.match.params.folder,
    };
  }

  onToggle = (event) => {
    const {location, locale} = this.props;
    const {slug} = event.target.dataset;
    if (this.props.match.params.folder !== slug) {
      this.props.history.push(generateRouteTo(`/play-guide/${slug}`, location, locale));
    } else if (this.props.mobile) {
      this.setState({activeMenu: this.state.activeMenu ? '' : slug});
    }
  };

  render() {
    const {menu, guides, match, activeTab, slug, location, locale} = this.props;
    const {activeMenu} = this.state;

    return (
      <div className="guide-menu-wrap">
        <div className="menu-nav">
          <Link to={generateRouteTo('/play-guide', location, locale)} className="title direct-link">
            {menu.mainPage}
          </Link>
          {menu.list.map((item, index) => {
            const page = getMatchGuide({guides, id: item.link});
            const cls = classNames({
              active: activeMenu === page._slug,
            });
            return (
              <button
                className={cls}
                data-index={index}
                data-slug={page._slug}
                onClick={this.onToggle}
                key={`menu-nav-${page._id}`}>
                {page._title}
              </button>
            );
          })}
        </div>
        <div>
          {menu.list.map((item, index) => {
            const {link, subMenu, subTab} = item;
            const page = getMatchGuide({guides, id: link});
            const cls = classNames('menu', {
              active: activeMenu === page._slug,
            });

            return (
              <div className={cls} key={page._id}>
                {subMenu && (
                  <GuideSubMenu
                    menus={subMenu}
                    guides={guides}
                    path={page._slug}
                    match={match}
                    locale={locale}
                  />
                )}
                {subTab && (
                  <GuideSubTab
                    tabs={subTab}
                    path={page._slug}
                    activeTab={activeTab}
                    slug={slug}
                    locale={locale}
                  />
                )}
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default withRouter(GuideMenu);
