import React from 'react';
import styled, {css} from 'styled-components';
import {google, outlook, yahoo, ics} from 'calendar-link';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import type {CalendarEvent} from 'calendar-link';
import epicTracking from 'epic-tracking';

import {CalendarIcon, GoogleIcon, AppleIcon, YahooIcon, OutlookIcon} from './AddToCalendarIcons';
interface Props {
    triggerLabel: string;
    event: CalendarEvent;
    instanceId: string; // addresses ssr related id mismatch error when using radix component.
}

const fontStyle = css`
    font-family: sans-serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 1;
`;

const flexRow = css`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const MenuTrigger = styled(DropdownMenu.Trigger)`
    ${fontStyle}
    ${flexRow}
    margin: 4rem auto;
    padding: 0.9rem 1.5rem;
    min-width: 17.5rem;
    background: #ffffff;
    border: 1px solid #919191;
    box-sizing: border-box;
    border-radius: 2px;
    color: #0b85ec;
    &:hover {
        border: 1px solid #303030;
    }
`;

const MenuContent = styled(DropdownMenu.Content)`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0.8rem;
    width: 17.5rem;
    background: #ffffff;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
    border-radius: 2px;
`;

const MenuItem = styled(DropdownMenu.Item)`
    ${fontStyle}
    ${flexRow}
    padding: 0.4rem;
    margin: 0.4rem 0;
    width: 100%;
    border-radius: 4px;
    color: #303030;
    &:hover {
        background: #f5f5f5;
    }
    & svg {
        margin-right: 1rem;
    }
`;

const trackAddToCalendarClick = (label: string, service?: string): void => {
    epicTracking.trackEvent('Interaction',{
        eventCategory: 'fn-web',
        eventAction: `user.click.body.add-to-calendar${service ? `.${service}` : ''}`,
        eventLabel: label,
        eventValue: 1,
        interactionType: 'click'
    });
};

const AddToCalendarMenu = (props: Props): JSX.Element => {
    const {event, instanceId, triggerLabel} = props;
    return (
        <DropdownMenu.Root
            onOpenChange={(isOpen) => {
                if (isOpen) trackAddToCalendarClick(event.start);
            }}>
            <MenuTrigger id={instanceId}>
                <CalendarIcon />
                {triggerLabel}
            </MenuTrigger>
            <MenuContent>
                <MenuItem
                    as="a"
                    href={ics(event)}
                    onClick={() => trackAddToCalendarClick(event.start, 'apple')}
                    target="_blank"
                    rel="noopener noreferrer">
                    <AppleIcon />
                    Apple
                </MenuItem>
                <MenuItem
                    as="a"
                    href={google(event)}
                    onClick={() => trackAddToCalendarClick(event.start, 'google')}
                    target="_blank"
                    rel="noopener noreferrer">
                    <GoogleIcon />
                    Google
                </MenuItem>
                <MenuItem
                    as="a"
                    href={outlook(event)}
                    onClick={() => trackAddToCalendarClick(event.start, 'outlook')}
                    target="_blank"
                    rel="noopener noreferrer">
                    <OutlookIcon />
                    Outlook
                </MenuItem>
                <MenuItem
                    as="a"
                    href={yahoo(event)}
                    onClick={() => trackAddToCalendarClick(event.start, 'yahoo')}
                    target="_blank"
                    rel="noopener noreferrer">
                    <YahooIcon />
                    Yahoo
                </MenuItem>
            </MenuContent>
        </DropdownMenu.Root>
    );
};

export default AddToCalendarMenu;
