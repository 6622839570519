import {ContextMessage as Message} from '@epic-core/localization';
import {connectToStores} from 'epic-alt-utils';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React from 'react';

import EulaActions from '../../actions/EulaActions';
import LoadScreen from '../../components/loadScreen/LoadScreen';
import MetaTags from '../../components/common/MetaTags';

import {CmsStore, EulaStore} from '../../stores';

const slug = 'eula';

/**
 * Eula View
 */
@connectToStores
class EulaView extends React.Component {
  static propTypes = {
    eulaStore: PropTypes.object,
    location: PropTypes.object,
    cmsStore: PropTypes.object,
  };

  static getStores() {
    return [EulaStore, CmsStore];
  }

  static getPropsFromStores() {
    return {
      eulaStore: EulaStore.getState(),
      cmsStore: CmsStore.getState(),
    };
  }

  static getInitialActions() {
    return [EulaActions.fetchEula];
  }

  state = {
    showDrop: false,
  };

  toggleDropdown = () => {
    this.setState({
      showDrop: !this.state.showDrop,
    });
  };

  showEula(eulaLink) {
    EulaActions.showEula(eulaLink);
  }

  UNSAFE_componentWillMount() {
    const query = queryString.parse(this.props.location.search) || '';
    const eulaParam = query.eula;
    if (eulaParam && this.props.eulaStore.isValidEula(eulaParam)) {
      EulaActions.showEula(eulaParam);
    }
  }

  getEulaLinks() {
    const {eulaStore} = this.props;
    const eulaLinks = [];
    const {currentEula, availableEulas} = eulaStore;
    for (const eula of availableEulas) {
      if (eulaStore[eula] === currentEula) continue;
      const onClickEula = this.showEula.bind(this, eula);
      const eulaKey = eulaStore[eula];
      const eulaMessage = `epic.fortnite.eula.${eulaKey}`;
      eulaLinks.push(
        <a role="presentation" key={eulaKey} onClick={onClickEula}>
          {' '}
          {/*eslint-disable-line*/}
          <Message code={eulaMessage} />
        </a>
      );
    }
    return <div className="container-contents">{eulaLinks}</div>;
  }

  render() {
    const {
      eulaStore,
      cmsStore: {pages},
    } = this.props;
    const {data: page} = pages[slug];
    const eula = eulaStore.getCurrentEula();
    let eulaContainer = null;
    if (eula) {
      let btnStyle = 'dropDown-container ';
      const currentEulaMessage = `epic.fortnite.eula.${eulaStore.currentEula}`;
      if (this.state.showDrop) {
        btnStyle = 'dropDown-container active';
      }
      const eulaLinks = this.getEulaLinks();
      eulaContainer = (
        <div className="site-block eula-container">
          <div className="site-block-header">
            <h1 className="site-block-title pull-left eula-title">{eula.title}</h1>
            <div
              role="presentation"
              className="eula-selector-container pull-right"
              onClick={this.toggleDropdown}>
              <div className="eula-button-container">
                <div className={btnStyle}>
                  {eulaLinks}
                  <div className="btn btn-xs btn-primary">
                    <Message code={currentEulaMessage} />
                    <i className="icon-right-arrow" />
                  </div>
                </div>
              </div>
            </div>
            <div className="clearfix" />
          </div>
          <div className="site-block-body">
            <div
              className="site-block-body-text eula-text"
              dangerouslySetInnerHTML={{__html: eula.body}}
            />
          </div>
        </div>
      );
    }
    return (
      <div className="eula-view-container">
        <MetaTags pageModel={page} />
        <div className="light-bg">
          <div id="eulaContainer" className="container eula">
            {eulaContainer}
          </div>
        </div>
        <LoadScreen show={eulaStore.loading} />
      </div>
    );
  }
}

export default EulaView;
