import epicTracking from 'epic-tracking';
import React from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import styled from 'styled-components';

import {Stack, Text} from 'epic-fortnite-react';

interface CmsData {
  imgLeft: string;
  imgRight: string;
  header: string;
  textContent: string;
  mobileImage: string;
}

interface VBucksSectionThreeColumnProps {
  mobile: boolean;
  tablet: boolean;
  data: CmsData;
}

const StyledVBucksSectionThreeColumn = styled.div`
  background-color: #fff;
  clip-path: polygon(0% 0%, 0% 100%, 100% 100%, 100% 4vw);
  margin-top: -4vw;
  align-items: center;
  display: flex;
  gap: 64px;
  padding: calc(80px + 4vw) 0 80px;
  position: relative;
  z-index: 2;

  @media (max-width: 1023px) {
    flex-direction: column;
  }

  img {
    min-width: 0;
    width: 100%;

    &.vbucks-left,
    &.vbucks-right {
      flex-basis: 30%;

      @media (max-width: 1023px) {
        display: none;
      }
    }

    &.vbucks-mobile {
      max-width: 600px;
      @media (min-width: 1024px) {
        display: none;
      }
    }
  }
`;

const Content = styled.div`
  padding: 0 32px;

  @media (min-width: 1024px) {
    max-width: 40vw;
  }
`;

const VBucksSectionThreeColumn = ({data, mobile, tablet}: VBucksSectionThreeColumnProps) => {
  const [visible, setVisibility] = React.useState(false);

  const setSectionVisibility = React.useCallback(
    (visibility) => {
      setVisibility(visibility);
      if (visibility) {
        epicTracking.trackEvent('Interaction', {
          interactionType: 'scrollViewSection',
          eventCategory: 'BattlePass VBucks Section',
          eventAction: 'VBucksSection-inView',
        });
      }
    },
    [setVisibility]
  );

  return (
    <VisibilitySensor
      active={!visible}
      onChange={setSectionVisibility}
      partialVisibility
      minTopValue={0}>
      <StyledVBucksSectionThreeColumn>
        <img aria-hidden="true" className="vbucks-left" src={data.imgLeft} alt="" />
        <Content>
          <Stack gap="lg">
            <Text align="center" as="h2" size="4xl">
              {data.header}
            </Text>
            <Text align="center" as="p" size="lg">
              {data.textContent}
            </Text>
          </Stack>
        </Content>
        <img aria-hidden="true" className="vbucks-right" src={data.imgRight} alt="" />
        <img aria-hidden="true" className="vbucks-mobile" src={data.mobileImage} alt="" />
      </StyledVBucksSectionThreeColumn>
    </VisibilitySensor>
  );
};

export default VBucksSectionThreeColumn;
