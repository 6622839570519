import React from 'react';
import {sanitizeCopy} from '@epic-mw/common-utils/dist/contentUtils';

interface Props {
    content: string;
    key: string;
}

const RichHtmlSection = ({content, key}: Props): JSX.Element => {
    const sanitizedContent = sanitizeCopy(content);

    return (
        <div
            // The CMS rich text field returns a HTML string we trust.
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{__html: sanitizedContent}}
            key={`richHtml-${key}`}
        />
    );
};

export default RichHtmlSection;
