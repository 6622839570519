import styled from 'styled-components';

import {breakpoints} from '../../constants/Breakpoints';

interface Props {
  buttonAlign: string;
}

const CTAButtonWrapper = styled.div<Props>`
  filter: drop-shadow(0px 0px 44px rgba(0, 0, 0, 0.8));
  display: flex;
  justify-content: center;
  width: 100vw;
  font-size: 2.5rem;
  margin-top: 54rem;

  @media ${breakpoints.laptop} {
    margin-top: 34rem;
  }

  @media ${breakpoints.tablet} {
    font-size: 2.1rem;
    margin-top: 29rem;
  }

  @media ${breakpoints.mobileL} {
    margin-bottom: 5rem;
    margin-top: 0;
    font-size: 1.8rem;
  }

  @media ${breakpoints.mobileS} {
    margin-bottom: 2rem;
    margin-top: 0;
    font-size: 1.8rem;
  }
`;

export default CTAButtonWrapper;
