import React from 'react';
import root from 'window-or-global';

import {SpeechBorder, SpeechTail} from '../../../icons';

import ContentBox from './ContentBox';
import ContentBoxDescription from './ContentBoxDescription';
import ContentBoxTitle from './ContentBoxTitle';

interface Props {
  title: string;
  description: string;
  animate: boolean;
}

const CalloutBox = ({title, description, animate}: Props) => {
  const [isMobile, setIsMobile] = React.useState(false);

  const checkWindowSize = React.useCallback(() => {
    const ww = root.innerWidth;
    if (ww <= 940 && !isMobile) {
      setIsMobile(true);
    } else if (ww > 940 && isMobile) {
      setIsMobile(false);
    }
  }, [root, setIsMobile, isMobile]);

  React.useLayoutEffect(() => {
    checkWindowSize();
    root.addEventListener('resize', checkWindowSize, true);
    return () => {
      root.removeEventListener('resize', checkWindowSize, true);
    };
  }, [root, checkWindowSize]);

  const z = isMobile ? -4 : -12;
  const xPos = isMobile ? '0%' : '2%';
  const yPos = isMobile ? '-30%' : '42%';
  const zAnimate = isMobile ? 0 : -6;
  return (
    <ContentBox
      className="vbucks-callout"
      initial={{x: '20%', y: '100%', opacity: 0, rotateZ: z}}
      animate={
        animate
          ? {x: xPos, y: yPos, opacity: 1, rotateZ: [0, z, zAnimate, z, zAnimate, z]}
          : {x: '20%', y: '100%', opacity: 0, rotateZ: z}
      }
      transition={{
        y: {duration: 0.3, type: 'spring', delay: 0.3},
        x: {duration: 0.3, type: 'spring', delay: 0.3},
        opacity: {duration: 0.3, type: 'spring', delay: 0.3},
        rotateZ: {
          duration: 0.5,
          repeat: Infinity,
          repeatType: 'loop',
          repeatDelay: 5,
          delay: 0.5,
        },
      }}
      callout>
      <ContentBoxTitle smallText color="#ffd200">
        {title}
      </ContentBoxTitle>
      <ContentBoxDescription>{description}</ContentBoxDescription>
      <SpeechTail id="speech-tail" />
      <SpeechBorder id="speech-border" />
    </ContentBox>
  );
};

export default CalloutBox;
