import styled from 'styled-components';

import {breakpoints} from '../../../../constants/Breakpoints';

const DifficultyWrapper = styled.div`
  display: inline-flex;
  align-items: flex-end;

  @media ${breakpoints.mobileM} {
    display: flex;
  }
`;

export default DifficultyWrapper;
