import React from 'react';

import {useLocation, useHistory} from 'react-router';
import {useLocalizationContext} from '@epic-core/localization';

/**
 * useHideLocaleIfPreference removes the 'lang' URL query parameter
 * if it matches the user's first supported language preference.
 * The query parameter should only be shown if it deviates from
 * the user's first supported preference.
 */
export function useHideLocaleIfPreference(languagePreference: string[]) {
  const {supportedLocales} = useLocalizationContext();
  const location = useLocation();
  const history = useHistory();

  if (!languagePreference) {
    throw new Error('useHideLocaleIfPreference needs a `languagePreference` argument');
  }

  React.useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    if (!queryParams.has('lang') || !languagePreference.length) return;

    for (const lang of languagePreference) {
      // Find the first user language preference supported by the app.
      const isSupportedLanguage = supportedLocales.some(
        (supportedLanguage) =>
          lang === supportedLanguage.lang ||
          (lang.includes('en') && supportedLanguage.lang === 'en-US')
      );

      if (isSupportedLanguage) {
        // If the first supported language preference matches the
        // query parameter, remove the 'lang' parameter from the URL.
        if (queryParams.get('lang') === lang && typeof window !== 'undefined') {
          queryParams.delete('lang');

          const newUrl = `${location.pathname}${queryParams.size ? `?${queryParams}` : ''}`;

          history.replace(newUrl);
        }

        /**
         * Otherwise, if the first supported language differs from the query parameter,
         * we leave the query parameter in place and conclude the process.
         *
         * In both scenarios, we're done, so we `return` to stop executing
         * after we've matched the first user language preference that
         * is supported by the app.
         */
        return;
      }
    }
  }, [location.search, supportedLocales, languagePreference]);
}
