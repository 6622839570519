import React from 'react';
import {LinkComponent, LinkComponentProps, useLocalizationLocale} from 'epic-games-nav-shared';
import {Link, useLocation} from 'react-router-dom';
import classnames from 'classnames';
import root from 'window-or-global';
import {generateRouteHref} from '../../utils/route';

function isRemixPath(pathname: string): boolean {
  return (
    pathname.startsWith('/@') ||
    pathname.startsWith('/my-library') ||
    pathname.startsWith('/creative/island-codes/') ||
    pathname.startsWith('/categories') ||
    pathname === '/'
  );
}

export const FortniteLinkComponent: LinkComponent = ({
  path,
  externalHrefTemplate,
  explicitLocale,
  isEmbedded,
  locale,
  children,
  className,
  ...props
}: LinkComponentProps) => {
  const activeLocale = useLocalizationLocale();
  const location = useLocation();

  if (externalHrefTemplate) {
    const href = externalHrefTemplate.replace('{locale}', locale);
    return (
      <a href={href} className={className} {...props}>
        {children}
      </a>
    );
  }

  assertPathIsPresent(path);
  const isEsportsLink = new RegExp(`^/competitive`).test(path as string);
  const isOnEsports = new RegExp(`${root.appContext}/competitive`).test(root.location.href);
  const isLocaleSwitch = activeLocale !== locale;

  // If `externalHrefTemplate` is not defined at this point `path` should be
  const [pathname, search] = path.split('?');
  const href = generateRouteHref({pathname, search: search || ''}, location, locale);

  if (isLocaleSwitch) {
    // we use the full appContext here, as for competitive it needs to include `/competitive`
    return (
      <a href={`${root.appContext}${href}`} className={className} {...props}>
        {children}
      </a>
    );
  }

  if ((isEsportsLink && !isOnEsports) || (!isEsportsLink && isOnEsports) || isRemixPath(href)) {
    return (
      <a href={`${root.appContext}${href}`} className={className} {...props}>
        {children}
      </a>
    );
  }

  const pathIndex = location.pathname.indexOf(path);
  const activeLink = pathIndex === 0;
  const classNames = classnames(className, {active: activeLink});

  return (
    <Link to={href} className={classNames} {...props}>
      {children}
    </Link>
  );
};

function assertPathIsPresent(maybePath?: string): asserts maybePath {
  if (!maybePath) {
    throw new Error(
      'FortniteLinkComponent error: either `path` or `externalHrefTemplate` should be defined'
    );
  }
}
