import React from 'react';

import {size} from '../../../../constants/Breakpoints';

interface Props {
  desktopSrc?: string;
  mobileSrc?: string;
  tabletSrc?: string;
  alt?: string;
  className?: string;
}

const ResponsiveImage = ({desktopSrc, mobileSrc, tabletSrc, alt = '', className}: Props) => {
  return (
    <picture className={className}>
      <source srcSet={desktopSrc} media={`(min-width: ${size.laptop})`} />
      <source srcSet={tabletSrc ?? desktopSrc} media={`(min-width: 500px)`} />
      <img src={mobileSrc ?? tabletSrc ?? desktopSrc} alt={alt} />
    </picture>
  );
};

export default ResponsiveImage;
