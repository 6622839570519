const epic = {
  purchaseFlow: true,
  value: 'epic',
  title: 'epic.fortnite.founder.dialog.retailer.epic',
};

const xbn = {
  title: 'epic.fortnite.founder.dialog.retailer.xbn',
  value: 'xbn',
  useOfferLink: true,
};

const psn = {
  title: 'epic.fortnite.founder.dialog.retailer.psn',
  value: 'psn',
  useOfferLink: true,
};

const retail = {
  defaultValue: 'us',
  title: 'epic.fortnite.founder.dialog.step1.retail',
  territories: {
    us: {
      title: 'epic.fortnite.founder.dialog.territory.us',
      value: 'us',
      countries: ['US'],
      defaultValue: 'ps4RetailAmerica',
      platforms: {
        ps4RetailAmerica: {
          title: 'epic.fortnite.founder.dialog.platform.ps4',
          value: 'ps4RetailAmerica',
          defaultValue: 'amazonPs4',
          retailers: {
            amazonPs4: {
              title: 'epic.fortnite.founder.dialog.retailer.amazon',
              value: 'amazonPs4',
              link: 'http://www.amazon.com/dp/B071WNVX56',
            },
            bestbuyPs4: {
              title: 'epic.fortnite.founder.dialog.retailer.bestbuy',
              value: 'bestbuyPs4',
              link: 'http://www.bestbuy.com/site/ps4-fortnite-playstation-4/5921703.p?skuId=5921703',
            },
            gamestopPs4: {
              title: 'epic.fortnite.founder.dialog.retailer.gamestop',
              value: 'gamestopPs4',
              link: 'http://www.gamestop.com/ps4/games/fortnite/149564',
            },
            targetPs4: {
              title: 'epic.fortnite.founder.dialog.retailer.target',
              value: 'targetPs4',
              link: 'https://www.target.com/p/-/A-52634875',
            },
          },
        },
        xboxRetailAmerica: {
          title: 'epic.fortnite.founder.dialog.platform.xbox',
          value: 'xboxRetailAmerica',
          defaultValue: 'amazonXb',
          retailers: {
            amazonXb: {
              title: 'epic.fortnite.founder.dialog.retailer.amazon',
              value: 'amazonXb',
              link: 'http://www.amazon.com/dp/B071JQTBFL',
            },
            bestbuyXb: {
              title: 'epic.fortnite.founder.dialog.retailer.bestbuy',
              value: 'bestbuyXb',
              link: 'http://www.bestbuy.com/site/xb1-fortnite-xbox-one/5921801.p?skuId=5921801',
            },
            gamestopXb: {
              title: 'epic.fortnite.founder.dialog.retailer.gamestop',
              value: 'gamestopXb',
              link: 'http://www.gamestop.com/xbox-one/games/fortnite/149560',
            },
            targetXb: {
              title: 'epic.fortnite.founder.dialog.retailer.target',
              value: 'targetXb',
              link: 'https://www.target.com/p/-/A-52634956',
            },
          },
        },
      },
    },
    ca: {
      title: 'epic.fortnite.founder.dialog.territory.ca',
      value: 'ca',
      countries: ['CA'],
      defaultValue: 'ps4RetailCa',
      platforms: {
        ps4RetailCa: {
          title: 'epic.fortnite.founder.dialog.platform.ps4',
          value: 'ps4RetailCa',
          defaultValue: 'amazonCaPs4',
          retailers: {
            amazonCaPs4: {
              title: 'epic.fortnite.founder.dialog.retailer.amazon',
              value: 'amazonCaPs4',
              link: 'https://www.amazon.ca/dp/B072MW91LZ/',
            },
            bestBuy: {
              title: 'epic.fortnite.founder.dialog.retailer.bestbuy',
              value: 'bestBuy',
              link: 'http://www.bestbuy.ca/en-ca/product/fortnite-ps4/10739584.aspx',
            },
            walmartCaPs4: {
              title: 'epic.fortnite.founder.dialog.retailer.walmart',
              value: 'walmartCaPs4',
              link: 'https://www.walmart.ca/en/ip/fortnite-ps4/6000197278441',
            },
            ebCaPs4: {
              title: 'epic.fortnite.founder.dialog.retailer.eb',
              value: 'ebCaPs4',
              link: 'https://www.ebgames.ca/PS4/Games/732725/fortnite',
            },
          },
        },
        xboxRetailCa: {
          title: 'epic.fortnite.founder.dialog.platform.xbox',
          value: 'xboxRetailCa',
          defaultValue: 'amazonCaXb',
          retailers: {
            amazonCaXb: {
              title: 'epic.fortnite.founder.dialog.retailer.amazon',
              value: 'amazonCaXb',
              link: 'https://www.amazon.ca/dp/B071X12VZN',
            },
            bestBuy: {
              title: 'epic.fortnite.founder.dialog.retailer.bestbuy',
              value: 'bestBuy',
              link: 'http://www.bestbuy.ca/en-ca/product/fortnite-xbox-one/10739638.aspx',
            },
            walmartCaXb: {
              title: 'epic.fortnite.founder.dialog.retailer.walmart',
              value: 'walmartCaXb',
              link: 'https://www.walmart.ca/en/ip/fortnite-xbox-one/6000197278444',
            },
            ebCaXb: {
              title: 'epic.fortnite.founder.dialog.retailer.eb',
              value: 'ebCaXb',
              link: 'https://www.ebgames.ca/Xbox%20One/Games/732726/fortnite',
            },
          },
        },
      },
    },
    uk: {
      title: 'epic.fortnite.founder.dialog.territory.uk',
      value: 'uk',
      countries: ['GB', 'FR', 'GR', 'PT', 'PL', 'ML'],
      defaultValue: 'ps4RetailEurope',
      platforms: {
        ps4RetailEurope: {
          title: 'epic.fortnite.founder.dialog.platform.ps4',
          value: 'ps4RetailEurope',
          defaultValue: 'amazonUkPs4',
          retailers: {
            amazonUkPs4: {
              title: 'epic.fortnite.founder.dialog.retailer.amazon',
              value: 'amazonUkPs4',
              link: 'https://www.amazon.co.uk/dp/B0734Y7Z71/',
            },
          },
        },
        xboxRetailEurope: {
          title: 'epic.fortnite.founder.dialog.platform.xbox',
          value: 'xboxRetailEurope',
          defaultValue: 'amazonUkXb',
          retailers: {
            amazonUkXb: {
              title: 'epic.fortnite.founder.dialog.retailer.amazon',
              value: 'amazonUkXb',
              link: 'https://www.amazon.co.uk/dp/B0734Y4YSR',
            },
          },
        },
      },
    },
    de: {
      title: 'epic.fortnite.founder.dialog.territory.de',
      value: 'de',
      countries: ['DE'],
      defaultValue: 'ps4RetailDe',
      platforms: {
        ps4RetailDe: {
          title: 'epic.fortnite.founder.dialog.platform.ps4',
          value: 'ps4RetailDe',
          defaultValue: 'amazonDePs4',
          retailers: {
            amazonDePs4: {
              title: 'epic.fortnite.founder.dialog.retailer.amazon',
              value: 'amazonDePs4',
              link: 'https://www.amazon.de/dp/B071GNYJKK/ref=sr_1_1?ie=UTF8&qid=1497862222&sr=8-1&keywords=fortnite&th=1:w',
            },
          },
        },
        xboxRetailDe: {
          title: 'epic.fortnite.founder.dialog.platform.xbox',
          value: 'xboxRetailDe',
          defaultValue: 'amazonDeXb',
          retailers: {
            amazonDeXb: {
              title: 'epic.fortnite.founder.dialog.retailer.amazon',
              value: 'amazonDeXb',
              link: 'https://www.amazon.de/dp/B0721N3XTS/ref=sr_1_1?ie=UTF8&qid=1497862222&sr=8-1&keywords=fortnite&th=1',
            },
          },
        },
        pcRetailDe: {
          title: 'epic.fortnite.founder.dialog.platform.pc',
          value: 'pcRetailDe',
          defaultValue: 'amazonDePc',
          retailers: {
            amazonDePc: {
              title: 'epic.fortnite.founder.dialog.retailer.amazon',
              value: 'amazonDePc',
              link: 'https://www.amazon.de/dp/B071GNYTG6/ref=sr_1_1?ie=UTF8&qid=1497862222&sr=8-1&keywords=fortnite&th=1',
            },
          },
        },
      },
    },
    at: {
      title: 'epic.fortnite.founder.dialog.territory.at',
      value: 'at',
      countries: ['AT'],
      defaultValue: 'ps4RetailAt',
      platforms: {
        ps4RetailAt: {
          title: 'epic.fortnite.founder.dialog.platform.ps4',
          value: 'ps4RetailAt',
          defaultValue: 'gamewarePs4',
          retailers: {
            gamewarePs4: {
              title: 'epic.fortnite.founder.dialog.retailer.gameware',
              value: 'gamewarePs4',
              link: 'http://www.gameware.at/info/spaces/gameware/gamewareSearch?query=Fortnite+ps4&actionTag=search',
            },
            gamestop: {
              title: 'epic.fortnite.founder.dialog.retailer.gamestop',
              value: 'gamestop',
              link: 'https://www.gamestop.at/PS4/Games/145968/fortnite',
            },
          },
        },
        xboxRetailAt: {
          title: 'epic.fortnite.founder.dialog.platform.xbox',
          value: 'xboxRetailAt',
          defaultValue: 'gamewareXb',
          retailers: {
            gamewareXb: {
              title: 'epic.fortnite.founder.dialog.retailer.gameware',
              value: 'gamewareXb',
              link: 'http://www.gameware.at/info/spaces/gameware/gamewareSearch?query=Fortnite+Xbox&actionTag=search',
            },
            gamestop: {
              title: 'epic.fortnite.founder.dialog.retailer.gamestop',
              value: 'gamestop',
              link: 'https://www.gamestop.at/Xbox%20One/Games/145967/fortnite',
            },
          },
        },
        pcRetailAt: {
          title: 'epic.fortnite.founder.dialog.platform.pc',
          value: 'pcRetailAt',
          defaultValue: 'gamewarePc',
          retailers: {
            gamewarePc: {
              title: 'epic.fortnite.founder.dialog.retailer.gameware',
              value: 'gamewarePc',
              link: 'http://www.gameware.at/info/spaces/gameware/gamewareSearch?query=Fortnite+PC&actionTag=search',
            },
            gamestop: {
              title: 'epic.fortnite.founder.dialog.retailer.gamestop',
              value: 'gamestop',
              link: 'https://www.gamestop.at/PC/Games/145969/fortnite',
            },
          },
        },
      },
    },
    ch: {
      title: 'epic.fortnite.founder.dialog.territory.ch',
      value: 'ch',
      countries: ['CH'],
      defaultValue: 'ps4RetailCh',
      platforms: {
        ps4RetailCh: {
          title: 'epic.fortnite.founder.dialog.platform.ps4',
          value: 'ps4RetailCh',
          defaultValue: 'worldOfGamesPs4',
          retailers: {
            worldOfGamesPs4: {
              title: 'epic.fortnite.founder.dialog.retailer.worldOfGames',
              value: 'worldOfGamesPs4',
              link: 'https://www.wog.ch/index.cfm/details/product/58321%2DFortnite',
            },
            gamestop: {
              title: 'epic.fortnite.founder.dialog.retailer.gamestop',
              value: 'gamestop',
              link: 'https://www.gamestop.ch/PS4/Games/59581/fortnite',
            },
          },
        },
        xboxRetailCh: {
          title: 'epic.fortnite.founder.dialog.platform.xbox',
          value: 'xboxRetailCh',
          defaultValue: 'worldOfGamesXb',
          retailers: {
            worldOfGamesXb: {
              title: 'epic.fortnite.founder.dialog.retailer.worldOfGames',
              value: 'worldOfGamesXb',
              link: 'https://www.wog.ch/index.cfm/details/product/58320%2DFortnite',
            },
            gamestop: {
              title: 'epic.fortnite.founder.dialog.retailer.gamestop',
              value: 'gamestop',
              link: 'https://www.gamestop.ch/Xbox%20One/Games/59580/fortnite',
            },
          },
        },
        pcRetailCh: {
          title: 'epic.fortnite.founder.dialog.platform.pc',
          value: 'pcRetailCh',
          defaultValue: 'worldOfGamesPc',
          retailers: {
            worldOfGamesPc: {
              title: 'epic.fortnite.founder.dialog.retailer.worldOfGames',
              value: 'worldOfGamesPc',
              link: 'https://www.wog.ch/index.cfm/details/product/58322%2DFortnite',
            },
            gamestop: {
              title: 'epic.fortnite.founder.dialog.retailer.gamestop',
              value: 'gamestop',
              link: 'https://www.gamestop.ch/PC/Games/59582/fortnite',
            },
          },
        },
      },
    },
    it: {
      title: 'epic.fortnite.founder.dialog.territory.it',
      value: 'it',
      countries: ['IT'],
      defaultValue: 'ps4',
      platforms: {
        ps4: {
          title: 'epic.fortnite.founder.dialog.platform.ps4',
          value: 'ps4',
          defaultValue: 'gamestop',
          retailers: {
            gamestop: {
              title: 'epic.fortnite.founder.dialog.retailer.gamestop',
              value: 'gamestop',
              link: 'https://www.gamestop.it/PS4/Games/105842/fortnite',
            },
          },
        },
        xbox: {
          title: 'epic.fortnite.founder.dialog.platform.xbox',
          value: 'xbox',
          defaultValue: 'gamestop',
          retailers: {
            gamestop: {
              title: 'epic.fortnite.founder.dialog.retailer.gamestop',
              value: 'gamestop',
              link: 'https://www.gamestop.it/XboxONE/Games/105843/fortnite',
            },
          },
        },
        pc: {
          title: 'epic.fortnite.founder.dialog.platform.pc',
          value: 'pc',
          defaultValue: 'gamestop',
          retailers: {
            gamestop: {
              title: 'epic.fortnite.founder.dialog.retailer.gamestop',
              value: 'gamestop',
              link: 'https://www.gamestop.it/PC/Games/105844/fortnite',
            },
            amazon: {
              title: 'epic.fortnite.founder.dialog.retailer.amazon',
              value: 'amazon',
              link: 'https://www.amazon.it/Gearbox-Fortnite/dp/B071KCFHGR',
            },
          },
        },
      },
    },
    es: {
      title: 'epic.fortnite.founder.dialog.territory.es',
      value: 'es',
      countries: ['ES'],
      defaultValue: 'pc',
      platforms: {
        pc: {
          title: 'epic.fortnite.founder.dialog.platform.pc',
          value: 'pc',
          defaultValue: 'amazon',
          retailers: {
            amazon: {
              title: 'epic.fortnite.founder.dialog.retailer.amazon',
              value: 'amazon',
              link: 'https://www.amazon.es/Deep-Silver-Fortnite/dp/B071KCFHGR',
            },
          },
        },
      },
    },
    ie: {
      title: 'epic.fortnite.founder.dialog.territory.ie',
      value: 'ie',
      countries: ['IE'],
      defaultValue: 'ps4',
      platforms: {
        ps4: {
          title: 'epic.fortnite.founder.dialog.platform.ps4',
          value: 'ps4',
          defaultValue: 'gamestop',
          retailers: {
            gamestop: {
              title: 'epic.fortnite.founder.dialog.retailer.gamestop',
              value: 'gamestop',
              link: 'https://www.gamestop.ie/Playstation%204/Games/64610/fortnite',
            },
          },
        },
        xbox: {
          title: 'epic.fortnite.founder.dialog.platform.xbox',
          value: 'xbox',
          defaultValue: 'gamestop',
          retailers: {
            gamestop: {
              title: 'epic.fortnite.founder.dialog.retailer.gamestop',
              value: 'gamestop',
              link: 'https://www.gamestop.ie/Xbox%20One/Games/64611/fortnite',
            },
          },
        },
        pc: {
          title: 'epic.fortnite.founder.dialog.platform.pc',
          value: 'pc',
          defaultValue: 'gamestop',
          retailers: {
            gamestop: {
              title: 'epic.fortnite.founder.dialog.retailer.gamestop',
              value: 'gamestop',
              link: 'https://www.gamestop.ie/PC/Games/64612/fortnite',
            },
          },
        },
      },
    },
    se: {
      title: 'epic.fortnite.founder.dialog.territory.se',
      value: 'se',
      countries: ['SE'],
      defaultValue: 'ps4',
      platforms: {
        ps4: {
          title: 'epic.fortnite.founder.dialog.platform.ps4',
          value: 'ps4',
          defaultValue: 'gamestop',
          retailers: {
            gamestop: {
              title: 'epic.fortnite.founder.dialog.retailer.gamestop',
              value: 'gamestop',
              link: 'https://www.gamestop.se/PlayStation%204/Games/76804/fortnite',
            },
            discshop: {
              title: 'epic.fortnite.founder.dialog.retailer.discshop',
              value: 'discshop',
              link: 'https://www.discshop.se/spel/playstation_4/fortnite/P152452',
            },
            webhallen: {
              title: 'epic.fortnite.founder.dialog.retailer.webhallen',
              value: 'webhallen',
              link: 'https://www.webhallen.com/se-sv/spel/playstation_4/270841-fortnite',
            },
            cdon: {
              title: 'epic.fortnite.founder.dialog.retailer.cdon',
              value: 'cdon',
              link: 'http://cdon.se/spel/fortnite-40604175',
            },
          },
        },
        xbox: {
          title: 'epic.fortnite.founder.dialog.platform.xbox',
          value: 'xbox',
          defaultValue: 'gamestop',
          retailers: {
            gamestop: {
              title: 'epic.fortnite.founder.dialog.retailer.gamestop',
              value: 'gamestop',
              link: 'https://www.gamestop.se/Xbox%20One/Games/76805/fortnite',
            },
            discshop: {
              title: 'epic.fortnite.founder.dialog.retailer.discshop',
              value: 'discshop',
              link: 'https://www.discshop.se/spel/xbox_one/fortnite/P152453',
            },
            webhallen: {
              title: 'epic.fortnite.founder.dialog.retailer.webhallen',
              value: 'webhallen',
              link: 'https://www.webhallen.com/se-sv/spel/xbox_one/270842-fortnite',
            },
            cdon: {
              title: 'epic.fortnite.founder.dialog.retailer.cdon',
              value: 'cdon',
              link: 'http://cdon.se/spel/fortnite-40604181',
            },
          },
        },
        pc: {
          title: 'epic.fortnite.founder.dialog.platform.pc',
          value: 'pc',
          defaultValue: 'gamestop',
          retailers: {
            gamestop: {
              title: 'epic.fortnite.founder.dialog.retailer.gamestop',
              value: 'gamestop',
              link: 'https://www.gamestop.se/PC/Games/76806/fortnite',
            },
            discshop: {
              title: 'epic.fortnite.founder.dialog.retailer.discshop',
              value: 'discshop',
              link: 'https://www.discshop.se/spel/pc/fortnite/P152454',
            },
            webhallen: {
              title: 'epic.fortnite.founder.dialog.retailer.webhallen',
              value: 'webhallen',
              link: 'https://www.webhallen.com/se-sv/spel/pc/270843-fortnite',
            },
            cdon: {
              title: 'epic.fortnite.founder.dialog.retailer.cdon',
              value: 'cdon',
              link: 'http://cdon.se/spel/fortnite-40604190',
            },
          },
        },
      },
    },
    fi: {
      title: 'epic.fortnite.founder.dialog.territory.fi',
      value: 'fi',
      countries: ['FI'],
      defaultValue: 'ps4',
      platforms: {
        ps4: {
          title: 'epic.fortnite.founder.dialog.platform.ps4',
          value: 'ps4',
          defaultValue: 'gamestop',
          retailers: {
            gamestop: {
              title: 'epic.fortnite.founder.dialog.retailer.gamestop',
              value: 'gamestop',
              link: 'https://www.gamestop.fi/PlayStation%204/Games/91536/fortnite',
            },
            discshop: {
              title: 'epic.fortnite.founder.dialog.retailer.discshop',
              value: 'discshop',
              link: 'https://www.discshop.fi/pelit/playstation_4/fortnite/P118729',
            },
            webhallen: {
              title: 'epic.fortnite.founder.dialog.retailer.webhallen',
              value: 'webhallen',
              link: 'https://www.webhallen.com/fi-fi/pelit/playstation_4/270841-fortnite',
            },
            cdon: {
              title: 'epic.fortnite.founder.dialog.retailer.cdon',
              value: 'cdon',
              link: 'http://cdon.fi/pelit/fortnite-40604175',
            },
          },
        },
        xbox: {
          title: 'epic.fortnite.founder.dialog.platform.xbox',
          value: 'xbox',
          defaultValue: 'gamestop',
          retailers: {
            gamestop: {
              title: 'epic.fortnite.founder.dialog.retailer.gamestop',
              value: 'gamestop',
              link: 'https://www.gamestop.fi/Xbox%20One/Games/91537/fortnite',
            },
            discshop: {
              title: 'epic.fortnite.founder.dialog.retailer.discshop',
              value: 'discshop',
              link: 'https://www.discshop.fi/pelit/xbox_one/fortnite/P118730',
            },
            webhallen: {
              title: 'epic.fortnite.founder.dialog.retailer.webhallen',
              value: 'webhallen',
              link: 'https://www.webhallen.com/fi-fi/pelit/xbox_one/270842-fortnite',
            },
            cdon: {
              title: 'epic.fortnite.founder.dialog.retailer.cdon',
              value: 'cdon',
              link: 'http://cdon.fi/pelit/fortnite-40604181',
            },
          },
        },
        pc: {
          title: 'epic.fortnite.founder.dialog.platform.pc',
          value: 'pc',
          defaultValue: 'gamestop',
          retailers: {
            gamestop: {
              title: 'epic.fortnite.founder.dialog.retailer.gamestop',
              value: 'gamestop',
              link: 'https://www.gamestop.fi/PC/Games/91538/fortnite',
            },
            discshop: {
              title: 'epic.fortnite.founder.dialog.retailer.discshop',
              value: 'discshop',
              link: 'https://www.discshop.fi/pelit/pc/fortnite/P118731',
            },
            webhallen: {
              title: 'epic.fortnite.founder.dialog.retailer.webhallen',
              value: 'webhallen',
              link: 'https://www.webhallen.com/fi-fi/pelit/pc/270843-fortnite',
            },
            cdon: {
              title: 'epic.fortnite.founder.dialog.retailer.cdon',
              value: 'cdon',
              link: 'http://cdon.fi/pelit/fortnite-40604190',
            },
          },
        },
      },
    },
    no: {
      title: 'epic.fortnite.founder.dialog.territory.no',
      value: 'no',
      countries: ['NO'],
      defaultValue: 'ps4',
      platforms: {
        ps4: {
          title: 'epic.fortnite.founder.dialog.platform.ps4',
          value: 'ps4',
          defaultValue: 'gamestop',
          retailers: {
            gamestop: {
              title: 'epic.fortnite.founder.dialog.retailer.gamestop',
              value: 'gamestop',
              link: 'https://www.gamestop.no/PlayStation%204/Games/80414/fortnite',
            },
            cdon: {
              title: 'epic.fortnite.founder.dialog.retailer.cdon',
              value: 'cdon',
              link: 'http://cdon.no/spill/fortnite-40604175',
            },
          },
        },
        xbox: {
          title: 'epic.fortnite.founder.dialog.platform.xbox',
          value: 'xbox',
          defaultValue: 'gamestop',
          retailers: {
            gamestop: {
              title: 'epic.fortnite.founder.dialog.retailer.gamestop',
              value: 'gamestop',
              link: 'https://www.gamestop.no/Xbox%20One/Games/80415/fortnite',
            },
            cdon: {
              title: 'epic.fortnite.founder.dialog.retailer.cdon',
              value: 'cdon',
              link: 'http://cdon.no/spill/fortnite-40604181',
            },
          },
        },
        pc: {
          title: 'epic.fortnite.founder.dialog.platform.pc',
          value: 'pc',
          defaultValue: 'gamestop',
          retailers: {
            gamestop: {
              title: 'epic.fortnite.founder.dialog.retailer.gamestop',
              value: 'gamestop',
              link: 'https://www.gamestop.no/PC/Games/80416/fortnite',
            },
            cdon: {
              title: 'epic.fortnite.founder.dialog.retailer.cdon',
              value: 'cdon',
              link: 'http://cdon.no/spill/fortnite-40604190',
            },
          },
        },
      },
    },
    dk: {
      title: 'epic.fortnite.founder.dialog.territory.dk',
      value: 'dk',
      countries: ['DK'],
      defaultValue: 'ps4',
      platforms: {
        ps4: {
          title: 'epic.fortnite.founder.dialog.platform.ps4',
          value: 'ps4',
          defaultValue: 'gamestop',
          retailers: {
            gamestop: {
              title: 'epic.fortnite.founder.dialog.retailer.gamestop',
              value: 'gamestop',
              link: 'https://www.gamestop.dk/PlayStation%204/Games/76094/fortnite',
            },
            webhallen: {
              title: 'epic.fortnite.founder.dialog.retailer.webhallen',
              value: 'webhallen',
              link: 'https://www.webhallen.com/dk-da/spil/playstation_4/270841-fortnite',
            },
            cdon: {
              title: 'epic.fortnite.founder.dialog.retailer.cdon',
              value: 'cdon',
              link: 'http://cdon.dk/spil/fortnite-40604175',
            },
          },
        },
        xbox: {
          title: 'epic.fortnite.founder.dialog.platform.xbox',
          value: 'xbox',
          defaultValue: 'gamestop',
          retailers: {
            gamestop: {
              title: 'epic.fortnite.founder.dialog.retailer.gamestop',
              value: 'gamestop',
              link: 'https://www.gamestop.dk/Xbox%20One/Games/76095/fortnite',
            },
            webhallen: {
              title: 'epic.fortnite.founder.dialog.retailer.webhallen',
              value: 'webhallen',
              link: 'https://www.webhallen.com/dk-da/spil/xbox_one/270842-fortnite',
            },
            cdon: {
              title: 'epic.fortnite.founder.dialog.retailer.cdon',
              value: 'cdon',
              link: 'http://cdon.dk/spil/fortnite-40604181',
            },
          },
        },
        pc: {
          title: 'epic.fortnite.founder.dialog.platform.pc',
          value: 'pc',
          defaultValue: 'gamestop',
          retailers: {
            gamestop: {
              title: 'epic.fortnite.founder.dialog.retailer.gamestop',
              value: 'gamestop',
              link: 'https://www.gamestop.dk/PC/Games/76096/fortnite',
            },
            webhallen: {
              title: 'epic.fortnite.founder.dialog.retailer.webhallen',
              value: 'webhallen',
              link: 'https://www.webhallen.com/dk-da/spil/pc/270843-fortnite',
            },
            cdon: {
              title: 'epic.fortnite.founder.dialog.retailer.cdon',
              value: 'cdon',
              link: 'http://cdon.dk/spil/fortnite-40604190',
            },
          },
        },
      },
    },
    global: {
      title: 'epic.fortnite.founder.dialog.territory.global',
      value: 'global',
      countries: [],
      defaultValue: 'ps4',
      platforms: {
        ps4: {
          title: 'epic.fortnite.founder.dialog.platform.ps4',
          value: 'ps4',
          defaultValue: 'target',
          retailers: {
            target: {
              title: 'epic.fortnite.founder.dialog.retailer.target',
              value: 'target',
              link: 'https://intl.target.com/p/fortnite-playstation-4/-/A-52634875',
            },
          },
        },
        xbox: {
          title: 'epic.fortnite.founder.dialog.platform.xbox',
          value: 'xbox',
          defaultValue: 'target',
          retailers: {
            target: {
              title: 'epic.fortnite.founder.dialog.retailer.target',
              value: 'target',
              link: 'https://intl.target.com/p/fortnite-xbox-one/-/A-52634956',
            },
          },
        },
      },
    },
  },
};

const pc = {
  title: 'epic.fortnite.founder.dialog.platform.pc',
  value: 'pc',
  retailers: {
    epic,
  },
};

const ps4 = {
  title: 'epic.fortnite.founder.dialog.platform.ps4',
  value: 'ps4',
  defaultValue: 'psn',
  retailers: {
    psn,
  },
};

const ps5 = {
  title: 'epic.fortnite.founder.dialog.platform.ps5',
  value: 'ps5',
  defaultValue: 'psn',
  retailers: {
    psn,
  },
};

const xbox = {
  title: 'epic.fortnite.founder.dialog.platform.xbox',
  value: 'xbox',
  defaultValue: 'xbn',
  retailers: {
    xbn,
  },
};

const digital = {
  title: 'epic.fortnite.founder.dialog.step1.digital',
  defaultValue: 'americas',
  territories: {
    americas: {
      title: 'epic.fortnite.founder.dialog.territory.americas',
      value: 'americas',
      countries: ['US', 'CA'],
      defaultValue: 'pc',
      platforms: {
        pc,
        ps4,
        ps5,
        xbox,
      },
    },
    europe: {
      title: 'epic.fortnite.founder.dialog.territory.eu',
      value: 'europe',
      countries: [
        'DK',
        'FI',
        'HU',
        'IS',
        'IE',
        'NL',
        'SE',
        'GB',
        'DE',
        'FR',
        'IT',
        'GR',
        'PT',
        'ES',
        'PL',
        'ML',
      ],
      defaultValue: 'pc',
      platforms: {
        pc,
        ps4,
        ps5,
        xbox,
      },
    },
    ru: {
      title: 'epic.fortnite.founder.dialog.territory.ru',
      value: 'ru',
      countries: ['RU', 'AM', 'AZ', 'BY', 'GE', 'KZ', 'KG', 'MD', 'TM', 'TJ', 'UA', 'UZ'],
      defaultValue: 'pc',
      platforms: {
        pc,
        ps4,
        ps5,
        xbox,
      },
    },
    aus: {
      title: 'epic.fortnite.founder.dialog.territory.aus',
      value: 'aus',
      countries: ['AU', 'NZ'],
      defaultValue: 'pc',
      platforms: {
        pc,
        ps4,
        ps5,
        xbox,
      },
    },
    ko: {
      title: 'epic.fortnite.founder.dialog.territory.ko',
      value: 'ko',
      countries: ['KO'],
      defaultValue: 'pc',
      platforms: {
        pc,
        ps4,
        ps5,
        xbox,
      },
    },
    jp: {
      title: 'epic.fortnite.founder.dialog.territory.jp',
      value: 'jp',
      countries: ['JP'],
      defaultValue: 'pc',
      platforms: {
        pc,
        ps4,
        ps5,
        xbox,
      },
    },
    sea: {
      title: 'epic.fortnite.founder.dialog.territory.sea',
      value: 'sea',
      countries: ['ID', 'MY', 'PH', 'SG', 'TH'],
      defaultValue: 'pc',
      platforms: {
        pc,
        ps4,
        ps5,
        xbox,
      },
    },
  },
};

const packOptionConfig = {
  defaultVersion: 'digital',
  defaultTerritory: 'americas',
  defaultPlatform: 'pc',
  defaultRetailer: 'epic',
  defaultValue: 'digital',
  versions: {
    digital,
    retail,
  },
};

export function getVersions() {
  return packOptionConfig.versions;
}

export function getTerritories(version) {
  if (!version) return null;
  return getVersions()[version].territories;
}

export function getPlatforms(version, territory) {
  if (!territory) return null;
  const optionTerritory = getTerritories(version);
  return optionTerritory ? optionTerritory[territory].platforms : null;
}

export function getRetailers(version, territory, platform) {
  if (!platform) return null;
  const optionPlatform = getPlatforms(version, territory);
  return optionPlatform ? optionPlatform[platform].retailers : null;
}

export function getDefaultRetailer(version, territory, platform) {
  const retailers = getRetailers(version, territory, platform);
  const retailOptions = Object.keys(retailers);
  if (retailOptions.length > 1) return null;
  return retailers[retailOptions[0]].value;
}

export function getOption(version, territory, platform, retailer) {
  const selectedRetailer = retailer || getDefaultRetailer(version, territory, platform);
  if (!selectedRetailer) return null;
  return getRetailers(version, territory, platform)[selectedRetailer];
}

export function getDefault(version, territory, platform, retailer, type) {
  let defaultedValue = null;
  switch (type) {
    case 'version':
      defaultedValue = packOptionConfig.defaultVersion;
      break;
    case 'territory':
      defaultedValue = getVersions()[version].defaultValue;
      break;
    case 'platform':
      defaultedValue = getTerritories(version)[territory].defaultValue;
      break;
    case 'retailer':
      defaultedValue = getPlatforms(version, territory)[platform].defaultValue;
      break;
    default:
      break;
  }
  return defaultedValue;
}

export function getTerritoryFromCountry(version, country) {
  const packVersions = version
    ? packOptionConfig.versions[version]
    : packOptionConfig.versions[packOptionConfig.defaultVersion];
  if (!country) return packVersions.defaultTerritory;
  for (const territoryId in packVersions.territories) {
    if (!Object.prototype.hasOwnProperty.call(packVersions.territories, territoryId)) continue;
    const territory = packVersions.territories[territoryId];
    if (territory.countries && territory.countries.indexOf(country) >= 0) {
      return territoryId;
    }
  }
  return packOptionConfig.defaultTerritory;
}
