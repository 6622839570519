import axios from 'axios';
import root from 'window-or-global';

import AppConfigActions from '../actions/AppConfigActions';
import Url from '../constants/Url';

/**
 * Get epicSid
 */
const AppConfigSource = {
  fetchEpicSid: {
    remote(state) {
      //the store state is accessible here
      return axios.get(Url().DOWNLOAD.EPIC_SID);
    },
    //On success call the following action:
    success: AppConfigActions.fetchEpicSidSuccess,
    //On failure call the following action:
    error: AppConfigActions.fetchEpicSidFailure,
    shouldFetch() {
      return !root.__server_side_render;
    },
  },
};

export default AppConfigSource;
