import React from 'react';
import {HttpErrorScreen, LoadScreen} from 'epic-fortnite-react';
import {withRouter, RouteComponentProps} from 'react-router';
import {
  useCmsData,
  PageData,
  getInitialCmsPageSharedValues,
  URLPatternBuilder,
} from 'epic-fortnite-shared-values';
import EventDetailsBaseplate from '../../components/baseplate/EventDetailsBaseplate';
import ArchivedEventBaseplate from '../../components/baseplate/ArchivedEventBaseplate';
import MetaTags from '../../components/common/MetaTags';

interface CmsDataProps extends PageData {
  eventDetailsConfig: EventDetailsConfigProps;
  sections: any;
  isCondensed: boolean;
}

interface EventDetailsConfigProps {
  isArchived: boolean;
  isInEventsList: boolean;
  tags: string[];
}

interface EventDetailsParams {
  eventName: string;
}

type EventDetailsProps = RouteComponentProps<EventDetailsParams>;

const EventDetailsView = ({match}: EventDetailsProps) => {
  const {pageData, isLoading, error} = useCmsData<CmsDataProps>(
    `events/${match?.params?.eventName}`
  );

  if (isLoading) {
    return <LoadScreen show />;
  }

  if (error) {
    return <HttpErrorScreen statusCode={error.response?.status ?? 500} />;
  }

  return (
    <div>
      <MetaTags pageModel={pageData} />
      {pageData?.eventDetailsConfig?.isArchived ? (
        <ArchivedEventBaseplate blocks={pageData?.sections?.blocks} />
      ) : (
        <EventDetailsBaseplate blocks={pageData?.sections?.blocks} />
      )}
    </div>
  );
};

const slugBuilder: URLPatternBuilder = (match: EventDetailsProps['match']) => {
  const slug = match.params.eventName;
  return `events/${slug}`;
};

EventDetailsView.getInitialSharedValues = getInitialCmsPageSharedValues(slugBuilder);

export default withRouter(EventDetailsView);
