import PropTypes from 'prop-types';
import React from 'react';

import RetryIndicator from './RetryIndicator';

const RetryScreen = ({show, retry}) => {
  if (show) {
    return (
      <div className="loadscreen-overlay">
        <RetryIndicator retry={retry} />
      </div>
    );
  }
  return <div />;
};

RetryScreen.propTypes = {
  show: PropTypes.bool,
  retry: PropTypes.func,
};

export default RetryScreen;
