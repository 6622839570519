import epicTracking from 'epic-tracking';
import React, {useState} from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import styled from 'styled-components';
import {Device} from '../../../constants';
import {motion} from 'framer-motion';
import {Container, Stack, Text, useBreakpoints} from 'epic-fortnite-react';
import FeatureCarousel from './FeatureCarousel';

import {CmsData} from './Types';
import {FontSizes} from 'epic-fortnite-react/types/theme';

interface BattlePassBadgeProps {
  image: string;
  imageAlt: string;
}

interface FeatureHeroCarouselProps {
  data: CmsData;
  mobile: boolean;
  tablet: boolean;
}

const FeatureHeroCarouselWrapper = styled.section<{seasonKey?: string; bgImage?: string}>`
  position: relative;
  z-index: 2;
`;

const FeatureHeroCarouselBadge = styled(({...rest}) => <motion.img {...rest} />)`
  display: block;
  height: 10rem;
  position: absolute;
  right: 2rem;
  top: 4vw;
  transform: translateY(-50%) !important;
  width: auto;
  z-index: 1;

  @media screen and ${Device.tablet} {
    height: 7.5rem;
  }
`;

const StyledHeroFlexContainer = styled.div<{bkImg?: string | null}>`
  display: flex;
  flex-direction: column;

  ${(props) =>
    props.bkImg
      ? `
    background-image: url(${props.bkImg});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    clip-path: polygon(0% 0%, 0% 100%, 100% 100%, 100% 4vw);
    padding-top: 16rem;
    margin-top: -4vw;
  `
      : null}
`;

const BattlePassBadge = ({image, imageAlt}: BattlePassBadgeProps) => {
  const [visible, setVisibility] = React.useState(false);
  return (
    <VisibilitySensor
      active={!visible}
      onChange={(visibility) => setVisibility(visibility)}
      partialVisibility>
      <FeatureHeroCarouselBadge
        initial={{opacity: 0, y: 100}}
        animate={visible ? {opacity: 1, y: 0} : {opacity: 0, y: 100}}
        transition={{ease: 'easeInOut', duration: 1, delay: 0.1}}
        src={image}
        alt={imageAlt}
      />
    </VisibilitySensor>
  );
};

const FeatureHeroCarousel = ({data, mobile, tablet}: FeatureHeroCarouselProps) => {
  const [activeSlide, setActiveSlide] = React.useState(0);
  const [visible, setVisibility] = useState(false);
  const headerSize = useBreakpoints<FontSizes>('2xl', {768: '4xl'});
  const {
    title,
    items,
    subtitle,
    badge,
    badgeAlt,
    seasonKey,
    mainHeroBackgroundImg,
    mobileHeroBackgroundImg,
  } = data;

  // const spike = items && items[activeSlide]?.spike;
  // const spikeRight = items && items[activeSlide]?.spikeRight;

  const setSectionVisibility = React.useCallback(
    (visibility) => {
      setVisibility(visibility);
      if (visibility) {
        epicTracking.trackEvent('Interaction', {
          interactionType: 'scrollViewSection',
          eventCategory: 'BattlePass Feature Hero',
          eventAction: 'featureHero-inView',
        });
      }
    },
    [setVisibility]
  );

  const setCurrentSlide = React.useCallback(
    (activeSlide) => {
      setActiveSlide(activeSlide);
    },
    [setActiveSlide]
  );

  //hides section if cms content isn't there
  if (!items || items.length === 0) return null;

  return (
    <FeatureHeroCarouselWrapper seasonKey={seasonKey}>
      <BattlePassBadge image={badge} imageAlt={badgeAlt} />
      <StyledHeroFlexContainer
        bkImg={mobile ? mobileHeroBackgroundImg : mainHeroBackgroundImg || null}>
        <VisibilitySensor
          active={!visible}
          onChange={(visibility) => setVisibility(visibility)}
          partialVisibility>
          <Container size="med">
            <Stack align="center" gap="lg">
              <Text as="h2" color="#fff" align="center" italic={true} size={headerSize.value}>
                {title}
              </Text>
              <Text as="h3" color="#fff" align="center" italic={true} size="xl">
                {subtitle}
              </Text>
            </Stack>
          </Container>
        </VisibilitySensor>
        <VisibilitySensor onChange={setSectionVisibility} partialVisibility minTopValue={0}>
          <FeatureCarousel
            seasonKey={seasonKey}
            mobile={mobile}
            tablet={tablet}
            isVisible={visible}
            activeSlide={activeSlide}
            setCurrentSlide={setCurrentSlide}
            items={items}
          />
        </VisibilitySensor>
      </StyledHeroFlexContainer>
    </FeatureHeroCarouselWrapper>
  );
};

export default FeatureHeroCarousel;
