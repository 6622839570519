import {getLocale} from '@epic-core/common';
import classnames from 'classnames';
import {generateRouteTo} from 'epic-fortnite-react';
import epicTracking from 'epic-tracking';
import PropTypes from 'prop-types';
import React from 'react';
import {withRouter} from 'react-router';
import root from 'window-or-global';

/**
 * CMS Content type => Fortnite - Common CTA Config
 */
class CommonCTABtn extends React.PureComponent {
  static propTypes = {
    ctaConfig: PropTypes.object.isRequired,
    className: PropTypes.string,
    history: PropTypes.object,
    id: PropTypes.string,
  };

  redirectInternalUrl = (e, path, locale) => {
    const {location} = this.props;
    epicTracking.trackEvent('Interaction', {
      interactionType: 'click',
      eventCategory: 'cta-common-button',
      eventAction: 'cta-action',
      eventLabel: e.currentTarget.id,
    });
    const {history} = this.props;
    history.push(generateRouteTo(path, location, locale));
  };

  render() {
    const locale = getLocale();

    let res = null;
    const {id} = this.props;
    if (!this.props.ctaConfig) {
      if (!root.document) {
        // Server side error message.
        console.error('CommonCTABtn passed undefined ctaConfig');
      }
      return null;
    }
    const {isCtaLinkExternal, ctaString, ctaLink, disabled, buttonTheme} = this.props.ctaConfig;
    const ctaClass = classnames(
      'btn btn-primary',
      [this.props.className],
      {invisible: !ctaString},
      {disabled},
      buttonTheme || ''
    );
    if (isCtaLinkExternal) {
      res = (
        <a className={ctaClass} href={ctaLink} target="_blank" rel="noopener noreferrer">
          <span>{ctaString}</span>
        </a>
      );
    } else {
      res = (
        <button
          id={id}
          className={ctaClass}
          onClick={(e) => this.redirectInternalUrl(e, ctaLink, locale)}>
          <span>{ctaString}</span>
        </button>
      );
    }
    return res;
  }
}

export default withRouter(CommonCTABtn);
