import PropTypes from 'prop-types';
import React from 'react';

import Media from './Media';

const MediaComponent = ({autoPlay, data, onMediaLoad, onMediaLoadError}) => {
  // extended_entities override, these are multi images, videos, gifs
  if (data.extended_entities && data.extended_entities.media) {
    return (
      <Media
        autoPlay={autoPlay}
        media={data.extended_entities.media}
        onMediaLoad={onMediaLoad}
        onMediaLoadError={onMediaLoadError}
      />
    );
  }

  // use Media component if media entities exist
  if (data.entities && data.entities.media) {
    return (
      <Media
        autoPlay={autoPlay}
        media={data.entities.media}
        onMediaLoad={onMediaLoad}
        onMediaLoadError={onMediaLoadError}
      />
    );
  }

  return null;
};

MediaComponent.propTypes = {
  autoPlay: PropTypes.bool,
  data: PropTypes.object,
  onMediaLoad: PropTypes.func,
  onMediaLoadError: PropTypes.func,
};

export default MediaComponent;
