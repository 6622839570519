import {Link as RouterLink} from 'react-router-dom';
import styled from 'styled-components';

const Link = styled(RouterLink)`
  width: 100%; // Fix Safari bug w/ random line breaks

  &&& {
    font-size: 1em;
    color: white;
  }
`;

export default Link;
