export type SortType = 'ASC' | 'DESC';
export type SocialLinkType =
    | 'YOUTUBE_CHANNEL'
    | 'TWITCH_CHANNEL'
    | 'FACEBOOK'
    | 'TWITTER'
    | 'WEBSITE_CHANNEL'
    | 'PODCAST_CHANNEL'
    | 'INSTAGRAM'
    | 'VK'
    | 'MIXER'
    | 'TIKTOK';
export type SocialLinkIdType = 'channel' | 'user' | 'group'; // may vary by social media (list can grow)
export type Status = 'INIT' | 'PENDING' | 'REJECTED' | 'ACTIVE' | 'DISABLED' | 'DELETED';

export interface Affiliate {
    country: string;
    note: string;
    guardianFirstName: string;
    lastModifiedAt: string;
    city: string;
    displayName: string;
    discordUserId: string;
    guardianRelationship: string;
    guardianLastName: string;
    createdAt: string;
    statusReason: string;
    statusReasonEnum: string;
    id: string;
    state: string;
    locked: boolean;
    slug: string;
    emailTemplate: string;
    verified: boolean;
    avatar: string;
    team: boolean;
    rvn: number;
    verificationCode: string;
    ageConfirmed: boolean;
    agreeContacted: boolean;
    contentTypes: string[];
    interests: string[];
    status: Status;
    notifyMe: boolean;
    payoutType: string;
    payoutSetupDueDate: string;
}

export interface AffiliatePaging {
    total: number;
    count: number;
    start: number;
}

export interface AffiliateReviewResult {
    statusReason: string;
    emailTemplate: string;
}

export interface GuardianDetails {
    guardianFirstName: string;
    guardianLastName: string;
    guardianRelationship: string;
}

export interface SocialLink {
    createdAt: string;
    followers: number;
    lastModifiedAt: string;
    id: string;
    rvn: number;
    affiliateId: string;
    socialLinkType: string;
    socialLink: string;
}

export interface ProductShareAmount {
    productId: string;
    totalShareAmount: number;
}

export interface EarningsReport {
    totalShareAmount: number;
    productShareAmountInfos: ProductShareAmount[];
    affiliateId: string;
}

export interface AffiliateReport {
    lastModifiedAt: string;
    productId: string;
    avgShareRate: number;
    rvn: number;
    affiliateId: string;
    shareAmount: number;
    createdAt: string;
    statusReason: string;
    reportDate: string;
    salesAmount: number;
    currency: string;
    id: string;
    chargeBackAmount: number;
    refundAmount: number;
    status: Status;
}

export enum ReportType {
    CURRENT = 'current',
    HOURLY = 'hourly',
    DAILY = 'daily',
    WEEKLY = 'weekly',
    MONTHLY = 'monthly',
    TOP = 'top',
    TOTAL = 'total'
}

export interface ShareRate {
    developerId: string;
    productId: string;
    campaignId: string;
    offerId: string;
    shareRateByEpic: number;
    shareRate: number;
    shareRateByDeveloper: number;
    affiliateId: string;
}

export interface RevenueShareRateRequest {
    productId: string;
    offerIds: string[];
}

export interface SocialLinkOptions {
    status?: Status;
    idType?: SocialLinkIdType;
}

export interface SocialLinkAffiliate {
    affiliateId: string;
    affiliateSlug: string;
    affiliateStatus: Status;
    affiliateVerified: boolean;
    socialLink: string;
    socialLinkType: SocialLinkType;
}

export interface Elements<D = undefined> {
    elements: D[];
    paging: AffiliatePaging;
}
