import {Text} from 'epic-fortnite-react';
import React from 'react';
import styled from 'styled-components';
import {Payload} from '../../utils/eventTracking';

interface CardSectionProps {
  cardBackgroundColor?: string;
  textColor?: string;
  cards: SimpleCard[];
}

interface SimpleCard {
  image?: string;
  title?: string;
  body?: string;
  href?: string;
  eventTracking: Payload;
  cardBackgroundColor?: string;
  color?: string;
}

const StyledSimpleCard = styled.div`
  overflow: hidden;

  &:hover {
    img {
      transform: scale(1.05);
    }
  }

  &:focus {
    box-shadow: 0 0 0 4px #1db8f3;
  }
`;

const StyledSimpleCardImage = styled.img`
  transition: transform 0.2s ease-out;
  width: 100%;
`;

const StyledCardContent = styled.div<{bgColor?: string}>`
  background-color: ${({bgColor}) => bgColor ?? '#fff'};
  padding: 24px;
  position: relative;
  z-index: 1;
`;

const SimpleCard = ({
  image,
  title,
  body,
  href,
  eventTracking,
  cardBackgroundColor,
  color,
}: SimpleCard) => (
  <StyledSimpleCard>
    {image && <StyledSimpleCardImage src={image} alt="" />}
    <StyledCardContent bgColor={cardBackgroundColor}>
      {title && (
        <Text as="h2" color={color} size="lg">
          {title}
        </Text>
      )}
      {body && (
        <Text as="p" color={color}>
          {body}
        </Text>
      )}
    </StyledCardContent>
  </StyledSimpleCard>
);

const StyledSimpleCardSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media (min-width: 600px) {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  }

  @media (min-width: 1200px) {
    display: flex;
    flex-direction: row;
  }
`;

const SimpleDisplayCardSection = ({cardBackgroundColor, cards, textColor}: CardSectionProps) => (
  <StyledSimpleCardSection>
    {cards.map((card, index) => (
      <SimpleCard
        key={index}
        {...card}
        cardBackgroundColor={cardBackgroundColor}
        color={textColor}
      />
    ))}
  </StyledSimpleCardSection>
);

export default SimpleDisplayCardSection;
