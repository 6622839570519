import styled from 'styled-components';

import {breakpoints} from '../../../../constants/Breakpoints';

const PaginationWrap = styled.div`
  min-height: 150px;
  .btn-wrapper {
    margin: 40px 0;
    padding: 40px 0 20px;
    text-align: center;
  }
  a.pagination-btn {
    font-family: 'BurbankBigRegular-Black', OpenSans, sans-serif;
    font-size: 3rem;
    color: #1db8f3;
    padding: 6px 10px;
    text-align: center;
    margin: 0.25em;
    &:focus,
    &:active,
    &.active,
    &:hover {
      padding: 6px 10px;
      margin: 0.25em;
    }
    &.arrow {
      padding: 6px 8px;
      i:before {
        font-size: 2.5rem;
      }
    }

    &.active {
      color: black;
    }
  }
  .bottom-spacer {
    margin-bottom: 50px;
  }
  .ellipsis {
    color: #1db8f3;
    padding: 6px 10px;
    margin: 0;
    opacity: 0.4;
  }

  @media ${breakpoints.mobileL} {
    a.pagination-btn {
      font-size: 2rem;
      margin: 0;
      &.arrow {
        i:before {
          font-size: 1.5rem;
        }
      }
    }
  }
`;

export default PaginationWrap;
