import {css} from 'styled-components';

import {Device} from '../../constants';

export const ruCss = css`
  font-size: 3em;
  @media screen and ${Device.tablet} {
    font-size: 5vw !important;
  }
  @media screen and ${Device.mobileL} {
    font-size: 6vw !important;
  }
`;
