import {connectToStores} from 'epic-alt-utils';
import HtmlTemplate from 'epic-fortnite-react/components/common/HtmlTemplate';
import Error404 from 'epic-fortnite-react/components/error/Error404';
import PropTypes from 'prop-types';
import React from 'react';
import {withRouter} from 'react-router';

import PageActions from '../../actions/PageActions';
import MetaTags from '../../components/common/MetaTags';
import LoadScreen from '../../components/loadScreen/LoadScreen';
import RetryScreen from '../../components/loadScreen/RetryScreen';
import {PageStore} from '../../stores';

/**
 * Simple static content page.
 */
@connectToStores
class SimpleView extends React.Component {
  static propTypes = {
    match: PropTypes.object,
    pageStore: PropTypes.object,
    slug: PropTypes.string,
  };

  static getStores() {
    return [PageStore];
  }

  static getPropsFromStores() {
    return {
      pageStore: PageStore.getState(),
    };
  }

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidUpdate(prevProps, prevState) {
    const prevSlug = this.getPageSlug(prevProps);
    const currentSlug = this.getPageSlug();
    if (prevSlug !== currentSlug) {
      const currentPage = this.getActivePage();
      if (currentPage.loading === 'UNKNOWN') {
        PageActions.getPage(this.getPageSlug());
      }
    }
  }

  componentDidMount() {
    const currentPage = this.getActivePage();
    if (currentPage.loading === 'UNKNOWN') {
      PageActions.getPage(this.getPageSlug());
    }
  }

  retry = () => PageActions.getPage(this.getPageSlug());

  getActivePage() {
    const pageSlug = this.getPageSlug();
    const simplePages = this.props.pageStore.pages;
    if (!simplePages) {
      // Page store can be set to null on a network error
      // Where the client loaded but the ajax call failed.
      return {loading: 'FAILURE'};
    }
    if (simplePages[pageSlug] === null) {
      // Page not found.
      return {
        loading: 'NOTFOUND',
      };
    }
    return simplePages[pageSlug] || {loading: 'UNKNOWN'};
  }

  getPageSlug(props) {
    const prop = props || this.props;
    const activeSlug = prop.match.params.slug;
    const parentSlug = prop.match.params.parent ? `${prop.match.params.parent}/` : '';
    return `${parentSlug}${activeSlug}`;
  }

  render() {
    const pageSlug = this.getPageSlug();
    const simplePage = this.getActivePage();
    const content = simplePage && simplePage.content;
    if (this.props.pageStore.loading) {
      return <LoadScreen show={true} />;
    }
    if (simplePage.loading === 'FAILURE') {
      return <RetryScreen show={true} retry={this.retry} />;
    }
    if (!content || simplePage.loading === 'UNKNOWN') {
      return <Error404 />;
    }
    const {
      metaTags,
      textTheme: {themes, textColor, contentBackgroundColor},
      backgroundTheme: {disableBackground, backgroundColor, background},
    } = simplePage;
    const activeSlugClass = pageSlug.replace(/[^a-z0-9-]/i, '-');
    const classes = ['simpleView', activeSlugClass, ...(themes || '').split(',')].join(' ');
    const style = {
      backgroundColor: 'white',
      color: 'black',
    };
    const contentStyle = {
      backgroundColor: 'white',
    };
    if (contentBackgroundColor) {
      contentStyle.backgroundColor = contentBackgroundColor;
    }
    if (background && !disableBackground) {
      style.backgroundImage = `url(${background})`;
    }
    if (backgroundColor) {
      style.backgroundColor = backgroundColor;
    }
    if (textColor) {
      style.color = textColor;
    }
    return (
      <>
        <MetaTags meta={metaTags} />
        <div className={classes} style={style}>
          <HtmlTemplate
            className="content"
            style={contentStyle}
            html={simplePage.content}
            Tag="div"
          />
        </div>
      </>
    );
  }
}

export default withRouter(SimpleView);
