import React from 'react';

import {Mask} from '../types';

import ExploreSection from './components/ExploreSection';
import FullwidthExplore from './components/FullwidthExplore';
import SideBySideExplore from './components/SideBySideExplore';

export interface ExploreMasksSectionProps {
  accent: string;
  masks: Mask[];
  layout?: string;
}

const buildSectionsSpaced = (masks: Mask[], accent: string) => {
  switch (masks.length) {
    case 1:
      return [<FullwidthExplore key="only" accent={accent} {...masks[0]} />];
    case 2:
      return [
        <FullwidthExplore key="first" accent={accent} {...masks[0]} />,
        <FullwidthExplore key="second" alternateOrder accent={accent} {...masks[1]} />,
      ];
    case 3:
      return [
        <FullwidthExplore key="first" accent={accent} {...masks[0]} />,
        <SideBySideExplore
          key="second"
          alternateOrder
          accent={accent}
          masks={[masks[1], masks[2]]}
        />,
      ];
    case 4:
      return [
        <FullwidthExplore key="first" accent={accent} {...masks[0]} />,
        <FullwidthExplore key="second" alternateOrder accent={accent} {...masks[1]} />,
        <SideBySideExplore key="third" accent={accent} masks={[masks[2], masks[3]]} />,
      ];
    case 5:
      return [
        <FullwidthExplore key="first" accent={accent} {...masks[0]} />,
        <SideBySideExplore
          key="second"
          alternateOrder
          accent={accent}
          masks={[masks[1], masks[2]]}
        />,
        <SideBySideExplore
          key="third"
          alternateOrder
          accent={accent}
          masks={[masks[3], masks[4]]}
        />,
      ];
    case 6:
      return [
        <FullwidthExplore key="first" accent={accent} {...masks[0]} />,
        <SideBySideExplore
          key="second"
          alternateOrder
          accent={accent}
          masks={[masks[1], masks[2]]}
        />,
        <FullwidthExplore key="third" alternateOrder accent={accent} {...masks[3]} />,
        <SideBySideExplore key="four" accent={accent} masks={[masks[4], masks[5]]} />,
      ];
    default:
      return [];
  }
};

const buildSectionsTight = (masks: Mask[], accent: string) => {
  const sections: JSX.Element[] = [];
  if (masks.length % 2 === 0) {
    for (let i = 0; i < masks.length; i += 2) {
      sections.push(
        <SideBySideExplore
          key={`${masks[i].name}-${i}`}
          accent={accent}
          masks={[masks[i], masks[i + 1]]}
        />
      );
    }
    return sections;
  } else {
    for (let i = 0; i < masks.length - 1; i += 2) {
      sections.push(
        <SideBySideExplore
          key={`${masks[i].name}-${i}`}
          accent={accent}
          masks={[masks[i], masks[i + 1]]}
        />
      );
    }
    sections.push(
      <FullwidthExplore
        key={`${masks[masks.length - 1].name}-uneven`}
        accent={accent}
        {...masks[masks.length - 1]}
      />
    );
    return sections;
  }
};

const ExploreMasksSection = ({masks = [], accent, layout = 'spaced'}: ExploreMasksSectionProps) => {
  const sectionBuilder = layout === 'tight' ? buildSectionsTight : buildSectionsSpaced;
  const sections = sectionBuilder(masks, accent);
  return <ExploreSection>{sections}</ExploreSection>;
};

export default ExploreMasksSection;
