import React from 'react';
import styled from 'styled-components';
import {Video} from '../Video/Video';

import {ClickableElement, ClickableElementProps} from '../';
import {space} from '../../../theme/theme';
import {Spaces} from '../../../types/theme';

export interface StyledSectionProps {
  $alignItems?: 'center' | 'flex-end' | 'flex-start';
  $backgroundColor?: string;
  $defaultTextColor?: string;
  $size?: Spaces;
  $textAlign?: 'center' | 'left' | 'right';
}

export interface StyledSectionBackgroundProps {
  $backgroundImage?: string;
  $backgroundPosition?: string;
  $backgroundSize?: string;
  $mobileBackgroundImage?: string;
  $mobileBackgroundPosition: string;
}

export interface SectionProps extends ClickableElementProps {
  alignItems?: 'center' | 'flex-end' | 'flex-start';
  backgroundImage?: string;
  backgroundColor?: string;
  backgroundPosition?: string;
  backgroundVideo?: string;
  backgroundSize?: string;
  children: React.ReactNode;
  defaultTextColor?: string;
  mobileBackgroundImage?: string;
  mobileBackgroundPosition?: string;
  size?: Spaces;
  textAlign?: 'center' | 'left' | 'right';
}

const StyledSectionBackground = styled.div<StyledSectionBackgroundProps>`
  bottom: 0;
  height: 100%;
  left: 0;
  background-image: ${({$backgroundImage}) => `url(${$backgroundImage})`};
  background-size: ${({$backgroundSize}) => ($backgroundSize ? $backgroundSize : 'cover')};
  background-position: ${({$backgroundPosition}) =>
    $backgroundPosition ? $backgroundPosition : 'center'};
  background-repeat: no-repeat;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 1;

  @media (max-width: 500px) {
    background-image: ${({$backgroundImage, $mobileBackgroundImage}) =>
      `url(${$mobileBackgroundImage ? $mobileBackgroundImage : $backgroundImage})`};
    background-position: ${({$backgroundPosition, $mobileBackgroundPosition}) =>
      $mobileBackgroundPosition
        ? $mobileBackgroundPosition
        : $backgroundPosition
        ? $backgroundPosition
        : 'center'};
  }
`;

const StyledSectionBackgroundOverlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAMAAAADCAYAAABWKLW/AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyBpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBXaW5kb3dzIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjkxNjgyMDA2NjJBNzExRTBCNjU1QzhBRDY1RUJCOUU4IiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjkxNjgyMDA3NjJBNzExRTBCNjU1QzhBRDY1RUJCOUU4Ij4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6OTE2ODIwMDQ2MkE3MTFFMEI2NTVDOEFENjVFQkI5RTgiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6OTE2ODIwMDU2MkE3MTFFMEI2NTVDOEFENjVFQkI5RTgiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz7UWbiJAAAAHUlEQVR42mJiYGD4DwQggoEJSDAyQAETAxIACDAAJMMJ/r07h+kAAAAASUVORK5CYII=');
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1;
`;

const StyledSectionContent = styled.div`
  position: relative;
  z-index: 2;
  height: 100%;
`;

const StyledSection = styled(ClickableElement)<StyledSectionProps>`
  --section__padding: ${({$size}) => ($size ? `${space[`${$size}`]}px` : '0px')};

  align-items: ${({$alignItems}) => ($alignItems ? $alignItems : 'stretch')};
  background-color: ${({$backgroundColor}) => $backgroundColor};
  color: ${({$defaultTextColor}) => ($defaultTextColor ? $defaultTextColor : 'inherit')};
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: var(--section__padding);
  padding-top: var(--section__padding);
  position: relative;
  margin-left: auto;
  margin-right: auto;
  text-align: ${({$textAlign}) => $textAlign};
  width: 100%;
  z-index: 1;
`;

export const Section = ({
  alignItems,
  backgroundImage,
  backgroundColor,
  backgroundPosition,
  backgroundVideo,
  backgroundSize,
  children,
  defaultTextColor,
  mobileBackgroundImage,
  mobileBackgroundPosition,
  size,
  textAlign,
  ...props
}: SectionProps) => {
  return (
    <StyledSection
      $alignItems={alignItems}
      $backgroundColor={backgroundColor}
      $defaultTextColor={defaultTextColor}
      $size={size}
      $textAlign={textAlign}
      {...props}>
      {backgroundImage && (
        <StyledSectionBackground
          $backgroundImage={backgroundImage}
          $backgroundPosition={backgroundPosition}
          $backgroundSize={backgroundSize}
          $mobileBackgroundImage={mobileBackgroundImage}
          $mobileBackgroundPosition={mobileBackgroundPosition}
        />
      )}
      {backgroundVideo && (
        <StyledSectionBackground>
          <StyledSectionBackgroundOverlay />
          <Video
            controls={false}
            loop={true}
            muted={true}
            playing={true}
            playsinline={true}
            url={backgroundVideo}
          />
        </StyledSectionBackground>
      )}
      {children && <StyledSectionContent>{children}</StyledSectionContent>}
    </StyledSection>
  );
};
