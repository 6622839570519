import classNames from 'classnames';
import React from 'react';

import StyledPrimalCTAButton from './StyledPrimalCTAButton';

interface Props {
  className?: string;
  ctaConfig: {
    isCtaLinkExternal?: boolean;
    ctaString: string;
    ctaLink: string;
    disabled?: boolean;
    theme?: string;
  };
}

const PrimalCTAButton = ({className, ctaConfig, ...props}: Props) => {
  const classes = classNames('btn-display', className);

  return <StyledPrimalCTAButton className={classes} ctaConfig={ctaConfig} {...props} />;
};

export default PrimalCTAButton;
