import styled from 'styled-components';

import {Device} from '../../../constants';

const FeatureHeroCarouselContainer = styled.div`
  position: relative;
  width: 100%;
  height: 70vh;
  min-height: 850px;
  z-index: 2;
  margin: 0;
  background-color: #1b002e;

  .slick-wrapper {
    height: 100%;
  }

  .slick-slider {
    position: relative;
    width: 100%;
    height: 100%;

    .slick-list {
      height: 100%;
      overflow: visible !important;
    }
    .slick-track {
      display: flex;
      flex-wrap: no-wrap;
      justify-content: flex-start;
      align-items: center;
      padding: 0 0;
      height: 100%;
    }
    .slick-slide {
      box-sizing: content-box;
      position: relative;
      user-select: none;
      z-index: 0;
    }
  }

  @media screen and (max-width: 940px) {
    height: 60vh;
    min-height: 675px;
  }
  @media screen and ${Device.mobileL} {
    min-height: 645px;
    height: 60vh;
  }
`;

export default FeatureHeroCarouselContainer;
