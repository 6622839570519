import HtmlTemplate from 'epic-fortnite-react/components/common/HtmlTemplate';
import React from 'react';

import Container from '../components/Container';
import HeroSection from '../components/HeroSection';
import HeroTitle from '../components/HeroTitle';
import {default as ResponsiveImageComp} from '../components/ResponsiveImage';
import {Background, ResponsiveImage} from '../types';

import {Hashtag, HeroGrid, HeroTextWrapper, ImageWrapper} from './components';

export interface DetailsHeroSectionProps {
  header?: string;
  background?: Background;
  image?: ResponsiveImage;
  hashtag?: string;
  accent?: string;
}

const DetailsHeroSection = ({
  header,
  hashtag,
  background,
  image = {},
  accent,
}: DetailsHeroSectionProps) => {
  const {desktopImage, mobileImage, tabletImage} = image;

  return (
    <HeroSection gradient={background}>
      <Container>
        <HeroGrid>
          <HeroTextWrapper>
            <Hashtag color={accent}>
              <HtmlTemplate html={hashtag ?? ''} />
            </Hashtag>
            <HeroTitle>
              <HtmlTemplate html={header ?? ''} />
            </HeroTitle>
          </HeroTextWrapper>
          <ImageWrapper>
            <ResponsiveImageComp
              desktopSrc={desktopImage}
              mobileSrc={mobileImage}
              tabletSrc={tabletImage}
            />
          </ImageWrapper>
        </HeroGrid>
      </Container>
    </HeroSection>
  );
};

export default DetailsHeroSection;
