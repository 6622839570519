import {Video} from 'epic-fortnite-react';
import React from 'react';
import styled from 'styled-components';

import {RiftTourModal} from './components/RiftTourModal';

const ModalVideoWrapper = styled.div`
  height: 0;
  padding-top: calc(100% * (9 / 16));
  background-color: #c4c4c4;
  overflow: hidden;
  position: relative;
`;

export type RiftTourVideoModalProps = {
  ariaLabel: string;
  isOpen?: boolean;
  onDismiss?: () => void;
  video?: string;
};

export const RiftTourVideoModal = ({ariaLabel, isOpen, onDismiss, video}) => {
  const handleOnDismiss = () => {
    onDismiss && onDismiss();
  };

  return (
    <RiftTourModal
      ariaLabel={ariaLabel}
      isOpen={isOpen}
      maxWidth={1200}
      onDismiss={handleOnDismiss}>
      <ModalVideoWrapper>
        <Video height="100%" playing={true} url={video} width="100%" />
      </ModalVideoWrapper>
    </RiftTourModal>
  );
};
