import {getOgLocale} from '@epic-core/common';
import qs from 'query-string';
import queryString from 'query-string';
import invariant from 'tiny-invariant';
import root from 'window-or-global';
import {generateRouteHref} from './route';
/**
 * Helper to get an imported asset's path when used in JSX to make sure SSR and client paths match
 *
 * Prepend the root webpack asset path if it exists so server/client resource paths match
 * Then strip out intermediate relative pathing to avoid http://path/./resource
 */
export const getAssetPath = (asset: string | undefined): string | undefined => {
  if (!asset) return undefined;
  if (/^data:image/.test(asset) || /^http/.test(asset)) return asset;

  return `${root.__webpack_asset_path || ''}${asset}`.replace(/\/\.\//, '/');
};

/*
1080p
---
16:9 ... 1920x1080
5:3 .... 1920x1152
4:3 .... 1920x1440
3:2 .... 1920x1280
720p
---
16:9 ... 1280x720
5:3 .... 1280x768
4:3 .... 1280x960
3:2 .... 1280x853
480p
---
16:9 ... 854x480
5:3 .... 854x512
4:3 .... 854x640
3:2 .... 854x570
320p
---
16:9 ... 480x270
5:3 .... 480x288
4:3 .... 480x360
3:2 .... 480x320
 */

export enum AssetSizeType {
  RATIO_16X9_320 = 'RATIO_16X9_320',
  RATIO_16X9_480 = 'RATIO_16X9_480',
  RATIO_16X9_720 = 'RATIO_16X9_720',
  RATIO_16X9_1080 = 'RATIO_16X9_1080',
  RATIO_3X4_320 = 'RATIO_3X4_320',
  RATIO_3X4_480 = 'RATIO_3X4_480',
  RATIO_3X4_720 = 'RATIO_3X4_720',
  RATIO_3X4_1080 = 'RATIO_3X4_1080',
  MOBILE = 'MOBILE',
  MAX_WIDTH_1440 = 'MAX_WIDTH_1440',
}

const assetSizes = {
  [AssetSizeType.RATIO_16X9_320]: {w: 480, h: 270},
  [AssetSizeType.RATIO_16X9_480]: {w: 854, h: 480},
  [AssetSizeType.RATIO_16X9_720]: {w: 1280, h: 720},
  [AssetSizeType.RATIO_16X9_1080]: {w: 1920, h: 1080},

  [AssetSizeType.RATIO_3X4_320]: {w: 360, h: 480},
  [AssetSizeType.RATIO_3X4_480]: {w: 640, h: 854},
  [AssetSizeType.RATIO_3X4_720]: {w: 960, h: 1280},
  [AssetSizeType.RATIO_3X4_1080]: {w: 1440, h: 1920},
  [AssetSizeType.MOBILE]: {mobileresize: 1},
  [AssetSizeType.MAX_WIDTH_1440]: {w: 1440},
};

export const getAssetsPathAtSize = (
  asset: string | undefined,
  size: AssetSizeType
): string | undefined => {
  const path: string | undefined = getAssetPath(asset);
  if (!path) return undefined;

  const {url, query = {}} = qs.parseUrl(path);
  if (size === AssetSizeType.MOBILE) {
    return `${url}?${qs.stringify(assetSizes[size])}`;
  }
  const decoratedQuery = assetSizes[size]
    ? {
        ...query,
        resize: 1,
        ...assetSizes[size],
      }
    : query;
  return `${url}?${qs.stringify(decoratedQuery)}`;
};

export function trackProgress() {
  if (
    typeof root.com.epicgames !== 'undefined' &&
    typeof root.com.epicgames.web !== 'undefined' &&
    !!root.com.epicgames.web.track
  ) {
    root.com.epicgames.web.track.loadPixel();
  }
}

export function loadScript(url) {
  // eslint-disable-next-line no-restricted-globals
  if (typeof document === 'undefined') {
    console.log('FortniteUtils loadScript skipped, document not available');
    return;
  }
  const scriptTag = root.document.createElement('script');
  scriptTag.setAttribute('type', 'text/javascript');
  scriptTag.setAttribute('src', url);
  root.document.head.appendChild(scriptTag);
}

export function canPlayVideo() {
  // eslint-disable-next-line no-restricted-globals
  if (typeof document === 'undefined') {
    console.log('FortniteUtils canPlayVideo: false, document not available');
    return false;
  }
  let canPlay;
  const video = root.document.createElement('video');
  if (video.canPlayType && video.canPlayType('video/webm') !== '') {
    canPlay = 'webm';
  } else if (video.canPlayType && video.canPlayType('video/mp4') !== '') {
    canPlay = 'mp4';
  } else {
    canPlay = 'none';
  }
  return canPlay;
}

export function getMetaTags(localizationStore, shareImage) {
  // Social metadata
  const socialMeta = {
    siteName: localizationStore.messages['epic.fortnite.social.meta.site_name'],
    description: localizationStore.messages['epic.fortnite.social.meta.site_description'],
    twitterName: localizationStore.messages['epic.fortnite.social.meta.twitter_account'],
    twitterDesc: localizationStore.messages['epic.fortnite.social.meta.twitter_description'],
    locale: getOgLocale(),
  };

  let baseUrl;
  // eslint-disable-next-line no-restricted-globals
  if (typeof document === 'undefined') {
    baseUrl = root.location.origin;
  } else {
    baseUrl = root.document.getElementsByTagName('html')[0].dataset
      ? root.document.getElementsByTagName('html')[0].dataset.baseurl
      : root.document.getElementsByTagName('html')[0].getAttribute('data-baseurl');
  }

  //if ends with /fortnite add a slash
  if (!baseUrl.match(/.*\/$/)) {
    baseUrl += '/';
  }

  const ogUrl = root.location.href;
  const tags = [
    // TODO: This needs to be refactored to only worry about keywords for ZH-CN and ZH-Hant
    // SEO keywords
    // {name: 'keywords', content: localizationStore.messages['epic.fortnite.meta.keywords']},

    //Facebook
    {property: 'og:url', content: ogUrl},
    {property: 'og:title', content: socialMeta.siteName},
    {property: 'og:description', content: socialMeta.description},
    {property: 'og:site_name', content: socialMeta.siteName},
    {property: 'og:locale', content: socialMeta.locale},
    {property: 'og:image', content: getAssetPath(shareImage)},

    //Twitter
    {name: 'twitter:card', content: 'summary'},
    {name: 'twitter:site', content: socialMeta.twitterName},
    {name: 'twitter:title', content: socialMeta.siteName},
    {name: 'twitter:description', content: socialMeta.twitterDesc},
    {name: 'twitter:image', content: getAssetPath(shareImage)},
  ];
  return tags;
}

export function getDateParams(input) {
  const date = new Date(input);
  const time = date.getTime();
  const year = date.getFullYear();
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const month = date.getMonth();
  const enMonth = months[month + 1];
  const day = date.getDate() < 9 ? `0${date.getDate()}` : date.getDate();
  const hour = date.getHours();
  const min = date.getMinutes();
  const sec = date.getSeconds();

  return {
    date,
    time,
    year,
    month,
    enMonth,
    day,
    hour,
    min,
    sec,
  };
}

export function parseHTML(string) {
  const html = root.document.createElement('div');
  html.innerHTML = string;
  return html;
}

/**
 * If the delta for the x or y coordinates of the mouse have changed from mouse down to mouse up
 *  it is dragging motion and not intended to be a click.
 * @param  {Object} e Event object
 * @return {Boolean}   true/false
 */
export function draggingMotion(e, mouseDown) {
  const allowed = 40;
  const x = e.clientX;
  const y = e.clientY;

  if (mouseDown) {
    if (Math.abs(x - mouseDown.x) > allowed) {
      return true;
    }

    if (Math.abs(y - mouseDown.y) > allowed) {
      return true;
    }
  }

  return false;
}

/**
 * Fisher-Yates shuffle function.  This shuffle is done in-place and is destructive
 * @param array
 */
export function shuffle(array) {
  let unShuffled = array.length;
  let element = null;
  let currentElement;

  // While there remain elements to shuffle…
  while (unShuffled) {
    // Pick a remaining element…
    currentElement = Math.floor(Math.random() * unShuffled--);

    // And swap it with the current element.
    element = array[unShuffled];
    array[unShuffled] = array[currentElement];
    array[currentElement] = element;
  }

  return array;
}

export function htmlEncode(rawString) {
  if (!rawString) return rawString;
  const encodedStr = rawString.replace(/[\u00A0-\u9999<>\\&]/gim, (i) => `&#${i.charCodeAt(0)};`);
  return encodedStr;
}

const ACCOUNT_ID_REGEX = /[a-f0-9]{32}/;
export function isAccountId(id) {
  return ACCOUNT_ID_REGEX.test(id);
}

export function generatePath(url, params) {
  if (!url || !params) return '/';
  invariant(!url.includes('?'), `url ${url} should not include a query param`);
  const query = queryString.stringify(params);
  if (query) {
    return `${url}?${query}`;
  }
  return url;
}

export function getMatchGuide({guides, id, slug, parent}) {
  let parentGuide = null;
  for (let i = 0; i < guides.length; i++) {
    const guide = guides[i];
    if (!guide) continue;
    if (id && guide._id === id) {
      return guide;
    } else if (slug && guide._slug === slug) {
      return guide;
    } else if (parent && guide._urlPattern.indexOf(`${parent}/`) !== -1) {
      return guide;
    } else if (parent && guide._urlPattern.match(new RegExp(`\\w*${parent}$`, 'g'))) {
      parentGuide = guide;
    }
  }
  return parentGuide || guides[0];
}

export function isMatchGuideMenu(url, slug) {
  if (url && slug) {
    const regex = new RegExp(`/${slug}(/|$)`);
    return regex.test(url);
  }
  return false;
}

export function getGuideBackgroundImages(guide) {
  const images = [
    `url(${guide.backTopOverlay || ''})`,
    `url(${guide.backCenter || ''})`,
    `url(${guide.backTop || ''})`,
    `url(${guide.backBaseOverlay || ''})`,
    `url(${guide.backBase || ''})`,
  ];
  return images.join(',');
}

/**
 * Makes sure not to use the esports or account merge context and always uses the root /fortnite context.
 * This is meant to be used as an alternative to generateRoutePath() but it doesn't have all the same error and
 * scenario handling.  Make sure to pass only simple paths which do not include context or locale.
 * @param  {String} pathname
 * @return {String}
 */
export function generateMainSitePath(pathname, locale) {
  if (!locale) {
    throw new Error(`generateMainSitePath: missing locale argument: ${locale}`);
  }

  let path = pathname;
  if (path && path[0] === '/') {
    //drop the leading slash
    path = path.substr(1);
  }
  const pathnameWithContext = `${root.appContext.replace('/competitive', '')}/${path}`;

  return generateRouteHref(pathnameWithContext, root.location, locale);
}

export const externalRegex = /(account-merge|competitive)/;
export const fnRegex = /(fn)\//;

export const isExternalAppPath = (path) => {
  return externalRegex.test(path) || fnRegex.test(path);
};

export const islandUrlStripTitle = (code) =>
  code.replace(/(.*)([0-9]{4}-[0-9]{4}-[0-9]{4})$/, '$2');

export const lastRoutePart = (route) => {
  const hasSlash = route.indexOf('/') !== -1;
  const lastRouteCapture = /\/([^/]+)\/?$/; // gets the last part of route e.g. /fn/cosplay = cosplay, /fn/battlepass/ch2-s17 = ch2-s17
  return hasSlash ? route.match(lastRouteCapture)[1] : route;
};
