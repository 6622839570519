import React, {forwardRef, useRef} from 'react';
import Dropzone, {useDropzone, DropzoneOptions, DropEvent, FileError} from 'react-dropzone';
import styled from 'styled-components';

import {Stack, Text, UtilityButton} from '../';
import {Field, FieldBaseProps, FieldLabelVariant} from '../Field/Field';

type InputProps = React.AllHTMLAttributes<HTMLInputElement>;

export type FileUploadBaseProps = Omit<
  FieldBaseProps,
  'value' | 'labelId' | 'secondaryMessage' | 'type'
> & {
  onBlur?: InputProps['onBlur'];
  onDrop?: DropzoneOptions['onDrop'];
  onDropAccepted?: DropzoneOptions['onDropAccepted'];
  onDropRejected?: DropzoneOptions['onDropRejected'];
  disabled?: boolean;
  type?: 'image' | 'video';
};

export type FileUploadLabelProps = FieldLabelVariant;
export type FileUploadProps = FileUploadBaseProps & FileUploadLabelProps;

export type StyledFileUploadProps = {
  $disabled?: boolean;
};

interface FileWithDimensions extends File {
  width: number;
  height: number;
}

const StyledFileUpload = styled.div<StyledFileUploadProps>`
  padding: 48px 16px;
  font-size: 14px;
  line-height: 24px;
  width: 100%;

  ${({$disabled}) => ({
    cursor: !$disabled ? 'pointer' : 'default',
  })}
`;

function isFileWithDimensions(file: File): file is FileWithDimensions {
  if (!(file as FileWithDimensions).width || !(file as FileWithDimensions).height) {
    return false;
  }

  return true;
}

function imageValidator<T extends File>(file: T) {
  const errors: FileError[] = [];

  if (!isFileWithDimensions(file)) {
    return null;
  }

  const ratio = file.width / file.height;
  const desiredRatio = 16 / 9;
  if (ratio !== desiredRatio) {
    errors.push({
      code: 'image-aspect-ratio',
      message: 'Image should be 16:9 ratio',
    });
  }

  if (file.width < 1920 || file.height < 1080) {
    errors.push({
      code: 'image-minimum-resolution',
      message: 'Image should have a minimum resolution of 1920 by 1080',
    });
  }

  return errors.length > 0 ? errors : null;
}

// eslint-disable-next-line react/display-name
export const FileUpload = forwardRef<HTMLInputElement, FileUploadProps>(
  ({onDrop, onDropAccepted, onDropRejected, disabled, type, ...props}, forwardedRef) => {
    const defaultRef = useRef<HTMLInputElement | null>(null);
    const ref = forwardedRef || defaultRef;

    const {getRootProps, getInputProps, isDragActive} = useDropzone({
      disabled,
      onDrop,
      onDropAccepted,
      onDropRejected,
      maxFiles: 1,
      multiple: false,
      accept: type === 'image' ? 'image/jpeg, image/png' : 'video/quicktime, video/mp4',
      maxSize: type === 'image' ? 10485760 /* 10MB */ : 1074000000 /* 1GB */,
      validator: type === 'image' ? imageValidator : undefined,
      getFilesFromEvent:
        type === 'image'
          ? async (event: DragEvent | DropEvent) => {
              const files = await (Dropzone as any).defaultProps.getFilesFromEvent(event);
              const promises: Promise<FileWithDimensions>[] = [];
              for (let index = 0; index < files.length; index++) {
                const file = files[index];
                if (file instanceof DataTransferItem) {
                  continue;
                }

                const promise = new Promise<FileWithDimensions>((resolve, reject) => {
                  const image = new Image();
                  image.onload = function () {
                    file.width = image.width;
                    file.height = image.height;
                    resolve(file);
                  };
                  const url = URL.createObjectURL(file);
                  image.src = url;
                });
                promises.push(promise);
              }
              return await Promise.all(promises);
            }
          : (Dropzone as any).defaultProps.getFilesFromEvent,
    });

    return (
      <Field disabled={disabled} labelId={undefined} {...props}>
        {(fieldProps, icon, secondaryIcon, prefix) => (
          <Stack gap="med">
            <StyledFileUpload $disabled={disabled} {...getRootProps()}>
              {icon}
              {prefix}
              <input
                ref={ref}
                type="file"
                disabled={disabled}
                {...fieldProps}
                {...getInputProps()}
              />
              <Stack gap="med" align="center">
                <svg width="80" height="80" fill="#000" xmlns="http://www.w3.org/2000/svg">
                  <path d="M40 11c-6.224 0-12.483 2.369-17.228 7.104-3.53 3.521-5.67 7.89-6.576 12.445C6.986 32.332 0 40.357 0 50.044 0 61.034 8.985 70 20 70h42.609C72.199 70 80 62.216 80 52.647c0-9.03-6.972-16.382-15.815-17.19.04-6.281-2.195-12.575-6.984-17.353C52.459 13.373 46.224 11 40 11zm0 5.206c4.903 0 9.758 1.819 13.505 5.558a19.075 19.075 0 015.49 15.808 2.597 2.597 0 001.51 2.695c.337.153.702.232 1.071.233h1.033c6.79 0 12.174 5.373 12.174 12.147 0 6.775-5.385 12.147-12.174 12.147H20c-8.195 0-14.783-6.573-14.783-14.75 0-7.722 5.886-14.013 13.424-14.696a2.611 2.611 0 002.337-2.277 19.037 19.037 0 015.49-11.307c3.743-3.735 8.63-5.558 13.532-5.558z" />
                  <path d="M38.234 32.501c.437-.398.896-.677 1.766-.677s1.25.205 1.766.677l9.566 8.677c1.002.925 1.19 2.682.163 3.687-.976.954-2.684 1.078-3.696.163l-5.19-4.718v16.675A2.606 2.606 0 0140 59.588a2.606 2.606 0 01-2.609-2.603V40.31l-5.19 4.718c-1.012.915-2.773.842-3.695-.163a2.623 2.623 0 01.163-3.687l9.565-8.677z" />
                </svg>
                {!isDragActive ? (
                  <Stack gap="med">
                    <Text font="OpenSans" size="sm">
                      Drag and drop file here, or
                    </Text>
                    <UtilityButton disabled={disabled} label="Choose File" />
                  </Stack>
                ) : (
                  <p>Drop here</p>
                )}
              </Stack>
              {secondaryIcon}
            </StyledFileUpload>
          </Stack>
        )}
      </Field>
    );
  }
);
