import styled from 'styled-components';

import {breakpoints} from '../../../constants/Breakpoints';

const StepDescription = styled.div`
  &&& {
    font-size: 2rem;
    line-height: 1.2;
  }

  @media ${breakpoints.mobileL} {
    &&& {
      font-size: 1.6rem;
    }
  }
`;

export default StepDescription;
