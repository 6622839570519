export const getDecoratedFontFamily = (locale: string) => {
  switch (locale) {
    case 'ar':
      return 'NotoNaskhArabicUI, NotoNaskhArabic, sans-serif';
    case 'ko':
      return 'aERIN, sans-serif';
    case 'ja':
      return 'NotoSansCJKjp, sans-serif';
    case 'ru':
      return 'BurbankBigCondensed-Black';
    default:
      return 'BurbankBigRegular-Black';
  }
};
