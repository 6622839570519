import axios from 'axios';
import LoginActions from 'epic-fortnite-react/actions/LoginActions';
import root from 'window-or-global';

const noop = () => {};

export default function configureAxios() {
  axios.interceptors.request.use((config) => {
    config.headers['X-Requested-With'] = 'XMLHttpRequest';
    return config;
  });

  axios.interceptors.response.use(
    (response) => response,
    (err) => {
      if (err.response && err.response.status === 401) {
        const {message} = err.response.data;
        const returnUrl = root.location.pathname;

        if (message === 'epic.common.sso.missing_bearer_token') {
          LoginActions.requestLogin(returnUrl);
        } else if (message === 'epic.common.sso.session_invalid') {
          LoginActions.requestLogout(returnUrl);
        }

        return new Promise(noop, noop);
      }
      return Promise.reject(err);
    }
  );
}
