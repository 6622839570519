import {getLocale} from '@epic-core/common';

export const getBackgroundImage = (data, tablet, mobile) => {
  if (!data || !data.background) return '';
  if (tablet && data.background.tabletImage) {
    return data.background.tabletImage;
  } else if (mobile && data.background.mobileImage) {
    return data.background.mobileImage;
  } else if (data.background.desktopImage) {
    return data.background.desktopImage;
  }
  return '';
};

export const getBackgroundStyle = (data, tablet, mobile) => {
  const bkImage = getBackgroundImage(data, tablet, mobile);
  const backgroundStyle = bkImage ? {backgroundImage: `url(${bkImage})`} : {};
  if (data.backgroundColor) {
    backgroundStyle.backgroundColor = data.backgroundColor;
  }
  if (data.fontColor) {
    backgroundStyle.color = data.fontColor;
  }
  return backgroundStyle;
};

export const convertDate = (date) => {
  const options = {
    weekday: 'long',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    timeZoneName: 'short',
  };
  const parseDate = new Date(date);
  return parseDate.toLocaleDateString(getLocale(), options);
};
