import {HtmlTemplate} from 'epic-fortnite-react';
import styled from 'styled-components';
import React from 'react';
import {Container} from 'epic-fortnite-react';
import {CoreSection} from './CoreSection';

const StyledRichText = styled.div<{
  $contentColor: string;
  $linkColor: string;
  $linkFont?: string;
  $linkSize?: string;
}>`
  color: ${({$contentColor}) => $contentColor} !important;
  font-family: 'BurbankBigRegular-Black';
  font-size: 24px;
  font-weight: 900;
  margin: auto;
  max-width: 60ch;
  text-align: center;
  text-transform: uppercase;
  padding-bottom: 20px;

  // lots of really bad styles here due to really bad, unnecessarily specific global styles elsewhere
  * {
    font-family: inherit !important;
    font-size: inherit !important;
    font-weight: inherit !important;
    line-height: inherit !important;
  }

  a {
    color: ${({$linkColor}) => $linkColor} !important;
    text-decoration: underline !important;
    font-family: ${({$linkFont}) => ($linkFont ? $linkFont : 'BurbankBigRegular-Black')} !important;
    font-size: ${({$linkSize}) => ($linkSize ? `${$linkSize}px` : '24px')} !important;
    display: block;
    padding-bottom: 10px;
  }

  &&&& h3 {
    font-size: 36px !important;
  }

  html[lang='ja'],
  html[lang='ko'] & {
    word-break: keep-all;
  }

  html[lang='ar'] & {
    font-family: NotoNaskhArabicUI, NotoNaskhArabic, sans-serif !important;
    direction: rtl;

    a {
      font-family: NotoNaskhArabicUI, NotoNaskhArabic, sans-serif !important;
    }
  }

  html[lang='ko'] & {
    font-family: aERIN, sans-serif !important;

    a {
      font-family: aERIN, sans-serif !important;
    }
  }

  html[lang='ja'] & {
    font-family: NotoSansCJKjp, sans-serif !important;

    a {
      font-family: NotoSansCJKjp, sans-serif !important;
    }
  }

  html[lang='ru'] & {
    font-family: BurbankBigCondensed-Black !important;

    a {
      font-family: BurbankBigCondensed-Black !important;
    }
  }
`;

export const RichTextSection = ({
  textContent,
  contentColor,
  coreSectionProps,
  cta,
  linkColor,
  linkFont,
  linkSize,
}) => {
  return (
    <CoreSection coreSectionProps={coreSectionProps}>
      <Container maxWidth={1600} size="med">
        <StyledRichText
          $contentColor={contentColor}
          $linkColor={linkColor}
          $linkFont={linkFont}
          $linkSize={linkSize}>
          {textContent && <HtmlTemplate html={textContent} />}
        </StyledRichText>
      </Container>
    </CoreSection>
  );
};
