import React, {useState} from 'react';
import VisibilitySensor from 'react-visibility-sensor';

import {triggerAnalytic} from '../../utils/SubscriptionUtils';

import StyledSubscriptionPhasedSection from './StyledSubscriptionPhasedSection';
import SubscriptionCoreContent from './SubscriptionCoreContent';

interface Props {
  content: any;
  mobile: boolean;
  tablet: boolean;
}

const SubscriptionPhasedOutfitSection = (props: Props) => {
  const {content, mobile} = props;
  const [isVisible, setVisibility] = useState(false);
  const onChange = (): void => {
    setVisibility(!isVisible);
    if (isVisible) {
      triggerAnalytic('SubscriptionBundle');
    }
  };

  return (
    <VisibilitySensor onChange={onChange} partialVisibility minTopValue={10}>
      <StyledSubscriptionPhasedSection>
        <SubscriptionCoreContent content={content} mobile={mobile} />
      </StyledSubscriptionPhasedSection>
    </VisibilitySensor>
  );
};

export default SubscriptionPhasedOutfitSection;
