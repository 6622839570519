import {connectToStores} from 'epic-alt-utils';
import {getMatchGuide} from 'epic-fortnite-react/utils/FortniteUtils';
import PropTypes from 'prop-types';
import React from 'react';
import Helmet from 'react-helmet';
import {withRouter} from 'react-router';

import BasicItem from '../../components/playGuide/BasicItem';
import {PlayGuideStore, HeaderStore} from '../../stores';

@connectToStores
class PlayGuideBasicView extends React.Component {
  static propTypes = {
    playGuideStore: PropTypes.object,
    mobile: PropTypes.bool,
    match: PropTypes.object,
    history: PropTypes.object,
  };

  static getStores() {
    return [PlayGuideStore, HeaderStore];
  }

  static getPropsFromStores() {
    return {
      playGuideStore: PlayGuideStore.getState(),
      mobile: HeaderStore.getState().mobile(),
    };
  }

  render() {
    const {playGuideStore, mobile, match, history} = this.props;
    const {menu, guides} = playGuideStore.playGuide;
    const {folder, category, activeTab} = match.params;
    let {slug} = match.params;
    let guide;
    if (slug) {
      guide = getMatchGuide({guides, slug});
    } else if (category) {
      guide = getMatchGuide({guides, parent: category});
      slug = guide._slug;
    } else {
      guide = getMatchGuide({guides, parent: folder});
      slug = guide._slug;
    }

    return (
      <div className={`play-guide-view ${slug}`}>
        <Helmet title={guide._title} />
        <BasicItem
          key={`${slug}-${activeTab}`}
          menu={menu}
          guides={guides}
          guide={guide}
          activeTab={activeTab}
          mobile={mobile}
          match={match}
          history={history}
        />
      </div>
    );
  }
}

export default withRouter(PlayGuideBasicView);
