import React from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import styled, {keyframes} from 'styled-components';
import AnimateObject from 'epic-fortnite-react/components/common/AnimateObject';
import epicTracking from 'epic-tracking';

import {Device} from '../../../constants';
import {CrewLeaf} from '../../../icons';

import CommonCTABtn from '../../common/CommonCTABtn';

import {FeatureVideo} from '../FeatureHeroCarousel/Types';
import {HtmlTemplate, Stack, Text, useBreakpoints} from 'epic-fortnite-react';

interface Cta {
  ctaString: string;
  disabled: boolean;
  isCtaLinkExternal: boolean;
  textOnly: boolean;
}

interface CmsData {
  background: FeatureVideo;
  battleStarAlt: string;
  battleStarLogo: string;
  crewImg: string;
  crewImgMobile: string;
  crewLogo: string;
  crewLogoAlt: string;
  crewPattern: string;
  cta: Cta;
  description: string;
  title: string;
  vbucksAlt: string;
  vbucksImage: string;
}

interface CrewSectionProps {
  data: CmsData;
  mobile: boolean;
  tablet: boolean;
}

interface CrewPatternProps {
  pattern?: string;
}

const sideUpScroll = keyframes`
  0% {
    transform: translate3d(0%, 0%, 1px);
  }

  50% {
    transform: translate3d(-50%, -50%, 1px);
  }

  100% {
    transform: translate3d(0%, 0%, 1px);
  }
`;

const StyledCrewSection = styled.section`
  clip-path: polygon(0% 0%, 0% 100%, 100% 100%, 100% 4vw);
  margin-top: -4vw;
  position: relative;
  z-index: 2;
`;

const CrewSectionContainer = styled.section`
  display: flex;
  flex-direction: column-reverse;
  margin: 0 auto;
  max-width: 1600px;
  padding: 80px 20px 128px;

  @media (min-width: 1024px) {
    align-items: center;
    flex-direction: row;
    padding: 128px 80px;
  }
`;

const CrewImage = styled.img`
  max-width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 70px;
  transform: scale(1.2) translateX(3%); // adjustment to presently uploaded cms image

  .ar & {
    margin-right: 5rem;
  }

  @media (min-width: 1440px) {
    padding: 2rem;
  }

  @media (max-width: 500px) {
    max-width: 70%;
    margin-bottom: -4rem;
    margin-left: 3rem;
    transform: scale(1.7) translateX(3%);

    .ar & {
      padding-top: 70px;
    }
  }
`;

const CrewBackgroundContainer = styled.section`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  overflow: hidden;

  .video .overlay {
    display: none;
  }
`;

const CrewSolidBackground = styled.div<{bgColor?: string; background?: FeatureVideo}>`
  height: 100%;
  width: 100%;
  background-size: cover;

  ${({bgColor, background}) =>
    background && background.content
      ? `
          background: url("${background.content[0]}") top right no-repeat;
          background-size: cover;
        `
      : ` background-color: ${bgColor ?? '#fff'};`}

      @media screen and ${Device.mobileL} {
        ${({background}) =>
          background && background.mobileImage
            ? `
              background: url("${background.mobileImage}") right top no-repeat;
              background-size: cover;
            `
            : null}
`;

const CrewPattern = styled.div<CrewPatternProps>`
  position: absolute;
  top: 0;
  left: 0;
  width: 200%;
  height: 400%;
  background: ${(props) => (props.pattern ? `url(${props.pattern}) repeat` : 'none')};
  background-position: 0 0;
  background-size: 250px auto;
  z-index: 100;
  animation: ${sideUpScroll} 100s linear infinite both;
`;

const CrewLogo = styled.img`
  display: block;
  margin-bottom: -6rem;
  max-width: 500px;
  transform: scale(1.5); // adjustmenst to presently uploaded cms image
  width: 100%;

  @media (max-width: 1023px) {
    margin-top: -4rem;
    transform: scale(1.2);
  }
`;

const Content = styled.div`
  @media (min-width: 1024px) {
    flex-basis: 125%;
  }

  @media (min-width: 1200px) {
    flex-basis: 85%;
  }
`;

const CrewCTAContainer = styled.div`
  position: relative;

  #left,
  #right {
    position: absolute;
    width: 4em;
    height: 4em;
  }

  #left {
    right: 85%;
    top: 45%;
    transform: rotateY(180deg) scale(0.8);
  }

  #right {
    left: 84%;
    top: 40%;
  }
`;

const CrewSection = ({data}: CrewSectionProps) => {
  const {
    background,
    crewImg,
    crewImgMobile,
    crewLogo,
    crewLogoAlt,
    crewPattern,
    cta,
    description,
    title,
  } = data;

  const [visible, setVisibility] = React.useState(false);
  const isMobile: string = useBreakpoints(crewImgMobile, {500: crewImg}).value;

  const setSectionVisibility = React.useCallback(
    (visibility) => {
      setVisibility(visibility);
      if (visibility) {
        epicTracking.trackEvent('Interaction', {
          interactionType: 'scrollViewSection',
          eventCategory: 'BattlePass Crew Section',
          eventAction: 'CrewSection-inView',
        });
      }
    },
    [setVisibility]
  );

  return (
    <VisibilitySensor
      active={!visible}
      onChange={setSectionVisibility}
      partialVisibility
      minTopValue={0}>
      <StyledCrewSection>
        {background && (
          <CrewBackgroundContainer>
            <CrewSolidBackground bgColor={'rgb(68, 15, 104)'} background={background} />
            <CrewPattern pattern={crewPattern} />
          </CrewBackgroundContainer>
        )}
        <CrewSectionContainer>
          {crewImg && (
            <AnimateObject
              className={'crew-image'}
              minTop={20}
              speed={1.5}
              from={{x: `-=50`, autoAlpha: 0}}
              to={{x: 0, autoAlpha: 1, delay: 0.3}}>
              <CrewImage alt="" src={crewImg ? isMobile : crewImgMobile} />
            </AnimateObject>
          )}
          <Content>
            <Stack gap="2xl">
              {crewLogo && (
                <AnimateObject
                  className={'crew-logo'}
                  minTop={20}
                  speed={1.5}
                  from={{x: `-=50`, autoAlpha: 0}}
                  to={{x: 0, autoAlpha: 1, delay: 0.3}}>
                  <CrewLogo src={crewLogo} alt={crewLogoAlt} />
                </AnimateObject>
              )}
              <Stack gap="med">
                {title && (
                  <AnimateObject
                    minTop={20}
                    speed={1.5}
                    from={{y: `+=50`, autoAlpha: 0}}
                    to={{y: 0, autoAlpha: 1, delay: 0.3}}>
                    <Text align="center" as="h2" color="#fff" size="lg">
                      {title}
                    </Text>
                  </AnimateObject>
                )}
                {description && (
                  <AnimateObject
                    minTop={20}
                    speed={1.5}
                    from={{y: `+=50`, autoAlpha: 0}}
                    to={{y: 0, autoAlpha: 1, delay: 0.4}}>
                    <Text align="center" as="p" color="#fff" size="med">
                      <HtmlTemplate html={description} />
                    </Text>
                  </AnimateObject>
                )}
              </Stack>
              {cta && (
                <CrewCTAContainer>
                  <AnimateObject
                    minTop={20}
                    speed={1.5}
                    from={{y: `+=50`, autoAlpha: 0}}
                    to={{y: 0, autoAlpha: 1, delay: 0.5}}>
                    <>
                      <CommonCTABtn
                        id="battlePass-crew-learnMore"
                        className="btn-hover-transform crew-button"
                        ctaConfig={cta}
                      />
                      <CrewLeaf id="left" />
                      <CrewLeaf id="right" />
                    </>
                  </AnimateObject>
                </CrewCTAContainer>
              )}
            </Stack>
          </Content>
        </CrewSectionContainer>
        {/* {(vbucksImage || battleStarLogo) && (
          <CrewVBucksContainer>
            {battleStarLogo && <VBucksImage src={battleStarLogo} alt={battleStarAlt} />}
            {vbucksImage && <VBucksImage src={vbucksImage} alt={vbucksAlt} />}
          </CrewVBucksContainer>
        )} */}
      </StyledCrewSection>
    </VisibilitySensor>
  );
};

export default CrewSection;
