import {Container, Stack} from 'epic-fortnite-react';
import React from 'react';

import {RiftTourHeading} from './components/RiftTourHeading';
import {RiftTourSection} from './components/RiftTourSection';
import {RiftTourSubheading} from './components/RiftTourSubheading';
import {ScheduleCardSlider} from './components/ScheduleCardSlider';
import {RiftTourScheduleCard} from './RiftTourScheduleCard';
import {RiftTourBackground, RiftTourCtaInterface} from './types';

interface Props {
  data: CMSData;
}

interface CMSData {
  textHeading: string;
  subtitle: string;
  commonLogo: string;
  shareHeading: string;
  shareLogoMaxHeight: string;
  shareLogoMaxWidth: string;
  shareLogoPosition: 'bottom' | 'top';
  shareVideo: string;
  shareImage: string;
  cta: RiftTourCtaInterface;
  background: RiftTourBackground;
  scheduleList: {
    scheduleList: ScheduleItem[]; // Yuck... fix this in the CMS
  };
}

interface ScheduleItem {
  scheduleLogoOverride?: string;
  scheduleTimestamp: string;
  backgroundImage: string;
  shareText: string;
}

const CardBuilder = (data: CMSData) => {
  const {
    scheduleList: {scheduleList: items},
    commonLogo,
    cta,
    shareHeading,
    shareImage,
    shareLogoMaxHeight,
    shareLogoMaxWidth,
    shareLogoPosition,
    shareVideo,
  } = data;
  return items.map((item) => ({
    background: item.backgroundImage,
    content: (
      <RiftTourScheduleCard
        details={{
          ...item,
          commonLogo,
          cta,
          shareHeading,
          shareImage,
          shareLogoMaxHeight,
          shareLogoMaxWidth,
          shareLogoPosition,
          shareVideo,
        }}
      />
    ),
  }));
};

const RiftTourSchedule = ({data}: Props) => {
  const {textHeading, subtitle, scheduleList: schedule, background} = data;
  const {backgroundImage, gradientFrom, gradientPosition, gradientSize, gradientTo} = background;

  return (
    <RiftTourSection
      backgroundImage={backgroundImage}
      clipPathDirection={background?.overlayDirection}
      clipPathSpike={background?.hasSpike}
      gradient={{from: gradientFrom, to: gradientTo}}
      gradientPosition={gradientPosition}
      gradientSize={`${gradientSize}%`}>
      <Container maxWidth={1400} size="xl">
        <Stack align="center" gap="2xl">
          <Stack align="center" gap="lg">
            <RiftTourHeading color="#fff">{textHeading}</RiftTourHeading>
            <RiftTourSubheading color="#fff">{subtitle}</RiftTourSubheading>
          </Stack>
          {schedule?.scheduleList?.length && <ScheduleCardSlider slides={CardBuilder(data)} />}
        </Stack>
      </Container>
    </RiftTourSection>
  );
};

export default RiftTourSchedule;
