import PropTypes from 'prop-types';
import React from 'react';

import ContentRow from '../last-laugh/ContentRow';
import ContentRow100 from '../last-laugh/ContentRow100';
import ImageListBottom from '../last-laugh/ImageListBottom';
import ImageTextBottom from '../last-laugh/ImageTextBottom';
import ImageTextHorizVert from '../last-laugh/ImageTextHorizVert';
import FeatureHeroCarousel from '../last-laugh/LastLaughCarousel/FeatureHeroCarousel';

import BackGroundCallToAction from './BackGroundCallToAction';
import BackgroundImageVideo from './BackgroundImageVideo';
import BlankCallToAction from './BlankCallToAction';
import BlankCallToActionV2 from './BlankCallToActionV2';
import PosterList from './PosterList';
import ScheduleList from './ScheduleList';
import Video from './Video';

export default class LandingPageSection extends React.Component {
  static propTypes = {
    mobileStore: PropTypes.object.isRequired,
    appConfigStore: PropTypes.object.isRequired,
    countryStore: PropTypes.object.isRequired,
    section: PropTypes.object.isRequired,
    messages: PropTypes.object.isRequired,
    mobile: PropTypes.bool,
    tablet: PropTypes.bool,
    isLoggedIn: PropTypes.bool,
    history: PropTypes.object.isRequired,
  };

  render() {
    const {
      section,
      mobile,
      tablet,
      isLoggedIn,
      history,
      mobileStore,
      appConfigStore,
      countryStore,
      messages,
    } = this.props;

    switch (section.data._type) {
      case 'LandingPage Header': {
        return (
          <BackGroundCallToAction
            isLoggedIn={isLoggedIn}
            mobileStore={mobileStore}
            data={section.data}
            mobile={mobile}
            tablet={tablet}
            messages={messages}
            history={history}
          />
        );
      }
      case 'LandingPage Video': {
        return <Video data={section.data} mobile={mobile} messages={messages} tablet={tablet} />;
      }
      case 'LandingPage Center Header': {
        const {
          data: {disableBackground},
        } = section;

        if (disableBackground) {
          return (
            <BlankCallToAction
              isLoggedIn={isLoggedIn}
              mobileStore={mobileStore}
              data={section.data}
              messages={messages}
              history={history}
            />
          );
        }
        return (
          <BackGroundCallToAction
            isLoggedIn={isLoggedIn}
            mobileStore={mobileStore}
            data={section.data}
            mobile={mobile}
            tablet={tablet}
            align="center"
            messages={messages}
            history={history}
          />
        );
      }
      case 'LandingPage Call To Action': {
        return (
          <BlankCallToActionV2
            isLoggedIn={isLoggedIn}
            mobileStore={mobileStore}
            data={section.data}
            messages={messages}
            history={history}
          />
        );
      }
      case 'LandingPage Background Header': {
        return (
          <BackGroundCallToAction
            isLoggedIn={isLoggedIn}
            mobileStore={mobileStore}
            data={section.data}
            mobile={mobile}
            tablet={tablet}
            align="center"
            messages={messages}
            history={history}
          />
        );
      }
      case 'LandingPage BackgroundImage-Logo-Video': {
        return <BackgroundImageVideo data={section.data} mobile={mobile} tablet={tablet} />;
      }
      case 'Posters Carousel': {
        return <PosterList data={section.data} mobile={mobile} tablet={tablet} />;
      }
      case 'Artist Schedule': {
        return (
          <ScheduleList
            data={section.data}
            countryStore={countryStore}
            appConfigStore={appConfigStore}
            mobile={mobile}
            tablet={tablet}
          />
        );
      }
      case 'Fortnite Section - Bottom Images': {
        return <ImageListBottom {...section.data} />;
      }
      case 'Fortnite Section - Text': {
        return <ContentRow {...section.data} />;
      }
      case 'LastLaugh - Feature Hero': {
        return <FeatureHeroCarousel data={section.data} mobile={mobile} tablet={tablet} />;
      }
      case 'Fortnite Section - Image Text (Desktop Horiz) (Mobile Stacked)': {
        return <ImageTextHorizVert {...section.data} />;
      }
      case 'Fortnite Section - Bottom Text': {
        return <ImageTextBottom {...section.data} />;
      }
      case 'Fortnite Section - Text100': {
        return <ContentRow100 {...section.data} />;
      }
      default:
    }
    return null;
  }
}
