import {motion} from 'framer-motion';
import React from 'react';
import styled from 'styled-components';

import {Device} from '../../../constants';

const LoadoutImageContainer = styled(({...rest}) => <motion.div {...rest} />)`
  position: absolute;
  width: 200px;

  @media screen and (max-width: 940px) {
    width: 100px !important;
  }

  @media screen and ${Device.mobileL} {
    width: 100px !important;
  }
`;

export default LoadoutImageContainer;
