import queryString from 'query-string';
import React, {MouseEvent} from 'react';
import {useHistory, useLocation} from 'react-router';
import root from 'window-or-global';

import PaginationWrap from './components/PaginationWrap';

type UnevenInteger = 1 | 3 | 5 | 7 | 9 | 11 | 13 | 15;

interface Props {
  pagesToShow?: UnevenInteger;
  itemsPerPage: number;
  totalItemsCount: number;
}

const Pagination = ({pagesToShow = 5, itemsPerPage, totalItemsCount}: Props) => {
  const history = useHistory();
  const location = useLocation();

  const getPage = React.useCallback(
    (pageNumber: number) =>
      (e: MouseEvent): void => {
        e.preventDefault();
        const query = queryString.parse(history.location.search);
        history.push({search: queryString.stringify({...query, page: pageNumber})});
      },
    [history.location.search, history.push]
  );

  const buildUrl = React.useCallback(
    (page: number) => {
      const queryParams = {...queryString.parse(location.search), page};
      const queryStr = queryString.stringify(queryParams);
      return `${root.location.origin}${location.pathname}?${queryStr}`;
    },
    [location.search, root.location.origin]
  );

  const getPageFromParsedUrl = React.useCallback((): number => {
    const query = queryString.parse(location.search);
    return +(query.page || 1);
  }, [location.search]);

  const totalPages = Math.ceil(totalItemsCount / itemsPerPage);

  const currentPageNum = getPageFromParsedUrl();
  const currentPageIndex = currentPageNum - 1;

  const lastIndex = totalPages - 1;
  const defaultMaxDistance = pagesToShow / 2;
  const distanceToStart = currentPageIndex;
  const distanceToEnd = lastIndex - currentPageIndex;
  const shortestDistanceToEnd = Math.min(distanceToStart, distanceToEnd);

  // maxDistance defines the max distance of pages can have to current index to be displayed
  const maxDistance =
    shortestDistanceToEnd <= defaultMaxDistance
      ? defaultMaxDistance + (defaultMaxDistance - shortestDistanceToEnd)
      : defaultMaxDistance;

  const centerButtons = React.useMemo(
    () =>
      Array(totalPages)
        .fill(null)
        .reduce((buttons, _, pageIndex) => {
          if (Math.abs(currentPageIndex - pageIndex) < maxDistance) {
            const targetPage = pageIndex + 1;
            const button = (
              <a
                key={`pagination-btn-${pageIndex}`}
                className={`pagination-btn ${currentPageIndex === pageIndex ? 'active' : ''}`}
                href={buildUrl(targetPage)}
                onClick={getPage(targetPage)}>
                {targetPage}
              </a>
            );
            return [...buttons, button];
          }
          return buttons;
        }, []),
    [totalPages, currentPageIndex, maxDistance, buildUrl, getPage]
  );

  // going from 0 based to regular counting
  const nextPageNumber = currentPageIndex + 2;
  const previousPageNumber = currentPageIndex;

  const isLastPage = currentPageIndex === lastIndex;

  if (!itemsPerPage || !totalItemsCount) {
    return null;
  } else if (totalPages <= 1) {
    return (
      <PaginationWrap>
        <div className="bottom-spacer" />
      </PaginationWrap>
    );
  }
  return (
    <PaginationWrap>
      <div className="btn-wrapper">
        {currentPageIndex > 0 ? (
          <a
            key="prev-arrow"
            className={`pagination-btn arrow`}
            href={buildUrl(previousPageNumber)}
            onClick={getPage(previousPageNumber)}>
            <i className="fa fa-chevron-left" />
          </a>
        ) : null}
        {currentPageIndex > 0 && distanceToStart > maxDistance ? (
          <a key="first" className={`pagination-btn`} href={buildUrl(1)} onClick={getPage(1)}>
            1
          </a>
        ) : null}
        {currentPageIndex > 0 && distanceToStart >= maxDistance + 1 ? (
          <span key="elip-first" className="ellipsis">
            ...
          </span>
        ) : null}

        {centerButtons}

        {!isLastPage && distanceToEnd >= maxDistance + 1 ? (
          <span key="elip-last" className="ellipsis">
            ...
          </span>
        ) : null}
        {!isLastPage && distanceToEnd > maxDistance ? (
          <a
            key="last"
            className={`pagination-btn`}
            href={buildUrl(lastIndex + 1)}
            onClick={getPage(lastIndex + 1)}>
            {lastIndex + 1}
          </a>
        ) : null}
        {!isLastPage ? (
          <a
            key="next-arrow"
            className={`pagination-btn arrow`}
            href={buildUrl(nextPageNumber)}
            onClick={getPage(nextPageNumber)}>
            <i className="fa fa-chevron-right" />
          </a>
        ) : null}
      </div>
    </PaginationWrap>
  );
};

export default Pagination;
