import classNames from 'classnames';
import {HTMLReactParserOptions} from 'html-react-parser';
import Proptypes from 'prop-types';
import React from 'react';

import HtmlTemplate from './HtmlTemplate';

export interface StyledTextElement {
  text?: string;
  color?: string;
  theme?: string;
  type?: keyof JSX.IntrinsicElements;
  className?: string;
}

interface Props {
  Wrapper?: keyof JSX.IntrinsicElements;
  data: StyledTextElement[];
  htmlTemplateOptions?: HTMLReactParserOptions;
}

const StyledText = ({data, Wrapper, htmlTemplateOptions}: Props): JSX.Element | null => {
  if (!data || !data.length) return null;
  return (
    <>
      {data
        .map((item, index) => {
          if (!item) return null;
          const {text, color, type, theme, className: classes} = item;
          if (!text) return null;
          // Themes are pre created css classes which can apply sizing, font family's.
          const className = classNames((theme || '').split(','), classes || '');
          const style = color ? {color} : {};
          if (type === 'p') {
            if (Wrapper) {
              return (
                <Wrapper key={`st${index}`}>
                  <HtmlTemplate
                    key={`st${index}`}
                    Tag={type}
                    className={className}
                    html={text}
                    style={style}
                    options={htmlTemplateOptions}
                  />
                </Wrapper>
              );
            }
            return (
              <HtmlTemplate
                key={`st${index}`}
                Tag={type}
                className={className}
                html={text}
                style={style}
                options={htmlTemplateOptions}
              />
            );
          }
          const Component: keyof JSX.IntrinsicElements = type || 'h1';
          if (Wrapper) {
            return (
              <Wrapper key={`st${index}`}>
                <Component key={`st${index}`} className={className} style={style}>
                  {text}
                </Component>
              </Wrapper>
            );
          }
          return (
            <Component key={`st${index}`} className={className} style={style}>
              {text}
            </Component>
          );
        })
        .filter((item) => item !== null)}
    </>
  );
};

StyledText.propTypes = {
  data: Proptypes.arrayOf(
    Proptypes.shape({
      text: Proptypes.string.isRequired,
      color: Proptypes.string,
      theme: Proptypes.string,
      className: Proptypes.string,
    })
  ),
  Wrapper: Proptypes.string,
};

export default StyledText;
