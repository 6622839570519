import React from 'react';

import App from './App';

class Entry extends React.Component {
  componentDidCatch(error, info) {
    console.log('componentDidCatch', info);
    console.error('Entry componentDidCatch() caught an error');
    console.error(error);
  }

  render() {
    return <App {...this.props} />;
  }
}

export default Entry;
