import {connectToStores} from 'epic-alt-utils';
import PropTypes from 'prop-types';
import React from 'react';
import {withRouter} from 'react-router';

import AppConfigStore from '../../stores/AppConfigStore';

import DownloadSelection from './DownloadSelection';

@connectToStores
class LoginOverlay extends React.Component {
  static propTypes = {
    appConfigStore: PropTypes.object,
    preHeaderMsg: PropTypes.string,
  };

  static getStores() {
    return [AppConfigStore];
  }

  static getPropsFromStores() {
    return {
      appConfigStore: AppConfigStore.getState(),
    };
  }

  render() {
    const {
      appConfigStore: {countryCode},
      appConfigStore,
      preHeaderMsg,
    } = this.props;

    return (
      <DownloadSelection
        appConfigStore={appConfigStore}
        countryCode={countryCode}
        preHeaderMsg={preHeaderMsg}
      />
    );
  }
}

export default withRouter(LoginOverlay);
