import styled from 'styled-components';

import {breakpoints} from '../../../constants/Breakpoints';

const StyledPlayButtonWrapper = styled.span`
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.55vw;
  margin: 0 auto;
  width: 100%;
  & > span {
    padding: 5%;
    transition: 0.2s linear;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    & > span {
      &&&&&&&&&:hover {
        transform: scale(1.1);
      }
    }
  }
  & p {
    color: #fff;
    font-size: 1.2vw;
    padding-right: 25px;
    margin-bottom: 0;
    @media ${breakpoints.tablet} {
      font-size: 5em;
    }

    @media ${breakpoints.mobileL} {
      font-size: 1.5em;
    }
  }
  @media ${breakpoints.tablet} {
    font-size: 0.8vw;
  }

  @media ${breakpoints.mobileL} {
    font-size: 3vw;
  }
`;

export default StyledPlayButtonWrapper;
