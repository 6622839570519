import React from 'react';
import {useInView} from 'react-intersection-observer';
import styled from 'styled-components';

type AnimateInProps = {
  children: React.ReactNode;
  delay?: number;
  type?: 'fade-in' | 'move-in' | 'scale-in';
};

type StyledAnimateInProps = {
  $delay?: number;
  $inView?: boolean;
  $type?: 'fade-in' | 'move-in' | 'scale-in';
};

const StyledAnimateIn = styled.div<StyledAnimateInProps>`
  display: inline;
  transition-delay: ${({$delay}) => `calc(0.2s * ${$delay ? $delay + 1 : 1})`}}
  transition-duration: 0.4s;
  transition-property: opacity, transform;
  transition-timing-function: ease-out;

  ${({$type}) =>
    $type === 'fade-in'
      ? {
          opacity: 0,
        }
      : $type === 'move-in'
      ? {
          opacity: 0,
          transform: `translateY(32px)`,
        }
      : $type === 'scale-in'
      ? {
          opacity: 0,
          transform: `scale(0.95)`,
        }
      : null}

  ${({$inView}) =>
    $inView && {
      opacity: 1,
      transform: `scale(1) translateY(0)`,
    }};
`;

export const AnimateIn = ({children, delay, type = 'move-in'}: AnimateInProps) => {
  const [ref, inView] = useInView({threshold: 0, triggerOnce: true});

  return (
    <StyledAnimateIn $delay={delay} $inView={inView} $type={type} ref={ref}>
      {children}
    </StyledAnimateIn>
  );
};
