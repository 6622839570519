import React from 'react';
import styled from 'styled-components';

type FlexAlign = 'center' | 'flex-end' | 'flex-start';
type FlexFlex = boolean;
type FlexBasis = number;
type FlexJustify =
  | 'center'
  | 'flex-end'
  | 'flex-start'
  | 'space-around'
  | 'space-between'
  | 'stretch';
type FlexScrollable = boolean;

export interface StyledFlexItemProps {
  $align?: FlexAlign;
  $flex?: FlexFlex;
  $flexBasis?: FlexBasis;
  $justify?: FlexJustify;
  $scrollable?: FlexScrollable;
}

export interface FlexItemProps {
  align?: FlexAlign;
  children: React.ReactNode;
  flex?: FlexFlex;
  flexBasis?: FlexBasis;
  justify?: FlexJustify;
  scrollable?: FlexScrollable;
}

const StyledFlexItem = styled.div<StyledFlexItemProps>`
  align-items: ${({$align}) => $align};
  display: flex;
  flex-basis: ${({$flexBasis}) => ($flexBasis ? `${$flexBasis || 0}px` : 'initial')};
  flex-grow: ${({$flex}) => ($flex ? 1 : 0)};
  flex-direction: column;
  flex-shrink: ${({$flex}) => ($flex ? 1 : 0)};
  /* font-size: 0; TODO: uncomment after moving all typography to Text component */
  justify-content: ${({$justify}) => $justify};
  /* line-height: 0; TODO: uncomment after moving all typography to Text component */
  min-width: 0;
  overflow: ${({$scrollable}) => $scrollable && 'auto'};
  padding: var(--flex-gapY) var(--flex-gapX);
  position: relative;

  &:empty {
    display: none;
  }
`;

export const FlexItem = ({
  align,
  children,
  flex,
  flexBasis,
  justify,
  scrollable,
}: FlexItemProps) => {
  return (
    <StyledFlexItem
      $align={align}
      $flex={flex}
      $flexBasis={flexBasis}
      $justify={justify}
      $scrollable={scrollable}>
      {children}
    </StyledFlexItem>
  );
};
