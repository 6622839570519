import debounce from 'lodash.debounce';
import PropTypes from 'prop-types';
import React from 'react';
import {animated, useSpring} from 'react-spring';
import root from 'window-or-global';

const calc = (mobile, x, y) => {
  if (mobile) {
    return [(0.5 - Math.random()) * 50, (0.5 - Math.random()) * 50];
  } else {
    return [(0.5 - Math.random()) * (root.innerWidth / 4), y - root.innerHeight / 2];
  }
};
const translation = (x, y) =>
  `translate3d(${x / 3}px,${y / 4}px, 0) rotateY(0deg) rotate(${x > 0 ? 25 : -10}deg)`;
const config = {mass: 10, tension: 150, friction: 140};

const BackgroundSideCharacters = ({characters, mobile, children}) => {
  const [xycoor, set] = useSpring(() => ({xy: [0, 0], config}));
  const elements =
    characters &&
    characters.map((char, index) => {
      if (!char.url) {
        return null;
      }
      const style = {
        backgroundImage: `url(${char.url})`,
        transform: xycoor.xy.interpolate(translation),
      };
      const className = char.className || '';
      return (
        <animated.div className={`char ${className}`} style={style} key={`${char.url}-${index}`} />
      );
    });

  React.useEffect(() => {
    const listener = (event) => set({xy: calc(mobile, root.scrollX, root.scrollY)});
    const deBouncedListner = debounce(listener, 50, {trailing: true, leading: false});
    root.addEventListener('scroll', deBouncedListner);
    return () => root.removeEventListener('scroll', deBouncedListner);
  });

  return (
    <div className="BackgroundSideCharacters">
      {elements}
      {children}
    </div>
  );
};

BackgroundSideCharacters.propTypes = {
  characters: PropTypes.arrayOf(PropTypes.object),
  mobile: PropTypes.bool,
  children: PropTypes.array,
};

export default BackgroundSideCharacters;
