import {ContextMessage as Message} from '@epic-core/localization';
import classNames from 'classnames';
import PropType from 'prop-types';
import React from 'react';

import PackBuyDialog from './PackBuyDialog';

/**
 * A function for showing a comparison table based on config
 * @param config
 * @returns {XML}
 */
export default class PackBuyButton extends React.Component {
  static propTypes = {
    isFounder: PropType.bool,
    showValue: PropType.bool,
    config: PropType.object,
    asset: PropType.object,
  };

  state = {
    showDialog: false,
  };

  toggleDialog = (e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    const disableButton = this.props.isFounder;
    if (disableButton) return;
    this.setState({showDialog: !this.state.showDialog});
  };

  render() {
    const {config, asset, isFounder} = this.props;
    if (!config || !asset) return <div />;
    const {owned} = asset;
    const disableButton = isFounder || owned;
    let messageCode = 'epic.fortnite.button.buy';
    if (owned) {
      messageCode = 'epic.fortnite.button.owned';
    } else if (isFounder) {
      messageCode = 'epic.fortnite.button.founder';
    }
    const packBuyClass = classNames('pack-buy-button', `tier-${config.tier}`, {
      'can-buy': !(owned || isFounder),
    });
    const showBuyDialog = this.state.showDialog && !owned;
    const buttonTag = config.tier ? `buy-now-tier-${config.tier}` : 'buy-now';
    return (
      <div className={packBuyClass}>
        {asset && asset.actualValue && this.props.showValue ? (
          <h3 className="value text-right">
            <Message code="epic.fortnite.founders.value" args={[asset.actualValue]} />
          </h3>
        ) : null}
        <button
          id={buttonTag}
          className="btn btn-primary"
          onClick={this.toggleDialog}
          disabled={disableButton}>
          <Message code={messageCode} />
        </button>
        <PackBuyDialog {...this.props} show={showBuyDialog} onRequestClose={this.toggleDialog} />
      </div>
    );
  }
}
