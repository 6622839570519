import PropTypes from 'prop-types';
import React from 'react';
import VisibilitySensor from 'react-visibility-sensor';

export default class AnimateString extends React.PureComponent {
  static propTypes = {
    text: PropTypes.string,
    type: PropTypes.string,
    delay: PropTypes.number,
    yPos: PropTypes.number,
    minTop: PropTypes.number,
    staggerDelay: PropTypes.number,
    scaleFrom: PropTypes.number,
    speed: PropTypes.number,
    autoAlpha: PropTypes.number,
    className: PropTypes.string,
    rotationFrom: PropTypes.number,
    html: PropTypes.bool,
  };

  state = {
    animate: false,
  };

  string = [];

  animateText = (string, array, stagger) => {
    const breakWords = string.split(' ');
    const animateText = [];
    if (this.props.html) {
      animateText.push(
        <span
          ref={(c) => {
            array[0] = c;
          }}
          key={`word-${0}`}
          dangerouslySetInnerHTML={{__html: string}}
        />
      );
    }
    if (!this.props.html) {
      if (!stagger) {
        animateText.push(
          <span
            ref={(c) => {
              array[0] = c;
            }}
            key={`word-${0}`}>
            {string}
          </span>
        );
      } else {
        for (let x = 0; x < breakWords.length; x++) {
          animateText.push(
            <span
              ref={(c) => {
                array[x] = c;
              }}
              key={`word-${x}`}>
              {breakWords[x]}
            </span>
          );
        }
      }
    }
    return animateText;
  };

  tweenDatText = () => {
    const {delay, yPos, staggerDelay, scaleFrom, speed, autoAlpha, rotationFrom} = this.props;

    TweenMax.staggerFromTo(
      this.string,
      speed,
      {
        x: 0,
        y: `+=${yPos}`,
        autoAlpha,
        scale: scaleFrom,
        rotation: rotationFrom || 0,
      },
      {
        x: 0,
        y: 0,
        autoAlpha: 1,
        scale: 1,
        rotation: 0,
        ease: Elastic.easeOut.config(1, 1),
        force3D: true,
        delay,
      },
      staggerDelay
    );
  };

  onVisibilityChange = (isVisible) => {
    if (isVisible && !this.state.animate) {
      this.setState({animate: true});
      this.tweenDatText();
    }
  };

  render() {
    const {text, type, minTop, className, staggerDelay} = this.props;
    if (!text) return <div />;
    const animatedText = this.animateText(text, this.string, staggerDelay !== 0);
    let item;
    switch (type) {
      case 'h1':
        item = <h1>{animatedText}</h1>;
        break;
      case 'h2':
        item = <h2>{animatedText}</h2>;
        break;
      case 'h3':
        item = <h3>{animatedText}</h3>;
        break;
      case 'h4':
        item = <h4>{animatedText}</h4>;
        break;
      case 'h5':
        item = <h5>{animatedText}</h5>;
        break;
      default:
        item = <p>{animatedText}</p>;
    }
    const animateStringClass = className ? `animate-string ${className}` : 'animate-string';
    return (
      <VisibilitySensor
        onChange={this.onVisibilityChange}
        active={!this.state.animated}
        partialVisibility
        minTopValue={minTop}
        scrollCheck
        scrollDelay={10}>
        <div className={animateStringClass}>{item}</div>
      </VisibilitySensor>
    );
  }
}
