import parse from 'html-react-parser';
import PropTypes from 'prop-types';
import React from 'react';
import twemoji from 'twemoji';
import twitterText from 'twitter-text';

class Text extends React.Component {
  render() {
    const {data} = this.props;
    let {text} = data;
    const {entities, extended_entities} = data;

    // remove any embedded media links
    if (entities && entities.media) {
      entities.media.forEach((e) => {
        text = text.replace(e.url, '');
      });
    }

    // remove any quote links
    if (entities && data.quoted_status) {
      entities.urls.forEach((u) => {
        if (u.expanded_url.indexOf('/status/') > -1) {
          text = text.replace(u.url, '');
        }
      });
    }

    // replace + style links and mentions
    text = twitterText.autoLinkWithJSON(text, entities || {}, {
      usernameIncludeSymbol: true,
      targetBlank: true,
    });

    // replace + style emoji
    text = twemoji.parse(text);

    // remove any extended entities links
    if (extended_entities && extended_entities.media) {
      extended_entities.media.forEach((e) => {
        text = text.replace(e.display_url, '');
      });
    }

    return <p className="tweet-text">{parse(text)}</p>;
  }
}

Text.propTypes = {
  data: PropTypes.object,
};

Text.displayName = 'Text';

export default Text;
