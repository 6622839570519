import styled from 'styled-components';

import {Background} from '../../types';

interface Props {
  gradient?: Background;
}
const Section = styled.section<Props>`
  position: relative;
  padding: 5rem 0 10rem;
  display: flex;
  justify-content: center;
  color: white;

  ${({gradient}) =>
    gradient?.from && gradient?.to
      ? `background: linear-gradient(180deg, ${gradient.from} 0%, ${gradient.to} 100%);`
      : null}
`;

export default Section;
