import styled from 'styled-components';

import {breakpoints} from '../../../../constants/Breakpoints';

const Grid = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);

  @media ${breakpoints.mobileL} {
    grid-template-columns: 1fr;
  }
`;

export default Grid;
