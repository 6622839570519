import {getLocale} from '@epic-core/common';
import {generateRouteTo} from 'epic-fortnite-react';
import {connectToStores} from 'epic-alt-utils';
import Error404 from 'epic-fortnite-react/components/error/Error404';
import PropTypes from 'prop-types';
import React from 'react';
import {withRouter, Redirect} from 'react-router';

import PatchNotesActions from '../../actions/PatchNotesActions';
import LoadScreen from '../../components/loadScreen/LoadScreen';
import PatchNotesCarousel from '../../components/patchNotes/PatchNotesCarousel';

import PatchNotesHeader from '../../components/patchNotes/PatchNotesHeader';
import PatchNotesOverview from '../../components/patchNotes/PatchNotesOverview';
import PatchNotesSocialShare from '../../components/patchNotes/PatchNotesSocialShare';
import PatchNotesText from '../../components/patchNotes/PatchNotesText';
import {HeaderStore, PatchNotesStore} from '../../stores';

const fetchPatchNotes = (props) => {
  PatchNotesActions.getPatchNotes(props.match.params.slug);
};

const mapPatchNotesSection = (section, headerSections, {msie, firefox, mobile, tablet}) => {
  if (!section || !section.data) return null;
  switch (section.data._type) {
    case 'ReleaseNotes - Header':
      return (
        <PatchNotesHeader
          key={section._slug}
          headerSections={headerSections}
          mobile={mobile}
          tablet={tablet}
          msie={msie}
          firefox={firefox}
          content={section.data}
        />
      );
    case 'ReleaseNotes - RichTextArea':
      return <PatchNotesText key={section._slug} content={section.data} />;
    case 'ReleaseNotes - Carousel':
      return <PatchNotesCarousel key={section._slug} content={section.data} />;
    case 'Release Notes - Share Icons':
      return <PatchNotesSocialShare key={section._slug} content={section.data} />;
    case 'ReleaseNotes - TextSections':
      return <PatchNotesOverview key={section._slug} content={section.data} />;
    default:
      return null;
  }
};

@connectToStores
class PatchNotesView extends React.Component {
  static propTypes = {
    patchNotesStore: PropTypes.object,
    match: PropTypes.object,
    location: PropTypes.object,
  };

  static getStores() {
    return [HeaderStore, PatchNotesStore];
  }

  static getPropsFromStores() {
    return {
      msie: HeaderStore.getState().msie(),
      firefox: HeaderStore.getState().firefox(),
      patchNotesStore: PatchNotesStore.getState(),
      mobile: HeaderStore.getState().mobile(),
      tablet: HeaderStore.getState().tablet(),
    };
  }

  static getInitialActions() {
    return [fetchPatchNotes];
  }

  componentDidMount() {
    const currentSlug = this.props.match.params.slug;
    if (
      !this.props.patchNotesStore.loading &&
      (!this.props.patchNotesStore.patchNotes || this.props.patchNotesStore.slug !== currentSlug)
    ) {
      fetchPatchNotes(this.props);
    }
  }

  render() {
    const {patchNotesStore, match} = this.props;

    const locale = getLocale();

    if (!match.params.slug) {
      const location = this.props.location;
      const search = location.search || '';
      return (
        <Redirect
          to={generateRouteTo(
            {
              pathname: `/patch-notes/${patchNotesStore.latest}`,
              search,
            },
            location,
            locale
          )}
        />
      );
    }
    if (patchNotesStore.patchNotes && patchNotesStore.patchNotes.status === 404) {
      return <Error404 />;
    }

    const patchNotesSections = [];
    if (
      patchNotesStore.patchNotes &&
      patchNotesStore.patchNotes.sections &&
      patchNotesStore.patchNotes.sections.length > 0
    ) {
      const headerSections = patchNotesStore.patchNotes.sections.reduce((accumulator, item) => {
        if (item && item.data && item.data.sectionHeader) {
          accumulator.push({
            title: item.data.title,
            id: item.data.sectionKey,
            color: item.data.color,
          });
        }
        return accumulator;
      }, []);
      const {sections} = patchNotesStore.patchNotes;
      for (let i = 0; i < sections.length; i++) {
        const section = sections[i];
        patchNotesSections.push(mapPatchNotesSection(section, headerSections, this.props));
      }
    }

    return (
      <div className="patch-notes-view">
        {patchNotesSections}
        <LoadScreen show={patchNotesStore.loading} />
      </div>
    );
  }
}

export default withRouter(PatchNotesView);
