import {HttpErrorScreen, LoadScreen} from 'epic-fortnite-react';
import Error404 from 'epic-fortnite-react/components/error/Error404';
import {
  useCmsData,
  getInitialCmsPageSharedValues,
  URLPatternBuilder,
} from 'epic-fortnite-shared-values';
import {RouteComponentProps, withRouter} from 'react-router';
import {PageData} from 'epic-fortnite-shared-values';
import React from 'react';
import styled from 'styled-components';

import JumboFeature from '../../components/bundles/JumboFeature';
import SideBySide from '../../components/bundles/SideBySide';
import SimpleTextModule from '../../components/bundles/SimpleTextModule';
import Spotlight from '../../components/bundles/Spotlight';
import {FunctionalThemeProps} from '../../components/bundles/types';
import {CmsComponentMapper} from '@epic-mw/react-cms';
import MetaTags from '../../components/common/MetaTags';

interface CmsDataProps extends PageData {
  // In the future, as we nail down Functional CMS components, we want to revist here to update.
  sections: any;
  theme: FunctionalThemeProps;
}

interface BundlesParams {
  bundle: string;
}

type BundleDetailsProps = RouteComponentProps<BundlesParams>;

const BundleDetailsWrapper = styled.div<{
  bgColor: string;
  textColor: string;
  themeColor: string;
  customColor: string;
  useThemeColorForSpotlightText?: boolean;
}>`
  --bundle-theme-color: ${({themeColor}) => (themeColor ? themeColor : '#25D6CC')};
  --bundle-custom-color: ${({customColor}) => (customColor ? customColor : '#000')};

  --bundle-text-color--body: ${({textColor}) => (textColor ? textColor : '#fff')};
  --bundle-text-color--theme: ${({useThemeColorForSpotlightText}) =>
    useThemeColorForSpotlightText ? 'var(--bundle-theme-color)' : 'var(--bundle-text-color--body)'};

  background-color: ${({bgColor}) => (bgColor ? bgColor : '#fff')};
`;

const BundleDetailsView = ({match}) => {
  const {
    pageData,
    isLoading,
    error: cmsError,
  } = useCmsData<CmsDataProps>(`bundles/${match?.params?.bundle}`);

  if (isLoading) {
    return <LoadScreen show />;
  }

  if (cmsError) {
    return <HttpErrorScreen statusCode={cmsError.response?.status ?? 500} />;
  }

  if (!pageData) {
    return <Error404 />;
  }

  return (
    <BundleDetailsWrapper
      bgColor={pageData?.theme?.pageBackgroundColor ?? ''}
      themeColor={pageData?.theme?.highlightColor ?? ''}
      textColor={
        pageData.theme.useDarkTextColor
          ? pageData?.theme?.darkTextColor ?? ''
          : pageData?.theme?.lightTextColor ?? ''
      }
      customColor={pageData?.theme?.customColor ?? ''}
      useThemeColorForSpotlightText={pageData.theme.useThemeColorForSpotlightText}>
      <MetaTags pageModel={pageData} />
      <CmsComponentMapper
        dynamicPageDataArray={pageData?.sections?.items[0].items}
        componentMapping={{
          jumbo: (props: React.ComponentProps<typeof JumboFeature>) => <JumboFeature {...props} />,
          textModule: (props: React.ComponentProps<typeof SimpleTextModule>) => (
            <SimpleTextModule {...props} theme={pageData.theme} />
          ),
          sideBySide: (props: React.ComponentProps<typeof SideBySide>) => (
            <SideBySide {...props} theme={pageData.theme} />
          ),
          spotlight: (props: React.ComponentProps<typeof Spotlight>) => (
            <Spotlight {...props} theme={pageData.theme} />
          ),
        }}
      />
    </BundleDetailsWrapper>
  );
};

const slugBuilder: URLPatternBuilder = (match: BundleDetailsProps['match']) => {
  const slug = match.params.bundle;
  return `bundles/${slug}`;
};

BundleDetailsView.getInitialSharedValues = getInitialCmsPageSharedValues(slugBuilder);

export default withRouter(BundleDetailsView);
