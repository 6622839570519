import {BackgroundVideo} from 'epic-react-common';
import PropTypes from 'prop-types';
import React from 'react';
import root from 'window-or-global';

import ParallaxImage from './ParallaxImage';

export default class Background extends React.PureComponent {
  static propTypes = {
    mobile: PropTypes.bool,
    tablet: PropTypes.bool,
    background: PropTypes.object,
    toRoot: PropTypes.bool,
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    className: PropTypes.string,
    breakPoint: PropTypes.number,
  };

  constructor(props) {
    super(props);
    this.state = {
      mobile: props.mobile,
      breakPoint: props.breakPoint || 768,
    };
  }

  UNSAFE_componentWillMount() {
    if (root.addEventListener) {
      root.addEventListener('resize', this.screenSize);
    }
  }

  componentDidMount() {
    // call it once for firstime load
    // can't use in will mount because server render
    this.screenSize();
  }

  componentWillUnmount() {
    if (root.removeEventListener) {
      root.removeEventListener('resize', this.screenSize);
    }
  }

  screenSize = () => {
    if (!root.document) {
      return;
    }
    const breakPoint = this.state.breakPoint;
    const calcWidth =
      root.innerWidth ||
      root.document.documentElement.clientWidth ||
      root.document.body.clientWidth;
    if (calcWidth <= breakPoint && !this.state.mobile) {
      this.setState({mobile: true});
    } else if (calcWidth > breakPoint && this.state.mobile) {
      this.setState({mobile: false});
    }
  };

  render() {
    const {background = {}, className, toRoot, ...videoProps} = this.props;
    const {type, desktopImage, mobileImage, parallaxImage} = background;
    const isMobile = this.state.mobile;
    const bkImage = isMobile && mobileImage ? mobileImage : desktopImage;

    switch (type) {
      case 'image':
        return <div className="bk-img" style={{backgroundImage: `url(${bkImage || ''})`}} />;
      case 'parallax':
        return (
          <div className="bk-img" style={{backgroundImage: `url(${bkImage || ''})`}}>
            <ParallaxImage toRoot={toRoot} image={parallaxImage} />
          </div>
        );
      case 'object':
        return (
          <div className={className} style={{backgroundImage: `url(${bkImage || ''})`}}>
            {this.props.children}
          </div>
        );
      default:
        return (
          <BackgroundVideo
            background={background}
            mobile={this.props.mobile}
            tablet={this.props.tablet}
            {...videoProps}
          />
        );
    }
  }
}
