import {withLocalization} from '@epic-core/localization';
import {BaseButton, Container, Inline, Section, Stack, Text, Video} from 'epic-fortnite-react';
import React, {useState} from 'react';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {
  FacebookMessengerShareButton,
  FacebookShareButton,
  TwitterShareButton,
  VKShareButton,
} from 'react-share';
import styled from 'styled-components';

import {trackEvent} from '../../utils/eventTracking';

import {RiftTourModal} from './../riftTour/components/RiftTourModal';

export type SocialShareModalProps = {
  ariaLabel: string;
  backgroundImage?: string | null;
  logo: string;
  shareLink: string;
  shareText: string;
  eventTime: string;
  children: React.ReactNode;
  isOpen?: boolean;
  modalOverlayContent?: React.ReactNode;
  onDismiss?: () => void;
  video?: string;
  getMessage: (path: string) => string;
};

const ModalBackground = styled.div`
  height: 0;
  padding-top: calc(100% * (9 / 16));
  background-color: #c4c4c4;
  overflow: hidden;
  position: relative;
`;

const ModalBackgroundImage = styled.img`
  height: 100%;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 100%;
`;

const ModalBackgroundOverlayContent = styled.div`
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 1;
`;

const SocialLink = styled.div`
  color: #fff;
  display: block;
  height: 32px;
  width: 32px;
  button:hover,
  button.focus-visible {
    color: #fff;
    opacity: 0.8;
  }
  svg {
    fill: currentColor;
    width: 100%;
  }
`;

const StyledCopy = styled.div`
  background: #353535;
  border: 1px solid #797979;
  overflow: hidden;
  padding: 12px;
  width: 100%;
`;

const StyledCopyText = styled.p`
  color: #fff;
  font-size: 14px;
  line-height: 16px;
  margin: 0;
  word-break: break-word;
`;

const CopyLink = styled(BaseButton)`
  color: #68d8f4;
  padding: 4px;
  text-decoration: underline;
  &:hover {
    color: #52aac0;
  }
  &.focus-visible {
    box-shadow: 0px 0px 0px 4px #000;
  }
`;

const trackShareClick = (label: string, link: string): void => {
  trackEvent(
    {
      eventCategory: 'fn-web',
      eventAction: `user.click.body.shareModalCTA.${label}`,
      eventValue: 1,
      interactionType: 'click',
      eventLabel: `${link}`,
    },
    'identifiedOnly'
  );
};

export const SocialShareModal = ({
  ariaLabel,
  backgroundImage,
  logo,
  shareLink,
  shareText,
  eventTime,
  children,
  isOpen,
  modalOverlayContent,
  onDismiss,
  video,
  getMessage,
}: SocialShareModalProps) => {
  const [copied, setCopied] = useState(false);
  const copyText = `${shareText ? shareText.replace('${date}', eventTime) : ''} - ${shareLink}`;

  const handleOnDismiss = () => {
    setCopied(false);
    onDismiss && onDismiss();
  };

  const handleOnCopy = () => {
    if (!copied) trackShareClick('copy', shareLink);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <RiftTourModal ariaLabel={ariaLabel} isOpen={isOpen} maxWidth={800} onDismiss={handleOnDismiss}>
      <ModalBackground>
        {video && (
          <Video
            controls={false}
            height="100%"
            loop={true}
            muted={true}
            playing={true}
            playsinline={true}
            url={video}
            width="100%"
          />
        )}
        {backgroundImage && <ModalBackgroundImage src={backgroundImage} />}
        {modalOverlayContent && (
          <ModalBackgroundOverlayContent>{modalOverlayContent}</ModalBackgroundOverlayContent>
        )}
      </ModalBackground>
      <Section backgroundColor="rgba(0,0,0,0.8)" size="xl" textAlign="center">
        <Container size="xl">
          <Stack align="center" gap="lg" justify="center">
            {children}
            <Inline align="center" gap="sm" justify="center">
              <Text color="#fff" size="med" transform="uppercase">
                Share to
              </Text>
              {process.env.EPIC_FACEBOOK_MESSENGER_APPID && (
                <SocialLink>
                  <FacebookMessengerShareButton
                    appId={process.env.EPIC_FACEBOOK_MESSENGER_APPID}
                    url={shareLink}
                    onClick={() => trackShareClick('fb-messenger', shareLink)}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28">
                      <path
                        fillRule="evenodd"
                        d="M0 13.579C0 5.778 6.114 0 14 0s14 5.781 14 13.582c0 7.801-6.114 13.58-14 13.58-1.418 0-2.775-.19-4.053-.54a1.125 1.125 0 00-.749.056l-2.779 1.225a1.119 1.119 0 01-1.571-.99L4.77 24.42a1.106 1.106 0 00-.375-.798C1.673 21.187 0 17.659 0 13.579zm9.706-2.552l-4.113 6.524c-.395.626.375 1.33.963.882l4.417-3.353a.84.84 0 011.011-.003l3.273 2.453c.983.735 2.383.48 3.037-.56l4.116-6.52c.392-.626-.378-1.333-.965-.885l-4.418 3.352a.84.84 0 01-1.011.004l-3.272-2.453a2.101 2.101 0 00-3.038.56z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </FacebookMessengerShareButton>
                </SocialLink>
              )}
              <SocialLink>
                <TwitterShareButton
                  url={shareLink}
                  title={copyText}
                  onClick={() => trackShareClick('twitter', shareLink)}>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28">
                    <path d="M8.805 25.9c10.567 0 16.346-9.157 16.346-17.098 0-.26 0-.52-.017-.777A12.002 12.002 0 0028 4.915c-1.049.485-2.16.804-3.3.945a5.99 5.99 0 002.526-3.324 11.21 11.21 0 01-3.648 1.459c-1.78-1.981-4.61-2.466-6.902-1.183-2.291 1.283-3.475 4.015-2.888 6.663-4.619-.242-8.922-2.524-11.84-6.278-1.524 2.746-.745 6.258 1.78 8.022a5.512 5.512 0 01-2.608-.752v.076c0 2.86 1.928 5.324 4.609 5.89a5.49 5.49 0 01-2.594.104c.752 2.448 2.91 4.125 5.367 4.173-2.034 1.672-4.547 2.58-7.134 2.577-.458 0-.914-.03-1.368-.086a15.745 15.745 0 008.805 2.694" />
                  </svg>
                </TwitterShareButton>
              </SocialLink>
              <SocialLink>
                <FacebookShareButton
                  url={shareLink}
                  quote={copyText}
                  onClick={() => trackShareClick('facebook', shareLink)}>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28">
                    <path d="M1.545 0h24.91C27.308 0 28 .692 28 1.545v24.91c0 .853-.692 1.545-1.545 1.545h-7.144V17.172h3.654v-.005h.024l.546-4.239h-4.2V10.23c0-1.018.235-1.763 1.32-1.987a3.92 3.92 0 01.756-.065h2.244V4.393a31.02 31.02 0 00-3.258-.164c-2.924 0-5.005 1.614-5.373 4.603-.039.312-.058.64-.058.982v3.12H11.32v4.238h3.645V28H1.545A1.545 1.545 0 010 26.455V1.545C0 .692.692 0 1.545 0z" />
                  </svg>
                </FacebookShareButton>
              </SocialLink>
              <SocialLink>
                <VKShareButton
                  url={shareLink}
                  title={copyText}
                  onClick={() => trackShareClick('vk', shareLink)}>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 192">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M125.44 0H66.56C12.8 0 0 12.8 0 66.56v58.88C0 179.2 12.8 192 66.56 192h58.88c53.76 0 66.56-12.8 66.56-66.56V66.56C192 12.8 179.2 0 125.44 0zM153 61c4.234 0 5.124 2.18 4.234 5.146-1.78 8.158-18.837 32.186-18.837 32.186-1.483 2.372-2.076 3.559 0 6.229.731 1.024 2.292 2.552 4.077 4.301 1.836 1.798 3.91 3.83 5.564 5.785 6.014 6.765 10.546 12.472 11.808 16.406C161 135 159 137 155 137h-14c-3.75 0-5.638-2.1-9.718-6.639-1.73-1.924-3.853-4.286-6.678-7.112-8.307-8.009-11.866-9.047-13.943-9.047-2.818 0-3.661.798-3.661 4.798v12.555C107 135 105.899 137 97 137c-14.832 0-31.131-9.005-42.7-25.616-17.353-24.325-22.1-42.716-22.1-46.425C32.2 62.883 33 61 37 61h14c3.578 0 4.924 1.566 6.266 5.443 6.847 19.945 18.392 37.377 23.138 37.377 1.78 0 2.596-.82 2.596-5.34V77.863c-.321-5.868-2.371-8.406-3.89-10.286-.937-1.16-1.672-2.07-1.672-3.359C77.438 62.652 78.776 61 81 61h22c2.967 0 4 1.587 4 5.146v27.736c0 2.966 1.288 4.004 2.178 4.004 1.78 0 3.263-1.038 6.526-4.3 10.086-11.273 17.205-28.627 17.205-28.627.89-2.076 2.532-3.959 6.091-3.959h14z"
                    />
                  </svg>
                </VKShareButton>
              </SocialLink>
            </Inline>
            <StyledCopy>
              <Inline align="center" flex="start" gapX="sm" wrap={false}>
                <StyledCopyText>{copyText}</StyledCopyText>
                <CopyToClipboard onCopy={handleOnCopy} text={copyText}>
                  <CopyLink label={getMessage(`epic.common.${copied ? 'copied' : 'copy'}`)} />
                </CopyToClipboard>
              </Inline>
            </StyledCopy>
          </Stack>
        </Container>
      </Section>
    </RiftTourModal>
  );
};

export default withLocalization(SocialShareModal);
