import {datasource} from 'alt-utils/lib/decorators';
import {seamlessBootstrap, seamlessImmutable} from 'epic-alt-utils';
import {AllHtmlEntities} from 'html-entities';
import Immutable from 'seamless-immutable';
import root from 'window-or-global';

import RegisterActions from '../actions/RegisterActions';
import alt from '../alt';
import RegisterSource from '../sources/RegisterSource';

@datasource(RegisterSource)
@seamlessImmutable
class MobileStore {
  constructor() {
    this.on('error', (error) => {
      console.error(error);
    });
    this.on('bootstrap', seamlessBootstrap.bind(this, null));
    const initialState = Immutable({
      mobileData: null,
      loading: false,
      status: null,
      redeemEmailSent: false,
      platform: null,
    });
    this.state = initialState;
    this.bindActions(RegisterActions);
  }

  onRegister({locale, platform}) {
    if (root.dataLayer && typeof root.dataLayer.push === 'function') {
      root.dataLayer.push({
        mobilePlatform: platform,
      });
    }
    this.getInstance().register(locale, platform);
    this.setState(
      this.state.merge({
        loading: true,
        error: false,
      })
    );
  }

  onRegisterSuccess(response) {
    this.setState(
      this.state.merge({
        loading: false,
        status: response.data,
      })
    );
  }

  onRegisterFailure(response) {
    console.error('Failed to register user');
    this.setState(
      this.state.merge({
        loading: true,
        error: true,
      })
    );
  }

  onGetStatus(locale) {
    this.getInstance().getStatus(locale);
    this.setState(
      this.state.merge({
        loading: true,
        error: false,
      })
    );
  }

  onGetStatusSuccess(response) {
    this.setState(
      this.state.merge({
        loading: false,
        status: response.data,
      })
    );
  }

  onGetStatusFailure(response) {
    console.error('Failed to get user status');
    this.setState(
      this.state.merge({
        loading: false,
        error: true,
        status: response.data,
      })
    );
  }

  onSendRedeemedEmail(locale) {
    this.getInstance().sendRedeemedEmail(locale);
  }

  onSendRedeemedEmailSuccess(response) {
    this.setState(
      this.state.merge({
        redeemEmailSent: true,
      })
    );
  }

  onSendRedeemedEmailFailure(response) {
    console.error('Failed to send redeemed email');
  }

  onSetGameFlow(mode) {
    //Sanitize user input
    const entities = new AllHtmlEntities();
    this.setState(this.state.merge({mode: entities.encodeNonUTF(mode)}));
  }

  onSetMobileCode(code) {
    //Sanitize user input
    const entities = new AllHtmlEntities();
    this.setState(this.state.merge({code: entities.encodeNonUTF(code)}));
  }

  onUpdateSelectedDevice(platform) {
    if (root.dataLayer && typeof root.dataLayer.push === 'function') {
      root.dataLayer.push({
        mobilePlatform: platform,
      });
    }
    this.setState(
      this.state.merge({
        platform,
      })
    );
  }

  onEntitleUser() {
    this.getInstance().entitleUser();
  }

  onEntitleUserSuccess(response) {
    //console.log('successfully entitled user');
  }

  onEntitleUserFailure() {
    console.error('onEntitleUserFailure() failed to entitle user');
  }
}

export default alt.createStore(MobileStore, 'MobileStore');
