import epicTracking from 'epic-tracking';
import React from 'react';
import AddToCalendar from 'react-add-to-calendar';

class PartyRoyaleCalendarPicker extends AddToCalendar {
  handleDropdownLinkClick(e) {
    try {
      epicTracking.trackEvent('Interaction', {
        interactionType: 'click',
        eventCategory: 'EventCalendar',
        eventLabel: this.props.event.title,
        eventAction: `CalendarAdd${e.target.className}`,
      });
    } catch (error) {
      // Ignore.
    }
    super.handleDropdownLinkClick(e);
  }

  renderDropdown() {
    return (
      <div className="add-to-calendar-container">
        <div className="add-to-calendar-caret">{super.renderDropdown()}</div>
      </div>
    );
  }
}

export default PartyRoyaleCalendarPicker;
