import {Platform} from './common';

export enum SupportedType {
    NONE = 'NONE',
    MOD = 'MOD',
    MOD_STAGING = 'MOD_STAGING'
}

export interface ProductConfiguration {
    productId: string;
    certSandbox?: string;
    priceTierId?: string;
    ownerId?: string;
    merchantGroup?: string;
    lifeCycle?: string;
    supportedTypes: SupportedType[];
    productType?: string;
    liveSandbox?: string;
}

export interface ProductSandboxes {
    productLiveSandboxId: string;
    productCertSandboxId?: string;
    productDraftSandboxId?: string;
}

export interface ProductSandboxesConfiguration extends ProductSandboxes {
    productId: string;
}

export interface ModProduct {
    id: string;
    slug: string;
    sandboxId: string;
    productName: string;
    platforms: Platform[];
    isModMarketEnabled: boolean;
    isModMarketSuspended: boolean;
    /**
     * @deprecated in favor of isModCreationAllowed
     * @see isModCreationAllowed
     */
    isUserEntitled: boolean;
    isModCreationAllowed: boolean;
    isOrgProductOwner?: boolean;
}

export interface ModProductSearchParams {
    productId: string;
    productSandboxId?: string;
    orgId?: string;
}

export interface ModProductSearchBySlugParams extends Omit<ModProductSearchParams, 'productId'> {
    productSlug: string;
}
