import React from 'react';
import flattenChildren from 'react-keyed-flatten-children';
import styled from 'styled-components';

import {AnimateIn} from '../';
import {space} from '../../../theme/theme';
import {Spaces} from '../../../types/theme';

type StackAlign = 'center' | 'left' | 'right';
type StackJustify = 'center' | 'end' | 'start';

export type StyledStackProps = {
  $gap?: Spaces;
  $justify?: StackJustify;
};

export type StyledStackItemProps = {
  $align?: StackAlign;
};

export type StackProps = {
  align?: StackAlign;
  animateIn?: boolean;
  children: React.ReactNode;
  gap?: Spaces;
  justify?: StackJustify;
};

const StyledStack = styled.div<StyledStackProps>`
  --stack-gap: ${({$gap}) =>
    $gap ? `${space[`${$gap}`]}px` : '0px'}; /* 0px fallback needed for calc to work */

  display: flex;
  // flex-grow: 1; commented out to allow items to stretch in parent container
  flex-direction: column;
  justify-content: ${({$justify}) =>
    $justify === 'start' ? 'flex-start' : $justify === 'end' ? 'flex-end' : 'center'};
  min-width: 0;
`;

const StyledStackItem = styled.div<StyledStackItemProps>`
  align-items: ${({$align}) =>
    $align === 'left' ? 'flex-start' : $align === 'right' ? 'flex-end' : 'center'};
  display: flex;
  flex-direction: column;
  list-style: none;
  position: relative;

  &:empty {
    height: 0;
    margin-top: calc(var(--stack-gap) * -1);
    overflow: hidden;
    visibility: hidden;
  }

  &:not(:last-child) {
    margin-bottom: var(--stack-gap);

    html[lang='pt-BR'] & {
      text-align: center;
    }
  }
`;

export const Stack = ({align, animateIn, children, gap, justify}: StackProps) => {
  return (
    <>
      {flattenChildren(children).length !== 0 && (
        <StyledStack $gap={gap} $justify={justify}>
          {React.Children.map(flattenChildren(children), (child) => {
            if (child !== null) {
              let key: React.Key | null = null;
              // child has a type of ReactElement | ReactText
              // ReactText is an alias for string | number
              // We narrow the type here to ReactElement so we can pull the key out
              if (typeof child !== 'string' && typeof child !== 'number') {
                key = child.key;
              }

              return (
                <StyledStackItem $align={align} key={key}>
                  {animateIn ? (
                    <AnimateIn delay={typeof key === 'number' ? key++ : 1}>{child}</AnimateIn>
                  ) : (
                    child
                  )}
                </StyledStackItem>
              );
            }
          })}
        </StyledStack>
      )}
    </>
  );
};
