import classnames from 'classnames';
import epicTracking from 'epic-tracking';
import React from 'react';

import {DownloadCTA} from '../types';

/**
 * CMS Content type => Cosplay - Download Button
 */

export interface Props extends DownloadCTA {
  id: string;
  className?: string;
}

const DownloadButtonBase = ({id, link, text, className}: Props) => {
  const trackDownloadClick = (e) => {
    epicTracking.trackEvent('Interaction', {
      interactionType: 'click',
      eventCategory: 'cta-cosplay-button',
      eventAction: 'cta-action',
      eventLabel: id,
    });
  };

  const classes = classnames('btn btn-primary', [className]);
  return (
    <a
      className={classes}
      id={id}
      onClick={trackDownloadClick}
      href={link}
      target="_blank"
      rel="noopener noreferrer">
      <span>{text}</span>
    </a>
  );
};

export default DownloadButtonBase;
