import {getLocale} from '@epic-core/common';
import {getGuideBackgroundImages} from 'epic-fortnite-react/utils/FortniteUtils';
import PropTypes from 'prop-types';
import React from 'react';

import GuideMedia from './GuideMedia';
import GuideMenu from './GuideMenu';
import GuideSelection from './GuideSelection';
import GuideStock from './GuideStock';
import GuideTip from './GuideTip';

export default class BasicItem extends React.Component {
  static propTypes = {
    menu: PropTypes.object,
    guides: PropTypes.array,
    mobile: PropTypes.bool,
    match: PropTypes.object,
    history: PropTypes.object,
    activeTab: PropTypes.string,
    guide: PropTypes.object,
  };

  constructor(props) {
    super(props);
    const activeTab = props.activeTab && parseInt(props.activeTab, 10);
    this.state = {
      activeTab: activeTab || 0,
    };
  }

  onTabChange = (event) => {
    const {index} = event.target.dataset;
    this.setState({activeTab: parseInt(index, 10) || 0});
  };

  render() {
    const {activeTab} = this.state;
    const {
      menu,
      guides,
      mobile,
      match,
      history,
      guide: {_slug, info},
    } = this.props;
    const {itemList, title, subText, subTitle, tipTitle} = info;
    const isList = !!itemList;
    const current = isList ? itemList[activeTab] : info;
    const images = getGuideBackgroundImages(info);
    const locale = getLocale();

    return (
      <div className="content">
        <GuideMenu
          slug={_slug}
          menu={menu}
          guides={guides}
          mobile={mobile}
          match={match}
          history={history}
          activeTab={activeTab}
          locale={locale}
        />
        <div className="guide-info" style={{backgroundImage: images}}>
          <div className={isList ? 'guide-info-head' : 'guide-info-content'}>
            <h2>{title}</h2>
            <h5 dangerouslySetInnerHTML={{__html: subText}} />
            {subTitle && <h3>{subTitle}</h3>}
            {isList && (
              <GuideSelection
                list={itemList}
                activeTab={activeTab}
                onTabChange={this.onTabChange}
              />
            )}
          </div>
          <div className="guide-info-content">
            <GuideMedia medias={current.medias} activeTab={activeTab} />
            {current.mapImage && (
              <div className="guide-map">
                <div style={{backgroundImage: `url(${current.mapImage})`}} />
                <div
                  className="cover"
                  style={{backgroundImage: `url(${current.mapHover || ''})`}}
                />
              </div>
            )}
            {current.stockTitle && <h3>{current.stockTitle}</h3>}
            <GuideStock stocks={current.stocks} info={info} />
            {current.extraStockTitle && <h3>{current.extraStockTitle}</h3>}
            <GuideStock stocks={current.extraStocks} info={info} />
            <GuideTip tips={current.tips} tipTitle={tipTitle} />
            {current.extraContent && (
              <div
                className="guide-extra"
                dangerouslySetInnerHTML={{__html: current.extraContent}}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}
