import styled from 'styled-components';

import {breakpoints} from '../../../../constants/Breakpoints';

const SelectContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;

  & .select-wrapper {
    width: 100%;
  }

  @media ${breakpoints.mobileL} {
    & .select-wrapper {
      margin-top: 2rem;
    }
  }
`;

export default SelectContainer;
