import {ContextMessage as Message} from '@epic-core/localization';
import {connectToStores} from 'epic-alt-utils';
import HttpErrorScreen from 'epic-fortnite-react/components/error/HttpErrorScreen';
import PropTypes from 'prop-types';
import React from 'react';

import CmsLoadScreen from '../../components/loadScreen/CmsLoadScreen';
import FaqSection from '../../components/faq/FaqSection';
import MetaTags from '../../components/common/MetaTags';
import QuickLink from '../../components/faq/QuickLink';

import {CmsStore} from '../../stores';

import {fetchCmsData} from '../../utils/CmsStoreUtils';

/**
 * FAQ View
 */
const cmsUrlPattern = 'faq';

@connectToStores
class FaqView extends React.Component {
  static propTypes = {
    cmsStore: PropTypes.object,
  };

  static getStores() {
    return [CmsStore];
  }

  static getInitialActions() {
    return [fetchCmsData(cmsUrlPattern)];
  }

  static getPropsFromStores() {
    return {
      cmsStore: CmsStore.getState(),
    };
  }

  render() {
    const {cmsStore} = this.props;

    if (cmsStore.pages[cmsUrlPattern].loading) {
      return <CmsLoadScreen page={cmsStore?.pages[cmsUrlPattern]} />;
    }

    if (!cmsStore?.pages[cmsUrlPattern] || !cmsStore.pages[cmsUrlPattern].data) {
      return <HttpErrorScreen statusCode={404} />;
    }

    if (cmsStore.pages[cmsUrlPattern].error) {
      return <HttpErrorScreen statusCode={cmsStore.pages[cmsUrlPattern].error?.statusCode} />;
    }

    const faqData = cmsStore.pages.faq.data;
    const quickLinks = faqData.subSection.sectionList.map((item, index) => {
      const sectionTitle = item.sectTitle;
      const titleID = sectionTitle.replace(/ |\(|\)/g, '-').toLowerCase();
      return <QuickLink key={`sect-${sectionTitle}`} title={sectionTitle} hashTag={titleID} />;
    });
    const FAQsection = faqData.subSection.sectionList.map((item, index) => {
      const sectionContent = item.faqList;
      const sectionTitle = item.sectTitle;
      return (
        <FaqSection key={`${sectionTitle}`} title={sectionTitle} sectContent={sectionContent} />
      );
    });
    return (
      <div id="faq" className="light-bg">
        <div className="container faq">
          <MetaTags pageModel={faqData} />
          <h1 className="site-block-page-title">{faqData.mainTitle}</h1>
          <div className="site-block">
            <div className="col-md-4 col-md-push-8">
              <div className="quickLink">
                <h3>
                  <Message code="epic.fortnite.faq.jump" />
                </h3>
                <ul>{quickLinks}</ul>
              </div>
            </div>
            <div className="col-md-8 col-md-pull-4">
              {faqData.lastModifiedDate ? (
                <span className="last-modified-date">{faqData.lastModifiedDate}</span>
              ) : null}
              {FAQsection}
            </div>
            <div className="clearfix" />
          </div>
        </div>
      </div>
    );
  }
}

export default FaqView;
