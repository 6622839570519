import styled, {css} from 'styled-components';

import {Device} from '../../../constants';

import LoadoutImageContainer from './LoadoutImageContainer';

const HeroLoadoutContainer = styled.div<{reverse?: boolean}>`
  height: 100%;
  width: 100%;
  max-width: 1440px;
  position: absolute;
  margin: 0 auto;
  display: flex;
  flex-direction: ${(props) => (props.reverse ? 'column-reverse' : 'column')};
  align-items: flex-end;
  justify-content: space-around;

  ${(props) =>
    !props.reverse
      ? css`
          ${LoadoutImageContainer} {
            top: 7%;
            right: 23%;
            width: 120px;
          }
          ${LoadoutImageContainer}:first-child {
            top: 35%;
            right: 22%;
            width: 180px;
            @media screen and ${Device.mobileL} {
              right: 3%;
            }
          }
          #midas-0-right {
            bottom: unset;
            top: 24%;
            right: 25%;
            width: 220px;
            @media screen and ${Device.laptopL} {
              right: 20%;
              top: 16%;
              width: 200px !important;
            }
            @media screen and ${Device.laptop} {
              right: 8%;
              top: 16%;
              width: 155px !important;
            }
            @media screen and ${Device.mobileL} {
              width: 100px !important;
            }
          }
          #poisonivy-0-right {
            top: 30%;
            right: 23%;
            width: 200px;
            @media screen and ${Device.laptop} {
              right: 13%;
            }
            @media screen and ${Device.mobileL} {
              top: 15%;
              right: 7%;
            }
          }
          @media screen and (max-width: 940px) {
            align-items: center;
          }
          @media screen and ${Device.mobileL} {
            align-items: flex-end;
          }
        `
      : null}

  ${(props) =>
    props.reverse
      ? css`
          ${LoadoutImageContainer}:first-child {
            top: 23%;
            left: 22%;
            width: 140px;
            @media screen and ${Device.mobileL} {
              left: 13%;
            }
          }
          ${LoadoutImageContainer}:last-child {
            bottom: 55%;
            left: 16%;
            width: 145px;
            @media screen and ${Device.mobileL} {
              bottom: 52%;
              left: 4%;
            }
          }

          #poisonivy-0-left {
            width: 240px;
            bottom: unset;
            top: 27%;
            left: 17%;
            @media screen and ${Device.laptop} {
              left: 12%;
            }
            @media screen and ${Device.mobileL} {
              top: 13%;
              left: 4%;
            }
          }
          #midas-0-left {
            bottom: 55%;
            left: 14%;
            width: 230px;
            @media screen and ${Device.laptopL} {
              left: 20%;
            }
            @media screen and ${Device.laptop} {
              bottom: unset;
              left: 5%;
              top: 15%;
              width: 200px !important;
            }
            @media screen and ${Device.mobileL} {
              width: 100px !important;
            }
          }
          @media screen and (max-width: 940px) {
            align-items: center;
          }
        `
      : null}

    @media screen and (max-width: 940px) {
    height: 100%;
  }
`;

export default HeroLoadoutContainer;
