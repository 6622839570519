import axios from 'axios';
import root from 'window-or-global';

import CosplayArchiveActions from '../actions/CosplayArchiveActions';
import Url from '../constants/Url';

const CosplayArchiveSource = {
  getArchivePages: {
    remote(state, locale, {page, ordering}) {
      return axios.get(Url().COSPLAY.ARCHIVE(locale, page, ordering));
    },

    success: CosplayArchiveActions.getArchivePagesSuccess,
    error: CosplayArchiveActions.getArchivePagesFailure,

    shouldFetch() {
      return !root.__server_side_render;
    },
  },
};

export default CosplayArchiveSource;
