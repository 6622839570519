import root from 'window-or-global';

export default function () {
    let appContext = '';
    if (root.appContext && root.appContext !== '/') {
        appContext = root.appContext;
    }
    if (!root.location.origin) {
        const port = root.location.port ? `:${root.location.port}` : '';
        root.location.origin = `${root.location.protocol}//${root.location.hostname}${port}`;
    }
    let base = root.apiHost ? root.apiHost : root.location.origin + appContext;

    // hacky fix to add /competitive to the URL's if on the competitive site
    if (root.location.pathname.includes('/competitive')) {
        base += '/competitive';
    }

    const socialShareUrls = {
        FACEBOOK: 'https://graph.facebook.com/?id=',
        TWITTER: 'https://public.newsharecounts.com/count.json?url='
    };
    const URL = {
        BLOG: {
            GETPOSTS: `${base}/api/blog/getPosts`,
            GETPOST: `${base}/api/blog/getPost`,
            GETFEATURED: `${base}/api/blog/getFeatured`,
            GETCOMMENTCOUNT: `${base}/assets/ajax-get-comment-count`,
            SOCIALSHARE: socialShareUrls
        }
    };
    return URL;
}
