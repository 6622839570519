import {getLocale} from '@epic-core/common';
import axios from 'axios';
import epicTracking from 'epic-tracking';
import {v4 as uuidv4} from 'uuid';

export interface Payload {
  eventLabel?: string;
  eventValue?: number | string;
  eventCategory?: string;
  eventAction: string;
  interactionType: string;
}

type TrackingMode = 'privateOnly' | 'identifiedOnly' | 'both';

const trackPrivately = (payload: Payload): void => {
  const url = 'https://x4qym5ga7a.execute-api.us-east-1.amazonaws.com/gamedev';
  const id = uuidv4();
  const locale = getLocale();
  const apiPayload = {...payload, id, locale};

  try {
    axios.post(url, apiPayload);
  } catch (error) {
    console.error(error);
  }
};

export function trackEvent(payload: Payload, mode: TrackingMode = 'both') {
  switch (mode) {
    case 'privateOnly':
      trackPrivately(payload);
      break;
    case 'identifiedOnly':
      epicTracking.trackEvent('Interaction', payload);
      break;

    case 'both':
      trackPrivately(payload);
      epicTracking.trackEvent('Interaction', payload);
      break;

    default:
      console.error(`Invalid mode: ${mode}`);
  }
}

/* Notes

1. same tracking payload is handed to epicTracking and private Tracking
2. private tracking receives additional random ID (generate UUID v4)
3. should be possible to deactivate one by handing in a flag (e.g. for pageview tracking)
4. export custom hook for pageview in separate file
*/
