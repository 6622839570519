import PropTypes from 'prop-types';
import React from 'react';
import VisibilitySensor from 'react-visibility-sensor';

export default class CreativeImageCard extends React.PureComponent {
  static propTypes = {
    data: PropTypes.object,
    mobile: PropTypes.bool,
    tablet: PropTypes.bool,
  };

  state = {
    animated: false,
  };

  items = [];

  inView = (isVisible) => {
    if (isVisible && !this.state.animated) {
      this.setState({animated: true});
      this.contentAnimation();
    }
  };

  contentAnimation = () => {
    const item = this.items;
    TweenMax.staggerFromTo(
      item,
      3,
      {
        y: '+=150',
        autoAlpha: 0,
      },
      {
        y: 0,
        autoAlpha: 1,
        ease: Elastic.easeOut.config(1, 1),
        force3D: true,
      },
      0.1
    );
  };

  render() {
    const {data, mobile, tablet} = this.props;
    const {title = '', description, backgroundImage, disable} = data;

    if (disable) return <div />;

    let image = backgroundImage ? backgroundImage.desktopImage : '';
    if (backgroundImage && mobile) {
      image = backgroundImage.mobileImage;
    } else if (backgroundImage && tablet) {
      image = backgroundImage.tabletImage;
    }

    const bkStyle = {backgroundImage: `url(${image})`};

    return (
      <div className="creative-image-card">
        {image !== '' ? <div className="bkgrdImage" style={bkStyle} /> : null}
        <div className="section">
          <VisibilitySensor
            onChange={this.inView}
            active={!this.state.animated}
            scrollCheck
            scrollDelay={10}>
            <div className="content text-center">
              <h1
                ref={(c) => {
                  this.items[0] = c;
                }}>
                {title}
              </h1>
              <div
                ref={(c) => {
                  this.items[1] = c;
                }}
                className="description"
                dangerouslySetInnerHTML={{__html: description}}
              />
            </div>
          </VisibilitySensor>
        </div>
      </div>
    );
  }
}
