import {HtmlTemplate} from 'epic-fortnite-react';
import styled from 'styled-components';
import React from 'react';
import {Container} from 'epic-fortnite-react';
import {CoreSection} from './CoreSection';

const StyledRichText = styled.div<{
  $contentColor: string;
  $linkColor: string;
  $linkFont?: string;
  $linkSize?: string;
}>`
  @media (min-width: 1024px) {
    font-size: 20px;
  }

  color: ${({$contentColor}) => $contentColor} !important;
  font-family: 'OpenSans, sans-serif';
  font-size: 16px;
  font-weight: 600;
  margin: 0 auto;
  max-width: 800px;
  text-align: left;
  padding-top: 16px;
  padding-bottom: 64px;

  a {
    color: ${({$linkColor}) => $linkColor} !important;
    display: inline-block;
    text-decoration: underline !important;
  }

  &&&& h2 {
    @media (min-width: 1024px) {
      font-size: 44px;
    }

    font-size: 32px;
    margin-bottom: 24px;
  }

  &&&& ul,
  &&&& p {
    @media (min-width: 1024px) {
      line-height: 32px;
    }

    font-size: inherit;
    line-height: 20px;
  }

  html[lang='ja'],
  html[lang='ko'] & {
    word-break: keep-all;
  }
`;

export const SimpleRichTextSection = ({
  textContent,
  contentColor,
  coreSectionProps,
  cta,
  linkColor,
  linkFont,
  linkSize,
}) => {
  return (
    <CoreSection coreSectionProps={coreSectionProps}>
      <Container maxWidth={1600} size="med">
        <StyledRichText
          $contentColor={contentColor}
          $linkColor={linkColor}
          $linkFont={linkFont}
          $linkSize={linkSize}>
          {textContent && <HtmlTemplate html={textContent} />}
        </StyledRichText>
      </Container>
    </CoreSection>
  );
};
