import {getLocale} from '@epic-core/common';
import {EpicGamesFooter, EpicGamesFooterProps} from 'epic-games-footer';
import {LocalizationContextProps} from 'epic-games-nav-shared';
import React, {useCallback} from 'react';
import styled from 'styled-components';
import root from 'window-or-global';

import {Account} from '../../stores/types';
import {navTheme} from '../mainNav/nav.theme';
import {FortniteLinkComponent} from '../mainNav/FortniteLinkComponent';

const Styled = styled.div`
  position: relative;

  .news-legal-wrap .tags {
    display: flex;
    flex-direction: column;

    img:last-of-type {
      margin: 0 auto;
    }
  }
`;

interface Props {
  config?: EpicGamesFooterProps;
  i18n?: LocalizationContextProps;
  account?: Account;
}

export const Footer = ({config, i18n, account}: Props): JSX.Element => {
  const email = account?.email || '';
  const legalTags = config?.legalTags || [];
  const locale = i18n?.locale || getLocale();

  const upOnClick = useCallback((e) => {
    if (root.Events && root.Events.emitter && root.Events.SCROLL_TO_TOP) {
      root.Events.emitter._fireEvent(root.Events.SCROLL_TO_TOP);
    }
    root.document.body.scrollTop = 0;
    root.document.documentElement.scrollTop = 0;
  }, []);

  return (
    <Styled>
      <EpicGamesFooter
        links={config?.links}
        isLoggedIn={account?.isLoggedIn}
        socialLinks={config?.socialLinks || []}
        copyrights={config?.copyrights || []}
        legalTags={legalTags}
        hideDefaultTermsPolicy={config?.hideDefaultTermsPolicy}
        moreLegalLinks={config?.moreLegalLinks}
        logos={config?.logos || []}
        messages={config?.messages || {}}
        upOnClick={upOnClick}
        newLineCopyrights={config?.newLineCopyrights}
        newsLetterConfigs={config?.newsLetterConfigs || {}}
        enableNewsLetter={config?.newsLetterConfigs?.enableNewsLetterCMS && !account?.cabinedMode}
        i18n={i18n}
        locale={locale}
        email={email}
        theme={navTheme}
        LinkComponent={FortniteLinkComponent}
      />
    </Styled>
  );
};
