import PropTypes from 'prop-types';
import React, {useLayoutEffect, useState} from 'react';
import root from 'window-or-global';

import ScreenSizeContext from './ScreenSizeContext';

const ScreenSizeProviderClient = ({children}) => {
  const [size, setSize] = useState({
    innerWidth: root.innerWidth,
    innerHeight: root.innerHeight,
  });
  useLayoutEffect(() => {
    const updateSize = () =>
      setSize({
        innerWidth: root.innerWidth,
        innerHeight: root.innerHeight,
      });
    root.addEventListener('resize', updateSize);
    updateSize();
    return () => root.removeEventListener('resize', updateSize);
  }, []);

  return <ScreenSizeContext.Provider value={size}>{children}</ScreenSizeContext.Provider>;
};

ScreenSizeProviderClient.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.object), PropTypes.object]),
};

export default ScreenSizeProviderClient;
