import Proptypes from 'prop-types';
import React from 'react';

import Poster from './PosterItem';

const PosterList = ({data}) => {
  const {artistPosters} = data;
  if (!artistPosters || !artistPosters.length) return null;
  const size = Math.round(12 / artistPosters.length);
  const posters = artistPosters.map((item, index) => (
    <Poster key={`poster${index}`} data={item} size={size} />
  ));
  return (
    <div className="PosterList">
      <div className="container-fluid">
        <div className="row">{posters}</div>
      </div>
    </div>
  );
};

PosterList.propTypes = {
  data: Proptypes.shape({
    artistPosters: Proptypes.arrayOf(
      Proptypes.shape({
        name: Proptypes.string,
        poster: Proptypes.string,
      })
    ),
    name: Proptypes.string,
  }).isRequired,
};

export default PosterList;
