import PropTypes from 'prop-types';
import React, {useMemo} from 'react';
import root from 'window-or-global';

import ScreenSizeContext from './ScreenSizeContext';

// Provider for when rendering server side to prevent useLayoutEffect warning.
const ScreenSizeProviderServer = ({children}) => {
  const size = useMemo(
    () => ({
      innerWidth: root.innerWidth,
      innerHeight: root.innerHeight,
    }),
    [root.innerHeight, root.innerWidth]
  );
  return <ScreenSizeContext.Provider value={size}>{children}</ScreenSizeContext.Provider>;
};

ScreenSizeProviderServer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.object), PropTypes.object]),
};

export default ScreenSizeProviderServer;
