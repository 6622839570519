import {datasource} from 'alt-utils/lib/decorators';
import {seamlessImmutable, seamlessBootstrap} from 'epic-alt-utils';
import alt from 'epic-fortnite-react/alt';
import Immutable from 'seamless-immutable';

import SubscribeActions from '../actions/SubscribeActions';
import SubscribeSource from '../sources/SubscribeSource';

@datasource(SubscribeSource)
@seamlessImmutable

/*
 * NOTE: This Store is not being used at the moment.
 * */
class SubscribeStore {
  constructor() {
    this.on('error', (error) => {
      console.error(error);
    });
    this.on('bootstrap', seamlessBootstrap.bind(this, null));
    this.state = Immutable({
      error: null,
      isSubscribed: false,
    });
    this.bindActions(SubscribeActions);
  }

  onAddUserToNewsletter() {
    if (!this.state.isSubscribed) {
      this.getInstance().addUserToNewsletter();
    } else {
      console.log('User has already been subscribed');
    }
  }

  onAddUserToNewsletterSuccess(response) {
    if (response.data && response.data.isSuccess) {
      console.log('Subscribed successfully');
      this.setState(
        this.state.merge({
          loading: false,
          subscribeSuccess: true,
          subscribeFailed: false,
          isSubscribed: true,
        })
      );
    } else {
      console.error('fail to subscribe newsletter');
      this.setState(
        this.state.merge({
          loading: false,
          subscribeSuccess: false,
          subscribeFailed: true,
          isSubscribed: false,
        })
      );
    }
  }

  onAddUserToNewsletterFailure(error) {
    console.log('error', error);
    this.setState(
      this.state.merge({
        loading: false,
        subscribeSuccess: false,
        subscribeFailed: true,
      })
    );
  }
}

export default alt.createStore(SubscribeStore, 'SubscribeStore');
