import {Font, Space} from '../types/theme';

export const font: Font = {
  BurbankBigRegularBlack: {
    fontMetrics: {
      capHeight: 718,
      ascent: 768,
      descent: -245,
      lineGap: 0,
      unitsPerEm: 1000,
      trackingCrop: -0.025,
    },
    fontSizes: {
      '9xl': {
        capHeight: 112,
        lineGap: 32,
        letterSpacing: 0.0125,
      },
      '8xl': {
        capHeight: 96,
        lineGap: 24,
        letterSpacing: 0.0125,
      },
      '7xl': {
        capHeight: 80,
        lineGap: 24,
        letterSpacing: 0.0125,
      },
      '6xl': {
        capHeight: 64,
        lineGap: 24,
        letterSpacing: 0.0125,
      },
      '5xl': {
        capHeight: 48,
        lineGap: 24,
        letterSpacing: 0.0125,
      },
      '4xl': {
        capHeight: 40,
        lineGap: 16,
        letterSpacing: 0.0125,
      },
      '3xl': {
        capHeight: 32,
        lineGap: 16,
        letterSpacing: 0.0125,
      },
      '2xl': {
        capHeight: 24,
        lineGap: 8,
        letterSpacing: 0.025,
      },
      xl: {
        capHeight: 20,
        lineGap: 8,
        letterSpacing: 0.025,
      },
      lg: {
        capHeight: 16,
        lineGap: 8,
        letterSpacing: 0.025,
      },
      med: {
        capHeight: 12,
        lineGap: 8,
        letterSpacing: 0.025,
      },
      sm: {
        capHeight: 10,
        lineGap: 4,
        letterSpacing: 0.025,
      },
      xs: {
        capHeight: 8,
        lineGap: 4,
        letterSpacing: 0.025,
      },
    },
  },
  OpenSans: {
    fontMetrics: {
      capHeight: 1462,
      ascent: 2189,
      descent: -600,
      lineGap: 0,
      unitsPerEm: 2048,
      trackingCrop: -0.1,
    },
    fontSizes: {
      '9xl': {
        capHeight: 112,
        lineGap: 80,
        letterSpacing: -0.0375,
      },
      '8xl': {
        capHeight: 96,
        lineGap: 72,
        letterSpacing: -0.0375,
      },
      '7xl': {
        capHeight: 80,
        lineGap: 64,
        letterSpacing: -0.025,
      },
      '6xl': {
        capHeight: 64,
        lineGap: 64,
        letterSpacing: -0.025,
      },
      '5xl': {
        capHeight: 48,
        lineGap: 48,
        letterSpacing: -0.0125,
      },
      '4xl': {
        capHeight: 40,
        lineGap: 40,
        letterSpacing: 0.0125,
      },
      '3xl': {
        capHeight: 32,
        lineGap: 32,
        letterSpacing: 0.0125,
      },
      '2xl': {
        capHeight: 24,
        lineGap: 24,
        letterSpacing: 0.025,
      },
      xl: {
        capHeight: 20,
        lineGap: 20,
        letterSpacing: 0.025,
      },
      lg: {
        capHeight: 16,
        lineGap: 16,
        letterSpacing: 0.025,
      },
      med: {
        capHeight: 12,
        lineGap: 12,
        letterSpacing: 0.025,
      },
      sm: {
        capHeight: 10,
        lineGap: 12,
        letterSpacing: 0.025,
      },
      xs: {
        capHeight: 8,
        lineGap: 8,
        letterSpacing: 0.025,
      },
    },
  },
};

// rems - use when `html { font-size: 10px }` is gone
// export const space: Space = {
//   none: 0,
//   '4xs': 0.125,
//   '3xs': 0.1875,
//   '2xs': 0.25,
//   xs: 0.5,
//   sm: 0.75,
//   med: 1,
//   lg: 1.5,
//   xl: 2,
//   '2xl': 3,
//   '3xl': 4,
//   '4xl': 6,
// };

// px - remove when `html { font-size: 10px }` is gone
export const space: Space = {
  none: 0,
  '4xs': 2,
  '3xs': 3,
  '2xs': 4,
  xs: 8,
  sm: 12,
  med: 16,
  lg: 24,
  xl: 32,
  '2xl': 48,
  '3xl': 64,
  '4xl': 96,
  '5xl': 144,
  '6xl': 208,
};
