import React from 'react';
import styled, {keyframes} from 'styled-components';

type AnimateProps = {
  children: React.ReactNode;
  delay?: number;
  type?: 'floating';
};

type StyledAnimateProps = {
  $delay?: number;
  $type?: 'floating';
};

const floating = keyframes`
  0% { transform: translate(0, 0px) }
  50% { transform: translate(0, 24px) }
  100% { transform: translate(0, -0px) }
`;

const StyledAnimate = styled.div<StyledAnimateProps>`
  animation-delay: ${({$delay}) => `calc(0.2s * ${$delay ? $delay + 1 : 1})`}}
  animation-name: ${({$type}) => ($type === 'floating' ? floating : null)};
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
`;

export const Animate = ({children, delay, type = 'floating'}: AnimateProps) => {
  return (
    <StyledAnimate $delay={delay} $type={type}>
      {children}
    </StyledAnimate>
  );
};
