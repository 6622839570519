import AnimateObject from 'epic-fortnite-react/components/common/AnimateObject';
import React, {useState} from 'react';
import VisibilitySensor from 'react-visibility-sensor';

import {triggerAnalytic} from '../../utils/SubscriptionUtils';

import StyledSubscriptionBattlePassTextWrapper from './StyledSubscriptionBattlePassTextWrapper';
import StyledSubscriptionGradientFadeOut from './StyledSubscriptionGradientFadeOut';
import StyledSubscriptionPatternSlice from './StyledSubscriptionPatternSlice';
import StyledSubscriptionPatternWrapper from './StyledSubscriptionPatternWrapper';
import SubscriptionCoreText from './SubscriptionCoreText';
import SubscriptionHeroImage from './SubscriptionHero';

interface Props {
  content: any;
  mobile: boolean;
  tablet: boolean;
}

const SubscriptionBattlePassSection = (props: Props) => {
  const {
    mobile,
    content: {
      textContent: {header, textSection, theme},
      bgSlice3,
      heroImg,
      heroImgMobile,
    },
  } = props;
  const heroImage = mobile ? heroImgMobile : heroImg;
  const [isVisible, setVisibility] = useState(false);
  const onChange = (): void => {
    setVisibility(!isVisible);
    if (isVisible) {
      triggerAnalytic('SubscriptionBattlePass');
    }
  };
  return (
    <VisibilitySensor active={!isVisible} onChange={onChange} partialVisibility minTopValue={0}>
      <StyledSubscriptionPatternWrapper>
        {/*
                    JS - 11/12/20 - Switched to using a single slice that contains smaller icons.
                    CMS contains 3 slice options.
                */}
        <StyledSubscriptionPatternSlice bgSlice={bgSlice3} />
        <StyledSubscriptionPatternSlice bgSlice={bgSlice3} offset={true} />
        <StyledSubscriptionPatternSlice bgSlice={bgSlice3} />
        <StyledSubscriptionPatternSlice bgSlice={bgSlice3} offset={true} />
        {/* JS - 11/18/20 - This needs to be cleaner once we have time to revisit */}
        {!mobile && (
          <React.Fragment>
            <StyledSubscriptionPatternSlice bgSlice={bgSlice3} />
            <StyledSubscriptionPatternSlice bgSlice={bgSlice3} offset={true} />
            <StyledSubscriptionPatternSlice bgSlice={bgSlice3} />
            <StyledSubscriptionPatternSlice bgSlice={bgSlice3} offset={true} />
            <StyledSubscriptionPatternSlice bgSlice={bgSlice3} />
          </React.Fragment>
        )}
        {heroImage && (
          <AnimateObject
            className={'hero-image'}
            minTop={300}
            speed={2}
            from={{x: `-=100`, autoAlpha: 0}}
            to={{x: -15, autoAlpha: 1, delay: 0.4}}>
            <SubscriptionHeroImage src={heroImage} alt="" />
          </AnimateObject>
        )}
        <StyledSubscriptionBattlePassTextWrapper>
          <AnimateObject
            minTop={100}
            speed={2}
            from={{
              x: `+=150`,
              autoAlpha: 0,
            }}
            to={{
              x: 0,
              autoAlpha: 1,
              delay: 0.6,
            }}>
            <SubscriptionCoreText header={header} textSection={textSection} theme={theme} />
          </AnimateObject>
        </StyledSubscriptionBattlePassTextWrapper>
        {!mobile && <StyledSubscriptionGradientFadeOut />}
      </StyledSubscriptionPatternWrapper>
    </VisibilitySensor>
  );
};

export default SubscriptionBattlePassSection;
