import generateWithAsyncActions from 'epic-alt-utils';

import alt from '../alt';

export default generateWithAsyncActions(alt, [
  'register',
  'getStatus',
  'sendRedeemedEmail',
  'setGameFlow',
  'setMobileCode',
  'entitleUser',
  'updateSelectedDevice',
]);
