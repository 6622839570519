import {getLocale} from '@epic-core/common';
import moment from 'moment-timezone';

export const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
export const formattedDate = (datetime: string) => moment(datetime).format('LL');
export const formattedTime = (datetime: string) => moment(datetime).tz(userTimezone).format('LT');

export const formattedDateTime = (datetime: string) => {
  const locale = getLocale();
  moment.locale(locale);

  return {date: formattedDate(datetime), time: formattedTime(datetime)};
};
