import {getAssetPath} from 'epic-fortnite-react/utils/FortniteUtils';
import React from 'react';

import ArrowLeft from '../../../../../images/cosplay/arrow-left.png';
import ArrowRight from '../../../../../images/cosplay/arrow-right.png';
import Container from '../components/Container';
import MaskType from '../components/MaskType';
import SpikeDivider from '../components/SpikeDivider';
import {Background, CTA} from '../types';

import {
  CarouselArrow,
  CarouselImage,
  CarouselWrapper,
  Section,
  Subheader,
  ThumbnailWrapper,
  Thumbnails,
} from './components';
import CarouselImageWrapper from './components/CarouselImageWrapper';
import Difficulty from './components/Difficulty';

export interface ExploreFoldablesCarouselProps {
  carousel?: Array<{
    background?: Background;
    description?: string;
    difficulty?: string;
    image?: {src?: string; alt?: string};
    link?: CTA;
    name?: string;
    thumbnail?: {src?: string; alt?: string};
    time?: string;
  }>;
  header?: string;
  subheader?: string;
  type?: string;
  accent?: string;
}

const ExploreFoldablesCarousel = ({
  carousel,
  header,
  subheader,
  type,
  accent,
}: ExploreFoldablesCarouselProps) => {
  const [activeIndex, setActiveIndex] = React.useState(0);

  if (!carousel) {
    return null;
  }

  const next = () =>
    setActiveIndex((index) => {
      if (index === carousel.length - 1) {
        return 0;
      }
      return index + 1;
    });
  const previous = () =>
    setActiveIndex((index) => {
      if (index === 0) {
        return carousel.length - 1;
      }
      return index - 1;
    });

  const activeItem = carousel[activeIndex] ?? {};
  const {name, description, background, image, time, difficulty, link} = activeItem;

  return (
    <Section gradient={background}>
      <SpikeDivider position="top" background={background?.from} />
      <Container>
        <MaskType accent={accent}>{type}</MaskType>
        <h1>{header}</h1>
        <Subheader>{subheader}</Subheader>
        <CarouselWrapper>
          <CarouselArrow>
            <img src={getAssetPath(ArrowLeft)} alt="" onClick={previous} />
          </CarouselArrow>
          <Difficulty
            accent={accent}
            cta={link}
            description={description}
            difficulty={difficulty}
            name={name}
            time={time}
          />
          <CarouselImageWrapper>
            <CarouselImage src={image?.src} alt={image?.alt} />
          </CarouselImageWrapper>
          <CarouselArrow>
            <img src={getAssetPath(ArrowRight)} alt="" onClick={next} />
          </CarouselArrow>
        </CarouselWrapper>
        <Thumbnails>
          {carousel.map((foldable, index) => {
            return (
              <ThumbnailWrapper
                key={`${index}-${foldable.name}`}
                selected={index === activeIndex}
                accent={accent}
                onClick={() => setActiveIndex(index)}>
                <img src={foldable.thumbnail?.src} alt={foldable.thumbnail?.alt} />
              </ThumbnailWrapper>
            );
          })}
        </Thumbnails>
      </Container>
    </Section>
  );
};

export default ExploreFoldablesCarousel;
