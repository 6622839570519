import PropTypes from 'prop-types';
import React from 'react';

import {localizedMessage} from '../message/Message';

import TwitterLogoSVG from './TwitterLogo';
import VerifiedBadge from './VerifiedBadge';

const parseTwitterDate = (tdate) => {
  const systemDate = new Date(Date.parse(tdate));
  const userDate = new Date();

  const diff = Math.floor((userDate - systemDate) / 1000);
  if (diff < 59) return `${diff}s`;
  if (diff <= 3540) return `${Math.round(diff / 60)}m`;
  if (diff <= 86400) return `${Math.round(diff / 3600)}h`;
  if (diff < 604800) return `${Math.round(diff / 86400)}d`;
  return systemDate.toString().substring(4, 10);
};

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mounted: false,
    };
  }

  componentDidMount() {
    this.setState({
      mounted: true,
    });
  }

  createTimestamp(time) {
    if (!time || !this.state.mounted) return null;
    return parseTwitterDate(time);
  }

  render() {
    const {data, linkProps} = this.props;
    const timestamp = this.createTimestamp(data.created_at);
    const verified = data.user.verified ? <VerifiedBadge /> : null;
    return (
      <div className="header">
        <a
          className="account-group"
          href={`https://twitter.com/${data.user.screen_name}`}
          {...linkProps}>
          <img
            className="avatar"
            src={data.user.profile_image_url_https}
            alt={localizedMessage('epic.fortnite.twitter.avatar')}
          />
          <strong className="fullname">{data.user.name}</strong>
          {verified}
          <span>&nbsp;</span>
          <span className="username">
            <s>@</s>
            <b>{data.user.screen_name}</b>
          </span>
        </a>
        <small className="time">
          <a
            href={`https://twitter.com/${data.user.screen_name}/status/${data.id_str}`}
            className="tweet-timestamp"
            {...linkProps}>
            {' • '}
            {timestamp}
          </a>
        </small>
        <a
          href={`https://twitter.com/${data.user.screen_name}/status/${data.id_str}`}
          title={localizedMessage('epic.fortnite.twitter.viewontwitter')}
          {...linkProps}>
          <div className="logo">
            <TwitterLogoSVG />
          </div>
        </a>
      </div>
    );
  }
}

Header.propTypes = {
  linkProps: PropTypes.object,
  data: PropTypes.object,
};

Header.defaultProps = {
  data: {
    user: {},
  },
};

Header.displayName = 'Header';

export default Header;
