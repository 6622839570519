import React from 'react';
import styled from 'styled-components';

import {space} from '../../../theme/theme';
import {Spaces} from '../../../types/theme';

export type StyledGridProps = {
  $autoFill?: number;
  $autoFit?: number;
  $breakpoint?: number;
  $direction?: string;
  $directionMobile?: string;
  $gap?: Spaces;
  $gapX?: Spaces;
  $gapY?: Spaces;
};

export type GridProps = {
  autoFill?: number;
  autoFit?: number;
  breakpoint?: number;
  children: React.ReactNode;
  gap?: Spaces;
  gapX?: Spaces;
  gapY?: Spaces;
  ref?: React.ForwardedRef<HTMLDivElement>;
  reverseOrder?: boolean;
  reverseOrderMobile?: boolean;
};

const StyledGrid = styled.div<StyledGridProps>`
  --grid-gapX: ${({$gap, $gapX}) =>
    $gapX || $gap
      ? `${space[`${$gapX || $gap}`]}px`
      : '0px'}; /* 0px fallback needed for calc to work */
  --grid-gapY: ${({$gap, $gapY}) =>
    $gapY || $gap
      ? `${space[`${$gapY || $gap}`]}px`
      : '0px'}; /* 0px fallback needed for calc to work */

  display: flex;
  flex-direction: ${({$directionMobile}) => $directionMobile};

  @media (min-width: ${({$breakpoint}) => $breakpoint}px) {
    flex-direction: ${({$direction}) => $direction};
  }

  gap: var(--grid-gapX);

  ${({$autoFill}) => ({
    gridTemplateColumns: `repeat(auto-fill, minmax(${$autoFill}px, 1fr))`,
  })}

  ${({$autoFit}) => ({
    gridTemplateColumns: `repeat(auto-fit, minmax(${$autoFit}px, 1fr))`,
  })}
`;

export const Grid = React.forwardRef(
  (
    {
      autoFill,
      autoFit,
      breakpoint,
      children,
      gap,
      gapX,
      gapY,
      reverseOrder,
      reverseOrderMobile,
    }: GridProps,
    ref
  ) => {
    return (
      <StyledGrid
        $autoFill={autoFill}
        $autoFit={autoFit}
        $breakpoint={breakpoint}
        $direction={reverseOrder ? 'row-reverse' : 'row'}
        $directionMobile={reverseOrderMobile ? 'column-reverse' : 'column'}
        $gap={gap}
        $gapX={gapX}
        $gapY={gapY}>
        {children}
      </StyledGrid>
    );
  }
);

Grid.displayName = 'Grid';
