import React from 'react';
import ReactPlayer, {ReactPlayerProps} from 'react-player';
import styled from 'styled-components';
import {replaceYoutubeCookieEmbeds} from '@epic-mw/common-utils/dist/videoUtils';

export type StyledVideoWrapperProps = {
  $aspectRatio?: '9:16' | '16:9';
  $maxWidth?: number;
};

export type StyledVideoPlayerWrapperProps = {
  $aspectRatio?: '9:16' | '16:9';
};

export interface VideoProps extends ReactPlayerProps {
  aspectRatio?: '9:16' | '16:9';
  maxWidth?: number;
  videoUrl?: string;
}

const StyledVideoWrapper = styled.div<StyledVideoWrapperProps>`
  max-width: ${({$aspectRatio, $maxWidth}) =>
    $aspectRatio && $maxWidth ? `${$maxWidth}px` : 'none'};
  width: ${({$aspectRatio}) => ($aspectRatio ? '100%' : 'initial')};
`;

const StyledVideoPlayerWrapper = styled.div<StyledVideoPlayerWrapperProps>`
  position: ${({$aspectRatio}) => ($aspectRatio ? 'relative' : 'initial')};
  padding-top: ${({$aspectRatio}) =>
    $aspectRatio === '9:16' ? '177.77%' : $aspectRatio === '16:9' ? '56.25%' : '56.25%'};
`;

const StyledReactPlayer = styled(ReactPlayer)`
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;

  video {
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 0;
  }
`;

export const Video = ({aspectRatio, maxWidth, url, ...props}: VideoProps) => {
  const videoUrl = replaceYoutubeCookieEmbeds(url);

  return (
    <StyledVideoWrapper $aspectRatio={aspectRatio} $maxWidth={maxWidth}>
      <StyledVideoPlayerWrapper $aspectRatio={aspectRatio}>
        <StyledReactPlayer controls={true} height="100%" width="100%" {...props} url={videoUrl} />
      </StyledVideoPlayerWrapper>
    </StyledVideoWrapper>
  );
};
