import styled from 'styled-components';

import {Device} from '../../constants';

const StyledSubscriptionCoreTextWrapper = styled.div<{textAlign?: string; overrides?: JSON}>`
  text-align: ${(props) => (props.textAlign ? props.textAlign : 'center')};
  padding: 2em;
  ${(props) => (props.overrides ? JSON.parse(props.overrides) : '')}

  @media screen and ${Device.laptop} {
    width: 80%;
  }

  @media screen and ${Device.tablet} {
    width: 100%;
  }

  @media screen and ${Device.mobileL} {
    padding: 1em;
    text-align: center;
    width: 100%;
  }
`;

export default StyledSubscriptionCoreTextWrapper;
