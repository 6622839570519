import {getLocale} from '@epic-core/common';
import React from 'react';
import {useLocation} from 'react-router';
import root from 'window-or-global';

import {generateRouteHref} from '../../utils/route';

import Button, {ButtonA} from './Button';

const ButtonLink = ({href = '', popup = false, children}) => {
  const location = useLocation();
  const locale = getLocale();
  const onClick = React.useCallback(() => {
    if (popup) {
      root.open(href, '_blank');
    } else {
      root.location.href = href;
    }
  }, [href, popup]);
  if (!href) return null;
  if (/^\//.test(href)) {
    const url = generateRouteHref(href, location, locale);
    return (
      <ButtonA href={url} className="btn btn-primary" target="_blank" rel="noopener noreferrer">
        {children}
      </ButtonA>
    );
  }
  return (
    <Button className="btn btn-primary" onClick={onClick}>
      {children}
    </Button>
  );
};

export default ButtonLink;
