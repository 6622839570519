import styled from 'styled-components';

import DownloadButtonBase, {Props as DownloadBaseProps} from './DownloadButtonBase';

interface Props extends DownloadBaseProps {
  background?: string;
}

const CTA = styled(DownloadButtonBase)<Props>`
  &&&&& {
    background-color: ${({background}) => background};
  }
`;

export default CTA;
