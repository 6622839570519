import CountrySelect from 'epic-fortnite-react/components/common/CountrySelect';
import StyledText from 'epic-fortnite-react/components/common/StyledText';
import Proptypes from 'prop-types';
import React from 'react';

const ScheduleListHeader = ({header, subHeader, availableCountries, setCountry, placeholder}) => {
  if (header && availableCountries && availableCountries.length > 0) {
    const styledtext = [header, subHeader];
    return (
      <div className="ScheduleListHeader">
        <div className="header">
          <StyledText data={styledtext} />
        </div>
        <div className="countryselect">
          <CountrySelect
            countryOptions={availableCountries}
            setCountry={setCountry}
            placeholder={placeholder}
          />
        </div>
      </div>
    );
  }
  if (header) return <StyledText data={[header]} />;
  return null;
};
const StyledTextProps = Proptypes.shape({
  text: Proptypes.string,
  color: Proptypes.string,
  theme: Proptypes.string,
});

ScheduleListHeader.propTypes = {
  header: StyledTextProps,
  subHeader: StyledTextProps,
  placeholder: Proptypes.string,
  availableCountries: Proptypes.arrayOf(
    Proptypes.shape({
      label: Proptypes.string,
      value: Proptypes.string,
    })
  ),
  setCountry: Proptypes.func,
};

export default ScheduleListHeader;
