import {defaultTheme} from 'epic-games-nav-shared';

export const navTheme = {
  ...defaultTheme,
  logo: {
    src: 'https://cdn2.unrealengine.com/fn-nav-logo-3e6bd67b98b0.svg?resize=1&w=240&quality=high',
    margin: '0 16px 0 16px',
    width: 120,
  },
  forwardIcon: 'fn-arrow-forward',
  palette: {
    ...defaultTheme.palette,
    text: {
      primary: '#ccc',
      primaryHover: '#fff',
    },
    background: {
      default: '#2a2a2a',
      paper: '#1f1f1f',
    },
    border: '#2a2a2a',
    accent: {
      text: '#ff0',
      background: '#ff0',
      backgroundText: '#000',
      backgroundHover: '#f7f756',
    },
  },
};
