import {getLocale} from '@epic-core/common';
import {generateRouteHref} from 'epic-fortnite-react';
import {Message} from 'epic-fortnite-react';
import PropTypes from 'prop-types';
import React from 'react';
import {useLocation} from 'react-router';

interface Props {
  link: {
    url: string;
    name: string;
  };
}

const CreativeHomeLearnMoreLinks = ({link}: Props) => {
  const location = useLocation();
  const locale = getLocale();
  const name = link?.name || <Message code="epic.common.learnMore" />;
  if (!/^\//.test(link?.url)) {
    return (
      <h3>
        <a href={link?.url}>
          {name}
          <span className="icon-reverse-right-arrow" />
        </a>
      </h3>
    );
  }
  const url = generateRouteHref(link?.url, location, locale);
  return (
    <h3>
      <a href={url}>
        {name}
        <span className="icon-reverse-right-arrow" />
      </a>
    </h3>
  );
};

CreativeHomeLearnMoreLinks.propTypes = {
  link: PropTypes.shape({
    url: PropTypes.string,
    name: PropTypes.string,
  }),
};

export default CreativeHomeLearnMoreLinks;
