import React from 'react';

import MetaTags from '../../components/common/MetaTags';

import ArchiveLinkSection, {ArchiveLinkSectionProps} from './ArchiveLinkSection';
import {SocialShareProps} from './components/SocialShare';
import StyleBaseline from './components/StyleBaseline';
import DetailsHeroSection, {DetailsHeroSectionProps} from './DetailsHeroSection';
import ExploreFoldablesCarousel, {ExploreFoldablesCarouselProps} from './ExploreFoldablesCarousel';
import ExploreMasksSection, {ExploreMasksSectionProps} from './ExploreMasksSection';
import HowItWorksSection, {HowItWorksSectionProps} from './HowItWorksSection';
import MaskCarouselSection, {MaskCarouselSectionProps} from './MaskCarouselSection';
import MaskDownloadSection, {MaskDownloadSectionProps} from './MaskDownloadSection';

interface Props {
  data: {
    hero: Omit<DetailsHeroSectionProps, 'accent'>;
    howItWorks: HowItWorksSectionProps;
    accentColor: string;
    carousel: Omit<MaskCarouselSectionProps, 'accent' | 'socialShare'>;
    download: Omit<MaskDownloadSectionProps, 'accent' | 'socialShare'>;
    masks: Omit<ExploreMasksSectionProps, 'accent'>;
    foldables: Omit<ExploreFoldablesCarouselProps, 'accent'>;
    socialShare?: SocialShareProps['socialShare'];
    archiveLink?: ArchiveLinkSectionProps;
    isArchived?: boolean;
  };
}

const MaskDetailPage = ({data}: Props) => {
  const {
    hero = {},
    howItWorks,
    accentColor: accent,
    carousel,
    download,
    masks,
    foldables,
    socialShare = undefined,
    archiveLink,
    isArchived,
  } = data;

  return (
    <StyleBaseline>
      <MetaTags pageModel={data} />
      <DetailsHeroSection {...hero} accent={accent} />
      <HowItWorksSection {...howItWorks} />
      <MaskCarouselSection {...carousel} socialShare={socialShare} accent={accent} />
      <MaskDownloadSection {...download} socialShare={socialShare} accent={accent} />
      {!isArchived ? <ExploreMasksSection masks={masks?.masks} accent={accent} /> : null}
      {!isArchived ? <ExploreFoldablesCarousel {...foldables} accent={accent} /> : null}
      {isArchived === true && archiveLink ? <ArchiveLinkSection {...archiveLink} /> : null}
    </StyleBaseline>
  );
};

export default MaskDetailPage;
