import {getLocale} from '@epic-core/common';
import classnames from 'classnames';
import {generateRouteTo} from 'epic-fortnite-react';
import epicTracking from 'epic-tracking';
import React from 'react';
import {useLocation, withRouter} from 'react-router';
import {RouteComponentProps} from 'react-router-dom';

import {CTA as CTAProps} from '../types';

/**
 * CMS Content type => Fortnite - Common CTA Config
 */

export interface Props extends RouteComponentProps {
  id?: string;
  className?: string;
  ctaConfig: CTAProps;
}

const BaseCTABtn = ({history, id, ctaConfig, className}: Props) => {
  const location = useLocation();
  const locale = getLocale();
  const redirectInternalUrl = (e, path) => {
    epicTracking.trackEvent('Interaction', {
      interactionType: 'click',
      eventCategory: 'cta-cosplay-button',
      eventAction: 'cta-action',
      eventLabel: path,
    });
    history.push(generateRouteTo(path, location, locale));
  };

  const {isCtaLinkExternal, ctaString, ctaLink, disabled} = ctaConfig;
  const ctaClass = classnames('btn btn-primary', [className], {invisible: !ctaString}, {disabled});
  if (isCtaLinkExternal) {
    return (
      <a id={id} className={ctaClass} href={ctaLink} target="_blank" rel="noopener noreferrer">
        <span>{ctaString}</span>
      </a>
    );
  } else {
    return (
      <button id={id} className={ctaClass} onClick={(e) => redirectInternalUrl(e, ctaLink)}>
        <span>{ctaString}</span>
      </button>
    );
  }
};

export default withRouter(BaseCTABtn);
