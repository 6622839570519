import axios from 'axios';
import root from 'window-or-global';

import RegisterActions from '../actions/RegisterActions';
import Url from '../constants/Url';

const RegisterSource = {
  getStatus: {
    remote(state, locale) {
      return axios.get(Url().REGISTER(locale));
    },
    success: RegisterActions.getStatusSuccess,
    error: RegisterActions.getStatusFailure,
    shouldFetch() {
      return !root.__server_side_render;
    },
  },

  register: {
    remote(state, locale, platform) {
      return axios.post(Url().REGISTER(locale), {
        platform,
      });
    },
    success: RegisterActions.registerSuccess,
    error: RegisterActions.registerFailure,
    shouldFetch() {
      return !root.__server_side_render;
    },
  },

  sendRedeemedEmail: {
    remote(state, locale) {
      return axios.post(Url().MOBILEREDEEMED(locale));
    },
    success: RegisterActions.sendRedeemedEmailSuccess,
    error: RegisterActions.sendRedeemedEmailFailure,
    shouldFetch() {
      return !root.__server_side_render;
    },
  },

  entitleUser: {
    remote(state, locale) {
      return axios.get(Url().MOBILEENTITLEMENT);
    },
    success: RegisterActions.entitleUserSuccess,
    error: RegisterActions.entitleUserFailure,
    shouldFetch() {
      return !root.__server_side_render;
    },
  },
};

export default RegisterSource;
