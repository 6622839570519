import styled from 'styled-components';

import {breakpoints} from '../../../constants/Breakpoints';

const Grid = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  width: 100%;
  align-items: center;

  @media ${breakpoints.mobileL} {
    display: flex;
    justify-content: center;
  }
`;

export default Grid;
