import {getLocale} from '@epic-core/common';
import axios from 'axios';
import root from 'window-or-global';

import {BlogActions} from '../actions';
import {URL} from '../constants';

/**
 * BlogSource
 * @type {class}
 */
const BlogSource = {
    getBlogData: {
        remote(state, articleCount, offset, category, locale, previewId, rootPageSlug) {
            const cat = category === 'all' ? '' : category;
            const loc = locale || '';

            return axios.get(URL().BLOG.GETPOSTS, {
                params: {
                    category: cat,
                    postsPerPage: articleCount,
                    offset,
                    locale: loc,
                    previewId,
                    rootPageSlug
                },
                headers: {'Accept-Language': getLocale()}
            });
        },
        success: BlogActions.getBlogDataSuccess,
        error: BlogActions.getBlogDataFailure,
        shouldFetch() {
            return !root.__server_side_render;
        }
    },

    /**
     * Gets a single blog post
     * @param {params} Object containing two values:
     * @param {params} slug Slug of the blog article
     * @param {params} locale Blog locale
     */
    getBlogPost: {
        remote(state, params) {
            const loc = params.locale ? encodeURIComponent(params.locale) : '';
            return axios.get(URL().BLOG.GETPOST, {
                params: {
                    slug: params.slug,
                    getNeighbors: params.getNeighbors !== undefined ? params.getNeighbors : true,
                    locale: loc,
                    previewId: params.previewId,
                    rootPageSlug: params.rootPageSlug
                },
                headers: {'Accept-Language': getLocale()}
            });
        },
        success: BlogActions.getBlogPostSuccess,
        error: BlogActions.getBlogPostFailure,
        shouldFetch() {
            return !root.__server_side_render;
        }
    },

    getBlogSocialShareCount: {
        remote(state, params) {
            let socialUrls = [];
            /**
             * If customShareFunc prop func is found, use customShareFunc from props
             */
            if (params.customShareFunc) {
                const customShareConfig = params.customShareFunc(params, URL().BLOG.SOCIALSHARE);
                socialUrls = customShareConfig.socialUrls || [];
            } else {
                const currentHref = root.location.href;
                socialUrls = [
                    `${URL().BLOG.SOCIALSHARE.FACEBOOK}${currentHref}`,
                    `${URL().BLOG.SOCIALSHARE.TWITTER}${currentHref}`
                ];
            }
            const sendGetRequests = socialUrls.map((url) => {
                return axios.get(url, {noHeaderMods: true});
            });
            return axios.all(sendGetRequests).catch((error) => {
                if (error.message !== 'Network Error') {
                    console.log('Fetching social share count failed due to exceed rate limit');
                } else {
                    throw error;
                }
            });
        },
        /* Preprocess response */
        interceptResponse(res, action, args) {
            const responseStatus = res && (res.response || {}).status;

            if (Number.isFinite(responseStatus) && responseStatus > 400) {
                return {
                    message: 'There was an issue while fetching social share count',
                    status: responseStatus
                };
            }

            const getCount = (data) => {
                return data.share ? data.share.share_count : data.count;
            };

            // Basic check for data validity
            const isValidData = (totalCount, slug) => {
                return (
                    totalCount && typeof totalCount === 'number' && slug && typeof slug === 'string'
                );
            };

            let socialSiteData = [];
            if (res && res.length) {
                socialSiteData = res.map((socialSite) => {
                    return getCount(socialSite.data);
                });
            }
            const totalCount = socialSiteData.reduce((prev, curr) => {
                return prev + curr;
            }, 0);
            const slug = args[0].slug;
            return {
                totalCount,
                slug,
                dataIsValid: isValidData(totalCount, slug)
            };
        },
        success: BlogActions.getBlogSocialShareCountSuccess,
        error: BlogActions.getBlogSocialShareCountFailure,
        shouldFetch() {
            return !root.__server_side_render;
        }
    }
};

export default BlogSource;
