/**
 * React 15.0.1 does not include context in their shallowCompare function.  pure-render-decorator does not pass
 *     context to the shallowCompare function.  This is a copy of those two functions passing context.
 */
import shallowEqual from 'fbjs/lib/shallowEqual';
import Immutable from 'seamless-immutable';
import React from 'react';
import createReactClass from 'create-react-class';

/**
 * Generates commons names for async actions and then calls alt.generateActions() with the array of action names.
 * @param  {Object} alt
 * @param  {Array} asyncActions Array of async action names
 */
export function generateAsyncActions(alt, ...asyncActions) {
    if (!alt) {
        return null;
    }

    const actionsList = asyncActions.reduce((list, action) => {
        list.push(`${action}Starting`, `${action}Success`, `${action}Failure`);
        return list;
    }, []);

    return alt.generateActions(...actionsList);
}

/**
 * Generates commons names for async actions and concats non async actions
 * and then calls alt.generateActions() with the array of action names.
 * @param  {Object} alt
 * @param  {Array} asyncActions Array of async action names
 * @param  {Array} actions      Array of non async action names
 */
export default function generateWithAsyncActions(alt, asyncActions = [], actions = []) {
    if (!alt) {
        return null;
    }

    const asyncActionsArray = [];
    if (!!asyncActions) {
        for (let i = 0; i < asyncActions.length; i++) {
            const action = asyncActions[i];
            asyncActionsArray.push(action, `${action}Starting`, `${action}Success`, `${action}Failure`);
        }
    }

    return alt.generateActions.apply(alt, asyncActionsArray.concat(actions));
}

export function seamlessImmutable(StoreModel) {
    StoreModel.config = {
        setState(currentState, nextState) {
            this.state = nextState;
            return this.state;
        },

        getState(currentState) {
            return currentState;
        },

        onSerialize(state) {
            return state.asMutable();
        },

        onDeserialize(data) {
            let immutableData = Immutable(data);
            if (typeof StoreModel.prototype.getStateFunctions === 'function') {
                immutableData = immutableData.merge(StoreModel.prototype.getStateFunctions());
            }
            return immutableData;
        }
    };
    return StoreModel;
}

/**
 * This is used to do some sort of post-processing on the state after it has been initialized
 * @param config
 */
export function seamlessInit(config) {
    // Functions must be added on init as they are lost when we serialize/deserialize
    const altInstance = this.getInstance();
    if (altInstance) {
        if (typeof config === 'function') {
            const newState = this.state.merge(config(), {deep: true});
            altInstance.state = newState;
        }
        this.state = altInstance.state;
    }
}

/**
 * This function is used to reconcile between our immutable state and the bootstrapped state.  It's not quite enough
 * to just use the serialize/deserialize above because those only set the state correctly on the instance's state which
 * leaves this.state to be incorrect.
 * @param config
 */
export function seamlessBootstrap(callback, state) {
    // When we have preload data to process, we need to inject it into our state
    if (state !== !this.state) {
        const altInstance = this.getInstance();
        if (typeof callback === 'function') {
            altInstance.state = callback(state);
        }
        this.state = altInstance.state; // client side bootstrapping
    }
}

export function pureRender(component) {
    //avoiding arrow function so that "this" is the component's scope
    const shouldComponentUpdate = function shouldComponentUpdate(nextProps, nextState, nextContext) {
        return !shallowEqual(this.props, nextProps) ||
            !shallowEqual(this.state, nextState) ||
            (typeof nextContext !== 'undefined' && !shallowEqual(this.context, nextContext));
    };

    component.prototype.shouldComponentUpdate = shouldComponentUpdate;
}

/**
 * 'Higher Order Component' that controls the props of a wrapped
 * component via stores.
 *
 * Expects the Component to have two static methods:
 *   - getStores(): Should return an array of stores.
 *   - getPropsFromStores(props): Should return the props from the stores.
 *
 * Example using old React.createClass() style:
 *
 *    const MyComponent = React.createClass({
 *      statics: {
 *        getStores(props) {
 *          return [myStore]
 *        },
 *        getPropsFromStores(props) {
 *          return myStore.getState()
 *        }
 *      },
 *      render() {
 *        // Use this.props like normal ...
 *      }
 *    })
 *    MyComponent = connectToStores(MyComponent)
 *
 *
 * Example using ES6 Class:
 *
 *    class MyComponent extends React.Component {
 *      static getStores(props) {
 *        return [myStore]
 *      }
 *      static getPropsFromStores(props) {
 *        return myStore.getState()
 *      }
 *      render() {
 *        // Use this.props like normal ...
 *      }
 *    }
 *    MyComponent = connectToStores(MyComponent)
 *
 * A great explanation of the merits of higher order components can be found at
 * http://bit.ly/1abPkrP
 */

const assign = (props, state) => {
    const obj = {};
    if (props && Object.keys(props).length) {
        for (const key in props) {
            if (key && typeof key === 'string') {
                obj[key] = props[key];
            }
        }
    }
    if (state && Object.keys(state).length) {
        for (const key in state) {
            if (key && typeof key === 'string') {
                obj[key] = state[key];
            }
        }
    }
    return obj;
};

export function connectToStores(Spec, ...args) {
    const Component = args.length <= 1 || args[1] === undefined ? Spec : args[1];
    return (function() {
        // Check for required static methods.
        if (typeof Spec.getStores !== 'function') {
            throw new Error('connectToStores() expects the wrapped component to have a static getStores() method');
        }
        if (typeof Spec.getPropsFromStores !== 'function') {
            throw new Error('connectToStores() expects the wrapped component to have a static getPropsFromStores() method');
        }

        const StoreConnection = createReactClass({
            displayName: `Stateful${(Component.displayName || Component.name || 'Container')}`,

            getInitialState: function getInitialState() {
                return Spec.getPropsFromStores(this.props, this.context);
            },

            /**
             * This will allow us to change the state of the store on mount and see that change in render
             */
            UNSAFE_componentWillMount: function UNSAFE_componentWillMount() {
                if (typeof Spec.getInitialActions === 'function') {
                    const initialActions = Spec.getInitialActions(this.props, this.state);
                    if (!initialActions || !Array.isArray(initialActions)) return;
                    for (let i = 0; i < initialActions.length; i++) {
                        const action = initialActions[i];
                        if (typeof action !== 'function') continue;
                        action.call(this, assign(this.props, this.state));
                    }
                    this.setState(Spec.getPropsFromStores(this.props, this.context));
                }
            },

            componentDidMount: function componentDidMount() {
                const stores = Spec.getStores(this.props, this.context);
                this.storeListeners = stores.map((store) => {
                    return store.listen(this.onChange);
                });
                if (Spec.componentDidConnect) {
                    Spec.componentDidConnect(this.props, this.context);
                }
            },

            UNSAFE_componentWillReceiveProps: function UNSAFE_componentWillReceiveProps(nextProps) {
                this.setState(Spec.getPropsFromStores(nextProps, this.context));
            },

            componentWillUnmount: function componentWillUnmount() {
                if (this.storeListeners && this.storeListeners.length) {
                    this.storeListeners.forEach((unlisten) => {
                        return unlisten();
                    });
                }
            },

            onChange: function onChange() {
                this.setState(Spec.getPropsFromStores(this.props, this.context));
            },

            render: function render() {
                return React.createElement(Component, assign(this.props, this.state));
            }
        });
        if (Component.contextTypes) {
            StoreConnection.contextTypes = Component.contextTypes;
        }

        if (Component.getInitialSharedValues) {
            StoreConnection.getInitialSharedValues = Component.getInitialSharedValues;
        }

        return StoreConnection;
    })();
}
