import {getLocale} from '@epic-core/common';
import {generateRouteTo} from 'epic-fortnite-react';
import {connectToStores} from 'epic-alt-utils';
import PropTypes from 'prop-types';
import React from 'react';
import {withRouter, Redirect} from 'react-router';

import TermsStore from '../../stores/TermsStore';
import 'date-format-lite';

/**
 * Terms & Policy View
 */
@connectToStores
class TermsView extends React.Component {
  static propTypes = {
    termsStore: PropTypes.object,
    match: PropTypes.object,
    location: PropTypes.object,
  };

  static getStores() {
    return [TermsStore];
  }

  static getPropsFromStores() {
    return {
      termsStore: TermsStore.getState(),
    };
  }

  getMatchPolicy = (slug) => {
    const {policyList} = this.props.termsStore;
    for (let i = 0; i < policyList.length; i++) {
      if (policyList[i].slug === slug) {
        return policyList[i];
      }
    }
    return policyList[0];
  };

  render() {
    const activeSlug = this.props.match.params.slug;
    const activeTerms = this.getMatchPolicy(activeSlug);
    const location = this.props.location;
    const search = location.search || '';

    const locale = getLocale();

    if (!activeTerms) {
      return (
        <Redirect
          to={generateRouteTo(
            {
              pathname: '/news/fortnite-island-creator-rules',
              search,
            },
            location,
            locale
          )}
        />
      );
    }

    return (
      <div className="terms-view-container">
        <div className="site-block">
          <div className="terms-text" dangerouslySetInnerHTML={{__html: activeTerms.content}} />
        </div>
      </div>
    );
  }
}

export default withRouter(TermsView);
