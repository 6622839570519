import cloneDeep from 'clone-deep';
import PropTypes from 'prop-types';
import React from 'react';
import root from 'window-or-global';

import Context from './Context';
import Footer from './Footer';
import Header from './Header';
import MediaComponent from './MediaComponent';
import Modal from './Modal';
import Quote from './Quote';
import Text from './Text';

class Tweet extends React.Component {
  constructor(props) {
    super(props);
    this.toggleModal = this.toggleModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.state = {
      modalActive: false,
      modalIndex: 0,
    };
  }

  toggleModal(idx) {
    const {disableModal} = this.props;
    if (disableModal) return this.onClick();
    this.setState({
      modalActive: true,
      modalIndex: idx,
    });
  }

  closeModal() {
    this.setState({
      modalActive: false,
    });
  }

  getChildContext() {
    return {
      toggleModal: this.toggleModal,
      closeModal: this.closeModal,
    };
  }

  getData() {
    const {data: dataOrg} = this.props;
    const data = cloneDeep(dataOrg);
    // Support for extended tweets
    if ('full_text' in data) {
      data.text = data.full_text;

      if ('quoted_status' in data) {
        data.quoted_status.text = data.quoted_status.full_text;
      }

      if ('retweeted_status' in data) {
        data.retweeted_status.text = data.retweeted_status.full_text;

        if ('quoted_status' in data.retweeted_status) {
          data.retweeted_status.quoted_status.text = data.retweeted_status.quoted_status.full_text;
        }
      }
    }

    if ('extended_tweet' in data) {
      data.text = data.extended_tweet.full_text;
      data.entities = data.extended_tweet.entities;
      data.extended_entities = data.extended_tweet.extended_entities;
      data.display_text_range = data.extended_tweet.display_text_range;
    }

    if ('quoted_status' in data) {
      if ('extended_tweet' in data.quoted_status) {
        data.quoted_status.text = data.quoted_status.extended_tweet.full_text;
        data.quoted_status.entities = data.quoted_status.extended_tweet.entities;
        data.quoted_status.extended_entities = data.quoted_status.extended_tweet.extended_entities;
        data.quoted_status.display_text_range =
          data.quoted_status.extended_tweet.display_text_range;
      }
    }

    if ('retweeted_status' in data) {
      if ('extended_tweet' in data.retweeted_status) {
        data.retweeted_status.text = data.retweeted_status.extended_tweet.full_text;
        data.retweeted_status.entities = data.retweeted_status.extended_tweet.entities;
        data.retweeted_status.extended_entities =
          data.retweeted_status.extended_tweet.extended_entities;
        data.retweeted_status.display_text_range =
          data.retweeted_status.extended_tweet.display_text_range;
      }
      if (data.retweeted_status.is_quote_status) {
        if ('extended_tweet' in data.retweeted_status.quoted_status) {
          data.retweeted_status.quoted_status.text =
            data.retweeted_status.quoted_status.extended_tweet.full_text;
          data.retweeted_status.quoted_status.entities =
            data.retweeted_status.quoted_status.extended_tweet.entities;
          data.retweeted_status.quoted_status.extended_entities =
            data.retweeted_status.quoted_status.extended_tweet.extended_entities;
          data.retweeted_status.quoted_status.display_text_range =
            data.retweeted_status.quoted_status.extended_tweet.display_text_range;
        }
      }
    }

    // use Quote component if quoted status exists
    const showQuoteStatus = !!data.quoted_status;

    // use retweet as data if its a RT
    const isRT = !!data.retweeted_status;
    if (data.retweeted_status) {
      return {data: data.retweeted_status, isRT, showQuoteStatus};
    }
    return {data, isRT, showQuoteStatus};
  }

  getLink = () => {
    const {data} = this.props;
    return `https://twitter.com/${data.user.screen_name}/status/${data.id_str}`;
  };

  onClick = () => {
    root.open(this.getLink());
  };

  render() {
    const {modalActive, modalIndex, autoPlay} = this.state;
    const {linkProps, tweetStyles, onTweetAction, onMediaLoad, onMediaLoadError} = this.props;
    const {data, isRT, showQuoteStatus} = this.getData();

    return (
      <div className="tweet" style={tweetStyles}>
        {isRT ? <Context {...this.props} /> : null}
        <div className="content">
          <Header data={data} linkProps={linkProps} />
          <div className="link" role="presentation" onClick={this.onClick}>
            <Text data={data} />
          </div>
          <MediaComponent
            data={data}
            autoPlay={autoPlay}
            onMediaLoad={onMediaLoad}
            onMediaLoadError={onMediaLoadError}
          />
          {showQuoteStatus && <Quote data={data.quoted_status} linkProps={linkProps} />}
          <Footer data={data} linkProps={linkProps} onTweetAction={onTweetAction} />
        </div>
        {modalActive ? <Modal data={data} modalIndex={modalIndex} /> : null}
      </div>
    );
  }
}

Tweet.childContextTypes = {
  toggleModal: PropTypes.func,
  closeModal: PropTypes.func,
};

Tweet.propTypes = {
  disableModal: PropTypes.bool,
  data: PropTypes.object,
  linkProps: PropTypes.object,
  tweetStyles: PropTypes.object,
  onTweetAction: PropTypes.func,
  onMediaLoad: PropTypes.func,
  onMediaLoadError: PropTypes.func,
  autoPlay: PropTypes.bool,
};

Tweet.defaultProps = {
  data: {
    entities: {},
    user: {},
  },
  linkProps: {},
  tweetStyles: {},
  onTweetAction: undefined,
  onMediaLoad: undefined,
};

export default Tweet;
