import styled from 'styled-components';

interface Props {
  $color?: string;
}

export default styled.li<Props>`
  &&&& {
    color: ${({$color}) => $color || 'white'};
  }
`;
