export interface CmsData {
  title: string;
  subtitle: string;
  items: HeroSlide[];
  badge: string;
  badgeAlt: string;
  seasonKey: string;
  mainHeroBackgroundImg?: string;
  mobileHeroBackgroundImg?: string;
}

export interface HeroSlide {
  color: string;
  icon: string;
  name: string;
  hero: ImageArray[];
  heroItems: ImageArray[];
  slideBackground: ResponsiveImage;
  spike: string;
  spikeRight?: string;
  key: string;
  sound?: string;
  heroSubtitle?: string;
}

export interface ResponsiveImage {
  desktopImage: string;
  tabletImage?: string;
  mobileImage?: string;
}

export interface ImageArray {
  image: string;
  imageAlt: string;
  plusIconColor: string;
}

export enum HeroIds {
  'shehulk',
  'doom',
  'thor',
  'mystique',
  'groot',
  'storm',
  'ironman',
}

export interface FeatureVideo {
  type: string;
  tabletImage?: string;
  mobileImage?: string;
  contentId?: string;
  content?: string;
  embedSrc?: string;
}
