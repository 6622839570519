import React, {useEffect, useState} from 'react';
import axios, {AxiosResponse} from 'axios';
import styled from 'styled-components';
import root from 'window-or-global';
import debounce from 'lodash.debounce';

import {ContextMessage as Message} from '@epic-core/localization';

interface Props {
    url: string;
    key: string;
}

interface SketchfabViewerData {
    type: string;
    version: string;
    title: string;
    provider_name: string;
    provider_url: string;
    thumbnail_url: string;
    thumbnail_width: number;
    thumbnail_height: number;
    html?: string;
    width: number;
    height: number;
    author_name: string;
    author_url: string;
}

interface SketchfabResponse extends AxiosResponse {
    data: AxiosResponse['data'] & {data?: SketchfabViewerData};
}

const contentMaxWidth = 810;
const articleMargin = 30;
const aspectRatio = 9 / 16;

const SketchfabIcon = (): JSX.Element => (
    <svg
        width="120"
        height="120"
        viewBox="0 0 120 120"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
            d="M60 0C26.8608 0 0 26.8632 0 60C0 93.1368 26.8608 120 60 120C93.1392 120 120 93.1368 120 60C120 26.8632 93.144 0 60 0ZM54.7872 95.7042L28.92 80.7726V50.6526L54.7872 64.62V95.7042ZM59.3952 57.5328L28.7904 41.31L59.3952 23.64L90.0048 41.31L59.3952 57.5328ZM90.024 80.8302L64.2528 95.7102V64.74L90.024 50.82V80.8302Z"
            fill="white"
        />
    </svg>
);
const Placeholder = ({
    className,
    errorMessage
}: {
    className?: string;
    errorMessage?: string;
}): JSX.Element => (
    <div data-testid="sketchfab-placeholder" className={className}>
        <SketchfabIcon />
        {errorMessage ? <Message code={errorMessage} /> : null}
    </div>
);

const StyledPlaceholder = styled(Placeholder)`
    height: ${aspectRatio * 100}vw;
    max-height: ${contentMaxWidth * aspectRatio}px;
    margin: 1rem 0;
    background: #f2f2f2;
    display: flex;
    justify-content: center;
    align-items: center;

    & span {
        position: absolute;
        max-width: 23rem;
        text-align: center;
    }

    @keyframes fadeIn {
        from {
            opacity: 0.5;
        }
    }

    & svg {
        animation: ${(props) => (props.errorMessage ? '' : 'fadeIn 1s infinite alternate')};
    }
`;

const StyledViewerWrapper = styled.div`
    margin: 1rem 0;
`;

const Sketchfab = ({url, key}: Props): JSX.Element => {
    const [sketchfabResponse, setSketchfabResponse] = useState<SketchfabResponse | null>(null);
    const [hasError, setError] = useState<boolean>(false);

    if (!url) {
        return <StyledPlaceholder key={key} errorMessage="epic.news.sketchfab.urlRequired" />;
    }

    const getSketchfabData = async () => {
        /**
         * ! maxwidth is required to get a responsive iframe width and prevents viewer's UI controls to scale down too small to use
         * @see https://sketchfab.com/developers/oembed
         */
        const smallScreenViewerWidth = root.innerWidth - articleMargin;
        const screenSmallerThanContentMax = root.innerWidth < contentMaxWidth;
        const sketchfabMaxWidth = screenSmallerThanContentMax
            ? smallScreenViewerWidth
            : contentMaxWidth;

        const requestURL = `https://sketchfab.com/oembed?url=${url}&maxwidth=${sketchfabMaxWidth}`;
        try {
            setSketchfabResponse(null);
            setError(false);
            const response = await axios.get<SketchfabResponse>(requestURL);
            setSketchfabResponse(response);
        } catch (error) {
            console.error('Sketchfab request unsuccessful.', error);
            setError(true);
        }
    };

    useEffect(() => {
        getSketchfabData();
        const debouncedGetSketchfabData = debounce(getSketchfabData, 500);
        root.addEventListener('resize', debouncedGetSketchfabData);
        return () => root.removeEventListener('resize', debouncedGetSketchfabData);
    }, []);

    if (sketchfabResponse === null && !hasError) {
        return <StyledPlaceholder key={key} />;
    }

    if (hasError) {
        return <StyledPlaceholder key={key} errorMessage="epic.news.sketchfab.error" />;
    }

    return (
        <StyledViewerWrapper
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{__html: sketchfabResponse?.data?.html}}
            key={key}
            data-testid="sketchfab-viewer"
        />
    );
};

export default Sketchfab;
