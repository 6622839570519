import {connectToStores} from 'epic-alt-utils';
import PropTypes from 'prop-types';
import React from 'react';

import {BlogStore} from '../stores';

import ArticleView from './ArticleView';

/**
 * @implements {ArticleView}
 */
@connectToStores
class ArticleViewWrapper extends React.Component {
    static propTypes = {
        isLauncher: PropTypes.bool,
        blogStore: PropTypes.object,
        match: PropTypes.object
    };

    static getStores() {
        return [BlogStore];
    }

    static getPropsFromStores() {
        return {
            blogStore: BlogStore.getState()
        };
    }

    render() {
        const articleView = <ArticleView {...this.props} isLauncher={this.props.isLauncher} />;

        return <div className="article-view-wrapper">{articleView}</div>;
    }
}

export default ArticleViewWrapper;
