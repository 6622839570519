import {Container, Grid, GridColumn, Stack} from 'epic-fortnite-react';
import React from 'react';

import {RiftTourColumnImage} from './components/RiftTourColumnImage';
import {RiftTourCTA} from './components/RiftTourCTA';
import {RiftTourHeading} from './components/RiftTourHeading';
import {RiftTourSection} from './components/RiftTourSection';
import {RiftTourSubheading} from './components/RiftTourSubheading';
import {RiftTourBackground, RiftTourCtaInterface} from './types';

interface Props {
  data: CMSData;
}

interface CMSData {
  textHeading: string;
  subtitle: string;
  heroImage: string;
  headerIcon: string;
  background: RiftTourBackground;
  cta: RiftTourCtaInterface;
}

const RiftTourQuests = ({data}: Props) => {
  const {textHeading, subtitle, heroImage, headerIcon, background, cta} = data;
  const {backgroundImage, gradientFrom, gradientTo, gradientSize, gradientPosition} = background;

  return (
    <RiftTourSection
      backgroundImage={backgroundImage}
      clipPathDirection={background?.overlayDirection}
      clipPathSpike={background?.hasSpike}
      gradient={{from: gradientFrom, to: gradientTo}}
      gradientPosition={gradientPosition}
      gradientSize={`${gradientSize}%`}>
      <Container maxWidth={1200} size="med">
        <Grid breakpoint={900} gap="2xl" reverseOrder={true}>
          <GridColumn span={1 / 2}>
            <Stack align="center" gap="lg" justify="center">
              <img src={headerIcon} alt="" style={{height: 68, width: 77}} />
              <RiftTourHeading color="#fff">{textHeading}</RiftTourHeading>
              <RiftTourSubheading color="#fff">{subtitle}</RiftTourSubheading>
              {cta?.label && <RiftTourCTA label={cta.label} textLink={true} />}
            </Stack>
          </GridColumn>
          <GridColumn span={1 / 2}>
            <RiftTourColumnImage alt="" src={heroImage} style={{maxWidth: '575px'}} />
          </GridColumn>
        </Grid>
      </Container>
    </RiftTourSection>
  );
};

export default RiftTourQuests;
