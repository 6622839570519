import {XmlEntities} from 'html-entities';

// Singleton to prevent multiple copies of XmlEntities in memory.
class Entities {
    decode = null;
    constructor() {
        const entities = new XmlEntities();
        this.decode = entities.decode;
    }
}

export default new Entities();
