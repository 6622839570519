import React from 'react';
import {Link} from 'react-router-dom';
import styled, {css} from 'styled-components';

const ButtonCss = css`
  background-color: #ff0;
  background-image: none;
  border-radius: 0;
  border: none;
  color: #000000;
  color: black;
  cursor: pointer;
  display: inline-block;
  font-size: 1.5em;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 2;
  margin-bottom: 0;
  padding: 0 2em;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  touch-action: manipulation;
  transform: translateZ(0);
  transition: background-color 0.3s ease;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  z-index: 1;
  :hover {
    background-color: #e6e600;
  }
`;

export const ButtonA = styled.a`
  ${ButtonCss}
`;

export const ButtonLinkStyled = styled(Link)`
  ${ButtonCss}
`;

export const ButtonLink = ({children, to, ...rest}) => (
  <ButtonLinkStyled to={to} {...rest}>
    {children}
  </ButtonLinkStyled>
);

const ButtonStyled = styled.button`
  ${ButtonCss}
`;

export const Button = ({children, ...rest}) => <ButtonStyled {...rest}>{children}</ButtonStyled>;

export default ButtonStyled;
