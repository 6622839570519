import React from 'react';

import SubscriptionBattlePassSection from '../../components/subscription/SubscriptionBattlePassSection';
import SubscriptionBundleSection from '../../components/subscription/SubscriptionBundleSection';
import SubscriptionFaqSection from '../../components/subscription/SubscriptionFaqSection';
import SubscriptionHeader from '../../components/subscription/SubscriptionHeader';
import SubscriptionJoinSection from '../../components/subscription/SubscriptionJoinSection';
import SubscriptionPhasedBackblingSection from '../../components/subscription/SubscriptionPhasedBackblingSection';
import SubscriptionPhasedOutfitSection from '../../components/subscription/SubscriptionPhasedOutfitSection';
import SubscriptionVBucksSection from '../../components/subscription/SubscriptionVBucksSection';
import SideBySide from '../../../client/components/bundles/SideBySide';

interface Props {
  section: any;
  mobile: boolean;
  tablet: boolean;
}

const SubscriptionRocketLeagueSection = ({content}) => {
  const {coreSectionProps, heroImage, layout, textSection} = content.content;
  return (
    <SideBySide
      coreSectionProps={coreSectionProps}
      heroImage={heroImage}
      layout={layout}
      textSection={textSection}
    />
  );
};

const SubscriptionFortniteCrewLegacyStyles = ({content}) => {
  const {coreSectionProps, heroImage, layout, textSection} = content.content;
  return (
    <SideBySide
      coreSectionProps={coreSectionProps}
      heroImage={heroImage}
      layout={layout}
      textSection={textSection}
    />
  );
};

export default class SubscriptionSection extends React.Component<Props> {
  props: {section: any; mobile: boolean; tablet: boolean};
  render() {
    const {section, mobile, tablet} = this.props;

    const name = section?._slug;

    switch (name) {
      case 'subscription-header': {
        return <SubscriptionHeader content={section} mobile={mobile} tablet={tablet} />;
      }
      case 'subscription-battle-pass': {
        return <SubscriptionBattlePassSection content={section} mobile={mobile} tablet={tablet} />;
      }
      case 'subscription-vbucks': {
        return <SubscriptionVBucksSection content={section} mobile={mobile} tablet={tablet} />;
      }
      case 'subscription-bundle': {
        return <SubscriptionBundleSection content={section} mobile={mobile} tablet={tablet} />;
      }
      case 'subscription-join-the-crew': {
        return <SubscriptionJoinSection content={section} mobile={mobile} tablet={tablet} />;
      }
      case 'subscription-rocket-league-module': {
        return <SubscriptionRocketLeagueSection content={section} />;
      }
      case 'subscription-fortnite-crew-legacy-styles': {
        return <SubscriptionFortniteCrewLegacyStyles content={section} />;
      }
      case 'subscription-phased-outfits': {
        return (
          <SubscriptionPhasedOutfitSection content={section} mobile={mobile} tablet={tablet} />
        );
      }
      case 'subscription-phased-backbling': {
        return (
          <SubscriptionPhasedBackblingSection content={section} mobile={mobile} tablet={tablet} />
        );
      }
      case 'subscription-faq': {
        return <SubscriptionFaqSection content={section} mobile={mobile} tablet={tablet} />;
      }
      default:
        return <div />;
    }
  }
}
