import styled from 'styled-components';

const StepHeader = styled.h3`
  margin: 2rem 0 1rem;
  color: ${({color}) => color};
  line-height: 1;

  &&& {
    font-size: 2.2rem;
  }
`;

export default StepHeader;
