import {StyledText} from 'epic-fortnite-react';
import React from 'react';

import {CreativeModeStore} from './types';

import {CreativeHomeHeaderDocsLink, StyledCreativeHomeHeader, CreativeHomeLearnMoreLinks} from './';

interface Props {
  creativeModeStore: CreativeModeStore;
}

const CreativeHomeHeader = ({creativeModeStore}: Props) => {
  const section = creativeModeStore.creativeModeData?.headerSection;
  const sectionFeatured = creativeModeStore.creativeModeData?.featuredSection;
  const header = section?.homeHeader || [];
  const {creativeDocsLinkTitle, creativeDocsLinkURL} = section;
  const hasDocsLink = Boolean(creativeDocsLinkTitle && creativeDocsLinkURL);
  const learnMoreLinks = sectionFeatured?.learnMoreLinks || [];
  return (
    <StyledCreativeHomeHeader>
      {header.length > 0 && <StyledText data={header} />}
      {hasDocsLink && (
        <CreativeHomeHeaderDocsLink title={creativeDocsLinkTitle} url={creativeDocsLinkURL} />
      )}
      <div>
        {learnMoreLinks.length > 0 && (
          <div className="learnmore">
            {learnMoreLinks.map((link, index) => (
              <CreativeHomeLearnMoreLinks key={`cml${link.url}${index}`} link={link} />
            ))}
          </div>
        )}
      </div>
    </StyledCreativeHomeHeader>
  );
};

export default CreativeHomeHeader;
