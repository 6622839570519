import styled from 'styled-components';

import {Device} from '../../constants';

const ImageOnlyMobile = styled.img`
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  display: none;
  @media only screen and ${Device.tablet} {
    display: inline;
  }
`;

export default ImageOnlyMobile;
