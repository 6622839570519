import classNames from 'classnames';
import {BackgroundVideo} from 'epic-react-common';
import PropTypes from 'prop-types';
import React from 'react';
import VisibilitySensor from 'react-visibility-sensor';

/**
 * Display a video.
 */
export default class Video extends React.Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    mobile: PropTypes.bool,
    tablet: PropTypes.bool,
  };

  state = {
    animated: false,
    playVideo: false,
  };

  inView = (isVisible) => {
    if (!this.state.playVideo && isVisible) {
      this.setState({playVideo: true});
    } else if (this.state.playVideo && !isVisible) {
      this.setState({playVideo: false});
    }
  };

  render() {
    const {data, mobile, tablet} = this.props;
    const bkVideo = data.backgroundVideo || {};
    const backgroundStyle = {};
    if (data.backgroundColor) {
      backgroundStyle.backgroundColor = data.backgroundColor;
    }
    const classes = classNames('LPVideo', data._slug, data.style);
    return (
      <div className={classes} style={backgroundStyle}>
        <div className="after" style={backgroundStyle} />
        <VisibilitySensor
          onChange={this.inView}
          active={!this.state.animated}
          partialVisibility
          scrollCheck
          scrollDelay={10}>
          <div className="bkVid">
            <BackgroundVideo
              background={bkVideo}
              enableRatioHelper
              play={this.state.playVideo}
              noOverlay
              mute
              loop
              fixed={true}
              mobile={mobile}
              tablet={tablet}
            />
          </div>
        </VisibilitySensor>
      </div>
    );
  }
}
