import {AxiosContainer} from '@epic-node-services/public-axios';
import {checkConfig} from '@epic-node-services/common';
import {registerPublicService} from '@epic-node-services/public-service-configs';
import root from 'window-or-global';

const axios = AxiosContainer.getAxiosInstance();

// https://docs.microsoft.com/en-us/bing/search-apis/bing-web-search/reference/query-parameters
interface OverrideParams {
    answerCount?: number;
    cc?: string;
    count?: number;
    freshness?: 'day' | 'week' | 'month';
    mkt?: string;
    offset?: number;
    q?: string;
    responseFilter?: string;
    safeSearch?: 'off' | 'moderate' | 'strict';
    setLang?: string;
    textDecorations?: boolean;
    textFormat?: 'raw' | 'html';
    promote?: string;
}

interface SearchParams {
    q: string;
    offset?: number;
    locale?: string;
    filterPayload?: string;
}

class BingSearchServiceDef {
    private api;
    private key;
    private domain;
    private pageCount;

    public configure(envConfig) {
        checkConfig('BingSearchService', envConfig, 'EPIC_SERVICE_BING_SEARCH_DOMAIN', 'EPIC_SERVICE_BING_SEARCH_KEY');

        this.api = envConfig.EPIC_SERVICE_BING_SEARCH_API || 'https://api.bing.microsoft.com/v7.0/search';
        this.key = envConfig.EPIC_SERVICE_BING_SEARCH_KEY;
        this.domain = envConfig.EPIC_SERVICE_BING_SEARCH_DOMAIN;
        this.pageCount = parseInt(envConfig.EPIC_SERVICE_BING_SEARCH_PAGE_COUNT, 10) || 10;
    }

    public async search(
        {q = '', offset = 0, locale = 'en-US', filterPayload = ''}: SearchParams,
        overrideParams?: OverrideParams
    ) {
        const safeQuery = q.replace(/'/, "\\'");
        let searchQuery = `site:${this.domain} ${safeQuery}`;
        // filterPayload will replace the default search query
        if (filterPayload) {
            if (filterPayload.indexOf('*')) {
                const replacedString = filterPayload.replace('*', safeQuery);
                searchQuery = `site:${replacedString}`;
            } else {
                searchQuery = `site:${filterPayload} ${safeQuery}`;
            }
        }

        // https://docs.microsoft.com/en-us/bing/search-apis/bing-web-search/reference/query-parameters
        const params = {
            q: searchQuery, // search query
            count: this.pageCount,
            offset: +offset, // offset controls pagination
            mkt: locale, // mkt determines the market the search results are targeted for, in the format,
            setLang: locale,
            responseFilter: 'webpages',
            ...overrideParams
        };

        return axios
            .get(this.api, {
                params,
                headers: {'Ocp-Apim-Subscription-Key': this.key}
            })
            .then(resp => {
                const webPages = resp.data.webPages ? resp.data.webPages.value : [];
                const hasMoreResults = webPages.length === this.pageCount;
                const nextOffset = offset + this.pageCount;
                const prevOffset = offset - this.pageCount < 0 ? 0 : offset - this.pageCount;

                return {
                    ...resp.data,
                    query: searchQuery,
                    offset,
                    nextOffset,
                    prevOffset,
                    hasMoreResults,
                    count: this.pageCount
                };
            })
            .catch(ex => {
                console.error(`Failed to search query: ${searchQuery}.`, ex.message);
                throw ex;
            });
    }
}

// Enforce singleton even if multiple versions of this module are loaded
if (!root.__BingSearchService) {
    root.__BingSearchService = new BingSearchServiceDef();
    registerPublicService(root.__BingSearchService);
}
export const BingSearchService: BingSearchServiceDef = root.__BingSearchService;
