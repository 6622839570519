import {AnimateIn, Container, Section, Stack} from 'epic-fortnite-react';
import React from 'react';
import styled from 'styled-components';
import {WatchVideo} from '../common/WatchVideo';

import {CoreSectionProps, FunctionalThemeProps} from './types';

export interface JumboFeatureProps {
  coreSectionProps?: CoreSectionProps;
  featureImage: string;
  logo: string;
  theme: FunctionalThemeProps;
  cta?: {
    ctaLink: string;
  };
}

const StyledJumboFeatureImage = styled.img`
  display: block;
  margin: 0 auto;
  max-height: 60vh;
  object-fit: contain;
  width: 100%;
  @media only screen and (min-width: 1400px) {
    margin-top: 3rem;
  }
`;

const StyledJumboLogo = styled.img`
  display: block;
  margin: auto;
  max-height: 225px;
  max-width: 100%;
  transform: translateY(-32px);
`;

const JumboFeature = (props: JumboFeatureProps) => {
  const {theme = {}, coreSectionProps, featureImage, logo, cta} = props;

  return (
    <Section
      backgroundColor={
        coreSectionProps?.backgroundColor
          ? coreSectionProps.backgroundColor
          : theme.pageBackgroundColor
      }
      backgroundImage={coreSectionProps?.backgroundImage}
      backgroundPosition={coreSectionProps?.backgroundPosition}
      backgroundSize={coreSectionProps?.backgroundSize}
      backgroundVideo={coreSectionProps?.backgroundVideo}
      size="med">
      <Container maxWidth={1200} size="lg">
        <Stack gap="med">
          {featureImage && (
            <AnimateIn>
              <StyledJumboFeatureImage src={featureImage} alt="" />
            </AnimateIn>
          )}
          {logo && <StyledJumboLogo src={logo} />}
        </Stack>
        {cta?.ctaLink ? (
          <div style={{padding: '1.5rem 0 3rem 0', margin: '-1.5rem 0 3rem 0'}}>
            <WatchVideo videoId={cta?.ctaLink} textColor="#fff" />
          </div>
        ) : null}
      </Container>
    </Section>
  );
};

export default JumboFeature;
