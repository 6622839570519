// We shouldn't really be importing from a node package in client code
// import getTagsFromModel from 'epic-node-cms/dist/cmsUtils/metaUtils'; // disabling and copied code over to prevent node code from being imported
import parse from 'url-parse';
import root from 'window-or-global';

function getTagsFromModel(pageModel) {
  if (!pageModel) return {tags: null, title: null};

  const metaTags = pageModel._metaTags || null;
  const tagMatches = metaTags ? metaTags.match(/<meta(.*?)>/gim) : [];
  const titleMatches = metaTags ? metaTags.match(/<title>(.*?)<\/title>/gim) : [];

  const tags = [];
  if (tagMatches && tagMatches.length) {
    tagMatches.forEach((t) => {
      const tag = {};

      let name = t.match(/name="(.*?)"/);
      if (name && name[1]) {
        name = name[1];
        tag.name = name;
      }

      let property = t.match(/property="(.*?)"/);
      if (property && property[1]) {
        property = property[1];
        tag.property = property;
      }

      let content = t.match(/content="(.*?)"/);
      if (content && content[1]) {
        content = content[1];
        tag.content = content;
      }

      tags.push(tag);
    });
  }

  let title = pageModel._title || '';
  if (titleMatches && titleMatches.length) {
    title = titleMatches[0].replace('<title>', '').replace('</title>', '');
  }

  return {tags, title};
}

interface MetaProperties {
  name?: string;
  property?: string;
  content?: string;
}

interface MetaLinks {
  href: string;
  rel: string;
}

interface MetaTags {
  meta?: MetaProperties[];
  title?: string;
  links?: MetaLinks[] | null;
}

interface TagsModel {
  tags: MetaProperties[];
  title?: string;
}

export const getHelmetTags = (page) => {
  const data: TagsModel = getTagsFromModel(page);

  const currentUrl = parse(root.location.href);
  currentUrl.set('hash', '');
  currentUrl.set('query', '');
  const tags: MetaTags = {
    meta: [
      {
        name: 'og:url',
        content: currentUrl.toString(),
      },
    ],
  };

  if (data.tags) {
    tags.meta = [...data.tags, ...(tags.meta || [])];
  }
  if (data.title) {
    tags.title = data.title;
  }
  return tags;
};
