import {withLocalization} from '@epic-core/localization';
import qs from 'query-string';
import React from 'react';
import Helmet from 'react-helmet';
import {useLocation} from 'react-router';
import root from 'window-or-global';

import SocialShareImage from '../../images/Social_ShareImage.jpg';
import {getMetaTags} from '../../utils/FortniteUtils';

function trimTrailingSlash(_path: string): string {
  let path = _path;
  if (path.match(/\/$/)) {
    path = path.slice(0, path.length - 1);
  }
  return path;
}

const Head = ({messages, supportedLocales, title, locale}) => {
  const location = useLocation();
  const tags = getMetaTags({messages}, SocialShareImage);

  const url = trimTrailingSlash(
    `${root.location.origin}${root.appContext}${location.pathname}`.toLowerCase()
  );

  return (
    <Helmet meta={tags} title={title}>
      <link
        rel="canonical"
        href={`${url}?${qs.stringify({
          lang: locale,
        })}`}
      />
      {supportedLocales.map((locale) => {
        const {lang} = locale;
        return (
          <link
            key={lang}
            rel="alternate"
            href={`${url}?${qs.stringify({
              lang,
            })}`}
            hrefLang={lang}
          />
        );
      })}
      <link
        rel="alternate"
        href={`${url}?${qs.stringify({
          lang: 'en-US',
        })}`}
        hrefLang="x-default"
      />
    </Helmet>
  );
};

export default withLocalization(Head); // can we replace this with a hook
