import PropTypes from 'prop-types';
import React from 'react';
import root from 'window-or-global';

import ScreenSizeProviderClient from './ScreenSizeProviderClient';
import ScreenSizeProviderServer from './ScreenSizeProviderServer';

// Prevent useLayloutEffect warning on server side.
const ScreenSizeProvider = (props) => {
  if (root.__server_side_render) {
    return <ScreenSizeProviderServer {...props} />;
  }
  return <ScreenSizeProviderClient {...props} />;
};

ScreenSizeProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.object), PropTypes.object]),
};

export default ScreenSizeProvider;
