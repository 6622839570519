import axios from 'axios';
import root from 'window-or-global';

import CmsStoreActions from '../actions/CmsStoreActions';
import Url from '../constants/Url';

/**
 * ErrorSource
 *
 * Responsible for making the ajax calls for errors.
 *
 * For more information on source usage:
 * http://alt.js.org/docs/async/
 *
 */
const CmsStoreSource = {
  getCmsData: {
    remote(state, locale, slug) {
      //the store state is accessible here
      return axios.get(Url().CMS_STORE.GET(locale, slug));
    },

    //On success call the following action:
    success: CmsStoreActions.getCmsDataSuccess,

    //On failure call the following action:
    error: CmsStoreActions.getCmsDataFailure,

    shouldFetch() {
      return !root.__server_side_render;
    },
  },
};

export default CmsStoreSource;
