import {withLocalization} from '@epic-core/localization';
import React from 'react';
import styled from 'styled-components';

import VideoPlayButton from '../../common/VideoPlayButton';

const StyledPlayVideo = styled.button`
  align-items: center;
  appearance: none;
  background: none;
  border: 0;
  display: flex;
  filter: drop-shadow(0 0 16px rgba(0, 0, 0, 0.5));
  font-size: 24px;
  gap: 24px;

  @media (min-width: 900px) {
    font-size: 32px;
  }
`;
interface Props {
  playVideo: (e: React.MouseEventHandler<any>) => void;
  playText?: string;
  videoId: string;
  getMessage: (code: string) => string;
}

const PlayVideo = ({playVideo, playText, videoId, getMessage}: Props) => (
  <StyledPlayVideo onClick={playVideo}>
    {<h4>{playText || getMessage('epic.fortnite.trailer')}</h4>}
    {videoId && <VideoPlayButton css={{fontSize: '0.375em'}} id="season-overview-video" />}
  </StyledPlayVideo>
);

export default withLocalization(PlayVideo);
