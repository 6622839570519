import React from 'react';

import {FAQItemInterface} from '../../stores/types';
import AnimateObject from '../common/AnimateObject';

import FAQItem from './FAQItem';

interface Props {
  itemList: FAQItemInterface[];
}

const FAQSection = ({itemList}: Props) => (
  <React.Fragment>
    {itemList.map((item, index) => (
      <AnimateObject
        key={`faq-item-${index}`}
        className={'subscription-faq-item-wrapper'}
        minTop={20}
        speed={3}
        from={{y: `+=100`, autoAlpha: 0}}
        to={{y: 0, autoAlpha: 1, delay: index * 0.05}}
        style={{display: 'block'}}>
        <FAQItem question={item.question} answer={item.answer} />
      </AnimateObject>
    ))}
  </React.Fragment>
);

export default FAQSection;
