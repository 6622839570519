import ClassNames from 'classnames';
import {YoutubeVideoEmbed} from 'epic-react-common';
import PropTypes from 'prop-types';
import React from 'react';

export default class VideoOverlay extends React.PureComponent {
  static propTypes = {
    videoID: PropTypes.string,
    isLauncher: PropTypes.bool,
    play: PropTypes.bool,
    close: PropTypes.func,
    closeButton: PropTypes.bool,
    show: PropTypes.bool,
  };
  render() {
    const videoOverlayClass = ClassNames('video-overlay', {active: this.props.show});
    return (
      <div
        role="presentation"
        onClick={this.props.close}
        id="videoOverlay"
        className={videoOverlayClass}>
        <div className="container full-height">
          <div className="video-container embed-responsive embed-responsive-16by9">
            <YoutubeVideoEmbed
              video={this.props.videoID}
              play={this.props.play}
              launcher={this.props.isLauncher}
            />
            {this.props.closeButton ? (
              <div role="presentation" className="close-video" onClick={this.props.close}>
                <div className="icon-close" />
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}
