import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import {sanitizeCopy} from '@epic-mw/common-utils/dist/contentUtils';

export default class PatchNotesText extends React.Component {
  static propTypes = {
    content: PropTypes.object,
  };

  render() {
    const {content} = this.props;
    if (!content) return <div />;
    const headerID =
      content.sectionHeader && content.sectionKey ? `${content.sectionKey}-anchor` : '';
    const headerClasses = classNames('patch-notes-text', 'section', 'container-fluid', {
      'section-header': content.sectionHeader,
      noBreak: content.noLineBreak,
    });
    return (
      <div className={headerClasses}>
        <div className="row">
          <div className="col-xs-12 col-sm-11 col-lg-8">
            {content.title ? (
              <h1 className={content.color} id={headerID}>
                {content.title}
              </h1>
            ) : null}
            <div
              className="patch-notes-description"
              dangerouslySetInnerHTML={{__html: sanitizeCopy(content.description)}}
            />
          </div>
        </div>
      </div>
    );
  }
}
