import React from 'react';
import root from 'window-or-global';

interface Props {
  desktopImage: string;
  mobileImage: string;
  mobile: boolean;
  alt: string;
  className?: string;
  style?: React.CSSProperties;
  breakPoint?: number;
}

const BackgroundImage = ({
  desktopImage,
  mobileImage,
  mobile,
  alt,
  className,
  style,
  breakPoint = 768,
}: Props) => {
  const [image, setImage] = React.useState(mobile ? mobileImage : desktopImage);
  React.useEffect(() => {
    const screenSize = () => {
      if (!root.document) {
        // Skip SSR.
        return;
      }
      const calcWidth =
        root.innerWidth ||
        root.document.documentElement.clientWidth ||
        root.document.body.clientWidth;
      if (calcWidth <= breakPoint) {
        setImage(mobileImage);
      } else if (calcWidth > breakPoint) {
        setImage(desktopImage);
      }
    };
    screenSize();
    if (root.addEventListener) {
      root.addEventListener('resize', screenSize);
    }
    return () => root.removeEventListener('resize', screenSize);
  }, []);

  return <img src={image} alt={alt} className={className} style={style} />;
};

export default BackgroundImage;
