import React from 'react';
import VisibilitySensor from 'react-visibility-sensor';

import FeatureTitleSpikesContainer from './FeatureTitleSpikesContainer';
import TitleSpike from './TitleSpike';

interface Props {
  spike: string;
  mobile: boolean;
  tablet?: boolean;
}
const FeatureHeroTitleSpikes = ({spike, mobile}: Props) => {
  const [visible, setVisibility] = React.useState(false);

  return (
    <VisibilitySensor
      active={!visible}
      onChange={(visibility) => setVisibility(visibility)}
      partialVisibility>
      <FeatureTitleSpikesContainer>
        <TitleSpike
          initial={{opacity: 0, y: 100, scale: mobile ? 0.8 : 0.5}}
          animate={visible ? {opacity: 1, y: 0} : {opacity: 0, y: 100}}
          transition={{ease: 'easeInOut', duration: 0.5, delay: 0.1}}
          size="large"
          src={spike}
        />
        <TitleSpike
          initial={{opacity: 0, y: 100, rotateY: 180, scale: 0.5}}
          animate={
            visible
              ? {opacity: 1, y: 0, rotateY: 180, scale: 0.6}
              : {opacity: 0, y: 100, rotateY: 180, scale: 0.6}
          }
          transition={{ease: 'easeInOut', duration: 0.5, delay: 0.1}}
          size="small"
          src={spike}
        />
      </FeatureTitleSpikesContainer>
    </VisibilitySensor>
  );
};

export default FeatureHeroTitleSpikes;
