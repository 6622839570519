import styled from 'styled-components';

const DifficultyLabel = styled.span`
  margin-right: 1.5rem;
  line-height: 100%;
  color: black;
  white-space: nowrap; // Fix Safari random linebreaks bug
`;

export default DifficultyLabel;
