import {motion} from 'framer-motion';
import React from 'react';
import styled, {css} from 'styled-components';

import {Device} from '../../../constants';

const ContentBox = styled(({...rest}) => <motion.div {...rest} />)`
  position: relative;
  width: 100%;
  max-width: 35em;
  flex: 0 1 auto;

  #speech-tail {
    width: 40px;
    height: 40px;
    position: absolute;
    left: 97%;
    top: 35%;
  }

  #speech-border {
    width: 180px;
    position: absolute;
    bottom: -24px;
    left: -9px;
    transform: rotate(-2deg);
  }

  @media screen and (max-width: 940px) {
    text-align: center;

    #speech-tail {
      left: 0;
      right: 0;
      top: unset;
      bottom: calc(100% - 2px);
      transform: rotate(-90deg);
      margin: 0 auto;
    }
  }

  ${(props) =>
    props.callout
      ? css`
          padding: 2em 3em;
          text-align: left !important;

          &:after {
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 0;
            background: linear-gradient(20deg, rgba(0, 50, 84, 1) 0%, rgba(2, 96, 162, 1) 70%);
            clip-path: polygon(0 0, 100% 0, 97% 98%, 3% 100%);
          }

          @media screen and (max-width: 940px) {
            transform: rotateZ(-4deg) translate3d(0, -30%, 0);
          }

          @media screen and ${Device.laptop} {
            padding: 1em 2em;
          }
        `
      : null};
`;

export default ContentBox;
