import {withLocalization} from '@epic-core/localization';
import Proptypes from 'prop-types';
import React from 'react';

import PartyRoyaleCalendarPicker from './PartyRoyaleCalendarPicker';
import {icon, useScheduleEvent} from './ScheduleItemDate.hooks';
import {convertDate} from './utils';

const ScheduleItemDate = ({data, getMessage}) => {
  const {date} = data;
  const localDate = convertDate(date);
  const {event, items} = useScheduleEvent(data, getMessage);
  return (
    <div className="ScheduleItemDate">
      {localDate}
      <PartyRoyaleCalendarPicker
        buttonTemplate={icon}
        event={event}
        buttonLabel=""
        listItems={items}
      />
    </div>
  );
};

ScheduleItemDate.propTypes = {
  data: Proptypes.shape({
    name: Proptypes.string,
    location: Proptypes.string,
    description: Proptypes.string,
    moreInfo: Proptypes.string,
    moreInfoUrl: Proptypes.string,
    column2: Proptypes.string,
    date: Proptypes.oneOfType([Proptypes.instanceOf(Date), Proptypes.string]),
    maxLocalDate: Proptypes.oneOfType([Proptypes.instanceOf(Date), Proptypes.string]),
    minLocalDate: Proptypes.oneOfType([Proptypes.instanceOf(Date), Proptypes.string]),
  }),
  getMessage: Proptypes.func,
};

export default withLocalization(ScheduleItemDate);
