import {getLocale} from '@epic-core/common';
import {generateRouteTo} from 'epic-fortnite-react';
import PropTypes from 'prop-types';
import React from 'react';
import {Link, useLocation} from 'react-router-dom';
import root from 'window-or-global';

const calColumns = (index, total) => {
  // Max length is 8 items.
  if (total >= 8) {
    return 4;
  }
  // If less then 4 items, put all on one row.
  if (total <= 4) {
    return total;
  }
  const firstRow = Math.ceil(total / 2);
  if (index < firstRow) {
    return firstRow;
  }
  // Return remainder on second row.
  return total - firstRow;
};

const calcWidth = (index, total) => {
  const columns = calColumns(index, total);
  switch (columns) {
    case 1:
      return 'island col-sm-12';
    case 2:
      return 'island col-sm-6';
    case 3:
      return 'island col-sm-4';
    case 4:
    default:
      return 'island col-sm-3';
  }
};

const CreativeHomeFeaturedIsland = ({
  item,
  defaultImage,
  islandImgAltTemplate,
  index,
  total,
  setIsland,
}) => {
  const location = useLocation();
  const locale = getLocale();
  const url = generateRouteTo(`/creative/island-codes/${item.url}`, location, locale);
  const onClick = React.useCallback(
    (event) => {
      event.stopPropagation();
      event.preventDefault();
      setIsland({success: true, ...item});
    },
    [item, url]
  );
  const img = (item.image || defaultImage || '').replace(/^http:/, 'https:');
  const containerClass = calcWidth(index, total);
  const title = React.useMemo(() => {
    if (islandImgAltTemplate) return islandImgAltTemplate.replace('{title}', item.title);
    return item.title;
  }, [islandImgAltTemplate, item]);
  // Image is not loaded with ssr to allow catching of a 404 error loading images.
  const onerror = React.useCallback(
    (event) => {
      if (event.target.src !== defaultImage) {
        event.target.src = defaultImage;
      }
    },
    [defaultImage]
  );
  return (
    <div className={containerClass} role="presentation" onClick={onClick}>
      <div className="col-md-12">
        <Link to={url}>{root.document && <img onError={onerror} src={img} alt={title} />}</Link>
        <div className="caption">
          {item.date && <h5>{item.date}</h5>}
          <Link to={url}>{item.title}</Link>
        </div>
      </div>
    </div>
  );
};

CreativeHomeFeaturedIsland.defaultProps = {
  item: {},
};

CreativeHomeFeaturedIsland.propTypes = {
  item: PropTypes.shape({
    title: PropTypes.string,
    code: PropTypes.string,
    image: PropTypes.string,
    date: PropTypes.string,
    url: PropTypes.string,
  }),
  defaultImage: PropTypes.string,
  islandImgAltTemplate: PropTypes.string,
  index: PropTypes.number,
  total: PropTypes.number,
};

export default CreativeHomeFeaturedIsland;
