import styled from 'styled-components';

import {Device} from '../../constants';

interface Props {
  $bgGradientImg?: string;
  $bgGradientMobileImg?: string;
  $bgGradient?: string;
  $bgDark?: string;
}

export default styled.div<Props>`
  &&&& {
    background: ${({$bgGradientImg}) => `url(${$bgGradientImg})`};
    background-repeat: no-repeat;
    background-size: cover;
    ${({$bgGradient}) => ($bgGradient && `background: linear-gradient${$bgGradient};`) || ''}
    ${({$bgDark}) => ($bgDark && `background-color: ${$bgDark};`) || ''}
        @media screen and ${Device.tablet} {
      ${({$bgGradientMobileImg}) =>
        ($bgGradientMobileImg && `background-image: url(${$bgGradientMobileImg})`) || ''};
    }
  }
`;
