import {getLocale} from '@epic-core/common';
import classNames from 'classnames';
import {connectToStores} from 'epic-alt-utils';
import {generateRouteHref, generateRouteTo} from '../../utils/route';
import PropTypes from 'prop-types';
import React from 'react';
import {withRouter} from 'react-router';

import LoginActions from '../../actions/LoginActions';
import HeaderStore from '../../stores/HeaderStore';
import StoreStore from '../../stores/StoreStore';
import Message from '../message/Message';

/**
 * DownloadButton.
 */
@connectToStores
class DownloadButton extends React.PureComponent {
  static propTypes = {
    isLoggedIn: PropTypes.bool,
    plain: PropTypes.bool,
    id: PropTypes.string,
    className: PropTypes.string,
    returnLink: PropTypes.string,
    cta: PropTypes.string,
    rawCta: PropTypes.string,
    lgButton: PropTypes.bool,
    exLink: PropTypes.string,
    disableBtn: PropTypes.bool,
    ios: PropTypes.bool,
    android: PropTypes.bool,
    history: PropTypes.object,
    worldCupStyle: PropTypes.bool,
  };

  static getStores() {
    return [StoreStore, HeaderStore];
  }

  static getPropsFromStores() {
    let exLink = null;
    if (StoreStore.getState().battleRoyale.header) {
      exLink = StoreStore.getState().battleRoyale.header.ctaLink || null;
    }
    return {
      exLink,
      ios: HeaderStore.getState().ios(),
      android: HeaderStore.getState().android(),
    };
  }

  onClick = (locale) => {
    const {exLink, disableBtn, returnLink, ios, android, history, location} = this.props;
    let buttonLink = returnLink || 'download';
    if (exLink) {
      buttonLink = exLink;
    }
    if (exLink && disableBtn) {
      buttonLink = '';
    }
    if (ios) {
      history.push(generateRouteTo('/download', location, locale));
    } else if (android) {
      history.push(generateRouteTo('/mobile/android', location, locale));
    } else {
      LoginActions.requestDownloadModal({redirectUrl: buttonLink});
    }
  };

  render() {
    const {
      isLoggedIn,
      id,
      cta,
      rawCta,
      className,
      returnLink,
      plain,
      lgButton,
      exLink,
      disableBtn,
      worldCupStyle,
      location,
    } = this.props;
    const locale = getLocale();
    const buttonClasses = classNames(
      'btn',
      worldCupStyle ? '' : 'download-button',
      worldCupStyle ? '' : 'btn-primary',
      plain ? '' : 'btn-display',
      className || 'download-btn-default',
      {'btn-lg': lgButton, disabled: disableBtn}
    );
    const boltClass = classNames('bolt', {hide: disableBtn});
    let buttonLink = returnLink || generateRouteHref('/download', location, locale);
    if (exLink) {
      buttonLink = exLink;
    }
    if (exLink && disableBtn) {
      buttonLink = '';
    }
    //if user is already logged in go straight to download page
    if (isLoggedIn) {
      return (
        <div>
          {exLink ? (
            <a id={id} className={buttonClasses} href={buttonLink}>
              <div className={boltClass}>
                <i className="icon-down-arrow" />
              </div>
              {rawCta ? <span>{rawCta}</span> : <Message code={cta} />}
            </a>
          ) : (
            <button
              id={id}
              className={buttonClasses}
              onClick={() => this.onClick(locale)}
              disabled={disableBtn}>
              {rawCta ? <span>{rawCta}</span> : <Message code={cta} />}
            </button>
          )}
        </div>
      );
    }
    const link = exLink ? (
      <a id={id} className={buttonClasses} href={buttonLink} disabled={disableBtn}>
        <div className={boltClass}>
          <i className="icon-down-arrow" />
        </div>
        {rawCta ? <span>{rawCta}</span> : <Message code={cta} />}
      </a>
    ) : (
      <button id={id} className={buttonClasses} onClick={this.onClick} disabled={disableBtn}>
        {rawCta ? <span>{rawCta}</span> : <Message code={cta} />}
      </button>
    );
    return <div>{link}</div>;
  }
}

export default withRouter(DownloadButton);
