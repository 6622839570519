import {connectToStores} from 'epic-alt-utils';
import PropTypes from 'prop-types';
import React from 'react';
import {withRouter} from 'react-router';

import {BattlePassOverviewStore, LandingPageStore} from '../../stores';
import BattlePassOverview from '../battlePass/BattlePassOverview';
import LandingPageView from '../landingpages/LandingPageView';
import SimpleView from '../simple/SimpleView';

/**
 * DefaultView.
 *
 * Seaches for a landing page or a simple page which are
 * located in /landing-pages/* and /pages/* in the cms
 */

@connectToStores
class DefaultView extends React.Component {
  static propTypes = {
    location: PropTypes.object,
    match: PropTypes.object.isRequired,
    landingPageStore: PropTypes.object,
    battlePassOverviewStore: PropTypes.object,
  };

  static getStores() {
    return [LandingPageStore, BattlePassOverviewStore];
  }

  static getPropsFromStores() {
    return {
      landingPageStore: LandingPageStore.getState(),
      battlePassOverviewStore: BattlePassOverviewStore.getState(),
    };
  }

  getPageSlug(props) {
    const prop = props || this.props;
    const activeSlug = prop.match.params.slug;
    const parentSlug = prop.match.params.parent ? `${prop.match.params.parent}/` : '';
    return `${parentSlug}${activeSlug}`;
  }

  render() {
    const activeSlug = this.getPageSlug();
    const battlePassOverviewStore = this.props.battlePassOverviewStore;

    if (
      battlePassOverviewStore &&
      battlePassOverviewStore.pageSlugs &&
      battlePassOverviewStore.pageSlugs.includes(activeSlug)
    ) {
      return <BattlePassOverview slug={activeSlug} />;
    }
    const landingPages = this.props.landingPageStore.pages.pages;
    const landingPage = landingPages[activeSlug];

    if (landingPage) {
      return <LandingPageView {...this.props} />;
    }

    return <SimpleView {...this.props} />;
  }
}

export default withRouter(DefaultView);
