import {motion} from 'framer-motion';
import React from 'react';
import styled from 'styled-components';

const HeroContainer = styled(({...rest}) => <motion.div {...rest} />)`
  position: absolute;
  height: 100%;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default HeroContainer;
