import {Stack} from 'epic-fortnite-react';
import React from 'react';

import Baseplate from '../../components/baseplate/Baseplate';
import CtaFooter from '../../components/chapter2/s7/CtaFooter';
import MetaTags from '../../components/common/MetaTags';
import FlexibleHero from '../../components/flexible-hero/FlexibleHero';

const Chapter3Overview = ({page}) => {
  const {header, content, footer} = page;

  return (
    <div style={{backgroundColor: '#fff'}}>
      <MetaTags pageModel={page} />
      <FlexibleHero data={header} />
      <div style={{marginTop: '-4vw'}}>
        <Stack gap="2xl">
          <Baseplate blocks={content.items} />
          <CtaFooter data={footer} mobile={false} />
        </Stack>
      </div>
    </div>
  );
};

export default Chapter3Overview;
