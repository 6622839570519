import React from 'react';
import PropTypes from 'prop-types';
import {connectToStores} from 'epic-alt-utils';
import SearchStore from '../stores/SearchStore';
import queryString from 'query-string';
import PageJumbotron from '../components/PageJumbotron';
import Themes from '../constants/Themes';
import {withLocalization} from '@epic-core/localization';
import styled from 'styled-components';
import { BingResultsList } from '../components';

const Wrap = styled.div`
    .container {
        max-width: 1200px;
    }

    .searchView {
        min-height: 80vh;
        background-color: ${p => p.theme.bingSearch.white};
        background-size: 100% auto;
        ${(p) => p.theme.breakpoints.down('xs')} {
            padding: 1em 0;
        }
        &.bingView {
            min-height: 40vh;
        }
        h1 {
            padding-bottom: 0.6em;
            border-bottom: 1px solid ${p => p.theme.bingSearch.darkGray};
            margin-bottom: 0.6em;
            ${(p) => p.theme.breakpoints.down('xs')} {
                font-size: 2em;
            }
        }
    }
`;

class SearchView extends React.Component {
    static propTypes = {
        theme: PropTypes.oneOf([
            Themes.DARK,
            Themes.LIGHT
        ]),
        location: PropTypes.object,
        messages: PropTypes.object,
        searchFilters: PropTypes.array,
        enableCustomFilter: PropTypes.bool
    };
    static defaultProps = {
        theme: Themes.DARK,
        location: {}
    };
    static getStores() {
        return [SearchStore];
    }

    static getPropsFromStores() {
        return {
            searchStore: SearchStore.getState()
        };
    }

    static propTypes = {
        location: PropTypes.object,
        searchStore: PropTypes.object,
        sectionName: PropTypes.string,
        privacyStatement: PropTypes.string
    };

    render() {
        const {searchStore, theme, messages = {}, searchFilters = [], enableCustomFilter, sectionName, privacyStatement} = this.props;
        const {location = {}} = this.props;
        const searchQuery = (location && location.search) ? queryString.parse(location.search) : '';
        // try to get both 'keyword' and 'q' params value for the sake of backward compatibility, 'keyword' takes higher priority
        const query = searchQuery.keyword || searchQuery.q || '';
        const offset = parseInt(searchQuery.offset, 10) || 0;
        const filter = searchQuery.filter || '';

        return (
            <Wrap>
                <div className="container">
                    <PageJumbotron sectionNameCode="epic.common.search" sectionName={sectionName} />
                </div>

                <div className="searchView">
                    <div className="container">
                        <BingResultsList
                            enableCustomFilter={enableCustomFilter}
                            searchFilters={searchFilters}
                            messages={messages}
                            theme={theme}
                            searchStore={searchStore}
                            query={query}
                            offset={offset}
                            filter={filter}
                            privacyStatement={privacyStatement} />
                    </div>
                </div>
            </Wrap>
        );
    }
}

export default withLocalization(connectToStores(SearchView));
