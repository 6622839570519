import React from 'react';
import root from 'window-or-global';

const ScreenSizeContext = React.createContext({
  innerWidth: root.innerWidth,
  innerHeight: root.innerHeight,
});

ScreenSizeContext.displayName = 'ScreenSize';

export default ScreenSizeContext;
