import styled from 'styled-components';

import {Device} from '../../../constants';

const HeaderContentWrapper = styled.div`
  display: flex;
  height: 100%;
  align-items: flex-end;

  @media screen and ${Device.mobileL} {
    align-items: flex-start;
  }
`;

export default HeaderContentWrapper;
