import {getBowserState} from '@epic-core/common';
import {generateRouteTo} from 'epic-fortnite-react';

import {Link, withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import root from 'window-or-global';

class FeatureBanner extends React.Component {
    static propTypes = {
        post: PropTypes.object,
        locale: PropTypes.string,
        routerRootUrl: PropTypes.string,
        linkUrlPrefix: PropTypes.string
    };
    static defaultProps = {
        linkUrlPrefix: ''
    };

    state = {
        yPos: 0,
        mobile: false
    };
    componentDidMount() {
        root.addEventListener('resize', this.resize);
        root.addEventListener('scroll', this.scroll);
    }
    componentWillUnmount() {
        root.removeEventListener('resize', this.resize);
        root.removeEventListener('scroll', this.scroll);
    }
    scroll = () => {
        const featureImg = this.parallaxImage;
        const box = featureImg.getBoundingClientRect();
        const boxTop = box.top;

        if (boxTop < 0) {
            const yPos = Math.round(Math.abs(boxTop) / 2);
            this.setState({yPos});
        }
    };

    resize = () => {
        const ww =
            root.innerWidth ||
            root.document.documentElement.clientWidth ||
            root.document.body.clientWidth;
        if (ww <= 480 && !this.state.mobile) {
            this.setState({mobile: true});
        } else if (ww > 480 && this.state.mobile) {
            this.setState({mobile: false});
        }
    };

    render() {
        const {post, locale, routerRootUrl, linkUrlPrefix, location} = this.props;
        const {mobile} = getBowserState();
        const isMobile = mobile || this.state.mobile;
        const blog = post || {};
        const imageUrl =
            isMobile && blog.mobileThumbnailImage ? blog.mobileThumbnailImage : blog.trendingImage;
        const sticky = blog.sticky;
        const title = blog.title;
        const slug = blog.slug;
        const postDate = blog.date;
        const dateOptions = {
            month: 'short',
            year: 'numeric',
            day: 'numeric'
        };
        let date;
        if (blog.customDate) {
            date = blog.customDate;
        } else {
            date = postDate
                ? new Date(postDate).toLocaleDateString(locale.replace(/_/g, '-'), dateOptions)
                : '';
        }
        const routeUrl = routerRootUrl;
        const externalLink = blog.externalLink;
        const postLink = externalLink || `${linkUrlPrefix}${routeUrl}/${slug}`;
        const slugRoutePath = generateRouteTo(postLink, location, locale);
        const rootClasses = classNames('feature-banner', {'sticky-post': sticky});
        const bkgrdStyle = {
            backgroundImage: `url(${imageUrl})`,
            transform: `translateY(${this.state.yPos}px)`,
            MsTransform: `translateY(${this.state.yPos}px)`,
            WebkitTransform: `translateY(${this.state.yPos}px)`
        };

        const headlineClass = classNames('feature-headline');
        return (
            <Link to={slugRoutePath} id={`qa-banner-${slug}`} className={rootClasses}>
                <div className="container-image">
                    <div
                        ref={(c) => {
                            this.parallaxImage = c;
                        }}
                        style={bkgrdStyle}
                        className="feature-image"
                    />
                </div>
                <div className={headlineClass}>
                    <div className="feature-date">
                        <p>{date}</p>
                    </div>
                    <h2>{title}</h2>
                </div>
            </Link>
        );
    }
}
export default withRouter(FeatureBanner);
