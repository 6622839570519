import {BaseButton, BaseButtonProps} from 'epic-fortnite-react';
import React from 'react';
import styled from 'styled-components';

import {RiftTourCtaTheme} from '../types';

export type StyledRiftTourButtonProps = {
  $theme?: RiftTourCtaTheme;
};

export interface RiftTourButtonProps extends BaseButtonProps {
  theme?: RiftTourCtaTheme;
}

const StyledRiftTourButton = styled(BaseButton)<StyledRiftTourButtonProps>`
  --fn-button__background-color: ${({$theme}) =>
    $theme?.backgroundColor ? $theme?.backgroundColor : '#68d8f4'};

  clip-path: polygon(0% 16%, 100% 6%, 98% 100%, 2% 90%);
  padding: 24px 32px 20px;
  background-color: var(--fn-button__background-color);
  text-align: center;

  &:hover {
    opacity: 0.8;
  }

  &.focus-visible {
    background-color: #000;
  }

  &::before {
    content: '';
    position: absolute;
    top: 4px;
    left: 4px;
    right: 4px;
    bottom: 4px;
    background: var(--fn-button__background-color);
    clip-path: polygon(0% 16%, 100% 6%, 98% 100%, 2% 90%);
  }
`;

export const RiftTourButton = ({theme, ...props}: RiftTourButtonProps) => {
  return <StyledRiftTourButton color={theme?.color ?? '#000'} $theme={theme} {...props} />;
};
