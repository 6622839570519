import {Container, Section} from 'epic-fortnite-react';
import React from 'react';

import {CmsComponentMapper} from '@epic-mw/react-cms';

import SimpleTextWithBackground from '../blocks/SimpleTextWithBackground';
import SimpleDisplayCardSection from '../simpleCard/SimpleDisplayCardSection';
import SideBySideShare from '../sideBySide/SideBySideShare';
import LegalTextSection from '../blocks/LegalTextSection';

import DualLegalBlock from '../blocks/DualLegalBlock';
import MobileLandingHero from '../mobile/MobileLandingHero';
import JumboFeature from '../bundles/JumboFeature';
import SideBySide from '../bundles/SideBySide';
import StackedHero from '../blocks/StackedHero';
import NestedBlocksBaseplate from './NestedBlocksBaseplate';
import {FAQSection} from '../blocks/FAQSection';

const EventDetailsBaseplate = (props) => {
  const {blocks, shared, condensed} = props;

  return (
    <CmsComponentMapper
      dynamicPageDataArray={blocks}
      componentMapping={{
        condensedHero: (props: React.ComponentProps<typeof MobileLandingHero>) => (
          <MobileLandingHero data={props} />
        ),
        // Could add the 'isCondensed' field directly to Jumbo to determine jumbo/twoColumnHero.
        jumboHero: (props: React.ComponentProps<typeof JumboFeature>) => (
          <JumboFeature {...props} />
        ),
        stackedHero: (props: React.ComponentProps<typeof StackedHero>) => (
          <StackedHero {...props} />
        ),
        sideBySide: (props: React.ComponentProps<typeof SideBySide>) => <SideBySide {...props} />,
        cardSection: (props: React.ComponentProps<typeof SimpleDisplayCardSection>) => (
          <Section size="2xl" backgroundColor={props.cardBackgroundColor}>
            <Container maxWidth={1600} size="med">
              <SimpleDisplayCardSection {...props} />
            </Container>
          </Section>
        ),
        textSection: (props: React.ComponentProps<typeof SimpleTextWithBackground>) => (
          <SimpleTextWithBackground
            alt={shared && shared === 'true'}
            {...props}
            condensed={condensed}
          />
        ),
        shareSection: (props: React.ComponentProps<typeof SideBySideShare>) => (
          <SideBySideShare {...props} condensed={condensed} />
        ),
        legalTextSection: (props: React.ComponentProps<typeof LegalTextSection>) => (
          <LegalTextSection {...props} />
        ),
        multiLegalSection: (props: React.ComponentProps<typeof DualLegalBlock>) => (
          <Section size="sm" backgroundColor={props.backgroundColor}>
            <Container maxWidth={1600}>
              <DualLegalBlock {...props} />
            </Container>
          </Section>
        ),
        groupedBlocks: (props: React.ComponentProps<typeof NestedBlocksBaseplate>) => (
          <NestedBlocksBaseplate {...props} />
        ),
        faq: (props: React.ComponentProps<typeof FAQSection>) => <FAQSection {...props} />,
      }}
    />
  );
};

export default EventDetailsBaseplate;
