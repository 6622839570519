import {motion} from 'framer-motion';
import React from 'react';
import styled, {css} from 'styled-components';

import {Device} from '../../../constants';

const BattlePassSubTitle = styled(({...rest}) => <motion.h2 {...rest} />)<{
  color?: string;
  italics?: boolean;
  large?: boolean;
}>`
  position: relative;
  width: 90%;
  max-width: 900px;
  z-index: 1;
  color: ${(props) => (props.color ? props.color : '#fff')};
  text-align: center;
  && {
    font-size: ${(props) => (props.large ? '4em !important' : '1.25em')};
    line-height: ${(props) => (props.theme.locale === 'ru' ? 1.1 : 1)};
    margin: 0.5em auto 0 auto;
  }
  ${(props) =>
    props.italics
      ? css`
          font-style: italic;
        `
      : null}

  @media screen and (max-width: 1140px) {
    padding: 0 0.5em;
    margin: 0.5em auto;
  }
  @media screen and ${Device.tablet} {
    font-size: ${(props) => (props.large ? '6vw !important' : '3vw !important')};
  }
  @media screen and ${Device.mobileL} {
    font-size: ${(props) => (props.large ? '7vw !important' : '1em !important')};
    padding: 0;
  }
`;

export default BattlePassSubTitle;
