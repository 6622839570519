import {connectToStores} from 'epic-alt-utils';
import Error404 from 'epic-fortnite-react/components/error/Error404';
import HttpErrorScreen from 'epic-fortnite-react/components/error/HttpErrorScreen';
import PropTypes from 'prop-types';
import React from 'react';

import BattlePassActions from '../../actions/BattlePassActions';
import BattlePassSection from '../../components/battlePass/BattlePassSection';
import MetaTags from '../../components/common/MetaTags';
import LoadScreen from '../../components/loadScreen/LoadScreen';
import {BattlePassStore, HeaderStore, LoginStore} from '../../stores';

import TakeOverView from './TakeOverView';

const fetchData = () => {
  BattlePassActions.getPage.defer();
};

@connectToStores
class BattlePassView extends React.Component {
  static propTypes = {
    battlePassStore: PropTypes.object,
    loginStore: PropTypes.object,
    tablet: PropTypes.bool,
    mobile: PropTypes.bool,
  };

  static getStores() {
    return [BattlePassStore, HeaderStore, LoginStore];
  }

  static getInitialActions() {
    return [fetchData];
  }

  static getPropsFromStores() {
    return {
      battlePassStore: BattlePassStore.getState(),
      loginStore: LoginStore.getState(),
      mobile: HeaderStore.getState().mobile(),
      tablet: HeaderStore.getState().tablet(),
    };
  }

  render() {
    const {battlePassStore, loginStore} = this.props;

    const {loading, data, error} = battlePassStore;

    if (loading) {
      return <LoadScreen show={true} />;
    }

    if (error?.statusCode) {
      return <HttpErrorScreen statusCode={error?.statusCode} />;
    }

    const {showTakeOverPage, enabled, sections: stateSections, callout, slug} = data;

    if (showTakeOverPage) {
      return <TakeOverView BattlePassStore={battlePassStore} />;
    }

    const sections = [];

    if (!enabled) {
      return <Error404 />;
    }

    for (let i = 0; i < stateSections.length; i++) {
      const section = stateSections[i];

      sections.push(
        <BattlePassSection
          key={section._slug}
          mobile={this.props.mobile}
          tablet={this.props.tablet}
          section={section}
          callout={callout}
          season={slug}
          isLoggedIn={loginStore.isLoggedIn}
        />
      );
    }

    return (
      <div className={`battle-pass-view ${slug}`}>
        <MetaTags pageModel={data} />
        {sections}
      </div>
    );
  }
}

export default BattlePassView;
