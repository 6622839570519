import styled from 'styled-components';

import {Device} from '../../constants';

import StyledSubscriptionCoreTextWrapper from './StyledSubscriptionCoreTextWrapper';
import StyledSubscriptionHeading from './StyledSubscriptionHeading';

const StyledSubscriptionBattlePassTextWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(0, -50%);
  width: 40%;
  padding: 7em;
  z-index: 1;

  @media screen and ${Device.laptopL} {
    width: 50%;
    padding: 0;
    ${StyledSubscriptionCoreTextWrapper} {
      margin: 0 auto;
      margin-right: 0;
      ${StyledSubscriptionHeading} {
        font-size: 3em;
      }
    }
  }

  @media screen and ${Device.laptop} {
    width: 60%;
  }

  @media screen and ${Device.tablet} {
    width: 50%;
    ${StyledSubscriptionCoreTextWrapper} {
      margin: 0 auto;
      padding-left: 3em;
      padding-right: 0;
      ${StyledSubscriptionHeading} {
        font-size: 2.5em;
      }
    }
  }

  @media screen and ${Device.mobileL} {
    width: 100%;
    position: unset;
    padding: 0;
    transform: none;
    ${StyledSubscriptionCoreTextWrapper} {
      padding: 1em;
      margin-top: -80px;
    }
  }
`;

export default StyledSubscriptionBattlePassTextWrapper;
