import {getLocale} from '@epic-core/common';
import React from 'react';

import MetaTags from '../../components/common/MetaTags';
import {HelpCenterHero, HelpCenterSection} from '../../components/helpCenter';
import LoadScreen from '../../components/loadScreen/LoadScreen';
import {getInitialCmsPageSharedValues, PageData, useCmsData} from 'epic-fortnite-shared-values';
import {generateRouteTo, HttpErrorScreen} from 'epic-fortnite-react';
import {Section} from '../../components/helpCenter/HelpCenterSection';

const SLUG = 'help-center';

interface HelpCenterPageData extends PageData {
  mainTitle: any;
  heroImageDesktop: string;
  heroImageMobile: string;
  backgroundImage: string;
  meta: any;
  sections: {
    sections: Section[];
  };
}

const HelpCenterView = (props) => {
  const {pageData, isLoading, error} = useCmsData<HelpCenterPageData>(SLUG);
  const {history, location} = props;

  const locale = getLocale();

  if (isLoading) {
    return <LoadScreen show />;
  }

  if (error) {
    return <HttpErrorScreen statusCode={error.response?.status ?? 500} />;
  }

  if (!pageData) {
    return <HttpErrorScreen statusCode={404} />;
  }

  const {mainTitle, heroImageDesktop, heroImageMobile, backgroundImage, meta} = pageData;
  const {sections} = pageData.sections ?? {};

  const routeToPath = (route) => history.push(generateRouteTo(route, location, locale));

  return (
    <div className="help-center-wrapper" style={{backgroundImage: `url(${backgroundImage})`}}>
      <MetaTags meta={meta} />
      <div className="container-fluid help-center-container">
        <div className="col-md-12 col-lg-6 content-left help-center-column">
          <h4 className="main-title">{mainTitle}</h4>
          {sections
            ? sections.map((section: Section, index: number) => (
                <HelpCenterSection
                  key={`help-center-section-${index}`}
                  section={section}
                  routeHandler={routeToPath}
                  index={index}
                />
              ))
            : null}
        </div>
        <div className="col-md-6 content-right help-center-column">
          <HelpCenterHero heroImage={heroImageDesktop} />
        </div>
      </div>
      <div className="hero-container-mobile">
        <img className="hero-image-mobile" src={heroImageMobile} alt="" />
      </div>
    </div>
  );
};

HelpCenterView.getInitialSharedValues = getInitialCmsPageSharedValues(SLUG);

export default HelpCenterView;
