import {SlickWrapper} from 'epic-react-common';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import VisibilitySensor from 'react-visibility-sensor';

import AnimateString from '../common/AnimateString';

const LoadingScreens = ({data}) => {
  const [visible, setVisibility] = useState(false);
  const {title, paragraph, images} = data;
  const sliderOptions = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    dotsClass: 'loadScreens-paginations',
    arrows: false,
    draggable: false,
    swipeToSlide: true,
    autoplay: false,
    autoplaySpeed: 3000,
    centerMode: true,
    swipe: true,
    pauseOnHover: false,
    touchMove: false,
  };
  const slides = images.map((img, index) => {
    return <div style={{backgroundImage: `url(${img})`}} className="loadScreenSlide" key={index} />;
  });
  return (
    <VisibilitySensor
      onChange={(visibility) => setVisibility(visibility)}
      active={!visible}
      partialVisibility
      minTopValue={50}>
      <div className={`battlepass-loadingScreens ${visible ? 'visible' : ''}`}>
        <div className="loadingScreens-content">
          <AnimateString
            key="loadingscreen-h1"
            text={title}
            type="h1"
            speed={1.5}
            delay={0.3}
            autoAlpha={0}
            yPos={100}
            minTop={20}
            scaleFrom={1}
            staggerDelay={0}
          />
          {paragraph ? (
            <AnimateString
              key={`loadingscreen-p`}
              text={paragraph}
              type="p"
              speed={1.5}
              delay={0.3}
              autoAlpha={0}
              yPos={100}
              minTop={20}
              scaleFrom={1}
              staggerDelay={0}
            />
          ) : null}
        </div>
        <SlickWrapper className="loadScreens-slider" options={sliderOptions}>
          {slides}
        </SlickWrapper>
      </div>
    </VisibilitySensor>
  );
};

LoadingScreens.propTypes = {
  data: PropTypes.object,
};
export default LoadingScreens;
