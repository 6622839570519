import {useMotionValue, useSpring, useTransform} from 'framer-motion';
import React from 'react';
import root from 'window-or-global';

import GradientBackground from './GradientBackground';
import HeroName from './HeroName';
import {HeroSlide} from './Types';

interface Props {
  items: HeroSlide[];
  activeSlide: number;
  isVisible: boolean;
  mobile: boolean;
  tablet: boolean;
}

const HeroBackground = ({items, activeSlide, isVisible, mobile, tablet}: Props) => {
  const xPos = useMotionValue(root ? root.innerWidth / 2 : 1000);
  const x = useTransform(xPos, [0, root ? root.innerWidth : 2000], [-30, 30], {clamp: true});
  const xSpring = useSpring(x, {damping: 120});

  const handleMouse = (e) => {
    const {clientX, pageX} = e;
    if (!pageX && clientX) xPos.set(clientX); //x position within the element.
    if (pageX) xPos.set(pageX);
  };

  React.useLayoutEffect(() => {
    root.addEventListener('mousemove', handleMouse, true);
    return () => {
      root.removeEventListener('mousemove', handleMouse, true);
    };
  }, [root]);

  return (
    <>
      {items.map((item, index) => {
        const active = activeSlide === index && isVisible;
        const lines = item.name.split(' ');
        const name = lines.map((n, index) => {
          if (index + 1 < lines.length) {
            return (
              <React.Fragment key={`h1${item.key}-${index}`}>
                {n}
                <br />
              </React.Fragment>
            );
          }
          return n;
        });
        return (
          <GradientBackground
            active={active}
            key={`background-${item.key}`}
            bkImage={item.slideBackground.desktopImage}>
            <HeroName
              className={`heroName-${item.key}`}
              initial={{rotateY: 90, opacity: 0}}
              animate={active ? {opacity: 1, rotateY: 0} : {rotateY: -180, opacity: 0}}
              style={{x: active && !mobile && !tablet ? xSpring : 0}}
              transition={{
                duration: 0.5,
                delay: active ? 0.6 : 0,
                ease: 'easeInOut',
              }}
              keyId={item.key}>
              {name}
            </HeroName>
          </GradientBackground>
        );
      })}
    </>
  );
};

export default HeroBackground;
