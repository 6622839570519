import PropTypes from 'prop-types';
import React from 'react';

const VideoPlayButton = ({className, onClick}) => {
  return (
    <span className={`videoPlayButton ${className}`} onClick={onClick} role="presentation">
      <span />
    </span>
  );
};
VideoPlayButton.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
};
export default VideoPlayButton;
