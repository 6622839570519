import {connectToStores} from 'epic-alt-utils';
import StoreActions from 'epic-fortnite-react/actions/StoreActions';
import AnimateObject from 'epic-fortnite-react/components/common/AnimateObject';
import React, {useState} from 'react';
import {Link} from 'react-scroll';
import VisibilitySensor from 'react-visibility-sensor';

import {StoreStore} from '../../stores';
import {triggerAnalytic} from '../../utils/SubscriptionUtils';

import StyledArticleLink from './StyledArticleLink';
import StyledSubscriptionContentContainer from './StyledSubscriptionContentContainer';
import StyledSubscriptionContentLeft from './StyledSubscriptionContentLeft';
import StyledSubscriptionContentRight from './StyledSubscriptionContentRight';
import StyledSubscriptionCTAButton from './StyledSubscriptionCTAButton';
import StyledSubscriptionCTAContainer from './StyledSubscriptionCTAContainer';
import StyledSubscriptionHeader from './StyledSubscriptionHeader';
interface Props {
  content: any;
  mobile: boolean;
  tablet: boolean;
  storeStore: any;
}

const SubscriptionHeader = (props: Props) => {
  const {
    content,
    storeStore: {loadingSubscriptionOffer, subscriptionOfferData},
  } = props;
  const {isAnnouncement, articleLink} = content;
  const [isVisible, setVisibility] = useState(false);
  const onChange = (): void => {
    setVisibility(!isVisible);
    if (isVisible) {
      triggerAnalytic('SubscriptionHeader');
    }
  };

  const subTextString = isAnnouncement
    ? content.announcementTextContent
    : content.launchTextContent;

  return (
    <VisibilitySensor onChange={onChange} partialVisibility minTopValue={10}>
      <StyledSubscriptionHeader
        backgroundImage={content.backgroundImage}
        backgroundImageMobile={content.backgroundImageMobile}>
        <StyledSubscriptionContentLeft />
        <StyledSubscriptionContentRight>
          <StyledSubscriptionContentContainer textColor={content.headerTextColor}>
            <AnimateObject
              minTop={20}
              speed={10}
              from={{autoAlpha: 0}}
              to={{autoAlpha: 1, delay: 1.2}}>
              <img className="subscription-logo-image" src={content.logoImage} alt="" />
            </AnimateObject>
            <AnimateObject
              minTop={20}
              speed={2}
              from={{x: `+=100`, autoAlpha: 0}}
              to={{x: 0, autoAlpha: 1, delay: 0.4}}>
              <h2>{content.subHeader}</h2>
            </AnimateObject>
            <AnimateObject
              className="subscription-header-text"
              minTop={20}
              speed={2}
              from={{x: `+=100`, autoAlpha: 0}}
              to={{x: 0, autoAlpha: 1, delay: 0.8}}>
              {subscriptionOfferData && !loadingSubscriptionOffer && (
                <p>
                  {subTextString.replace('${PRICE}', subscriptionOfferData.data.offers[0][0].price)}
                </p>
              )}
            </AnimateObject>
            <AnimateObject
              minTop={20}
              speed={10}
              from={{autoAlpha: 0}}
              to={{autoAlpha: 1, delay: 1.2}}>
              <StyledSubscriptionCTAContainer>
                <img
                  className="cta-background cta-background-left"
                  src={content.ctaBackgroundLeft}
                  alt=""
                />
                <Link to="faq-section" smooth={true} offset={0} duration={500}>
                  <StyledSubscriptionCTAButton className="btn btn-primary btn-hover-transform">
                    {isAnnouncement
                      ? content.announcementCTA.ctaString
                      : content.launchCTA.ctaString}
                  </StyledSubscriptionCTAButton>
                </Link>
                <img
                  className="cta-background cta-background-right"
                  src={content.ctaBackgroundRight}
                  alt=""
                />
              </StyledSubscriptionCTAContainer>
            </AnimateObject>

            <AnimateObject
              className="subscription-header-article-link"
              minTop={20}
              speed={2}
              from={{x: `+=100`, autoAlpha: 0}}
              to={{x: 0, autoAlpha: 1, delay: 0.8}}>
              {articleLink && !articleLink?.linkDisabled && (
                <StyledArticleLink
                  textColor={articleLink?.linkColor}
                  textColorHover={articleLink?.linkHoverColor}
                  target={articleLink?.linkExternal ? '_blank' : '_self'}
                  href={articleLink?.linkHref}>
                  {articleLink?.linkString}
                </StyledArticleLink>
              )}
            </AnimateObject>
          </StyledSubscriptionContentContainer>
        </StyledSubscriptionContentRight>
      </StyledSubscriptionHeader>
    </VisibilitySensor>
  );
};

SubscriptionHeader.getStores = () => {
  return [StoreStore];
};

SubscriptionHeader.getInitialActions = () => {
  return [StoreActions.getSubscriptionData];
};

SubscriptionHeader.getPropsFromStores = () => {
  return {storeStore: StoreStore.getState()};
};

export default connectToStores(SubscriptionHeader);
