import {withLocalization} from '@epic-core/localization';
import {Stack, Text} from 'epic-fortnite-react';
import HtmlTemplate from 'epic-fortnite-react/components/common/HtmlTemplate';
import React from 'react';
import styled from 'styled-components';

import CTAButton from '../../../components/common/CTAButton';
import BackgroundImage from '../s6/BackgroundImage';
interface CtaFooterData {
  title: string;
  subtitle: string;
  subtitle2: string;
  leftImg: string;
  ctaConfig: {
    isCtaLinkExternal?: boolean;
    ctaString: string;
    ctaLink: string;
    disabled?: boolean;
    theme?: string;
  };
  bgImg: string;
  mobileBgImg: string;
  backgroundFooter: string;
}

interface Props {
  className?: string;
  mobile: boolean;
  data: CtaFooterData;
  getMessage: (code: string) => string;
}

const StyledCtaFooter = styled.div`
  background-image: linear-gradient(0deg, #ba0e18 38%, rgb(255, 255, 255) 80%);
  position: relative;

  img {
    width: 100%;
  }
`;

const StyledFooterContent = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: -58% auto 0 auto;
  padding-bottom: 48px;
  width: calc(100% - 4rem);

  @media (min-width: 900px) {
    margin-top: -30%;
  }

  .btn {
    margin-top: 0 !important;
  }
`;

// const FortniteLogo = styled.div`
//   color: #540367;

//   svg {
//     fill: currentColor;
//     width: 25vw !important;

//     @media (min-width: 900px) {
//       width: 12vw !important;
//     }
//   }
// `;

const StyledImg = styled.img`
  height: auto;
  width: 75% !important;

  @media (min-width: 900px) {
    height: auto;
    width: 26vw !important;
  }
`;

const StyledLegal = styled.div`
  color: #fff;
  display: block;
  font-size: 14px;
  line-height: 1.2;
  margin: auto;
  padding-bottom: 12px;
  text-align: center;
  p {
    margin: 0;
  }
`;

const CtaFooter = ({mobile, data, className, getMessage}: Props) => {
  const {title, subtitle, subtitle2, leftImg, ctaConfig, bgImg, mobileBgImg, backgroundFooter} =
    data;
  const ctaString = ctaConfig.ctaString || getMessage('epic.common.learnMore');
  const button = React.useMemo(
    () => ({
      ...ctaConfig,
      ctaString,
    }),
    [ctaConfig]
  );
  return (
    <StyledCtaFooter className={className}>
      <BackgroundImage
        alt={title}
        breakPoint={899}
        desktopImage={bgImg}
        mobile={mobile}
        mobileImage={mobileBgImg}
      />
      <StyledFooterContent>
        <Stack align="center" gap="med">
          {/* <FortniteLogo>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 625 159">
              <path d="m385.719 7.491 4.788 62.156-24.53-60.728-27.606 5.579v123.996h31.602L368.041 85.9l23.826 57.378 26.94-2.373-.966-133.414h-32.122Zm156.788 143.33 59.381 4.283v-35.338H576.97V93.159h21.092V62.548h-21.644V41.43l25.47-.965V2.226l-59.381 4.862V150.82ZM91.422 34.428 94.639 0H30v159l34.96-6.51V93.887h22.335l.642-33.471H64.954V34.428h26.468Zm43.512-24.817c-34.093 0-42.177 30.034-42.177 67.083 0 37.048 8.748 67.08 42.177 67.08s42.176-30.036 42.176-67.08c0-37.045-8.078-67.083-42.176-67.083Zm-.303 107.416c-4.562 0-5.355-17.915-5.355-40.015s.644-40.007 5.355-40.007c4.71 0 5.341 17.907 5.341 40.007 0 22.1-.797 40.015-5.341 40.015Zm127.056-61.199c0-24.637-16.505-45.914-37.78-45.914h-42.138v131.001h33.064l1.402-41.123 13.403 45.905 34.479-2.874-17.228-52.589s14.798-9.78 14.798-34.406Zm-46.394 20.067V39.556s10.422-1.105 10.422 17.556c0 18.66-10.422 18.783-10.422 18.783Zm47.364-32.338h18.247v96.557h34.48V49.286h19.026v-33.47l-71.753-5.734v33.475Zm202.853 10.17h18.711v95.003h34.472V49.935h19.594v-33.47l-72.769 3.794-.008 33.469Zm-39.609 90.971h35.45V13.697h-35.45v131.001Zm190.228-6.206c-4.943 0-8.872 3.924-8.872 9.123s3.929 9.111 8.872 9.111c4.942 0 8.871-3.916 8.871-9.113 0-5.197-3.931-9.123-8.871-9.123v.002Zm0 16.707c-4.039 0-7.226-3.289-7.226-7.584s3.187-7.586 7.226-7.586c4.038 0 7.223 3.289 7.223 7.586 0 4.297-3.185 7.582-7.223 7.582v.002Zm3.877-9.441c0-1.804-1.222-2.866-3.294-2.866h-4.197v9.39h1.587v-3.397h1.911l2.498 3.395h1.487v-.637l-2.231-2.966c1.335-.322 2.239-1.329 2.239-2.921v.002Zm-3.294 1.697h-2.603v-3.024h2.603c1.009 0 1.753.477 1.753 1.485 0 1.009-.744 1.537-1.753 1.537v.002Z" />
            </svg>
          </FortniteLogo> */}
          <Stack align="center" gap="2xs">
            {subtitle2 && (
              <Text
                color="#fff"
                font="BurbankBigRegularBlack"
                italic={true}
                size="med"
                transform="uppercase">
                {subtitle2}
              </Text>
            )}
            {leftImg && <StyledImg src={leftImg} alt={title} />}
          </Stack>
          {subtitle && (
            <Text
              color="#fff"
              font="BurbankBigRegularBlack"
              italic={true}
              size="xl"
              transform="uppercase">
              {subtitle}
            </Text>
          )}
          <CTAButton ctaConfig={button} theme="brightYellow" />
        </Stack>
      </StyledFooterContent>
      {backgroundFooter && (
        <StyledLegal>
          <HtmlTemplate html={backgroundFooter} />
        </StyledLegal>
      )}
    </StyledCtaFooter>
  );
};

export default withLocalization(CtaFooter);
