import moment from 'moment';
import React from 'react';

export const icon = {'calendar-plus-o': 'left'};

export const useScheduleEvent = (data, getMessage) => {
  const {date, name, location, description} = data || {};
  const items = React.useMemo(
    () => [
      {google: getMessage('epic.common.calendar.google')},
      {apple: getMessage('epic.common.calendar.apple')},
      {outlook: getMessage('epic.common.calendar.outlook')},
      {yahoo: getMessage('epic.common.calendar.yahoo')},
    ],
    [getMessage]
  );
  const event = React.useMemo(() => {
    const endTime = moment(date).add(1, 'hours');
    return {
      title: name,
      startTime: date,
      endTime,
      description: description || '',
      location: location || getMessage('epic.common.calendar.location'),
    };
  }, [date, name]);
  return {event, items};
};
