import {getLocale} from '@epic-core/common';
import HttpErrorScreen from 'epic-fortnite-react/components/error/HttpErrorScreen';
import {AssetSizeType, getAssetsPathAtSize} from 'epic-fortnite-react/utils/FortniteUtils';
import PropTypes from 'prop-types';
import React from 'react';
import Helmet from 'react-helmet';

import LoadScreen from '../../components/loadScreen/LoadScreen';

const TakeOver = ({battlePassStore}) => {
  const {loading, data, error} = battlePassStore;

  if (loading) {
    return <LoadScreen show={true} />;
  }

  if (error?.statusCode) {
    return <HttpErrorScreen statusCode={error?.statusCode} />;
  }

  const {metaTitle, metaDescription, metaImage, takeOverPage, slug} = data;

  const tags = [
    {itemProp: 'name', content: metaTitle},
    {itemProp: 'description', content: metaDescription},
    {itemProp: 'image', content: metaImage},
    {name: 'description', content: metaDescription},
    {name: 'twitter:title', content: metaTitle},
    {name: 'twitter:description', content: metaDescription},
    {name: 'twitter:image', content: metaImage},
    {property: 'og:title', content: metaTitle},
    {property: 'og:image', content: metaImage},
    {property: 'og:description', content: metaDescription},
  ];

  const desktopImage = takeOverPage?.takeOver?.desktopImage;
  const mobileImage = takeOverPage?.takeOver?.mobileImage;
  const mobileResized = getAssetsPathAtSize(mobileImage, AssetSizeType.MOBILE);
  const desktopResized = getAssetsPathAtSize(desktopImage, AssetSizeType.MAX_WIDTH_1440);
  const date = new Date(Date.parse('20 Feb 2020 00:10:00 EST')).toLocaleDateString(getLocale(), {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
    timeZone: 'America/New_York',
  });
  const localeDate = date.replace(/\//g, '.');
  if (!takeOverPage) {
    return null;
  }

  return (
    <>
      <Helmet meta={tags} title={metaTitle} />
      <div className={`TakeOverView mobile ${slug}`}>
        <div>
          <img src={mobileResized} alt={takeOverPage.imageName} />
          <span className="text">{localeDate}</span>
        </div>
      </div>
      <div className={`TakeOverView desktop ${slug}`}>
        <div>
          <img src={desktopResized} alt={takeOverPage.imageName} />
          <span className="text">{localeDate}</span>
        </div>
      </div>
    </>
  );
};

TakeOver.propTypes = {
  battlePassStore: PropTypes.object.isRequired,
};

export default TakeOver;
