import {getBowserState} from '@epic-core/common';
import {Stack, Text, Inline, Grid, GridColumn} from 'epic-fortnite-react';
import React, {useState} from 'react';
import styled from 'styled-components';

import {trackEvent} from '../../utils/eventTracking';

import {RiftTourCTA} from './../riftTour/components/RiftTourCTA';
import {RiftTourHeading} from './../riftTour/components/RiftTourHeading';
import {RiftTourSubheading} from './../riftTour/components/RiftTourSubheading';
import {RiftTourCtaInterface} from './../riftTour/types';
import {formattedDateTime} from './../riftTour/utils';
import SocialShareModal from './SocialShareModal';

type ShareLogoMaxHeight = string;
type ShareLogoMaxWidth = string;
type ShareLogoPosition = 'bottom' | 'top';

interface Props {
  details: ScheduleItem;
}

interface ShareLogoProps {
  $shareLogoPosition?: ShareLogoPosition;
}

interface ShareLogoImgProps {
  $shareLogoMaxHeight?: ShareLogoMaxHeight;
  $shareLogoMaxWidth?: ShareLogoMaxWidth;
}

interface WidgetWrapperProps {
  backgroundImageMobile: string;
  backgroundImageDesktop: string;
}

interface ScheduleItem {
  backgroundImageMobile: string;
  backgroundImageDesktop: string;
  commonLogo: string;
  cta: RiftTourCtaInterface;
  dateColor?: string;
  scheduleLogoOverride?: string;
  scheduleTimestamp: string;
  shareHeading: string;
  shareImage: string;
  shareLogoMaxHeight?: ShareLogoMaxHeight;
  shareLogoMaxWidth?: ShareLogoMaxWidth;
  shareLogoPosition?: ShareLogoPosition;
  shareLink: string;
  shareText: string;
  shareVideo: string;
}

const ShareLogo = styled.div<ShareLogoProps>`
  align-items: center;
  bottom: 0;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  padding: 48px;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;

  ${({$shareLogoPosition}) =>
    $shareLogoPosition === 'bottom'
      ? 'align-items: bottom'
      : $shareLogoPosition === 'top'
      ? 'align-items: top'
      : 'align-items: center'}
`;

const ShareLogoImg = styled.img<ShareLogoImgProps>`
  height: auto;
  width: auto;
  max-height: ${({$shareLogoMaxHeight}) =>
    $shareLogoMaxHeight ? `${$shareLogoMaxHeight}px` : 'none'};
  max-width: ${({$shareLogoMaxWidth}) => ($shareLogoMaxWidth ? `${$shareLogoMaxWidth}px` : 'auto')};
`;

const WidgetWrapper = styled.div<WidgetWrapperProps>`
  background-image: url(${({backgroundImageDesktop}) => backgroundImageDesktop});
  background-size: cover;
  padding: 6rem 4rem;
  @media (max-width: 425px) {
    background-image: url(${({backgroundImageMobile}) => backgroundImageMobile});
    max-width: 25.33rem;
    min-height: 36.5rem;
    padding: 8.6rem 2.5rem;
    margin: auto;
  }
`;

export const SocialShareWidget = ({details}: Props) => {
  const {mobile} = getBowserState();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const {
    backgroundImageMobile,
    backgroundImageDesktop,
    commonLogo,
    cta,
    dateColor = '#fff',
    scheduleLogoOverride,
    scheduleTimestamp,
    shareHeading,
    shareImage,
    shareLogoMaxHeight,
    shareLogoMaxWidth,
    shareLogoPosition,
    shareLink,
    shareText,
    shareVideo,
  } = details;

  const {date, time} = formattedDateTime(scheduleTimestamp);

  const handleClick = () => {
    trackEvent(
      {
        eventCategory: 'fn-web',
        eventAction: 'user.click.body.shareWidgetCTA',
        eventLabel: shareLink,
        eventValue: 1,
        interactionType: 'click',
      },
      'identifiedOnly'
    );
    if (mobile && navigator.share) {
      navigator
        .share({
          text: `${shareText ? shareText.replace('${date}', `${date} ${time}`) : ''}`,
          url: shareLink,
        })
        .then(() => {
          trackEvent(
            {
              eventCategory: 'fn-web',
              eventAction: 'user.click.navigator.success',
              eventLabel: shareLink,
              eventValue: 1,
              interactionType: 'click',
            },
            'identifiedOnly'
          );
          console.log('Thanks for sharing!');
        })
        .catch(() => {
          trackEvent(
            {
              eventCategory: 'fn-web',
              eventAction: 'user.click.navigator.dismiss',
              eventLabel: shareLink,
              eventValue: 1,
              interactionType: 'click',
            },
            'identifiedOnly'
          );
          console.error();
        });
    } else {
      setIsModalOpen(true);
    }
  };

  return (
    <WidgetWrapper
      backgroundImageMobile={backgroundImageMobile}
      backgroundImageDesktop={backgroundImageDesktop}>
      <Stack gap="xl">
        <Grid breakpoint={426} gapX="xl" gapY="lg">
          <GridColumn span={1 / 2}>
            <img
              src={scheduleLogoOverride ? scheduleLogoOverride : commonLogo}
              alt=""
              style={{maxWidth: '100%'}}
            />
          </GridColumn>
          <GridColumn span={1 / 2}>
            <Stack gap="sm">
              <Inline gapX="xs" gapY="3xs" justify="center">
                <Text truncate={0.9} color={dateColor} size="lg" transform="uppercase">
                  {date}
                </Text>
                <Text truncate={0.9} color={dateColor} size="lg" transform="uppercase">
                  {time}
                </Text>
              </Inline>
              <RiftTourCTA label={cta.label} onClick={handleClick} theme={cta.theme} />
            </Stack>
          </GridColumn>
        </Grid>
      </Stack>
      <SocialShareModal
        ariaLabel={shareHeading}
        backgroundImage={shareImage ?? null}
        logo={scheduleLogoOverride ? scheduleLogoOverride : commonLogo}
        shareLink={shareLink}
        shareText={shareText}
        eventTime={`${date} ${time}`}
        isOpen={isModalOpen}
        modalOverlayContent={
          !shareImage && (
            <ShareLogo $shareLogoPosition={shareLogoPosition}>
              <ShareLogoImg
                src={scheduleLogoOverride ? scheduleLogoOverride : commonLogo}
                alt=""
                $shareLogoMaxHeight={shareLogoMaxHeight}
                $shareLogoMaxWidth={shareLogoMaxWidth}
              />
            </ShareLogo>
          )
        }
        onDismiss={() => setIsModalOpen(false)}
        video={shareVideo ?? null}>
        <Stack justify="center" gap="med">
          <RiftTourHeading color="#fff">{shareHeading}</RiftTourHeading>
          <RiftTourSubheading color="#fff">{`${date} ${time}`}</RiftTourSubheading>
        </Stack>
      </SocialShareModal>
    </WidgetWrapper>
  );
};
