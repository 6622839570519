import axios from 'axios';
import root from 'window-or-global';

import EulaActions from '../actions/UefnEulaActions';
import Url from '../constants/Url';

/**
 * EulaSource
 *
 * Source mapping for getting eula data.
 *
 * For more information on source usage:
 * http://alt.js.org/docs/async/
 *
 */
const EulaSource = {
  getLatest: {
    remote(state) {
      //the store state is accessible here
      return axios.get(Url().EULA.LATEST, {
        params: {
          eulaId: state.currentEula,
          lang: state.currentLocale,
        },
      });
    },
    //On success call the following action:
    success: EulaActions.fetchEulaSuccess,
    //On failure call the following action:
    error: EulaActions.fetchEulaFailure,
    shouldFetch() {
      return !root.__server_side_render;
    },
  },
};

export default EulaSource;
