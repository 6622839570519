import {getPath} from '@epic-core/common';
import SearchView from 'epic-fortnite-react/views/search/SearchView';
import React from 'react'; //eslint-disable-line no-unused-vars
import {Redirect, Route, Switch} from 'react-router';

import {RewriteLocaleToQuery} from './RewriteLocaleToQueryParam';
import BattlePassView from '../views/battlePass/BattlePassView';
import ArticleWrapper from '../views/blog/ArticleWrapper';
import BlogWrapper from '../views/blog/BlogWrapper';
import BundleDetailsView from '../views/bundles/BundleDetailsView';
import CloudStreamingSeasonUAView from '../views/cloudStreamingSeasonUA/CloudStreamingSeasonUAView';
import CosplayArchiveView from '../views/cosplay/CosplayArchiveView';
import CosplayMaskView from '../views/cosplay/CosplayMaskView';
import CosplayView from '../views/cosplay/CosplayView';
import CreativeView from '../views/creativeMode/CreativeView';
import DarkfireView from '../views/darkfire/DarkfireView';
import DownloadView from '../views/download/DownloadView';
import EulaView from '../views/eula/EulaView';
import UefnEulaView from '../views/eula/UefnEulaView';
import FaqView from '../views/faq/FaqView';
import FnMobileView from '../views/fortniteMobile/FnMobileView';
import MobileLanding from '../views/mobile/MobileLanding';
import MobilePlatformPage from '../views/mobile/MobilePlatformPage';
import HelpCenterView from '../views/helpCenter/HelpCenterView';
import LandingPageView from '../views/landingpages/LandingPageView';
import NoBuildPage from '../views/noBuild/NoBuildPage';
import PatchNotesView from '../views/patchNotes/PatchNotesView';
import PosaJAView from '../views/posaJA/PosaJAView';
import PaymentsActView from '../views/paymentsact/PaymentsActView';
import PlayGuideView from '../views/playGuide/PlayGuideView';
import PosaRedeemWrapperView from '../views/redeem/PosaRedeemWrapperView';
import RedeemSuccessWrapperView from '../views/redeem/RedeemSuccessWrapperView';
import RedeemWrapperView from '../views/redeem/RedeemWrapperView';
import VbucksCardTermsView from '../views/redeem/VbucksCardTermsView';
import RetailBundleView from '../views/retailBundle/RetailBundleView';
import RiftTourView from '../views/riftTour/RiftTourView';
import SecureAccountView from '../views/safetySecurity/SecureAccountView';
import StwView from '../views/saveTheWorld/StwView';
import DefaultView from '../views/simple/DefaultView';
import SimpleView from '../views/simple/SimpleView';
import SubscriptionView from '../views/subscription/SubscriptionView';
import TermsView from '../views/terms/TermsView';
import RebootRallyView from '../views/rebootRally/RebootRallyView';
import GenericLegalView from '../views/genericLegal/GenericLegalView';
import EventDetailsView from '../views/events/EventDetailsView';

import RedirectWithStatus from './RedirectWithStatus';

const redirectLocale = (destination, route) => {
  let destPath = null;
  if (typeof destination === 'function') {
    destPath = destination(route.match.params);
  } else {
    destPath = destination;
  }
  const location = route.location || {};
  const search = location.search || '';

  const redirectUrl = `${destPath}${search}`;

  return <Redirect to={redirectUrl} />;
};

const goHome = redirectLocale.bind(null, '');
const goDownload = redirectLocale.bind(null, '/download');
const goAndroid = redirectLocale.bind(null, '/mobile/android');
const goMobileDownload = redirectLocale.bind(null, '/mobile/ios');
const goToAstronomical = redirectLocale.bind(null, '/news/astronomical');
const goToCrewTerms = redirectLocale.bind(null, '/news/fortnite-crew-terms');
const goToFortniteCrewSubscription = redirectLocale.bind(null, '/fortnite-crew-subscription');
const goToCreativeSubmissionForm = redirectLocale.bind(null, '/creative/creative-submission-form');
const goToMintyLegends = redirectLocale.bind(null, '/bundles/minty-legends-pack');
const goToBattlePass = redirectLocale.bind(null, '/battle-pass');
const goToXboxCloudGaming = redirectLocale.bind(null, '/mobile/xbox-cloud-gaming');
const goToGeforceNow = redirectLocale.bind(null, '/mobile/geforce-now');

const goToIslandGuidelines = redirectLocale.bind(
  null,
  '/news/fortnite-creative-featured-island-guidelines'
);
const goToNews = redirectLocale.bind(null, '/news');
const goToVBucksCard = redirectLocale.bind(null, '/vbuckscard');

function MovedToRemix() {
  if (process.env.EPIC_ENV === 'prod') {
    console.log("this shouldn't render, page has moved to remix");
    return <h1>404: Not found!</h1>;
  }
  return <h1>We&apos;ve moved to remix!</h1>;
}

const getRoutes = function getRoutes() {
  const path = getPath().slice(0, -1);
  const routes = (
    <Switch>
      <Route
        path={`/:locale(ar|de|es-ES|es-MX|fr|it|ja|ko|pl|pt-BR|ru|tr)/`}
        render={(props) => <RewriteLocaleToQuery basePath={path} {...props} />}
      />
      <Route exact path={`/`} component={MovedToRemix} />
      <Route exact path={`//*`} component={goHome} />
      <Route exact path={`/home`} component={goHome} />
      <Route path={`/savetheworld`} render={goHome} />
      <Route path={`/preview`} render={goHome} />
      <Route path={`/confirmation`} render={goDownload} />
      <Route path={`/dashboard`} render={goDownload} />
      <Route path={`/game`} render={goHome} />
      <Route path={`/contact`} render={goHome} />
      <Route path={`/gallery`} render={goHome} />
      <Route path={`/media`} render={goHome} />
      <Route path={`/chit-chat`} render={goHome} />
      <Route path={`/pre-register`} render={goHome} />
      <RedirectWithStatus status={301} path={`/mobile/create-account`} from="create-account" />
      <RedirectWithStatus status={301} path={`/mobile/android/get-started`} from="get-started" />
      <RedirectWithStatus status={301} path={`/mobile/sign-up`} from="sign-up" />
      <RedirectWithStatus status={301} exact path={`/blog`} from="blog" to="news" />
      <RedirectWithStatus status={301} path={`/blog/:title`} from="blog" to="news" />
      <RedirectWithStatus status={301} path={`/blog/category/:category`} from="blog" to="news" />
      <RedirectWithStatus
        status={301}
        path={`/blog/:articleLocale/category/:category`}
        from="blog"
        to="news"
      />
      <Route exact path={`/gamescom2017`} render={goHome} />
      <Route exact path={`/ios`} render={goMobileDownload} />
      <Route path={`/android`} render={goAndroid} />
      <Route path={`/astronomical`} render={goToAstronomical} />
      <Route path={`/save-the-world`} component={StwView} />
      <Route path={`/disneyplus`} render={goHome} />
      <Route path={`/disney`} render={goHome} />
      <Route path={`/disney+`} render={goHome} />
      <Route path={`/news/get-two-months-of-disney-plus-on-us`} render={goHome} />
      <Route path={`/news/get-a-month-of-disney-plus-on-us`} render={goHome} />
      <Route exact path={`/disney-plus/redeem`} component={goHome} />
      <Route exact path={`/disney-plus`} component={goHome} />
      <Route exact path={`/spotify/redeem`} component={goHome} />
      <Route exact path={`/spotify`} component={goHome} />
      <Route exact path={`/news`} component={BlogWrapper} />
      <Route
        exact
        path={`/news/competitive-payment-process-update`}
        component={() => <RedirectWithStatus status={301} url="https://epicgames.com/help/" />}
      />
      <Route
        exact
        path={`/news/account-linking-steps`}
        component={() => (
          <RedirectWithStatus
            status={301}
            url="https://www.epicgames.com/help/en-US/epic-accounts-c74/connect-accounts-c110/can-i-merge-my-epic-games-accounts-a4659"
          />
        )}
      />
      <Route
        exact
        path={`/news/account-merge-end-date`}
        component={() => (
          <RedirectWithStatus
            status={301}
            url="https://www.epicgames.com/help/en-US/epic-accounts-c74/connect-accounts-c110/can-i-merge-my-epic-games-accounts-a4659"
          />
        )}
      />
      <Route
        exact
        path={`/news/fncs-invitational-2022-full-details-and-broadcast-schedule`}
        component={() => (
          <RedirectWithStatus
            status={301}
            url="/competitive/news/fncs-invitational-2022-full-details-and-broadcast-schedule"
          />
        )}
      />
      <Route
        exact
        path={`/news/fortnite-battle-royale-chapter-4-season-2-competitive-details`}
        component={() => (
          <RedirectWithStatus
            status={301}
            url="/competitive/news/fortnite-battle-royale-chapter-4-season-2-competitive-details"
          />
        )}
      />
      <Route
        exact
        path={`/news/fortnite-creative-creator-content-rules-and-guidelines`}
        component={() => (
          <RedirectWithStatus status={301} url="/news/fortnite-island-creator-rules" />
        )}
      />
      <Route
        exact
        path={`/news/how-to-gain-access-to-publish-a-creative-island`}
        component={() => (
          <RedirectWithStatus
            status={301}
            url="https://dev.epicgames.com/documentation/en-us/fortnite-creative/publishing-from-the-creator-portal-in-fortnite-creative"
          />
        )}
      />
      <Route exact path={`/news/creative-featured-content`} render={goToIslandGuidelines} />
      <Route exact path={`/news/the-featured-hub`} render={goToIslandGuidelines} />
      <Route
        exact
        path={`/parental-controls`}
        component={() => (
          <RedirectWithStatus status={301} url="https://www.epicgames.com/site/parental-controls" />
        )}
      />
      <Route exact path={`/news/featured-island-guidelines`} render={goToIslandGuidelines} />
      <Route
        exact
        path={`/news/update-on-v-bucks-cards-and-the-merry-mint-pickaxe`}
        render={goToVBucksCard}
      />
      <Route
        exact
        path={`/news/announcing-new-fortnite-creative-hub-2-0-guidelines`}
        render={() => (
          <RedirectWithStatus
            status={301}
            url="/news/fortnite-creative-featured-islands-and-hub-guidelines"
          />
        )}
      />
      <Route
        path={`/news/fortnite-now-available-through-xbox-cloud-gaming-play-via-browser-on-mobile-and-pc-with-xbox-cloud-gaming-for-free`}
        render={goToXboxCloudGaming}
      />
      <Route
        path={`/news/fortnite-geforce-now-faq-play-fortnite-via-the-cloud-on-pc-mobile-and-more`}
        render={goToGeforceNow}
      />
      <Route path={`/news/how-to-install-fortnite-on-android-devices`} render={goAndroid} />
      {/* <Route
        exact
        path={`/mobile/sign-up`}
        render={() => <RedirectWithStatus status={301} url="/mobile" />}
      /> */}
      <Route exact path={`/news/v1-11-patch-notes`} render={goToNews} />
      <Route exact path={`/patch-notes`} render={goToNews} />
      <Route exact path={`/patch-notes/patch-notes-example`} render={goToNews} />
      <Route path={`/patch-notes/:slug`} component={PatchNotesView} />
      <Route
        exact
        path={`/news/freefortnite`}
        render={() => (
          <RedirectWithStatus
            status={301}
            url="https://www.epicgames.com/site/en-US/free-fortnite-faq"
          />
        )}
      />
      <Route
        path={`/animelegends`}
        render={() => (
          <RedirectWithStatus
            status={301}
            url="https://www.epicgames.com/fortnite/en-US/bundles/anime-legends-pack"
          />
        )}
      />
      <Route path={`/news/category/:category`} component={BlogWrapper} />
      <Route path={`/news/:title`} component={ArticleWrapper} />
      <Route exact path={`/buy-now`} render={goHome} />
      <Route exact path={`/play-now`} component={goHome} />
      <Route exact path={`/pre-order`} component={goHome} />
      <Route path={`/pre-order/:slug`} component={goHome} />
      <Route exact path={`/buy-now/:slug`} render={goHome} />
      <Route exact path={`/play-now/:slug`} component={goHome} />
      <Route exact path={`/search-for-survivors`} component={goHome} />
      <Route
        exact
        path={`/watch-fortnite`}
        component={() => <RedirectWithStatus status={301} url="https://watch.fortnite.com/" />}
      />
      <Route path={`/commander`} component={goHome} />
      <Route path={`/search-for-survivors/commanders`} component={goHome} />
      <Route path={`/search-for-survivors/rewards`} component={goHome} />
      <Route exact path={`/redeem`} component={RedeemWrapperView} />
      <Route exact path={`/vbuckscard`} component={PosaRedeemWrapperView} />
      <Route exact path={`/vbuckscardterms`} component={VbucksCardTermsView} />
      <Route exact path={`/vbuckscardterms/:country/:lang`} component={VbucksCardTermsView} />
      <Route
        exact
        path={`/vbuckcardterms`}
        component={redirectLocale.bind(null, '/vbuckscardterms')}
      />
      <Route path={`/?code=:redeemCode:`} component={RedeemWrapperView} />
      <Route path={`/redeem/confirmation`} component={RedeemSuccessWrapperView} />
      <Route path={`/search`} component={SearchView} />
      <Route path={`/download`} component={DownloadView} />
      <Route path={`/eula`} component={EulaView} />
      <Route path={`/unreal-editor-for-fortnite-supplemental-terms`} component={UefnEulaView} />
      <Route path={`/faq`} component={FaqView} />
      <Route path={`/cosplay/archive`} component={CosplayArchiveView} />
      <Route path={`/cosplay/:mask`} component={CosplayMaskView} />
      <Route path={`/cosplay`} component={CosplayView} />
      <Route path={`/terms-policy/:slug`} component={TermsView} />
      <Route exact path={`/battle-pass`} component={BattlePassView} />
      <Route exact path={`/battle-pass/:slug`} component={goToBattlePass} />
      <Route path={`/mobile/android/new-device`} component={FnMobileView} />
      <Route path={`/mobile/android/old-device`} component={FnMobileView} />
      <Route path={`/mobile/android/not-supported`} component={FnMobileView} />
      <Route path={`/mobile/:platform`} component={MobilePlatformPage} />
      <Route path={`/mobile`} component={MobileLanding} />
      <Route path={`/play-guide`} component={PlayGuideView} />
      <Route exact path={`/deepfreeze`} component={RetailBundleView} />
      <Route exact path={`/paymentsact`} component={PaymentsActView} />
      <Route exact path={`/posa-jppsa`} component={PosaJAView} />
      <Route exact path={`/creative`} component={CreativeView} />
      <Route exact path={`/creative/submit-island`} component={goToCreativeSubmissionForm} />
      <Route exact path={`/creative/island-codes/:code`} component={MovedToRemix} />
      <Route exact path={`/help-center`} component={HelpCenterView} />
      <Route
        exact
        path={`/safety-and-security`}
        component={() => (
          <RedirectWithStatus
            status={302}
            url="https://safety.epicgames.com/policies/account-security"
          />
        )}
      />
      <Route exact path={`/chargebacks`} component={GenericLegalView} />
      <Route exact path={`/platform-refunds`} component={GenericLegalView} />
      <Route exact path={`/cancel-purchase`} component={GenericLegalView} />
      <Route exact path={`/engagement-program-payout-terms`} component={GenericLegalView} />
      <Route exact path={`/voice-reporting-faqs`} component={GenericLegalView} />
      <Route exact path={`/secure-your-fortnite-account`} component={SecureAccountView} />
      <Route exact path={`/darkfire`} component={DarkfireView} />
      <Route exact path={`/fortnite-crew-subscription`} component={SubscriptionView} />
      <Route exact path={`/zero-build`} component={NoBuildPage} />
      <Route exact path={`/b/:uaPage`} component={CloudStreamingSeasonUAView} />
      <Route exact path={`/fortnite-crew`} render={goToFortniteCrewSubscription} />
      <Route exact path={`/subscription`} render={goToFortniteCrewSubscription} />
      <Route exact path={`/subscriptions`} render={goToFortniteCrewSubscription} />
      <Route path={`/fortnite-crew-terms`} render={goToCrewTerms} />
      <Route path={`/events/:eventName`} component={EventDetailsView} />
      <Route path={`/events/:eventName`} component={EventDetailsView} />
      <Route path={`/rift-tour`} component={RiftTourView} />
      <Route path={`/mintylegends`} render={goToMintyLegends} />
      <Route path={`/bundles/:bundle`} component={BundleDetailsView} />
      <Route path={`/reboot-rally`} component={RebootRallyView} />
      <Route path={`/:slug/success`} component={LandingPageView} />
      <Route path={`/:parent/:slug`} component={SimpleView} />
      <Route path={`/:slug`} component={DefaultView} />
    </Switch>
  );
  return routes;
};

export default getRoutes;
