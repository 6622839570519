import React, {Ref, useCallback, MouseEvent} from 'react';
import styled from 'styled-components';

import {ClickableElement, VisuallyHidden} from '..';

interface StyledClearButtonProps {
  $hide?: boolean;
}
interface ClearFieldProps {
  hide?: boolean;
  inputRef: Ref<HTMLInputElement>;
  onClear?: () => void;
}

const StyledClearButton = styled(ClickableElement)<StyledClearButtonProps>`
  opacity: ${({$hide}) => $hide && 0};
  padding-left: 12px;
  padding-right: 12px;
  pointer-events: ${({$hide}) => $hide && 'none'};
`;

export const ClearField = ({hide = false, onClear, inputRef}: ClearFieldProps) => {
  const handleClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();

      if (typeof onClear !== 'function' || event.button !== 0) {
        return;
      }

      onClear();

      if (inputRef && typeof inputRef === 'object' && inputRef.current) {
        inputRef.current.focus();
      }
    },
    [onClear, inputRef]
  );

  return (
    <StyledClearButton $hide={hide} onClick={handleClick}>
      <span aria-hidden="true" style={{display: 'block', fontSize: '0'}}>
        {/* TODO: Make icon dynamic / themable */}
        <svg width="32" height="32" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
          <path d="M14.112 26.156L6 25.885a298.603 298.603 0 004.275-9.433A491.403 491.403 0 006.562 6.175l7.55-.24 1.685 6.539c.021.02.031.04.031.06L17.763 5l7.738.211a642.203 642.203 0 00-3.932 10.82c.853 2.169 1.644 4.148 2.372 5.936.748 1.768 1.279 2.994 1.591 3.677L26 26.669 17.857 27a259.93 259.93 0 00-1.904-7.173c-.353 1.085-.686 2.16-.998 3.225l-.655 2.35-.188.754z" />
        </svg>
      </span>
      {/* TODO: LOC */}
      <VisuallyHidden>Clear</VisuallyHidden>
    </StyledClearButton>
  );
};
