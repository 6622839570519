import {
  Animate,
  AnimateIn,
  Container,
  Grid,
  GridColumn,
  Inline,
  Stack,
  Text,
} from 'epic-fortnite-react';
import {useMediaQuery} from 'epic-fortnite-react/hooks/useMediaQuery';
import React, {useState} from 'react';
import {YoutubeVideoEmbed} from 'epic-react-common';
import styled from 'styled-components';
import root from 'window-or-global';
import epicTracking from 'epic-tracking';
import {WatchVideo} from '../common/WatchVideo';
import {CoreSection} from '../blocks/CoreSection';
import VideoOverlay from 'epic-fortnite-react/components/common/VideoOverlay';

import * as Portal from '@radix-ui/react-portal';

import CTAButton from '../../components/common/CTAButton';

import {CoreSectionProps, FunctionalThemeProps} from './types';

export interface CtaProps {
  isCtaLinkExternal?: boolean;
  ctaString: string;
  ctaLink: string;
  disabled?: boolean;
  eventTracking?: {
    interactionType?: string;
    eventAction?: string;
    eventLabel?: string;
    eventValue?: string;
    eventCategory?: string;
  };
  textOnly?: boolean;
  theme?:
    | 'teal'
    | 'green'
    | 'black'
    | 'pink'
    | 'yellow'
    | 'blackYellow'
    | 'brightYellow'
    | undefined;
}

export interface TextSectionProps {
  cta?: CtaProps;
  groupCta?: CtaProps[];
  headerColor?: string;
  legalText?: string;
  legalTextColor?: string;
  legalTextLine2?: string;
  textAlignment?: 'center' | 'left' | 'right';
  textContent?: string;
  textContentColor?: string;
  textHeader?: string;
  logoAsHeader?: string;
  trailerConfig?: {
    text?: string;
    textColor?: string;
    videoId?: string;
    isActive?: boolean;
  };
}

interface ImageProps {
  heroBackgroundImage?: string;
  heroImage?: string;
  heroImageAltText?: string;
  imageAnimates?: boolean;
  overlapHeroTop?: boolean;
  snapHeroToBottom?: boolean;
  snapHeroToTop?: boolean;
}

export interface SideBySideProps extends ImageProps {
  coreSectionProps?: CoreSectionProps;
  layout?:
    | 'imageLeft' // deprecated with fallback
    | 'imageRight' // deprecated with fallback
    | 'mobileTopDesktopLeft'
    | 'mobileTopDesktopRight'
    | 'mobileBottomDesktopLeft'
    | 'mobileBottomDesktopRight';
  textSection?: TextSectionProps;
  videoId?: string;
  theme?: FunctionalThemeProps;
}

const StyledImagePlaceholder = styled.div`
  aspect-ratio: 1/1;
  width: 100%;
`;

interface SideBySideImageContainerProps {
  $overlapHeroTop: string;
  $snapHeroToBottom?: boolean;
  $snapHeroToTop?: boolean;
}

const SideBySideImageContainer = styled.div<SideBySideImageContainerProps>`
  margin-bottom: ${({$snapHeroToBottom}) =>
    `calc(${$snapHeroToBottom ? 'var(--section__padding)' : '0px'} * -1)`};
  margin-top: ${({$snapHeroToTop}) =>
    `calc(${$snapHeroToTop ? 'var(--section__padding)' : '0px'} * -1)`};
  position: relative;
  width: 100%;

  @media (max-width: 899px) {
    max-width: 400px;
  }

  @media (min-width: 900px) {
    margin-top: ${({$snapHeroToTop, $overlapHeroTop}) =>
      `calc((${$overlapHeroTop || $snapHeroToTop ? 'var(--section__padding)' : '0px'} + ${
        $overlapHeroTop ? $overlapHeroTop : '0px'
      }) * -1)`};
  }

  img {
    width: 100%;
  }
`;

const SideBySideBackgroundGlow = styled.div`
  background-color: var(--bundle-theme-color);
  border-radius: 50%;
  filter: blur(50px);
  height: 75%;
  left: 50%;
  opacity: 0.3;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 75%;
  z-index: -1;
`;

const SideBySideBackgroundImage = styled.img`
  left: 0;
  position: absolute;
  top: 0;
  z-index: -1;
`;

const SideBySideImage = styled.img<{imageAnimates?: boolean}>`
  position: relative;
  z-index: 1;
  margin: 1rem auto;
`;

interface LegalProps {
  color: string;
}

const LegalTextContainer = styled.div<LegalProps>`
  padding-left: var(--container__padding);
  padding-right: var(--container__padding);
  color: ${({color}) => color || '#F8FF01'};
  width: 100%;
  bottom: 12px;
  text-align: center;
  position: absolute;
  z-index: 2;

  p {
    margin: 0;

    @media screen {
      :lang(ar) {
        direction: ltr;

        /* Overwrite direction for legal copyright English text in Arabic locale */
        :not(:lang(en-US)) {
          direction: inherit;
        }
      }
    }
  }
`;

const VideoContainer = styled.div`
  border: 3px solid #fff;

  video {
    display: block;
  }
`;

const trackCtaClick = (item) => {
  if (item.eventTracking && root._epicTracking) {
    const {
      interactionType = '',
      eventAction = '',
      eventLabel = '',
      eventValue = '',
      eventCategory = '',
    } = item.eventTracking;

    epicTracking.trackEvent('Interaction', {
      interactionType,
      eventAction,
      eventLabel,
      eventValue,
      eventCategory,
    });
  }
};

const Image = ({
  heroBackgroundImage,
  heroImage,
  heroImageAltText,
  imageAnimates,
  overlapHeroTop,
  snapHeroToBottom,
  snapHeroToTop,
}: ImageProps) => {
  return (
    <SideBySideImageContainer
      $overlapHeroTop={`${overlapHeroTop}`}
      $snapHeroToBottom={snapHeroToBottom}
      $snapHeroToTop={snapHeroToTop}>
      {heroImage ? (
        <AnimateIn>
          {heroBackgroundImage ? (
            <SideBySideBackgroundImage alt="" src={heroBackgroundImage} />
          ) : (
            <SideBySideBackgroundGlow />
          )}
          {imageAnimates ? (
            <Animate delay={Math.floor(Math.random() * (4 - 1 + 1) + 1)}>
              <SideBySideImage alt={heroImageAltText ? heroImageAltText : ''} src={heroImage} />
            </Animate>
          ) : (
            <SideBySideImage alt={heroImageAltText ? heroImageAltText : ''} src={heroImage} />
          )}
        </AnimateIn>
      ) : (
        <StyledImagePlaceholder />
      )}
    </SideBySideImageContainer>
  );
};

const VideoMP4 = ({heroImage}: ImageProps) => {
  const isMP4 = heroImage?.toLowerCase().endsWith('.mp4');

  if (!isMP4) {
    return null;
  }

  return (
    <VideoContainer>
      <video autoPlay loop muted playsInline width="100%">
        <source src={heroImage} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </VideoContainer>
  );
};

const TextSectionCTA = ({...props}: CtaProps) => {
  return (
    <CTAButton
      ctaConfig={{
        ...props,
        onExternalClickHandler: () => {
          trackCtaClick(props.eventTracking);
        },
      }}
      theme={props.theme ? props.theme : 'brightYellow'}
    />
  );
};

const TextSection = ({
  cta,
  groupCta,
  headerColor,
  textAlignment,
  textContent,
  textContentColor,
  textHeader,
  logoAsHeader,
  trailerConfig,
}: TextSectionProps) => {
  const media900Up = useMediaQuery('(min-width: 900px)');
  const media550Down = useMediaQuery('(max-width: 550px)');

  const align = media900Up ? textAlignment : 'center';

  return (
    <Stack align={align} animateIn={true} gap="lg">
      {textHeader && (
        <Text
          align={align}
          as="h2"
          color={`${headerColor ? headerColor : 'var(--bundle-text-color--body)'}`}
          lineLength="sm"
          size="2xl"
          transform="uppercase">
          {textHeader}
        </Text>
      )}
      {textContent && (
        <Text
          align={align}
          color={`${textContentColor ? textContentColor : 'var(--bundle-text-color--body)'}`}
          lineLength="sm"
          size="lg"
          transform="uppercase">
          {textContent}
        </Text>
      )}
      {logoAsHeader ? (
        <img
          alt="logo header"
          src={logoAsHeader}
          style={{
            width: media900Up ? '25rem' : '18.75rem',
            marginBlockStart: media550Down ? '-100px' : '0',
          }}
        />
      ) : null}
      {cta?.ctaString && !cta?.disabled && <TextSectionCTA {...cta} />}
      {groupCta && (
        <Inline align="center" gap="xs" justify="center">
          {groupCta?.map((cta) => cta?.ctaString && !cta?.disabled && <TextSectionCTA {...cta} />)}
        </Inline>
      )}
      {trailerConfig?.videoId && trailerConfig?.isActive && (
        <WatchVideo
          videoId={trailerConfig.videoId}
          text={trailerConfig.text}
          textColor={trailerConfig.textColor}
        />
      )}
    </Stack>
  );
};

const StyledSideBySide = styled.div`
  position: relative;
`;

const VideoPlayButton = styled.button`
  all: unset;
  cursor: pointer;
  position: relative;

  svg {
    position: absolute;
    inset: 0;
    margin: auto;
    z-index: 2;
  }
`;

const SideBySide = (props: SideBySideProps) => {
  const {
    coreSectionProps,
    heroBackgroundImage,
    heroImage,
    heroImageAltText,
    imageAnimates,
    layout,
    videoId,
    overlapHeroTop,
    snapHeroToBottom,
    snapHeroToTop,
    textSection,
    theme,
  } = props;

  const [showVideo, setShowVideo] = useState(false);

  return (
    <>
      <StyledSideBySide>
        <CoreSection
          coreSectionProps={{
            backgroundColor: theme?.pageBackgroundColor,
            size: coreSectionProps?.size ? coreSectionProps?.size : '3xl',
            ...coreSectionProps,
          }}>
          <Container maxWidth={1200} size="xl">
            <Grid
              breakpoint={900}
              gapX="4xl"
              gapY="2xl"
              reverseOrder={
                layout === 'imageRight' ||
                layout === 'mobileTopDesktopRight' ||
                layout === 'mobileBottomDesktopLeft'
              }>
              {(layout === 'imageLeft' ||
                layout === 'imageRight' ||
                layout === 'mobileTopDesktopLeft' ||
                layout === 'mobileTopDesktopRight') && (
                <GridColumn span={3 / 5}>
                  {heroImage && videoId ? (
                    <VideoPlayButton onClick={() => setShowVideo(true)}>
                      <svg width="48" height="48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle
                          cx="24"
                          cy="24"
                          r="23.5"
                          fill="#000"
                          fillOpacity=".6"
                          stroke="#fff"
                        />
                        <path
                          d="M31.869 22.4a2 2 0 010 3.2L22.2 32.856c-1.319.99-3.201.049-3.201-1.6V16.744c0-1.648 1.882-2.589 3.2-1.6L31.87 22.4z"
                          fill="#fff"
                        />
                      </svg>
                      <Image
                        heroBackgroundImage={heroBackgroundImage}
                        heroImage={heroImage}
                        heroImageAltText={heroImageAltText}
                        overlapHeroTop={overlapHeroTop}
                        snapHeroToBottom={snapHeroToBottom}
                        snapHeroToTop={snapHeroToTop}
                      />
                    </VideoPlayButton>
                  ) : heroImage && !videoId ? (
                    <>
                      <VideoMP4 heroImage={heroImage} />
                      <Image
                        heroBackgroundImage={heroBackgroundImage}
                        heroImage={heroImage}
                        heroImageAltText={heroImageAltText}
                        imageAnimates={imageAnimates}
                        overlapHeroTop={overlapHeroTop}
                        snapHeroToBottom={snapHeroToBottom}
                        snapHeroToTop={snapHeroToTop}
                      />
                    </>
                  ) : videoId ? (
                    <YoutubeVideoEmbed video={videoId} play={false} mute={true} launcher={false} />
                  ) : null}
                </GridColumn>
              )}
              <GridColumn span={2 / 5}>
                {(textSection?.textHeader ||
                  textSection?.textContent ||
                  textSection?.logoAsHeader) && <TextSection {...textSection} />}
              </GridColumn>
              {(layout === 'mobileBottomDesktopLeft' || layout === 'mobileBottomDesktopRight') && (
                <GridColumn span={3 / 5}>
                  <Image
                    heroBackgroundImage={heroBackgroundImage}
                    heroImage={heroImage}
                    heroImageAltText={heroImageAltText}
                    imageAnimates={imageAnimates}
                    overlapHeroTop={overlapHeroTop}
                    snapHeroToBottom={snapHeroToBottom}
                    snapHeroToTop={snapHeroToTop}
                  />
                </GridColumn>
              )}
            </Grid>
          </Container>
        </CoreSection>
        {(textSection?.legalText || textSection?.legalTextLine2) && (
          <LegalTextContainer
            color={`${
              textSection?.legalTextColor
                ? textSection?.legalTextColor
                : 'var(--bundle-text-color--body)'
            }`}>
            {textSection?.legalText && <p>{textSection?.legalText}</p>}
            {textSection?.legalTextLine2 && <p>{textSection?.legalTextLine2}</p>}
          </LegalTextContainer>
        )}
      </StyledSideBySide>
      {showVideo && (
        <Portal.Root>
          <VideoOverlay
            videoID={videoId}
            isLauncher={false}
            play={showVideo}
            close={() => setShowVideo(false)}
            closeButton
            show={showVideo}
          />
        </Portal.Root>
      )}
    </>
  );
};

export default SideBySide;
