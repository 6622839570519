import {TwitchVideoEmbed, YoutubeVideoEmbed} from 'epic-react-common';
import React from 'react';

const MultiPlatformStream = ({platform, stream}) => {
  switch (platform) {
    case 'twitch':
      return (
        <TwitchVideoEmbed
          channel={stream}
          parentOptions={['www.epicgames.com', 'epicgames-gamedev.ol.epicgames.net']}
        />
      );
    case 'youtube':
      return <YoutubeVideoEmbed video={stream} />;
    default:
      return <TwitchVideoEmbed channel={stream} />;
  }
};

export default MultiPlatformStream;
