import {withLocalization} from '@epic-core/localization';
import React from 'react';

import ErrorContainer from './ErrorContainer';
import ErrorText from './ErrorText';

interface Props {
  onRetry: React.MouseEventHandler<HTMLButtonElement>;
  getMessage: (string) => string;
}

const FoundersPackError = ({onRetry, getMessage}: Props) => {
  return (
    <ErrorContainer>
      <ErrorText color="white">{getMessage('errors.com.epicgames.general')}</ErrorText>
      <button className="btn btn-primary" onClick={onRetry}>
        {getMessage('epic.common.retry')}
      </button>
    </ErrorContainer>
  );
};

export default withLocalization(FoundersPackError);
