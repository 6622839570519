import styled from 'styled-components';

import {Device} from '../../../constants';

const StyledLogo = styled.img`
  && {
    display: none;
    width: 2rem;
    height: auto;
    margin-bottom: -5%;
    margin-top: -13%;

    @media screen and ${Device.mobileL} {
      display: inline;
      width: 40%;
      margin-bottom: -0.5em;
      margin-top: 5.7em;
    }
  }
`;

export default StyledLogo;
