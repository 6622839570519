import {datasource} from 'alt-utils/lib/decorators';
import {seamlessImmutable, seamlessBootstrap} from 'epic-alt-utils';
import {alt} from 'epic-react-common';
import Immutable from 'seamless-immutable';

import SearchActions from '../actions/SearchActions';
import SearchSource from '../sources/SearchSource';

@datasource(SearchSource)
@seamlessImmutable
class BingSearchStore {
  constructor() {
    this.on('error', (error) => {
      console.error(error);
    });
    this.on('bootstrap', seamlessBootstrap.bind(this, null));

    this.state = Immutable({
      query: '',
      loading: false,
      count: 10,
      offset: 0,
      filter: '',
      nextOffset: 10,
      prevOffset: 0,
      hasMoreResults: false,
      bingResults: {},
      bingFirstRun: true,
      locale: 'en-US',
    });
    this.bindActions(SearchActions);
  }

  onBingSearch(params) {
    this.setState(
      this.state.merge({
        query: params.query,
        offset: params.offset,
        filter: params.filter,
        loading: true,
        locale: params.locale,
      })
    );
    this.getInstance().bingSearch();
  }

  onBingSearchSuccess(response) {
    const webPages = response.data.webPages || {};
    const bingResults = webPages.value || [];

    this.setState(
      this.state.merge({
        bingResults,
        count: response.data.count,
        offset: response.data.offset,
        nextOffset: response.data.nextOffset,
        prevOffset: response.data.prevOffset,
        hasMoreResults: response.data.hasMoreResults,
        bingFirstRun: false,
        loading: false,
      })
    );
  }

  onBingSearchFailure() {
    this.setState(
      this.state.merge({
        bingResults: {},
        bingFirstRun: false,
        loading: false,
      })
    );
  }
}

export default alt.createStore(BingSearchStore, 'BingSearchStore');
