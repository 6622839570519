import React from 'react';
import {HttpErrorScreen, LoadScreen} from 'epic-fortnite-react';
import {getInitialCmsPageSharedValues, useCmsData, PageData} from 'epic-fortnite-shared-values';
import {withRouter} from 'react-router';

import styled from 'styled-components';
import RebootRallyBaseplate from '../../components/baseplate/RebootRallyBaseplate';
import MetaTags from '../../components/common/MetaTags';

import queryString from 'query-string';
import TwoColumnHero from '../../components/hero/TwoColumnHero'; // Shares CMS data structure with JumboHero
import JumboFeature from '../../components/bundles/JumboFeature';

const SLUG = 'reboot-rally';

interface CmsDataProps extends PageData {
  jumbo: any;
  sections: any;
  isCondensed: boolean;
}

const StyledRebootRallyWrapper = styled.div``;

const RebootRallyView = (props) => {
  const {pageData, isLoading, error: cmsError} = useCmsData<CmsDataProps>(SLUG);
  const {jumbo, sections, isCondensed} = pageData;
  const {shared} = queryString.parse(props.location.search);

  // Sort the sections to move `shareSection` to the bottom if query param `shared=true`
  const sortedSections = (blocks) => {
    const _blocks = blocks;

    if (!shared) return _blocks;

    const sharedModuleIndex = _blocks.findIndex((block) => 'shareSection' in block);

    if (sharedModuleIndex < 0) return _blocks;

    const itemToMove = _blocks.splice(sharedModuleIndex, 1)[0];
    _blocks.push(itemToMove);

    return _blocks;
  };

  if (isLoading) {
    return <LoadScreen show />;
  }

  if (cmsError) {
    return <HttpErrorScreen statusCode={cmsError.response?.status ?? 500} />;
  }

  return (
    <StyledRebootRallyWrapper>
      <MetaTags pageModel={pageData} />
      {isCondensed ? <TwoColumnHero {...jumbo} /> : <JumboFeature {...jumbo} />}
      <RebootRallyBaseplate
        blocks={sortedSections(sections?.blocks)}
        shared={shared}
        condensed={isCondensed}
      />
    </StyledRebootRallyWrapper>
  );
};

RebootRallyView.getInitialSharedValues = getInitialCmsPageSharedValues(SLUG);

export default withRouter(RebootRallyView);
