import React from 'react';
import {animated, useSpring} from 'react-spring';

export interface Section {
  title: string;
  body: string;
  key: string;
  route: string;
  is_external: boolean;
}

interface HelpCenterSectionProps {
  section: Section;
  routeHandler: (route: string) => any;
  index: number;
}

const HelpCenterSection = (props: HelpCenterSectionProps) => {
  const styles = useSpring({
    from: {transform: 'translateX(-100%)', opacity: 0, borderTop: '1px solid #fff'},
    to: {transform: 'translateX(0)', opacity: 1},
    delay: props.index * 200,
  });

  const linkTo = (route: string, is_external: boolean) => {
    is_external ? (window.location.href = route) : props.routeHandler(route); // eslint-disable-line
  };

  return (
    <animated.div className="section-container" style={styles}>
      <div
        className="title-container"
        role="button"
        tabIndex={0}
        onClick={() => linkTo(props.section.route, props.section.is_external)}>
        <h3 className="section-title">{props.section.title}</h3>
        <i className="icon-full-arrow" />
      </div>
      <p className="section-body" dangerouslySetInnerHTML={{__html: props.section.body}} />
    </animated.div>
  );
};

export default HelpCenterSection;
