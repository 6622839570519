import styled from 'styled-components';

import {breakpoints} from '../../../../constants/Breakpoints';

const Subheader = styled.div`
  font-size: 1.8rem;
  max-width: 94rem;
  margin-top: 2rem;
  width: 85%;

  @media ${breakpoints.mobileL} {
    font-size: 1.6rem;
  }
`;

export default Subheader;
