import styled from 'styled-components';

const StyledSubscriptionGradientFadeOut = styled.div`
  width: 100%;
  height: 80px;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
  position: absolute;
  bottom: 0;
`;

export default StyledSubscriptionGradientFadeOut;
