import React from 'react';
import AddToCalendarMenu from './AddToCalendarMenu';

interface Props {
    triggerLabel: string;
    eventTitle: string;
    eventDescription?: string;
    eventLocation?: string;
    eventStart: string;
    eventEnd?: string;
    eventUrl?: string;
    key: string;
}

const AddToCalendar = (props: Props): JSX.Element | null => {
    const {
        key,
        triggerLabel,
        eventTitle,
        eventDescription,
        eventLocation,
        eventStart,
        eventEnd,
        eventUrl
    } = props;

    const event = {
        title: eventTitle,
        description: eventDescription,
        location: eventLocation,
        start: eventStart,
        end: eventEnd,
        url: eventUrl
    };

    if(!eventTitle || !eventStart) {
        console.error('Event configured in CMS is missing required data (title or start)', {event})
        return null;
    }

    return (
        <AddToCalendarMenu key={key} instanceId={key} event={event} triggerLabel={triggerLabel} />
    );
};

export default AddToCalendar;
