import {getBowserState} from '@epic-core/common';
import {Stack, Text} from 'epic-fortnite-react';
import React, {useState} from 'react';
import styled from 'styled-components';

import {trackEvent} from '../../utils/eventTracking';

import {RiftTourCTA} from './components/RiftTourCTA';
import {RiftTourHeading} from './components/RiftTourHeading';
import {RiftTourSubheading} from './components/RiftTourSubheading';
import RiftTourShareModal from './RiftTourShareModal';
import {RiftTourCtaInterface} from './types';
import {formattedDateTime} from './utils';

type ShareLogoMaxHeight = string;
type ShareLogoMaxWidth = string;
type ShareLogoPosition = 'bottom' | 'top';

interface Props {
  details: ScheduleItem;
}

interface ShareLogoProps {
  $shareLogoPosition?: ShareLogoPosition;
}

interface ShareLogoImgProps {
  $shareLogoMaxHeight?: ShareLogoMaxHeight;
  $shareLogoMaxWidth?: ShareLogoMaxWidth;
}

interface ScheduleItem {
  backgroundImage: string;
  commonLogo: string;
  cta: RiftTourCtaInterface;
  scheduleLogoOverride?: string;
  scheduleTimestamp: string;
  shareHeading: string;
  shareImage: string;
  shareLogoMaxHeight?: ShareLogoMaxHeight;
  shareLogoMaxWidth?: ShareLogoMaxWidth;
  shareLogoPosition?: ShareLogoPosition;
  shareText: string;
  shareVideo: string;
}

const ShareLogo = styled.div<ShareLogoProps>`
  align-items: center;
  bottom: 0;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  padding: 48px;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;

  ${({$shareLogoPosition}) =>
    $shareLogoPosition === 'bottom'
      ? 'align-items: bottom'
      : $shareLogoPosition === 'top'
      ? 'align-items: top'
      : 'align-items: center'}
`;

const ShareLogoImg = styled.img<ShareLogoImgProps>`
  height: auto;
  width: auto;
  max-height: ${({$shareLogoMaxHeight}) =>
    $shareLogoMaxHeight ? `${$shareLogoMaxHeight}px` : 'none'};
  max-width: ${({$shareLogoMaxWidth}) => ($shareLogoMaxWidth ? `${$shareLogoMaxWidth}px` : 'auto')};
`;

export const RiftTourScheduleCard = ({details}: Props) => {
  const {mobile} = getBowserState();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const {
    commonLogo,
    cta,
    scheduleLogoOverride,
    scheduleTimestamp,
    shareHeading,
    shareImage,
    shareLogoMaxHeight,
    shareLogoMaxWidth,
    shareLogoPosition,
    shareText,
    shareVideo,
  } = details;

  const {date, time} = formattedDateTime(scheduleTimestamp);

  const handleClick = () => {
    trackEvent({
      eventCategory: 'fn-web',
      eventAction: 'buffet-schedule',
      eventLabel: scheduleTimestamp,
      eventValue: 1,
      interactionType: 'click',
    });
    if (mobile && navigator.share) {
      navigator
        .share({
          text: `${shareText ? shareText.replace('${date}', `${date} ${time}`) : ''}`,
          url: 'https://fn.gg/rift',
        })
        .then(() => {
          trackEvent({
            eventCategory: 'fn-web',
            eventAction: 'buffet-social',
            eventLabel: 'success',
            eventValue: 1,
            interactionType: 'click',
          });
          console.log('Thanks for sharing!');
        })
        .catch(() => {
          trackEvent({
            eventCategory: 'fn-web',
            eventAction: 'buffet-social',
            eventLabel: 'dismiss',
            eventValue: 1,
            interactionType: 'click',
          });
          console.error();
        });
    } else {
      setIsModalOpen(true);
    }
  };

  return (
    <>
      <Stack gap="lg">
        <Stack gap="med">
          <img
            src={scheduleLogoOverride ? scheduleLogoOverride : commonLogo}
            alt=""
            style={{maxWidth: '100%'}}
          />
          <Stack gap="3xs" justify="center">
            <Text color="#fff" size="lg" transform="uppercase">
              {date}
            </Text>
            <Text color="#fff" size="lg" transform="uppercase">
              {time}
            </Text>
          </Stack>
          <RiftTourCTA label={cta.label} onClick={handleClick} theme="white" />
        </Stack>
      </Stack>
      <RiftTourShareModal
        ariaLabel={shareHeading}
        backgroundImage={shareImage ?? null}
        logo={scheduleLogoOverride ? scheduleLogoOverride : commonLogo}
        shareText={shareText}
        eventTime={`${date} ${time}`}
        isOpen={isModalOpen}
        modalOverlayContent={
          !shareImage && (
            <ShareLogo $shareLogoPosition={shareLogoPosition}>
              <ShareLogoImg
                src={scheduleLogoOverride ? scheduleLogoOverride : commonLogo}
                alt=""
                $shareLogoMaxHeight={shareLogoMaxHeight}
                $shareLogoMaxWidth={shareLogoMaxWidth}
              />
            </ShareLogo>
          )
        }
        onDismiss={() => setIsModalOpen(false)}
        video={shareVideo ?? null}>
        <Stack justify="center" gap="med">
          <RiftTourHeading color="#fac6da">{shareHeading}</RiftTourHeading>
          <RiftTourSubheading color="#fff">{`${date} ${time}`}</RiftTourSubheading>
        </Stack>
      </RiftTourShareModal>
    </>
  );
};
