import {ERROR_ACCOUNT_PASSWORD_RESUSE, ERROR_ACCOUNT_VALIDATION_FAILURE} from '../constants/ServiceErrors';

export function isPasswordReuseError(code) {
    return code === ERROR_ACCOUNT_PASSWORD_RESUSE;
}

export function isValidationError(code) {
    // Handles general validation error codes
    return code === ERROR_ACCOUNT_VALIDATION_FAILURE;
}
