import AnimateObject from 'epic-fortnite-react/components/common/AnimateObject';
import PropTypes from 'prop-types';
import React from 'react';

import VideoOverlay from '../common/VideoOverlay';

export default class VideoBlock extends React.PureComponent {
  static propTypes = {
    videos: PropTypes.array,
  };

  state = {
    showVideo: false,
    contentId: '',
  };

  showVideo = (contentId) => {
    this.setState({
      showVideo: true,
      contentId,
    });
  };

  closeOverlay = () => {
    this.setState({
      showVideo: false,
      contentId: '',
    });
  };

  render() {
    const {videos} = this.props;
    if (!videos.length) return null;
    const ctaFrom = {
      opacity: 0,
      scale: 0.5,
      transformOrigin: 'center bottom',
    };
    const ctaTo = {
      delay: 1.4,
      opacity: 1,
      scale: 1,
      rotationY: 0,
    };

    const videoList = videos.map((video, i) => {
      const {img = '', title, contentId} = video;

      return (
        <AnimateObject key={title} minTop={20} speed={1} from={ctaFrom} to={ctaTo}>
          <div className="trailer" onClick={() => this.showVideo(contentId)} role="presentation">
            <img className="trailer-img" src={img} alt={title} />
            <h5>{title}</h5>
          </div>
        </AnimateObject>
      );
    });
    return (
      <div>
        <div className="trailer-section">{videoList}</div>
        {this.state.contentId && (
          <VideoOverlay
            videoID={this.state.contentId}
            isLauncher={false}
            play={this.state.showVideo}
            close={this.closeOverlay}
            closeButton
            show={this.state.showVideo}
          />
        )}
      </div>
    );
  }
}
