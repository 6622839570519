import {generateRouteTo} from 'epic-fortnite-react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import {withRouter} from 'react-router';

import Header from './Header';

class ConfirmPage extends React.PureComponent {
  static propTypes = {
    content: PropTypes.object,
    status: PropTypes.object,
    history: PropTypes.object,
    title: PropTypes.string,
    android: PropTypes.bool,
    locale: PropTypes.string,
  };

  componentDidUpdate(prevProps) {
    if (this.props.status !== prevProps.status) {
      const {status, android, history, location, locale} = this.props;
      if (!status || !status.registered) {
        if (android) {
          history.push(generateRouteTo('/mobile/android/sign-up', location, locale));
        } else {
          history.push(generateRouteTo('/mobile/sign-up', location, locale));
        }
      }
    }
  }

  render() {
    const {content, title, android, status} = this.props;
    const confirmData = content.confirm || [];
    const message = [];
    let activeSection;
    for (let x = 0; x < confirmData.length; x++) {
      const item = confirmData[x];
      message.push(
        <div key={`item-${x}`}>
          <h2>{item.title}</h2>
          {item.description ? <div dangerouslySetInnerHTML={{__html: item.description}} /> : null}
        </div>
      );
    }
    if (status && status.platform && status.platform.includes('apple-')) {
      activeSection = message[0]; //eslint-disable-line
    } else {
      activeSection = message[1]; //eslint-disable-line
    }
    if (android) {
      activeSection = message[0];
    }
    const confirmClass = classNames('confirm-page', {android});
    return (
      <div className={confirmClass}>
        <div className="container-fluid content-container">
          <div className="container">
            <div className="row">
              <div className="col-xs-12 col-md-9 col-md-offset-3 text-center">
                <div className="content">
                  <Header android title={title} />
                  {activeSection}
                </div>
              </div>
            </div>
          </div>
          <div className="heroes" />
        </div>
      </div>
    );
  }
}

export default withRouter(ConfirmPage);
