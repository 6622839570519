import React from 'react';

import {Inline, Stack, Text} from '../';

export const tones = ['neutral', 'critical', 'positive'] as const;

type FieldTone = typeof tones[number];

export interface FieldMessageProps {
  disabled?: boolean;
  hint?: React.ReactNode;
  id: string;
  message: React.ReactNode;
  secondaryHint?: React.ReactNode;
  secondaryMessage?: React.ReactNode;
  tone?: FieldTone;
}

export const FieldMessage = ({
  disabled,
  hint,
  id,
  message,
  secondaryHint,
  tone = 'neutral',
}: FieldMessageProps) => {
  if (tones.indexOf(tone) === -1) {
    throw new Error(`Invalid tone: ${tone}`);
  }

  // if no messages or hints, return nothing
  if (!hint && !secondaryHint && !message) {
    return null;
  }

  return (
    <Stack gap="sm">
      {message && (
        <Text color="#5e5e5e" font="OpenSans" linkStyle="underline" size="xs">
          {message}
        </Text>
      )}
      {!disabled && (
        <Inline flex="start" gap="sm" id={id}>
          {hint && (
            <Text
              color={tone === 'critical' ? 'red' : tone === 'positive' ? 'green' : '#5e5e5e'}
              font="OpenSans"
              linkStyle="underline"
              size="xs">
              {hint}
            </Text>
          )}
          {secondaryHint && (
            <Text
              color={tone === 'critical' ? 'red' : tone === 'positive' ? 'green' : '#5e5e5e'}
              font="OpenSans"
              linkStyle="underline"
              size="xs">
              {secondaryHint}
            </Text>
          )}
        </Inline>
      )}
    </Stack>
  );
};
