import {datasource} from 'alt-utils/lib/decorators';
import {seamlessBootstrap, seamlessImmutable} from 'epic-alt-utils';
import {alt} from 'epic-react-common';
import Immutable from 'seamless-immutable';

import StoreActions from '../actions/StoreActions';
import StoreSource from '../sources/StoreSource';

@datasource(StoreSource)
@seamlessImmutable
class StoreStore {
  getStateFunctions() {
    return {
      loading() {
        return (
          this.loadingFeatured ||
          this.loadingCategoryList ||
          this.loadingAssets ||
          this.loadingFounders
        );
      },
      getAssetBySlug(slug) {
        if (!slug) return null;
        return this.assets[slug];
      },
      getConfigForOffer(offer) {
        if (!offer || !this.foundersData) return null;
        for (let i = 0; i < this.foundersData.config.length; i++) {
          const configItem = this.foundersData.config[i];
          if (configItem.offerId === offer) return configItem;
        }
        return null;
      },
      getConfigForSlug(slug) {
        if (!slug || !this.foundersData) return null;
        for (let i = 0; i < this.foundersData.config.length; i++) {
          const configItem = this.foundersData.config[i];
          if (configItem.slug === slug) return configItem;
        }
        return null;
      },
      getFoundersOfferForSlug(slug) {
        if (!slug || !this.foundersData) return null;
        const config = this.getConfigForSlug(slug) || {};
        return this.getOfferById(config.offerId);
      },
      getOfferById(offerId) {
        if (!offerId || !this.foundersData || !this.foundersData.offers) return null;
        for (let i = 0; i < this.foundersData.offers.length; i++) {
          const offer = this.foundersData.offers[i];
          if (offer.id === offerId) return offer;
        }
        return null;
      },
      getDefaultTier() {
        if (!this.foundersData || this.foundersData.config.length < 1) return null;
        return this.foundersData.config[0];
      },
    };
  }

  constructor() {
    this.on('error', (error) => {
      console.error(error);
    });
    this.on('bootstrap', seamlessBootstrap.bind(this, null));
    const initialState = Immutable({
      featured: null,
      categories: {},
      assets: {},
      foundersData: null,
      subscriptionOfferData: null,
      loadingSubscriptionOffer: false,
      subscriptionOfferError: false,
      battleRoyale: {},
      refreshSlug: null,
      loadingFounders: false,
      loadingFeatured: false,
      loadingCategoryList: false,
      loadingAssets: false,
      categoriesLoaded: false,
      featuresLoaded: false,
      cacheCleared: false,
      error: null,
      showPopDown: true,
    });
    this.state = initialState.merge(this.getStateFunctions());
    this.bindActions(StoreActions);
  }

  onGetFeatured() {
    if (this.state.featuresLoaded) return false;
    this.getInstance().getFeatured();
    return this.setState(
      this.state.merge({
        loadingFeatured: true,
        cacheCleared: false,
      })
    );
  }

  onGetFeaturedSuccess(response) {
    const {offers} = response.data;
    if (offers) {
      offers.sort((a, b) => a.priceValue - b.priceValue);
    }
    this.setState(
      this.state.merge({
        featured: offers,
        loadingFeatured: false,
        featuresLoaded: true,
      })
    );
  }

  onGetFeaturedFailure(error) {
    const {response} = error;
    this.setState(
      this.state.merge({
        error: response && response.data ? response.data.message : 'epic.fortnite.500.msg1',
        loadingFeatured: false,
      })
    );
  }

  onGetCategories() {
    if (this.state.categoriesLoaded) return false;
    this.getInstance().getCategories();
    return this.setState(this.state.set('loadingCategoryList', true));
  }

  onGetCategoriesSuccess(response) {
    const categoryList = response.data.categories;
    const categories = {};
    for (const category of categoryList) {
      categories[category.path] = category;
    }
    this.setState(
      this.state.merge({
        categories,
        loadingCategoryList: false,
        categoriesLoaded: true,
      })
    );
    for (const path in this.state.categories) {
      if (Object.prototype.hasOwnProperty.call(this.state.categories.prototype, path)) {
        const category = this.state.categories[path];
        this.onGetCategory(category);
      }
    }
  }

  onGetCategoriesFailure(error) {
    const {response} = error;
    this.setState(
      this.state.merge({
        error: response && response.data ? response.data.message : 'epic.fortnite.500.msg1',
        loadingCategoryList: false,
      })
    );
  }

  onGetCategory(category) {
    if (!category) return false;
    let currentCategory = category;
    if (typeof category === 'string') {
      currentCategory = this.state.categories[category];
    }
    const loadingCategoryState = {
      loadingCategories: true,
      categories: {},
    };
    loadingCategoryState.categories[currentCategory.path] = currentCategory.set('loading', true);
    this.getInstance().getCategory(currentCategory.path);
    return this.setState(this.state.merge(loadingCategoryState, {deep: true}));
  }

  onGetCategorySuccess(response) {
    const categoryDetails = response.data;
    const updatedCategory = {
      categories: {},
    };
    updatedCategory.categories[categoryDetails.categoryPath] = {
      assets: categoryDetails.assets,
      loading: false,
    };
    this.setState(this.state.merge(updatedCategory, {deep: true}));
  }

  onGetCategoryFailure(error) {
    const {response} = error;
    this.setState(
      this.state.merge({
        error: response && response.data ? response.data.message : 'epic.fortnite.500.msg1',
        loadingCategories: false,
      })
    );
  }

  onGetAssetDetail(slug) {
    if (!slug || this.state.assets[slug]) return false;
    this.getInstance().getAssetDetail(slug);
    return this.setState(
      this.state.merge({
        loadingAssets: true,
      })
    );
  }

  onGetAssetDetailSuccess(response) {
    const asset = response.data;
    const assets = {};
    assets[asset.slug] = asset;
    this.setState(
      this.state.merge(
        {
          assets,
          loadingAssets: false,
          error: null,
          errorStatus: null,
        },
        {deep: true}
      )
    );
  }

  onRefreshAsset(slug) {
    this.getInstance().resetPurchased();
    this.setState(this.state.set('refreshSlug', slug));
  }

  onRefreshAssetSuccess(response) {
    this.onGetAssetDetail(this.state.refreshSlug);
    this.setState(this.state.set('refreshSlug', null));
  }

  onGetAssetDetailFailure(error) {
    const {response} = error;
    this.setState(
      this.state.merge({
        error:
          response && response.data && response.data.message
            ? response.data.message
            : 'epic.fortnite.500.msg1',
        errorStatus: response.status,
        loadingAssets: false,
      })
    );
  }

  onClearCaches() {
    this.setState(
      this.state.merge({
        categoriesLoaded: false,
        featuresLoaded: false,
        loadingFeatured: false,
        loadingCategoryList: false,
        loadingAssets: false,
        loadingFounders: false,
        foundersData: null,
        foundersError: false,
        cacheCleared: true,
        assets: {},
      })
    );
  }

  onGetFoundersPacks(data) {
    if (this.state.foundersData) return false;
    this.getInstance().getFoundersPacks();
    return this.setState(
      this.state.merge({
        foundersError: false,
        loadingFounders: true,
      })
    );
  }

  onGetFoundersPacksSuccess(response) {
    this.setState(
      this.state.merge({
        foundersData: response.data,
        foundersError: false,
        loadingFounders: false,
      })
    );
  }

  onGetFoundersPacksFailure(error) {
    console.log('Failed to load founders packs');
    this.setState(
      this.state.merge({
        loadingFounders: false,
        foundersError: true,
      })
    );
  }

  onGetSubscriptionData(data) {
    if (this.state.subscriptionData) return false;
    this.getInstance().getSubscriptionData();
    return this.setState(
      this.state.merge({
        subscriptionOfferError: false,
        loadingSubscriptionOffer: true,
      })
    );
  }

  onGetSubscriptionDataSuccess(response) {
    this.setState(
      this.state.merge({
        subscriptionOfferData: response.data,
        loadingSubscriptionOffer: false,
        subscriptionOfferError: false,
      })
    );
  }

  onGetSubscriptionDataFailure(error) {
    console.log('Failed to load subscription offer');
    this.setState(
      this.state.merge({
        loadingSubscriptionOffer: false,
        subscriptionOfferError: true,
      })
    );
  }

  onSendWelcome() {
    this.getInstance().sendWelcome();
  }

  onClosePopDown() {
    this.setState(
      this.state.merge({
        showPopDown: false,
      })
    );
  }
}

export default alt.createStore(StoreStore, 'StoreStore');
