import {seamlessImmutable, seamlessBootstrap} from 'epic-alt-utils';
import alt from 'epic-fortnite-react/alt';
import Immutable from 'seamless-immutable';

@seamlessImmutable
class LandingPageStore {
  constructor() {
    this.on('error', (error) => {
      console.error(error);
    });
    this.on('bootstrap', seamlessBootstrap.bind(this, null));
    this.state = Immutable({
      pages: {},
      noHeaderPages: {},
    });
  }
}

export default alt.createStore(LandingPageStore, 'LandingPageStore');
