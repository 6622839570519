import {withLocalization} from '@epic-core/localization';
import Proptypes from 'prop-types';
import React from 'react';
import Select from 'react-select';

const CountrySelect = ({countryOptions, setCountry, placeholder, getMessage, ...rest}) => {
  const onChange = React.useCallback((item) => setCountry(item.value), [setCountry]);
  const noOptionsMessage = React.useCallback(
    () => getMessage('epic.common.no_results'),
    [getMessage]
  );
  return (
    <Select
      placeholder={placeholder}
      isOptionDisabled={(option) => option.disabled}
      onChange={onChange}
      noOptionsMessage={noOptionsMessage}
      options={countryOptions}
      {...rest}
    />
  );
};

CountrySelect.propTypes = {
  countryOptions: Proptypes.arrayOf(
    Proptypes.shape({
      label: Proptypes.string,
      value: Proptypes.string,
    })
  ),
  setCountry: Proptypes.func,
  placeholder: Proptypes.string,
  getMessage: Proptypes.func,
  styles: Proptypes.object,
  width: Proptypes.string,
  className: Proptypes.string,
};

export default withLocalization(CountrySelect);
