import classNames from 'classnames';
import CommonCTABtn from 'epic-fortnite-react/components/common/CommonCTABtn';
import React from 'react';
import styled from 'styled-components';

export interface Props {
  className?: string;
  ctaConfig: {
    isCtaLinkExternal?: boolean;
    ctaString: string;
    ctaLink: string;
    disabled?: boolean;
    textOnly?: boolean;
    theme?: string;
    onExternalClickHandler?: any;
  };
  theme?:
    | 'teal'
    | 'green'
    | 'black'
    | 'pink'
    | 'yellow'
    | 'blackYellow'
    | 'brightYellow'
    | undefined;
}

const themeOptions = {
  teal: {
    bgColor: '#64fecb',
    textColor: '#000',
  },
  green: {
    bgColor: '#8bff3a',
    textColor: '#000',
  },
  pink: {
    bgColor: '#b900b6',
    textColor: '#fff',
  },
  yellow: {
    bgColor: '#FFDA0B',
    textColor: '#000',
  },
  black: {
    bgColor: '#000',
    textColor: '#fff',
  },
  blackYellow: {
    bgColor: '#000',
    textColor: '#f7e03c',
  },
  brightYellow: {
    bgColor: '#f7ff19',
    textColor: '#000',
  },
};

const initialCtaConfig = {
  isCtaLinkExternal: false,
  ctaString: '',
  ctaLink: '',
  disabled: false,
  textOnly: false,
  theme: '',
  onExternalClickHandler: undefined,
};

const StyledCTAButton = styled(CommonCTABtn)`
  &&&&&&& {
    border: 2px solid transparent;
    outline: none;
    background: ${({theme = 'black'}) => themeOptions[theme].bgColor};
    line-height: 1;
    margin: 0;
    padding: 0.625rem 0.75rem 0.375rem 0.75rem;

    &::after {
      display: none;
    }

    span {
      color: ${({theme = 'black'}) => themeOptions[theme].textColor};
    }
  }
`;

const StyledTextOnlyCTAButton = styled(CommonCTABtn)`
  &&&&&&& {
    background: transparent;
    line-height: 1;
    margin: 0;
    padding: 0;

    &::after {
      display: none;
    }

    span {
      color: ${({theme = 'yellow'}) => themeOptions[theme].bgColor};
    }
  }
`;

const CTAButton = ({className, ctaConfig = initialCtaConfig, theme, ...props}: Props) => {
  const classes = classNames('btn-display', className);

  return ctaConfig?.textOnly ? (
    <StyledTextOnlyCTAButton className={classes} ctaConfig={ctaConfig} theme={theme} {...props} />
  ) : (
    <StyledCTAButton className={classes} ctaConfig={ctaConfig} theme={theme} {...props} />
  );
};

export default CTAButton;
