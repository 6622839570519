import {Device} from 'epic-fortnite-styles/constants';
import styled from 'styled-components';

const CreativeHomeFeaturedDropDownContainer = styled.div`
  top: 3.5em;
  position: absolute;
  right: 1em;
  width: 13em;
  max-width: 50em;
  @media screen and ${Device.laptop} {
    width: 95%;
    position: unset;
    margin: 0.5em auto;
    max-width: 20em;
  }
`;

export default CreativeHomeFeaturedDropDownContainer;
