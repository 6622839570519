import {SharedValueKey, SharedValuesContext} from '@epic-core/hooks';
import {DefaultSharedStore, createSharedValueKey, SharedValueStoreContext} from '@epic-core/hooks';
import express from 'express';
import {matchPath} from 'react-router-dom';
import parse from 'url-parse';
import type URL from 'url-parse';

import {PageData} from '../cms/cms.types';

import {SharedKeys} from './sharedKeys';

let context: SharedValuesContext;

export const getSharedContext = (): SharedValuesContext => {
  return context;
};

export const setSharedContext = (newContext: SharedValuesContext): void => {
  context = newContext;
};

export type InitSharedValueResponse = {
  key: SharedValueKey<PageData>;
  value: {[key: string]: PageData};
};

export const sharedStore = new DefaultSharedStore();

export async function bootstrapSharedValues<SharedValuesState>(
  sharedValuesState: SharedValuesState,
  keys: SharedKeys<SharedValuesState>
): Promise<void> {
  let context = getSharedContext();
  const stores = Object.keys(keys);
  context = new SharedValueStoreContext(sharedStore);

  stores.forEach((store) => {
    keys[store] = createSharedValueKey(store, sharedValuesState[store] || {});
    context.set<SharedValuesState>(keys[store], sharedValuesState[store] || {});
  });

  setSharedContext(context);
}

export async function serverBootstrapSharedValues<SharedValuesState>(
  routes: JSX.Element,
  keys: SharedKeys<SharedValuesState>,
  state: SharedValuesState,
  req: express.Request
): Promise<SharedValuesState> {
  const originalUrl = req.originalUrl;
  const url = parse(originalUrl, true);

  try {
    await initializeMatchingRoutes(routes, state, url);
    await bootstrapSharedValues(state, keys);
    return state;
  } catch (err) {
    console.error('serverBootstrapSharedValues -> failed to bootstrap shared values', err);
    throw err;
  }
}

async function initializeMatchingRoutes<SharedValuesState>(
  routes: JSX.Element,
  state: SharedValuesState,
  url: URL<any>
) {
  const routeSwitchProps = routes.props ?? {};
  const children = routeSwitchProps.children ?? [];

  const promises: Promise<any>[] = [];
  for (const route of children) {
    const routeProps = route.props;
    const match = matchPath(url.pathname, routeProps);
    if (!match || !routeProps.component || !routeProps.component.getInitialSharedValues) {
      continue;
    }
    const initPromise = routeProps.component.getInitialSharedValues(match, url);
    if (initPromise && typeof initPromise.then === 'function') {
      promises.push(initPromise);
    }
  }

  try {
    const routeInitPromiseArray = await Promise.all(promises);
    aggregateResponses(state, routeInitPromiseArray);
  } catch (err) {
    console.error(
      `initializeMatchingRoutes -> failed to resolve route init promises (${promises.length} promises): `,
      JSON.stringify(err, Object.getOwnPropertyNames(err)),
      err
    );

    throw err;
  }
}

async function aggregateResponses<SharedValuesState>(
  state: SharedValuesState,
  responseArray: InitSharedValueResponse[][]
) {
  responseArray.forEach((responses) => {
    responses.forEach((response) => {
      if (response && typeof response === 'object' && response.key && response.value) {
        const {key, value} = response;
        const id = key.id;
        state[id] = Object.assign({}, state[id] ?? {}, value);
      } else {
        console.warn(
          'server.initializers aggregateResponses() failed to initialize due to an invalid response',
          response
        );
      }
    });
  });
}
