import React from 'react';

export const CalendarIcon = (): JSX.Element => (
    <svg
        style={{marginRight: '1rem'}}
        width="21"
        height="22"
        viewBox="0 0 23 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <rect x="0.5" y="0.5" width="22" height="20" rx="3.5" fill="#F61212" stroke="black" />
        <path
            d="M1 6.5H0.5V7V20C0.5 21.933 2.067 23.5 4 23.5H19C20.933 23.5 22.5 21.933 22.5 20V7V6.5H22H1Z"
            fill="white"
            stroke="black"
        />
        <path
            d="M4 8.5H3.5V9V19C3.5 19.8284 4.17157 20.5 5 20.5H18C18.8284 20.5 19.5 19.8284 19.5 19V9V8.5H19H4Z"
            fill="white"
            stroke="#E5E5E5"
        />
        <rect x="7" y="9" width="1" height="11" fill="#E5E5E5" />
        <rect x="11" y="9" width="1" height="11" fill="#E5E5E5" />
        <rect x="15" y="9" width="1" height="11" fill="#E5E5E5" />
        <rect x="4" y="12" width="15" height="1" fill="#E5E5E5" />
        <rect x="4" y="16" width="15" height="1" fill="#E5E5E5" />
        <path d="M1 4C1 2.34315 2.34315 1 4 1H19C20.6569 1 22 2.34315 22 4V7H1V4Z" fill="#F61212" />
    </svg>
);

export const GoogleIcon = (): JSX.Element => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M23.745 12.27C23.745 11.48 23.675 10.73 23.555 10H12.255V14.51H18.725C18.435 15.99 17.585 17.24 16.325 18.09V21.09H20.185C22.445 19 23.745 15.92 23.745 12.27Z"
            fill="#4285F4"
        />
        <path
            d="M12.255 24C15.495 24 18.205 22.92 20.185 21.09L16.325 18.09C15.245 18.81 13.875 19.25 12.255 19.25C9.12504 19.25 6.47505 17.14 5.52505 14.29H1.54504V17.38C3.51504 21.3 7.56504 24 12.255 24Z"
            fill="#34A853"
        />
        <path
            d="M5.52501 14.29C5.27501 13.57 5.145 12.8 5.145 12C5.145 11.2 5.28501 10.43 5.52501 9.71V6.62H1.545C0.725004 8.24 0.255005 10.06 0.255005 12C0.255005 13.94 0.725004 15.76 1.545 17.38L5.52501 14.29Z"
            fill="#FBBC05"
        />
        <path
            d="M12.255 4.75C14.025 4.75 15.605 5.36 16.855 6.55L20.275 3.13C18.205 1.19 15.495 0 12.255 0C7.56504 0 3.51504 2.7 1.54504 6.62L5.52505 9.71C6.47505 6.86 9.12504 4.75 12.255 4.75Z"
            fill="#EA4335"
        />
    </svg>
);

export const OutlookIcon = (): JSX.Element => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M14.9308 5.18494V9.7616L16.5302 10.7687C16.5723 10.781 16.6637 10.7819 16.7059 10.7687L23.5894 6.12786C23.5894 5.57863 23.077 5.18494 22.7879 5.18494H14.9308Z"
            fill="#0072C6"
        />
        <path
            d="M14.9308 11.4692L16.3905 12.4718C16.5961 12.623 16.8439 12.4718 16.8439 12.4718C16.597 12.623 23.5903 7.97778 23.5903 7.97778V16.3894C23.5903 17.3051 23.0041 17.6891 22.345 17.6891H14.9299V11.4692H14.9308Z"
            fill="#0072C6"
        />
        <path
            d="M7.37329 9.35913C6.87591 9.35913 6.47958 9.59288 6.18695 10.0595C5.89431 10.5261 5.74756 11.1439 5.74756 11.9129C5.74756 12.6932 5.89431 13.3101 6.18695 13.7635C6.47958 14.2179 6.8636 14.4437 7.33814 14.4437C7.82762 14.4437 8.21604 14.2231 8.50252 13.782C8.789 13.3409 8.93312 12.7284 8.93312 11.9454C8.93312 11.129 8.79427 10.4936 8.5157 10.0393C8.23713 9.58585 7.85662 9.35913 7.37329 9.35913Z"
            fill="#0072C6"
        />
        <path
            d="M0.448608 2.86057V20.7656L14.0696 23.6216V0.224243L0.448608 2.86057V2.86057ZM9.56326 14.84C8.98766 15.5975 8.23719 15.9772 7.31096 15.9772C6.40846 15.9772 5.6738 15.6098 5.10524 14.8761C4.53755 14.1414 4.25282 13.1853 4.25282 12.006C4.25282 10.7608 4.54106 9.75368 5.11842 8.98475C5.69577 8.21582 6.46031 7.83092 7.41202 7.83092C8.31101 7.83092 9.03863 8.19825 9.59314 8.93466C10.1485 9.67107 10.4262 10.6412 10.4262 11.846C10.4271 13.0842 10.1389 14.0825 9.56326 14.84Z"
            fill="#0072C6"
        />
    </svg>
);

export const YahooIcon = (): JSX.Element => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_62:400)">
            <path
                d="M14.9314 6.31711L7.97143 23.0828H3.48L5.28857 18.6257L0 6.31711H4.67143L7.42286 13.3285L10.2857 6.31711H14.9314ZM14.3829 11.9057H19.4229L24 0.917114H18.96L14.3829 11.9057ZM18.5057 15.9428C18.5074 15.3796 18.342 14.8286 18.0303 14.3595C17.7186 13.8904 17.2748 13.5243 16.755 13.3076C16.2351 13.0909 15.6627 13.0333 15.1101 13.1422C14.5575 13.251 14.0497 13.5214 13.6508 13.919C13.252 14.3166 12.9801 14.8237 12.8696 15.3759C12.7591 15.9282 12.815 16.5008 13.0301 17.0213C13.2452 17.5418 13.61 17.9867 14.0781 18.2998C14.5463 18.6129 15.0968 18.78 15.66 18.78C16.4132 18.78 17.1358 18.4813 17.6692 17.9495C18.2026 17.4177 18.5034 16.6961 18.5057 15.9428Z"
                fill="#5F01D1"
            />
        </g>
        <defs>
            <clipPath id="clip0_62:400">
                <rect width="24" height="24" fill="white" />
            </clipPath>
        </defs>
    </svg>
);

export const IcsIcon = (): JSX.Element => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M9.5 4C9.5 3.44772 9.94772 3 10.5 3H13.5C14.0523 3 14.5 3.44772 14.5 4V10L17.7645 10C18.2139 10 18.4351 10.5468 18.1122 10.8593L12.365 16.4207C12.1715 16.608 11.8645 16.6083 11.6706 16.4216L5.8937 10.8602C5.56948 10.5481 5.79042 10 6.24047 10H9.5V4Z"
            fill="#303030"
        />
        <path d="M20 19H4V21H20V19Z" fill="#303030" />
    </svg>
);

export const AppleIcon = (): JSX.Element => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M18.5284 22.1141C17.3292 23.2721 16.0061 23.0916 14.7449 22.5456C13.4041 21.9886 12.1784 21.9533 10.7624 22.5456C8.99898 23.303 8.06308 23.0828 7.00106 22.1141C1.00509 15.9714 1.8901 6.61427 8.70471 6.262C10.3575 6.35007 11.5146 7.1713 12.4881 7.23955C13.9351 6.94672 15.3202 6.10789 16.869 6.21797C18.7297 6.36768 20.1214 7.09864 21.0507 8.41304C17.223 10.7028 18.1301 15.7226 21.6458 17.1317C20.9422 18.9701 20.0395 20.7865 18.5262 22.1295L18.5284 22.1141ZM12.3554 6.19595C12.1762 3.46588 14.3998 1.22017 16.9575 1C17.3093 4.1484 14.0812 6.50419 12.3554 6.19595Z"
            fill="black"
        />
    </svg>
);
