import PropTypes from 'prop-types';
import React, {useState} from 'react';
import VisibilitySensor from 'react-visibility-sensor';

import AnimateString from '../common/AnimateString';

const Gliders = ({data, mobile}) => {
  const [visible, setVisibility] = useState(false);
  const {title, paragraph, bgImg, gliders, mobileBgImg, gliderImages} = data;
  const bgImage = mobileBgImg && mobile ? mobileBgImg : bgImg;
  let images = null;
  //need to kill this for next season and remove from CMS as it is pure garbage
  if (gliders && gliders.length > 0) {
    images = gliders.map((img, index) => {
      const key = `glider-${index}`;
      return <img src={img} alt={key} key={key} />;
    });
  }
  // this is the stuff we want to go forward with
  if (gliderImages && gliderImages.length > 0) {
    images = gliderImages.map((glider, i) => {
      const key = `glider-${i}`;
      return <img src={glider.image} alt={glider.imageAlt} key={key} />;
    });
  }
  return (
    <VisibilitySensor
      onChange={(visibility) => setVisibility(visibility)}
      active={!visible}
      partialVisibility
      minTopValue={50}>
      <div className={`battlepass-gliders ${visible ? 'visible' : ''}`}>
        <div className="gliders-bg" style={{backgroundImage: `url(${bgImage})`}} />
        <div className="glider-images">{images}</div>
        <div className="gliders-content-wrapper">
          <div className="gliders-content">
            {title ? (
              <AnimateString
                key="gliders-h1"
                text={title}
                type="h1"
                speed={1.5}
                delay={0.3}
                autoAlpha={0}
                yPos={100}
                minTop={20}
                scaleFrom={1}
                staggerDelay={0}
              />
            ) : null}
            {paragraph ? (
              <AnimateString
                key={`gliders-p`}
                text={paragraph}
                type="p"
                speed={1.5}
                delay={0.3}
                autoAlpha={0}
                yPos={100}
                minTop={20}
                scaleFrom={1}
                staggerDelay={0}
              />
            ) : null}
          </div>
        </div>
        <div className="battlepass-gliders-clip-path" />
      </div>
    </VisibilitySensor>
  );
};

Gliders.propTypes = {
  mobile: PropTypes.bool,
  data: PropTypes.object,
};

export default Gliders;
