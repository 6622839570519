import styled from 'styled-components';

import Content from './Content';

const ContentRow = styled(Content)`
  padding: 2em 4% 4em 4%;
  max-width: 1000px;
  margin: 0 auto 1em auto;
  position: relative;
  z-index: 10;
  && p {
    ${({theme}) => theme.decoratedFontFamily}
    font-size: 1.3em;
    line-height: 1.2;
  }
`;

export default ContentRow;
