import {YoutubeVideoEmbed} from 'epic-react-common';
import PropTypes from 'prop-types';
import React from 'react';

export default class GuideMedia extends React.Component {
  static propTypes = {
    medias: PropTypes.array,
    activeTab: PropTypes.number,
  };

  render() {
    const {medias, activeTab} = this.props;

    if (!medias) {
      return null;
    }

    return medias.map((item, index) => {
      const key = `${activeTab}-media-${index}`;
      return (
        <div key={key}>
          {item.title && <h3>{item.title}</h3>}
          {item.videoId && (
            <div>
              <YoutubeVideoEmbed video={item.videoId} play={false} launcher={false} />
            </div>
          )}
          {item.subText && (
            <div className="guide-text" dangerouslySetInnerHTML={{__html: item.subText}} />
          )}
        </div>
      );
    });
  }
}
