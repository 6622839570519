import {Carousel} from 'epic-fortnite-react';
import React from 'react';

import {CreativeModeStore} from './types';

interface Props {
  creativeModeStore: CreativeModeStore;
  mobile: boolean;
  tablet: boolean;
  isAndroid: boolean;
}

const CreativeHeader = ({creativeModeStore, mobile, tablet, isAndroid}: Props) => {
  const slides = creativeModeStore.creativeModeData?.carousel?.slides;

  return (
    <div className="creative-header header-container">
      <Carousel
        slides={slides}
        mobile={mobile}
        tablet={tablet}
        isAndroid={isAndroid}
        breakPoint={768}
      />
    </div>
  );
};

export default CreativeHeader;
