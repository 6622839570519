import styled from 'styled-components';

import {Device} from '../../constants';

interface Props {
  $color?: string;
}

export default styled.p<Props>`
    &&&& {
        color: ${({$color}) => $color || '#fff'};
    }
    font-family: BurbankBigRegular-Black, sans-serif;
    position: absolute
    bottom: 0
    @media screen and ${Device.laptopL} {
        display: none;
    }
`;
