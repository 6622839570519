import styled from 'styled-components';

import {Device} from '../../constants';

interface Props {
  $color?: string;
}

export default styled.div<Props>`
  width: 100px;
  height: 100px;
  &&&& {
    background-color: ${({$color}) => $color || '#F8FF01'};
  }
  border-radius: 50%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 30px;
  top: -30px;
  text-transform: uppercase;
  font: 27px/28px BurbankBigRegular-Black, sans-serif;
  @media screen and ${Device.tablet} {
    width: 80px;
    height: 80px;
    font-size: 1.5em;
  }
`;
