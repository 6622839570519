import styled from 'styled-components';

const ImageWrapper = styled.div`
  width: 100%;
  min-width: 0;

  img {
    width: 100%;
  }
`;

export default ImageWrapper;
