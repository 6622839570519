import PropTypes from 'prop-types';
import React from 'react';

import Message from '../message/Message';

import RetweetSVG from './Retweet';

class Context extends React.Component {
  render() {
    const {data, linkProps} = this.props;
    return (
      <div className="context">
        <div className="tweet-context">
          <RetweetSVG />
          <span className="retweet-text">
            <a
              className="pretty-link"
              href={`https://twitter.com/${data.user.screen_name}`}
              {...linkProps}>
              {data.user.name}
            </a>
            &nbsp;
            <Message code="epic.fortnite.twitter.retweeted" />
          </span>
        </div>
      </div>
    );
  }
}

Context.propTypes = {
  data: PropTypes.object,
  linkProps: PropTypes.object,
};

Context.displayName = 'Context';

export default Context;
