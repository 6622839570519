import React, {useState} from 'react';

import {FAQItemInterface} from '../../stores/types';

import HtmlTemplate from './HtmlTemplate';
import StyledFAQHeaderContainer from './StyledFAQHeaderContainer';
import StyledFAQItemContainer from './StyledFAQItemContainer';

const FAQItem = ({question, answer}: FAQItemInterface) => {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <StyledFAQItemContainer>
      <StyledFAQHeaderContainer>
        <h6>{question}</h6>
        <i
          className={`fa fa-${isOpen ? 'minus' : 'plus'}`}
          aria-hidden="true"
          onClick={() => setIsOpen(!isOpen)}></i>
      </StyledFAQHeaderContainer>
      {isOpen && <HtmlTemplate Tag="div" html={answer} />}
    </StyledFAQItemContainer>
  );
};

export default FAQItem;
