import React from 'react';

import FeatureIconContainer from './FeatureIconContainer';
import HeroIcon from './HeroIcon';
import {HeroSlide} from './Types';
interface Props {
  data: HeroSlide;
  active: boolean;
  index: number;
  goToSlide: (value: number) => void;
}

const FeatureIcon = ({data, active, index, goToSlide}: Props) => {
  const {icon, name} = data;
  const getIndex = React.useCallback(() => {
    goToSlide(index);
  }, [goToSlide, index]);
  return (
    <FeatureIconContainer onClick={getIndex} active={active}>
      <HeroIcon active={active} src={icon} alt={name} />
    </FeatureIconContainer>
  );
};

export default FeatureIcon;
