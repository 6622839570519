import styled from 'styled-components';

import {breakpoints} from '../../../../constants/Breakpoints';

const CarouselImage = styled.img`
  width: 380px;

  @media ${breakpoints.mobileL} {
    width: unset;
    height: 500px;
  }

  @media (max-width: 500px) {
    max-width: 100%;
    height: unset;
  }
`;

export default CarouselImage;
