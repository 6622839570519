import React from 'react';
import styled, {css} from 'styled-components';
import {Device} from '../../../constants';

import Hero from './Hero';
import {HeroSlide} from './Types';
import LoadoutItem from './LoadoutItem';
import {ImageArray} from './Types';
import {LoadoutImageContainer} from './LoadoutItem';

interface HeroLoadoutProps {
  items: ImageArray[];
  active: boolean;
  reverse?: boolean;
  keyId: string;
  mobile: boolean;
  tablet: boolean;
}

interface FeatureCarouselItemProps {
  data: HeroSlide;
  active: boolean;
  isVisible: boolean;
  mobile: boolean;
  tablet: boolean;
  goToSlide: (value: number) => void;
  index: number;
}

const FeatureCarouselItemContainer = styled.div<{active: boolean}>`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100vw;
  perspective: 1200px;

  @media screen and ${Device.mobileL} {
    overflow: ${(props) => (props.active ? 'visible' : 'hidden')};
  }
`;

const FeatureHeroSubtitle = styled.h3`
  && {
    font-size: 2.4em;
    margin-top: 1.5em;

    @media screen and (max-width: 940px) {
      margin-top: 7em;
    }

    @media screen and ${Device.tablet} {
      font-size: 2.8em;
      margin-top: 3em;
    }

    @media screen and ${Device.mobileL} {
      font-size: 1.4em;
      margin-top: 2em;
    }
  }
`;

const HeroLoadoutContainer = styled.div<{reverse?: boolean}>`
  height: 100%;
  width: 100%;
  max-width: 1440px;
  position: absolute;
  margin: 0 auto;
  display: flex;
  flex-direction: ${(props) => (props.reverse ? 'column-reverse' : 'column')};
  align-items: flex-end;
  justify-content: space-around;

  ${(props) =>
    !props.reverse
      ? css`
          ${LoadoutImageContainer} {
            top: 20%;
            right: 8%;
            max-width: 180px;
          }
          // Top Right
          ${LoadoutImageContainer}:first-child {
            top: 2%;
            right: 15%;
            max-width: 200px;
          }

          // Bottom Right
          ${LoadoutImageContainer}:last-child {
            top: unset;
            bottom: 25%;
            right: 12%;
            max-width: 200px;
          }

          @media screen and (max-width: 940px) {
            align-items: center;
          }
          @media screen and ${Device.mobileL} {
            align-items: flex-end;
          }
        `
      : null}

  ${(props) =>
    props.reverse
      ? css`
          // Bottom Left
          ${LoadoutImageContainer}:first-child {
            bottom: 25%;
            left: 5%;
            max-width: 260px;
          }

          // Top Left
          ${LoadoutImageContainer}:last-child {
            top: 5%;
            left: 10%;
            max-width: 250px;
          }

          @media screen and (max-width: 940px) {
            align-items: center;
          }
        `
      : null}

    @media screen and (max-width: 940px) {
      display: none;
    }
  }
`;

const HeroLoadout = ({items, active, reverse, keyId, mobile, tablet}: HeroLoadoutProps) => {
  return (
    <HeroLoadoutContainer reverse={reverse}>
      {items.map((item, index) => {
        return (
          <LoadoutItem
            mobile={mobile}
            tablet={tablet}
            keyId={`${keyId}-${index}-${reverse ? 'left' : 'right'}`}
            leftSide={reverse}
            active={active}
            image={item}
            key={`${item.imageAlt}-${keyId}-${index}`}
          />
        );
      })}
    </HeroLoadoutContainer>
  );
};

const FeatureCarouselItem = ({
  data,
  active,
  isVisible,
  mobile,
  tablet,
  goToSlide,
  index,
}: FeatureCarouselItemProps) => {
  const {hero, heroItems, key, sound, heroSubtitle} = data;
  const leftItems = heroItems?.slice(0, 2);
  const rightItems = heroItems?.slice(2, heroItems.length);
  const animate = active && isVisible;

  return (
    <FeatureCarouselItemContainer active={active}>
      {leftItems && (
        <HeroLoadout
          mobile={mobile}
          tablet={tablet}
          keyId={key}
          reverse
          active={animate}
          items={leftItems}
        />
      )}
      {animate && (
        <>
          <Hero
            sound={sound}
            mobile={mobile}
            tablet={tablet}
            keyId={key}
            active={animate}
            images={hero}
            goToSlide={goToSlide}
            index={index}
          />
          {heroSubtitle && <FeatureHeroSubtitle>{heroSubtitle}</FeatureHeroSubtitle>}
        </>
      )}
      {rightItems && (
        <HeroLoadout
          mobile={mobile}
          tablet={tablet}
          keyId={key}
          active={animate}
          items={rightItems}
        />
      )}
    </FeatureCarouselItemContainer>
  );
};

export default FeatureCarouselItem;
