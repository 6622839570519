import PropTypes from 'prop-types';
import React from 'react';

import {SocialButton} from './';

const SocialButtons = ({socialButtons}) => {
  const buttons =
    socialButtons && socialButtons.map((button) => <SocialButton key={button.type} {...button} />);
  if (!socialButtons) return null;
  return <div className="SocialButtons">{buttons}</div>;
};

SocialButtons.propTypes = {
  socialButtons: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string.isRequired,
      alt: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    })
  ),
};

export default SocialButtons;
