import React from 'react';
import {components} from 'react-select';
import styled from 'styled-components';

const StyledDropdownIndicator = styled.span`
  &&&:after {
    content: '\\22C1';
  }
`;

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <StyledDropdownIndicator />
    </components.DropdownIndicator>
  );
};

export default DropdownIndicator;
