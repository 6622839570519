import {withLocalization} from '@epic-core/localization';
import {generateRouteTo} from 'epic-fortnite-react';
import LoginActions from 'epic-fortnite-react/actions/LoginActions';
import {generateMainSitePath} from 'epic-fortnite-react/utils/FortniteUtils';
import epicTracking from 'epic-tracking';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import root from 'window-or-global';

import {Device} from '../../constants';

import CopyrightWrapper from './CopyrightWrapper';
import CTAButton from './CTAButton';
import CTAButtonWrapper from './CTAButtonWrapper';
import FeatureImageContainer from './FeatureImageContainer';
import HeroSection from './HeroSection';
import ImageOverlay from './ImageOverlay';
import ResponsiveImage from './ResponsiveImage';
import {useLocation} from 'react-router';

const UAButton = styled(CTAButton)`
  line-height: 2.5;
  padding-right: 2em;
  padding-left: 2em;
  font-size: 4rem;

  @media only screen and ${Device.tablet} {
    font-size: 2rem;
  }
`;

/**
 * Presents a title, description and button for a call to action with a background image.
 */
const BackGroundCallToAction = ({getMessage, history, isLoggedIn, data, locale}) => {
  const location = useLocation();

  const signIn = React.useCallback(
    (url) => {
      if (isLoggedIn) {
        LoginActions.closeLoginModal();
        if (/^http/.test(url)) {
          root.location.href = url;
        } else {
          history.push(generateMainSitePath(url, locale));
        }
      } else {
        LoginActions.requestRegister({returnPath: generateMainSitePath(url, locale)});
      }
    },
    [isLoggedIn, history]
  );

  const onClick = React.useCallback(() => {
    epicTracking.trackEvent('Interaction', {
      interactionType: 'click',
      eventCategory: 'marketingPage022019',
      eventAction: 'playButton',
      eventLabel: 'playFreeNow',
    });
    if (data.ctaLoginRequired) {
      signIn(data.ctaLink);
      return;
    }
    const link = data.ctaLink || '/getfortnite';
    history.push(generateRouteTo(link, location, locale));
  }, [data]);

  const {
    cta,
    title,
    title1,
    description,
    titleColor = 'white',
    background,
    buttonAlign = 'bottom-left',
    copyright,
    descriptionFontColor = 'white',
    fontColor = 'white',
    exitLink,
    exitLinkText,
  } = data;

  const descriptionStyle = React.useMemo(() => {
    if (descriptionFontColor) {
      return {
        color: descriptionFontColor,
      };
    }
    return {};
  }, [data.descriptionFontColor]);

  const linkStyle = React.useMemo(
    () => ({
      ...descriptionStyle,
      fontWeight: 'bold',
    }),
    [descriptionStyle]
  );

  const ctaString = React.useMemo(
    () => cta || getMessage('epic.fortnite.battleRoyale.playFreeNow'),
    [cta]
  );

  return (
    <HeroSection backgroundColor={data.backgroundColor}>
      <FeatureImageContainer>
        <ResponsiveImage
          desktopSrc={background?.desktopImage}
          mobileSrc={background?.mobileImage}
          tabletSrc={background?.tabletImage}
        />
      </FeatureImageContainer>
      <ImageOverlay fontColor={fontColor}>
        {title && <h1 style={{color: titleColor}}>{title}</h1>}
        {title1 && <h1 style={{color: titleColor}}>{title1}</h1>}
        {description ? (
          <p style={descriptionStyle} dangerouslySetInnerHTML={{__html: description}} />
        ) : (
          ''
        )}
        <CTAButtonWrapper buttonAlign={buttonAlign}>
          <UAButton onClick={onClick}>{ctaString}</UAButton>
        </CTAButtonWrapper>
        {exitLink && exitLinkText ? (
          <p>
            <a style={linkStyle} href={exitLink}>
              {exitLinkText}
            </a>
          </p>
        ) : (
          ''
        )}
      </ImageOverlay>
      {copyright && (
        <CopyrightWrapper>
          <p>{copyright}</p>
        </CopyrightWrapper>
      )}
    </HeroSection>
  );
};

BackGroundCallToAction.propTypes = {
  data: PropTypes.object,
  mobile: PropTypes.bool,
  tablet: PropTypes.bool,
  isLoggedIn: PropTypes.bool,
  history: PropTypes.object,
  getMessage: PropTypes.func,
};

export default withLocalization(BackGroundCallToAction);
