import styled from 'styled-components';

import {Device} from '../../constants';

interface SubscriptionJoinWrapperProps {
  bgPatternImg?: string;
  bgGradientImg?: string;
  bgGradientColor1?: string;
  bgGradientColor2?: string;
  bgGradientColor3?: string;
}

const StyledSubscriptionJoinWrapper = styled.section<SubscriptionJoinWrapperProps>`
  width: 100%;
  position: relative;
  color: #fff;
  padding-top: 5em;
  padding-bottom: 5em;
  ${(props) => `
        background: ${props.bgGradientColor1};
        background: linear-gradient(15deg,
            ${props.bgGradientColor1} 0%,
            ${props.bgGradientColor2} 50%,
            ${props.bgGradientColor3} 100%);
    `}

  @media screen and ${Device.mobileL} {
    padding-bottom: 0;
  }
`;

export default StyledSubscriptionJoinWrapper;
