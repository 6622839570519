import styled from 'styled-components';

const Header = styled.h1`
  &&& {
    font-size: 3.5em;
  }
  text-align: center;
`;

export default Header;
