import React from 'react';
import {Container, Grid, GridColumn} from 'epic-fortnite-react';
import {CoreSection} from '../blocks/CoreSection';
import {CoreSectionProps} from '../bundles/types';
import ShareSection from '../blocks/ShareSection';
import {SocialShareOptions} from '../blocks/types';
import {SimpleTextWithBackgroundProps} from '../blocks/SimpleTextWithBackground';

export interface CenteredShareProps {
  coreSectionProps?: CoreSectionProps;
  textSection: SimpleTextWithBackgroundProps;
  condensed?: boolean;
  heading: string;
  shareOptions: SocialShareOptions[];
  shareLink: string;
  shareText: string;
  hasNativeSocialShare: boolean;
}

const CenteredShare = (props: CenteredShareProps) => {
  const {
    coreSectionProps,
    heading,
    shareOptions,
    condensed,
    shareLink,
    shareText,
    hasNativeSocialShare,
  } = props;

  return (
    <CoreSection coreSectionProps={coreSectionProps} condensed={condensed}>
      <Container maxWidth={1600} size="med">
        <Grid breakpoint={900} gap="2xl" reverseOrderMobile={true}>
          <GridColumn span={1}>
            <ShareSection
              heading={heading}
              shareOptions={shareOptions}
              shareLink={shareLink}
              shareText={shareText}
              hasNativeSocialShare={hasNativeSocialShare}
            />
          </GridColumn>
        </Grid>
      </Container>
    </CoreSection>
  );
};

export default CenteredShare;
