import {getLocale} from '@epic-core/common';
import {generateRouteTo} from 'epic-fortnite-react';
import {connectToStores} from 'epic-alt-utils';
import throttle from 'lodash.throttle';
import PropTypes from 'prop-types';
import React from 'react';
import Helmet from 'react-helmet';
import {Redirect, withRouter} from 'react-router';
import root from 'window-or-global';

import {BlogActions} from '../actions';
import {
    Alert,
    CategoryFilter,
    FeatureBanner,
    GridLayout,
    LoadIndicator,
    Message
} from '../components';
import {BlogStore} from '../stores';
import {getMetaTags, getTagsFromModel, getUniqueMetaData} from '../utils/metaUtils';
import {getRootPageSlug} from '../utils/UrlPropUtils';

let scrollListenerEnabled = false;

const fetchCategories = (category, rootPageSlug, defer) => {
    const getBlogOptions = {
        category: category || 'all',
        isCategoryFilter: !!category,
        rootPageSlug: getRootPageSlug(rootPageSlug)
    };
    if (defer) {
        return BlogActions.getBlogData.defer(getBlogOptions);
    }
    return BlogActions.getBlogData(getBlogOptions);
};

/**
 * BlogView
 * Rendered when the route matches /news or a specified root URL
 * @implements {Alert}
 * @implements {LoadIndicator}
 * @implements {GridLayout}
 * @implements {CategoryFilter}
 * @implements {FeatureBanner}
 * @implements {Message}
 * @reactProps {object} blogStore
 * @reactProps {bool} disableCategoryCount
 * @reactProps {object} match
 * @reactProps {object} alertMessage
 * @reactProps {bool} isEntitled
 * @reactProps {bool} isLauncher
 * @reactProps {bool} isLoggedIn
 * @reactProps {bool} showLoading
 * @reactProps {string} routerRootUrl 'news/'
 * @reactProps {string} linkUrlPrefix
 * @reactProps {string} rootPageSlug 'blog'
 * @reactProps {bool} enableTileChunking true
 * @reactProps {bool} hideFilter false
 * @reactProps {bool} enableScrollToLoad false
 * @reactProps {bool} initCategories true
 * @reactProps {boo} enableAutoShortDescription false
 * @reactProps {number} mobilePostNum
 */
@connectToStores
class BlogView extends React.Component {
    static getStores() {
        return [BlogStore];
    }

    static getPropsFromStores() {
        return {
            blogStore: BlogStore.getState()
        };
    }

    static getInitialActions() {
        return [
            (props) => {
                const routeCategory = props.match.params.category || 'all';
                const storeCategory = props.blogStore.category || 'all';
                if (props.initCategories !== false || storeCategory !== routeCategory) {
                    fetchCategories(
                        routeCategory,
                        props.rootPageSlug || BlogView.defaultProps.rootPageSlug
                    );
                }
            }
        ];
    }

    static defaultProps = {
        routerRootUrl: 'news',
        rootPageSlug: 'blog',
        disableCategoryCount: true,
        hideFilter: false,
        enableScrollToLoad: false,
        initCategories: true,
        hideLoadIndicator: false,
        categoryOverlay: true,
        mobilePostNum: 2
    };

    state = {
        onLoadMore: this.onLoadMore.bind(this),
        handleScroll: throttle(this.handleScroll.bind(this), 200)
    };

    static propTypes = {
        blogStore: PropTypes.object,
        disableCategoryCount: PropTypes.bool,
        match: PropTypes.object,
        alertMessage: PropTypes.object,
        isEntitled: PropTypes.bool,
        isLauncher: PropTypes.bool,
        isLoggedIn: PropTypes.bool,
        showLoading: PropTypes.bool,
        // Url used for react-router
        routerRootUrl: PropTypes.string,
        linkUrlPrefix: PropTypes.string,
        // Url used for CMS API back end
        rootPageSlug: PropTypes.string,
        hideFilter: PropTypes.bool,
        enableScrollToLoad: PropTypes.bool,
        hideLoadIndicator: PropTypes.bool,
        overrideMetaTags: PropTypes.array,
        initCategories: PropTypes.bool, //eslint-disable-line
        categoryOverlay: PropTypes.bool,
        mobilePostNum: PropTypes.number
    };

    goToRoot(props) {
        const locale = getLocale();
        const blogStore = props.blogStore;
        const blogTotal = blogStore.blogTotal;
        if (!blogStore.loadingArticles && blogTotal === 0) {
            const routerRootUrl = props.routerRootUrl;
            const linkUrlPrefix = this.props.linkUrlPrefix || '';

            let newPath = generateRouteTo(
                `${linkUrlPrefix}${routerRootUrl}`,
                props.location,
                locale
            );
            if (props.history.location.pathname !== newPath) {
                //TON-40182
                let tryCategoryMap = false;
                if (blogStore.catLocaleMap && typeof blogStore.catLocaleMap === 'object') {
                    const uriParts = props.history.location.pathname.split('/category/');
                    if (uriParts && uriParts.length) {
                        const categoryUri = uriParts[uriParts.length - 1];
                        for (const mapping in blogStore.catLocaleMap) {
                            if (
                                Object.prototype.hasOwnProperty.call(
                                    blogStore.catLocaleMap,
                                    mapping
                                )
                            ) {
                                const uri = encodeURIComponent(mapping);
                                if (uri === categoryUri || mapping === categoryUri) {
                                    newPath = generateRouteTo(
                                        `/category/${blogStore.catLocaleMap[
                                            mapping
                                        ].toLowerCase()}?localeMapping=true`,
                                        props.location,
                                        locale
                                    );
                                    tryCategoryMap = true;
                                    break;
                                }
                            }
                        }
                    }
                }

                if (props.history.location.search.indexOf('localeMapping=true') > -1) return;

                setTimeout(() => {
                    if (tryCategoryMap) {
                        props.history.replace(newPath);
                    } else {
                        props.history.replace(
                            generateRouteTo(routerRootUrl, props.location, locale)
                        );
                    }
                }, 0);
            }
        }
    }

    UNSAFE_componentWillMount() {
        this.goToRoot(this.props);
    }

    UNSAFE_componentWillUpdate(nextProps) {
        this.goToRoot(nextProps);
    }

    componentDidMount() {
        if (this.props.enableScrollToLoad) {
            this.addScrollListener();
        }
    }

    componentWillUnmount() {
        this.removeScrollListener();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        // refetch data once we detect the route param 'category' has been changed
        const newCategory = nextProps.match.params.category || 'all';
        const blogStore = nextProps.blogStore || {};
        const storeCategory = blogStore.category;

        if (newCategory !== storeCategory && newCategory !== blogStore.requestedCategory) {
            const rootPageSlug = nextProps.rootPageSlug || BlogView.defaultProps.rootPageSlug;
            fetchCategories(newCategory, rootPageSlug, true);
        }

        if (scrollListenerEnabled && !nextProps.enableScrollToLoad) {
            this.removeScrollListener();
        }

        if (!scrollListenerEnabled && nextProps.enableScrollToLoad) {
            this.addScrollListener();
        }
    }

    addScrollListener() {
        if (this.props.isLauncher) {
            root.document
                .getElementById('paragonReactWrapper')
                .addEventListener('scroll', this.state.handleScroll);
        } else {
            root.addEventListener('scroll', this.state.handleScroll);
        }
        scrollListenerEnabled = true;
    }

    removeScrollListener() {
        if (this.props.isLauncher) {
            root.document
                .getElementById('paragonReactWrapper')
                .removeEventListener('scroll', this.state.handleScroll);
        } else {
            root.removeEventListener('scroll', this.state.handleScroll);
        }
        scrollListenerEnabled = false;
    }

    // auto load when scrolled to the bottom of the page
    handleScroll(e, execute = false) {
        const scrollTop = root.pageYOffset || root.document.documentElement.scrollTop;
        const windowHeight = root.innerHeight;
        const body = root.document.body;
        const html = root.document.documentElement;
        const docHeight = Math.max(
            body.scrollHeight,
            body.offsetHeight,
            html.clientHeight,
            html.scrollHeight,
            html.offsetHeight
        );

        //on some screens scrollTop+windowHeight can be a few tenths of a pixel off so we subtract one
        if (scrollTop + windowHeight >= docHeight - 1) {
            // scrolled to the the bottom
            if (execute) {
                this.autoLoadMore();
            } else {
                setTimeout(() => {
                    this.handleScroll(e, true);
                }, 400);
            }
        }
    }

    autoLoadMore() {
        const {hasMoreArticles, loadingArticles, loading} = this.props.blogStore;
        const rootPageSlug = this.props.rootPageSlug;
        if (hasMoreArticles && !loadingArticles && !loading) {
            const category = this.props.match.params.category || 'all';
            BlogActions.getBlogData({
                category,
                loadMore: true,
                rootPageSlug: getRootPageSlug(rootPageSlug)
            });
        }
    }

    onLoadMore() {
        const category = this.props.match.params.category || 'all';
        const rootPageSlug = this.props.rootPageSlug;
        BlogActions.getBlogData({
            category,
            loadMore: true,
            rootPageSlug: getRootPageSlug(rootPageSlug)
        });
    }

    getFirstBlog() {
        const blogStore = this.props.blogStore || {};
        const topFeatured =
            blogStore.topFeatured && blogStore.topFeatured[0] ? blogStore.topFeatured[0] : null;
        if (!topFeatured) {
            const blogList = blogStore.blogList || [];
            const blogListMutable =
                blogList && typeof blogList.asMutable === 'function'
                    ? blogList.asMutable()
                    : blogList || [];
            blogListMutable.sort((a, b) => {
                const dateA = new Date(a.date);
                const dateB = new Date(b.date);

                if (dateA < dateB) return 1;
                if (dateA > dateB) return -1;
                return 0;
            });
            let firstBlog = null;
            let foundFirstStick = false;
            blogListMutable.forEach((blog) => {
                if (!firstBlog && blog.featured) firstBlog = blog;
                if (!foundFirstStick && blog.sticky && blog.featured) {
                    firstBlog = blog;
                    foundFirstStick = true;
                }
            });
            if (!firstBlog) firstBlog = blogListMutable[0];
            return firstBlog;
        }
        return topFeatured;
    }

    render() {
        const {location} = this.props;
        const blogStore = this.props.blogStore || {};
        const locale = getLocale();
        const categorySummary = blogStore.categoryTotals;
        const blogList = blogStore.blogList;
        const topFeatured = blogStore.topFeatured;
        const isLauncher = this.props.isLauncher;
        const isLoggedIn = this.props.isLoggedIn;
        const isEntitled = this.props.isEntitled;
        const alertMessage = this.props.alertMessage;
        const initLoading = this.props.showLoading;
        const rootPageSlug = this.props.rootPageSlug;
        const routerRootUrl = this.props.routerRootUrl;
        const linkUrlPrefix = this.props.linkUrlPrefix || '';
        const disableCategoryCount = this.props.disableCategoryCount;
        const routeCategory = this.props.match.params.category;
        const isCategoryFilter = blogStore.isCategoryFilter;
        const hideCategoryLink = (blogStore.config && blogStore.config.hideCategoryLink) || false;
        const hideAuthor = (blogStore.config && blogStore.config.hideAuthor) || false;
        const config = blogStore.config || {};
        const categoryOverlay = this.props.categoryOverlay;
        const mobilePostNum = this.props.mobilePostNum;

        // Return user to main if category does not exist
        if (routeCategory === 'all') {
            const redirectPath = generateRouteTo(
                {
                    pathname: routerRootUrl,
                    search: location.search
                },
                location,
                locale
            );
            return (
                <div id="redirect-link" data-redirect-path={redirectPath}>
                    <Redirect to={redirectPath} />
                </div>
            );
        }

        if (initLoading || isCategoryFilter) {
            return (
                <div className="blog-view-container">
                    <div className="blog-view">
                        {!this.props.hideLoadIndicator ? <LoadIndicator /> : ''}
                    </div>
                </div>
            );
        }
        let loadMoreButton;
        let loadingStyle = {display: 'none'};
        /**
         * Loading states and 'Load More' buttons
         */
        if (blogStore.loadingArticles) {
            loadingStyle = {display: 'block'};
            // show 'Load More' buttons if loading is complete
        } else if (blogStore.hasMoreArticles) {
            loadMoreButton = (
                <div className="load-more">
                    <button className="btn btn-primary btn-load" onClick={this.state.onLoadMore}>
                        <Message code="epic.blog.load_more" />
                    </button>
                </div>
            );
        } else {
            loadMoreButton = '';
        }

        /**
         * Topmost article (stickied to top of index)
         */
        const banner = topFeatured ? (
            <div key="blog-banner" className="top-featured-activity">
                <FeatureBanner
                    post={topFeatured[0]}
                    locale={locale}
                    routerRootUrl={routerRootUrl}
                    hideCategoryLink={hideCategoryLink}
                    linkUrlPrefix={linkUrlPrefix}
                    hideAuthor={hideAuthor}
                />
            </div>
        ) : (
            <div />
        );

        let alert = '';
        if (alertMessage) {
            alert = (
                <div key="blog-alert" className="blog-alert alert-wrapper container">
                    <Alert
                        message={alertMessage.message}
                        severity={alertMessage.severity}
                        display={alertMessage.display}
                        closeable={alertMessage.closeable}
                        isLauncher={isLauncher}
                        isEntitled={isEntitled}
                        isLoggedIn={isLoggedIn}
                    />
                </div>
            );
        }

        let filter;
        if (!this.props.hideFilter) {
            filter = (
                <CategoryFilter
                    key="blog-filter"
                    categorySummary={categorySummary}
                    currentCategory={blogStore.category}
                    locale={locale}
                    categoryOverlay={categoryOverlay}
                    routerRootUrl={routerRootUrl}
                    rootPageSlug={rootPageSlug}
                    disableCategoryCount={disableCategoryCount}
                />
            );
        }

        const blogs = (
            <GridLayout
                blogs={blogList}
                currentCategory={blogStore.category}
                locale={locale}
                mobilePostNum={mobilePostNum}
                routerRootUrl={routerRootUrl}
                linkUrlPrefix={linkUrlPrefix}
            />
        );

        // Generate blog listing and first blog meta data
        const blogListingMeta = getTagsFromModel(blogStore) || [];

        /**  Duplicate og:image and og:description content was showing in the meta data on Competitive News Page.
             Locatated the index of these unwanted values and removed them from the meta array.  */
        const unwantedOGImageIndex = 1;

        if (unwantedOGImageIndex >= 0 && unwantedOGImageIndex < blogListingMeta.length) {
            blogListingMeta.splice(unwantedOGImageIndex, 1);
        }

        const unwantedOGDescriptionIndex = 1;

        if (
            unwantedOGDescriptionIndex >= 0 &&
            unwantedOGDescriptionIndex < blogListingMeta.length
        ) {
            blogListingMeta.splice(unwantedOGDescriptionIndex, 1);
        }

        const firstBlog = this.getFirstBlog() || {};
        const firstBlogMeta = getMetaTags(locale, firstBlog, config.twitterDefaultTags) || [];
        let meta = blogListingMeta.concat(firstBlogMeta);

        // titleMetaTag finds value of twitter:title for SEO
        const titleMetaTag = meta.find((tag) => tag.name && tag.name.includes('twitter:title'));
        const title = titleMetaTag ? titleMetaTag.content : '';

        const ogDescriptionIndex = meta.findIndex((tag) => tag.property === 'og:description');
        const ogTitleIndex = meta.findIndex((tag) => tag.property === 'og:title');

        if (ogDescriptionIndex !== -1) {
            const twitterDescriptionTagIndex = meta.findIndex(
                (tag) => tag.name === 'twitter:description'
            );

            if (twitterDescriptionTagIndex !== -1) {
                const twitterDescriptionTag = meta[twitterDescriptionTagIndex];

                if (twitterDescriptionTag && twitterDescriptionTag.content) {
                    /** Updated the 'content' of 'og:description' with 'twitter:description',
                        as Fortnite News was showing the first blog article meta data */
                    meta[ogDescriptionIndex].content = twitterDescriptionTag.content;
                } else {
                    // Filter out the duplicate 'og:description' property
                    meta = meta.filter((tag) => !(tag.property === 'og:description'));
                }
            }
        }

        if (ogTitleIndex !== -1) {
            // Find the index of the 'twitter:title' tag
            const twitterTitleTagIndex = meta.findIndex((tag) => tag.name === 'twitter:title');

            if (twitterTitleTagIndex !== -1) {
                const twitterTitleTag = meta[twitterTitleTagIndex];

                if (twitterTitleTag && twitterTitleTag.content) {
                    // Update the 'content' of 'og:title' with the 'content' of 'twitter:title'
                    meta[ogTitleIndex].content = twitterTitleTag.content;
                }
            }
        }

        meta = getUniqueMetaData(meta);

        return (
            <div className="blog-view-container">
                <div className="blog-view">
                    <Helmet meta={meta} title={title} />
                    <div>
                        {banner}
                        {alert}
                        {filter}
                        {blogs}
                        <div className="loading-icon blog-loading" style={loadingStyle} />
                        {loadMoreButton}
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(BlogView);
