import {motion} from 'framer-motion';
import React from 'react';
import styled from 'styled-components';

const LoadoutImage = styled(({...rest}) => <motion.img {...rest} />)`
  width: 100%;
  height: auto;
`;

export default LoadoutImage;
