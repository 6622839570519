import {ContextMessage as Message} from '@epic-core/localization';
import classnames from 'classnames';
import HtmlTemplate from 'epic-fortnite-react/components/common/HtmlTemplate';
import PropTypes from 'prop-types';
import React from 'react';
import VisibilitySensor from 'react-visibility-sensor';

import ActualPrice from './ActualPrice';
import BonusText from './BonusText';
import BulletPoint from './BulletPoint';
import DiscountPercent from './DiscountPercent';
import LegalText from './LegalText';
import OldPriceInner from './OldPriceInner';
import PackBlockContainer from './PackBlockContainer';
import PackBuyButton from './PackBuyButton';
import Price from './Price';
import SubHeader from './SubHeader';
import Title from './Title';
import Verb from './Verb';

export default class PackBlock extends React.PureComponent {
  static propTypes = {
    storeStore: PropTypes.object,
    loginStore: PropTypes.object,
    isFounder: PropTypes.bool,
    isLoggedIn: PropTypes.bool,
    countryCode: PropTypes.string,
    legalTags: PropTypes.array,
    getMessage: PropTypes.func,
    packs: PropTypes.array,
  };

  state = {
    animated: false,
  };

  items = [];

  inView = (isVisible) => {
    if (isVisible && !this.state.animated) {
      this.setState({animated: true});
      TweenMax.staggerFromTo(
        this.items,
        0.5,
        {y: '+=100', autoAlpha: 0},
        {
          y: 0,
          autoAlpha: 1,
          ease: Power4.easeInOut,
          delay: 0.5,
        },
        0.5
      );
    }
  };

  render() {
    const {packs, storeStore} = this.props;
    if (!packs.length) return null;

    const packList = packs
      .map((pack) => {
        const {packSlug = ''} = pack;
        const detailConfig = storeStore.getConfigForSlug(packSlug);
        if (!detailConfig) {
          return null;
        }

        const detailAsset = storeStore.getOfferById(detailConfig.offerId);

        if (!detailAsset) {
          return null;
        }
        return {pack, detailAsset, detailConfig};
      })
      .filter((item) => !!item)
      .reduce((accum, {pack, detailAsset, detailConfig}, i) => {
        const {
          bgImg = '',
          bgImgAlt = '',
          content = '',
          img,
          img2,
          img2Alt = '',
          bgLight = '',
          bgDark = '',
          subHeader = '',
          subHeaderColor = '',
          bulletPoints = [],
          titleColor = '',
          verbColor = '',
          bonusText = '',
          bonusTextColor = '',
          legalText = '',
          legalTextColor = '',
          bgGradientImg = '',
          bgGradientMobileImg = '',
          priceColor = '',
          oldPriceColor = '',
          actualPriceColor = '',
          bulletPointsColor = '',
        } = pack;

        const {discounted, discountPrice, discountPercentage, price, actualValue, title, free} =
          detailAsset;

        const bgGradient = `(135deg, ${bgLight} 0%, ${bgDark} 100%)`;

        //sanitize Discount b/c safari is stupid
        const sanitizePrice = price[0] === '$' ? `$${price}` : price;

        const wrapperClassNames = classnames({
          'edition-pack-wrapper': true,
          'mtl-pack-wrapper': true,
        });

        return accum.concat(
          <PackBlockContainer
            className={wrapperClassNames}
            key={title}
            $bgGradientImg={bgGradientImg}
            $bgGradientMobileImg={bgGradientMobileImg}
            $bgGradient={bgGradient}
            $bgDark={bgDark}
            ref={(c) => {
              this.items[i] = c;
            }}>
            {discounted && (
              <DiscountPercent className="discount-percent">
                <Message
                  code="epic.fortnite.founder.percentOff"
                  args={[100 - discountPercentage]}
                />
              </DiscountPercent>
            )}
            <div className="edition-pack">
              <img className="character-img" src={bgImg} alt={bgImgAlt} />
              <Title $color={titleColor} className="pack-content__title">
                {title}
              </Title>
              {subHeader && (
                <SubHeader $color={subHeaderColor} className="pack-content__sub-header">
                  {subHeader}
                </SubHeader>
              )}
              <Verb $color={verbColor} className="verb">
                <Message code="epic.fortnite.founder.includes" />
              </Verb>
              {content ? (
                <div className="content-wrapper">
                  <img src={img} alt={title} />
                  <HtmlTemplate Tag="div" className="content" html={content} />
                </div>
              ) : (
                <div className="pack-content__wrapper">
                  <div className="pack-content__main">
                    <img src={img} alt={title} />
                    <ul className="pack-content__list">
                      {bulletPoints.map((bulletPoint) => (
                        <BulletPoint
                          $color={bulletPointsColor}
                          className="pack-content__list-item"
                          key={bulletPoint}>
                          {bulletPoint}
                        </BulletPoint>
                      ))}
                    </ul>
                  </div>
                  <BonusText $color={bonusTextColor} className="pack-content__bonus-text">
                    {bonusText}
                  </BonusText>
                </div>
              )}

              {discounted && (
                <div className="old-price">
                  <span className="outer">
                    <OldPriceInner className="inner" $color={oldPriceColor}>
                      <Message code="epic.stw.pack.was.price" args={[sanitizePrice]} />
                    </OldPriceInner>
                  </span>
                </div>
              )}
              <Price $color={priceColor} className="price">
                {free ? (
                  <Message code="epic.fortnite.common.free" />
                ) : discounted ? (
                  discountPrice
                ) : (
                  price
                )}{' '}
              </Price>
              <ActualPrice $color={actualPriceColor} className="actual-price">
                {actualValue && (
                  <Message
                    code="epic.fortnite.founders.value"
                    args={[actualValue.replace('$', '$$$')]}
                  />
                )}
              </ActualPrice>
              <img className="pack-content__image--mobile" src={img2} alt={img2Alt} />
              <PackBuyButton config={detailConfig} asset={detailAsset} {...this.props} />
              {legalText && (
                <LegalText $color={legalTextColor} className="pack-content__legal-text">
                  {legalText}
                </LegalText>
              )}
            </div>
          </PackBlockContainer>
        );
      }, []);

    return (
      <VisibilitySensor
        onChange={this.inView}
        active={!this.state.animated}
        partialVisibility
        minTopValue={30}
        scrollCheck
        scrollDelay={10}>
        <div className="edition-section">{packList}</div>
      </VisibilitySensor>
    );
  }
}
