import React from 'react';

import StatusCode from '../components/common/StatusCode';

/**
 * 404 component.  Shown when page not found.
 */
export default class Error404 extends React.Component {
    render() {
        return (
            <StatusCode code={404}>
                <div className="error404 errorPages">
                    <div className="content">
                        <div className="errorHeader">
                            {/* eslint-disable-next-line */ }
                            <h1>404 Page Not Found</h1>
                        </div>
                        <div className="errorBody">
                            <p>The requested page could not be found</p>
                        </div>
                    </div>
                </div>
            </StatusCode>
        );
    }
}

