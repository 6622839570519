import React from 'react';

export type CmsDynamicArray<T> = CmsDynamicMappingProps<T>[];

export interface CmsDynamicMappingProps<T> {
  [key: string]: T;
}

interface CmsComponentMapperProps<T> {
  dynamicPageDataArray?: CmsDynamicArray<T>;
  elementMapping?: {
    [key: string]: JSX.Element;
  };
  propKey?: string;
  itemSeparator?: JSX.Element;
}

export interface GenericCmsObjectProps {
  [key: string]: string | boolean | number | undefined | null;
}

/**
 * @deprecated Use CmsComponentMapper from @epic-mw/react-cms instead.
 */
export const CmsComponentMapper = <P extends any>(
  props: CmsComponentMapperProps<P>
): JSX.Element | null => {
  const {dynamicPageDataArray, elementMapping, propKey, itemSeparator} = props;

  if (
    !dynamicPageDataArray ||
    !dynamicPageDataArray.length ||
    !elementMapping ||
    !Object.keys(elementMapping).length
  ) {
    return <div className="fortnite-cms-map-not-found" />;
  }

  const numDynamicElements = dynamicPageDataArray.length;

  const elements: JSX.Element[] = [];
  let pageData;

  dynamicPageDataArray.forEach((item, i) => {
    // delete item._type;
    const itemLength = Object.keys(item).length;
    if (item && itemLength) {
      for (const key in item) {
        if (Object.prototype.hasOwnProperty.call(item, key)) {
          if (elementMapping[key]) {
            const element = elementMapping[key];
            const elementProps = element.props || {};
            pageData = item[key];

            const pageDataProps = propKey ? {[propKey]: pageData} : pageData;
            const afterSectionVerticalSpacerProvided =
              (i < numDynamicElements - 1 || i < itemLength - 1) && !!itemSeparator;

            elements.push(
              React.cloneElement(
                element,
                Object.assign(
                  {
                    key: `${key}-${i}`,
                    afterSectionVerticalSpacerProvided,
                    componentMapperIndex: i,
                    componentMapperItemLength: numDynamicElements,
                  },
                  pageDataProps,
                  elementProps
                )
              )
            );

            //place a vertical space separater between items
            if (afterSectionVerticalSpacerProvided && itemSeparator) {
              const itemSeparatorProps = itemSeparator.props || {};
              elements.push(
                React.cloneElement(
                  itemSeparator,
                  Object.assign({key: `${key}-${i}-separator`}, itemSeparatorProps)
                )
              );
            }
          }
        }
      }
    }
  });

  return <>{elements}</>;
};
