import styled from 'styled-components';

const CTAButton = styled.button`
  overflow: visible;
  color: #000000;
  line-height: 2;
  text-decoration: none;
  border: none;
  background-color: #ffe400;
  clip-path: polygon(0% 16%, 100% 6%, 98% 100%, 2% 90%);
  padding: 0.3875em 1em 0.125em 1em;
  ${({theme}) => theme.decoratedFontFamily};
  cursor: pointer;

  html[lang='ja'] & {
    span {
      font-size: 1.5rem;
    }
  }

  html[lang='fr'] & {
    span {
      font-size: 1.5rem;
    }
  }

  @supports (-webkit-touch-callout: none) {
    span {
      white-space: nowrap;
    }
  }
`;

export default CTAButton;
