import styled from 'styled-components';

import {Device} from '../../constants';

import Content from './Content';
import {ruCss} from './styles';

const Content40 = styled(Content)`
  && {
    max-width: 100%;
    max-height: 100%;
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0;
    margin: 0 auto 0 0;
    h3 {
      font-size: 4em;
      margin: 0;
    }
    p {
      padding: 0;
      ${({theme}) => theme.decoratedFontFamily}
      font-size: 1.25em;
      line-height: 1;
      max-width: 65%;
      @media screen and ${Device.tablet} {
        font-size: 3vw !important;
      }
      @media screen and ${Device.mobileL} {
        font-size: 1.5em !important;
      }
    }
    @media only screen and ${Device.tablet} {
      width: 80%;
      margin: 0 auto;
      text-align: center;
      margin: 4em auto;
      p {
        margin-top: 2em;
        max-width: 100%;
      }
    }
    h3 {
      @media screen and ${Device.tablet} {
        font-size: 6vw !important;
      }
      @media screen and ${Device.mobileL} {
        font-size: 7vw !important;
      }
      ${(props) => (props.theme.locale === 'ru' ? ruCss : '')}
    }
  }
`;

export default Content40;
