import styled from 'styled-components';

const LoadingTitle = styled.h3`
  color: #fff;
  margin: 0;
  width: 100%;
  text-align: center;
`;

export default LoadingTitle;
