import {getLocale} from '@epic-core/common';
import {datasource} from 'alt-utils/lib/decorators';
import {seamlessImmutable, seamlessBootstrap} from 'epic-alt-utils';
import alt from 'epic-fortnite-react/alt';
import Immutable from 'seamless-immutable';

import PageActions from '../actions/PageActions';
import PageSource from '../sources/PageSource';

@datasource(PageSource)
@seamlessImmutable
class PageStore {
  constructor() {
    this.on('error', (error) => {
      console.error(error);
    });
    this.on('bootstrap', seamlessBootstrap.bind(this, null));
    this.state = Immutable({
      pages: null,
      loading: false,
    });
    this.bindActions(PageActions);
  }

  /**
   * Get page
   * @param {string} page slug
   */
  onGetPage(slug) {
    if (!slug) return;
    if (this.state.pages && this.state.pages[slug]) return;
    this.getInstance().getPage(getLocale(), slug);
    this.setState(this.state.set('loading', true));
  }

  onGetPageSuccess(response) {
    const pages = {
      ...this.state.pages,
      ...(response.data && response.data.data),
    };
    this.setState(
      this.state.merge({
        loading: false,
        error: false,
        pages,
      })
    );
  }

  onGetPageFailure(error) {
    this.setState(
      this.state.merge({
        loading: false,
        pages: null,
        error: true,
      })
    );
  }
}

export default alt.createStore(PageStore, 'PageStore');
