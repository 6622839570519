import {connectToStores} from 'epic-alt-utils';
import PropTypes from 'prop-types';
import React from 'react';
import {withRouter} from 'react-router';
import root from 'window-or-global';

import {BlogActions} from '../actions';
import {BlogDetail} from '../components';
import ArticleRedirect from '../components/redirect/ArticleRedirect';
import {BlogStore} from '../stores';
import Entities from '../utils/Entities';
import {getRootPageSlug} from '../utils/UrlPropUtils';
import {withLocalization} from '@epic-core/localization';

const srcRegEx = /<script.* src="([^"\s]*).*<\/script>/gi;

const loadingMap = {};

const loadBlog = (props) => {
    const rootPageSlug = props.rootPageSlug || 'blog';
    const params = props.match.params;
    const title = params.title;
    if (loadingMap[title]) {
        //already loading/loaded
        return;
    }
    const locale = (props.i18n.locale || 'en-US').replace('en-US', 'en_US');
    const blogParams = {
        slug: title,
        locale,
        rootPageSlug: getRootPageSlug(rootPageSlug)
    };
    const customShareFunc = props.customShareFunc;
    if (customShareFunc) {
        blogParams.customShareFunc = customShareFunc;
    }
    const enableSocialMetrics = props.enableSocialMetrics;
    /** Only get social count if feature is enabled  */
    if (enableSocialMetrics) {
        BlogActions.getBlogSocialShareCount(blogParams);
    }
    loadingMap[title] = true;
    BlogActions.getBlogPost.defer(blogParams);
};

/**
 * ArticleView
 * Rendered for individual blog articles
 * @implements {BlogDetail}
 * @implements {LoadIndicator}
 * @reactProps object  blogStore
 * @reactProps object  location
 * @reactProps object  params
 * @reactProps bool  showLoading
 * @reactProps bool  enableComments
 * @reactProps string  rootPageSlug 'blog'
 * @reactProps string  routerRootUrl 'news/'
 * @reactProps string  commentNamespace 'B'
 * @reactProps bool  displayBlogDetailCategories false
 * @reactProps bool  enableContentToss fasle
 * @reactProps bool  enableLinkNavigator true
 * @reactProps bool  enableSocialMetrics false
 * @reactProps bool  isLauncher
 * @reactProps bool  initCategories
 * @reactProps bool  disableShareIcons false
 * @reactProps object  blog
 * @reactProps func  customConfigFunc
 * @reactProps func  customShareFunc
 * @reactProps object  componentMapping
 */
@connectToStores
class ArticleView extends React.Component {
    static defaultProps = {
        blogStore: {},
        rootPageSlug: 'blog',
        routerRootUrl: 'news',
        commentNamespace: 'B',
        displayBlogDetailCategories: false,
        enableContentToss: false,
        enableLinkNavigator: true,
        enableSocialMetrics: false,
        initCategories: true,
        disableShareIcons: false,
        hideLoadIndicator: false,
        overrideMetaTags: []
    };

    static propTypes = {
        blogStore: PropTypes.object,
        location: PropTypes.object,
        match: PropTypes.object,
        showLoading: PropTypes.bool,
        enableComments: PropTypes.bool,
        rootPageSlug: PropTypes.string,
        routerRootUrl: PropTypes.string,
        commentNamespace: PropTypes.string,
        displayBlogDetailCategories: PropTypes.bool,
        enableContentToss: PropTypes.bool,
        enableLinkNavigator: PropTypes.bool,
        enableSocialMetrics: PropTypes.bool,
        isLauncher: PropTypes.bool,
        initCategories: PropTypes.bool, //eslint-disable-line
        disableShareIcons: PropTypes.bool,
        hideLoadIndicator: PropTypes.bool,
        customConfigFunc: PropTypes.func, // customConfigFunc (blog) => return {config}
        //customShareFunc(blogParams, URL().BLOG.SOCIALSHARE) => return [url1, url2, url2]
        customShareFunc: PropTypes.func, // eslint-disable-uine
        overrideMetaTags: PropTypes.array,
        overrideMetaTitle: PropTypes.string,
        notFoundComponent: PropTypes.element,
        componentMapping: PropTypes.object,
        i18n: PropTypes.object
    };

    static getStores() {
        return [BlogStore];
    }

    static getPropsFromStores() {
        return {
            blogStore: BlogStore.getState()
        };
    }

    /**
     * Initial Actions
     * @emits {BlogStore#onGetBlogPost}
     * @emits {BlogStore#onGetBlogSocialShareCount}
     */
    static getInitialActions() {
        return [
            BlogActions.resetError,
            (props) => {
                if (props.initCategories !== false) {
                    loadBlog(props);
                }
            }
        ];
    }

    /**
     * On client side only it takes the _script field from the cms and looks for valid src attributes.
     * It then creates new script tags with the src so the browser loads them.  It comes from data preload
     * as html encoded so it must be decoded first.
     *
     * Example: _script = '<script async src="//platform.twitter.com/widgets.js" charset="utf-8"></script>';
     */
    handleCustomJavaScript(props) {
        if (root.__server_side_render) {
            return;
        }

        if (root.document) {
            const blogStore = props.blogStore || {};
            const blog = blogStore.blog || blogStore.getArticle(props.match.params.title);
            const encodedScripts = blog._script || null;

            if (encodedScripts) {
                const scriptsDecoded = Entities.decode(encodedScripts);
                if (typeof scriptsDecoded === 'string') {
                    const lines = scriptsDecoded.split('\n');
                    if (lines && lines.length) {
                        lines.forEach((line) => {
                            const matches = srcRegEx.exec(line);
                            srcRegEx.lastIndex = 0;
                            if (matches && matches[1]) {
                                const element = root.document.getElementById('articleView');
                                if (element) {
                                    const newScript = root.document.createElement('script');
                                    newScript.setAttribute('src', matches[1]);
                                    element.appendChild(newScript);
                                }
                            }
                        });
                    }
                }
            }
        }
    }

    componentDidMount() {
        this.handleCustomJavaScript(this.props);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const nextBlogStore = nextProps.blogStore || {};
        const nextBlog =
            nextBlogStore.blog || nextBlogStore.getArticle(nextProps.match.params.title);
        const blogStore = this.props.blogStore || {};
        const blog = blogStore.blog || blogStore.getArticle(this.props.match.params.title);

        if (nextBlog.slug && nextBlog.slug !== blog.slug) {
            this.handleCustomJavaScript(nextProps);
        }
        if (!nextBlog.slug && !blogStore.loading) {
            loadBlog(nextProps);
        }
    }

    render() {
        const blogStore = this.props.blogStore;
        const blog = blogStore.blog || blogStore.getArticle(this.props.match.params.title);
        const location = this.props.location;
        const config = blogStore.config || {};

        const blogList = blogStore.blogList;
        const enableComments = this.props.enableComments || this.props.blogStore.enableComments;
        const showLoading = this.props.showLoading || this.props.blogStore.loading;
        const routerRootUrl = this.props.routerRootUrl;
        const commentNamespace = this.props.commentNamespace;
        const displayBlogDetailCategories = this.props.displayBlogDetailCategories;
        const enableContentToss = this.props.enableContentToss;
        const enableLinkNavigator = this.props.enableLinkNavigator;
        const enableSocialMetrics = this.props.enableSocialMetrics;
        const overrideMetaTags = this.props.overrideMetaTags;
        const overrideMetaTitle = this.props.overrideMetaTitle;
        const rootPageSlug = this.props.rootPageSlug;
        const hideLoadIndicator = this.props.hideLoadIndicator;
        const notFoundComponent = this.props.notFoundComponent;
        const componentMapping = this.props.componentMapping;

        const detailProps = {
            blog,
            blogList,
            enableComments,
            commentCount: parseInt(this.props.blogStore.commentCount, 10),
            locale: this.props.match.params.locale,
            slug: this.props.match.params.title,
            routerRootUrl,
            commentNamespace,
            displayBlogDetailCategories,
            enableContentToss,
            enableLinkNavigator,
            enableSocialMetrics,
            disableShareIcons: this.props.disableShareIcons,
            isLauncher: this.props.isLauncher,
            config,
            rootPageSlug,
            overrideMetaTags,
            overrideMetaTitle,
            componentMapping
        };

        // customConfigFunc is a prop that can be used to pass configs for blogDetails from outside of the plugin
        // check UE for example
        if (this.props.customConfigFunc) {
            Object.assign(detailProps, this.props.customConfigFunc(blog));
        }

        return (
            <div className="article-view" id="articleView">
                <ArticleRedirect
                    blogStoreError={blogStore.error}
                    blog={blog}
                    location={location}
                    routerRootUrl={routerRootUrl}
                    hideLoadIndicator={hideLoadIndicator}
                    showLoading={showLoading}
                    showNotFound={!blog.title} // blog can be empty object so testing for property
                    notFoundComponent={notFoundComponent}>
                    <BlogDetail {...detailProps} />
                </ArticleRedirect>
            </div>
        );
    }
}

export default withRouter(withLocalization(ArticleView));
