import React, {useCallback} from 'react';
import {Redirect, Route} from 'react-router';
import root from 'window-or-global';

interface Props {
  to?: string;
  from?: string;
  status?: number;
  url?: string;
}
const RedirectWithStatus = ({to, from, status = 302, url}: Props) => {
  const redirectLocale = useCallback(
    (location) => {
      if (url) {
        root.location.href = `${url}${location.search}`;
        return;
      }

      const {pathname} = location;
      // get breakedown pathname
      const pathArray = pathname.split('/');
      // update the array with to value
      pathArray[pathArray.indexOf(from)] = to;
      // build pathname and pass into redirect
      const redirectUrl = `${pathArray.join('/')}${location.search}`;
      return <Redirect to={redirectUrl} />;
    },
    [to, from, url]
  );

  return (
    <Route
      render={({staticContext, location}) => {
        // there is no `staticContext` on the client, so
        // we need to guard against that here
        if (staticContext) staticContext.statusCode = status;
        return redirectLocale(location);
      }}
    />
  );
};
export default RedirectWithStatus;
