import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

/**
 * Alert Message.
 * @reactProps {string} message
 * @reactProps {string} severity
 * @reactProps {string} display
 * @reactProps {bool} isLauncher
 * @reactProps {bool} isEntitled
 * @reactProps {bool} isLoggedIn
 * @reactProps {bool} closeable
 */
class Alert extends React.Component {
    static propTypes = {
        message: PropTypes.string,
        severity: PropTypes.string,
        display: PropTypes.string,
        isLauncher: PropTypes.bool,
        isEntitled: PropTypes.bool,
        isLoggedIn: PropTypes.bool,
        closeable: PropTypes.bool
    };
    
    state = {
        show: true
    };
    
    onClose = () => {
        this.setState({
            show: false
        });
    };
    
    /**
     * Return whether show alert or not based on use case
     * @param  {string} display  Use case Options include: ['none', 'all', 'web', 'launcher', 'entitled', 'loggedIn']
     * @param  {bool} launcher Flag indicating whether client is launcher
     * @param  {bool} entitled Flag indicating whether user is entitled
     * @param  {bool} loggedIn Flag indicating whether use is logged in
     * @return {bool}          Whether should show alert message or not
     */
    showAlert(display, launcher, entitled, loggedIn) {
        return display !== 'none'
            && (display === 'all'
                || (display === 'web' && !launcher)
                || (display === 'launcher' && launcher)
                || (display === 'entitled' && entitled)
                || (display === 'loggedIn' && loggedIn));
    }
    
    render() {
        const {display, isLauncher, isEntitled, isLoggedIn, severity, closeable, message} = this.props;
        if (!this.state.show || !this.showAlert(display, isLauncher, isEntitled, isLoggedIn)) {
            return (
                <div />
            );
        }
        const iconClasses = classNames('alert-icon', `epic-blog-icon-${severity}`);
        const alertClasses = classNames('alert', `alert-${severity}`);
        const messageClasses = classNames({'col-xs-10': closeable, 'col-xs-11': !closeable});
        return (
            <div className="alert-container">
                <div className={alertClasses}>
                    <div className="row">
                        <div className="col-xs-1 text-center">
                            <span className={iconClasses} />
                        </div>
                        <div className={messageClasses}>
                            <p>{message}</p>
                        </div>
                        {closeable ? (
                            <div className="col-xs-1">
                                <button type="button" className="close" onClick={this.onClose} aria-label="Close">
                                    <i className="epic-blog-icon-close" />
                                </button>
                            </div>
                        ) : ''}
                    </div>
                </div>
            </div>
        );
    }
}

export default Alert;
