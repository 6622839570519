import {getLocale} from '@epic-core/common';
import {datasource} from 'alt-utils/lib/decorators';
import {seamlessBootstrap, seamlessImmutable} from 'epic-alt-utils';
import alt from 'epic-fortnite-react/alt';
import Immutable from 'seamless-immutable';

import CosplayMaskActions from '../actions/CosplayMaskActions';
import CosplayMaskSource from '../sources/CosplayMaskSource';
import {shouldCacheState} from '../utils/storeUtils';

@datasource(CosplayMaskSource)
@seamlessImmutable
class CosplayMaskStore {
  constructor() {
    this.on('error', console.error);
    this.on('bootstrap', seamlessBootstrap.bind(this, null));
    this.state = Immutable({});
    this.bindActions(CosplayMaskActions);
  }

  onGetMask(mask) {
    if (shouldCacheState(this.state[mask])) return;
    this.getInstance().getMask(getLocale(), mask);
    this.setState(
      this.state.merge({
        [mask]: {
          error: false,
          data: undefined,
          loading: true,
        },
      })
    );
  }

  onGetMaskSuccess(response) {
    const mask = response.data._slug;
    if (response.data.error) {
      return this.setState(
        this.state.merge({
          [mask]: {
            loading: false,
            error: response.data.error,
            data: undefined,
          },
        })
      );
    }

    this.setState(
      this.state.merge({
        [mask]: {
          loading: false,
          error: false,
          data: response.data,
        },
      })
    );
  }

  onGetMaskFailure(error) {
    if (error.payload?.data) {
      const mask = error.payload.data;
      this.setState(
        this.state.merge({
          [mask]: {
            loading: false,
            data: null,
            error: true,
          },
          error: true,
        })
      );
    } else {
      this.setState({error: true});
    }
  }
}

export default alt.createStore(CosplayMaskStore, 'CosplayMaskStore');
