import {generateRouteTo} from '../../utils/route';
import classNames from 'classnames';
import {BingBranding} from 'epic-react-search';
import {Themes} from 'epic-react-search';
import PropTypes from 'prop-types';
import React from 'react';
import {withRouter} from 'react-router';
import {Link} from 'react-router-dom';

import SearchActions from '../../actions/SearchActions';

import BingResult from './BingResult';

class BingResultsList extends React.Component {
  static propTypes = {
    searchStore: PropTypes.object,
    localizationStore: PropTypes.object,
    query: PropTypes.string,
    filter: PropTypes.string,
    locale: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.state = {
      query: props.query ? props.query : '',
    };
  }

  /**
   * Event handler for form field input
   */
  handleInput = (evt) => {
    this.setState({
      query: evt.target.value,
    });
  };

  handleEnterSubmit = (evt) => {
    if (evt.keyCode === 13) {
      this.handleSubmit();
    }
  };

  /**
   * Event handler for submitting form with Enter key
   */
  handleSubmit = () => {
    const {filter, location, locale} = this.props;
    this.props.history.push(
      generateRouteTo(
        {
          path: '/search',
          search: `?keyword=${this.state.query}&offset=0&filter=${encodeURIComponent(filter)}`,
        },
        location,
        locale
      )
    );
  };

  handleNextClick = () => {
    const {filter, location, locale} = this.props;
    this.props.history.push(
      generateRouteTo(
        {
          path: '/search',
          search: `?keyword=${this.state.query}&offset=${
            this.props.searchStore.nextOffset
          }&filter=${encodeURIComponent(filter)}`,
        },
        location,
        locale
      )
    );
  };

  handlePrevClick = () => {
    const {filter, location, locale} = this.props;
    this.props.history.push(
      generateRouteTo(
        {
          path: '/search',
          search: `?keyword=${this.state.query}&offset=${
            this.props.searchStore.prevOffset
          }&filter=${encodeURIComponent(filter)}`,
        },
        location,
        locale
      )
    );
  };

  componentDidMount() {
    if (this.state.query) {
      const {offset, filter, locale} = this.props;
      SearchActions.bingSearch({query: this.state.query, offset, filter, locale});
    }
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (
      this.props.query !== newProps.query ||
      this.props.offset !== newProps.offset ||
      this.props.filter !== newProps.filter
    ) {
      const {query, offset, filter, locale} = newProps;
      this.setState({
        query,
      });
      SearchActions.bingSearch({query, offset, filter, locale});
    }
  }

  generateCustomFilters(searchFilters, currentFilter = '') {
    const {location, locale} = this.props;
    let jsx = null;
    if (searchFilters.length) {
      const elements = searchFilters.map((filter) => {
        const {filterName} = filter;
        const linkObj = generateRouteTo(
          {
            pathname: '/search',
            search: `?keyword=${this.state.query}&offset=0&filter=${encodeURIComponent(
              filterName
            )}`,
          },
          location,
          locale
        );
        const isActive = filterName === currentFilter;
        return (
          <li className={`search-filter-element ${isActive ? 'active' : ''}`} key={filterName}>
            <Link to={linkObj}>{filterName}</Link>
          </li>
        );
      });
      jsx = <div className="search-filters">{elements}</div>;
    }
    return jsx;
  }

  render() {
    const {enableCustomFilter, searchFilters = {}, messages = {}, filter} = this.props;
    const {bingResults, config, hasMoreResults, offset, loading, bingFirstRun} =
      this.props.searchStore;
    let customFilters;
    if (enableCustomFilter || config.enableCustomFilter) {
      customFilters = this.generateCustomFilters(
        searchFilters || config.searchFilters || [],
        filter
      );
    }

    let nextPage = null;
    // show/hide next page button
    if (hasMoreResults) {
      nextPage = (
        <button className="nextResults" onClick={this.handleNextClick}>
          <span className="fa fa-angle-right" />
        </button>
      );
    }
    let prevPage = null;
    // show/hide prev page button
    if (offset) {
      prevPage = (
        <button className="prevResults" onClick={this.handlePrevClick}>
          <span className="fa fa-angle-left" />
        </button>
      );
    }
    const bingPagination = (
      <div className="pagination">
        {prevPage}
        {nextPage}
      </div>
    );

    let bingResultContent;
    // loading animation
    const showNoResults = bingResults && !bingResults.length && !bingFirstRun;
    const showResults = bingResults && bingResults.length;
    const showBingBranding = (showResults || showNoResults) && !loading;
    const noResultClass = !loading && showNoResults ? 'no-result' : '';
    if (loading) {
      bingResultContent = <div className="bingLoader" />;
      // display bing search results
    } else if (bingResults) {
      // show "no results"
      if (showNoResults) {
        bingResultContent = <p className="noResults">{messages['epic.common.no_results']}</p>;
        // show results
      } else if (showResults) {
        bingResultContent = bingResults.map((result, index) => (
          <BingResult
            title={result.name}
            url={result.url}
            displayUrl={result.displayUrl}
            description={result.snippet}
            key={result.url}
          />
        ));
      }
    }
    return (
      <div className="bingResults">
        <div className="bingSearchBar" id="bingSearchBar">
          <input
            type="text"
            value={this.state.query}
            onChange={this.handleInput}
            onKeyDown={this.handleEnterSubmit}
          />
          <button onClick={this.handleSubmit}>{messages['epic.common.search']}</button>
        </div>
        {customFilters}
        <div className={classNames(noResultClass)}>
          {bingResultContent}
          {showBingBranding && <BingBranding theme={Themes.LIGHT} />}
        </div>
        {!loading && bingPagination}
      </div>
    );
  }
}

export default withRouter(BingResultsList);
