import React from 'react';

import Error404 from './Error404';
import Error500 from './Error500';

interface ErrorProps {
  statusCode: number;
}

const HttpErrorScreen = ({statusCode}: ErrorProps) => {
  switch (statusCode) {
    case 404:
      return <Error404 />;
    case 500:
      return <Error500 />;
    default:
      return <Error500 />;
  }
};

export default HttpErrorScreen;
