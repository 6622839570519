import Proptypes from 'prop-types';
import React from 'react';

import ScheduleItem from './ScheduleItem';
import ScheduleListHeader from './ScheduleListHeader';

const initialCountries = [
  'ES',
  'AU',
  'RU',
  'PL',
  'SE',
  'PE',
  'IE',
  'CH',
  'NZ',
  'AT',
  'NO',
  'GR',
  'RO',
  'CZ',
  'HU',
  'TR',
  'UA',
  'BG',
  'SK',
  'HR',
  'RE',
  'SI',
  'GT',
  'CY',
  'LU',
  'MA',
  'DO',
  'PK',
  'SV',
  'RS',
  'LT',
  'EE',
  'IS',
];

const localDate = (date) => {
  if (!date) {
    return;
  }
  const splitDate = date.split(/[^0-9]/);
  if (splitDate.length > 2) {
    splitDate[1] -= 1;
    return new Date(...splitDate);
  }
};

const ScheduleList = ({data, countryStore}) => {
  const [country, setCountry] = React.useState('');
  const {
    header,
    subHeader,
    artistSchedule,
    columnCount,
    selectCountry,
    noShowings,
    selectPlaceHolder,
  } = data;

  // Convert countries json encoded string field into array of country codes.
  const convertedSchedule = React.useMemo(
    () =>
      artistSchedule
        .map((item) => {
          if (!item.countries) return item;
          try {
            // Ensure user entered country codes are upper case.
            const countries = JSON.parse(item.countries).map((country) => country.toUpperCase());
            return {
              ...item,
              countries,
            };
          } catch (error) {
            // Ignore.
          }
          return item;
        })
        .filter((item) => {
          const {date, maxLocalDate, minLocalDate} = item;
          const scheduledDate = new Date(date);
          const earliestDate = localDate(minLocalDate);
          const latestDate = localDate(maxLocalDate);
          if (
            (latestDate && scheduledDate > latestDate) ||
            (earliestDate && scheduledDate < earliestDate)
          ) {
            return false;
          }
          return true;
        }),
    [artistSchedule]
  );

  // Build a list of all countries available to filter by.
  const availableCountries = React.useMemo(() => {
    const available =
      convertedSchedule &&
      convertedSchedule.asMutable().reduce((acc, item) => {
        if (!item.countries || !Array.isArray(item.countries)) return acc;
        const newCountries = item.countries.filter((country) => !acc.includes(country));
        return [...acc, ...newCountries];
      }, []);
    if (available.length === 0) {
      return null;
    }
    const disabled = initialCountries.filter((country) => !available.includes(country));
    return [...disabled, ...available]
      .map((country) => ({
        value: country,
        label: countryStore[country],
        disabled: disabled.includes(country),
      }))
      .sort((a, b) => (a.label > b.label ? 1 : b.label > a.label ? -1 : 0));
  }, [data]);

  // If country is set, only show listings with that country.
  const scheduleList = React.useMemo(() => {
    if (availableCountries && availableCountries.length && !country) {
      return selectCountry;
    }
    const results = convertedSchedule
      .filter(
        (item) =>
          !country || !item.countries || (item.countries && item.countries.includes(country))
      )
      .map((item, index) => (
        <ScheduleItem key={`poster${index}`} data={item} columnCount={columnCount} />
      ));
    if (results.length === 0) {
      return noShowings;
    }
    return results;
  }, [convertedSchedule, country]);

  if (!artistSchedule || !artistSchedule.length) return null;

  return (
    <div className="ScheduleList">
      <ScheduleListHeader
        header={header}
        subHeader={subHeader}
        availableCountries={availableCountries}
        setCountry={setCountry}
        placeholder={selectPlaceHolder}
      />
      <div className="container-fluid">{scheduleList}</div>
    </div>
  );
};

const StyledTextProps = Proptypes.shape({
  text: Proptypes.string,
  color: Proptypes.string,
  theme: Proptypes.string,
});

ScheduleList.propTypes = {
  data: Proptypes.shape({
    noShowings: Proptypes.string,
    selectCountry: Proptypes.string,
    selectPlaceHolder: Proptypes.string,
    artistSchedule: Proptypes.arrayOf(
      Proptypes.shape({
        name: Proptypes.string,
        date: Proptypes.oneOfType([Proptypes.instanceOf(Date), Proptypes.string]),
        maxLocalDate: Proptypes.oneOfType([Proptypes.instanceOf(Date), Proptypes.string]),
        minLocalDate: Proptypes.oneOfType([Proptypes.instanceOf(Date), Proptypes.string]),
        moreInfo: Proptypes.string,
        moreInfoUrl: Proptypes.string,
        column2: Proptypes.string,
        countries: Proptypes.string,
      })
    ),
    header: StyledTextProps,
    subHeader: StyledTextProps,
    columnCount: Proptypes.string,
  }).isRequired,
  countryStore: Proptypes.object.isRequired,
};

export default ScheduleList;
