import {getLocale} from '@epic-core/common';
import {datasource} from 'alt-utils/lib/decorators';
import {seamlessImmutable, seamlessBootstrap} from 'epic-alt-utils';
import alt from 'epic-fortnite-react/alt';
import Immutable from 'seamless-immutable';

import BattlePassActions from '../actions/BattlePassActions';
import BattlePassSource from '../sources/BattlePassSource';

@datasource(BattlePassSource)
@seamlessImmutable
class BattlePassStore {
  constructor() {
    this.on('error', (error) => {
      console.error(error);
    });
    this.on('bootstrap', seamlessBootstrap.bind(this, null));

    this.state = Immutable({
      loading: true,
      data: null,
      error: null,
    });
    this.bindActions(BattlePassActions);
  }

  onGetPage() {
    if (this.state.data) return;
    this.getInstance().getPage(getLocale());
    this.setState(
      this.state.merge({
        loading: true,
        data: null,
        error: null,
      })
    );
  }

  onGetPageSuccess(response) {
    this.setState(
      this.state.merge({
        loading: false,
        error: null,
        data: response.data,
      })
    );
  }

  onGetPageFailure(err) {
    const statusCode = err.response?.status ?? 500;

    this.setState(
      this.state.merge({
        loading: false,
        data: null,
        error: {statusCode},
      })
    );
  }
}

export default alt.createStore(BattlePassStore, 'BattlePassStore');
