import {ContextMessage as Message} from '@epic-core/localization';
import PropTypes from 'prop-types';
import React from 'react';

class RetryIndicator extends React.PureComponent {
  render() {
    return (
      <div className="load-indicator">
        <div className="container">
          <div className="row">
            <div className="logo" />
            <div className="beacon-container">
              <div className="beacon" />
              <div className="triangle" />
            </div>
            <h6 className="load-text">
              <button className="btn btn-primary btn-load" onClick={this.props.retry}>
                <Message code="epic.common.retry" />
              </button>
            </h6>
          </div>
        </div>
      </div>
    );
  }
}

RetryIndicator.propTypes = {
  retry: PropTypes.func,
};

export default RetryIndicator;
