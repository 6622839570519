import React from 'react';

import {Container, Section, SectionProps} from '../';
import {Spaces} from '../../../types/theme';

interface BoxProps extends SectionProps {
  sizeX?: Spaces;
  sizeY?: Spaces;
}

export const Box = ({children, size, sizeX, sizeY, ...props}: BoxProps) => {
  return (
    <Section size={sizeY || size} {...props}>
      <Container size={sizeX || size}>{children}</Container>
    </Section>
  );
};
