import React from 'react';

import CountriesContentContext, {
  CountriesContentContextType,
  Option,
} from './CountriesContentContext';

export const getContent = (country, language, countriesContent) => {
  if (!country || !language || !countriesContent) {
    return '';
  }
  const content = countriesContent
    .filter((item) => item.country === country && item.lang === language)
    .reduce((acc, item) => {
      if (acc) return acc;
      if (item && item.content) return item.content;
      return '';
    }, '');
  if (content) return content;
  const countryOnly = countriesContent
    .filter((item) => item.country === country)
    .reduce((acc, item) => {
      if (acc) return acc;
      if (item && item.content) return item.content;
      return '';
    }, '');
  if (countryOnly) return countryOnly;
  // No terms found, default to us/en_US.
  return countriesContent
    .filter((item) => item.country === 'us' && item.lang === 'en_US')
    .reduce((acc, item) => {
      if (acc) return acc;
      if (item && item.content) return item.content;
      return '';
    }, '');
};

export const getLanguage = (country, defaultCountry, countriesLanguagesMap, defaultLanguage) => {
  if (!countriesLanguagesMap) return '';
  if (defaultCountry === country) {
    return defaultLanguage;
  }
  if (countriesLanguagesMap[country]?.length) {
    return countriesLanguagesMap[country][0];
  }
  return '';
};

export const getLanguages = (country, countriesLanguagesMap, getMessage): Option[] => {
  if (!countriesLanguagesMap || !countriesLanguagesMap[country]) return countriesLanguagesMap['us'];
  return countriesLanguagesMap[country].map((item) => {
    return {
      value: item,
      label: getMessage(`epic.header.locale.name.${item}`) || item,
    };
  });
};

export const getInitCountry = (countries, country) => {
  if (!countries) return null;
  if (country) {
    const result = countries.filter((item) => item.value === country);
    if (result.length) return result[0];
  }
  const defaultResult = countries.filter((item) => item.value === 'us');
  if (defaultResult.length) return defaultResult[0];
  return countries[0];
};

export const getInitLanguage = (country, countries, defaultLanguage, defaultCountry, languages) => {
  if (!languages) return null;
  if (country === defaultCountry) {
    const result = languages.filter((item) => item.value === defaultLanguage);
    if (result.length) return result[0];
  }
  return languages && languages.length ? languages[0] : null;
};

const useCountryContentSelector = (
  defaultCountry: string,
  defaultLanguage: string,
  getMessage: (code) => string
) => {
  const {countries, countriesLanguagesMap, countriesContent}: CountriesContentContextType =
    React.useContext(CountriesContentContext);
  const initCountryState = React.useMemo(
    () => (countriesLanguagesMap && countriesLanguagesMap[defaultCountry] ? defaultCountry : 'us'),
    [countriesLanguagesMap, defaultCountry]
  );
  const [country, setCountry] = React.useState(initCountryState);

  // Generate languages options.
  const languages: Option[] = React.useMemo(
    () => getLanguages(country, countriesLanguagesMap, getMessage),
    [country, countriesLanguagesMap, getMessage]
  );

  const initLanguageState = React.useMemo(
    () =>
      languages.filter((item) => item.value === defaultLanguage).length ? defaultLanguage : 'en_US',
    [languages, defaultLanguage]
  );
  const [language, setLanguage] = React.useState(initLanguageState);

  // If country changes, reset language to first available language.
  React.useEffect(() => {
    const lang = getLanguage(country, defaultCountry, countriesLanguagesMap, initLanguageState);
    if (language !== lang) setLanguage(lang);
  }, [country, countriesLanguagesMap, initLanguageState]);

  const content = React.useMemo(
    () => getContent(country, language, countriesContent),
    [country, language, countriesContent]
  );
  const initCountry = React.useMemo(() => getInitCountry(countries, country), [countries, country]);
  const initLanguage = React.useMemo(
    () => getInitLanguage(country, countries, initLanguageState, defaultCountry, languages),
    [country, countries, defaultCountry, defaultLanguage, languages]
  );
  return {initCountry, initLanguage, setCountry, setLanguage, content, countries, languages};
};

export default useCountryContentSelector;
