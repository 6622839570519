import React from 'react';
import styled from 'styled-components';

import CountrySelect from './LanguageSelect';

const StyledLanguageSelect = styled(CountrySelect)`
  width: 80%;
  max-width: 450px;
  margin: 3em 0;
  z-index: 1000;
  margin: 0 auto;
  div {
    border-radius: unset;
    z-index: 1000;
  }
`;

const StyledLanguageSelectExtended = (props) => <StyledLanguageSelect {...props} />;

export default StyledLanguageSelectExtended;
