import React from 'react';

export interface Option {
  value: string;
  label: string;
}

interface CountriesLanguagesMap {
  [country: string]: string[];
}

interface CountriesContent {
  lang: string;
  country: string;
  content: string;
}

export interface CountriesContentContextType {
  countries?: Option[];
  countriesLanguagesMap?: CountriesLanguagesMap;
  countriesContent?: CountriesContent[];
}

export const value = {};

export const CountriesContentContext = React.createContext(value);

CountriesContentContext.displayName = 'CountriesContentContext';

export default CountriesContentContext;
