import styled from 'styled-components';

const ImageOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  padding: 0 1rem;
  font-size: 1.2em;

  display: flex;
  flex-direction: column;
  align-items: center;

  &&& h2 {
    line-height: 1.1;
  }

  & > {
    z-index: 1;
  }
`;

export default ImageOverlay;
