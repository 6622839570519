import styled from 'styled-components';

const LoadingIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  height: 100%;

  & .loading-icon {
    width: 4rem;
    height: 4rem;
  }
`;

export default LoadingIconWrapper;
