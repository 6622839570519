import {motion} from 'framer-motion';
import React from 'react';
import styled, {css} from 'styled-components';

import {Device} from '../../../constants';

const Heroes = {
  joker: css`
    font-size: 12vw !important;
    text-align: center;
    @media screen and ${Device.mobileL} {
      font-size: 7em !important;
    }
  `,
  midas: css`
    font-size: 11vw !important;
    text-align: center;
    @media screen and ${Device.mobileL} {
      font-size: 7em !important;
    }
  `,
  poisonivy: css`
    top: -7%;
    font-size: 11vw !important;
    text-align: center;
    @media screen and ${Device.mobileL} {
      font-size: 7em !important;
    }
  `,
};

const HeroName = styled(({...rest}) => <motion.h1 {...rest} />)`
  color: #fff;
  position: relative;
  top: -10%;
  font-style: italic;
  mix-blend-mode: soft-light;
  white-space: nowrap;
  line-height: 1 !important;
  ${(props) => Heroes[props.keyId]}

  @media screen and (max-width: 940px) {
    top: 25%;
    padding: 0 1em;
  }
`;

export default HeroName;
