import HttpErrorScreen from 'epic-fortnite-react/components/error/HttpErrorScreen';
import LoadScreen from 'epic-fortnite-react/components/loadScreen/LoadScreen';
import {getInitialCmsPageSharedValues, useCmsData, PageData} from 'epic-fortnite-shared-values';
import React from 'react';
import styled from 'styled-components';

import MultiPlatformStream from '../../components/streaming/MultiPlatformStream';

import BlackoutCtaBar from './BlackoutCtaBar';

/*
  CMS Controlled "takeover" view.
  - Toggled in the CMS at /downtime
  - Page data fetched in the CMS at /blackout
  There are separate implentations for competitive and FN Core which allows a takeover for one or both apps.
  Each CMS environment contains the same /downtime and /blackout pages.
  When 'twitch' is the selected `streamPlatform`, `stream` should be an account name for the twitch stream.
  When 'youtube' is the selected `streamPlatform`,  `stream` should be a livestream id.
*/

const SLUG = 'blackout';

const StyledBlackoutContainer = styled.div`
  position: relative;
  max-height: 95vh;
  @media only screen and (max-width: 800px) {
    position: unset;
  }
`;

export interface BlackoutViewPageData extends PageData {
  stream?: string;
  streamPlatform?: 'twitch' | 'youtube';
  ctaBar: BlackoutCtaProps;
}

interface BlackoutCtaProps {
  isActive: boolean;
  link?: string;
  text?: string;
  bgColor?: string;
  accentColor?: string;
}

const BlackoutView = () => {
  const {pageData, isLoading, error} = useCmsData<BlackoutViewPageData>(SLUG);

  if (isLoading || !pageData.stream) return <LoadScreen show={true} />;
  if (error) return <HttpErrorScreen statusCode={500} />;
  return (
    <StyledBlackoutContainer>
      <MultiPlatformStream
        platform={pageData?.streamPlatform ?? 'twitch'}
        stream={pageData?.stream ?? 'Fortnite'}
      />
      {pageData?.ctaBar?.isActive && <BlackoutCtaBar config={pageData?.ctaBar} />}
    </StyledBlackoutContainer>
  );
};

BlackoutView.getInitialSharedValues = getInitialCmsPageSharedValues(SLUG);

export default BlackoutView;
