import React from 'react';

import Container from './components/Container';
import DifficultyInfoWithMaskType from './components/DifficultyInfoWithMaskType';
import DownloadButton from './components/DownloadButton';
import DownloadImageWrapper from './components/DownloadImageWrapper';
import Grid from './components/Grid';
import ResponsiveImage from './components/ResponsiveImage';
import Section from './components/Section';
import {SocialShareProps} from './components/SocialShare';
import {Background, DownloadCTA, ResponsiveImage as IResponsiveImage} from './types';

export interface MaskDownloadSectionProps {
  header?: string;
  type?: string;
  time?: string;
  difficulty?: string;
  description?: string;
  cta?: DownloadCTA;
  background?: Background;
  image?: IResponsiveImage;
  accent?: string;
  socialShare?: SocialShareProps['socialShare'];
}

const MaskDownloadSection = ({
  header,
  type,
  time,
  difficulty,
  description,
  cta,
  background,
  image,
  accent,
  socialShare = undefined,
}: MaskDownloadSectionProps) => {
  const button = cta ? (
    <DownloadButton
      id={`${cta.text}-${cta.link}`}
      link={cta.link}
      text={cta.text}
      background={accent}
    />
  ) : undefined;
  return (
    <Section gradient={background}>
      <Container>
        <Grid>
          <DownloadImageWrapper>
            <ResponsiveImage
              desktopSrc={image?.desktopImage}
              tabletSrc={image?.tabletImage}
              mobileSrc={image?.mobileImage}
              alt={image?.altText}
            />
          </DownloadImageWrapper>
          <DifficultyInfoWithMaskType
            button={button}
            name={header}
            description={description}
            type={type}
            time={time}
            difficulty={difficulty}
            accent={accent}
            socialShare={socialShare}
          />
        </Grid>
      </Container>
    </Section>
  );
};
export default MaskDownloadSection;
