import {generateRouteHref, generateRouteTo} from 'epic-fortnite-react';
import classNames from 'classnames';
import epicTracking from 'epic-tracking';
import PropTypes from 'prop-types';
import React from 'react';
import {withRouter, Redirect} from 'react-router';
import semver from 'semver';
import root from 'window-or-global';

import AndroidDownloadNewFlow from './AndroidDownloadNewFlow';
import EGSDownloadImage from './EGSDownloadImage';

class AndroidDownload extends React.Component {
  static propTypes = {
    data: PropTypes.object,
    isAndroid: PropTypes.bool,
    link: PropTypes.string,
    oldFlow: PropTypes.bool,
    newFlow: PropTypes.bool,
    notSupported: PropTypes.bool,
    osVersion: PropTypes.string,
    supportedDevices: PropTypes.object,
    location: PropTypes.object,
    locale: PropTypes.string,
  };

  state = {
    show: false,
  };

  componentDidMount() {
    const {link, notSupported, isAndroid} = this.props;
    if (isAndroid && !notSupported) {
      setTimeout(() => {
        root.location = link;
      }, 1000);
    }
  }

  trackClick(title) {
    epicTracking.trackEvent('Interaction', {
      interactionType: 'click',
      eventCategory: 'fortnite Android',
      eventAction: 'Redownload',
      eventLabel: title,
    });
  }

  onChange = (title) => {
    epicTracking.trackEvent('Interaction', {
      interactionType: 'click',
      eventCategory: 'fortnite Android',
      eventAction: 'Toggle Drawer',
      eventLabel: title,
    });
    this.setState({show: !this.state.show});
  };

  render() {
    const {data, isAndroid, link, notSupported, oldFlow, newFlow, osVersion, locale} = this.props;
    const supportedDevices = this.props.supportedDevices || {};
    const location = this.props.location || {};
    const search = location.search || '';

    if (!isAndroid && !newFlow) {
      return (
        <Redirect
          to={generateRouteTo(
            {
              pathname: '/mobile/android/new-device',
              search,
            },
            location,
            locale
          )}
        />
      );
    } else if (semver.satisfies(semver.coerce(osVersion), '5.x.x - 7.x.x') && !oldFlow) {
      return (
        <Redirect
          to={generateRouteTo(
            {
              pathname: '/mobile/android/old-device',
              search,
            },
            location,
            locale
          )}
        />
      );
    } else if (semver.satisfies(semver.coerce(osVersion), '>=8.x.x') && !newFlow) {
      return (
        <Redirect
          to={generateRouteTo(
            {
              pathname: '/mobile/android/new-device',
              search,
            },
            location,
            locale
          )}
        />
      );
    } else if (semver.satisfies(semver.coerce(osVersion), '<5.x.x') && !notSupported) {
      return (
        <Redirect
          to={generateRouteTo(
            {
              pathname: '/mobile/android/not-supported',
              search,
            },
            location,
            locale
          )}
        />
      );
    }

    const settings = data.settingsMessage || [];
    const settingMessage = settings.map((item) => {
      const osKey = item.osVersion;
      const semVersion = semver.valid(semver.coerce(osKey));
      if (
        semver.satisfies(semver.coerce(osVersion), '4.5.x - 4.9.x') &&
        semver.satisfies(semVersion, '4.5.x - 4.9.x')
      ) {
        return (
          <div
            className="settings-message"
            key={`item-${osKey}`}
            dangerouslySetInnerHTML={{__html: item.description}}
          />
        );
      } else if (
        semver.satisfies(semver.coerce(osVersion), '5.x.x - 5.9.x') &&
        semver.satisfies(semVersion, '5.x.x - 5.9.x')
      ) {
        return (
          <div
            className="settings-message"
            key={`item-${osKey}`}
            dangerouslySetInnerHTML={{__html: item.description}}
          />
        );
      } else if (
        semver.satisfies(semver.coerce(osVersion), '6.x.x - 6.9.x') &&
        semver.satisfies(semVersion, '6.x.x - 6.9.x')
      ) {
        return (
          <div
            className="settings-message"
            key={`item-${osKey}`}
            dangerouslySetInnerHTML={{__html: item.description}}
          />
        );
      } else if (
        semver.satisfies(semver.coerce(osVersion), '7.x.x - 7.9.x') &&
        semver.satisfies(semVersion, '7.x.x - 7.9.x')
      ) {
        return (
          <div
            className="settings-message"
            key={`item-${osKey}`}
            dangerouslySetInnerHTML={{__html: item.description}}
          />
        );
      }
      return <div key={`item-${osKey}`} />;
    });
    const linkCTA = notSupported ? generateRouteHref('/faq', location, locale) : link;
    const buttonID = notSupported ? 'FAQ Button' : 'Redownload';
    const drawerClassOpened = classNames('support', {active: !this.state.show});
    const drawerClassClosed = classNames('support', {active: this.state.show});
    const downloadClass = classNames('android-downloadpage', {newFlow, oldFlow, notSupported});

    return (
      <div className={downloadClass}>
        <div className="container-fluid">
          {data.image ? (
            <div className="row">
              <div className="col-xs-12 text-center">
                <img src={data.image} alt="Fortnite Icon" />
              </div>
            </div>
          ) : null}
          {newFlow && data.slider ? (
            <AndroidDownloadNewFlow data={data} />
          ) : (
            <div className="row">
              <div className="col-xs-12 text-center">
                {data.title ? <h1>{data.title}</h1> : null}
                {notSupported ? <p>{data.subtitle}</p> : null}
                {oldFlow && data.description ? (
                  <p className="description">{data.description}</p>
                ) : null}
                {oldFlow && supportedDevices.supportedDevices ? (
                  <div
                    role="presentation"
                    onClick={() => this.onChange('Device Drawer')}
                    className="supportSteps">
                    <div className={drawerClassClosed}>
                      <span>
                        {supportedDevices.btnText}
                        <i className="icon-arrow" />
                      </span>
                      <div
                        className="drawer"
                        dangerouslySetInnerHTML={{
                          __html: supportedDevices.supportedDevices,
                        }}
                      />
                    </div>
                  </div>
                ) : null}
                {oldFlow ? settingMessage : null}
                {oldFlow ? null : (
                  <div className="copy" dangerouslySetInnerHTML={{__html: data.description}} />
                )}
              </div>
            </div>
          )}

          {supportedDevices.egIcon && isAndroid ? (
            <div className="row">
              <div className="col-xs-12 text-center">
                <a href={linkCTA} onClick={() => this.trackClick(buttonID)} className="egIcon">
                  <EGSDownloadImage locale={locale} theme="DARK" />
                </a>
              </div>
            </div>
          ) : (
            <div className="row">
              <div className="col-xs-12 text-center">
                <div
                  role="presentation"
                  onClick={() => this.onChange('Device Drawer')}
                  className="supportSteps">
                  <div className={drawerClassOpened}>
                    <span>
                      {supportedDevices.btnText}
                      <i className="icon-arrow" />
                    </span>
                    <div
                      className="drawer"
                      dangerouslySetInnerHTML={{
                        __html: supportedDevices.supportedDevices,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="row">
            {data.openText ? (
              <div className="col-xs-12 text-center">
                <div className="copy spacing">
                  <p>{data.openText}</p>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(AndroidDownload);
