import {BackgroundVideo} from 'epic-react-common';
import epicTracking from 'epic-tracking';
import React from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import styled from 'styled-components';
import AnimateString from '../../common/AnimateString';
import {FeatureVideo} from '../FeatureHeroCarousel/Types';
import VBucksFeature from '../VBucksFeature/VBucksFeature';

import {Device} from '../../../constants';

interface CmsData {
  bgImage: string;
  vBuckImg: string;
  title: string;
  description: string;
  vBuckImgMobile: string;
  vBucksImageAlt: string;
  customLayout: boolean;
  calloutDescription: string;
  calloutTitle: string;
  background: FeatureVideo;
}

interface VBucksSectionProps {
  mobile: boolean;
  tablet: boolean;
  data: CmsData;
}

const VBucksSectionContainer = styled.section`
  display: flex;
  width: 100%;
  min-height: 1000px;
  position: relative;
  align-items: center;
  justify-content: center;
  margin-bottom: -4em;
  padding: 2em 0;
  z-index: 100;

  .video .overlay {
    display: none;
  }

  @media screen and (min-width: 2200px) {
    min-height: 1200px;
    margin-bottom: -12em;
  }

  @media screen and ${Device.tablet} {
    min-height: 1100px;
    align-items: flex-start;
    padding-top: 6em;
  }

  @media screen and ${Device.mobileL} {
    min-height: 650px;
    align-items: flex-start;
    padding-top: 6em;
    margin-bottom: -7em;

    .video {
      background-size: 100%;
    }
  }
`;

const VBucksContentContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 1600px;
  height: 100%;
  position: relative;
  align-items: center;
  justify-content: flex-end;

  @media screen and (max-width: 975px) {
    flex-direction: column;
    justify-content: center;
  }
`;

const VBucksContent = styled.div`
  display: flex;
  width: 100%;
  max-width: 40em;
  position: relative;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  z-index: 1;
  left: 2em;

  && {
    h2 {
      font-size: 4em;
      line-height: 1;
      color: #ffda0b;
      text-shadow: 0 0 8px rgba(0, 0, 0, 0.8), 0px 5px 8px rgba(0, 0, 0, 0.3);
    }

    p {
      font-size: 1.3em;
      color: #fff;
      width: 100%;
      max-width: 30em;
      text-shadow: 0 0 10px #000, 0 0 15px rgba(0, 0, 0, 0.4);
    }
  }

  @media screen and (max-width: 975px) {
    position: absolute;
    max-width: 100%;
    padding: 0 1em;
    top: 2em;
    left: 0;

    h2,
    p {
      text-align: center;
      margin: 0 auto;
    }
  }

  @media screen and ${Device.mobileL} {
    && {
      h2 {
        font-size: 2.5em;
        margin-bottom: 0.5em;
      }

      p {
        font-size: 1em;
      }
    }
  }
`;

const VBucksImage = styled.img`
  display: block;
  width: auto;
  height: 110%;
  transform: translate3d(18%, 0px, 1px);
  flex: 0 1 auto;

  @media screen and (max-width: 1245px) {
    transform: translate3d(25vw, 0px, 1px);
  }

  @media screen and (max-width: 975px) {
    width: 120%;
    height: auto;
    transform: translate3d(0px, 0px, 0px);
  }
  @media screen and ${Device.mobileL} {
    width: 750px;
  }
`;

const VBucksSection = ({data, mobile, tablet}: VBucksSectionProps) => {
  const {vBuckImg, title, description, vBuckImgMobile, vBucksImageAlt, customLayout, background} =
    data;
  const mainImage = (mobile || tablet) && vBuckImgMobile ? vBuckImgMobile : vBuckImg;
  const [visible, setVisibility] = React.useState(false);

  const setSectionVisibility = React.useCallback(
    (visibility) => {
      setVisibility(visibility);
      if (visibility) {
        epicTracking.trackEvent('Interaction', {
          interactionType: 'scrollViewSection',
          eventCategory: 'BattlePass VBucks Section',
          eventAction: 'VBucksSection-inView',
        });
      }
    },
    [setVisibility]
  );

  if (customLayout) return <VBucksFeature data={data} mobile={mobile} />;

  return (
    <VisibilitySensor
      active={!visible}
      onChange={setSectionVisibility}
      partialVisibility
      minTopValue={0}>
      <VBucksSectionContainer>
        {background && <BackgroundVideo background={background} mobile={mobile} tablet={tablet} />}
        <VBucksContentContainer>
          <VBucksContent>
            {title ? (
              <AnimateString
                key="VBucksSection-h2"
                text={title}
                type="h2"
                speed={1.5}
                delay={0}
                autoAlpha={0}
                yPos={100}
                minTop={20}
                scaleFrom={1}
                staggerDelay={0}
              />
            ) : null}
            {description ? (
              <AnimateString
                key="VBucksSection-p"
                text={description}
                type="p"
                speed={1.5}
                delay={0.2}
                autoAlpha={0}
                yPos={100}
                minTop={20}
                scaleFrom={1}
                staggerDelay={0}
              />
            ) : null}
          </VBucksContent>
          {mainImage && <VBucksImage src={mainImage} alt={vBucksImageAlt} />}
        </VBucksContentContainer>
      </VBucksSectionContainer>
    </VisibilitySensor>
  );
};

export default VBucksSection;
