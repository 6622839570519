import React from 'react';

export interface OverlayProps {
  background?: string;
  borderRadius?: string;
  boxShadow?: string;
  children?: React.ReactNode;
  className?: string;
  transition?: string;
  visible?: boolean;
  zIndex?: number;
}

export const Overlay = ({
  background,
  borderRadius,
  boxShadow,
  children,
  className,
  transition,
  visible = false,
  zIndex,
}: OverlayProps) => (
  <div
    style={{
      background,
      borderRadius,
      bottom: 0,
      boxShadow,
      left: 0,
      pointerEvents: 'none',
      position: 'absolute',
      right: 0,
      top: 0,
      transition,
      zIndex,
      opacity: !visible ? 0 : undefined,
    }}
    className={className}>
    {children}
  </div>
);
