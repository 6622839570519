import {connectToStores} from 'epic-alt-utils';
import HttpErrorScreen from 'epic-fortnite-react/components/error/HttpErrorScreen';
import PropTypes from 'prop-types';
import React from 'react';

import CosplayActions from '../../actions/CosplayActions';
import MetaTags from '../../components/common/MetaTags';
import ArchiveLinkSection from '../../components/cosplay/ArchiveLinkSection';
import StyleBaseline from '../../components/cosplay/components/StyleBaseline';
import ExploreFoldablesCarousel from '../../components/cosplay/ExploreFoldablesCarousel';
import ExploreMasksSection from '../../components/cosplay/ExploreMasksSection';
import HowItWorksSection from '../../components/cosplay/HowItWorksSection';
import OverviewHeroSection from '../../components/cosplay/OverviewHeroSection';
import LoadScreen from '../../components/loadScreen/LoadScreen';
import {CosplayStore} from '../../stores';

const fetchData = () => {
  CosplayActions.getOverview();
};

@connectToStores
class CosplayView extends React.Component {
  static propTypes = {
    cosplayStore: PropTypes.object,
  };

  static getStores() {
    return [CosplayStore];
  }

  static getInitialActions() {
    return [fetchData];
  }

  static getPropsFromStores() {
    return {
      cosplayStore: CosplayStore.getState(),
    };
  }

  render() {
    const store = this.props.cosplayStore || null;

    if (store.error || store.error?.response) {
      return <HttpErrorScreen statusCode={store.error?.response?.statusCode ?? 500} />;
    }

    const data = store.data;
    if (!data || store.loading) {
      return <LoadScreen show={true} />;
    }

    const {accentColor, hero, howItWorks, masks, foldables, archiveLink, hideExplore} = data;

    return (
      <StyleBaseline>
        <MetaTags pageModel={data} />
        <OverviewHeroSection {...hero} />
        {hideExplore === true ? null : <HowItWorksSection {...howItWorks} />}
        {hideExplore === true ? null : (
          <ExploreMasksSection masks={masks?.masks} accent={accentColor} />
        )}
        {hideExplore === true ? null : (
          <ExploreFoldablesCarousel {...foldables} accent={accentColor} />
        )}
        {archiveLink ? <ArchiveLinkSection {...archiveLink} /> : null}
      </StyleBaseline>
    );
  }
}

export default CosplayView;
