import {connectToStores} from 'epic-alt-utils';
import {initBowser} from 'epic-games-nav/dist/client';
import React from 'react';
import {RouteComponentProps, withRouter} from 'react-router';

import {LocalizationStore, LoginStore} from '../../stores';

import {NavWrapper} from './NavWrapper';

type Props = RouteComponentProps<{
  appOwner: 'esports' | 'legacy';
  iosLink: string;
  localizationStore: any;
  loginStore: any;
}>;

/**
 * Wrapper around the NavWrapper to connect to the alt stores and convert the data
 * to regular json.  Then pass the props to a light, functional component of NavWrapper
 */
class NavWrapperAlt extends React.Component<Props> {
  constructor(props) {
    initBowser();
    super(props);
  }

  static getStores() {
    return [LocalizationStore, LoginStore];
  }

  static getPropsFromStores() {
    return {
      localizationStore: LocalizationStore.getState(),
      loginStore: LoginStore.getState(),
    };
  }

  render() {
    const {appOwner, localizationStore = {}, loginStore} = this.props;
    const {accountInfo = {}} = loginStore;

    return (
      <NavWrapper
        appOwner={appOwner}
        displayName={accountInfo?.displayName || undefined}
        isLoggedIn={!!accountInfo?.isLoggedIn}
        locale={localizationStore?.locale}
      />
    );
  }
}

export default connectToStores(withRouter(NavWrapperAlt));
