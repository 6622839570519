import styled from 'styled-components';

import {Device} from '../../constants';

const ImageOnlyDesktop = styled.img`
  max-width: 100%;
  max-height: 100%;
  display: inline;
  width: 32%;
  filter: drop-shadow(0px 40px 70px rgba(0, 0, 0, 0.34));
  margin-bottom: 96px;
  @media only screen and ${Device.tablet} {
    display: none;
  }
`;

export default ImageOnlyDesktop;
