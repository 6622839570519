import React from 'react';
import styled from 'styled-components';

interface DualLegalProps {
  backgroundColor?: string;
  textColor?: string;
  justifyContent?: string;
  legalItems: LegalItem[];
}

interface LegalItem {
  text?: string;
  logo?: string;
  isReversed: boolean;
}

const DualLegalWrapper = styled.div<{color?: string; justify?: string}>`
  display: flex;
  justify-content: ${({justify}) => (justify ? justify : 'center')};
  padding: 10px 25px;
  color: ${({color}) => color ?? '#000'};
  width: 100%;

  @media only screen and (max-width: 600px) {
    flex-direction: column;
    align-items: center;
  }
`;

const StyledLegalItem = styled.div<{isReversed: boolean}>`
  display: flex;
  align-items: center;
  flex-direction: ${({isReversed}) => (isReversed ? `row-reverse` : `row`)};
  padding: 10px 25px;

  p {
    padding: 0 10px;
    margin: 0;
  }

  @media only screen and (max-width: 600px) {
    padding: 5px 0;
  }
`;

const DualLegalBlock = (props: DualLegalProps) => (
  <DualLegalWrapper color={props.textColor} justify={props.justifyContent}>
    {props.legalItems.map((item, index) => (
      <StyledLegalItem key={`legal-item-${index}`} isReversed={item.isReversed}>
        {item.text && <p>{item.text}</p>}
        {item.logo && <img src={item.logo} alt="" />}
      </StyledLegalItem>
    ))}
  </DualLegalWrapper>
);

export default DualLegalBlock;
