import {Container, FAQ, HttpErrorScreen, LoadScreen, Section} from 'epic-fortnite-react';
import {getInitialCmsPageSharedValues, useCmsData, PageData} from 'epic-fortnite-shared-values';
import React from 'react';
import styled from 'styled-components';

import SimpleTextWithBackground from '../../components/blocks/SimpleTextWithBackground';
import SideBySide from '../../components/bundles/SideBySide';
import {CmsComponentMapper} from '../../components/common/CmsComponentMapper';
import MetaTags from '../../components/common/MetaTags';
import FlexibleHero from '../../components/flexible-hero/FlexibleHero';

const NoBuildWrapper = styled.div`
  background-color: #fff;
  color: #000;
`;

const SLUG = 'no-build-mode';

export interface NoBuildPageData extends PageData {
  content: string;
}

const NoBuildPage = () => {
  const {pageData, isLoading, error} = useCmsData<NoBuildPageData>(SLUG);
  const {header, faq} = pageData;

  if (isLoading) {
    return <LoadScreen show />;
  }

  if (error) {
    return <HttpErrorScreen statusCode={error.response?.status ?? 500} />;
  }

  if (!pageData) {
    return <HttpErrorScreen statusCode={404} />;
  }

  return (
    <NoBuildWrapper>
      <MetaTags pageModel={pageData} />
      <FlexibleHero data={header} />
      <CmsComponentMapper
        dynamicPageDataArray={pageData?.content?.items}
        elementMapping={{
          twoColumnSection: <SideBySide />,
          textBlock: <SimpleTextWithBackground />,
        }}
      />
      <Section size="4xl">
        <Container maxWidth={600} size="lg">
          <FAQ questions={faq?.questions} title={faq?.title} />
        </Container>
      </Section>
    </NoBuildWrapper>
  );
};

NoBuildPage.getInitialSharedValues = getInitialCmsPageSharedValues(SLUG);

export default NoBuildPage;
