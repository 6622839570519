import PropTypes from 'prop-types';
import React from 'react';

const BingResult = function BingResult(props) {
  return (
    <div className="bingResult">
      <div className="resultTitle">
        <a href={props.url}>{props.title}</a>
      </div>
      <div className="resultLink">
        <a href={props.url}>{props.displayUrl}</a>
      </div>
      <p className="resultDescription">{props.description}</p>
    </div>
  );
};

BingResult.propTypes = {
  title: PropTypes.string,
  url: PropTypes.string,
  displayUrl: PropTypes.string,
  description: PropTypes.string,
};

export default BingResult;
