import {getLocale} from '@epic-core/common';
import {getAssetPath} from 'epic-fortnite-react/utils/FortniteUtils';
import React from 'react';
import {Link} from 'react-router-dom';

import Medal from '../../../../../../images/cosplay/medal-green.svg';
import Stopwatch from '../../../../../../images/cosplay/stopwatch-green.svg';

import {ArchiveItemDetails, ArchiveItemWrapper, ArchiveName, DifficultyIndicator, Image} from './';

interface Props {
  url?: string;
  image?: string;
  name?: string;
  publishDate?: string;
  difficulty?: string;
  timeRequired?: string;
  difficultyLabels?: {[key: string]: string};
}

const DIFFICULTY_MAP = {
  '1': 'veryEasy',
  '2': 'easy',
  '3': 'medium',
  '4': 'hard',
  '5': 'veryHard',
};

const ArchiveItem = ({
  url,
  image,
  name,
  publishDate,
  difficulty,
  difficultyLabels,
  timeRequired,
}: Props) => {
  const date = publishDate ? new Date(publishDate) : undefined;

  const difficultyKey = difficulty ? DIFFICULTY_MAP[difficulty] : undefined;
  const difficultyLabel =
    difficulty && difficultyKey && difficultyLabels ? difficultyLabels[difficultyKey] : undefined;

  return (
    <Link to={url ?? ''}>
      <ArchiveItemWrapper>
        <Image alt={name} src={image} />
        <ArchiveItemDetails>
          <p>
            {date
              ? date.toLocaleDateString(getLocale(), {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                })
              : null}
          </p>
          <ArchiveName>{name}</ArchiveName>
          <DifficultyIndicator text={timeRequired} image={getAssetPath(Stopwatch)} />
          <DifficultyIndicator text={difficultyLabel} image={getAssetPath(Medal)} />
        </ArchiveItemDetails>
      </ArchiveItemWrapper>
    </Link>
  );
};

export default ArchiveItem;
