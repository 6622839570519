import React from 'react';
import styled from 'styled-components';

import {VisuallyHidden} from '../VisuallyHidden/VisuallyHidden';

export interface ProgressProps {
  color?: string;
  value?: number;
}

export interface StyledProgressValueProps {
  $color?: string;
  $percentage?: string;
}

export interface StyledProgressBackgroundProps {
  $color?: string;
}

const StyledProgress = styled.div`
  height: 8px;
  position: relative;
  z-index: 1;
`;

const StyledProgressValue = styled.div<StyledProgressValueProps>`
  @keyframes animate-progress-loading {
    from {
      right: 100%;
    }

    to {
      right: -50%;
    }
  }

  background-color: ${({$color}) => $color};
  height: 100%;
  left: 0;
  position: absolute;
  transition: width 0.6s ease-out;
  width: ${({$percentage}) => $percentage};
`;

const StyledProgressBackground = styled.div<StyledProgressBackgroundProps>`
  background-color: ${({$color}) => $color};
  height: 100%;
  left: 0;
  opacity: 0.5;
  position: absolute;
  right: 0;
`;

export const Progress = ({color = '#C4C4C4', value = 0}: ProgressProps) => {
  const percentage = `${value}%`;

  return (
    <StyledProgress>
      <StyledProgressValue
        $color={color}
        $percentage={percentage}
        aria-valuemax={100}
        aria-valuemin={0}
        aria-valuenow={value}
        role="progressbar">
        <VisuallyHidden>{percentage}</VisuallyHidden>
      </StyledProgressValue>
      <StyledProgressBackground $color={color} />
    </StyledProgress>
  );
};
