import {SlickWrapper} from 'epic-react-common';
import React from 'react';

import FeatureCarouselItem from './FeatureCarouselItem';
import FeatureHeroCarouselContainer from './FeatureHeroCarouselContainer';
import FeatureHeroTitle from './FeatureHeroTitle';
import FeatureIcon from './FeatureIcon';
import FeatureTitleVisibilityContainer from './FeatureTitleVisibilityContainer';
import HeroBackground from './HeroBackground';
import ScrollContainer from './ScrollContainer';
import ThumbContainer from './ThumbContainer';
import {HeroSlide} from './Types';

interface Props {
  items: HeroSlide[];
  setCurrentSlide: (value: number) => void;
  activeSlide: number;
  isVisible: boolean;
  mobile: boolean;
  tablet: boolean;
}
const FeaturedCarousel = ({
  items,
  activeSlide,
  setCurrentSlide,
  isVisible,
  tablet,
  mobile,
}: Props) => {
  const [sliderRef, setSliderRef] = React.useState(null);

  const sliderSettings = React.useMemo(() => {
    return {
      dots: false,
      arrows: false,
      infinite: false,
      swipeToSlide: false,
      useCSS: false,
      useTransform: false,
      touchMove: false,
      swipe: false,
      slidesToShow: 1,
      afterChange: (current) => setCurrentSlide(current),
    };
  }, [setCurrentSlide]);

  const goToSlide = React.useCallback(
    (index) => {
      if (activeSlide !== index) {
        sliderRef.slickGoTo(index);
      }
    },
    [sliderRef, activeSlide]
  );
  return (
    <>
      <FeatureHeroCarouselContainer key="heroCarousel">
        <HeroBackground
          mobile={mobile}
          tablet={tablet}
          items={items}
          activeSlide={activeSlide}
          isVisible={isVisible}
        />
        <SlickWrapper ref={setSliderRef} options={sliderSettings}>
          {items.map((item, index) => {
            return (
              <FeatureCarouselItem
                isVisible={isVisible}
                active={index === activeSlide}
                key={`heroSlide-${item.key}`}
                data={item}
                mobile={mobile}
                tablet={tablet}
              />
            );
          })}
        </SlickWrapper>
        <ThumbContainer>
          <ScrollContainer>
            {items.map((item, index) => {
              return (
                <FeatureIcon
                  goToSlide={goToSlide}
                  index={index}
                  active={index === activeSlide}
                  key={`heroThumb-${item.key}`}
                  data={item}
                />
              );
            })}
          </ScrollContainer>
        </ThumbContainer>
      </FeatureHeroCarouselContainer>
      <FeatureTitleVisibilityContainer>
        {items.map(({title, subTitle}, index) => (
          <FeatureHeroTitle
            key={`featuretitle${index}`}
            active={index === activeSlide}
            mobile={mobile}
            tablet={tablet}
            title={title}
            subtitle={subTitle}
          />
        ))}
      </FeatureTitleVisibilityContainer>
    </>
  );
};

export default FeaturedCarousel;
