export enum ModImageType {
    Storefront = 'storefront',
    StorefrontLandscape = 'OfferImageWide',
    Carousel = 'carousel',
    AboutImg = 'aboutImg',
    Thumbnail = 'Thumbnail',
    Featured = 'Featured'
}

export interface ModImage {
    url: string;
    type: ModImageType;
    md5: string;
    size: number;
    width: number;
    height: number;
    uploadedDate: string;
}
