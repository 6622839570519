import React from 'react';
import styled from 'styled-components';

import {Text, VisuallyHidden} from '../';
import {ClickableElement, ClickableElementProps} from '../ClickableElement/ClickableElement';

export interface BaseButtonProps extends ClickableElementProps {
  className?: string;
  color?: string;
  disabled?: boolean;
  label: string | React.ReactNode | JSX.Element;
  labelHidden?: boolean;
  size?: 'sm' | 'med' | 'lg';
  title?: string;
}

const StyledBaseButton = styled(ClickableElement)`
  &:not([disabled]) {
    user-select: none;
  }

  & > * {
    pointer-events: none;
  }
`;

export const BaseButton = ({
  className,
  color,
  disabled,
  label,
  labelHidden,
  size = 'lg',
  ...props
}: BaseButtonProps) => {
  const BaseButtonLabel = () => {
    return typeof label === 'string' ? (
      <Text align="center" color={color} transform="uppercase" size={size}>
        {label}
      </Text>
    ) : (
      <>{label}</>
    );
  };

  return (
    <StyledBaseButton
      {...(className && {className})}
      {...(disabled && {disabled: true})}
      {...props}>
      {!labelHidden ? <BaseButtonLabel /> : <VisuallyHidden>{label}</VisuallyHidden>}
    </StyledBaseButton>
  );
};
