import styled from 'styled-components';

import {breakpoints} from '../../../../constants/Breakpoints';

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  padding: 0 2rem;

  @media ${breakpoints.mobileL} {
    padding: 10rem 2rem 0;
  }
`;

export default TextWrapper;
