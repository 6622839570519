import {withLocalization} from '@epic-core/localization';
import HtmlTemplate from 'epic-fortnite-react/components/common/HtmlTemplate';
import React from 'react';

import Col from './Col';
import Container from './Container';
import ContentBox from './ContentBox';
import Row from './Row';
import CountrySelect from './StyledCountrySelect';
import LanguageSelect from './StyledLanguageSelect';
import useCountryContentSelector from './useCountryContentSelector.hook';

const CountryContentSelector = ({defaultCountry, defaultLanguage, getMessage}) => {
  const {initCountry, initLanguage, setCountry, setLanguage, content, countries, languages} =
    useCountryContentSelector(defaultCountry, defaultLanguage, getMessage);
  const placeholder = getMessage('epic.common.selectCountry');
  return (
    <Container>
      <Row>
        <Col>
          <CountrySelect
            placeholder={placeholder}
            countryOptions={countries}
            setCountry={setCountry}
            initValue={initCountry}
          />
        </Col>
        <Col>
          <LanguageSelect
            placeholder=""
            languageOptions={languages}
            setLanguage={setLanguage}
            initValue={initLanguage}
          />
        </Col>
      </Row>

      <ContentBox>
        <HtmlTemplate html={content} />
      </ContentBox>
    </Container>
  );
};

export default withLocalization(CountryContentSelector);
