import {Device} from 'epic-fortnite-styles/constants';
import styled from 'styled-components';

const StyledButtonWrapper = styled.div`
  position: absolute;
  bottom: 23%;
  @media screen and ${Device.laptop} {
    bottom: 22%;
  }
  @media screen and ${Device.mobileL} {
    bottom: 23%;
  }
  width: 100%;
  text-align: center;
  &&& .btn.btn-display:after {
    background-color: #ffdd00;
  }
  && .btn.btn-primary {
    font-size: 2.5em;
    @media screen and ${Device.laptopL} {
      font-size: 1.4em;
    }
    @media screen and ${Device.tablet} {
      font-size: 1em;
    }
    @media screen and (max-width: 425px) {
      font-size: 2em;
    }
    @media screen and ${Device.mobileL} {
      font-size: 1.5em;
    }
  }
`;

export default StyledButtonWrapper;
