import styled from 'styled-components';

import {Device} from '../../constants';

const StyledSubscriptionCoreWrapper = styled.section<{bgImg?: string}>`
  display: flex;
  min-height: 600px;
  margin-bottom: 2em;
  ${({bgImg}) =>
    bgImg
      ? `
    background: url(${bgImg});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;
  `
      : null}

  @media screen and ${Device.laptop} {
    min-height: 500px;
  }

  @media screen and ${Device.mobileL} {
    min-height: unset;
  }
`;

export default StyledSubscriptionCoreWrapper;
