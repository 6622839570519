import axios, {AxiosInstance, AxiosStatic} from 'axios';

import root from 'window-or-global';

class AxiosContainerInstance {
    // default to global for backwards compatibility
    private axiosInstance: AxiosStatic | AxiosInstance = axios;

    public getAxiosInstance() {
        return this.axiosInstance;
    }

    public setAxiosInstance(instance: AxiosInstance) {
        this.axiosInstance = instance;
    }
}

// Enforce singleton even if multiple versions of this module are loaded
if (!root.__AxiosContainer) {
    root.__AxiosContainer = new AxiosContainerInstance();
}

root.__AxiosContainer.setAxiosInstance.configure = (): void => {}; // no-op to pass required-env-check
export const AxiosContainer: AxiosContainerInstance = root.__AxiosContainer;
