import styled from 'styled-components';

const StyledRiftTourPlaceholder = styled.div`
  height: 0;

  @media (orientation: portrait) {
    padding-top: 20vh;
  }

  @media (orientation: landscape) {
    padding-top: 60vh;
  }
`;

export default StyledRiftTourPlaceholder;
