import React from 'react';

interface Props {
  styles?: React.CSSProperties;
  id?: string;
}

const CrewLeaf = ({styles, id}: Props) => (
  <svg style={styles} id={id} viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.558 39.687a29.865 29.865 0 004.52-1.268 45.386 45.386 0 004.7-2.147s.738-1.662 1.382-3.538c.716-2.085 1.347-4.407
                1.347-4.407s-1.715.053-3.485-.078c-2.031-.15-4.155-.487-4.155-.487a14.441 14.441 0 01-2.773 1.417c-1.931.689-3.65 1.234-3.65
                1.234a19.6 19.6 0 002.494 2.512c1.2.956 1.573 1.243 1.573 1.243zm6.224-13.09a34.8 34.8 0 004.146.27 19.7 19.7 0 003.468-.356s-.252 2.178-.7
                4.163a40.284 40.284 0 01-1.347 4.407s2.119-1.643 3.976-3.279c1.458-1.285 2.751-2.649 2.751-2.649s.069-2.209.035-4.572c-.026-1.8-.173-4.346-.173-4.346a15.229
                15.229 0 01-3.425 1.443c-2.084.5-4.1.817-4.1.817s-1 1.069-2.094 2.042c-1.217 1.069-2.537 2.055-2.537 2.055zm6.076-5.9s2.272-.733 4.294-1.478c1.565-.576 3-1.208
                3-1.208a28.562 28.562 0 01.591 3.894 34.94 34.94 0 01-.035 4.572s1.076-1.531 2.052-3.129c1.239-2.031 2.442-4.233
                2.442-4.233s-.823-2.565-1.72-4.937c-.641-1.692-1.444-3.425-1.444-3.425s-1.632
                1.526-2.946 2.512a29.638 29.638 0 01-3.181 2.086zm3.616-7.545s1.485-1.03 2.955-2.295c1.577-1.357 3.146-2.955 3.146-2.955s1.02 1.86 1.895 3.842a69.88 69.88 0 011.7
                4.285l1.391-8.761s-1.8-2.513-3.442-4.433a23.511 23.511 0 00-2.5-2.512 15.391 15.391 0 01-1.608 3.329 27.671 27.671 0 01-2.694 3.372 12.767 12.767 0 01-.113
                2.617c-.21 1.518-.73 3.505-.73 3.505z"
      fill="#f7ff00"
      fillRule="evenodd"
    />
  </svg>
);

export default CrewLeaf;
