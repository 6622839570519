import {getLocale} from '@epic-core/common';
import classNames from 'classnames';
import {connectToStores} from 'epic-alt-utils';
import LoginActions from 'epic-fortnite-react/actions/LoginActions';
import RegisterActions from 'epic-fortnite-react/actions/RegisterActions';
import Footer from 'epic-fortnite-react/components/footer/FooterAlt';
import NavWrapper from 'epic-fortnite-react/components/mainNav/NavWrapperAlt';
import {useDarklyAppVariation} from 'epic-fortnite-shared-values';
import {colors as bingSearch} from 'epic-react-search';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, {useEffect} from 'react';
import {matchPath, withRouter} from 'react-router';
import {ThemeProvider, createGlobalStyle} from 'styled-components';
import Head from 'epic-fortnite-react/components/head/Head';
import {useHideLocaleIfPreference} from 'epic-fortnite-react';
import {withLocalization} from '@epic-core/localization';
import {setLocaleClassOnBody, initBowser, setUserAgent} from '@epic-mw/deprecated-utils';

import 'focus-visible';

import getBlackoutRoutes from './routes/blackoutRoutes';
import getRoutes from './routes/routes';
import {AppConfigStore, HeaderStore, LandingPageStore, LoginStore, MobileStore} from './stores';
import {getDecoratedFontFamily} from './utils/StyleUtils';

const HideNavigation = createGlobalStyle`
  #egh {
    display: none;
  }
  #egf {
    display: none;
  }
`;

const showHeaderAndFooter = (landingPageStore, mobileStore, location) => {
  const patterns = [`/:slug`, `/:slug/success`];
  for (let i = 0; i < patterns.length; i += 1) {
    const match = matchPath(location.pathname, {
      path: patterns[i],
      exact: true,
      strict: false,
    });

    // Remove header/footer for landing pages with noHeader selected OR the cloud-gaming page.
    if (match && match.params.slug) {
      const activeSlug = match.params.slug;

      if (landingPageStore.noHeaderPages[activeSlug]) {
        return false;
      }
    }

    // For Cloud UA campaign root/default page.
    if (match && match.params.campaign) {
      return false;
    }
  }
  return mobileStore.mode !== 'iosgame' && mobileStore.mode !== 'game';
};

const App = ({
  landingPageStore,
  mobileStore,
  location,
  history,
  languagePreference,
  getMessage,
  ...props
}) => {
  useHideLocaleIfPreference(languagePreference);

  const showHeader = showHeaderAndFooter(landingPageStore, mobileStore, location);
  const classes = classNames('fortnite-wrapper', {
    mobile: props.mobile,
    tablet: props.tablet,
    msie: props.msie,
    msedge: props.msedge,
    firefox: props.firefox,
    ps4: props.ps4,
    switch: props.switch,
    noheader: !showHeader,
  });
  const locale = getLocale();
  const decoratedFontFamily = `font-family: ${getDecoratedFontFamily(locale)};`;
  const theme = {locale, decoratedFontFamily, bingSearch};
  const {value: blackout} = useDarklyAppVariation('blackout');

  useEffect(() => {
    initBowser();
    setUserAgent();

    // @NOTE: Until the (soon to be) legacy <EpicGamesNav /> component gets
    // replaced with the new global nav implementation the following statement
    // could cause the locale class name to show up twice in the body
    // class name list. Behavior is not affected.
    setLocaleClassOnBody(locale);
  }, [locale]);

  return (
    <div>
      {!showHeader && <HideNavigation />}
      <ThemeProvider theme={theme}>
        <Head title={getMessage('epic.fortnite.social.meta.site_name')} />
        <NavWrapper {...props} appOwner="legacy" />
        <div className={classes}>
          {blackout ? getBlackoutRoutes() : getRoutes()}
          <Footer outer={props.router} location={props.location} loginStore={props.loginStore} />
        </div>
      </ThemeProvider>
    </div>
  );
};

App.getStores = () => [AppConfigStore, HeaderStore, LoginStore, MobileStore, LandingPageStore];
App.getPropsFromStores = () => ({
  appConfigStore: AppConfigStore.getState(),
  loginStore: LoginStore.getState(),
  mobileStore: MobileStore.getState(),
  landingPageStore: LandingPageStore.getState(),
  mobile: HeaderStore.getState().mobile(),
  tablet: HeaderStore.getState().tablet(),
  msie: HeaderStore.getState().msie(),
  msedge: HeaderStore.getState().msedge(),
  firefox: HeaderStore.getState().firefox(),
  headerStore: HeaderStore.getState(),
  ps4: AppConfigStore.getState().isPs4(),
  switch: AppConfigStore.getState().isSwitch(),
});

App.getInitialActions = () => [
  LoginActions.getAccountInfo,
  (props) => {
    const query = queryString.parse(props.location.search) || {};
    if (query.mode) {
      RegisterActions.setGameFlow(query.mode);
    }
    if (query.error === 'noAccount') {
      LoginActions.errorLoginModal();
    }
  },
];

App.propTypes = {
  appConfigStore: PropTypes.object,
  landingPageStore: PropTypes.object,
  mobileStore: PropTypes.object,
  loginStore: PropTypes.object,
  location: PropTypes.object,
  router: PropTypes.object,
  mobile: PropTypes.bool,
  tablet: PropTypes.bool,
  msie: PropTypes.bool,
  msedge: PropTypes.bool,
  switch: PropTypes.bool,
  ps4: PropTypes.bool,
  firefox: PropTypes.bool,
};

export default withRouter(withLocalization(connectToStores(App)));
