import {withLocalization} from '@epic-core/localization';
import classNames from 'classnames';
import {generateRouteTo} from 'epic-fortnite-react';
import LoginActions from 'epic-fortnite-react/actions/LoginActions';
import StyledText from 'epic-fortnite-react/components/common/StyledText';
import {generateMainSitePath} from 'epic-fortnite-react/utils/FortniteUtils';
import epicTracking from 'epic-tracking';
import PropTypes from 'prop-types';
import React from 'react';
import {withRouter} from 'react-router';
import root from 'window-or-global';

/**
 * Presents a title, description and button for a call to action with no background image.
 */
class BlankCallToActionV2 extends React.Component {
  static propTypes = {
    data: PropTypes.object,
    align: PropTypes.string,
    isLoggedIn: PropTypes.bool,
    history: PropTypes.object,
    buttonStyle: PropTypes.string,
    locale: PropTypes.string,
  };

  onClick = () => {
    const {history, data, location, locale} = this.props;
    epicTracking.trackEvent('Interaction', {
      interactionType: 'click',
      eventCategory: 'BlankCallToActionV2',
      eventAction: 'playButton',
      eventLabel: 'jumpIn',
    });
    if (data.ctaLoginRequired) {
      this.signIn(data.ctaLink, locale);
      return;
    }
    const link = data.ctaLink || '/getfortnite';
    history.push(generateRouteTo(link, location, locale));
  };

  signIn = (url) => {
    const {isLoggedIn, history, locale} = this.props;
    if (isLoggedIn) {
      LoginActions.closeLoginModal();
      if (/^http/.test(url)) {
        root.location.href = url;
      } else {
        history.push(generateMainSitePath(url, locale));
      }
    } else {
      LoginActions.requestRegister({returnPath: generateMainSitePath(url, locale)});
    }
  };

  render() {
    const {data, align} = this.props;
    const {buttonStyle, theme} = data;
    const backgroundStyle = {};
    if (data.backgroundColor) {
      backgroundStyle.backgroundColor = data.backgroundColor;
    }
    if (data.fontColor) {
      backgroundStyle.color = data.fontColor;
    }
    const linkStyle = {fontWeight: 'bold'};
    if (data.linkFontColor) {
      linkStyle.color = data.linkFontColor;
    }
    const ctaButton = data.cta || this.getMessage('epic.fortnite.battleRoyale.playFreeNow');
    // If props align is passed then use as default. Other wise default to bottom-left.
    const defaultButtonClasses = align || 'bottom-left';
    const buttonClasses = classNames(data.buttonAlign || defaultButtonClasses, 'text-center');
    const containerClasses = classNames('cta-container', (theme || '').split(','));
    const wrapperClasses = classNames('cta-wrapper');
    const btnClassNoAnim = ['btn', 'btn-primary', 'btn-hover-transform'];
    const btnClassAnim = [
      'btn',
      'download-button',
      'btn-primary',
      'btn-display',
      'download-btn-default',
    ];

    const buttonDownloadClasses = classNames(
      buttonStyle === 'noanim' ? btnClassNoAnim : btnClassAnim,
      buttonStyle || ''
    );
    const {title} = data;
    return (
      <div className={containerClasses} style={backgroundStyle}>
        <div className={wrapperClasses}>
          <div className={buttonClasses}>
            {title && <StyledText data={title} Wrapper={'div'} />}
            <button className={buttonDownloadClasses} onClick={this.onClick}>
              <span>{ctaButton}</span>
            </button>
            {data.exitLink && data.exitLinkText ? (
              <p className="exitLink">
                <a style={linkStyle} href={data.exitLink}>
                  {data.exitLinkText}
                </a>
              </p>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default withLocalization(withRouter(BlankCallToActionV2));
