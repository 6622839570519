import React from 'react';
import flattenChildren from 'react-keyed-flatten-children';
import styled from 'styled-components';

import {space} from '../../../theme/theme';
import {Spaces} from '../../../types/theme';

type InlineAlign = 'center' | 'flex-end' | 'flex-start';
type InlineJustify =
  | 'center'
  | 'flex-end'
  | 'flex-start'
  | 'space-around'
  | 'space-between'
  | 'stretch';
type InlineFlex = 'start' | 'end';

export type StyledInlineProps = {
  $align?: InlineAlign;
  $gap?: Spaces;
  $gapX?: Spaces;
  $gapY?: Spaces;
  $justify?: InlineJustify;
  $wrap?: boolean;
};

export type StyledInlineItemProps = {
  $flex?: InlineFlex;
};

export type InlineProps = {
  align?: InlineAlign;
  children?: React.ReactNode;
  flex?: InlineFlex;
  gap?: Spaces;
  gapX?: Spaces;
  gapY?: Spaces;
  id?: string;
  justify?: InlineJustify;
  list?: 'ordered' | 'unordered';
  wrap?: boolean;
};

const StyledInline = styled.div<StyledInlineProps>`
  --inline-gapX: ${({$gap, $gapX}) =>
    $gapX || $gap
      ? `${space[`${$gapX || $gap}`]}px`
      : '0px'}; /* 0px fallback needed for calc to work */
  --inline-gapY: ${({$gap, $gapY}) =>
    $gapY || $gap
      ? `${space[`${$gapY || $gap}`]}px`
      : '0px'}; /* 0px fallback needed for calc to work */
  --inline-inset: 0.5rem;

  align-items: ${({$align}) => $align};
  display: flex;
  flex: 1;
  flex-wrap: ${({$wrap}) => ($wrap ? 'wrap' : 'no-wrap')};
  justify-content: ${({$justify}) => $justify};
  margin: calc(var(--inline-gapY) * -1) calc(var(--inline-gapX) * -1);
  max-width: calc(100% + (var(--inline-gapX) * 2) + 0.1rem);
  min-width: 0;

  body.ar & {
    direction: rtl;
  }
`;

const StyledInlineItem = styled.div<StyledInlineItemProps>`
  display: flex;
  flex-shrink: 1;
  /* font-size: 0; TODO: uncomment after moving all typography to Text component */
  /* line-height: 0; TODO: uncomment after moving all typography to Text component */
  margin: calc(var(--inline-inset) * -1);
  min-width: 0;
  overflow: hidden; /* required for truncation */
  padding: calc(var(--inline-gapY) + var(--inline-inset))
    calc(var(--inline-gapX) + var(--inline-inset));
  position: relative;
  text-align: left;

  body.ar & {
    text-align: right;
  }

  &:empty {
    display: none;
  }

  &:not(:only-child):first-child {
    flex-grow: ${({$flex}) => ($flex === 'start' ? 1 : $flex === 'end' ? 0 : 'initial')};
    flex-shrink: ${({$flex}) => ($flex === 'start' ? 1 : $flex === 'end' ? 0 : 'initial')};
  }

  &:not(:only-child):last-child {
    flex-grow: ${({$flex}) => ($flex === 'start' ? 0 : $flex === 'end' ? 1 : 'initial')};
    flex-shrink: ${({$flex}) => ($flex === 'start' ? 0 : $flex === 'end' ? 1 : 'initial')};
  }
`;

export const Inline = ({
  align = 'center',
  children,
  flex,
  gap,
  gapX,
  gapY,
  id,
  justify,
  list,
  wrap = true,
}: InlineProps) => {
  return (
    <>
      {flattenChildren(children).length !== 0 && (
        <StyledInline
          as={list && list === 'ordered' ? 'ol' : list && list === 'unordered' ? 'ul' : 'div'}
          $align={align}
          $gap={gap}
          $gapX={gapX}
          $gapY={gapY}
          $justify={justify}
          $wrap={wrap}
          id={id}>
          {React.Children.map(flattenChildren(children), (child) => {
            let key: React.Key | null = null;
            // child has a type of ReactElement | ReactText
            // ReactText is an alias for string | number
            // We narrow the type here to ReactElement so we can pull the key out
            if (typeof child !== 'string' && typeof child !== 'number') {
              key = child.key;
            }
            return (
              <StyledInlineItem as={list ? 'li' : 'div'} $flex={flex} key={key}>
                {child}
              </StyledInlineItem>
            );
          })}
        </StyledInline>
      )}
    </>
  );
};
