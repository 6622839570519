import classNames from 'classnames';
import StyledText from 'epic-fortnite-react/components/common/StyledText';
import PropTypes from 'prop-types';
import React from 'react';

import ImageCta, {StyledBattlePassFooterContainer} from '../battlePass/ImageCta';

import BackgroundImageClip from './BackgroundImageClip';
import StyledImage from './StyledImage';
import {getBackgroundStyle} from './utils';
import Video from './Video';

import {breakpoints} from '../../constants/Breakpoints';
import styled from 'styled-components';
import {StyledPlayButtonWrapper} from '../battlePass/CtaFooter';

const StyledBackgroundImageVideo = styled.div`
  position: relative;
  & .logo {
    max-width: 700px;
    margin: 0 auto;
    padding-top: 2em;

    & h1 {
      font-size: 1.18em;
    }
    & img {
      max-width: 100%;
    }

    @media ${breakpoints.tablet} {
      padding-bottom: 1em;
      & img {
        padding: 1em 0;
      }
      & h1 {
        margin: 0.2em;
      }
    }
  }

  & ${StyledBattlePassFooterContainer} {
    width: 90%;
    max-width: 1200px;
    margin: 0 auto;
    z-index: 10;

    & img.image-cta-background {
      max-width: 100%;
    }

    ${StyledPlayButtonWrapper} {
      font-size: 3.6rem;

      &&& p {
        font-size: 3.6rem;
      }

      & span.videoPlayButton {
        width: 6rem;
        height: 6rem;
        border-width: 2px;

        & span {
          font-size: 1.5rem;
        }
      }
      @media ${breakpoints.mobileL} {
        font-size: 2.4rem;
        &&& p {
          font-size: 2.4rem;
        }
        & span.videoPlayButton {
          width: 4rem;
          height: 4rem;

          & span {
            font-size: 1rem;
          }
        }
      }
    }
  }
`;

/**
 * Presents a logo, title, video with a background image.
 */
class BackgroundImageVideo extends React.Component {
  static propTypes = {
    data: PropTypes.object,
    mobile: PropTypes.bool,
    tablet: PropTypes.bool,
  };

  render() {
    const {data, tablet, mobile} = this.props;
    const backgroundStyle = getBackgroundStyle(data, tablet, mobile);
    const {text, logo, video, videoClickToPlay, videoType} = data;
    const wrapperClasses = classNames('wrapper', {
      nobackground: !backgroundStyle.backgroundImage,
    });
    const dataImageCta = {
      desktopImg: videoClickToPlay.desktopImg,
      mobileImg: videoClickToPlay.mobileImg,
      videoId: videoClickToPlay.videoId,
    };
    return (
      <StyledBackgroundImageVideo>
        <div className={wrapperClasses} style={backgroundStyle}>
          <div className="logo">
            <StyledImage data={logo} />
            <StyledText data={text} />
          </div>
          {videoType === 'clickToPlay' && videoClickToPlay && videoClickToPlay.videoId && (
            <ImageCta mobile={mobile} data={dataImageCta} />
          )}
          {videoType === 'autoPlay' && video && video.enabled && (
            <Video data={video} tablet={tablet} mobile={mobile} />
          )}
          <BackgroundImageClip />
        </div>
      </StyledBackgroundImageVideo>
    );
  }
}

export default BackgroundImageVideo;
