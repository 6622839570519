import {Section} from 'epic-fortnite-react';
import React from 'react';

import {CoreSectionProps as CmsCoreSectionProps} from '../bundles/types';

export interface CoreSectionProps {
  children: React.ReactNode;
  coreSectionProps?: CmsCoreSectionProps;
  condensed?: boolean;
}

export const CoreSection = ({children, coreSectionProps, condensed}: CoreSectionProps) => {
  return (
    <Section
      backgroundColor={coreSectionProps?.backgroundColor || "#0A0A09"}
      backgroundImage={coreSectionProps?.backgroundImage}
      backgroundPosition={coreSectionProps?.backgroundPosition}
      backgroundSize={coreSectionProps?.backgroundSize}
      backgroundVideo={coreSectionProps?.backgroundVideo}
      defaultTextColor={coreSectionProps?.defaultTextColor}
      mobileBackgroundImage={coreSectionProps?.mobileBackgroundImage}
      size={condensed ? '2xl' : coreSectionProps?.size ? coreSectionProps.size : 'xl'}
      textAlign={coreSectionProps?.textAlign}>
      {children}
    </Section>
  );
};
