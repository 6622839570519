import styled from 'styled-components';

import {Device} from '../../../../constants';

// Module wrapper
export const StyledBattleStarsContainer = styled.div<{bgImg: string}>`
  background: ${({bgImg}) => `url(${bgImg}) no-repeat center center`};
  background-size: cover;
  min-height: 45vw;
  max-height: 800px;
  position: relative;
  display: flex;

  @media screen and ${Device.laptopL} {
    min-height: 50vw;
  }

  @media screen and ${Device.mobileL} {
    flex-direction: column;
    background: unset;
  }
`;

// Wraps the text content and text background image
export const StyledBattleStarsContentContainer = styled.div`
  position: relative;
  top: 0;
  bottom: 0;
  width: 50%;

  img {
    height: 100%;
    width: auto;
    position: absolute;
  }

  @media screen and ${Device.mobileL} {
    width: 100%;
    position: unset;

    img {
      position: unset;
    }
  }
`;

// Renders background image with nested text
export const StyledBattleStarsContentBackground = styled.div`
  background-size: cover;
  position: relative;
  height: 100%;

  @media screen and ${Device.mobileL} {
    background: unset;
    background-color: #fff;
    height: 50%;
    padding: 2em;
    position: unset;
  }
`;

// Text content within StyledBattleStarsContentBackground
export const StyledBattleStarsTextContent = styled.div`
  position: absolute;
  width: 60%;
  top: 50%;
  left: 50%;
  transform: translate(-58%, -50%);
  display: flex;
  flex-direction: column;
  align-content: center;
  font-style: italic;

  && h2 {
    font-size: 3em;
    line-height: 1.1;
  }

  && p {
    font-size: 1.2em;
    line-height: 1.5;
  }

  @media screen and ${Device.laptopL} {
    && h2 {
      font-size: 2em;
    }

    && p {
      font-size: 1em;
    }
  }

  @media screen and ${Device.laptop} {
    && h2 {
      font-size: 1.5em;
    }

    && p {
      font-size: 0.8em;
    }
  }

  @media screen and ${Device.mobileL} {
    width: 100%;
    position: unset;
    transform: unset;
    text-align: center;

    && h2 {
      font-size: 2em;
    }

    && p {
      font-size: 1em;
    }
  }
`;

// Main hero image
export const StyledBattleStarsHeroImage = styled.img`
  position: absolute;
  flex: 1;
  width: 100%;
  z-index: 100;
  bottom: 0;

  @media screen and ${Device.mobileL} {
    position: unset;
  }
`;
