import {getLocale} from '@epic-core/common';
import {generateRouteTo} from 'epic-fortnite-react';
import PropTypes from 'prop-types';
import React from 'react';
import {Link, withRouter} from 'react-router-dom';
import root from 'window-or-global';
import LoginOverlay from 'epic-fortnite-react/components/common/LoginOverlay';

class ClientDownload extends React.PureComponent {
  static propTypes = {
    supportedOS: PropTypes.array.isRequired,
    className: PropTypes.string,
    preHeaderMsg: PropTypes.string,
    headerMsg: PropTypes.string.isRequired,
    mobileHeaderMsg: PropTypes.string.isRequired,
    iOSDownloadUnavailable: PropTypes.string.isRequired,
    bodyMsg: PropTypes.string.isRequired,
    selectMsg: PropTypes.string,
    pcDownloadUrl: PropTypes.string,
    macDownloadUrl: PropTypes.string,
    xboxLink: PropTypes.string,
    gfnLink: PropTypes.string,
    androidUrl: PropTypes.string,
    returnHomeMsg: PropTypes.string.isRequired,
    mobileMsg: PropTypes.string.isRequired,
    chooseAltPlatformMsg: PropTypes.string.isRequired,
    windowMsg: PropTypes.string,
    iosMsg: PropTypes.string,
    androidMsg: PropTypes.string,
    macMsg: PropTypes.string,
    alertJSX: PropTypes.element,
    showAlert: PropTypes.bool,
    legalTags: PropTypes.array,
    children: PropTypes.node,
  };

  componentDidMount() {
    // auto download the game on a supported platform
    if (this.props.supportedOS.indexOf('windows') !== -1 && root._epicGamesNav.browser.windows) {
      setTimeout(() => {
        if (this.props.pcDownloadUrl) {
          root.location = this.props.pcDownloadUrl;
        } else {
          console.info(
            'pcDownloadUrl is not provided, fail to start download client automatically on windows'
          );
        }
      }, 2000);
    }

    if (this.props.supportedOS.indexOf('mac') !== -1 && root._epicGamesNav.browser.mac) {
      setTimeout(() => {
        if (this.props.macDownloadUrl) {
          root.location = this.props.macDownloadUrl;
        } else {
          console.info(
            'macDownloadUrl is not provided, fail to start download client automatically on Mac'
          );
        }
      }, 2000);
    }
  }

  render() {
    const {className, returnHomeMsg, location} = this.props;
    const legalTags = this.props.legalTags || [];
    const bottomImgs = [];
    const locale = getLocale();
    if (legalTags.length) {
      legalTags.forEach((tag, index) => {
        const temp = (
          <li className="tag-container" key={tag.src}>
            <p className="tag-title accent-color">{tag.title}</p>
            <img className="bottomImg" alt={tag.title} src={tag.src} />
          </li>
        );
        bottomImgs.push(temp);
      });
    }

    return (
      <div className={className}>
        <LoginOverlay preHeaderMsg={this.props.preHeaderMsg} />
        <ul className="downloadLegalTags">{bottomImgs}</ul>
        <Link to={generateRouteTo('/', location, locale)} className="returnLink">
          {returnHomeMsg}
        </Link>
      </div>
    );
  }
}
export default withRouter(ClientDownload);
