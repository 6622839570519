import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

export default class Header extends React.PureComponent {
  static propTypes = {
    title: PropTypes.string,
    android: PropTypes.bool,
  };
  render() {
    const {title, android} = this.props;
    const headerClass = classNames('header-fnMobile-container text-center', {android});
    return (
      <div className={headerClass}>
        <h1 dangerouslySetInnerHTML={{__html: title}} />
      </div>
    );
  }
}
