import {connectToStores} from 'epic-alt-utils';
import React from 'react';
import {RouteComponentProps, withRouter} from 'react-router';
import Immutable from 'seamless-immutable';

import {FooterStore, LoginStore, LocalizationStore, Account} from '../../stores';

import {Footer} from './Footer';

interface Props extends RouteComponentProps {
  appConfigStore: any;
  footerStore: any;
  localizationStore: any;
  loginStore: any;
  history: any;
  i18n: any;
}

@connectToStores
class FooterAlt extends React.Component<Props> {
  static getStores() {
    return [FooterStore, LoginStore, LocalizationStore];
  }

  static getPropsFromStores() {
    return {
      footerStore: FooterStore.getState(),
      loginStore: LoginStore.getState(),
      localizationStore: LocalizationStore.getState(),
    };
  }

  render() {
    const config = this.props.footerStore.config
      ? this.props.footerStore.config.asMutable({deep: true})
      : {};

    const i18n = this.props.localizationStore
      ? {messages: this.props.localizationStore.messages}
      : this.props.i18n || {};

    const loginStore = this.props.loginStore || {};

    return (
      <Footer
        config={config}
        i18n={Immutable.asMutable(i18n, {deep: true})}
        account={Immutable.asMutable(loginStore.accountInfo ?? {}) as Account}
      />
    );
  }
}

export default withRouter(FooterAlt);
