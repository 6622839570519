import {connectToStores} from 'epic-alt-utils';
import Error404 from 'epic-fortnite-react/components/error/Error404';
import Error500 from 'epic-fortnite-react/components/error/Error500';
import PropTypes from 'prop-types';
import React from 'react';

import CosplayMaskActions from '../../actions/CosplayMaskActions';
import StyleBaseline from '../../components/cosplay/components/StyleBaseline';
import FoldableDetailPage from '../../components/cosplay/FoldableDetailPage';
import MaskDetailPage from '../../components/cosplay/MaskDetailPage';
import LoadScreen from '../../components/loadScreen/LoadScreen';
import {CosplayMaskStore} from '../../stores';

const fetchData = (props) => {
  const {
    match: {
      params: {mask},
    },
  } = props;
  CosplayMaskActions.getMask(mask);
};

@connectToStores
class CosplayMaskView extends React.Component {
  static propTypes = {
    cosplayMaskStore: PropTypes.object,
    match: PropTypes.object,
  };

  static getStores() {
    return [CosplayMaskStore];
  }

  static getInitialActions() {
    return [fetchData];
  }

  static getPropsFromStores() {
    return {
      cosplayMaskStore: CosplayMaskStore.getState(),
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.mask !== this.props.match.params.mask) {
      fetchData(this.props);
    }
  }

  render() {
    const {
      match: {
        params: {mask},
      },
    } = this.props;
    const store = this.props.cosplayMaskStore || null;
    if (store.error === true) {
      return <Error500 />;
    }

    const payload = store[mask];
    if (payload?.error) {
      switch (payload.error?.statusCode) {
        case 404:
          return <Error404 />;
        case 500:
          return <Error500 />;
        default:
          console.error('unrecognised error', payload.error);
          return <Error500 />;
      }
    }

    const data = payload?.data;
    if (!data || payload.loading) {
      return <LoadScreen show={true} />;
    }

    switch (data._templateName) {
      case 'cosplay-mask-detail-page':
        return <MaskDetailPage data={data} />;
      case 'cosplay-foldable-detail-page':
        return <FoldableDetailPage data={data} />;
      case 'cosplay-foldable-detail-page-additional-download':
        return <FoldableDetailPage data={data} additionalCarouselSection />;
      default:
        return (
          <StyleBaseline>
            <h1>CosplayMaskView was unable to render page with template {data._templateName}</h1>
          </StyleBaseline>
        );
    }
  }
}

export default CosplayMaskView;
