import styled from 'styled-components';

interface Props {
  gradient?: {
    from?: string;
    to?: string;
  };
}
const Gradient = styled.div<Props>`
  position: relative;
  display: flex;
  justify-content: center;
  background: ${({gradient}) =>
    gradient?.from && gradient?.to
      ? `linear-gradient(180deg, ${gradient.from} 0%, ${gradient.to} 100%)`
      : null};
`;

export default Gradient;
