// Inspired by https://example-react-resize-observer.sethcorker.com/

import {useEffect, useRef, useState} from 'react';
import ResizeObserver from 'resize-observer-polyfill';
import root from 'window-or-global';

const findBreakPoint = (breakpoints, width: number) => {
  const breakpointIndex = breakpoints.map((x) => Object.values(x)[0]).findIndex((x) => width < x);

  if (breakpointIndex === -1) {
    return Object.keys(breakpoints[breakpoints.length - 1])[0];
  }

  return Object.keys(breakpoints[breakpointIndex])[0];
};

const useResponsiveBreakpoints = (
  breakpoints: Array<{[key: string]: number}>,
  elRef: any = undefined
) => {
  const firstQuery = Object.keys(breakpoints[0])[0];
  const [breakSize, setBreakSize] = useState(firstQuery);
  const [loading, setLoading] = useState(true);

  const observer = useRef(
    new ResizeObserver((entries: ResizeObserverEntry[]) => {
      const {width} = entries[0].contentRect;
      setBreakSize(findBreakPoint(breakpoints, width));
    })
  );

  useEffect(() => {
    const _observer = observer?.current;
    const _element = elRef ? elRef.current : root.document.body;

    _element && _observer.observe(_element);
    setLoading(false);

    return () => {
      _element && _observer.unobserve(_element);
    };
  }, [elRef, observer]);

  return {breakSize, loading};
};

export {useResponsiveBreakpoints};
