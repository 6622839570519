/**
 *  @param n {String | Number} - given number to count trailing decimals
 *
 *  @return {Number} - number of traling decimals
 * */
export function countTrailingDecimals(n) {
    const convertedNum = +n;
    if (isFinite(convertedNum)) {
        const numSplit = convertedNum.toString().split('.');
        const decimals = numSplit[1];
        if (decimals) {
            return decimals.length;
        }
    } else {
        console.warn(`countTrailingDecimals() -> unable to count decimals due invalid number value ${n}`);
    }
    return 0;
}

export function formatPriceM2(price, decimals, enforceTrailingDecimals = false, locale = 'en-US') {
    const convertedPrice = +price;
    if (isFinite(price)) {
        if (!enforceTrailingDecimals && countTrailingDecimals(price) === 0) {
            return convertedPrice.toString();
        }

        return Intl.NumberFormat(locale, {
            maximumFractionDigits: decimals,
            minimumFractionDigits: decimals
        }).format(convertedPrice);
    } else {
        console.warn(`formatPriceM2() -> unable to format price due invalid number value ${price}`);
    }

    return price;
}
