import React from 'react';
import {Container, FAQ, FAQProps} from 'epic-fortnite-react';

import {CoreSection} from '../blocks/CoreSection';
import {CoreSectionProps, FunctionalThemeProps} from '../bundles/types';

export type FAQSectionProps = FAQProps & {
  coreSectionProps?: CoreSectionProps;
  theme?: FunctionalThemeProps;
};

export const FAQSection = ({coreSectionProps, theme, ...props}: FAQSectionProps) => {
  return (
    <CoreSection
      coreSectionProps={{
        backgroundColor: theme?.pageBackgroundColor,
        size: coreSectionProps?.size ? coreSectionProps?.size : '3xl',
        ...coreSectionProps,
      }}>
      <Container maxWidth={1200} size="xl">
        <FAQ {...props} />
      </Container>
    </CoreSection>
  );
};
