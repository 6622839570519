import styled from 'styled-components';

import {breakpoints} from '../../../../constants/Breakpoints';

interface Props {
  alternateOrder?: boolean;
}
const Grid = styled.div<Props>`
  display: grid;
  grid-template-columns: ${({alternateOrder}) => (alternateOrder === true ? '7fr 5fr' : '5fr 7fr')};

  @media ${breakpoints.mobileL} {
    display: flex;
  }
`;

export default Grid;
