import {connectToStores} from 'epic-alt-utils';
import Error404 from 'epic-fortnite-react/components/error/Error404';
import PropTypes from 'prop-types';
import React from 'react';
import Helmet from 'react-helmet';

import {
  RetailHeader,
  RetailAvailablePlatforms,
  RetailBundleSection,
  RetailHelpSection,
} from '../../components/retailBundle';
import {RetailBundleStore, HeaderStore} from '../../stores';

@connectToStores
class RetailBundleView extends React.Component {
  static propTypes = {
    retailBundleStore: PropTypes.object,
    tablet: PropTypes.bool,
    mobile: PropTypes.bool,
  };

  static getStores() {
    return [RetailBundleStore, HeaderStore];
  }

  static getPropsFromStores() {
    return {
      retailBundleStore: RetailBundleStore.getState(),
      mobile: HeaderStore.getState().mobile(),
      tablet: HeaderStore.getState().tablet(),
    };
  }

  render() {
    const {mobile, tablet} = this.props;
    const {deepFreezeData} = this.props.retailBundleStore;
    if (deepFreezeData.disable) {
      return <Error404 />;
    }
    const tags = [
      {itemProp: 'name', content: deepFreezeData.metaTitle},
      {itemProp: 'description', content: deepFreezeData.metaDescription},
      {itemProp: 'image', content: deepFreezeData.metaImage},
      {name: 'description', content: deepFreezeData.metaDescription},
      {name: 'twitter:title', content: deepFreezeData.metaTitle},
      {name: 'twitter:description', content: deepFreezeData.metaDescription},
      {name: 'twitter:image', content: deepFreezeData.metaImage},
      {property: 'og:title', content: deepFreezeData.metaTitle},
      {property: 'og:image', content: deepFreezeData.metaImage},
      {property: 'og:description', content: deepFreezeData.metaDescription},
    ];
    const {header, platforms, bundle, helpLink} = deepFreezeData;
    return (
      <div className={`retail-bundle-view ${deepFreezeData._slug}`}>
        <Helmet meta={tags} title={deepFreezeData.metaTitle} />
        <RetailHeader data={header} isMobile={mobile || tablet} />
        <RetailAvailablePlatforms data={platforms} />
        <RetailBundleSection data={bundle} />
        <RetailHelpSection
          link={helpLink}
          background={header.background}
          isMobile={mobile || tablet}
        />
      </div>
    );
  }
}

export default RetailBundleView;
