import styled from 'styled-components';

import {breakpoints} from '../../../constants/Breakpoints';

const HowToHeader = styled.h2`
    width: 100%; // Fix weird Safari bug causing random line breaks
    &&& {
        font-size: 6rem;
    }

    @media ${breakpoints.mobileL} {
        &&& {
            font-size: 3.5rem;
        }
    }}
`;

export default HowToHeader;
