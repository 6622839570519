import styled from 'styled-components';

import BaseCTA, {Props as CTAProps} from './BaseCTA';

interface Props extends Omit<CTAProps, 'history' | 'location' | 'match' | 'staticContext'> {
  background?: string;
}

const CTA = styled(BaseCTA)<Props>`
  &&&&& {
    background-color: ${({background}) => background};
  }
`;

export default CTA;
