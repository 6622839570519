import React from 'react';

import CTA from '../components/CTA';
import MaskType from '../components/MaskType';
import ResponsiveImage from '../components/ResponsiveImage';
import Section from '../components/Section';
import SpikeDivider from '../components/SpikeDivider';
import {Background, CTA as ICTA, ResponsiveImage as IResponsiveImage} from '../types';

import {ImageWrapper, InfoWrapper} from './components';

export interface GenericExploreSectionProps {
  header?: string;
  accent?: string;
  background?: Background;
  image?: IResponsiveImage;
  type?: string;
  cta?: ICTA;
}

const GenericExploreSection = ({
  accent,
  header,
  background,
  image,
  type,
  cta,
}: GenericExploreSectionProps) => {
  return (
    <Section gradient={background}>
      <SpikeDivider position="top" background={background?.from} reversed={true} />
      <ImageWrapper>
        <ResponsiveImage
          desktopSrc={image?.desktopImage}
          tabletSrc={image?.tabletImage}
          mobileSrc={image?.mobileImage}
          alt={image?.altText}
        />
      </ImageWrapper>
      <InfoWrapper>
        <MaskType accent={accent}>{type}</MaskType>
        <h1>{header}</h1>
        {cta ? <CTA ctaConfig={cta} background={accent} /> : null}
      </InfoWrapper>
    </Section>
  );
};

export default GenericExploreSection;
