import styled, {css, keyframes} from 'styled-components';

const glow = keyframes`
    from {
        box-shadow: 0 0 5px 5px rgba(255, 255, 255, 0.2);
    }
    to {
        box-shadow: 0 0 12px 14px rgba(255, 255, 255, 0.2);
    }
`;

const FeatureIconContainer = styled.div<{active: boolean}>`
  position: relative;
  height: 100px;
  width: 100px;
  border-radius: 50%;
  cursor: pointer;
  flex: 0 0 auto;
  margin: 0 1em;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    z-index: 0;
    border-radius: 50%;

    ${(props) =>
      props.active
        ? css`
            transform: scale3d(1.05, 1.05, 1.05);
            transition: transform 1s ease-in-out;
          `
        : css`
            transform: scale3d(0, 0, 0);
          `};
  }

  ${(props) =>
    props.active
      ? css`
          animation: ${glow} 1.5s ease-in-out infinite;
          animation-direction: alternate;
          animation-delay: 1s;
        `
      : null}

  @media screen and (max-width: 940px) {
    width: 85px;
    height: 85px;
    margin: 0 0.5em;
  }
`;

export default FeatureIconContainer;
