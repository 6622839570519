import generateWithAsyncActions from 'epic-alt-utils';
import alt from 'epic-fortnite-react/alt';

/**
 * PlayerActions
 *
 * This is simply an example of what an alt action should look like.
 *
 * It uses the utility method generateWithAsyncActions to generate action names.
 * All it does is auto generate additional actions for each element in the array.
 *   Players
 *   PlayersSuccess
 *   PlayersFailure
 *   PlayersStarting
 */

export default generateWithAsyncActions(alt, ['getUserName']);
