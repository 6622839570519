import PropTypes from 'prop-types';
import React from 'react';

import VideoOverlayContext, {VideoOverlayConfig} from './VideoOverlayContext';

const VideoOverlayProvider = ({children}) => {
  const [config, setConfig] = React.useState<VideoOverlayConfig>({});
  return (
    <VideoOverlayContext.Provider value={{config, setConfig}}>
      {children}
    </VideoOverlayContext.Provider>
  );
};

VideoOverlayProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.object), PropTypes.object]),
};

export default VideoOverlayProvider;
