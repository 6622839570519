import {Container, Section, Stack, Text, useBreakpoints} from 'epic-fortnite-react';
import {FontSizes} from 'epic-fortnite-react/types/theme';
import epicTracking from 'epic-tracking';
import React from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import styled from 'styled-components';

// import AnimateString from '../common/AnimateString';

interface Props {
  coreSectionProps?: any;
  image: string;
  index: number;
  imageAlt: string;
  title: string;
  description: string;
}

const StyledScreenItem = styled.div`
  &:not(:last-child) {
    margin-bottom: 64px;
  }
`;

const StyledImage = styled.div`
  background-color: #fff;
  border: 8px solid #fff;

  img {
    clip-path: polygon(0 1%, 100% 0, 99% 99%, 1% 100%);
    display: block;
    max-width: 100%;
  }
`;

const ScreenItem = ({image, index, imageAlt, title, description, coreSectionProps}: Props) => {
  const [visible, setVisibility] = React.useState(false);

  const headingSize: FontSizes = useBreakpoints('3xl', {900: '5xl'}).value;
  const bodySize: FontSizes = useBreakpoints('med', {900: 'lg'}).value;

  const toggleVisibility = React.useCallback(
    (visibility) => {
      setVisibility(visibility);
      if (visibility) {
        epicTracking.trackEvent('Interaction', {
          interactionType: 'scrollViewSection',
          eventCategory: 'OverviewSeasonSection',
          eventAction: title,
        });
      }
    },
    [setVisibility, title]
  );

  return (
    <StyledScreenItem>
      <VisibilitySensor
        onChange={toggleVisibility}
        active={!visible}
        partialVisibility
        minTopValue={0}>
        <Section
          backgroundColor={coreSectionProps?.backgroundColor}
          backgroundImage={coreSectionProps?.backgroundImage}
          backgroundPosition={coreSectionProps?.backgroundPosition}
          backgroundSize={coreSectionProps?.backgroundSize}
          key={`screen-${index}`}
          size={coreSectionProps?.size}
          textAlign="center">
          <Container maxWidth={1200} size="lg">
            <Stack gap="2xl">
              <StyledImage>
                <img src={image} alt={imageAlt || `screen-${index}`} />
              </StyledImage>
              <Container maxWidth={900}>
                <Stack gap="xl" align="center">
                  <Text italic={true} size={headingSize} transform="uppercase">
                    {title}
                  </Text>
                  <Text size={bodySize} transform="uppercase">
                    {description}
                  </Text>
                </Stack>
              </Container>
            </Stack>
          </Container>
        </Section>
      </VisibilitySensor>
    </StyledScreenItem>
  );
};

export default ScreenItem;
