import React from 'react';

import RiftTourFaq from './RiftTourFaq';
import RiftTourHeader from './RiftTourHeader';
import RiftTourItems from './RiftTourItems';
import RiftTourQuests from './RiftTourQuests';
import RiftTourSchedule from './RiftTourSchedule';
import RiftTourTiktokPromo from './RiftTourTiktokPromo';

const RiftTourSection = ({section}: any) => {
  switch (section._templateName) {
    case 'rift-tour-header': {
      return <RiftTourHeader data={section} />;
    }
    case 'rift-tour-quests': {
      return <RiftTourQuests data={section} />;
    }
    case 'rift-tour-tiktok-promo': {
      return <RiftTourTiktokPromo data={section} />;
    }
    case 'rift-tour-schedule': {
      return <RiftTourSchedule data={section} />;
    }
    case 'rift-tour-items': {
      return <RiftTourItems data={section} />;
    }
    case 'rift-tour-faq': {
      return <RiftTourFaq data={section} />;
    }
    default:
      return <div />;
  }
};

export default RiftTourSection;
