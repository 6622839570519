import {withLocalization} from '@epic-core/localization';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import LandingPageSection from './LandingPageSection';
import {getBackgroundStyle} from './utils';

/**
 * Presents background for a list of sections.
 */
class BackgroundWithSections extends React.Component {
  static propTypes = {
    mobileStore: PropTypes.object.isRequired,
    appConfigStore: PropTypes.object.isRequired,
    playerStore: PropTypes.object.isRequired,
    footerStore: PropTypes.object.isRequired,
    countryStore: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired,
    messages: PropTypes.object.isRequired,
    mobile: PropTypes.bool,
    tablet: PropTypes.bool,
    isLoggedIn: PropTypes.bool,
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };

  render() {
    const {
      data,
      mobile,
      tablet,
      isLoggedIn,
      history,
      mobileStore,
      appConfigStore,
      playerStore,
      footerStore,
      countryStore,
      match,
      messages,
    } = this.props;
    // Render any item with a name starting with section as a page section.
    const sectionComponents = Object.keys(data)
      .sort()
      .filter((key) => /^section/.test(key))
      .map((section, index) => {
        const sectionData = {data: data[section]};
        return (
          <LandingPageSection
            key={`section${index}${section}`}
            mobileStore={mobileStore}
            appConfigStore={appConfigStore}
            playerStore={playerStore}
            footerStore={footerStore}
            countryStore={countryStore}
            mobile={mobile}
            tablet={tablet}
            messages={messages}
            section={sectionData}
            isLoggedIn={isLoggedIn}
            match={match}
            history={history}
          />
        );
      });
    const backgroundStyle = getBackgroundStyle(data, tablet, mobile);
    const {theme} = data;
    const containerClasses = classNames('BgSec-container', (theme || '').split(','));
    const wrapperClasses = classNames('wrapper', {
      nobackground: !backgroundStyle.backgroundImage,
    });
    return (
      <div className={containerClasses}>
        <div className={wrapperClasses} style={backgroundStyle}>
          {sectionComponents}
        </div>
      </div>
    );
  }
}

export default withLocalization(BackgroundWithSections);
