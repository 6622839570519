import {withLocalization} from '@epic-core/localization';
import classNames from 'classnames';
import {connectToStores} from 'epic-alt-utils';
import HttpErrorScreen from 'epic-fortnite-react/components/error/HttpErrorScreen';
import LoadScreen from 'epic-fortnite-react/components/loadScreen/LoadScreen';
import AppConfigStore from 'epic-fortnite-react/stores/AppConfigStore';
import FooterStore from 'epic-fortnite-react/stores/FooterStore';
import HeaderStore from 'epic-fortnite-react/stores/HeaderStore';
import MobileStore from 'epic-fortnite-react/stores/MobileStore';
import {generateRouteHref} from 'epic-fortnite-react';
import PropTypes from 'prop-types';
import React from 'react';
import root from 'window-or-global';

import ClientDownload from '../../components/download/ClientDownload';

import {CmsStore} from '../../stores';
import {fetchCmsData} from '../../utils/CmsStoreUtils';
import MetaTags from '../../components/common/MetaTags';

const slug = 'download';

/**
 * DownloadView
 *
 * renders with updated meta and content data from cms download page
 */

const DownloadView = (props) => {
  const {
    cmsStore: {pages},
    location,
    locale,
    messages,
    appConfigStore,
    mobileStore,
    footerStore,
    fnMobile,
    callToActionDropdown,
  } = props;

  if (!pages[slug]) return <HttpErrorScreen statusCode={404} />;

  const {loading: pageLoading, data: page, error: pageError} = pages[slug];

  if (pageLoading) return <LoadScreen show />;
  if (pageError) return <HttpErrorScreen statusCode={pageError?.statusCode} />;

  if (root._epicGamesNav.browser.windows && !fnMobile) {
    setTimeout(() => {
      root.location = appConfigStore.pcDownloadUrl;
    }, 2000);
  }

  const mobileData = mobileStore.mobileData.codeConfirm;
  const legalTags = footerStore.config.legalTags || [];
  const callToActionDropdownLinks = callToActionDropdown.links || [];
  const disableAndroid =
    mobileStore.androidMobileData.hideDownload && mobileStore.androidMobileData.createAccount;
  let supportedDevices = ['windows', 'android'];
  if (disableAndroid) {
    supportedDevices = ['windows'];
  }
  const containerClass = classNames('downloadView', {fnMobile});

  // altPlaftformLinks returns hrefTemplate from link matching "key" argument

  const altPlatformLinks = (key) => {
    let platformLink = '';
    callToActionDropdownLinks?.forEach((link) => {
      if (link.key === key) {
        platformLink = link.hrefTemplate;
      }
    });
    return platformLink;
  };

  return (
    <div className={`${containerClass} theEnd-background-override theEnd-all-children-override`}>
      <MetaTags pageModel={page} />
      <ClientDownload
        supportedOS={supportedDevices}
        className="site-block"
        preHeaderMsg={page.content}
        headerMsg={messages['epic.fortnite.download.header']}
        mobileHeaderMsg={mobileData.title}
        iOSDownloadUnavailable={messages['epic.fortnite.download.unavailable']}
        bodyMsg={messages['epic.fortnite.download.body']}
        selectMsg={messages['epic.fortnite.download.select']}
        pcDownloadUrl={appConfigStore.pcDownloadUrl}
        macDownloadUrl={appConfigStore.macDownloadUrl}
        xboxMsg={messages['epic.common.xbl']}
        xboxLink={altPlatformLinks('xcloud')}
        gfnLink={altPlatformLinks('gfn')}
        androidUrl={generateRouteHref('/mobile/android', location, locale)}
        returnHomeMsg={messages['epic.fortnite.download.return']}
        mobileMsg={messages['epic.fortnite.download.mobile']}
        chooseAltPlatformMsg={messages['epic.fortnite.download.chooseanother']}
        windowMsg={messages['epic.common.windows']}
        macMsg={messages['epic.common.mac']}
        iosMsg={messages['epic.common.ios']}
        androidMsg={messages['epic.common.android']}
        legalTags={legalTags}
      />
    </div>
  );
};

DownloadView.propTypes = {
  cmsStore: PropTypes.object,
  location: PropTypes.object,
  messages: PropTypes.object,
  appConfigStore: PropTypes.object,
  mobileStore: PropTypes.object,
  footerStore: PropTypes.object,
  fnMobile: PropTypes.bool,
  mobile: PropTypes.bool,
  callToActionDropdown: PropTypes.object,
};

DownloadView.getStores = () => [CmsStore, AppConfigStore, MobileStore, HeaderStore];

DownloadView.getInitialActions = () => [fetchCmsData(slug)];

DownloadView.getPropsFromStores = () => ({
  cmsStore: CmsStore.getState(),
  appConfigStore: AppConfigStore.getState(),
  mobileStore: MobileStore.getState(),
  footerStore: FooterStore.getState(),
  mobile: HeaderStore.getState().mobile(),
  callToActionDropdown: HeaderStore.getState().config.callToActionDropdown,
});

export default withLocalization(connectToStores(DownloadView));
