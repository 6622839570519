import {getLocale} from '@epic-core/common';
import {SlickWrapper} from 'epic-react-common';
import PropTypes from 'prop-types';
import React from 'react';
import {Link, withRouter} from 'react-router-dom';

import Background from '../common/Background';
import CommonCTABtn from '../common/CommonCTABtn';
import VideoOverlay from '../common/VideoOverlay';
import VideoPlayButton from '../common/VideoPlayButton';
import {localizedMessage} from '../message/Message';
import {generateRouteTo} from '../../utils/route';

import './Carousel.sass';

class Carousel extends React.PureComponent {
  static propTypes = {
    slides: PropTypes.array,
    mobile: PropTypes.bool,
    tablet: PropTypes.bool,
    isAndroid: PropTypes.bool,
    breakPoint: PropTypes.number,
  };

  state = {
    showVideo: false,
    videoId: '',
  };

  showVideo = (videoId) => {
    this.setState({
      showVideo: true,
      videoId,
    });
  };

  closeOverlay = () => {
    this.setState({
      showVideo: false,
      videoId: '',
    });
  };

  render() {
    const {slides = [], mobile, tablet, breakPoint = 768, isAndroid = false, location} = this.props;
    const list = [];
    const locale = getLocale();

    for (let i = 0; i < slides.length; i++) {
      const slide = slides[i];
      const key = `item-${i}`;
      const {
        background,
        captionPos = 'center',
        captionTitle,
        captionDesc,
        captionImage,
        button,
        videoId,
        watchLabel,
        textColor,
        secondaryTextColor,
        type = '',
        androidDownload,
      } = slide;
      const style = textColor ? {color: textColor} : null;
      const secondaryStyle = secondaryTextColor ? {color: secondaryTextColor} : null;
      const showAndroidDownloadBtn = isAndroid && androidDownload;

      list.push(
        <div className={`slide ${type}`} key={key}>
          <Background
            mobile={mobile}
            tablet={tablet}
            background={background}
            breakPoint={breakPoint}
          />
          <div className={`caption ${captionPos}`}>
            {captionImage && <img src={captionImage} alt="badge" />}
            {captionTitle && <h1 style={style}>{captionTitle}</h1>}
            {captionDesc && <h3 style={secondaryStyle}>{captionDesc}</h3>}
            {showAndroidDownloadBtn ? (
              <Link
                to={generateRouteTo('/mobile/android', location, locale)}
                className="btn btn-primary btn-hover-transform">
                {localizedMessage('epic.fortnite.battleRoyale.playFreeNow')}
              </Link>
            ) : (
              <CommonCTABtn ctaConfig={button} className="btn-hover-transform" />
            )}
          </div>
          {videoId && (
            <div className="play-wrapper">
              <h3>{watchLabel}</h3>
              <VideoPlayButton onClick={() => this.showVideo(videoId)} className="bp-videoplay" />
            </div>
          )}
        </div>
      );
    }

    const sliderOptions = {
      adaptiveHeight: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: true,
      autoplay: true,
      autoplaySpeed: 5000,
      dotsClass: 'carousel-dots bottom',
      arrows: false,
      draggable: false,
      swipeToSlide: true,
      swipe: true,
      touchMove: true,
      fade: false,
      pauseOnHover: false,
    };
    return (
      <div id="mainCarousel">
        <SlickWrapper options={sliderOptions}>{list}</SlickWrapper>
        {this.state.videoId && (
          <VideoOverlay
            videoID={this.state.videoId}
            isLauncher={false}
            play={this.state.showVideo}
            close={this.closeOverlay}
            closeButton
            show={this.state.showVideo}
          />
        )}
      </div>
    );
  }
}

export default withRouter(Carousel);
