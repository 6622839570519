import axios from 'axios';
import root from 'window-or-global';

import RiftTourActions from '../actions/RiftTourActions';
import Url from '../constants/Url';

const RiftTourSource = {
  getPage: {
    remote(state, locale) {
      return axios.get(Url().RIFTTOUR.GET(locale));
    },
    success: RiftTourActions.getPageSuccess,
    error: RiftTourActions.getPageFailure,

    shouldFetch() {
      return !root.__server_side_render;
    },
  },
};

export default RiftTourSource;
