import styled from 'styled-components';

import {breakpoints} from '../../../../constants/Breakpoints';

interface Props {
  alternateOrder?: boolean;
}

const MaskImageWrapper = styled.div<Props>`
  ${({alternateOrder = false}) => (alternateOrder === true ? `order: -1;` : null)}

  & img {
    width: 100%;
  }

  @media ${breakpoints.mobileL} {
    width: 100vw;
  }
`;

export default MaskImageWrapper;
