import {Device} from 'epic-fortnite-styles/constants';
import styled from 'styled-components';

import {StyledCreativeCoreTextWrapper} from './';

const StyledCreativeFAQWrapper = styled.section`
  width: 50%;
  margin: 0 auto;
  padding-bottom: 4em;
  &&& h6 {
    font-size: 1.2em;
    color: #00c6ff;
  }

  &&& p {
    font-family: sans-serif;
    text-transform: none;
  }

  .subscription-faq-item-wrapper {
    display: block;
  }

  @media screen and ${Device.laptop} {
    width: 80%;
    padding: 1em;
    ${StyledCreativeCoreTextWrapper} {
      width: 100%;
    }
  }

  @media screen and ${Device.mobileL} {
    width: 100%;
    padding: 1em;
  }
`;

export default StyledCreativeFAQWrapper;
