import root from 'window-or-global';

export default function () {
  /**
   * Enum for URLs for the API endpoints.
   */
  let appContext = '';
  if (root.appContext && root.appContext !== '/') {
    ({appContext} = root);
  }

  if (!root.location.origin) {
    const port = root.location.port ? `:${root.location.port}` : '';
    root.location.origin = `${root.location.protocol}//${root.location.hostname}${port}`;
  }
  const origin = root.apiHost ? root.apiHost : root.location.origin + appContext;

  const URL = {
    LOGIN: {
      INFO(locale) {
        return `${origin}/${locale}/api/accountInfo`;
      },
      PREREGISTER: `${origin}/api/pre-register`,
    },

    SEARCH: {
      BING: `${origin}/api/bing/search`,
    },

    REGISTER: (locale) => `${origin}/${locale}/event/register`,

    MOBILEREDEEMED: (locale) => `${origin}/${locale}/event/code-redeemed`,

    MOBILEENTITLEMENT: `${origin}/api/grant-mobile-entitlement`,

    DOWNLOAD: {
      EPIC_SID: `${origin}/api/epicSid`,
    },

    STORE: {
      FEATURED: `${origin}/marketplace/api/featured`,
      CATEGORIES: `${origin}/marketplace/api/categories`,
      CATEGORY: (category) => `${origin}/marketplace/api/categories/${category}`,
      ASSET: (slug) => `${origin}/marketplace/api/asset/${slug}`,
      FOUNDERS: `${origin}/api/founders-packs`,
      SUBSCRIPTION: `${origin}/api/subscription-offer`,
      RESETPURCHASED: `${origin}/marketplace/refresh-purchased-cache`,
      WELCOME: `${origin}/api/welcome-email`,
    },
  };

  return URL;
}
