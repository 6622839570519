import PropTypes from 'prop-types';
import React from 'react';

import LoadIndicator from './LoadIndicator';

const LoadScreen = (props) => {
  if (props.show) {
    return (
      <div className="loadscreen-overlay">
        <LoadIndicator />
      </div>
    );
  }
  return <div />;
};

LoadScreen.propTypes = {
  show: PropTypes.bool,
};

export default LoadScreen;
