import styled, {css} from 'styled-components';

import {Device} from '../../../constants';

interface Props {
  bkImage?: string;
  active: boolean;
}
const GradientBackground = styled.div<Props>`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: ${(props) => (props.active ? 1 : 0)};
  perspective: 600px;
  overflow: hidden;

  ${(props) =>
    props.bkImage
      ? css`
          background-image: url(${props.bkImage});
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
        `
      : null}

  transition: opacity 500ms ease-in-out;

  &:after,
  &:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    height: 10vh;
    z-index: 1;
  }
  &:before {
    top: 0;
    background: linear-gradient(0deg, rgba(27, 0, 46, 0) 0%, rgba(27, 0, 46, 1) 100%);
  }
  &:after {
    bottom: 0;
    background: linear-gradient(0deg, rgba(27, 0, 46, 1) 0%, rgba(27, 0, 46, 0) 100%);
  }

  @media screen and (max-width: 940px) {
    align-items: flex-start;
    padding-top: 15%;
  }
  @media screen and ${Device.mobileL} {
    padding-top: 3%;
    background-position: 50% -70px;
    &:after,
    &:before {
      height: 5vh;
    }
  }
`;

export default GradientBackground;
