import {motion} from 'framer-motion';
import React from 'react';
import styled from 'styled-components';

import {Device} from '../../../constants';

const VBucksImage = styled(({...rest}) => <motion.img {...rest} />)`
  position: absolute;
  top: 6%;
  right: -22%;
  width: 85%;
  height: auto;
  display: block;
  transition: width 200ms ease-in-out;

  @media screen and (max-width: 1265px) {
    width: 950px;
  }

  @media screen and (max-width: 940px) {
    position: relative;
    right: 0;
    top: 0;
  }
  @media screen and ${Device.mobileL} {
    width: 545px;
  }
`;

export default VBucksImage;
