import {FAQSection} from 'epic-fortnite-react';
import React, {useState} from 'react';
import VisibilitySensor from 'react-visibility-sensor';

import {CreativeCoreText, StyledCreativeFAQWrapper} from './';

interface Props {
  items: any;
  header: string;
}

const CreativeFaqSection = (props: Props) => {
  const {items, header} = props;
  const [isVisible, setVisibility] = useState(false);
  const onChange = (): void => {
    setVisibility(!isVisible);
  };

  return (
    <VisibilitySensor onChange={onChange} partialVisibility minTopValue={10}>
      <StyledCreativeFAQWrapper id="faq-section">
        <CreativeCoreText header={header} />
        <FAQSection itemList={items} />
      </StyledCreativeFAQWrapper>
    </VisibilitySensor>
  );
};

export default CreativeFaqSection;
