import {getLocale} from '@epic-core/common';
import {CmsComponentMapper} from '@epic-mw/react-cms';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import forEach from 'lodash.foreach';
import root from 'window-or-global';

import {generateTitle, getMetaTags, getUniqueMetaData} from '../../utils/metaUtils';
import LinkNavigator from '../common/LinkNavigator';
import Message from '../message/Message';

import ShareIcons from './ShareIcons';
import RichHtmlSection from './RichHtmlSection';
import AddToCalendar from './AddToCalendar';
import Sketchfab from './Sketchfab';

const getScrollTop = function getScrollTop(isLauncher) {
    if (typeof root.document === 'undefined') {
        return 0;
    } else if (isLauncher) {
        const wrapperId = root.__reactWrapper || 'paragonReactWrapper';
        const documentElement = root.document.getElementById(wrapperId);
        return documentElement ? documentElement.scrollTop : 0;
    }
    return root.pageYOffset || root.document.documentElement.scrollTop;
};

export default class BlogDetail extends React.Component {
    static propTypes = {
        blog: PropTypes.object,
        enableLinkNavigator: PropTypes.bool,
        enableSocialMetrics: PropTypes.bool,
        isLauncher: PropTypes.bool,
        config: PropTypes.object,
        rootPageSlug: PropTypes.string,
        disableShareIcons: PropTypes.bool,
        overrideMetaTags: PropTypes.array,
        overrideMetaTitle: PropTypes.string,
        componentMapping: PropTypes.object
    };

    static defaultProps = {
        rootPageSlug: 'news' // Default to getting 'news' content
    };

    constructor(props) {
        super(props);
        this.state = {
            current: -1,
            fixSocialPos: false,
            topAlign: false,
            bottomAlign: false
        };
    }

    /**
     * Add scroll eventListener
     */
    componentDidMount() {
        const wrapperId = root.__reactWrapper || 'paragonReactWrapper';
        const isLauncher = this.props.isLauncher && root.document.getElementById(wrapperId);
        if (isLauncher) {
            root.document
                .getElementById(wrapperId)
                .addEventListener('scroll', this.handleScroll, true);
        } else {
            root.addEventListener('scroll', this.handleScroll);
            this.initLightbox(this.props.blog);
        }
    }

    /**
     * Remove scroll eventListener
     */
    componentWillUnmount() {
        const wrapperId = root.__reactWrapper || 'paragonReactWrapper';
        const isLauncher = this.props.isLauncher && root.document.getElementById(wrapperId);
        if (isLauncher) {
            root.document
                .getElementById(wrapperId)
                .removeEventListener('scroll', this.handleScroll);
        } else {
            root.removeEventListener('scroll', this.handleScroll);
            forEach(this.images, (item, index) => {
                item.removeEventListener('click', this.showLightbox);
            });
        }
    }

    /**
     * Scroller event handler
     * Make social icons fixed position while scrolling
     * @param  {object} event scroll event
     */
    handleScroll = (event) => {
        if (!event) {
            return;
        }
        const headerRef = this.blogHeader;
        const cmsRef = this.cmsContainer;
        const scrollTop = getScrollTop(this.props.isLauncher);
        const headerHeight = headerRef ? headerRef.offsetHeight + 52.8 : 0;
        const cmsHeight = cmsRef ? cmsRef.offsetHeight + 154 : 0;
        const removeFix = scrollTop >= cmsHeight;
        const fixPosition = scrollTop > headerHeight && !removeFix;
        if (fixPosition && !this.state.fixSocialPos) {
            this.setState({
                fixSocialPos: fixPosition,
                topAlign: fixPosition,
                bottomAlign: false
            });
        } else if (!fixPosition && this.state.fixSocialPos) {
            this.setState({
                fixSocialPos: fixPosition,
                topAlign: fixPosition,
                bottomAlign: false
            });
        }
        if (removeFix && !fixPosition && !this.state.bottomAlign) {
            this.setState({
                bottomAlign: removeFix
            });
        }
        if (scrollTop === 0 && !removeFix) {
            this.setState({
                bottomAlign: removeFix
            });
        }
    };

    componentDidUpdate(prevProps) {
        const prevBlog = prevProps.blog || {};
        const blog = this.props.blog || {};
        if (blog._id && blog._id !== prevBlog._id) {
            this.initLightbox(blog);
        }
    }

    initLightbox(blog = {}) {
        if (!blog.enableLightbox) return;

        const section = root.document.getElementById('cmsSection');
        this.images = section.getElementsByTagName('img');
        forEach(this.images, (item, index) => {
            item.setAttribute('data-idx', index);
            item.addEventListener('click', this.showLightbox);
        });
    }

    showLightbox = (e) => {
        root.document.body.className += ' hidden-scroll';
        const idx = parseInt(e.target.dataset.idx, 10) || 0;
        this.setState({current: idx});
    };

    hideLightbox = (e) => {
        root.document.body.className = root.document.body.className.replace(' hidden-scroll', '');
        e.preventDefault();
        this.setState({current: -1});
    };

    prevImage = (e) => {
        e.stopPropagation();
        let current = this.state.current - 1;
        if (current < 0) current = this.images.length - 1;
        this.setState({current});
    };

    nextImage = (e) => {
        e.stopPropagation();
        let current = this.state.current + 1;
        if (current >= this.images.length) current = 0;
        this.setState({current});
    };

    /**
     * Generated Meta Tags for sharing
     * @param  {object} blog     post data
     * @param  {config} hashTags example: "ue4, epicGames", optional
     * @return {array}  meta
     */
    generateMetaTags(blog, config, metaTitle = this.props.overrideMetaTitle) {
        let meta = getMetaTags(getLocale(), blog, config.twitterDefaultTags, metaTitle);
        meta = getUniqueMetaData(meta);
        return meta.concat(this.props.overrideMetaTags || []);
    }

    render() {
        const {enableLinkNavigator, config} = this.props;
        const blog = this.props.blog || {};
        const alt = blog.trendingImageAlt || '';
        const locale = getLocale();
        const date = !blog.date
            ? ''
            : new Date(blog.date).toLocaleDateString(locale.replace(/_/g, '-'), {
                  month: 'numeric',
                  year: 'numeric',
                  day: 'numeric'
              });
        const numericDate = date.replace(/\//g, '.');
        const noTopImage = this.props.blog.noTopImage === true || !blog.trendingImage;
        const showTranslateMessage = this.props.blog.showTranslateMessage;
        const contentArray = blog.items ? blog.items : [{content: {content: blog.content}}]; //required for (backwards) compatibility. Old CMS template only provides blog.content
        const metaTitle = generateTitle(blog, this.props.overrideMetaTitle);
        const meta = this.generateMetaTags(blog, config, metaTitle);
        const style = !noTopImage
            ? {
                  backgroundImage: `url("${blog.trendingImage}")`,
                  backgroundPosition: 'top'
              }
            : {};
        const containerClass = classNames('blog-container', {'no-image': noTopImage});
        const enableLightbox = blog.enableLightbox || false;
        const componentMapping = {
            content: RichHtmlSection,
            addToCalendar: AddToCalendar,
            sketchfab: Sketchfab,
            ...this.props.componentMapping
        };

        return (
            <div className={containerClass}>
                <Helmet meta={meta} title={metaTitle} />
                {showTranslateMessage ? (
                    <div className="blog-translate-message">
                        <Message code="epic.blog.translateMessage" />
                    </div>
                ) : (
                    ''
                )}
                {!noTopImage ? (
                    <div
                        ref={(c) => {
                            this.blogHeader = c;
                        }}
                        className="blog-header"
                        style={style}>
                        <img className="invisible-image" alt={alt} src={blog.trendingImage} />
                    </div>
                ) : null}
                <section className="blog-article">
                    <div className="blog-content container-fluid">
                        <article>
                            <div
                                ref={(c) => {
                                    this.cmsContainer = c;
                                }}
                                className="cmsContainer">
                                <ShareIcons
                                    config={this.props.config}
                                    meta={meta}
                                    blog={blog}
                                    enableSocialMetrics={this.props.enableSocialMetrics}
                                    disableShareIcons={this.props.disableShareIcons}
                                    topAlign={this.state.topAlign}
                                    fixSocialPos={this.state.fixSocialPos}
                                    bottomAlign={this.state.bottomAlign}
                                />
                                <div className="blog-header-info">
                                    <div className="row">
                                        <div className="col-xs-12">
                                            <h1 className="blog-header-title">{blog.title}</h1>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-xs-12">
                                            {blog.subtitle ? (
                                                <span className="blog-header-subtitle">
                                                    {blog.subtitle}
                                                </span>
                                            ) : (
                                                ''
                                            )}
                                            <span className="blog-header-date">{numericDate}</span>
                                        </div>
                                    </div>
                                    <div className="row">
                                        {blog.author && (
                                            <div className="blog-header-author col-xs-12">
                                                {blog.author}
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <section
                                    id="cmsSection"
                                    className={`cms ${enableLightbox && 'lightbox-enabled'}`}>
                                    <CmsComponentMapper
                                        dynamicPageDataArray={contentArray}
                                        componentMapping={componentMapping}
                                    />
                                </section>
                            </div>
                        </article>
                    </div>
                    {enableLinkNavigator && (
                        <LinkNavigator
                            nextExtLink={blog.nextExtLink}
                            prevExtLink={blog.prevExtLink}
                            blogNextSlug={blog.nextSlug}
                            blogPrevSlug={blog.prevSlug}
                            routerRootUrl={this.props.routerRootUrl}
                            locale={locale}
                        />
                    )}
                </section>
                {
                    // eslint-disable-next-line
                }
                {this.state.current >= 0 && (
                    <div className="lightbox" onClick={this.hideLightbox} role="presentation">
                        <img src={this.images[this.state.current].src} alt="" />
                        <button
                            title="Close"
                            className="close epic-blog-icon-close"
                            onClick={this.hideLightbox}
                        />
                        <button
                            title="Previous image"
                            className="prev epic-blog-icon-leftArrow"
                            onClick={this.prevImage}
                        />
                        <button
                            title="Next image"
                            className="next epic-blog-icon-rightArrow"
                            onClick={this.nextImage}
                        />
                    </div>
                )}
            </div>
        );
    }
}
