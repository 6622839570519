import React from 'react';
import styled from 'styled-components';

import {Inline, Stack, Text} from '../';

export interface FieldLabelProps {
  description?: React.ReactNode;
  descriptionId?: string;
  htmlFor: string;
  id?: string;
  label?: React.ReactNode;
  secondaryLabel?: React.ReactNode;
  tertiaryLabel?: React.ReactNode;
}

const StyledLabel = styled.label`
  margin: 0;
  font-weight: normal;
`;

export const FieldLabel = ({
  description,
  descriptionId,
  htmlFor,
  id,
  label,
  secondaryLabel,
  tertiaryLabel,
}: FieldLabelProps) => {
  if (!label) {
    return null;
  }

  return (
    <Stack gap="sm">
      <StyledLabel id={id} htmlFor={htmlFor} style={{marginBottom: 0}}>
        <Inline align="center" gap="xs" justify="space-between">
          <Text size="med" transform="uppercase">
            <strong>{label}</strong>
            {secondaryLabel && <span>{` (${secondaryLabel})`}</span>}
          </Text>
          {tertiaryLabel && (
            <Text color="#5e5e5e" font="OpenSans" size="xs">
              {tertiaryLabel}
            </Text>
          )}
        </Inline>
      </StyledLabel>
      {description && (
        <Text color="#5e5e5e" font="OpenSans" id={descriptionId} linkStyle="underline" size="xs">
          {description}
        </Text>
      )}
    </Stack>
  );
};
