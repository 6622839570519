import React, {AllHTMLAttributes} from 'react';
import styled from 'styled-components';
import invariant from 'tiny-invariant';

import {FieldLabel, FieldLabelProps, FieldMessage, FieldMessageProps, Stack} from '..';

type FormElementProps = AllHTMLAttributes<HTMLFormElement>;

export type FieldLabelVariant =
  | {
      'aria-labelledby': string;
      secondaryLabel?: never;
      tertiaryLabel?: never;
      description?: never;
      label?: string;
    }
  | {
      label: FieldLabelProps['label'];
      'aria-label': string;
      secondaryLabel?: never;
      tertiaryLabel?: never;
      description?: never;
    }
  | {
      label: FieldLabelProps['label'];
      secondaryLabel?: FieldLabelProps['secondaryLabel'];
      tertiaryLabel?: FieldLabelProps['tertiaryLabel'];
      description?: FieldLabelProps['description'];
    };

export type FieldBaseProps = {
  'aria-describedby'?: FormElementProps['aria-describedby'];
  autoComplete?: FormElementProps['autoComplete'];
  autoFocus?: boolean;
  disabled?: FormElementProps['disabled'];
  hint?: FieldMessageProps['hint'];
  icon?: React.ReactNode;
  id: NonNullable<FormElementProps['id']>;
  labelId?: string;
  message?: FieldMessageProps['message'];
  name?: FormElementProps['name'];
  prefix?: string;
  readOnly?: boolean;
  required?: boolean;
  secondaryHint?: FieldMessageProps['secondaryHint'];
  tone?: FieldMessageProps['tone'];
  value?: FormElementProps['value'];
};

type PassthroughProps = 'id' | 'name' | 'disabled' | 'autoComplete' | 'autoFocus' | 'readOnly';

interface FieldRenderProps extends Pick<FieldBaseProps, PassthroughProps> {
  'aria-describedby'?: string;
  'aria-required'?: boolean;
  'aria-label'?: string;
  'aria-labelledby'?: string;
  readOnly?: boolean;
}

type InternalFieldProps = FieldBaseProps &
  FieldLabelVariant & {
    secondaryIcon?: React.ReactNode;
    children(
      props: FieldRenderProps,
      icon: React.ReactNode,
      secondaryIcon: React.ReactNode,
      prefix: React.ReactNode
    ): React.ReactNode;
  };

type StyledFieldInputWrapperProps = {
  $disabled?: FormElementProps['disabled'];
  $readOnly?: boolean;
  $tone?: FieldMessageProps['tone'];
};

const StyledFieldInputWrapper = styled.div<StyledFieldInputWrapperProps>`
  --field-border-color: transparent;

  border-radius: 2px;
  box-shadow: inset 0 0 0 1px var(--field-border-color);

  ${({$disabled, $readOnly, $tone}) => ({
    '--field-border-color': `${
      $disabled || $readOnly ? 'transparent' : $tone === 'critical' ? '#F61212' : '#919191'
    }`,

    backgroundColor: `${$disabled && !$readOnly ? '#F6F6F6' : 'transparent'}`,
  })}

  &:hover {
    ${({$disabled, $readOnly, $tone}) => ({
      '--field-border-color': `${
        $disabled || $readOnly ? 'transparent' : $tone === 'critical' ? '#F61212' : '#5E5E5E'
      }`,
    })}
  }

  &.focus-visible {
    ${({$disabled, $readOnly, $tone}) => ({
      '--field-border-color': `${
        $disabled || $readOnly ? 'transparent' : $tone === 'critical' ? '#F61212' : '#303030'
      }`,
    })}
  }
`;

// background-color: ${({$variant}) => ($variant === 'disabled' ? '#F6F6F6' : 'transparent')};

export const Field = ({
  'aria-describedby': ariaDescribedBy,
  autoComplete,
  autoFocus,
  children,
  disabled,
  hint,
  icon,
  id,
  labelId,
  message,
  name,
  prefix,
  readOnly,
  required,
  secondaryHint,
  secondaryIcon,
  tone,
  value,
  ...props
}: InternalFieldProps) => {
  invariant(prefix === undefined || typeof prefix === 'string', 'Prefix must be a string');

  const messageId = `${id}-message`;
  const descriptionId =
    'description' in props && props.description ? `${id}-description` : undefined;
  // const hasValue = typeof value === 'string' ? value.length > 0 : value != null;
  const hasVisualLabel = 'label' in props;

  return (
    <Stack gap="sm">
      {hasVisualLabel && (
        <FieldLabel
          description={props.description}
          descriptionId={descriptionId}
          htmlFor={id}
          id={labelId}
          label={props.label}
          secondaryLabel={props.secondaryLabel}
          tertiaryLabel={props.tertiaryLabel}
        />
      )}
      <StyledFieldInputWrapper $disabled={disabled} $readOnly={readOnly} $tone={tone}>
        {children(
          {autoComplete, autoFocus, disabled, id, name, readOnly},
          icon,
          secondaryIcon,
          prefix
        )}
      </StyledFieldInputWrapper>
      {(hint || message) && (
        <FieldMessage
          disabled={disabled}
          hint={hint}
          id={messageId}
          message={message}
          secondaryHint={secondaryHint}
          tone={tone}
        />
      )}
    </Stack>
  );
};
