import PropTypes from 'prop-types';
import React from 'react';

const FeaturedGridSliderControls = ({swiperRef}) => {
  const goNext = React.useCallback(() => {
    if (swiperRef.current && swiperRef.current !== null) {
      swiperRef.current.slickNext();
    }
  }, [swiperRef.current]);

  const goPrev = React.useCallback(() => {
    if (swiperRef.current && swiperRef.current !== null) {
      swiperRef.current.slickPrev();
    }
  }, [swiperRef.current]);

  return (
    <div className="col-md-12">
      <div className="grid-actions">
        <button type="button" onClick={goPrev}></button>
        <button type="button" onClick={goNext}></button>
      </div>
    </div>
  );
};

FeaturedGridSliderControls.propTypes = {
  swiperRef: PropTypes.object,
};

export default FeaturedGridSliderControls;
