import styled from 'styled-components';

const ArchivePageLayout = styled.div`
  text-align: left;
  width: 100%;

  @media (max-width: 1280px) {
    padding: 0 3rem;
  }
`;

export default ArchivePageLayout;
