import {motion} from 'framer-motion';
import React from 'react';
import styled, {css, keyframes} from 'styled-components';

import {Device} from '../../../constants';

const flash = keyframes`
    0% {
        background-color: #000;
    }
    50% {
        background-color: #fff;
    }
    100% {
        background-color: #000;
    }
`;

const SlashContainer = styled(({...rest}) => <motion.div {...rest} />)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: #000;
  display: flex;
  justify-content: center;
  align-items: center;

  div {
    position: absolute;
  }
  #first {
    width: 35em;
    @media and ${Device.mobileL} {
      width: 16em;
    }
  }
  #second {
    width: 45em;
    @media and ${Device.mobileL} {
      width: 18em;
    }
  }

  animation: ${(props) =>
    props.flicker
      ? css`
          ${flash} 150ms ease-in-out 2 forwards
        `
      : 'none'};
`;

export default SlashContainer;
