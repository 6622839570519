import {getBowserState} from '@epic-core/common';
import {SlickWrapper} from 'epic-react-common';
import PropTypes from 'prop-types';
import pureRender from 'pure-render-decorator';
import React from 'react';
import root from 'window-or-global';

import GridItem from './GridItem';

@pureRender
class GridLayout extends React.Component {
    state = {
        changeThirdPosImage: false,
        mobile: false
    };

    static propTypes = {
        blogs: PropTypes.array,
        locale: PropTypes.string,
        routerRootUrl: PropTypes.string,
        linkUrlPrefix: PropTypes.string,
        mobilePostNum: PropTypes.number,
        enableSlider: PropTypes.bool
    };

    static defaultProps = {
        enableSlider: false
    };

    UNSAFE_componentWillMount() {
        // call it once for firstime load
        this.screenSize();
        if (root.addEventListener) {
            root.addEventListener('resize', this.screenSize);
        }
    }

    componentWillUnmount() {
        if (root.removeEventListener) {
            root.removeEventListener('resize', this.screenSize);
        }
    }

    screenSize = () => {
        if (!root.document) {
            return;
        }
        const calcWidth =
            root.innerWidth ||
            root.document.documentElement.clientWidth ||
            root.document.body.clientWidth;
        if (calcWidth <= 767 && !this.state.mobile) {
            this.setState({mobile: true});
        } else if (calcWidth > 767 && this.state.mobile) {
            this.setState({mobile: false});
        }
        switch (true) {
            case calcWidth > 1200 && this.state.changeThirdPosImage:
                this.setState({changeThirdPosImage: false});
                break;
            case calcWidth < 1200 && !this.state.changeThirdPosImage:
                this.setState({changeThirdPosImage: true});
                break;
            case calcWidth <= 992 && this.state.ThirdPosImage:
                this.setState({changeThirdPosImage: false});
                break;
            default:
                break;
        }
    };

    render() {
        const {blogs, routerRootUrl, locale, linkUrlPrefix, mobilePostNum, enableSlider} =
            this.props;
        const {mobile} = getBowserState();
        const isMobile = mobile || this.state.mobile;
        const items = [];
        let list = [];
        const sliderOptions = {
            adaptiveHeight: true,
            infinite: false,
            speed: 700,
            slidesToScroll: 1,
            arrows: true,
            draggable: false,
            swipeToSlide: true,
            swipe: true,
            touchMove: true,
            slidesToShow: 1.5
        };
        //used to give classes
        let swap = false;
        let type = '';
        for (let x = 0; x < blogs.length; x++) {
            const blog = blogs[x];
            let size = 'small';
            if (x % 6 === 0) {
                size = swap ? 'medium' : 'large';
                type = 'two';
            } else if (type === 'two') {
                size = swap ? 'large' : 'medium';
                type = 'three';
                swap = !swap;
            } else if (type === 'three') {
                size = 'thirdPos';
                type = '';
            }
            items.push(
                <GridItem
                    key={`blog-${blog.slug}`}
                    post={blog}
                    size={size}
                    changeThirdPosImage={this.state.changeThirdPosImage}
                    mobile={isMobile}
                    locale={locale}
                    routerRootUrl={routerRootUrl}
                    linkUrlPrefix={linkUrlPrefix}
                />
            );
        }
        if (enableSlider && this.state.mobile) {
            list = items.splice(mobilePostNum);
        }
        return (
            <div className="grid-layout container-fluid">
                <div className="row">
                    {items}
                    {enableSlider && this.state.mobile && list.length > 0 && (
                        <SlickWrapper options={sliderOptions}>{list}</SlickWrapper>
                    )}
                </div>
            </div>
        );
    }
}

export default GridLayout;
