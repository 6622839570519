import React from 'react';

import {Payload, trackEvent} from './eventTracking';

export function usePrivatePageViewTracking(payload: Payload): void {
  React.useEffect(() => {
    //uses privateOnly to prevent double tracking of pageview. epicTracking is fired via the router.
    trackEvent(payload, 'privateOnly');
  }, []);
}
