import styled from 'styled-components';

const ImageWrapper = styled.div`
  margin: auto;
  img {
    max-height: 1000px;
    max-width: 100vw;
  }
`;

export default ImageWrapper;
