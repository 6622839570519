import styled from 'styled-components';

import {Device} from '../../constants';

interface Props {
  $color?: string;
}

export default styled.div<Props>`
  &&&& {
    color: ${({$color}) => $color || '#ffffff'};
  }
  font-family: BurbankBigRegular-Black, sans-serif;
  font-size: 75px;
  line-height: 1;
  text-align: left @media screen and ${Device.laptop} {
    font-size: 4em;
    text-align: center;
  }
  @media screen and ${Device.tablet} {
    font-size: 2em;
  }
`;
