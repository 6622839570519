import styled from 'styled-components';

import {breakpoints} from '../../../constants/Breakpoints';

const DifficultyLevelDisplay = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 3rem 0;

  @media ${breakpoints.mobileL} {
    margin: 2rem 0;
  }
`;

export default DifficultyLevelDisplay;
