interface EnvConfig {
    [key: string]: string;
}

const services: ConfigurableService[] = [];

export interface ConfigurableService {
    configure: (envConfig: EnvConfig) => void;
}

export function registerPublicService(service: ConfigurableService) {
    services.push(service);
}

export function configurePublicServices(envConf: EnvConfig) {
    services.forEach(service => service.configure(envConf));
}

export function debugPublicServices() {
    console.log('Debug Public Services: Registered Services');
    let configuredServices: string[] = [];
    services.forEach(service => configuredServices.push(service.constructor.name));
    console.log(`Configured Services: [${configuredServices.join(', ')}]`);
}
