/**
 * This file is used to aggregate all the css files that are used by our views.  This is necessary because with webpack chunks,
 * extract text is unable to create a style sheet for individual chunks.  To get around this, we load all the chunked styles
 * here.
 */
import 'epic-minimalist-blog/app/scripts/blogStyles';
import './blog/BlogWrapper.sass';
import './download/DownloadView.sass';
import './faq/FaqView.sass';
import 'epic-fortnite-react/views/search/SearchView.sass';
import './terms/TermsView.sass';
import './eula/EulaView.sass';

import './redeem/RedeemCustomStyles.sass';
import './redeem/PosaRedeemCustomStyle.sass';
import './redeem/VbucksCardTermsView.sass';
import './fortniteMobile/FnMobileView.sass';
import './patchNotes/PatchNotesView.sass';
import './playGuide/PlayGuideView.sass';
import './playGuide/PlayGuideHomeView.sass';
import './fortniteMobile/AndroidView.sass';
import './retailBundle/RetailBundleView.sass';
import './creativeMode/CreativeView.sass';
import './creativeMode/CreativeHomeView.sass';
import './landingpages/LandingPageView.sass';
import './safetySecurity/SafetySecurityView.sass';
import './saveTheWorld/StwView.sass';
import './helpCenter/HelpCenterView.sass';
import './battlePass/BattlePassView.sass';
import './battlePass/TakeOverView.sass';
import './darkfire/DarkfireView.sass';
import './paymentsact/PaymentsActView.sass';
import './simple/SimpleView.sass';
import './subscription/SubscriptionView.sass';
import './landingpages/LandingPageCh2s6View.sass';
import './landingpages/LandingPageCh2s7View.sass';
import './landingpages/LandingPageCh2s8View.sass';
import './genericLegal/GenericLegalView.sass';
