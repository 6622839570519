import root from 'window-or-global';

export const getOrigin = (): string => {
  if (!root || !root.location) return '';

  if (root.__server_side_render) {
    return `http://localhost:${root.clientEnvConfig.NODE_SERVER_PORT}`;
  }

  let appContext = '';
  if (root.appContext && root.appContext !== '/') {
    appContext = root.appContext;
  }

  const location = root.location || {};

  if (!location.origin) {
    const port = location.port ? `:${location.port}` : '';
    root.location.origin = `${location.protocol}//${location.hostname}${port}`;
  }
  const origin = root.location.origin + appContext;
  return origin;
};
