import React from 'react';
import styled, {keyframes} from 'styled-components';

const pulseAnimation = keyframes`
  0% {
    margin-left: 10px;
  }
  50% {
    margin-left: 20px;
  }
  100% {
    margin-left: 10px;
  }
`;

const StyledBlackoutCtaSection = styled.div<{bgColor?: string; accentColor?: string}>`
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  background-color: ${({bgColor}) => bgColor ?? '#fff'};
  padding: 0;
  h3 {
    margin: 0;
    padding: 0;
    i {
      margin-left: 10px;
      position: absolute;
      transform: translate(0, -55%);
      top: 50%;
    }
  }
  &&&& a {
    padding: 10px;
    color: #000;
    &:hover {
      color: ${({accentColor}) => accentColor ?? '#000'};
      i {
        animation: ${pulseAnimation} 2s infinite;
      }
    }
  }
  @media only screen and (max-width: 800px) {
    position: unset;

    h3 > i {
      position: unset;
    }
  }
`;

const BlackoutCtaBar = ({config}) => (
  <StyledBlackoutCtaSection bgColor={config.bgColor} accentColor={config.accentColor}>
    <a href={config.link} target="_blank" rel="noreferrer">
      <h3>
        {config.text} <i className="icon-arrow" />
      </h3>
    </a>
  </StyledBlackoutCtaSection>
);

export default BlackoutCtaBar;
