import axios from 'axios';
import root from 'window-or-global';

import PatchNotesActions from '../actions/PatchNotesActions';
import Url from '../constants/Url';

/**
 * ErrorSource
 *
 * Responsible for making the ajax calls for errors.
 *
 * For more information on source usage:
 * http://alt.js.org/docs/async/
 *
 */
const PatchNotesSource = {
  getPatchNotes: {
    remote(state, key, locale) {
      //the store state is accessible here
      return axios.get(Url().PATCH_NOTES.GET(key, locale));
    },

    //On success call the following action:
    success: PatchNotesActions.getPatchNotesSuccess,

    //On failure call the following action:
    error: PatchNotesActions.getPatchNotesFailure,

    shouldFetch() {
      return !root.__server_side_render;
    },
  },
};

export default PatchNotesSource;
