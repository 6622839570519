import styled from 'styled-components';

import {breakpoints} from '../../../../constants/Breakpoints';

const HeroGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media ${breakpoints.tablet} {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  @media ${breakpoints.mobileL} {
    justify-content: center;
  }
`;

export default HeroGrid;
