import {Link, withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';
import React from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import classNames from 'classnames';
import {generateRouteTo} from 'epic-fortnite-react';

export function trunc(string, n) {
    if (!string) return '';
    return string.length > n ? `${string.substr(0, n - 1)}...` : string;
}

class GridItem extends React.Component {
    static propTypes = {
        post: PropTypes.object,
        locale: PropTypes.string,
        routerRootUrl: PropTypes.string,
        linkUrlPrefix: PropTypes.string,
        size: PropTypes.string,
        mobile: PropTypes.bool,
        changeThirdPosImage: PropTypes.bool
    };
    static defaultProps = {
        linkUrlPrefix: ''
    };
    state = {
        inView: false
    };
    onChange = (isVisible) => {
        if (isVisible) {
            this.setState({inView: true});
        }
    };
    render() {
        const {
            locale,
            post,
            routerRootUrl,
            linkUrlPrefix,
            size,
            mobile,
            changeThirdPosImage,
            location
        } = this.props;
        let imageUrl = '';
        const title = post.title;
        /**
         * Grid Title is currently only used in epic-minimalist-blog. If it has a value it will show as title for blog
         * if not present or undefined then it will default to title post.
         */
        const gridTitle = post.gridTitle;
        const slug = post.slug;
        const postDate = post.date;
        const alt = post.alt || '';
        const dateOptions = {
            month: 'short',
            year: 'numeric',
            day: 'numeric'
        };
        let date;
        if (post.customDate) {
            date = post.customDate;
        } else {
            date = postDate
                ? new Date(postDate).toLocaleDateString(locale.replace(/_/g, '-'), dateOptions)
                : '';
        }
        const externalLink = post.externalLink;
        const postLink = externalLink || `/${linkUrlPrefix}${routerRootUrl}/${slug}`;
        const slugRoutePath = generateRouteTo(postLink, location, locale);
        const titleToUse = gridTitle || title;
        /*if title is longer than 55 characters it gets truncated*/
        const truncTitle = !mobile ? trunc(titleToUse, 55) : titleToUse;
        /**
         * If you need a different size just add to this switch and pass the case as prop size in parent
         */
        let gridClass;
        switch (size) {
            case 'large':
                gridClass = classNames('gridItem-large col-xs-12 col-sm-6 col-md-6 col-lg-7', {
                    inView: this.state.inView
                });
                imageUrl = post.trendingImage;
                break;
            case 'medium':
                gridClass = classNames('gridItem-medium col-xs-12 col-sm-6 col-md-6 col-lg-5', {
                    inView: this.state.inView
                });
                imageUrl = changeThirdPosImage ? post.trendingImage : post.image;
                break;
            case 'thirdPos':
                gridClass = classNames('gridItem-thirdPos col-xs-12 col-sm-6 col-md-12 col-lg-3', {
                    inView: this.state.inView
                });
                imageUrl = changeThirdPosImage ? post.trendingImage : post.image;
                break;
            default:
                gridClass = classNames('gridItem-small col-xs-12 col-sm-6 col-md-4 col-lg-3', {
                    inView: this.state.inView
                });
                imageUrl = mobile ? post.trendingImage : post.image;
        }
        if (mobile && post.mobileThumbnailImage) {
            imageUrl = post.mobileThumbnailImage;
        }
        const bkImage = imageUrl ? {backgroundImage: `url(${imageUrl})`} : {};

        return (
            <VisibilitySensor
                onChange={this.onChange}
                active={!this.state.inView}
                partialVisibility
                minTopValue={10}>
                <Link to={slugRoutePath} title={title} id={`grid-${slug}`} className={gridClass}>
                    <div className="grid-item">
                        <div className="background-image" style={bkImage}>
                            <img src={imageUrl} className="invisible-image" alt={alt} />
                        </div>
                        <div className="grid-content">
                            <h4 className="date">{date}</h4>
                            <h1 className="title">{truncTitle}</h1>
                        </div>
                    </div>
                </Link>
            </VisibilitySensor>
        );
    }
}

export default withRouter(GridItem);
