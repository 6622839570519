import React from 'react';

import {Overlay, OverlayProps} from '../Overlay/Overlay';

export type FieldOverlayVariant = 'default' | 'disabled' | 'focus' | 'hover' | 'critical';

export interface FieldOverlayProps
  extends Pick<OverlayProps, 'background' | 'borderRadius' | 'children' | 'className' | 'visible'> {
  variant?: FieldOverlayVariant;
}

export const FieldOverlay = ({variant, ...props}: FieldOverlayProps) => {
  return <Overlay {...props} />;
};
