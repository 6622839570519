import styled from 'styled-components';

import {Device} from '../../../constants';

const VBucksFeatureContainer = styled.section`
  position: relative;
  height: 70vh;
  min-height: 850px;
  background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(26, 99, 145, 1) 100%);
  display: flex;
  justify-content: center;
  align-items: center;

  #slash-corner {
    position: absolute;
    top: 10%;
    right: -6%;
    width: 25em;
  }

  @media screen and (max-width: 940px) {
    height: 100%;
    min-height: auto;
  }

  @media screen and ${Device.tablet} {
    #slash-corner {
      width: 16em;
      right: -5%;
    }
  }
`;

export default VBucksFeatureContainer;
