const isElementInView = (container, element, partial) => {
  const containerBounds = container.getBoundingClientRect();
  const elementBounds = element.getBoundingClientRect();
  const containerBoundsLeft = Math.floor(containerBounds?.left);
  const containerBoundsRight = Math.floor(containerBounds?.right);
  const elementBoundsLeft = Math.floor(elementBounds?.left);
  const elementBoundsRight = Math.floor(elementBounds?.right);

  const isTotallyInView =
    elementBoundsLeft >= containerBoundsLeft && elementBoundsRight <= containerBoundsRight;
  const isPartiallyInView =
    partial &&
    ((elementBoundsLeft < containerBoundsLeft && elementBoundsRight > containerBoundsLeft) ||
      (elementBoundsRight > containerBoundsRight && elementBoundsLeft < containerBoundsRight));

  return isTotallyInView || isPartiallyInView;
};

export {isElementInView};
