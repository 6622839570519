import {withLocalization} from '@epic-core/localization';
import {BaseButton, Container, Inline, Section, Stack, Text} from 'epic-fortnite-react';
import React, {useState} from 'react';
import styled from 'styled-components';
import {
  FacebookMessengerShareButton,
  FacebookMessengerIcon,
  WhatsappShareButton,
  TwitterShareButton,
  TwitterIcon,
  WhatsappIcon,
  FacebookShareButton,
  FacebookIcon,
} from 'react-share';
import {CopyToClipboard} from 'react-copy-to-clipboard';

import {trackEvent} from '../../utils/eventTracking';
import {RiftTourButton} from '../riftTour/components/RiftTourButton';
import {SocialShareOptions} from '../blocks/types';

interface ShareSectionProps {
  heading: string;
  shareOptions: SocialShareOptions[];
  shareLink: string;
  shareText: string;
  hasNativeSocialShare: boolean;
  locale: string;
  getMessage: (path: string) => string;
}

const StyledCopy = styled.div`
  background: #fff;
  border: 1px solid #797979;
  max-width: 500px;
  overflow: hidden;
  padding: 12px;
  width: 100%;
`;

const StyledCopyText = styled.p`
  color: #000;
  font-size: 14px;
  line-height: 16px;
  margin: 0;
  word-break: unset;
`;

const CopyLink = styled(BaseButton)`
  color: #68d8f4;
  padding: 4px;
  text-decoration: underline;
  width: 100%;
  &:hover {
    color: #52aac0;
  }
  &.focus-visible {
    box-shadow: 0px 0px 0px 4px #000;
  }
`;

const trackShareClick = (label: string, link: string): void => {
  trackEvent(
    {
      eventCategory: 'fn-web',
      eventAction: `user.click.body.shareModalCTA.${label}`,
      eventValue: 1,
      interactionType: 'click',
      eventLabel: `${link}`,
    },
    'identifiedOnly'
  );
};

const SocialComponentMap = {
  messenger: (shareLink: string, title: string, appId: string) => (
    <FacebookMessengerShareButton
      appId={appId}
      url={shareLink}
      onClick={() => trackShareClick('fb-messenger', shareLink)}>
      <FacebookMessengerIcon round />
    </FacebookMessengerShareButton>
  ),
  whatsapp: (shareLink: string, title: string) => (
    <WhatsappShareButton
      url={shareLink}
      title={title}
      onClick={() => trackShareClick('whatsapp', shareLink)}>
      <WhatsappIcon round />
    </WhatsappShareButton>
  ),
  twitter: (shareLink: string, title: string) => (
    <TwitterShareButton
      url={shareLink}
      title={title}
      onClick={() => trackShareClick('twitter', shareLink)}>
      <TwitterIcon round />
    </TwitterShareButton>
  ),
  facebook: (shareLink: string, title: string) => (
    <FacebookShareButton url={shareLink} quote={title}>
      <FacebookIcon round />
    </FacebookShareButton>
  ),
};

const ShareSection = ({
  heading,
  shareOptions,
  getMessage,
  shareLink,
  shareText,
  hasNativeSocialShare,
  locale,
}: ShareSectionProps) => {
  const [copied, setCopied] = useState(false);
  const isWindows = navigator.userAgent.toLowerCase().match('windows');

  const handleOnCopy = () => {
    if (!copied) trackShareClick('copy', shareLink);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  const handleSocialShareClick = () => {
    navigator
      .share({
        text: shareText,
        url: shareLink,
      })
      .then(() => {
        trackEvent({
          eventCategory: 'fn-web',
          eventAction: 'cta',
          eventLabel: 'user.click.body.sharemodalcta.share',
          eventValue: locale,
          interactionType: 'click',
        });
      })
      .catch(() => {
        trackEvent({
          eventCategory: 'fn-web',
          eventAction: 'cta',
          eventLabel: 'dismiss',
          eventValue: locale,
          interactionType: 'click',
        });
        console.error();
      });
  };

  return (
    <Section>
      <Container maxWidth={1600} size="med">
        <Stack gap="lg">
          <Text size="xl" color="#000" align="center" transform="uppercase">
            {heading}
          </Text>
          {navigator['share'] && !isWindows && hasNativeSocialShare ? (
            <RiftTourButton
              onClick={() => handleSocialShareClick()}
              label={getMessage(`epic.common.share`)}
              theme={{backgroundColor: 'rgb(255, 218, 11)', color: '#000'}}
            />
          ) : (
            <Inline align="center" gap="sm" justify="center">
              {shareOptions.map((link, index) => (
                <Stack key={`social-link.${link.componentName}.${index}`} gap="sm">
                  {SocialComponentMap[link.componentName](shareLink, link.text, link.appId)}
                  <Text size="sm" align="center">
                    {link.label}
                  </Text>
                </Stack>
              ))}
            </Inline>
          )}
          <StyledCopy>
            <Inline align="center" flex="start" gapX="sm" wrap={false}>
              <StyledCopyText>{shareLink}</StyledCopyText>
              <CopyToClipboard onCopy={handleOnCopy} text={shareLink}>
                <CopyLink
                  label={getMessage(`epic.common.${copied ? 'copied' : 'copy'}`)}
                  style={{right: 10}}
                />
              </CopyToClipboard>
            </Inline>
          </StyledCopy>
        </Stack>
      </Container>
    </Section>
  );
};

export default withLocalization(ShareSection);
