import PropTypes from 'prop-types';
import React from 'react';
import root from 'window-or-global';

const SocialButton = ({url, alt, type}) => {
  const onClick = React.useCallback(() => (root.location.href = url), [url]);
  if (/^http/.test(url)) {
    return (
      <a href={url} target="_blank" rel="noreferrer noopener" className="socialbutton">
        <span className={type} alt={type} />
      </a>
    );
  }
  return <button className={`socialbutton ${type}`} title={alt} onClick={onClick}></button>;
};

SocialButton.propTypes = {
  url: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export default SocialButton;
