import styled from 'styled-components';

import SocialButton from './SocialButton';

const SocialButtonGroup = styled.div`
  display: flex;
  flex-direction: row;

  ${SocialButton} {
    margin: 0 0.25rem;
  }
`;

export default SocialButtonGroup;
