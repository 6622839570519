import AnimateObject from 'epic-fortnite-react/components/common/AnimateObject';
import {BackgroundVideo} from 'epic-react-common';
import epicTracking from 'epic-tracking';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import styled from 'styled-components';
import AnimateString from '../common/AnimateString';
import VideoOverlay from '../common/VideoOverlay';
import VideoPlayButton from '../common/VideoPlayButton';
import BackgroundImage from '../chapter2/s6/BackgroundImage';
const StyledBackgroundVideo = styled('div')`
  video {
    position: absolute;
    inset: 0;
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
`;

const VideoHeader = ({data, mobile, tablet}) => {
  const {badge, aboveTitle, title, subtitle, videoId, background, duration, disabledYoutubeBtn} =
    data;
  const [isPlay, setPlay] = useState(false);

  const playVideo = React.useCallback(
    (e) => {
      setPlay(true);
      epicTracking.trackEvent('Interaction', {
        interactionType: 'click',
        eventCategory: 'BattlePass Video',
        eventAction: 'play Video',
        eventLabel: e.currentTarget.id,
      });
    },
    [setPlay]
  );

  const closeVideo = React.useCallback(
    (e) => {
      setPlay(false);
      epicTracking.trackEvent('Interaction', {
        interactionType: 'click',
        eventCategory: 'BattlePass Video',
        eventAction: 'close Video',
        eventLabel: e.currentTarget.id,
      });
    },
    [setPlay]
  );
  return (
    <div className="battlepass-background-header">
      <div className="video-dots-mask" />
      {background.content ? (
        <BackgroundImage
          alt={title}
          breakPoint={768}
          desktopImage={background.content}
          mobile={mobile}
          mobileImage={background.mobileImage}
          style={{width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'center'}}
        />
      ) : (
        <StyledBackgroundVideo>
          <BackgroundVideo
            background={background}
            enableRatioHelper
            play={true}
            noOverlay
            mute
            loop
            playsInline
            forceVideoMobile
            backgroundVideo
            mobile={mobile}
            tablet={tablet}
          />
        </StyledBackgroundVideo>
      )}
      <div className="content">
        <div className="animated-content">
          {duration ? (
            <AnimateString
              key="videoHeader-duration"
              className="vh-duration"
              text={duration}
              type="h5"
              speed={1.5}
              delay={0.3}
              autoAlpha={0}
              yPos={100}
              minTop={20}
              scaleFrom={1}
              staggerDelay={0}
            />
          ) : null}
          {badge ? (
            <AnimateObject
              minTop={20}
              speed={1.5}
              className={'video-badge'}
              from={{
                y: `+=150`,
                autoAlpha: 0,
              }}
              to={{
                y: 0,
                autoAlpha: 1,
                delay: 0.3,
              }}>
              <img src={badge} alt="badge" />
            </AnimateObject>
          ) : null}
          {aboveTitle ? (
            <AnimateString
              key="videoHeader-aboveTitle"
              className="above-title"
              text={aboveTitle}
              type="h4"
              speed={1.5}
              delay={0.3}
              autoAlpha={0}
              yPos={100}
              minTop={20}
              scaleFrom={1}
              staggerDelay={0}
            />
          ) : null}
          {title ? (
            <AnimateString
              key="videoHeader-Title"
              className="video-title"
              text={title}
              type="h1"
              speed={1.5}
              delay={0.3}
              autoAlpha={0}
              yPos={100}
              minTop={20}
              scaleFrom={1}
              staggerDelay={0.01}
            />
          ) : null}
          {subtitle ? (
            <AnimateString
              key="videoHeader-subtitle"
              text={subtitle}
              className="video-copy"
              type="h3"
              speed={1.5}
              delay={0.3}
              autoAlpha={0}
              yPos={100}
              minTop={20}
              scaleFrom={1}
              staggerDelay={0}
            />
          ) : null}
          {!disabledYoutubeBtn && (
            <div className="play-wrapper">
              <AnimateObject
                minTop={20}
                speed={1.5}
                from={{
                  y: `+=150`,
                  autoAlpha: 0,
                }}
                to={{
                  y: 0,
                  autoAlpha: 1,
                  delay: 0.4,
                }}>
                <VideoPlayButton
                  id="battlepass-video-button"
                  onClick={playVideo}
                  className="bp-videoplay"
                />
              </AnimateObject>
            </div>
          )}
        </div>
      </div>
      {isPlay ? (
        <div className="videoOverlay-wrapper">
          <VideoOverlay
            key={`bp-videoHeader-${videoId}`}
            videoID={videoId}
            isLauncher={false}
            play={isPlay}
            close={closeVideo}
            closeButton
            show={isPlay}
          />
        </div>
      ) : null}
    </div>
  );
};

VideoHeader.propTypes = {
  data: PropTypes.object,
  mobile: PropTypes.bool,
  tablet: PropTypes.bool,
};

export default VideoHeader;
