import styled from 'styled-components';

interface Props {
  active: boolean;
}

const FeatureTitleVisibilityContainer = styled.div<Props>`
  position: relative;
  width: 100%;
`;

export default FeatureTitleVisibilityContainer;
