import {DeviceSize} from 'epic-fortnite-styles/constants';
import styled from 'styled-components';

const StyledBadge = styled.img`
  height: auto;
  width: 65%;
  max-width: 500px;

  @media (min-width: ${DeviceSize.tablet}) {
    max-width: 600px;
  }

  @media (min-width: ${DeviceSize.laptopL}) {
    max-width: 800px;
  }
`;

export default StyledBadge;
