import {withLocalization} from '@epic-core/localization';
import {connectToStores} from 'epic-alt-utils';
import AppConfigStore from 'epic-fortnite-react/stores/AppConfigStore';
import {BlogView} from 'epic-minimalist-blog/app/scripts';
import {BlogActions} from 'epic-minimalist-blog/app/scripts/actions';
import PropTypes from 'prop-types';
import React from 'react';
import PatchNotesActions from '../../actions/PatchNotesActions';

@connectToStores
class BlogWrapper extends React.Component {
  static getStores() {
    return [AppConfigStore];
  }

  static getPropsFromStores() {
    return {
      alertMessage: AppConfigStore.getState().globalAlert,
    };
  }
  static getInitialActions() {
    return [
      (props) => {
        // reset blog store state so it won't read state from home page
        BlogActions.setStoreState({blogList: []});
      },
    ];
  }
  static propTypes = {
    alertMessage: PropTypes.object, //eslint-disable-line
    getMessage: PropTypes.func,
  };
  componentDidMount() {
    PatchNotesActions.clearErrorStatus();
  }

  render() {
    const metaTitle = this.props.getMessage('epic.fortnite.blog.meta.title');

    return (
      <div className="blog-wrapper">
        <BlogView {...this.props} overrideMetaTitle={metaTitle} enableAutoShortDescription={true} />
      </div>
    );
  }
}

export default withLocalization(BlogWrapper);
