import React, {useState} from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import styled from 'styled-components';

import {Device} from '../../constants';
import {triggerAnalytic} from '../../utils/SubscriptionUtils';

import StyledSubscriptionCoreTextWrapper from './StyledSubscriptionCoreTextWrapper';
import StyledSubscriptionPhasedSection from './StyledSubscriptionPhasedSection';
import SubscriptionCoreContent from './SubscriptionCoreContent';

const StyledPhasedBackblingSection = styled(StyledSubscriptionPhasedSection)`
  clip-path: polygon(0% 5%, 0% 100%, 100% 100%, 100% 0%);
  margin-top: -5em;

  ${StyledSubscriptionCoreTextWrapper} {
    @media screen and ${Device.mobileL} {
      position: absolute;
      bottom: 2em;
      width: 100%;
    }
  }
`;

interface Props {
  content: any;
  mobile: boolean;
  tablet: boolean;
}

const SubscriptionPhasedBackblingSection = (props: Props) => {
  const {content, mobile} = props;
  const [isVisible, setVisibility] = useState(false);
  const onChange = (): void => {
    setVisibility(!isVisible);
    if (isVisible) {
      triggerAnalytic('SubscriptionBundle');
    }
  };

  return (
    <VisibilitySensor onChange={onChange} partialVisibility minTopValue={10}>
      <StyledPhasedBackblingSection>
        <SubscriptionCoreContent content={content} mobile={mobile} />
      </StyledPhasedBackblingSection>
    </VisibilitySensor>
  );
};

export default SubscriptionPhasedBackblingSection;
