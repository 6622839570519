import React from 'react';

export interface VideoOverlayConfig {
  videoID?: string;
  isLauncher?: boolean;
  play?: boolean;
  close?: React.MouseEventHandler<HTMLDivElement>;
  closeButton?: boolean;
  show?: boolean;
}

export interface VideoOverlayState {
  config?: VideoOverlayConfig;
  setConfig?: (config: VideoOverlayConfig) => void;
}

const VideoOverlayContext = React.createContext<VideoOverlayState>({});

VideoOverlayContext.displayName = 'VideoOverlay';

export default VideoOverlayContext;
