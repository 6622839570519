class GeneralServiceError extends Error {
    public errorCode: string;
    public numericErrorCode: number;

    public constructor(message: string, errorCode: string, numericErrorCode: number) {
        super(message);
        this.errorCode = errorCode;
        this.numericErrorCode = numericErrorCode;
        this.name = 'GeneralServiceError';
    }
}

export default GeneralServiceError;
