import {VideoOverlay} from 'epic-fortnite-react';
import PropTypes from 'prop-types';
import React from 'react';

export default class CreatorCard extends React.PureComponent {
  static propTypes = {
    data: PropTypes.object,
  };

  state = {
    show: false,
  };

  toggleOverlay = () => {
    this.setState({show: !this.state.show});
  };

  render() {
    const {data} = this.props;
    const {description, image, videoID} = data;
    const imageStyle = image ? {backgroundImage: `url(${image})`} : {};
    return (
      <div className="creator-card">
        <div className="section">
          <div className="content text-center">
            <div className="description" dangerouslySetInnerHTML={{__html: description}} />
            <div
              role="presentation"
              id="CreatorVideo"
              onClick={this.toggleOverlay}
              className="image"
              style={imageStyle}>
              <div className="playButton">
                <i className="icon-play" />
              </div>
            </div>
          </div>
        </div>
        {image || videoID ? (
          <VideoOverlay
            videoID={videoID}
            isLauncher={false}
            play={this.state.show}
            close={this.toggleOverlay}
            closeButton
            show={this.state.show}
          />
        ) : null}
      </div>
    );
  }
}
