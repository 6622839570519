import styled from 'styled-components';

interface Props {
  alternateOrder?: boolean;
}
const HalfImageWrap = styled.div<Props>`
  ${({alternateOrder = false}) => (alternateOrder === true ? `order: -1;` : null)}

  & img {
    width: 100%;
  }
`;

export default HalfImageWrap;
