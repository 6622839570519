import epicTracking from 'epic-tracking';
import {useMotionValue, useSpring, useTransform} from 'framer-motion';
import React from 'react';
import useSound from 'use-sound';
import root from 'window-or-global';
import {motion} from 'framer-motion';
import styled, {css, keyframes} from 'styled-components';

import {PlusIcon} from '../../../icons';
import {Device} from '../../../constants';

import {HeroIds, ImageArray} from './Types';

interface HeroProps {
  images: ImageArray[];
  active: boolean;
  keyId: string;
  mobile: boolean;
  tablet: boolean;
  sound?: string;
  goToSlide: (value: number) => void;
  index: number;
}

const pulse = keyframes`
    from {
        box-shadow: 0px 0px 0px 0px rgba(255,255,255,0.25);
    }
    to {
        box-shadow: 0px 0px 3px 30px rgba(255,155,255,0.0);
    }
`;

const heroColors = {
  ironman: css`
    path {
      fill: #f60029;
    }
  `,
  storm: css`
    path {
      fill: #b7022d;
    }
  `,
  groot: css`
    path {
      fill: #719900;
    }
  `,
  mystiques: css`
    path {
      fill: #f60029;
    }
  `,
  thor: css`
    path {
      fill: #f60029;
    }
  `,
  doom: css`
    path {
      fill: #9b6b30;
    }
  `,
  shehulk: css`
    path {
      fill: #20af10;
    }
  `,
};

const IconButton = styled.button`
  width: 40px;
  height: 40px;
  outline: none;
  background-color: transparent;
  border: none;
  padding: 0;
  margin: 0;
  position: absolute;
  z-index: 2;
  transform: translate3d(0, 0, 5px);

  svg {
    display: block;
    position: relative;
    z-index: 1;
    transform: rotate(0);
    transition: transform 300ms ease-in-out;
    ${(props) => heroColors[props.keyId]}
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 90%;
    height: 90%;
    transform: translate(-50%, -50%);
    border-radius: 100%;
    z-index: -1;
    transform-origin: center;

    ${css`
      animation: ${pulse} 1.5s ease-in-out infinite;
    `}
  }

  &:hover {
    svg {
      transform: rotate(180deg);
    }
  }
  @media screen and (max-width: 940px) {
    ${(props) =>
      props.keyId === 'storm'
        ? css`
            margin-top: 30em;
          `
        : css`
            margin-top: 10em;
          `};
  }
  @media screen and ${Device.mobileL} {
    margin-top: 0;
  }
`;

const HeroContainer = styled(({...rest}) => <motion.div {...rest} />)`
  position: absolute;
  height: 90%;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const HeroImage = styled(({...rest}) => <motion.img {...rest} />)`
  position: absolute;
  height: 100%;
  width: auto;
  z-index: 1;
  pointer-events: none;
  opacity: ${(props) => (props.active ? 1 : 0)};
  transition: opacity 500ms ease-in-out;
  @media screen and (max-width: 940px) {
    height: 70%;
    align-self: flex-end;
  }
  @media screen and ${Device.tablet} {
    height: 89%;
    ${({keyId}) =>
      keyId === 'hera'
        ? `margin-left: -65px` // NOTE: Very specific to a specific off balanced image.
        : null};
  }
`;

const Hero = ({images, active, keyId, mobile, tablet, sound, index, goToSlide}: HeroProps) => {
  const [activeSkin, setSkin] = React.useState(0);
  const [currentSlideLength, setSlideLength] = React.useState(0);
  const [grootSound] = useSound(sound || '');
  const switchSkin = React.useCallback(() => {
    const max = images.length - 1;
    setSlideLength(max);
    if (sound && grootSound) {
      grootSound();
      console.clear();
      console.log('Turn on your sound and play again...');
    }
    if (activeSkin < max) setSkin((prev) => prev + 1);
    if (activeSkin >= max) {
      goToSlide(index + 1);
    }
  }, [images, setSkin, activeSkin, sound, grootSound]);

  // We only want to trigger the analytic event when a user directly engages with the button.
  const handleSwitchVariantClick = () => {
    const max = images.length - 1;
    epicTracking.trackEvent('Interaction', {
      interactionType: 'click',
      eventCategory: 'clickHeroSkin',
      eventAction: 'view-hero-skin',
      eventLabel: activeSkin + 1 > max ? 0 : activeSkin + 1,
    });

    switchSkin();
  };

  const xPos = useMotionValue(root ? root.innerWidth / 2 : 1000);
  const x = useTransform(xPos, [0, root ? root.innerWidth : 2000], [50, -50], {clamp: true});
  const xRotate = useTransform(xPos, [0, root ? root.innerWidth : 2000], [-10, 17], {
    clamp: true,
  });
  const xSpring = useSpring(x, {damping: 100});
  const rSpring = useSpring(xRotate, {damping: 100});

  const handleMouse = (e) => {
    const {clientX, pageX} = e;
    if (!pageX && clientX) xPos.set(clientX); //x position within the element.
    if (pageX) xPos.set(pageX);
  };

  React.useEffect(() => {
    root.addEventListener('mousemove', handleMouse, true);
    return () => {
      root.removeEventListener('mousemove', handleMouse, true);
    };
  }, [root]);

  React.useEffect(() => {
    const switchDelay = currentSlideLength ? 3000 : 4000;
    const rotateVariantInterval = setInterval(() => {
      switchSkin();
    }, switchDelay);

    return () => {
      clearInterval(rotateVariantInterval);
    };
  }, [switchSkin]);

  //special styles for certain heroes
  const yPos =
    HeroIds[keyId] === HeroIds.storm || (HeroIds[keyId] === HeroIds.groot && !mobile && !tablet)
      ? -65
      : 0;
  const scale = HeroIds[keyId] === HeroIds.groot ? 1.1 : 1;
  const xp = HeroIds[keyId] === HeroIds.thor ? -60 : 0;

  return (
    <HeroContainer
      initial={{
        scale,
        opacity: 0,
      }}
      style={{
        rotateY: active && !mobile && !tablet ? rSpring : 0,
        x: active && !mobile && !tablet ? xSpring : 0,
      }}
      animate={active ? {y: yPos, scale, opacity: 1} : {y: yPos, scale, opacity: 0}}
      transition={{opacity: {duration: 0.8, ease: 'easeInOut', delay: active ? 0.8 : 0}}}>
      {images.map((item, index) => {
        const itemOnDisplay = Boolean(index === activeSkin);

        return (
          <React.Fragment key={`${keyId}-${index}`}>
            <HeroImage
              active={itemOnDisplay}
              src={`${item.image}?width=500`}
              alt={item.imageAlt}
              initial={{x: 150, z: -200}}
              keyId={keyId}
              animate={
                active
                  ? {x: xp, z: 1}
                  : {
                      x: !mobile && !tablet ? 150 : 20,
                      z: !mobile && !tablet ? -200 : -50,
                    }
              }
              transition={{duration: 0.8, ease: 'easeInOut', delay: active ? 0.8 : 0}}
            />
            {(images.length > 1 || sound) && itemOnDisplay && (
              <IconButton keyId={keyId} onClick={handleSwitchVariantClick}>
                <PlusIcon fillColor={item.plusIconColor} />
              </IconButton>
            )}
          </React.Fragment>
        );
      })}
    </HeroContainer>
  );
};

export default Hero;
