import styled from 'styled-components';

import {breakpoints} from '../../../../constants/Breakpoints';

const ArchiveViewWrapper = styled.div`
  min-height: 80rem;
  @media ${breakpoints.laptop} {
    min-height: 75vw;
  }

  @media ${breakpoints.tablet} {
    min-height: 130vw;
  }

  @media ${breakpoints.mobileL} {
    min-height: 100vh;
  }
`;

export default ArchiveViewWrapper;
