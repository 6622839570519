import AnimateObject from 'epic-fortnite-react/components/common/AnimateObject';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import VisibilitySensor from 'react-visibility-sensor';

import AnimateString from '../common/AnimateString';

const GunWraps = ({data}) => {
  const [visible, setVisibility] = useState(false);
  const {guns} = data;
  const renderGuns = () => {
    return guns.map(({title, paragraph, image, altImage}, index) => {
      return (
        <div className="gun-content" key={`gun-${index}`}>
          <div className="gun-parallax">
            <div className="mouse-parallax">
              <AnimateObject
                minTop={20}
                speed={1.5}
                from={{
                  y: `+=150`,
                  autoAlpha: 0,
                }}
                to={{
                  y: 0,
                  autoAlpha: 1,
                }}>
                <img className="gun" src={image} alt={altImage} />
              </AnimateObject>
            </div>
          </div>
          <div className="gunwrap-description">
            {title ? (
              <AnimateString
                key={`gunwrap-${index}-h1`}
                text={title}
                type="h1"
                speed={1.5}
                delay={0.3}
                autoAlpha={0}
                yPos={100}
                minTop={20}
                scaleFrom={1}
                staggerDelay={0}
              />
            ) : null}
            {paragraph ? (
              <AnimateString
                key={`gunwrap-${index}-p`}
                text={paragraph}
                type="p"
                speed={1.5}
                delay={0.3}
                autoAlpha={0}
                yPos={100}
                minTop={20}
                scaleFrom={1}
                staggerDelay={0}
              />
            ) : null}
          </div>
        </div>
      );
    });
  };

  return (
    <VisibilitySensor
      onChange={(visibility) => setVisibility(visibility)}
      active={!visible}
      partialVisibility
      minTopValue={100}>
      <div className={`battlepass-gunwraps ${visible ? 'visible' : ''}`}>
        <div className="gunwraps-wrapper">{renderGuns()}</div>
      </div>
    </VisibilitySensor>
  );
};

GunWraps.propTypes = {
  data: PropTypes.object,
};

export default GunWraps;
