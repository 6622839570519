import axios from 'axios';
import root from 'window-or-global';

import PlayerActions from '../actions/PlayerActions';
import Url from '../constants/Url';

/**
 * PlayerSource
 *
 * Responsible for making the ajax calls for players.
 *
 * For more information on source usage:
 * http://alt.js.org/docs/async/
 *
 */
const PlayerSource = {
  getUserName: {
    remote(state) {
      //the store state is accessible here
      return axios.get(Url().PLAYERS.USERNAME);
    },

    local(state) {
      return false;
    },

    shouldFetch(state) {
      return !root.__server_side_render && (!state || !state.userName);
    },

    //On success call the following action:
    success: PlayerActions.getUserNameSuccess,

    //On failure call the following action:
    error: PlayerActions.getUserNameFailure,
  },
};

export default PlayerSource;
