import React, {
  cloneElement,
  forwardRef,
  useCallback,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';

import {usePrevious} from '../../../hooks/usePrevious';
import {isElementInView} from '../../../utils/isElementInView';
import {isEmpty} from '../../../utils/isEmpty';

export type SliderWrapperProps = {
  $slideOffset?: number;
  $slideOverflowX?: number;
  $slideOverflowY?: number;
  $slidesInView?: number;
};

export type SlidesProps = {
  $allSlidesVisible?: boolean;
  $slideGap?: number;
  $slideOverflowX?: number;
};

export type SlideProps = {
  $align?: 'center' | 'start';
  $slideGap?: number;
  $slideOffset?: number;
  $slidesInView?: number;
  $slideWidth?: string;
};

export type SliderProps = {
  align?: 'center' | 'start';
  children?: React.ReactNode;
  slideGap?: number;
  slideOffset?: number;
  slideOverflowX?: number;
  slideOverflowY?: number;
  slidesInView?: number;
  slidesPerClick?: number;
};

const SliderWrapper = styled.div<SliderWrapperProps>`
  display: block;
  margin-bottom: ${({$slideOverflowY}) => `-${$slideOverflowY}px`};
  margin-top: ${({$slideOverflowY}) => `-${$slideOverflowY}px`};
  overflow: hidden;
  position: relative;
  width: calc(100% + 64px);
`;

const Slides = styled.ul<SlidesProps>`
  align-items: stretch;
  direction: ltr !important; // fix later
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  margin: 0;
  -webkit-overflow-scrolling: touch;
  overflow-x: scroll;
  overflow-y: hidden;
  padding: 0;
  padding-left: ${({$allSlidesVisible}) => ($allSlidesVisible ? '32px' : '16px')};
  padding-right: ${({$allSlidesVisible}) => ($allSlidesVisible ? '32px' : '16px')};
  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;
  scrollbar-width: none; /* Firefox */
  width: 100%;

  &::-webkit-scrollbar {
    background-color: transparent;
    display: none;
    width: 0;
  }
`;

const Slide = styled.li<SlideProps>`
  display: block;
  flex-shrink: 0;
  overflow-y: hidden;
  padding-left: ${({$slideGap}) => $slideGap && `${$slideGap * 0.5}px`};
  padding-right: ${({$slideGap}) => $slideGap && `${$slideGap * 0.5}px`};
  position: relative;
  scroll-snap-align: ${({$align}) => $align};
  width: ${({$slideWidth}) => $slideWidth};
`;

const SliderButton = styled.button`
  background-color: rgba(255, 255, 255, 0.6);
  border: 0;
  border-radius: 50%;
  cursor: pointer;
  display: block;
  filter: drop-shadow(0px 0px 16px rgba(0, 0, 0, 0.15));
  height: 48px;
  opacity: 0;
  padding: 6px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: opacity 0.2s ease-out;
  width: 48px;
  z-index: 999;

  // hide buttons on touch screens
  @media (hover: none) and (pointer: coarse) {
    display: none;
  }

  &:hover,
  &.focus-visible {
    opacity: 1;
  }

  ${SliderWrapper}:hover & {
    opacity: 0.8;
  }

  svg {
    height: 100%;
    width: 100%;
  }
`;

const SliderButtonPrev = styled(SliderButton)`
  left: 16px;
`;

const SliderButtonNext = styled(SliderButton)`
  right: 16px;
`;

const Slider = forwardRef<HTMLDivElement, SliderProps>(
  (
    {
      align = 'start',
      children,
      slideGap = 0,
      slideOffset = 0,
      slideOverflowX = 0,
      slideOverflowY = 0,
      slidesPerClick = 3,
      slidesInView = 1,
    },
    ref
  ) => {
    const slidesRef = useRef<HTMLUListElement>(null);

    const [allSlidesVisible, setAllSlidesVisible] = useState(false);
    const [canScrollPrev, setCanScrollPrev] = useState(false);
    const [canScrollNext, setCanScrollNext] = useState(false);

    const prevSlidesInView = usePrevious(slidesInView);

    const handleScrollPrev = useCallback(() => {
      if (slidesRef?.current) {
        const container = slidesRef.current;
        const slideArray = Array.from(slidesRef.current.children);

        let firstSlideInView: any;
        let lastSlideOutOfView: any;

        for (let i = 0; i < slideArray.length && !firstSlideInView; i++) {
          if (isElementInView(container, slideArray[i], false)) {
            firstSlideInView = slideArray[i];
            lastSlideOutOfView = slideArray[i - 1];
          }
        }

        if (lastSlideOutOfView) {
          container.scrollBy({
            behavior: `smooth`,
            left: -lastSlideOutOfView.scrollWidth * slidesPerClick,
          });
        }
      }
    }, [slidesPerClick, slidesRef]);

    const handleScrollNext = useCallback(() => {
      const container = slidesRef.current;
      const slideArray = container && Array.from(container?.children);

      let lastSlideInView: any;
      let firstSlideOutOfView: any;

      if (slideArray && !isEmpty(slideArray)) {
        for (let i = slideArray?.length - 1; i >= 0 && !lastSlideInView; i--) {
          if (isElementInView(container, slideArray[i], false)) {
            lastSlideInView = slideArray[i];
            firstSlideOutOfView = slideArray[i + 1];
          }
        }
      }

      if (firstSlideOutOfView) {
        container?.scrollBy({
          behavior: `smooth`,
          left: firstSlideOutOfView.scrollWidth * slidesPerClick,
        });
      }
    }, [slidesPerClick, slidesRef]);

    // const handleScrollBackToStart = () => {
    //   const container = slidesRef?.current;

    //   container?.scrollBy({
    //     behavior: `smooth`,
    //     left: -container.scrollWidth,
    //   });
    // };

    const handleResize = () => {
      const container = slidesRef?.current;

      const currentlyCanScrollPrev = !isElementInView(container, container?.firstChild, false);
      const currentlyCanScrollNext = !isElementInView(container, container?.lastChild, false);

      setCanScrollPrev(currentlyCanScrollPrev);
      setCanScrollNext(currentlyCanScrollNext);

      setAllSlidesVisible(!currentlyCanScrollPrev && !currentlyCanScrollNext);
    };

    useLayoutEffect(() => {
      window.addEventListener(`resize`, handleResize);
      slidesRef?.current?.addEventListener(`scroll`, handleResize);
      handleResize();

      return () => {
        window.removeEventListener(`resize`, handleResize);
      };
    }, [handleResize, slidesRef]);

    // make sure slider starts at beginning
    useLayoutEffect(() => {
      if (slidesInView !== prevSlidesInView) {
        const container = slidesRef?.current;

        const canScrollPrev = !isElementInView(container, container?.firstChild, false);

        canScrollPrev && handleScrollPrev();
      }
    }, [handleScrollPrev, prevSlidesInView, slidesRef, slidesInView]);

    return (
      <SliderWrapper
        $slideOffset={slideOffset}
        $slideOverflowX={slideOverflowX}
        $slideOverflowY={slideOverflowY}
        $slidesInView={slidesInView}
        ref={ref}>
        {canScrollPrev && (
          <SliderButtonPrev aria-hidden={true} onClick={handleScrollPrev} tabIndex={-1}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 38 38">
              <path d="M.053 19C.053 8.536 8.536.053 19 .053c8.715 0 16.055 5.883 18.266 13.894l-20.792 1.895 6-5.684-5.053-5.053L4.79 18.368 17.42 32.895l5.053-5.053-6-6 21.156.632C35.998 31.279 28.278 37.947 19 37.947 8.536 37.947.053 29.464.053 19z" />
            </svg>
          </SliderButtonPrev>
        )}
        {canScrollNext && (
          <SliderButtonNext aria-hidden={true} onClick={handleScrollNext} tabIndex={-1}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 38 38">
              <path d="M37.947 19C37.947 8.536 29.464.053 19 .053 10.285.053 2.945 5.936.734 13.947l20.792 1.895-6-5.684 5.053-5.053L33.21 18.368 20.58 32.895l-5.053-5.053 6-6-21.156.632C2.002 31.279 9.722 37.947 19 37.947c10.464 0 18.947-8.483 18.947-18.947z" />
            </svg>
          </SliderButtonNext>
        )}
        <Slides
          $allSlidesVisible={allSlidesVisible}
          $slideGap={slideGap}
          $slideOverflowX={slideOverflowX}
          ref={slidesRef}>
          {React.Children.map(children, (child) => {
            const currentSlideWidth = `calc((100% / ${slidesInView}) - ${
              allSlidesVisible ? '0px' : `64px / ${slidesInView}`
            }
            )`;

            if (React.isValidElement(child)) {
              return (
                <Slide $align={align} $slideGap={slideGap} $slideWidth={currentSlideWidth}>
                  {cloneElement(child)}
                </Slide>
              );
            }
          })}
        </Slides>
      </SliderWrapper>
    );
  }
);

Slider.displayName = `Slider`;

export {Slider};
