import {Device} from 'epic-fortnite-styles/constants';
import styled from 'styled-components';

import StyledPlayVideo from '../s6/StyledPlayVideo';

const StyledVideoHeaderC2s8 = styled.div<{captionPos: string}>`
  height: 90vh;
  background-color: white;
  min-height: 900px;
  position: relative;
  overflow: hidden;

  @media screen and (max-width: 1700px) {
    min-height: 850px;
  }

  @media screen and ${Device.laptopL} {
    height: 68vh;
    min-height: 600px;
  }

  @media screen and ${Device.laptop} {
    height: 54vh;
    min-height: 450px;
  }

  @media screen and ${Device.mobileL} {
    min-height: 650px;
    margin-top: -2rem;
  }

  @media screen and ${Device.mobileM} {
    min-height: 590px;
    margin-top: -1rem;
  }

  @media screen and ${Device.mobileS} {
    min-height: 466px;
  }

  ${StyledPlayVideo} {
    bottom: 13%;
    right: 3%;

    @media screen and ${Device.desktopS} {
      bottom: 10%;
    }

    @media screen and ${Device.laptopL} {
      bottom: 10%;
    }

    @media screen and ${Device.tablet} {
      bottom: 5%;
    }

    @media screen and ${Device.mobileL} {
      right: unset;
      bottom: 5em;
    }
  }

  &.overview ${StyledPlayVideo} {
    bottom: 13%;
    right: 3%;

    @media screen and ${Device.desktopS} {
      bottom: 10%;
    }

    @media screen and ${Device.laptopL} {
      bottom: 10%;
    }

    @media screen and ${Device.tablet} {
      bottom: 5%;
    }

    @media screen and ${Device.mobileL} {
      right: unset;
      bottom: 5em;
    }
  }

  ${({theme}) => `
        ${
          (theme.locale === 'ar' &&
            `
            ${StyledPlayVideo} {
                left: 3%;
                right: unset;
                @media screen and ${Device.mobileL} {
                    left: unset;
                }
            }
        `) ||
          ''
        }
    `}
`;

export default StyledVideoHeaderC2s8;
