import styled from 'styled-components';

import {breakpoints} from '../../../../constants/Breakpoints';

const MaskDescription = styled.div`
  &&& {
    font-size: 3.5rem;
  }
  @media ${breakpoints.mobileL} {
    &&& {
      font-size: 2.4rem;
    }
  }
  margin-bottom: 2rem;
  text-align: center;
  width: 100%; // Fix Safari bug w/ random line breaks
`;

export default MaskDescription;
