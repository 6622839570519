import PropTypes from 'prop-types';
import React from 'react';

import ScreenItem from './ScreenItem';

const Screens = ({data, className}) => {
  const {list} = data;

  if (!list.length) return null;

  return (
    <div className={`chapters-screens ${className}`}>
      {list.map(({image, title, description, imageAlt}, index) => {
        return (
          <ScreenItem
            key={`screen-${index}`}
            title={title}
            description={description}
            image={image}
            imageAlt={imageAlt}
            index={index}
          />
        );
      })}
    </div>
  );
};

Screens.propTypes = {
  data: PropTypes.object,
  className: PropTypes.string,
};

export default Screens;
