/**
 * custom classes defined for QA team
 * @type {Object}
 */
const CUSTOM_CLASSES = {
    root: '__post__',
    date: '__post-date__',
    author: '__post-author__',
    category: '__post-category__',
    title: '__post-title__',
    short: '__post-short__'
};

export default CUSTOM_CLASSES;
