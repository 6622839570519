import {useSharedValue} from '@epic-core/hooks';

import {DarklySharedKeys} from './darkly.keys';

export type DarklyKeys = 'blackout';

export type LaunchDarklyStoreProps = {
  //eslint-disable-next-line
  [key in DarklyKeys]?: boolean;
};

export const useLaunchDarklyStore = (): LaunchDarklyStoreProps => {
  const [darklyStore] = useSharedValue<LaunchDarklyStoreProps>(DarklySharedKeys.LaunchDarklyStore);
  return darklyStore || {};
};

interface AppVariationRes {
  value: boolean | null;
  source: string;
}

export const useDarklyAppVariation = (key: DarklyKeys): AppVariationRes => {
  const darklyStore = useLaunchDarklyStore();

  return {
    value: darklyStore[key] || false,
    source: 'store',
  };
};
