import {BaseButton, BaseButtonProps} from 'epic-fortnite-react';
import React from 'react';

import {RiftTourButton, RiftTourButtonProps} from './RiftTourButton';
import {RiftTourSubheading} from './RiftTourSubheading';

export interface RiftTourCTAProps extends RiftTourButtonProps, BaseButtonProps {
  textLink?: boolean;
}

export const RiftTourCTA = ({label, textLink, ...props}: RiftTourCTAProps) => {
  return textLink ? (
    <BaseButton
      label={<RiftTourSubheading color="#FEDD03">{label}</RiftTourSubheading>}
      {...props}
    />
  ) : (
    <RiftTourButton label={label} {...props} />
  );
};
