import {withLocalization} from '@epic-core/localization';
import classNames from 'classnames';
import {generateRouteTo} from 'epic-fortnite-react';
import {connectToStores} from 'epic-alt-utils';
import Error404 from 'epic-fortnite-react/components/error/Error404';
import {generateMainSitePath} from 'epic-fortnite-react/utils/FortniteUtils';
import PropTypes from 'prop-types';
import React from 'react';
import Helmet from 'react-helmet';
import {matchPath, withRouter} from 'react-router';
import {Link} from 'react-router-dom';
import Immutable from 'seamless-immutable';
import root from 'window-or-global';

import BattlePassSection from '../../components/battlePass/BattlePassSection';
import BackgroundWithSections from '../../components/landingpages/BackgroundWithSections';
import LandingPageSection from '../../components/landingpages/LandingPageSection';
import {
  AppConfigStore,
  CountryStore,
  FooterStore,
  HeaderStore,
  LandingPageStore,
  LoginStore,
  MobileStore,
  PlayerStore,
} from '../../stores';

/**
 * LandingPageView.
 *
 * Rendered when the Route matches /getfortnite
 */

@connectToStores
class LandingPageView extends React.Component {
  static propTypes = {
    location: PropTypes.object,
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    loginStore: PropTypes.object,
    headerStore: PropTypes.object,
    footerStore: PropTypes.object,
    appConfigStore: PropTypes.object,
    mobileStore: PropTypes.object,
    playerStore: PropTypes.object,
    countryStore: PropTypes.object,
    supportedLocales: PropTypes.array.isRequired,
    messages: PropTypes.object.isRequired,
    landingPageStore: PropTypes.object,
    tablet: PropTypes.bool,
    mobile: PropTypes.bool,
    locale: PropTypes.string,
  };

  // Messages context is used by the Locale component.
  static childContextTypes = {
    messages: PropTypes.object,
  };

  constructor(props) {
    super(props);
    const {messages} = this.props;
    this.messages = messages;
  }

  getChildContext() {
    return {
      messages: this.getMessages(),
    };
  }

  static getStores() {
    return [
      LoginStore,
      HeaderStore,
      FooterStore,
      AppConfigStore,
      MobileStore,
      PlayerStore,
      LandingPageStore,
      CountryStore,
    ];
  }

  static getPropsFromStores() {
    return {
      loginStore: LoginStore.getState(),
      headerStore: HeaderStore.getState(),
      footerStore: FooterStore.getState(),
      appConfigStore: AppConfigStore.getState(),
      mobileStore: MobileStore.getState(),
      playerStore: PlayerStore.getState(),
      landingPageStore: LandingPageStore.getState(),
      countryStore: CountryStore.getState(),
      mobile: HeaderStore.getState().mobile(),
      tablet: HeaderStore.getState().tablet(),
    };
  }

  getMessages() {
    const config = this.getConfig();
    return config.messages;
  }

  getConfig() {
    const headerStore = this.props.headerStore || {};
    return headerStore.config || Immutable({});
  }

  /**
   * Retrieve localization string.
   * @return {String}
   */
  getMessageCode(code) {
    return this.messages[code];
  }

  getLocaleOptions() {
    // The localizationStore contains an immutable array.
    const {supportedLocales} = this.props;
    const mutableArray = [];
    for (let i = 0; i < supportedLocales.length; i++) {
      mutableArray.push({
        name: this.getMessageCode(supportedLocales[i].name),
        lang: supportedLocales[i].lang,
      });
    }
    return mutableArray.length ? Immutable(mutableArray) : supportedLocales;
  }

  visitFortnite() {
    const {history, locale} = this.props;
    console.log('LandingPageView push to main site');
    history.push(generateMainSitePath('/', locale));
  }

  renderWithHeader() {
    const {
      landingPageStore,
      loginStore,
      mobileStore,
      appConfigStore,
      playerStore,
      footerStore,
      countryStore,
      match,
      history,
    } = this.props;

    const activeSlug = this.props.match.params.slug;
    const page = landingPageStore.pages.pages[activeSlug];

    if (!landingPageStore.pages.enabled || !page) {
      return <Error404 />;
    }

    const tags = [
      {itemProp: 'name', content: page.metaTitle},
      {itemProp: 'description', content: page.metaDescription},
      {itemProp: 'image', content: page.metaImage},
      {name: 'description', content: page.metaDescription},
      {name: 'twitter:title', content: page.metaTitle},
      {name: 'twitter:description', content: page.metaDescription},
      {name: 'twitter:image', content: page.metaImage},
      {property: 'og:title', content: page.metaTitle},
      {property: 'og:image', content: page.metaImage},
      {property: 'og:description', content: page.metaDescription},
      {name: 'robots', content: 'noindex'},
    ];

    const sections = page.sections
      .map((section) => {
        if (section.data && /^BattlePass/.test(section.data._type)) {
          return (
            <BattlePassSection
              key={section._slug}
              mobile={this.props.mobile}
              tablet={this.props.tablet}
              section={section}
              isLoggedIn={loginStore.isLoggedIn}
            />
          );
        } else if (section.hasSections) {
          return (
            <BackgroundWithSections
              key={section._slug}
              mobileStore={mobileStore}
              appConfigStore={appConfigStore}
              playerStore={playerStore}
              footerStore={footerStore}
              countryStore={countryStore}
              mobile={this.props.mobile}
              tablet={this.props.tablet}
              messages={this.props.messages}
              data={section}
              isLoggedIn={loginStore.isLoggedIn}
              match={match}
              history={history}
            />
          );
        } else if (section.data && /^LandingPage/.test(section.data._type)) {
          return (
            <LandingPageSection
              key={section._slug}
              mobileStore={mobileStore}
              appConfigStore={appConfigStore}
              playerStore={playerStore}
              footerStore={footerStore}
              countryStore={countryStore}
              mobile={this.props.mobile}
              tablet={this.props.tablet}
              messages={this.props.messages}
              section={section}
              isLoggedIn={loginStore.isLoggedIn}
              match={match}
              history={history}
            />
          );
        } else if (section.content) {
          return (
            <div
              key={section._slug}
              className="body"
              dangerouslySetInnerHTML={{__html: section.content}}
            />
          );
        }
        return null;
      })
      .filter((section) => section);

    const containerClass = classNames('page-body', activeSlug);
    return (
      <div className={containerClass}>
        <Helmet meta={tags} title={page.metaTitle} />
        {sections}
      </div>
    );
  }

  renderWithNoHeader() {
    const {landingPageStore = {}, location, locale} = this.props;

    const pages = landingPageStore?.pages;
    const activeSlug = this.props.match?.params?.slug;

    if (!pages || !activeSlug) {
      return <Error404 />;
    }

    const page = pages?.pages[activeSlug];

    if (!pages?.enabled || !page) {
      return <Error404 />;
    }

    const visitlinkclasses = `fulls ${page.positionVisitLink || ''}`;
    const containerClass = classNames('page-body', 'noheader', activeSlug);
    const {visitLinkColor} = page;
    const visitLinkStyle = {};
    if (visitLinkColor) {
      visitLinkStyle.color = visitLinkColor;
    }
    return (
      <div className={containerClass}>
        <div className="container">
          {page.disableLogo ? (
            ''
          ) : (
            <div className="logo fortnite" role="presentation" id="egh">
              <div className="propLogo" role="presentation" onClick={this.visitFortnite} />
            </div>
          )}
          <div className="full-site-container">
            {page.disableVisitLink ? null : (
              <div className={visitlinkclasses}>
                <Link to={generateRouteTo('/', location, locale)} style={visitLinkStyle}>
                  {this.messages['epic.fortnite.landingpage.visitfullsite']}
                </Link>
              </div>
            )}
          </div>
        </div>
        {this.renderWithHeader()}
      </div>
    );
  }

  showHeaderAndFooter = () => {
    const {landingPageStore, location} = this.props;
    const patterns = [`/:slug`, `/:slug/success`];
    for (let i = 0; i < patterns.length; i += 1) {
      const match = matchPath(location.pathname, {
        path: patterns[i],
        exact: true,
        strict: false,
      });
      if (match && match.params.slug) {
        const activeSlug = match.params.slug;
        if (landingPageStore.noHeaderPages[activeSlug]) {
          return false;
        }
      }
    }
    return true;
  };

  render() {
    if (this.showHeaderAndFooter()) {
      return this.renderWithHeader();
    }

    return this.renderWithNoHeader();
  }
}

export default withLocalization(withRouter(LandingPageView));
