import PropTypes from 'prop-types';
import React from 'react';
import VisibilitySensor from 'react-visibility-sensor';

export default class AnimateObject extends React.PureComponent {
  static propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.object), PropTypes.object]),
    minTop: PropTypes.number,
    speed: PropTypes.number,
    className: PropTypes.string,
    from: PropTypes.object,
    to: PropTypes.object,
  };

  state = {
    animate: false,
  };

  tweenObj = () => {
    const {from, to, speed} = this.props;

    // cant pass ease without tweenmax obj, so not customize that part
    const mergeTo = Object.assign({ease: Elastic.easeOut.config(0.5, 0.4)}, to);

    TweenMax.fromTo(this.item, speed, from, mergeTo);
  };

  onVisibilityChange = (isVisible) => {
    if (isVisible && !this.state.animate) {
      this.setState({animate: true});
      this.tweenObj();
    }
  };

  render() {
    const {children, minTop, className} = this.props;
    if (!children) return <div />;
    const animateObjectClass = className ? `animate-object ${className}` : 'animate-object';
    return (
      <VisibilitySensor
        onChange={this.onVisibilityChange}
        active={!this.state.animated}
        partialVisibility
        minTopValue={minTop}
        scrollCheck
        scrollDelay={10}>
        <span
          className={animateObjectClass}
          ref={(c) => {
            this.item = c;
          }}>
          {children}
        </span>
      </VisibilitySensor>
    );
  }
}
