import {useRef, useEffect} from 'react';
import root from 'window-or-global';

export const useJuxtaposeScript = () => {
  const juxtaposeScriptRef = useRef<HTMLScriptElement | null>(null);

  useEffect(() => {
    const enableJuxtapose = root.document.getElementsByClassName('juxtapose');
    if (enableJuxtapose.length) {
      const juxtaposeScript = root.document.createElement('script');
      juxtaposeScript.src = 'https://cdn3.unrealengine.com/static/js/juxtapose.min.js';
      root.document.body.appendChild(juxtaposeScript);

      juxtaposeScriptRef.current = juxtaposeScript;

      return () => {
        if (juxtaposeScriptRef.current) {
          juxtaposeScriptRef.current?.parentNode?.removeChild(juxtaposeScriptRef.current);
        }
      };
    }
  }, []);

  return null;
};
