import React from 'react';

import Container from '../components/Container';
import CTA from '../components/CTA';
import Gradient from '../components/Gradient';
import ResponsiveImage from '../components/ResponsiveImage';
import SpikeLayer from '../components/SpikeLayer';
import {Background, ResponsiveImage as Image} from '../types';

import {Grid, Header, ImageWrapper, TextWrapper} from './components';

export interface ArchiveLinkSectionProps {
  header?: string;
  image?: Image;
  ctaText?: string;
  background?: Background;
}

const ArchiveLinkSection = ({header, image, ctaText, background}: ArchiveLinkSectionProps) => {
  const ctaConfig = React.useMemo(
    () => ({
      ctaString: ctaText ?? 'Archive',
      ctaLink: '/cosplay/archive',
      disabled: false,
      isCtaLinkExternal: false,
      textOnly: false,
    }),
    [ctaText]
  );

  return (
    <SpikeLayer>
      <Gradient gradient={background}>
        <Container>
          <Grid>
            <TextWrapper>
              <Header>{header}</Header>
              <CTA id="goToArchive" ctaConfig={ctaConfig} />
            </TextWrapper>
            <ImageWrapper>
              <ResponsiveImage
                desktopSrc={image?.desktopImage}
                tabletSrc={image?.tabletImage}
                mobileSrc={image?.mobileImage}
              />
            </ImageWrapper>
          </Grid>
        </Container>
      </Gradient>
    </SpikeLayer>
  );
};

export default ArchiveLinkSection;
