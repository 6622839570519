import styled from 'styled-components';

interface Props {
  textColor?: string;
  textColorHover?: string;
}

const StyledArticleLink = styled.a<Props>`
  && {
    color: ${({textColor}) => textColor || 'inherit'} !important;
    font-size: 1.5em;
    font-family: BurbankBigRegular-Black, sans-serif;

    :hover {
      color: ${({textColorHover}) => textColorHover || 'inherit'} !important;
    }
  }
`;

export default StyledArticleLink;
