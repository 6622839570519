import {getLocale} from '@epic-core/common';
import classNames from 'classnames';
import {DownloadButton} from 'epic-fortnite-react';
import PropTypes from 'prop-types';
import React from 'react';
import VisibilitySensor from 'react-visibility-sensor';

export default class CreativeCard extends React.PureComponent {
  static propTypes = {
    isLoggedIn: PropTypes.bool,
    data: PropTypes.object,
    mobile: PropTypes.bool,
  };

  infoBoxes = [];

  state = {
    animated: false,
  };

  inView = (isVisible) => {
    const {infoBoxes} = this;
    if (isVisible && !this.state.animated) {
      this.setState({animated: true});
      this.contentAnimation(infoBoxes);
    }
  };

  contentAnimation = (item) => {
    TweenMax.staggerFromTo(
      item,
      2,
      {
        y: '+=150',
        autoAlpha: 0,
      },
      {
        y: 0,
        autoAlpha: 1,
        ease: Elastic.easeOut.config(1, 1),
        force3D: true,
      },
      0.1
    );
  };

  render() {
    const {isLoggedIn, data, mobile} = this.props;

    const {ctaText, infoBox = []} = data;

    const locale = getLocale();

    const activeItems = [];
    for (let x = 0; x < infoBox.length; x++) {
      const key = `infoItem-${x}`;
      const {image, titleOne, description, disable} = infoBox[x];
      if (!disable) {
        activeItems.push(
          <div
            key={key}
            ref={(c) => {
              this.infoBoxes[x] = c;
            }}
            className="card">
            <img className="card-image" src={image} alt={titleOne} />
            <div className="card-content">
              <h2 className="card-title">{titleOne}</h2>
              <div className="card-description" dangerouslySetInnerHTML={{__html: description}} />
            </div>
          </div>
        );
      }
    }

    const containerClass = classNames('infoBoxContainer', {
      two: activeItems.length === 2,
      three: activeItems.length === 3,
    });

    return (
      <div className="creative-card">
        <div className="gradient" />
        <div className="section">
          <VisibilitySensor
            key="infoBoxAnimation"
            onChange={this.inView}
            active={!this.state.animated}
            scrollCheck
            partialVisibility={mobile}
            minTopValue={50}
            scrollDelay={10}>
            <div className={containerClass}>
              <div className="container-fluid">
                <div className="row">
                  <div className="cards">{activeItems}</div>
                </div>
                <div className="row">
                  <div className="col-xs-12 text-center">
                    <div className="buttonWrapper">
                      <DownloadButton
                        id="CreativeModeSquads"
                        className="play-free"
                        rawCta={ctaText}
                        isLoggedIn={isLoggedIn}
                        locale={locale}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </VisibilitySensor>
        </div>
      </div>
    );
  }
}
