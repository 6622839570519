import {Container, Inline, Section, Stack, Text} from 'epic-fortnite-react';
import React from 'react';

import {CoreSectionProps} from '../bundles/types';
import CTAButton from '../common/CTAButton';
export interface SimpleTextWithBackgroundProps {
  alignment?: 'left' | 'center' | 'right';
  coreSectionProps?: CoreSectionProps;
  headerColor?: string;
  logoAsHeader?: string;
  textContent?: string;
  textContentColor?: string;
  legalText?: string;
  alt?: boolean;
  altTextContent?: string;
  textHeader?: string;
  condensed?: boolean;
  cta?: {
    isCtaLinkExternal?: boolean;
    ctaString: string;
    ctaLink: string;
    disabled?: boolean;
    textOnly?: boolean;
    theme?: string;
    onExternalClickHandler?: any;
  };
}

const SimpleTextWithBackground = ({
  textHeader,
  alignment,
  cta,
  coreSectionProps,
  headerColor,
  logoAsHeader,
  textContent,
  textContentColor,
  legalText,
  altTextContent,
  alt,
  condensed,
}: SimpleTextWithBackgroundProps) => {
  const textToRender = alt && altTextContent ? altTextContent : textContent || logoAsHeader;

  return textToRender ? (
    <Section
      backgroundImage={coreSectionProps?.backgroundImage}
      backgroundColor={coreSectionProps?.backgroundColor}
      size={condensed ? 'med' : 'xl'}
      backgroundPosition={
        coreSectionProps?.backgroundPosition ? coreSectionProps?.backgroundPosition : 'center'
      }
      backgroundSize={
        coreSectionProps?.backgroundSize ? coreSectionProps?.backgroundSize : 'cover'
      }>
      <Container maxWidth={800} size="med">
        <Section size={coreSectionProps?.size ?? '5xl'}>
          <Stack gap="2xl">
            {textHeader && (
              <Text
                align="center"
                color={headerColor ? headerColor : 'var(--bundle-text-color--body)'}
                lineLength="med"
                size="2xl"
                transform="uppercase">
                {textHeader}
              </Text>
            )}
            {textContent && (
              <Text
                align="center"
                color={textContentColor ? textContentColor : 'var(--bundle-text-color--body)'}
                lineLength="med"
                size={condensed ? 'lg' : 'xl'}
                transform="uppercase">
                {textToRender}
              </Text>
            )}
            {logoAsHeader ? (
              <img alt="Rating" src={textToRender} style={{margin: '0 auto', width: '200px'}} />
            ) : null}
            {cta?.ctaString && cta?.ctaLink && <CTAButton ctaConfig={cta} theme="brightYellow" />}
          </Stack>
        </Section>
        {legalText && (
          <Inline justify="center">
            <Text
              align="center"
              color={textContentColor ? textContentColor : 'var(--bundle-text-color--body)'}
              font="OpenSans"
              lineLength="med"
              size="sm"
              weight="600">
              {legalText}
            </Text>
          </Inline>
        )}
      </Container>
    </Section>
  ) : null;
};

export default SimpleTextWithBackground;
