import React from 'react';

interface Props {
  styles?: React.CSSProperties;
  fillColor?: string;
}

const PlusIcon = ({styles, fillColor}: Props) => (
  <svg style={styles} viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
    <circle fill="#fff" cx="19.9" cy="20" r="19.2" />
    <path fill={fillColor || '#d60a08'} d="M29.2 16.8h-6v-6h-6.5v6h-6v6.5h6v5.9h6.5v-5.9h6z" />
  </svg>
);

export default PlusIcon;
