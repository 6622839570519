import React, {useRef} from 'react';
import styled from 'styled-components';

import {useResponsiveBreakpoints} from '../../../hooks/useResponsiveBreakpoints';
import {space} from '../../../theme/theme';
import {Spaces} from '../../../types/theme';

type FlexAlign = 'center' | 'flex-end' | 'flex-start' | 'stretch';
type FlexBreakpoint = number;
type FlexDirection = 'column' | 'column-reverse' | 'row' | 'row-reverse';
type FlexDisplay = 'block' | 'flex';
type FlexGap = Spaces;
type FlexGapX = Spaces;
type FlexGapY = Spaces;
type FlexJustify =
  | 'center'
  | 'flex-end'
  | 'flex-start'
  | 'space-around'
  | 'space-between'
  | 'stretch';
type FlexScrollable = boolean;

export interface StyledFlexProps {
  $align?: FlexAlign;
  $breakpoint?: FlexBreakpoint;
  $direction?: FlexDirection;
  $display?: FlexDisplay;
  $gap?: FlexGap;
  $gapX?: FlexGapX;
  $gapY?: FlexGapY;
  $justify?: FlexJustify;
  $scrollable?: FlexScrollable;
}

export interface FlexProps {
  align?: FlexAlign;
  breakpoint?: FlexBreakpoint;
  children: React.ReactNode;
  direction?: FlexDirection;
  display?: FlexDisplay;
  gap?: FlexGap;
  gapX?: FlexGapX;
  gapY?: FlexGapY;
  justify?: FlexJustify;
  scrollable?: FlexScrollable;
}

const StyledFlex = styled.div<StyledFlexProps>`
  --flex-gapX: ${({$gap, $gapX}) =>
    $gapX || $gap
      ? `${space[`${$gapX || $gap}`]}px`
      : '0px'}; /* 0px fallback needed for calc to work */
  --flex-gapY: ${({$gap, $gapY}) =>
    $gapY || $gap
      ? `${space[`${$gapY || $gap}`]}px`
      : '0px'}; /* 0px fallback needed for calc to work */

  align-items: ${({$align}) => $align};
  display: ${({$display}) => $display};
  flex-grow: 1;
  flex-direction: ${({$direction}) => $direction};
  height: ${({$scrollable}) => $scrollable && '100%'};
  justify-content: ${({$justify}) => $justify};
  margin: calc(var(--flex-gapY) * -1) calc(var(--flex-gapX) * -1);
  max-width: calc(100% + (var(--flex-gapX) * 2) + 0.1rem);
  min-height: 100%;
  min-width: 0;
  overflow: ${({$scrollable}) => $scrollable && 'hidden'};

  body.ar & {
    direction: rtl;
  }
`;

export const Flex = ({
  align = 'stretch',
  breakpoint,
  children,
  direction,
  justify,
  gap,
  gapX,
  gapY,
  scrollable,
}: FlexProps) => {
  const ref = useRef(null);
  const display = useResponsiveBreakpoints(
    [{block: breakpoint ? breakpoint : 0}, {flex: breakpoint ? breakpoint + 1 : 0}],
    ref
  );

  return (
    <StyledFlex
      $align={align}
      $direction={direction}
      $display={
        breakpoint && display.breakSize === 'block'
          ? 'block'
          : breakpoint && display.breakSize === 'flex'
          ? 'flex'
          : 'flex'
      }
      $justify={justify}
      $gap={gap}
      $gapX={gapX}
      $gapY={gapY}
      $scrollable={scrollable}
      ref={ref}
      style={{
        opacity: `${display.loading ? `0` : `1`}`,
      }}>
      {children}
    </StyledFlex>
  );
};
