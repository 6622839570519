import classNames from 'classnames';
import {BackgroundVideo, SlickWrapper} from 'epic-react-common';
import PropTypes from 'prop-types';
import React from 'react';
import root from 'window-or-global';

export default class PatchNotesCarousel extends React.Component {
  static propTypes = {
    content: PropTypes.object,
    mobile: PropTypes.bool,
    tablet: PropTypes.bool,
  };

  state = {
    move: '',
  };

  componentDidMount() {
    const parent = this.carouselContainer;
    parent.addEventListener('mousemove', this.mouseMove, false);
    parent.addEventListener('mouseout', this.resetMove, false);
  }
  componentWillUnmount() {
    const parent = this.carouselContainer;
    parent.removeEventListener('mousemove', this.mouseMove, false);
    parent.removeEventListener('mouseout', this.resetMove, false);
  }

  mouseMove = (e) => {
    const pageX = e.clientX || e.pageX;
    const ww = root.innerWidth;
    const maxDis = 100;
    if (pageX < maxDis && this.state.move !== 'left') {
      this.setState({move: 'left'});
    } else if (pageX > ww - maxDis && this.state.move !== 'right') {
      this.setState({move: 'right'});
    } else if (pageX > maxDis && pageX < ww - maxDis && this.state.move !== '') {
      this.setState({move: ''});
    }
  };

  resetMove = () => {
    this.setState({move: ''});
  };
  render() {
    const {content, mobile, tablet} = this.props;
    if (!content) {
      return <div />;
    }
    const sliderOptions = {
      infinite: true,
      speed: 700,
      autoplaySpeed: 2500,
      autoplay: true,
      draggable: true,
      touchMove: true,
      swipeToSlide: true,
      swipe: true,
      focusOnSelect: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      accessibility: true,
      arrows: true,
      dots: false,
    };

    const items = [];
    for (let i = 0; i < content.items.length; i++) {
      const {item} = content.items[i];
      items.push(
        <div key={`carouselItem-${i}`} className="pn-slide embed-responsive embed-responsive-16by9">
          <BackgroundVideo
            background={item}
            play={this.state.playVideo}
            fixed={false}
            noOverlay={true}
            backgroundVideo={true}
            mute={true}
            loop={true}
            enableRatioHelper={true}
            mobile={mobile}
            tablet={tablet}
          />
        </div>
      );
    }
    const carouselContainerClass = classNames('patch-notes-carousel section carousel-fluid', {
      left: this.state.move === 'left',
      right: this.state.move === 'right',
    });
    return (
      <div
        ref={(c) => {
          this.carouselContainer = c;
        }}
        className={carouselContainerClass}>
        <SlickWrapper
          ref={(c) => {
            this.patchNotesSlider = c;
          }}
          options={sliderOptions}>
          {items}
        </SlickWrapper>
      </div>
    );
  }
}
