import {connectToStores} from 'epic-alt-utils';
import {HttpErrorScreen, Slide, StyledTextElement} from 'epic-fortnite-react';
import {useCmsData, PageData, getMultipleCmsPageSharedValues} from 'epic-fortnite-shared-values';
import PropTypes from 'prop-types';
import React from 'react';
import root from 'window-or-global';

import MetaTags from '../../components/common/MetaTags';
import CreativeHeader from '../../components/creativeMode/CreativeHeader';
import LoadScreen from '../../components/loadScreen/LoadScreen';
import SimpleTabHeader from '../../components/simpleTabs/SimpleTabHeader';
import SimpleTabSection from '../../components/simpleTabs/SimpleTabSection';
import {HeaderStore, LoginStore} from '../../stores';

export interface CreativeViewPageData extends PageData {
  carousel: {
    slides: Slide[];
  };
  headerSection: {
    homeHeader: StyledTextElement[];
    creativeDocsLinkTitle: string;
    creativeDocsLinkURL: string;
  };
  tabSections: {
    tabs: {
      component: string;
      tabLabel: string;
      isMobileOnly: boolean;
      _type: string;
      redirectTo: string;
      key: string;
      contentRef: string;
    }[];
    _type: string;
  };
  theme: {
    backgroundColor: string;
    _type: string;
    primaryColor: string;
  };
  active: boolean;
}

const urlPattern = '/creative-mode';

const CreativeView = (props) => {
  const [state, setState] = React.useState({activeTab: 0, isMobile: false});

  const {loginStore, mobile, tablet, android} = props;

  const {
    pageData,
    isLoading: cmsLoading,
    error: cmsError,
  } = useCmsData<CreativeViewPageData>(urlPattern);

  // Filter out `isMobileOnly` tab options when field is selected in CMS.
  const filterTabOptionsForMobile = (tabs) => {
    let _tabs = tabs;
    if (state.isMobile) _tabs = _tabs.filter((tab) => tab.isMobileOnly !== true);
    return _tabs;
  };

  const isMobile = () => {
    if (!root.document) return;
    const width = root.innerWidth;
    if (width <= 1200 && !state.isMobile) {
      setState({...state, isMobile: true});
    } else if (width > 1200 && state.isMobile) {
      // TODO: Should we increated activeTab by 1 to prevent selectedTab from changing on size change?
      setState({...state, isMobile: false});
    }
  };

  React.useEffect(() => {
    isMobile();
    if (root.addEventListener) {
      root.addEventListener('resize', isMobile);
    }
    return () => {
      if (root.removeEventListener) {
        root.removeEventListener('resize', isMobile);
      }
    };
  }, []);

  if (cmsError) {
    return <HttpErrorScreen statusCode={cmsError?.response?.status ?? 500} />;
  }

  if (cmsLoading || Object.keys(pageData).length === 0) {
    return <LoadScreen show />;
  }

  const {carousel, tabSections, theme} = pageData;
  const {activeTab} = state;
  const {slides = []} = carousel || {};
  const {tabs = []} = tabSections || {};
  const filteredTabs = filterTabOptionsForMobile(tabs);

  const setActiveTab = (selectedIndex) => {
    const {component, redirectTo} = filteredTabs[selectedIndex];
    if (component && component === 'Redirect' && /^http/.test(redirectTo)) {
      root.location.href = redirectTo;
      return;
    }
    setState({...state, activeTab: selectedIndex});
  };

  return (
    <div className="creative-view">
      <MetaTags pageModel={pageData} />
      {slides && slides.length > 0 && (
        <CreativeHeader
          mobile={mobile}
          tablet={tablet}
          isAndroid={android}
          creativeModeStore={{creativeModeData: pageData}}
        />
      )}
      {tabs && tabs.length > 0 && (
        <SimpleTabHeader
          filteredTabs={filteredTabs}
          onSetActiveTab={setActiveTab}
          activeTab={activeTab}
          theme={theme}
          mobile={mobile}
        />
      )}
      <div className="homeViewContent">
        <div className="simple-tab-section-wrapper">
          <div className="simple-tab-section-content-container">
            <SimpleTabSection
              store={{
                loginStore,
                mobile,
                tablet,
                creativeModeStore: {creativeModeData: pageData},
                filteredTabs,
              }}
              mobile={mobile}
              tablet={tablet}
              activeTab={activeTab}
              theme={theme}
              isMobile={state.isMobile}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

CreativeView.propTypes = {
  loginStore: PropTypes.object,
  android: PropTypes.bool,
  tablet: PropTypes.bool,
  mobile: PropTypes.bool,
};

CreativeView.getStores = () => [HeaderStore, LoginStore];

CreativeView.getPropsFromStores = () => ({
  loginStore: LoginStore.getState(),
  android: HeaderStore.getState().android(),
  mobile: HeaderStore.getState().mobile(),
  tablet: HeaderStore.getState().tablet(),
});

CreativeView.getInitialSharedValues = getMultipleCmsPageSharedValues([
  urlPattern,
  `${urlPattern}/creative-faq`,
]);

export default connectToStores(CreativeView);
