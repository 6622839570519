import React, {useState} from 'react';
import VisibilitySensor from 'react-visibility-sensor';

import BattlePassSection from './BattlePassSection';
import FeatureCarousel from './FeatureCarousel';
import FeatureHeroTitleSpikes from './FeatureHeroTitleSpikes';
import FlexContainer from './FlexContainer';
import {CmsData} from './Types';

interface Props {
  data: CmsData;
  mobile: boolean;
  tablet: boolean;
}
const FeatureHeroCarousel = ({data, mobile, tablet}: Props) => {
  const [activeSlide, setActiveSlide] = React.useState(0);
  const [visible, setVisibility] = useState(false);
  const {items} = data;

  const spike = items && items[activeSlide]?.spike;
  const setCurrentSlide = React.useCallback(
    (activeSlide) => {
      setActiveSlide(activeSlide);
    },
    [setActiveSlide]
  );

  //hides section if cms content isn't there
  if (!items || items.length === 0) return null;

  const topValue = mobile ? 200 : 400;
  return (
    <BattlePassSection>
      <FlexContainer className="last-laugh-carousel">
        <FeatureHeroTitleSpikes mobile={mobile} tablet={tablet} spike={spike} />
        <VisibilitySensor
          onChange={(visibility) => setVisibility(visibility)}
          partialVisibility
          minTopValue={topValue}>
          <FeatureCarousel
            mobile={mobile}
            tablet={tablet}
            isVisible={visible}
            activeSlide={activeSlide}
            setCurrentSlide={setCurrentSlide}
            items={items}
          />
        </VisibilitySensor>
      </FlexContainer>
    </BattlePassSection>
  );
};

export default FeatureHeroCarousel;
