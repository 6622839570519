import PropTypes from 'prop-types';
import React from 'react';
import Helmet from 'react-helmet';
import VisibilitySensor from 'react-visibility-sensor';
import root from 'window-or-global';

import PatchNotesNavigation from './PatchNotesNavigation';

export default class PatchNotesHeader extends React.Component {
  static propTypes = {
    msie: PropTypes.bool,
    firefox: PropTypes.bool,
    content: PropTypes.object,
    headerSections: PropTypes.array,
    mobile: PropTypes.bool,
    tablet: PropTypes.bool,
  };

  state = {
    inView: true,
    yPos: -120,
  };

  componentDidMount() {
    root.addEventListener('scroll', this.scroll, false);
  }

  componentWillUnmount() {
    root.removeEventListener('scroll', this.scroll, false);
  }

  inView = (isVisible) => {
    if (isVisible && !this.state.inView) {
      this.setState({inView: true});
    } else if (!isVisible && this.state.inView) {
      this.setState({inView: false});
    }
  };
  scroll = (e) => {
    if (!e) {
      return;
    }
    const scrollTop = root.pageYOffset || root.document.documentElement.scrollTop;
    const y = -(scrollTop / 5).toFixed(2);
    const yPos = -120 + y; //needs to be the same as the state above
    const isMobile = this.props.mobile || this.props.tablet;
    if (this.state.inView && !isMobile) {
      this.setState({yPos});
    }
  };

  render() {
    const {msie, firefox, headerSections, content} = this.props;
    let backgroundImage;
    if (content.metaImage) {
      backgroundImage = content.metaImage;
    } else if (content.background) {
      backgroundImage = content.background.desktopImage;
    }
    const backgroundStyle = {
      background: content.background
        ? `url(${content.background.desktopImage}) no-repeat`
        : undefined,
      backgroundPosition: `center ${this.state.yPos}px`,
    };
    const tags = [
      {itemProp: 'name', content: content.title},
      {itemProp: 'description', content: content.description},
      {itemProp: 'image', content: backgroundImage},
      {name: 'title', content: content.title},
      {name: 'description', content: content.description},
      {name: 'twitter:title', content: content.title},
      {name: 'twitter:description', content: content.description},
      {name: 'twitter:image', content: backgroundImage},
      {property: 'og:title', content: content.title},
      {property: 'og:image', content: backgroundImage},
      {property: 'og:description', content: content.description},
    ];
    const helmetTag = <Helmet meta={tags} title={content.title} />;

    return (
      <div className="patch-notes-header container-fluid">
        {helmetTag}
        <PatchNotesNavigation
          msie={msie}
          firefox={firefox}
          content={content}
          headerSections={headerSections}
        />
        <VisibilitySensor onChange={this.inView} partialVisibility scrollCheck scrollDelay={10}>
          <div className="background-image" style={backgroundStyle} />
        </VisibilitySensor>
      </div>
    );
  }
}
