import Message from 'epic-fortnite-react/components/message/Message';
import React from 'react';

export default class LoadIndicator extends React.PureComponent {
  render() {
    return (
      <div className="load-indicator">
        <div className="container">
          <div className="row">
            <div className="logo" />
            <div className="beacon-container">
              <div className="beacon" />
              <div className="triangle" />
            </div>
            <h6 className="load-text">
              <Message code="epic.common.searching" />
            </h6>
          </div>
        </div>
      </div>
    );
  }
}
