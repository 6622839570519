import React from 'react';

interface Props {
  styles?: React.CSSProperties;
  id?: string;
}

const SpeechBorder = ({styles, id}: Props) => (
  <svg id={id} style={styles} viewBox="0 0 33.2 23.1" xmlns="http://www.w3.org/2000/svg">
    <path fill="#24c2f2" d="M.9.5L.5 22.2l31.8.3-29.6-2.2z" />
  </svg>
);

export default SpeechBorder;
