import styled from 'styled-components';

const FeatureTitleSpikesContainer = styled.div`
  width: 45%;
  max-width: 1440px;
  margin: 0 auto -15em auto;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  z-index: 3;
  position: relative;

  @media screen and (max-width: 1140px) {
    max-width: none;
    align-items: baseline;
    margin: 0 auto -6em auto;
  }
`;

export default FeatureTitleSpikesContainer;
