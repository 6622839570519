import {Container, Section, Stack, VisuallyHidden} from 'epic-fortnite-react';
import {Text, useBreakpoints} from 'epic-fortnite-react';
import {FontSizes} from 'epic-fortnite-react/types/theme';
import React from 'react';
import styled from 'styled-components';

import {WatchVideo} from '../common/WatchVideo';
import {CmsComponentMapper} from '../../components/common/CmsComponentMapper';

const FlexibleHeaderWrapper = styled(Section)`
  position: relative;
  color: #fff;
  height: 80vh;

  @media (min-width: 900px) {
    aspect-ratio: 2/1;
  }
`;

const SimpleImage = styled.img<{size?: string; position?: string}>`
  width: ${({size}) => size ?? '100%'};
`;

type OverviewBadgeProps = {
  image?: string;
  size?: string;
  position?: string;
};

const StyledOverviewBadge = styled(SimpleImage)<OverviewBadgeProps>`
  left: 16px;
  top: -16px;
  width: 48px;
  height: 48px;
  position: absolute;

  @media (min-width: 900px) {
    left: 32px;
    top: 0;
  }
`;

const OverviewBadge = ({image}: OverviewBadgeProps) => {
  return <StyledOverviewBadge src={image} />;
};

type OverviewLogoProps = {
  image?: string;
  logoAltText?: string;
  size?: string;
  position?: string;
  mobileImage?: string;
};

const OverviewLogo = ({image, logoAltText, size, position, mobileImage}: OverviewLogoProps) => {
  const imageSrc: string = useBreakpoints(mobileImage ? mobileImage : image, {900: image}).value;

  const StyledOverviewImage = styled(SimpleImage)`
    display: block;
    height: auto;
    margin: auto;
    max-width: 100%;
    width: auto;
    padding: 25px;
    filter: drop-shadow(
      0px 0px 50px #263b36
    ); // This will effect Zero-Build and Overview. TODO: Consider image drop-shadow on page rebuild.

    @media (min-width: 900px) {
      max-height: 24vw;
    }
  `;

  return (
    <h1 style={{margin: '0px'}}>
      {logoAltText && <VisuallyHidden>{logoAltText}</VisuallyHidden>}
      <StyledOverviewImage
        alt={logoAltText ? logoAltText : ''}
        src={imageSrc}
        size={size}
        position={position}
      />
    </h1>
  );
};

type OverviewTextProps = {
  header?: string;
  textContent?: string;
};

const OverviewText = ({header, textContent}: OverviewTextProps) => {
  const textSize: FontSizes = useBreakpoints('2xl', {500: '4xl', 900: '6xl'}).value;

  return (
    <>
      {(header || textContent) && (
        <Stack align="center" gap="2xl">
          {header && (
            <Text
              as="h1"
              color="var(--bundle-text-color--theme)"
              lineLength="sm"
              size={textSize}
              transform="uppercase">
              {header}
            </Text>
          )}
          {textContent && (
            <Text
              as="p"
              color="var(--bundle-text-color--theme)"
              lineLength="sm"
              size="med"
              transform="uppercase">
              {textContent}
            </Text>
          )}
        </Stack>
      )}
    </>
  );
};

const FlexibleHero = ({data = {}}) => {
  const {blocks, coreSectionProps} = data;

  return (
    <FlexibleHeaderWrapper
      backgroundColor={coreSectionProps?.backgroundColor}
      backgroundImage={coreSectionProps?.backgroundImage}
      mobileBackgroundImage={coreSectionProps?.mobileBackgroundImage}
      backgroundPosition={coreSectionProps?.backgroundPosition}
      backgroundSize={coreSectionProps?.backgroundSize}
      size={coreSectionProps?.size}>
      <div style={{paddingBottom: '4vw', height: '100%', display: 'flex', alignItems: 'flex-end'}}>
        <Container maxWidth={600}>
          <CmsComponentMapper
            dynamicPageDataArray={blocks}
            elementMapping={{
              watchVideo: <WatchVideo />,
              logo: <OverviewLogo />,
              badge: <OverviewBadge />,
              textContent: <OverviewText />,
            }}
          />
        </Container>
      </div>
    </FlexibleHeaderWrapper>
  );
};

export default FlexibleHero;
