import {Link, withRouter} from 'react-router-dom';
import Message from '../../components/message/Message';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import {generateRouteTo} from 'epic-fortnite-react';

/**
 * Component that grabs next and prev blog post Url
 * @implements {Message}
 * @reactProps {string} blogNextSlug
 * @reactProps {string} blogPrevSlug
 * @reactProps {string} rootPageSlug
 */
class LinkNavigator extends React.Component {
    render() {
        const {
            routerRootUrl,
            blogNextSlug,
            blogPrevSlug,
            prevExtLink,
            nextExtLink,
            location,
            locale
        } = this.props;

        const prevLink = prevExtLink || `/${routerRootUrl}/${blogPrevSlug}`;
        const nextLink = nextExtLink || `/${routerRootUrl}/${blogNextSlug}`;

        return (
            <div className="prev-link-wrap">
                {blogPrevSlug && (
                    <Link
                        className={classNames('prev-link accent-color')}
                        to={generateRouteTo(prevLink, location, locale)}>
                        <i className="epic-blog-icon-leftArrow" />
                        <Message className="prev-link-text" code="epic.blog.previous" />
                    </Link>
                )}
                {routerRootUrl && (
                    <Link
                        className="slug-link accent-color"
                        to={generateRouteTo(`/${routerRootUrl}`, location, locale)}>
                        <i className="epic-blog-icon-grid" />
                    </Link>
                )}
                {blogNextSlug && (
                    <Link
                        className={classNames('next-link accent-color')}
                        to={generateRouteTo(nextLink, location, locale)}>
                        <i className="epic-blog-icon-rightArrow" />
                        <Message className="next-link-text" code="epic.blog.next" />
                    </Link>
                )}
            </div>
        );
    }
}

LinkNavigator.propTypes = {
    blogNextSlug: PropTypes.string,
    blogPrevSlug: PropTypes.string,
    rootPageSlug: PropTypes.string,
    nextExtLink: PropTypes.string,
    prevExtLink: PropTypes.string,
    locale: PropTypes.string
};

LinkNavigator.defaultProps = {
    routerRootUrl: 'news'
};

export default withRouter(LinkNavigator);
