import React from 'react';
import styled from 'styled-components';
import {ClickableElement, ClickableElementProps} from '../ClickableElement/ClickableElement';
import {Inline} from '../Inline/Inline';
import {Text} from '../Text/Text';

const StyledTextLink = styled(ClickableElement)`
  color: #0b85ec !important;
  text-decoration: underline !important;

  svg {
    width: 16px;
    height: 16px;
    margin-left: 8px;
    vertical-align: text-top;
  }
`;

type TextLinkProps = ClickableElementProps;

export const TextLink = ({children, ...props}: TextLinkProps) => {
  return (
    <StyledTextLink {...props}>
      <Inline align="flex-start" flex="start" gap="2xs" justify="center" wrap={false}>
        <Text
          align="center"
          icon={
            <svg
              aria-hidden={true}
              width="16"
              height="16"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg">
              <path d="M16 8c0-4.418-3.612-8-8.068-8C4.222 0 1.096 2.484.155 5.867l8.853.8-2.555-2.4 2.152-2.134 5.378 5.6-5.378 6.134-2.152-2.134L9.008 9.2 0 9.467C.695 13.184 3.982 16 7.932 16 12.388 16 16 12.418 16 8Z" />
            </svg>
          }
          size="med"
          transform="uppercase">
          {children}
        </Text>
      </Inline>
    </StyledTextLink>
  );
};
