import {Location} from 'history';
import qs from 'query-string';

type To = {pathname: string; search?: string};

/**
 * generateRouteHref returns a string including URL query parameters to be used in a link
 * @param toOrPath - Path or To object that the link should go to (relative to the root of application)
 * @param location - Location object representing the current location of the app
 * @param locale - Current locale of the application
 * @returns - String of where the link should navigate to - e.g. /crew?lang=fr
 */
export function generateRouteHref(toOrPath: string | To, location: Location, locale: string) {
  if (!locale) {
    throw new Error(`generateRouteHref: missing locale argument: ${locale}`);
  }

  const to = normalizeTo(toOrPath);
  const queries = constructQueryParams(to.search ?? '', location, locale, [
    'lang',
    'utm_campaign',
    'utm_content',
    'utm_medium',
    'utm_source',
    'utm_term',
  ]);
  if (Object.keys(queries).length > 0) {
    return `${to.pathname}?${qs.stringify(queries)}`;
  }
  return typeof to === 'string' ? to : to.pathname;
}

/**
 * generateRouteTo returns a Location object to be used in a Link
 * @param toOrPath - Path or To object that the link should go to (relative to the root of application)
 * @param location - Location object representing the current location of the app
 * @param locale - Current locale of the application
 * @returns - Location object of where the application should go to - e.g. `{pathname: '/crew', search: '?lang=fr'}'
 */
export function generateRouteTo(toOrPath: To, location: Location, locale: string) {
  if (!locale) {
    throw new Error(`generateRouteTo: missing locale argument: ${locale}`);
  }
  const to = normalizeTo(toOrPath);
  const queries = constructQueryParams(to.search ?? '', location, locale, [
    'lang',
    'utm_campaign',
    'utm_content',
    'utm_medium',
    'utm_source',
    'utm_term',
  ]);
  if (Object.keys(queries).length > 0) {
    return {
      pathname: to.pathname,
      search: `?${qs.stringify(queries)}`,
    };
  }
  return typeof to === 'string' ? {pathname: to} : to;
}

function normalizeTo(toOrPath: string | To): To {
  if (typeof toOrPath === 'string') {
    const path: string = toOrPath;
    const queryIndex = path.indexOf('?');
    const to = {
      pathname: queryIndex > -1 ? path.slice(0, queryIndex) : path,
      search: queryIndex > -1 ? path.slice(queryIndex) : undefined,
    };
    return to;
  }
  return toOrPath;
}

/**
 * constructQueryParams returns the query parameters that should be included as part of a link.
 * There should always be a `lang` param, if the pathQuery doesn't carry a `lang`
 * parameter we will set it to the `currentLocale`
 *
 * @param pathQuery - URL query string from the link URL/path
 * @param currentLocation - Location object representing the current loation of the app
 * @param currentLocale - Current locale of the application
 * @param paramsToPropagate - Array of parameter names that should be propagates from current location
 * @returns - Object with the parameters & values
 */
function constructQueryParams(
  pathQuery: string,
  currentLocation: Location,
  currentLocale: string,
  paramsToPropagate: string[]
) {
  if (!currentLocale) {
    throw new Error(`constructQueryParams: missing currentLocale argument: ${currentLocale}`);
  }

  const currentSearch = qs.parse(currentLocation.search);
  const pathSearch = pathQuery.length > 0 ? qs.parse(pathQuery) : {};

  const queries = pathSearch;
  // If there is already a lang specified on the link that takes precedence
  // over the active locale
  if (!queries['lang']) {
    queries['lang'] = currentLocale;
  }

  for (const key of paramsToPropagate) {
    if (currentSearch[key] && !queries[key]) {
      queries[key] = currentSearch[key];
    }
  }
  return queries;
}
