import styled from 'styled-components';

import {breakpoints} from '../../../../constants/Breakpoints';

const CarouselWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 5fr 5fr 1fr;
  align-items: center;
  height: 50rem;

  @media ${breakpoints.tablet} {
    grid-template-columns: 1fr 1fr;
  }

  @media ${breakpoints.mobileL} {
    grid-template-columns: 1fr;
    grid-gap: 0;
    height: auto;
  }
`;

export default CarouselWrapper;
