import styled, {css, keyframes} from 'styled-components';

import {Device} from '../../../constants';

const pulse = keyframes`
    from {
        box-shadow: 0px 0px 0px 0px rgba(255,255,255,0.25);
    }
    to {
        box-shadow: 0px 0px 3px 30px rgba(255,155,255,0.0);
    }
`;

const IconButton = styled.button`
  width: 40px;
  height: 40px;
  outline: none;
  background-color: transparent;
  border: none;
  padding: 0;
  margin: 0;
  position: absolute;
  z-index: 2;
  transform: translate3d(0, 0, 5px);

  svg {
    display: block;
    position: relative;
    z-index: 1;
    transform: rotate(0);
    transition: transform 300ms ease-in-out;
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 90%;
    height: 90%;
    transform: translate(-50%, -50%);
    border-radius: 100%;
    z-index: -1;
    transform-origin: center;

    ${css`
      animation: ${pulse} 1.5s ease-in-out infinite;
    `}
  }

  &:hover {
    svg {
      transform: rotate(180deg);
    }
  }
  @media screen and (max-width: 940px) {
    ${(props) =>
      props.keyId === 'storm'
        ? css`
            margin-top: 30em;
          `
        : css`
            margin-top: 10em;
          `};
  }
  @media screen and ${Device.mobileL} {
    margin-top: 0;
  }
`;

export default IconButton;
