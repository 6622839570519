import PropTypes from 'prop-types';
import React from 'react';

import Message from '../message/Message';

/**
 * PopDown Animation
 */
class PageJumbotron extends React.PureComponent {
  static propTypes = {
    sectionName: PropTypes.string,
  };

  render() {
    const sectionName = this.props.sectionName || '';
    return (
      <div className="page-jumbotron section-header">
        <div className="section-header-imagebg" />
        <div className="name-container container-fluid">
          <h1>
            <Message code={sectionName} />
          </h1>
        </div>
      </div>
    );
  }
}

export default PageJumbotron;
