import React from 'react';
import styled from 'styled-components';

import {BaseButton, BaseButtonProps} from '../';

export interface StyledUtilityButtonProps {
  $disabled?: boolean;
  $theme?: 'blue' | 'white';
}

export interface UtilityButtonProps extends BaseButtonProps {
  disabled?: boolean;
  theme?: 'blue' | 'white';
}

const StyledUtilityButton = styled(BaseButton)<StyledUtilityButtonProps>`
  --fn-button__background-color: ${({$theme}) =>
    $theme === 'blue' ? '#0B85EC' : $theme === 'white' ? '#fff' : 'tranparent'};
  // --fn-button__clip-path:  polygon(0% 16%, 100% 6%, 98% 100%, 2% 90%);
  --fn-button__clip-path: none;

  clip-path: var(--fn-button__clip-path);
  padding: 16px;
  background-color: var(--fn-button__background-color);

  &:hover {
    opacity: ${({$disabled}) => ($disabled ? 1 : 0.8)};
  }

  &.focus-visible {
    background-color: #000;
  }

  &::before {
    content: '';
    position: absolute;
    top: 4px;
    left: 4px;
    right: 4px;
    bottom: 4px;
    background: var(--fn-button__background-color);
    clip-path: var(--fn-button__clip-path);
  }
`;

export const UtilityButton = ({theme = 'blue', ...props}: UtilityButtonProps) => {
  return (
    <StyledUtilityButton
      $theme={theme}
      color={theme === 'blue' ? '#fff' : '#000'}
      size="med"
      {...props}
    />
  );
};
