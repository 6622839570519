import React from 'react';
import styled from 'styled-components';

import CountrySelect from './CountrySelect';

const StyledCountrySelect = styled(CountrySelect)`
  width: 80%;
  max-width: 450px;
  margin: 3em 0;
  z-index: 1001;
  margin: 0 auto;
  div {
    z-index: 1001;
    border-radius: unset;
  }
`;

const StyledCountrySelectExtended = (props) => <StyledCountrySelect {...props} />;

export default StyledCountrySelectExtended;
