import {getLocale} from '@epic-core/common';
import {stripTrailingSlashes} from '@epic-core/common';
import {extractSlugLocale} from '@epic-mw/localization';
import {RouteComponentProps} from 'react-router-dom';

import {InitSharedValueResponse} from '../shared-values/initializers';

import {cmsApi} from './cms.api';
import {CmsSharedKeys} from './cms.keys';
import {PageData} from './cms.types';

type URLPatternBuilder = <URLParams extends Record<string, string>>(
  match: RouteComponentProps<URLParams>['match']
) => string;

/**
 * getInitialCmsPageSharedValues fetches the data from cmsUrlPattern
 * during SSR and stores the response in the `CmsStore` in shared values' state,
 * with the cmsUrlPattern as key.
 *
 * if cmsUrlPattern is a function it will receive react-router's `match` as an argument
 * and should return the urlPattern from the function
 *
 * If cmsUrlPattern is undefined/not passed it'll extract the slug from the url, using the
 * path segments after the locale (e.g. /fortnite/en-US/bundles/anime-legends-pack ->
 * bundles/anime-legends-pack)
 */
export const getInitialCmsPageSharedValues = <P extends PageData>(
  cmsUrlPattern?: string | URLPatternBuilder
) => {
  return async (match: RouteComponentProps['match']): Promise<InitSharedValueResponse[]> => {
    const urlPattern = getUrlPattern(match, cmsUrlPattern);

    try {
      const response = await cmsApi.getPageData<P>(urlPattern);
      return [
        {
          key: CmsSharedKeys.CmsStore,
          value: {
            [urlPattern]: response || {},
          },
        },
      ];
    } catch (err) {
      console.error(
        `getInitialCmsPageSharedValues -> failed to get page data for ${cmsUrlPattern}`,
        err
      );
      throw err;
    }
  };
};

/**
 * getUrlPattern procudes the urlPattern from where to fetch cms content from.
 * if no `cmsUrlPattern` is provided it will use the path after the locale as the urlPattern
 * (e.g. /fortnite/en-US/bundles/anime-legends-pack would return bundles/anime-legends-pack
 * as the urlPattern)
 *
 * if `cmsUrlPattern` is a function it'll receive `match` as an argument and should
 * return the urlPattern to fetch as a string
 *
 * The returned urlPattern will be without trailing slash
 */
function getUrlPattern(
  match: RouteComponentProps['match'],
  cmsUrlPattern?: string | URLPatternBuilder
): string {
  if (!cmsUrlPattern) {
    return extractUrlPatternFromPath(match);
  }

  if (typeof cmsUrlPattern === 'function') {
    return cleanUrlPattern(cmsUrlPattern(match));
  }

  return cleanUrlPattern(cmsUrlPattern);
}

/**
 * extractUrlPatternFromPath returns the path after the locale, e.g.
 * /fortnite/en-US/bundles/anime-legends-pack -> bundles/anime-legends-pack
 */
export function extractUrlPatternFromPath(match: RouteComponentProps['match']) {
  const url = match.url;
  const {pageSlug} = extractSlugLocale(url, getLocale());
  return cleanUrlPattern(pageSlug);
}

function cleanUrlPattern(urlPattern: string): string {
  return urlPattern.length > 1 ? stripTrailingSlashes(urlPattern) : urlPattern;
}

/**
 * getMultipleCmsPageSharedValues returns an array of strings, for the purpose of
 * using multiple CMS slugs that can contain parent and child pages for SSR.
 * eg. "/creative-mode" : response1, "/creative-mode/creative-faq" : response2,
 * "/random-slug" : response3, etc
 */
export const getMultipleCmsPageSharedValues = (urlPatterns: string[]) => {
  return async (): Promise<InitSharedValueResponse[]> => {
    const values = {};

    await Promise.all(
      urlPatterns.map(async (urlPattern) => {
        try {
          const response = await cmsApi.getPageData(urlPattern);
          values[urlPattern] = response || {};
        } catch (err) {
          console.error('getInitialCmsPageSharedValues -> failed to get page data', err);
          throw err;
        }
      })
    );

    return [
      {
        key: CmsSharedKeys.CmsStore,
        value: {
          ...values,
        },
      },
    ];
  };
};
