import React from 'react';

interface Props {
  styles?: React.CSSProperties;
  id?: string;
  killGradient?: boolean;
}

const SlashIcon = ({styles, id, killGradient}: Props) => (
  <svg id={id} style={styles} viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
    <linearGradient
      id="a"
      x1="13.9934"
      x2="32.5635"
      y1="30.362"
      y2="1.9096"
      gradientUnits="userSpaceOnUse">
      <stop offset="0" stopColor="#2076bd" />
      <stop offset="1" stopColor="#1e3565" />
    </linearGradient>
    <path
      fill={killGradient ? '#fff' : 'url(#a)'}
      d="M27.3 18.7c-.6.2-1.1.5-1.7.5-.4 0-.7.1-1 .3-2 1.5-4.1 3-6.1 4.4-.3.2-.7.2-1.1.3-.3.1-.7.1-1 .3-1.1.8-2.2 1.6-3.4 2.4-1
             .7-1.9 1.4-3.2 1.4-.1 0-.3.1-.4.1-1 .7-2.1 1.3-3 2-1.1.9-2.1 1.8-3.4 2.3-.6.2-1.2.7-1.7 1.1-.4.2-.7.5-1.1.7.3-.1.7-.2.9-.4
              1.3-1.1 3.2-1.4 4.4-2.7l.1-.1c1.2-.3 2.3-.7 3.5-.9.3-.1.5-.2.6-.4.2-.5.5-.9 1.1-1 1.3-.3 2.7-.6 4-1 .9-.3 2-.3 2.8-1 .5-.5 1.2-.8 1.8-1.2.7-.4
              1.5-.8 2.2-1.3 1-.6 1.9-1.4 2.9-2 .4-.3 1-.5 1.5-.6.6-.1 1.2 0 1.9 0 .6 0 1.1 0 1.5-.5.2-.2.5-.4.8-.5.3-.1.5-.1.8-.1v.1c.5-.3.9-.7 1.4-.9.7-.4 1.4-.8 2.1-.9.7-.1
              1.2-.2 1.5-.7.1-.2.3-.3.5-.5.5-.5.6-.5 1 .2.1.1.1.2.2.3.6-.6 1.1-1.3 1-2.1-.4 0-.9 0-1.2-.1-.3-.1-.5-.5-.8-.7l-.8.8c-.3.3-.7.4-1.1.1-.6-.5-1-.5-1.7 0-1.5 1-3 1.9-4.6
              2.9-.7.4-.9.3-1.1-.5-.1 0-.1-.1-.1-.1zM4.9 28.2l-.2-.2c.3-.2.8-.3 1-.6.6-1.1 1.6-1.3 2.6-1.7.3-.1.6-.3.7-.6.3-.5.6-1 1.3-1.3 1.2-.4 2.4-.9
              3.6-1.3 1-.4 2-.7 2.9-1.4 1-.8 2-1.5 3.1-2.2.7-.5 1.4-.9 2-1.3 1.5-1 2.9-2.1 4.4-3.1.2-.1.5-.2.8-.2 1.3 0 2.6.2 3.6-.9.1-.1.3-.2.5-.3h.4c.1 
              0 .3 0 .4-.1.5-.3 1-.7 1.6-1 .3-.2.8-.3 1.2-.3.4 0 .7 0 .9-.4.5-.7.5-.7 1.1 0h.1c.3-.6.6-.3.9 0 .4-.7.6 0 .9.1.2-.1.5-.2.7-.3.1-.1.2-.3.4-.4-.2-.1-.5-.2-.7-.2-.1
              0-.3.1-.4.2-.3.2-.5.2-.8 0-.2-.2-.5-.5-.7-.4-.8.2-1.2-.3-1.8-.6-.6.5-.6.5-1.2.1-.3-.2-.7-.3-1-.1-1.1.6-2.2 1.2-3.2 1.7-.8.5-1 .4-1.1-.5 0-.1 0-.1-.1-.2-.5.4-1
              .7-1.7.5-.1 0-.3.1-.4.2-2 1.6-4 3.2-6.1 4.7-.8.6-1.6 1.4-2.8 1.4-.1 0-.3.1-.4.2-.8.7-1.7 1.3-2.5 2-1.3 1-2.6 2.1-4 3.1-.4.3-1 .2-1.3.5-.8.6-1.6 1.3-2.3 2-.8.8-1.4 1.7-2.1
              2.5-.1-.1-.2.2-.3.4zM21.3 9.9c-.4.2-.7.4-1.1.4-.3 0-.5.1-.8.4-.8.8-1.6 1.5-2.4 2.2-.3.3-.7.6-1.1.6-.7.1-1.2.4-1.6.8-.3.3-.7.6-1 .9-.7.5-1.3 1.1-2 1.3-1.1.3-2 .9-2.9
              1.7-.7.7-1.5 1.3-2.3 1.9l-2.7 1.8c0 .1.1.1.1.2l1.2-.9c.9-.7 2.2-1 3-2 .1-.1.1-.1.2-.1 1.1-.4 2.1-.8 3.2-1.2.1-.6.2-.8.9-1 1.2-.4 2.4-.7 3.6-1.1.4-.1.8-.2 1.1-.4 1.3-.9
              2.5-1.9 3.7-2.9.5-.4 1.1-.7 1.8-.7.6 0 1.3 0 1.8-.3.8-.5 1.8-.7 2.5-1.4.2-.2.7-.2 1-.3.2-.1.5-.2.6-.4.3-.6.5-.6.9-.4.1.1.3.1.5.2.1-.9.7-.3 1.1-.4 0-.4.3-.6.6-.5.6.2 1.1-.2
              1.5-.4-.5-.1-1-.1-1.4-.1-.2 0-.5.1-.7 0-.5-.1-1.1-.3-1.6.2-.2.2-.6.2-.9.1-.3 0-.6-.2-1-.3-.1.6-.3.7-.9.5-.6-.2-1.1-.2-1.6.3-.4.4-.9.7-1.3 1.1-1 .8-1.1.8-2 .2z"
    />
    <linearGradient
      id="b"
      x1="15.7976"
      x2="34.3677"
      y1="31.5396"
      y2="3.0871"
      gradientUnits="userSpaceOnUse">
      <stop offset="0" stopColor="#2076bd" />
      <stop offset="1" stopColor="#1e3565" />
    </linearGradient>
    <path
      fill={killGradient ? '#fff' : 'url(#b)'}
      d="M27.3 18.7v.3c.2.8.4.9 1.1.5 1.5-.9 3.1-1.9 4.6-2.9.7-.5 1.1-.5 1.7 0 .4.4.8.2 1.1-.1l.8-.8c.3.2.5.6.8.7.4.1.8.1
             1.2.1.1.8-.3 1.5-1 2.1-.1-.1-.1-.2-.2-.3-.4-.7-.5-.7-1-.2l-.5.5c-.4.6-.9.6-1.5.7-.7.1-1.4.5-2.1.9-.5.3-.9.6-1.4.9V21c-.3 
             0-.6 0-.8.1-.3.1-.6.3-.8.5-.4.4-1 .5-1.5.5-.6 0-1.3-.1-1.9 0-.5.1-1 .3-1.5.6-1 .6-1.9 1.4-2.9 2-.7.5-1.5.8-2.2 1.3-.6.4-1.2.7-1.8 
             1.2-.8.7-1.9.7-2.8 1-1.3.4-2.7.7-4 1-.6.1-.9.5-1.1 1-.1.3-.3.4-.6.4-1.2.3-2.3.6-3.5.9 0 0-.1 0-.1.1-1.3 1.2-3.1 1.5-4.4 2.7-.2.2-.6.3-.9.4.4-.3.7-.5 
             1.1-.7.6-.4 1.1-.9 1.7-1.1 1.3-.5 2.3-1.4 3.4-2.3.9-.7 2-1.4 3-2 .1-.1.3-.1.4-.1 1.3 0 2.2-.7 3.2-1.4 1.1-.8 2.2-1.6 3.4-2.4.3-.2.7-.2 1-.3.4-.1.8-.1 
             1.1-.3 2.1-1.5 4.1-2.9 6.1-4.4.3-.2.6-.3 1-.3.7-.3 1.2-.5 1.8-.7z"
    />
    <linearGradient
      id="c"
      x1="14.7353"
      x2="33.3054"
      y1="30.8462"
      y2="2.3938"
      gradientUnits="userSpaceOnUse">
      <stop offset="0" stopColor="#2076bd" />
      <stop offset="1" stopColor="#1e3565" />
    </linearGradient>
    <path
      fill={killGradient ? '#fff' : 'url(#c)'}
      d="M4.9 28.2c.1-.2.2-.5.3-.6.7-.8 1.4-1.7 2.1-2.5.7-.7 
            1.5-1.4 2.3-2 .4-.3 1-.3 1.3-.5 1.4-1 2.7-2.1 4-3.1.8-.7 
            1.7-1.3 2.5-2 .1-.1.3-.2.4-.2 1.2 0 2-.8 2.8-1.4 2.1-1.5 
            4.1-3.1 6.1-4.7.1-.1.3-.2.4-.2.7.2 1.2-.1 1.7-.5 0 .1.1.1.1.2.1.9.3 
            1 1.1.5 1.1-.6 2.2-1.2 3.2-1.7.4-.2.7-.1 1 .1.7.4.7.4 1.2-.1.5.3 1 
            .8 1.8.6.2 0 .5.2.7.4.3.3.5.2.8 0 .1-.1.3-.2.4-.2.2 
            0 .5.1.7.2-.1.1-.2.3-.4.4-.2.1-.4.2-.7.3-.3-.1-.5-.8-.9-.1-.3-.2-.7-.6-.9 0h-.1c-.6-.7-.6-.7-1.1 
            0-.2.3-.5.4-.9.4s-.8.1-1.2.3c-.5.3-1 .7-1.6 1 0 .2-.2.2-.3.2h-.4c-.2.1-.4.2-.5.3-1 1-2.3.9-3.6.9-.3 0-.6.1-.8.2-1.5 
            1-3 2.1-4.4 3.1-.7.5-1.4.9-2 1.3-1 .7-2.1 1.4-3.1 2.2-.9.8-1.9 
            1.1-2.9 1.5-1.2.5-2.4.9-3.6 1.3-.6.2-1 .7-1.3 1.3-.2.3-.4.4-.7.6-1 .4-2.1.6-2.6 1.7-.2.3-.6.4-1 .6 0 .1.1.1.1.2z"
    />
    <linearGradient
      id="d"
      x1="9.7761"
      x2="28.3462"
      y1="27.6095"
      y2="-.8429"
      gradientUnits="userSpaceOnUse">
      <stop offset="0" stopColor="#2076bd" />
      <stop offset="1" stopColor="#1e3565" />
    </linearGradient>
    <path
      fill={killGradient ? '#fff' : 'url(#d)'}
      d="M21.3 9.9c.9.6 1 .6 1.9-.1.4-.4.9-.7 1.3-1.1.5-.5 1-.5 1.6-.3.7.2.8.1.9-.5.3.1.6.2 1 .3.3 0 .7 0 .9-.1.6-.5 1.1-.3
             1.7-.2.2.1.4 0 .7 0 .5 0 1 0 1.4.1-.5.3-.9.6-1.5.4-.3-.1-.6.1-.6.5-.4.1-1-.5-1.1.4-.2-.2-.3-.3-.5-.3-.4-.2-.7-.1-.8.4 0
              .2-.4.3-.6.4-.3.1-.8.1-1 .3-.7.7-1.7.8-2.5 1.4-.5.3-1.2.3-1.8.3-.7 0-1.3.3-1.8.7-1.3.9-2.5 1.9-3.7 2.9-.3.2-.7.3-1.1.4-1.2.4-2.4.7-3.6 
              1.1-.7.2-.8.3-.9 1-1.1.4-2.2.8-3.2 1.2-.1 0-.2.1-.2.1-.8.9-2 1.2-3 2-.4.3-.8.5-1.3.8 0-.1-.1-.1-.1-.2.9-.6 1.8-1.1 2.7-1.8.8-.6 1.6-1.2
               2.3-1.9.8-.8 1.7-1.4 2.9-1.7.7-.2 1.4-.8 2-1.3.4-.3.7-.6 1-.9.5-.4 1-.7 1.6-.8.4 0 .8-.3 1.1-.6.8-.7 1.6-1.5 2.4-2.2.2-.2.4-.3.8-.4.4.1.7-.1 1.1-.3z"
    />
  </svg>
);

export default SlashIcon;
