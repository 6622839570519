import styled from 'styled-components';

const StyledFAQHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  i {
    padding-left: 10px;
  }
`;

export default StyledFAQHeaderContainer;
