import {Device} from 'epic-fortnite-styles/constants';
import styled from 'styled-components';

const StyledCreativeCoreTextWrapper = styled.div<{textAlign?: string; overrides?: string}>`
  text-align: ${(props) => (props.textAlign ? props.textAlign : 'center')};
  padding: 2em;
  ${(props) => (props.overrides ? JSON.parse(props.overrides) : '')}

  @media screen and ${Device.laptop} {
    width: 80%;
  }

  @media screen and ${Device.tablet} {
    width: 100%;
  }

  @media screen and ${Device.mobileL} {
    padding: 1em;
    text-align: center;
    width: 100%;
  }
`;

export default StyledCreativeCoreTextWrapper;
