import {ScreenSizeProvider, HttpErrorScreen, LoadScreen} from 'epic-fortnite-react';
import {useCmsData, PageData} from 'epic-fortnite-shared-values';
import PropTypes from 'prop-types';
import React from 'react';

import {CreativeHomeHeader, CreativeFaqSection} from './';

const slug = '/creative-mode/creative-faq';

interface CreativeHomeSectionPageData extends PageData {
  faqList: {
    items?: {
      answer: string;
      question: string;
      type: string;
    }[];
  };
  header: string;
}

const CreativeHomeSection = (props) => {
  const {creativeModeStore} = props;

  const {
    pageData,
    isLoading: cmsLoading,
    error: cmsError,
  } = useCmsData<CreativeHomeSectionPageData>(slug);

  if (cmsError) {
    return <HttpErrorScreen statusCode={500} />;
  }

  if (cmsLoading || Object.keys(pageData).length === 0) {
    return <LoadScreen show={true} />;
  }

  const {
    faqList: {items},
    header,
  } = pageData || {faqList: {items: null}, header: ''};

  return (
    <div className="creative-home-view">
      <ScreenSizeProvider>
        <CreativeHomeHeader creativeModeStore={creativeModeStore} />
        {items && <CreativeFaqSection items={items} header={header} {...props} />}
      </ScreenSizeProvider>
    </div>
  );
};

CreativeHomeSection.propTypes = {
  creativeModeStore: PropTypes.object,
};

export default CreativeHomeSection;
