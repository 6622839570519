import styled from 'styled-components';

interface Props {
  color?: string;
}
const Hashtag = styled.div<Props>`
  ${({color}) => (color ? `color: ${color};` : null)}
  font-size: 3.6rem;
`;

export default Hashtag;
