import queryString from 'query-string';
import React from 'react';
import {RouteComponentProps} from 'react-router-dom';
import Select from 'react-select';

import dropdownStyles from '../../../vbuckscardterms/dropdownStyles';

import {SelectContainer} from './index';

type OptionValues =
  | 'date-descending'
  | 'date-ascending'
  | 'difficulty-ascending'
  | 'difficulty-descending';

interface Option {
  label: string;
  value: OptionValues;
}

interface Props extends RouteComponentProps {
  orderingLabels: {[key: string]: string};
}

const ArchiveOrderSelect = ({orderingLabels, history}: Props) => {
  const options: Option[] = React.useMemo(
    () => [
      {label: orderingLabels.dateDescending, value: 'date-descending'},
      {label: orderingLabels.dateAscending, value: 'date-ascending'},
      {label: orderingLabels.difficultyAscending, value: 'difficulty-ascending'},
      {label: orderingLabels.difficultyDescending, value: 'difficulty-descending'},
    ],
    [orderingLabels]
  );

  const ordering = React.useMemo(
    () =>
      options.find(
        (option) =>
          option.value ===
          (queryString.parse(history.location.search)?.ordering ?? 'date-descending')
      ),
    [history.location.search, options]
  );

  const handleClick = React.useCallback(
    (option) => {
      history.push({
        search: queryString.stringify({
          ...queryString.parse(history.location.search),
          ordering: option?.value,
        }),
      });
    },
    [history.push, history.location.search]
  );

  return (
    <SelectContainer>
      <div className="select-wrapper">
        <Select
          options={options}
          styles={dropdownStyles}
          defaultValue={ordering}
          onChange={handleClick}
        />
      </div>
    </SelectContainer>
  );
};

export default ArchiveOrderSelect;
