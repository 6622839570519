import styled from 'styled-components';

import {breakpoints} from '../../../../constants/Breakpoints';

interface Props {
  alternateOrder?: boolean;
}

const SideToFullGrid = styled.div<Props>`
  width: 100%;
  display: flex;
  justify-content: center;

  @media ${breakpoints.tablet} {
    display: grid;
    grid-template-columns: ${({alternateOrder}) =>
      alternateOrder === true ? '7fr 5fr' : '5fr 7fr'};
  }

  @media ${breakpoints.mobileL} {
    display: flex;
    justify-content: center;
  }
`;

export default SideToFullGrid;
