import styled from 'styled-components';

const LoadingContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 50vh;
  min-height: 600px;
  background-color: #1e1e1e;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default LoadingContainer;
