import {useMotionValue, useSpring, useTransform} from 'framer-motion';
import React from 'react';
import root from 'window-or-global';
import styled from 'styled-components';
import {motion} from 'framer-motion';
import {ImageArray} from './Types';
import {Device} from '../../../constants';

interface Props {
  image: ImageArray;
  active: boolean;
  leftSide?: boolean;
  keyId: string;
  tablet?: boolean;
  mobile?: boolean;
}

const LoadoutImage = styled(({...rest}) => <motion.img {...rest} />)`
  width: 100%;
  height: auto;
`;

export const LoadoutImageContainer = styled(({...rest}) => <motion.div {...rest} />)`
  position: absolute;
  width: 225px;

  @media screen and (max-width: 940px) {
    position: relative;
    top: unset !important;
    left: unset !important;
    right: unset !important;
    bottom: unset !important;
    width: 120px !important;
    margin: 1em 0;
  }

  @media screen and ${Device.mobileL} {
    width: 100px !important;
  }
`;

const LoadoutItem = ({image, active, leftSide, keyId, mobile, tablet}: Props) => {
  const xPos = useMotionValue(root ? root.innerWidth / 2 : 1000);
  const x = useTransform(xPos, [0, root ? root.innerWidth : 2000], [50, -50], {clamp: true});
  const xSpring = useSpring(x, {damping: 130});

  const handleMouse = (e) => {
    const {clientX, pageX} = e;
    if (!pageX && clientX) xPos.set(clientX * 0.3); //x position within the element.
    if (pageX) xPos.set(pageX * 0.3);
  };

  React.useLayoutEffect(() => {
    root.addEventListener('mousemove', handleMouse, true);
    return () => {
      root.removeEventListener('mousemove', handleMouse, true);
    };
  }, [root]);

  return (
    <>
      <LoadoutImageContainer
        id={keyId}
        initial={{x: leftSide ? '150%' : '-150%', opacity: 0, scale: 0}}
        animate={
          active
            ? {x: '0%', opacity: 1, scale: 1}
            : {x: leftSide ? '150%' : '-150%', opacity: 0, scale: 0}
        }
        transition={{duration: active ? 0.5 : 0, ease: 'easeInOut'}}>
        {image.image && (
          <LoadoutImage
            style={{x: active && !mobile && !tablet ? xSpring : 0}}
            animate={
              active && !mobile ? {y: [5, -5], rotateY: [10, -10]} : {y: [0, 0], rotateY: [0, 0]}
            }
            transition={{
              duration: 2,
              delay: active ? 1.1 : 0,
              repeatType: 'mirror',
              repeat: Infinity,
            }}
            src={`${image.image}?width=250`}
            alt={image.imageAlt}
          />
        )}
      </LoadoutImageContainer>
    </>
  );
};

export default LoadoutItem;
