import React from 'react';
import styled from 'styled-components';

const DivAbsoluteBottom = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
`;

interface Props {
  children: React.ReactNode;
}

const DivAbsoluteBottomExtended = ({children, ...rest}: Props) => (
  <DivAbsoluteBottom {...rest}>{children}</DivAbsoluteBottom>
);

export default DivAbsoluteBottomExtended;
