import React from 'react';
import styled from 'styled-components';

import {Device} from '../../constants';

const DivRelative = styled.div`
  position: relative;
  max-width: 1920px;
  margin: 0 auto;
  z-index: 20;
  @media screen and (max-width: 2000px) {
    max-width: 1440px;
  }
  @media screen and ${Device.laptopL} {
    max-width: 1440px;
  }
`;

interface Props {
  children: React.ReactNode;
}

const DivRelativeExtended = ({children, ...rest}: Props) => (
  <DivRelative {...rest}>{children}</DivRelative>
);

export default DivRelativeExtended;
