import root from 'window-or-global';

export default function() {
    let appContext = '';
    if (root.appContext && root.appContext !== '/') {
        //eslint-disable-next-line
        appContext = root.appContext;
    }
    if (!root.location.origin) {
        const port = root.location.port ? `:${root.location.port}` : '';
        root.location.origin = `${root.location.protocol}//${root.location.hostname}${port}`;
    }
    const origin = root.apiHost ? root.apiHost : root.location.origin + appContext;

    const URL = {
        SEARCH: {
            BING: `${origin}/api/bing/search`
        }
    };
    return URL;
}
