import epicTracking from 'epic-tracking';
import React from 'react';
import VisibilitySensor from 'react-visibility-sensor';

import AnimateString from '../common/AnimateString';

interface Props {
  image: string;
  index: number;
  imageAlt: string;
  title: string;
  description: string;
}
const ScreenItem = ({image, index, imageAlt, title, description}: Props) => {
  const [visible, setVisibility] = React.useState(false);

  const toggleVisibility = React.useCallback(
    (visibility) => {
      setVisibility(visibility);
      if (visibility) {
        epicTracking.trackEvent('Interaction', {
          interactionType: 'scrollViewSection',
          eventCategory: 'OverviewSeasonSection',
          eventAction: title,
        });
      }
    },
    [setVisibility, title]
  );

  return (
    <VisibilitySensor
      onChange={toggleVisibility}
      active={!visible}
      partialVisibility
      minTopValue={0}>
      <div className="screen-section" key={`screen-${index}`}>
        <img src={image} alt={imageAlt || `screen-${index}`} />
        <div className="screen-content">
          <AnimateString
            key="chapter2-screen-Title"
            text={title}
            type="h1"
            speed={1.5}
            delay={0.3}
            autoAlpha={0}
            yPos={100}
            minTop={5}
            scaleFrom={1}
            staggerDelay={0}
          />
          <AnimateString
            key="chapter2-screen-subtitle"
            text={description}
            html={true}
            type="h4"
            speed={1.5}
            delay={0.3}
            autoAlpha={0}
            yPos={100}
            minTop={5}
            scaleFrom={1}
            staggerDelay={0}
          />
        </div>
      </div>
    </VisibilitySensor>
  );
};

export default ScreenItem;
