import HtmlTemplate from 'epic-fortnite-react/components/common/HtmlTemplate';
import styled from 'styled-components';

const StyledSubscriptionText = styled(HtmlTemplate)<{color?: string}>`
  color: ${(props) => (props.color ? props.color : '#000')};
  &&& {
    font-size: 1.2em;
  }
`;

export default StyledSubscriptionText;
