import styled from 'styled-components';

import {Device} from '../../constants';

const Image40 = styled.div`
  max-width: 100%;
  max-height: 100%;
  width: 40%;
  margin: 0 0 0 auto;
  @media only screen and ${Device.tablet} {
    width: 80%;
    margin: 0 auto;
  }
`;

export default Image40;
