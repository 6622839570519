import React from 'react';

import {VisuallyHidden} from '../';

export interface FormProps {
  action?: string;
  children?: React.ReactNode;
  id: string;
  noValidate?: boolean;
  onSubmit(e: React.FormEvent<HTMLFormElement>): void;
  preventDefault?: boolean;
  submitOnEnter?: boolean;
}

export const Form = ({
  action = '#',
  children,
  id,
  noValidate = true,
  onSubmit,
  preventDefault = true,
  submitOnEnter = true,
}: FormProps) => {
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    if (!preventDefault) {
      return;
    }

    onSubmit(e);
    e.nativeEvent.preventDefault();
  };

  return (
    <form action={action} id={id} noValidate={noValidate} onSubmit={handleSubmit}>
      {children}
      {submitOnEnter && (
        <VisuallyHidden>
          <button aria-hidden="true" tabIndex={-1} type="submit" />
        </VisuallyHidden>
      )}
    </form>
  );
};
