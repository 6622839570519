import PropTypes from 'prop-types';
import React from 'react';
import root from 'window-or-global';

import {localizedMessage} from '../message/Message';

import CloseSVG from './Close';
import Footer from './Footer';
import Header from './Header';
import Text from './Text';

const getMedia = (data, modalIndex) => {
  if (data?.extended_entities?.media && data.extended_entities.media[modalIndex]) {
    return data.extended_entities.media[modalIndex];
  }
  return data?.entities?.media && data?.entities?.media[modalIndex];
};

class Modal extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.close = this.close.bind(this);
  }

  close() {
    this.context.closeModal();
  }

  render() {
    if (root.__server_side_render) return null;

    const {data: dataOrg, modalIndex} = this.props;

    // use retweet as data if its a RT
    const data = dataOrg.retweeted_status ? dataOrg.retweeted_status : dataOrg;

    const media = getMedia(data, modalIndex);
    const modalWrap = {};
    const imgStyle = {};
    const w = media.sizes.large.w;
    const h = media.sizes.large.h;
    const sizewh650 = `${(media.sizes.large.w / media.sizes.large.h) * 650}px`;
    if (w > 1000) {
      if (h > 650) {
        imgStyle.width = sizewh650;
        modalWrap.width = sizewh650;
      } else {
        modalWrap.width = sizewh650;
        imgStyle.height = `${(media.sizes.large.h / media.sizes.large.w) * 1000}px`;
      }
    } else {
      if (h > 650) {
        modalWrap.width = sizewh650;
        imgStyle.width = sizewh650;
      } else {
        modalWrap.width = `${w}px`;
      }
    }

    return (
      <div className="Modal">
        <span className="spacer" />
        <div className="ModalClose" onClick={this.close.bind(this)} tabIndex="0" role="button" />
        <div className="Modal-wrap">
          <div className="closeModal" tabIndex="0" role="button" onClick={this.close}>
            <CloseSVG />
          </div>
          <div className="modal-tweet">
            <div className="media-wrap">
              <img
                src={media.media_url_https}
                style={imgStyle}
                alt={localizedMessage('epic.fortnite.twitter.media')}
              />
            </div>
            <div className="content">
              <Header data={data} />
              <Text data={data} />
              <Footer data={data} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Modal.contextTypes = {
  closeModal: PropTypes.func,
};

Modal.propTypes = {
  data: PropTypes.object,
  active: PropTypes.number,
  modalIndex: PropTypes.number,
};

Modal.defaultProps = {
  data: {
    entities: {},
    user: {},
  },
  active: 0,
};

export default Modal;
