class ValidationError extends Error {
    public fieldName;
    public errorCode;
    public constructor(message, fieldName, errorCode) {
        super(message);
        this.fieldName = fieldName;
        this.errorCode = errorCode;
        this.name = 'ValidationError';
    }
}

export default ValidationError;
