import styled from 'styled-components';

export const Button = styled.button`
  appearance: none;
  background-color: transparent;
  border: none;
  box-sizing: border-box;
  display: flex;
  font-family: inherit;
  font-size: 100%;
  line-height: 1;
  outline: none;
  padding: 0;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  &::before,
  &::after {
    box-sizing: inherit;
    content: '';
  }
`;
