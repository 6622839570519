import styled from 'styled-components';

interface Props {
  active: boolean;
}

const FeatureTitleContainer = styled.div<Props>`
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  z-index: 3;
  position: absolute;
  top: 0;
  visibility: ${({active}) => (active ? 'visible' : 'hidden')};
  opacity: ${({active}) => (active ? '1' : '0')};
  transition: opacity 1s ease-in-out;
  @media screen and (max-width: 1140px) {
    max-width: none;
    align-items: baseline;
  }
`;

export default FeatureTitleContainer;
