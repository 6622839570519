import * as RadixAccordion from '@radix-ui/react-accordion';
import React, {ComponentProps} from 'react';

export const Accordion = (
  props:
    | RadixAccordion.AccordionSingleProps
    | (RadixAccordion.AccordionMultipleProps & ComponentProps<typeof RadixAccordion.Root>)
) => <RadixAccordion.Root {...props} />;

export const AccordionItem = (
  props: RadixAccordion.AccordionItemProps & ComponentProps<typeof RadixAccordion.Item>
) => <RadixAccordion.Item {...props} />;

export const AccordionHeader = (
  props: RadixAccordion.AccordionHeaderProps & ComponentProps<typeof RadixAccordion.Header>
) => <RadixAccordion.Header {...props} />;

export const AccordionTrigger = (
  props: RadixAccordion.AccordionTriggerProps & ComponentProps<typeof RadixAccordion.Trigger>
) => <RadixAccordion.Trigger {...props} />;

export const AccordionContent = (
  props: RadixAccordion.AccordionContentProps & ComponentProps<typeof RadixAccordion.Content>
) => <RadixAccordion.Content {...props} />;

Accordion.Item = AccordionItem;
Accordion.Header = AccordionHeader;
Accordion.Trigger = AccordionTrigger;
Accordion.Content = AccordionContent;

Accordion.displayName = 'Accordion';
