import {Container, Inline, Section, Stack, Text, useBreakpoints} from 'epic-fortnite-react';
import {FontSizes} from 'epic-fortnite-react/types/theme';
import epicTracking from 'epic-tracking';
import React from 'react';
import styled from 'styled-components';
import root from 'window-or-global';

import {CmsComponentMapper} from '@epic-mw/react-cms';
import CTAButton from '../../components/common/CTAButton';

const FlexibleHeaderWrapper = styled(Section)`
  position: relative;
  color: #fff;
  height: 80vh;

  @media (min-width: 900px) {
    aspect-ratio: 16/9;
    height: auto;
  }

  @media (min-width: 1440px) {
    aspect-ratio: 2/1;
    height: auto;
  }
`;

const SimpleImage = styled.img<{size?: string; position?: string}>`
  width: ${({size}) => size ?? '100%'};
`;

type OverviewBadgeProps = {
  image?: string;
  size?: string;
  position?: string;
};

const OverviewBadge = ({image, size, position}: OverviewBadgeProps) => {
  return <SimpleImage src={image} size={'100px'} position={position} />;
};

type OverviewLogoProps = {
  image?: string;
  logoAltText?: string;
  size?: string;
  position?: string;
  mobileImage?: string;
};

const OverviewLogo = ({image, logoAltText, size, position, mobileImage}: OverviewLogoProps) => {
  const imageSrc: string | undefined = useBreakpoints(mobileImage ? mobileImage : image, {
    900: image,
  }).value;

  const StyledOverviewImage = styled(SimpleImage)`
    display: block;
    margin: auto;
    max-height: 6rem;
    width: auto;
  `;

  return (
    <StyledOverviewImage
      alt={logoAltText ? logoAltText : ''}
      src={imageSrc}
      size={size}
      position={position}
    />
  );
};

type OverviewTextProps = {
  header?: string;
  textContent?: string;
};

const trackCtaClick = (item, utmLink) => {
  if (item.eventTracking && root._epicTracking) {
    const {
      interactionType = '',
      eventAction = '',
      eventLabel = '',
      eventCategory = '',
    } = item.eventTracking;

    const utmEventValue = utmLink;

    epicTracking.trackEvent('Interaction', {
      interactionType,
      eventAction,
      eventLabel,
      eventValue: utmEventValue,
      eventCategory,
    });
  }
};

interface CTAConfig {
  isCtaLinkExternal: boolean;
  ctaString: string;
  ctaLink: string;
  disabled: boolean;
}

interface CTAObject {
  [key: string]: CTAConfig;
}

interface Props {
  data: CTAObject;
}

const CTAButtons = (data: Props) => {
  const arr: CTAConfig[] = [];

  Object.keys(data).map((item) => {
    const int = parseInt(item);
    if (typeof int === 'number' && !Number.isNaN(int)) {
      arr.push(data[item]);
    }
  });

  return (
    <Inline justify="center" gap="sm">
      {arr.map((item, index) => {
        return (
          <CTAButton
            ctaConfig={{
              isCtaLinkExternal: item?.isCtaLinkExternal,
              ctaString: item?.ctaString,
              ctaLink: item?.ctaLink,
              disabled: item?.disabled,
              onExternalClickHandler: () => {
                trackCtaClick(item, item?.ctaLink);
              },
            }}
            key={index}
            theme="blackYellow"
          />
        );
      })}
    </Inline>
  );
};

const OverviewText = ({header, textContent}: OverviewTextProps) => {
  const textSize = useBreakpoints<FontSizes>('lg', {1280: '2xl'}).value;

  return (
    <>
      {(header || textContent) && (
        <Stack align="center" gap="2xl">
          {header && (
            <Text
              align="center"
              as="h1"
              color="black"
              lineLength="sm"
              size={textSize}
              transform="uppercase">
              {header}
            </Text>
          )}
        </Stack>
      )}
    </>
  );
};

type RichTextProps = {
  richTextBox?: string;
};

const RichText = ({richTextBox}: RichTextProps) => {
  const RichTextBlock = styled.div`
    text-align: center;

    &&&& a {
      color: #000;
      text-decoration: underline;
      font-weight: 500;

      &:hover {
        color: #000;
      }
    }
  `;

  return (
    <>
      <RichTextBlock dangerouslySetInnerHTML={{__html: richTextBox ?? ''}} />
    </>
  );
};

const CloudStreamingSeasonUAHeader = (data) => {
  const {blocks, coreSectionProps} = data;
  const backgroundImage: string = useBreakpoints(
    coreSectionProps.mobileBackgroundImage
      ? coreSectionProps.mobileBackgroundImage
      : coreSectionProps.backgroundImage,
    {900: coreSectionProps.backgroundImage}
  ).value;

  return (
    <FlexibleHeaderWrapper
      backgroundColor={coreSectionProps?.backgroundColor}
      backgroundImage={backgroundImage}
      backgroundPosition="top"
      backgroundSize={coreSectionProps?.backgroundSize}
      size={coreSectionProps?.size}>
      <div style={{paddingBottom: '2rem', height: '100%', display: 'flex', alignItems: 'flex-end'}}>
        <Container size="med" maxWidth={800}>
          <div style={{display: 'flex', flexDirection: 'column', gap: '2rem'}}>
            <CmsComponentMapper
              dynamicPageDataArray={blocks}
              componentMapping={{
                logo: (props: React.ComponentProps<typeof OverviewLogo>) => (
                  <OverviewLogo {...props} />
                ),
                badge: (props: React.ComponentProps<typeof OverviewBadge>) => (
                  <OverviewBadge {...props} />
                ),
                textContent: (props: React.ComponentProps<typeof OverviewText>) => (
                  <OverviewText {...props} />
                ),
                ctaGroup: (props: React.ComponentProps<typeof OverviewText>) => (
                  <CTAButtons {...props} />
                ),
                richTextContent: (props: React.ComponentProps<typeof OverviewText>) => (
                  <RichText {...props} />
                ),
              }}
            />
          </div>
        </Container>
      </div>
    </FlexibleHeaderWrapper>
  );
};

export default CloudStreamingSeasonUAHeader;
