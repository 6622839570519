import {seamlessImmutable, seamlessBootstrap} from 'epic-alt-utils';
import alt from 'epic-fortnite-react/alt';
import Immutable from 'seamless-immutable';
//import Actions from '../actions/Actions';

@seamlessImmutable
class HkDownloadStore {
  getStateFunctions() {
    return {};
  }

  constructor() {
    this.on('error', (error) => {
      console.error(error);
    });
    this.on('bootstrap', seamlessBootstrap.bind(this, null));

    const initialState = Immutable({
      hkDownload: {},
    });

    this.state = initialState.merge(this.getStateFunctions());

    //In case you want to bind to some actions
    //this.bindActions(Actions);
  }
}

export default alt.createStore(HkDownloadStore, 'HkDownloadStore');
