import styled from 'styled-components';

import {Device} from '../../constants';

const StyledSubscriptionJoinStepImg = styled.img`
  width: 100%;
  padding-left: 25px;

  @media screen and ${Device.mobileL} {
    padding-left: 0;
  }
`;

export default StyledSubscriptionJoinStepImg;
