import React from 'react';
import {CoreSection} from './CoreSection';
import styled from 'styled-components';
import {Stack, Text} from 'epic-fortnite-react';
import {CoreSectionProps} from '../bundles/types';
import {WatchVideo} from '../common/WatchVideo';

interface StackedHeroProps {
  coreSectionProps: CoreSectionProps;
  heroImg: string;
  text: string;
  trailerConfig: {
    text: string;
    textColor?: string;
    videoId: string;
    isActive: boolean;
  };
}

const StyledStackedContentContainer = styled.div`
  min-height: 80vh;
  padding-left: 1rem;
  padding-right: 1rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledImage = styled.img`
  max-width: 32rem;
  width: 80vw;
`;

const StackedHero = ({coreSectionProps, heroImg, text, trailerConfig}: StackedHeroProps) => {
  return (
    <CoreSection coreSectionProps={coreSectionProps}>
      <StyledStackedContentContainer>
        <Stack align="center" gap="lg">
          <StyledImage src={heroImg} alt="" />
          <Text
            align="center"
            color={coreSectionProps.defaultTextColor}
            lineLength="sm"
            size="2xl"
            transform="uppercase">
            {text}
          </Text>
          {trailerConfig.videoId && trailerConfig.isActive && (
            <WatchVideo
              videoId={trailerConfig.videoId}
              text={trailerConfig.text}
              textColor={trailerConfig.textColor || coreSectionProps.defaultTextColor}
            />
          )}
        </Stack>
      </StyledStackedContentContainer>
    </CoreSection>
  );
};

export default StackedHero;
