import {getLocale} from '@epic-core/common';
import {generateRouteTo} from 'epic-fortnite-react';
import React from 'react';
import {useLocation} from 'react-router';

import CTA from '../../components/CTA';
import Link from '../../components/Link';
import MaskType from '../../components/MaskType';
import {Mask} from '../../types';

import MaskDescription from './MaskDescrption';
import MaskName from './MaskName';

interface Props extends Pick<Mask, 'name' | 'description' | 'type' | 'cta'> {
  accent: string;
}

const MaskDetails = ({accent, name, description, type, cta}: Props) => {
  const location = useLocation();
  const locale = getLocale();

  return (
    <>
      <MaskType accent={accent}>{type}</MaskType>
      {cta?.ctaLink ? (
        <Link to={generateRouteTo(cta?.ctaLink, location, locale) ?? ''}>
          <MaskName>{name}</MaskName>
        </Link>
      ) : (
        <MaskName>{name}</MaskName>
      )}
      <MaskDescription>{description}</MaskDescription>
      <CTA ctaConfig={cta} background={accent} />
    </>
  );
};

export default MaskDetails;
