import {getLocale} from '@epic-core/common';
import React from 'react';

import {generateMainSitePath} from '../../utils/FortniteUtils';
import StatusCode from '../common/StatusCode';
import Message from '../message/Message';

/**
 * 404 component.  Shown when page not found.
 */
const Error404 = () => {
  const locale = getLocale();
  return (
    <StatusCode code={404}>
      <div className="error404 errorPages">
        <div className="content">
          <div className="errorBody">
            <p>
              <Message code="epic.fortnite.404.subtitle" />
            </p>
          </div>
          <div className="errorHeader">
            <div className="llama-1" />
            <div className="llama-2" />
            <div className="llama-3" />
            <h1>
              <Message code="epic.fortnite.404" />
            </h1>
          </div>
          <a className="btn btn-display btn-error" href={generateMainSitePath('/', locale)}>
            <Message code="epic.fortnite.404.return" />
          </a>
        </div>
        <div className="box-shadow" />
      </div>
    </StatusCode>
  );
};

export default Error404;
