import {Container, Section, FAQ} from 'epic-fortnite-react';
import React from 'react';

import {CmsComponentMapper} from '@epic-mw/react-cms';
import SimpleTextWithBackground from '../blocks/SimpleTextWithBackground';
import SimpleDisplayCardSection from '../simpleCard/SimpleDisplayCardSection';
import CenteredShare from '../centered/CenteredShare';
import LegalTextSection from '../blocks/LegalTextSection';
import DualLegalBlock from '../blocks/DualLegalBlock';
import {SimpleRichTextSection} from '../blocks/SimpleRichTextSection';

const RebootRallyBaseplate = (props) => {
  const {blocks, shared, condensed} = props;

  return (
    <CmsComponentMapper
      dynamicPageDataArray={blocks}
      componentMapping={{
        cardSection: (props: React.ComponentProps<typeof SimpleDisplayCardSection>) => (
          <Section size="2xl" backgroundColor={props.cardBackgroundColor}>
            <Container maxWidth={1600} size="med">
              <SimpleDisplayCardSection {...props} />
            </Container>
          </Section>
        ),
        textSection: (props: React.ComponentProps<typeof SimpleTextWithBackground>) => (
          <SimpleTextWithBackground
            alt={shared && shared === 'true'}
            {...props}
            condensed={condensed}
          />
        ),
        shareSection: (props: React.ComponentProps<typeof CenteredShare>) => (
          <CenteredShare {...props} condensed={condensed} />
        ),
        legalTextSection: (props: React.ComponentProps<typeof LegalTextSection>) => (
          <LegalTextSection {...props} />
        ),
        multiLegalSection: (props: React.ComponentProps<typeof DualLegalBlock>) => (
          <Section size="sm" backgroundColor={props.backgroundColor}>
            <Container maxWidth={1600}>
              <DualLegalBlock {...props} />
            </Container>
          </Section>
        ),
        richTextSection: (props: React.ComponentProps<typeof SimpleRichTextSection>) => (
          <SimpleRichTextSection {...props} />
        ),
        faqSection: (props: React.ComponentProps<typeof FAQ>) => (
          <Section size="4xl" backgroundColor="#151718" defaultTextColor="#fff">
            <Container maxWidth={800} size="med">
              <FAQ linkColor="#F7FF19" {...props} />
            </Container>
          </Section>
        ),
      }}
    />
  );
};

export default RebootRallyBaseplate;
