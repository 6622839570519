import {datasource} from 'alt-utils/lib/decorators';
import {seamlessImmutable, seamlessBootstrap} from 'epic-alt-utils';
import Immutable from 'seamless-immutable';
import root from 'window-or-global';

import AppConfigActions from '../actions/AppConfigActions';
import alt from '../alt';
import AppConfigSource from '../sources/AppConfigSource';

@datasource(AppConfigSource)
@seamlessImmutable
class AppConfigStore {
  getStateFunctions() {
    return {
      getDownloadUrl() {
        if (root._epicGamesNav && root._epicGamesNav.browser && root._epicGamesNav.browser.mac) {
          return this.macDownloadUrl;
        }
        return this.pcDownloadUrl;
      },
      isSwitch() {
        return root.navigator ? /Nintendo/.test(root.navigator.userAgent) : false;
      },
      isPs4() {
        return root.navigator ? /PlayStation 4/.test(root.navigator.userAgent) : false;
      },
    };
  }

  constructor() {
    this.on('error', (error) => {
      console.error(error);
    });
    this.on('bootstrap', seamlessBootstrap.bind(this, null));
    const initialState = Immutable({
      pcDownloadUrl: null,
      macDownloadUrl: null,
      friendUrl: 'https://fortnite.com/friend/',
      androidDownloadUrl: null,
      psnLoginEnabled: false,
      xboxLoginEnabled: false,
      nintendoLoginEnabled: false,
      ps4RetailEnabled: false,
      platformDownloadLinks: null,
      friendLinkEnabled: false,
      globalAlert: null,
      enableBing: false,
      countryCode: 'US',
      isEu: false,
      euCookieMessage: {},
      termsOfServiceLink: '',
      helpLink: '',
      epicSid: null,
    });
    this.state = initialState.merge(this.getStateFunctions());
    this.bindActions(AppConfigActions);
  }

  onFetchEpicSid() {
    if (this.state.epicSid) return;
    this.getInstance().fetchEpicSid();
    return this.setState(this.state.set('loading', true));
  }

  onFetchEpicSidSuccess({data = {}}) {
    this.setState(
      this.state.merge({
        epicSid: data.result,
        loading: false,
      })
    );
  }

  onFetchEpicSidFailure(response) {
    console.error('Failed to send 404 not found message.');
    this.setState(this.state.merge({loading: false}));
  }
}

export default alt.createStore(AppConfigStore, 'AppConfigStore');
