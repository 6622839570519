import PropTypes from 'prop-types';
import React from 'react';

import Axes from './Axes';
import BattleStarsFeature from './BattleStarsFeature/BattleStarsFeature';
import CrewSection from './CrewFeature/CrewSection';
import Emotes from './Emotes';
import FeatureHeroCarousel from './FeatureHeroCarousel/FeatureHeroCarousel';
import Gliders from './Gliders';
import GunWraps from './GunWraps';
import ImageCta from './ImageCta';
import Items from './Items';
import LoadingScreens from './LoadingScreens';
import MoreRewards from './MoreRewards';
import SkinSlider from './SkinSlider';
import VBucksSection from './VBucksSection/VBucksSection';
import VBucksSectionThreeColumn from './VBucksSection/VBucksSectionThreeColumn';
// new battle pass since chapter2
import VideoHeader from './VideoHeader';
import XpBeyond from './XpBeyond/XpBeyond';
import XpProgression from './XpProgression';

export default class BattlePassSection extends React.Component {
  static propTypes = {
    section: PropTypes.object,
    mobile: PropTypes.bool,
    tablet: PropTypes.bool,
    isLoggedIn: PropTypes.bool,
    season: PropTypes.string,
    callout: PropTypes.object,
  };

  render() {
    const {section, mobile, tablet, season} = this.props;

    switch (section.data._type) {
      case 'BattlePass New - VideoHeader': {
        return <VideoHeader data={section.data} mobile={mobile} tablet={tablet} season={season} />;
      }
      case 'BattlePass New - SkinSlider': {
        return <SkinSlider data={section.data} mobile={mobile} tablet={tablet} season={season} />;
      }
      case 'BattlePass New - VBucksSection': {
        return (
          <VBucksSection data={section.data} mobile={mobile} tablet={tablet} season={season} />
        );
      }
      case 'BattlePass New - VBucksThreeColumn': {
        return <VBucksSectionThreeColumn data={section.data} mobile={mobile} tablet={tablet} />;
      }
      case 'BattlePass New - XpProgression': {
        return (
          <XpProgression data={section.data} mobile={mobile} tablet={tablet} season={season} />
        );
      }
      case 'BattlePass New - Gun Wraps': {
        return <GunWraps data={section.data} mobile={mobile} tablet={tablet} season={season} />;
      }
      case 'BattlePass New - Gliders': {
        return <Gliders data={section.data} mobile={mobile} tablet={tablet} season={season} />;
      }
      case 'BattlePass New - Axes': {
        return <Axes data={section.data} mobile={mobile} tablet={tablet} season={season} />;
      }
      case 'BattlePass New - Emotes': {
        return (
          <Emotes
            data={section.data}
            emotes={section.emotes}
            backgroundVids={section.backgroundVids}
            mobile={mobile}
            tablet={tablet}
            season={season}
          />
        );
      }
      case 'BattlePass New - Items': {
        return <Items data={section.data} mobile={mobile} tablet={tablet} season={season} />;
      }
      case 'BattlePass New - LoadingScreens': {
        return (
          <LoadingScreens data={section.data} mobile={mobile} tablet={tablet} season={season} />
        );
      }
      case 'BattlePass New - MoreRewards': {
        return <MoreRewards data={section.data} mobile={mobile} tablet={tablet} season={season} />;
      }
      case 'BattlePass New - ImageCta': {
        return <ImageCta data={section.data} mobile={mobile} tablet={tablet} season={season} />;
      }
      case 'BattlePass - Feature Hero': {
        return <FeatureHeroCarousel data={section.data} mobile={mobile} tablet={tablet} />;
      }
      case 'BattlePass New - Crew Section': {
        return <CrewSection data={section.data} mobile={mobile} tablet={tablet} />;
      }
      case 'BattlePass New - BattleStars': {
        return <BattleStarsFeature data={section.data} mobile={mobile} tablet={tablet} />;
      }
      case 'xp-beyond-data': {
        return <XpBeyond data={section.data} mobile={mobile} tablet={tablet} />;
      }
      default:
        return <div />;
    }
  }
}
