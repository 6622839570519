import styled from 'styled-components';

import {breakpoints} from '../../constants/Breakpoints';

const CopyrightWrapper = styled.div`
  position: absolute;
  bottom: -1rem;
  left: 1rem;
  color: #fff;
  font-size: 1.5rem;

  @media ${breakpoints.mobileL} {
    left: 0;
    right: 0;
    text-align: center;
    font-size: 1rem;
  }
`;

export default CopyrightWrapper;
