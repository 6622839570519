const customStyle = {
  indicatorSeparator: () => ({
    backgroundColor: 'unset',
  }),
  menu: (provided) => ({
    ...provided,
    width: '100%',
    marginTop: -2,
    marginRight: 0,
    marginLeft: 0,
    boxShadow: 'none',
    borderTop: 'none',
    borderRight: '1px solid hsl(0,0%,80%)',
    borderLeft: '1px solid hsl(0,0%,80%)',
    borderBottom: '1px solid hsl(0,0%,80%)',
  }),
  container: (styles) => ({
    ...styles,
    minWidth: 200,
  }),
  control: (provided) => ({
    ...provided,
    borderColor: 'hsl(0,0%,80%)',
    '&:hover': {
      borderColor: 'hsl(0,0%,80%)',
    },
    boxShadow: 'none',
    height: 48,
  }),
};

export default customStyle;
