import styled from 'styled-components';

interface Props {
  accent?: string;
}

const MaskType = styled.div<Props>`
  color: ${({accent}) => accent};
  font-size: 2rem;
`;

export default MaskType;
