// Inspired by https://example-react-resize-observer.sethcorker.com/

import {useEffect, useMemo, useRef, useState, RefObject} from 'react';
import ResizeObserver from 'resize-observer-polyfill';
import root from 'window-or-global';

type Breakpoints<T> = Record<number, T>;
type BreakpointPairs<T> = [string, T][];

const findBreakPoint = <T>(
  defaultValue: T,
  breakpointPairs: BreakpointPairs<T>,
  width: number
): T => {
  // Loop over breakpoints in reverse to test from large to small breakpoints
  for (let i = 0; i < breakpointPairs.length; i++) {
    const breakpointMinWidth = Number(breakpointPairs[i][0]);
    if (width >= breakpointMinWidth) {
      return breakpointPairs[i][1];
    }
  }

  return defaultValue;
};

export const useBreakpoints = <T>(
  defaultValue: T,
  breakpoints: Breakpoints<T>,
  elRef: RefObject<HTMLElement> | null = null
): {value: T; loading: boolean} => {
  const [value, setValue] = useState(defaultValue);
  const [loading, setLoading] = useState(true);

  const breakpointsPairsDescending = useMemo(() => {
    return Object.entries(breakpoints).sort(sortDescending);
  }, [breakpoints]);

  const observer = useRef(
    new ResizeObserver((entries: ResizeObserverEntry[]) => {
      const {width} = entries[0].contentRect;
      setValue(findBreakPoint(defaultValue, breakpointsPairsDescending, width));
    })
  );

  useEffect(() => {
    const _observer = observer?.current;
    const _element = elRef ? elRef.current : root.document.body;

    _element && _observer.observe(_element);
    setLoading(false);

    return () => {
      _element && _observer.unobserve(_element);
    };
  }, [elRef, observer]);

  return {value, loading};
};

function sortDescending(a: [string, unknown], b: [string, unknown]): number {
  if (b[0] > a[0]) {
    return 1;
  }

  if (a[0] > b[0]) {
    return -1;
  }

  return 0;
}
