import VideoOverlay from 'epic-fortnite-react/components/videooverlay/VideoOverlay';
import VideoOverlayProvider from 'epic-fortnite-react/components/videooverlay/VideoOverlayProvider';
import PropTypes from 'prop-types';
import React from 'react';
import Helmet from 'react-helmet';

import Screens from '../../components/chapter2/s6/StyledScreens';
import CtaFooter from '../../components/chapter2/s7/CtaFooter';
import VideoHeader from '../../components/chapter2/s8/VideoHeaderC2s8';
import {getHelmetTags} from '../../utils/metaTags';

const Chapter2Overview = ({page, classes, mobile}) => {
  const {videoHeader, screens, footerCta} = page;
  const tags = getHelmetTags(page);

  return (
    <div className={classes}>
      <VideoOverlayProvider>
        <Helmet {...tags} />
        <VideoHeader data={videoHeader} className="overview" />
        <Screens data={screens} />
        <CtaFooter data={footerCta} mobile={mobile} />
        <VideoOverlay />
      </VideoOverlayProvider>
    </div>
  );
};

Chapter2Overview.propTypes = {
  classes: PropTypes.string,
  page: PropTypes.object,
  mobile: PropTypes.bool,
};

export default Chapter2Overview;
