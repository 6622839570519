import PropTypes from 'prop-types';
import React, {useState} from 'react';
import VisibilitySensor from 'react-visibility-sensor';

import AnimateString from '../common/AnimateString';

const Axes = ({data}) => {
  const [visible, setVisibility] = useState(false);
  const {title, paragraph, image, imageAlt} = data;
  return (
    <div className="axe-wrapper">
      <VisibilitySensor
        onChange={(visibility) => setVisibility(visibility)}
        active={!visible}
        partialVisibility
        minTopValue={200}>
        <div className={`battlepass-axes ${visible ? 'visible' : ''}`}>
          <img className="axe-image" src={image} alt={imageAlt} />
          <div className="axes-content">
            <AnimateString
              key="axes-h1"
              text={title}
              type="h1"
              speed={1.5}
              delay={0.3}
              autoAlpha={0}
              yPos={100}
              minTop={20}
              scaleFrom={1}
              staggerDelay={0}
            />
            {paragraph ? (
              <AnimateString
                key={`axes-p`}
                text={paragraph}
                type="p"
                speed={1.5}
                delay={0.3}
                autoAlpha={0}
                yPos={100}
                minTop={20}
                scaleFrom={1}
                staggerDelay={0}
              />
            ) : null}
          </div>
        </div>
      </VisibilitySensor>
    </div>
  );
};

Axes.propTypes = {
  data: PropTypes.object,
};

export default Axes;
