import Error404 from 'epic-fortnite-react/components/error/Error404';
import {ArticleView} from 'epic-minimalist-blog/app/scripts';
import React from 'react';
import styled from 'styled-components';

import {JuxtaposeStyles} from '../../components/blog/JuxtaposeStyles';
import {SocialShareWidget} from '../../components/blog/SocialShareWidget';
import {useJuxtaposeScript} from '../../components/blog/useJuxtaposeScript';
import {breakpoints} from '../../constants/Breakpoints';

const BlogWrapper = styled.div`
  table tr:first-child:first-child td {
    padding: 1rem;
  }

  table td {
    padding: 0.25rem;
    border: 1px solid black;
  }

  &&& table p,
  &&& table a {
    line-height: 1.3;
  }

  @media only screen and ${breakpoints.mobileL} {
    &&& {
      table p {
        line-height: 2rem;
      }
    }
  }
`;

export default function ArticleWrapper(props) {
  useJuxtaposeScript();

  return (
    <BlogWrapper className="blog-wrapper">
      <JuxtaposeStyles />
      <ArticleView
        notFoundComponent={<Error404 />}
        {...props}
        componentMapping={{
          socialShareWidget: (props) => <SocialShareWidget key={props.key} details={props} />,
        }}
      />
    </BlogWrapper>
  );
}
