import {connectToStores} from 'epic-alt-utils';
import Error404 from 'epic-fortnite-react/components/error/Error404';
import PropTypes from 'prop-types';
import React from 'react';
import {withRouter} from 'react-router';

import BattlePassOverviewActions from '../../actions/BattlePassOverviewActions';
import LoadScreen from '../../components/loadScreen/LoadScreen';
import RetryScreen from '../../components/loadScreen/RetryScreen';
import {BattlePassOverviewStore, HeaderStore} from '../../stores';
import Chapter2Overview from '../chapter2/Chapter2Overview';
import Chapter3Overview from '../chapter3/Chapter3Overview';
// import Chapter2s3View from '../chapter2/Chapter2s3View';
// import Chapter2s4View from '../chapter2/Chapter2s4View';
// import Chapter2s5View from '../chapter2/Chapter2s5View';
// import Chapter2s6View from '../chapter2/Chapter2s6View';
// import Chapter2s7View from '../chapter2/Chapter2s7View';
// import Chapter2s8View from '../chapter2/Chapter2s8View';

// import Chapter2s2View from './Chapter2s2View';

@connectToStores
class BattlePassOverview extends React.Component {
  static propTypes = {
    slug: PropTypes.string,
    battlePassOverviewStore: PropTypes.object,
    mobile: PropTypes.bool,
  };

  static getStores() {
    return [BattlePassOverviewStore];
  }

  static getPropsFromStores() {
    return {
      battlePassOverviewStore: BattlePassOverviewStore.getState(),
      mobile: HeaderStore.getState().mobile(),
    };
  }

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidUpdate(prevProps) {
    const prevSlug = this.getPageSlug(prevProps);
    const currentSlug = this.getPageSlug();
    if (prevSlug !== currentSlug) {
      const currentPage = this.getActivePage();
      if (currentPage.loading === 'UNKNOWN') {
        BattlePassOverviewActions.getPage(this.getPageSlug());
      }
    }
  }

  componentDidMount() {
    const currentPage = this.getActivePage();
    if (currentPage.loading === 'UNKNOWN') {
      BattlePassOverviewActions.getPage(this.getPageSlug());
    }
  }

  retry = () => BattlePassOverviewActions.getPage(this.getPageSlug());

  getActivePage() {
    const pageSlug = this.getPageSlug();
    const pages = this.props.battlePassOverviewStore.pages;
    if (!pages) {
      // Page store can be set to null on a network error
      // Where the client loaded but the ajax call failed.
      return {loading: 'FAILURE'};
    }
    if (pages[pageSlug] === null) {
      // Page not found.
      return {
        loading: 'NOTFOUND',
      };
    }
    return pages[pageSlug] || {loading: 'UNKNOWN'};
  }

  getPageSlug(props) {
    const prop = props || this.props;
    const activeSlug = prop.match.params.slug;
    const parentSlug = prop.match.params.parent ? `${prop.match.params.parent}/` : '';
    return `${parentSlug}${activeSlug}`;
  }

  render() {
    const pageSlug = this.getPageSlug();
    const page = this.getActivePage();

    if (this.props.battlePassOverviewStore.loading) {
      return <LoadScreen show={true} />;
    }
    if (page.loading === 'FAILURE') {
      return <RetryScreen show={true} retry={this.retry} />;
    }
    if (!page || page.loading === 'UNKNOWN') {
      return <Error404 />;
    }
    const activeSlugClass = pageSlug.replace(/[^a-z0-9-]/i, '-');
    const classes = ['battlePassOverView', activeSlugClass].join(' ');

    const {mobile} = this.props;
    const isChapter2 = pageSlug.includes('ch2') || pageSlug.includes('chapter-2');

    if (isChapter2) {
      return <Chapter2Overview classes={classes} page={page} mobile={mobile} />;
    }

    return <Chapter3Overview page={page} />;
  }
}

export default withRouter(BattlePassOverview);
