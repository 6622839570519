import FAQSection from 'epic-fortnite-react/components/common/FAQSection';
import React, {useState} from 'react';
import VisibilitySensor from 'react-visibility-sensor';

import {triggerAnalytic} from '../../utils/SubscriptionUtils';

import StyledSubscriptionFAQWrapper from './StyledSubscriptionFAQWrapper';
import SubscriptionCoreText from './SubscriptionCoreText';

interface Props {
  content: any;
  mobile: boolean;
  tablet: boolean;
}

const SubscriptionFaqSection = (props: Props) => {
  const {
    content: {
      header,
      faqList: {items},
    },
  } = props;
  const [isVisible, setVisibility] = useState(false);
  const onChange = (): void => {
    setVisibility(!isVisible);
    if (isVisible) {
      triggerAnalytic('SubscriptionFAQ');
    }
  };

  return (
    <VisibilitySensor onChange={onChange} partialVisibility minTopValue={10}>
      <StyledSubscriptionFAQWrapper id="faq-section">
        <SubscriptionCoreText header={header} />
        <FAQSection itemList={items} />
      </StyledSubscriptionFAQWrapper>
    </VisibilitySensor>
  );
};

export default SubscriptionFaqSection;
