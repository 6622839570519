import {connectToStores} from 'epic-alt-utils';
import StoreActions from 'epic-fortnite-react/actions/StoreActions';
import React from 'react';
import Helmet from 'react-helmet';

import StyledSubscriptionNotificationFooter from '../../components/subscription/StyledSubscriptionNotificationFooter';
import {HeaderStore, SubscriptionInterface, SubscriptionStore} from '../../stores';
import {StoreStore} from '../../stores';

import SubscriptionSection from './SubscriptionSection';

interface Props {
  subscriptionStore: SubscriptionInterface;
  mobile: boolean;
  tablet: boolean;
  storeStore: any;
}
interface SubscriptionInterface {
  subscriptionStore: any;
}

class SubscriptionView extends React.Component<Props> {
  static getStores = () => [SubscriptionStore, HeaderStore, StoreStore];
  static getInitialActions = () => {
    return [StoreActions.getSubscriptionData];
  };
  static getPropsFromStores = () => ({
    subscriptionStore: SubscriptionStore.getState(),
    storeStore: StoreStore.getState(),
    mobile: HeaderStore.getState().mobile(),
    tablet: HeaderStore.getState().tablet(),
  });

  render() {
    const {
      mobile,
      tablet,
      subscriptionStore: {
        page: {
          metaDescription,
          metaTitle,
          ogDescription,
          ogTitle,
          twitterDescription,
          twitterTitle,
          metaImage,
          pageList,
          notifier: {isActive, notification},
        },
      },
      storeStore: {loadingSubscriptionOffer, subscriptionOfferData},
    } = this.props;
    const tags = [
      {itemProp: 'name', content: metaTitle},
      {itemProp: 'description', content: metaDescription},
      {itemProp: 'image', content: metaImage},
      {name: 'description', content: metaDescription},
      {name: 'twitter:title', content: twitterTitle},
      {name: 'twitter:description', content: twitterDescription},
      {name: 'twitter:image', content: metaImage},
      {property: 'og:title', content: ogTitle},
      {property: 'og:image', content: metaImage},
      {property: 'og:description', content: ogDescription},
    ];

    return (
      <div className="subscription-view-wrapper">
        <Helmet meta={tags} title={metaTitle} />
        {!!pageList &&
          pageList.map((section) => (
            <SubscriptionSection
              key={section._id}
              section={section}
              mobile={mobile}
              tablet={tablet}
            />
          ))}
        {isActive && !loadingSubscriptionOffer && (
          <StyledSubscriptionNotificationFooter
            html={notification.replace('${PRICE}', subscriptionOfferData.data.offers[0][0].price)}
            Tag="div"
          />
        )}
      </div>
    );
  }
}

export default connectToStores(SubscriptionView);
