import styled from 'styled-components';

import {Device} from '../../constants';

import SubscriptionHero from './SubscriptionHero';

const StyledSubscriptionPatternWrapper = styled.div`
  position: relative;
  top: 0;
  bottom: 0;
  width: 100%;

  .hero-image {
    position: absolute;
    width: auto;
    top: 0;
    bottom: 0;
    ${SubscriptionHero} {
      max-height: 100%;
    }
  }

  @media screen and ${Device.mobileL} {
    display: flex;
    flex-direction: column;
  }
`;

export default StyledSubscriptionPatternWrapper;
