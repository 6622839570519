import {Modal, ModalProps, Text, VisuallyHidden} from 'epic-fortnite-react';
import React from 'react';
import styled from 'styled-components';

type StyledRiftTourModalContentProps = {
  $maxWidth?: number;
};

interface RiftTourModalProps extends ModalProps {
  maxWidth?: number;
}

const StyledRiftTourModalContent = styled.div<StyledRiftTourModalContentProps>`
  filter: drop-shadow(0px 0px 16px rgba(0, 0, 0, 0.15));
  margin: auto;
  max-width: ${({$maxWidth}) => ($maxWidth ? `${$maxWidth}px` : 'none')};
  position: relative;
  width: 100vw;

  @media (min-width: 600px) {
    padding: 64px;
  }
`;

const StyledRiftTourModalCloseButton = styled.button`
  appearance: none;
  background-color: rgba(0, 0, 0, 0.4);
  border: 0;
  height: 32px;
  width: 32px;
  padding: 0;
  position: fixed;
  text-align: center;
  top: 24px;
  right: 24px;
  z-index: 99;

  &.focus-visible {
    box-shadow: inset 0px 0px 0px 4px #000;
  }
`;

export const RiftTourModal = ({maxWidth, ...props}: RiftTourModalProps) => {
  return (
    <Modal {...props}>
      <StyledRiftTourModalCloseButton onClick={props.onDismiss}>
        <>
          <VisuallyHidden>Close</VisuallyHidden>
          <Text aria-hidden="true" color="#fff" size="lg">
            X
          </Text>
        </>
      </StyledRiftTourModalCloseButton>
      <StyledRiftTourModalContent $maxWidth={maxWidth}>{props.children}</StyledRiftTourModalContent>
    </Modal>
  );
};
