import {ClickableElement, Text} from 'epic-fortnite-react';
import React from 'react';
import styled from 'styled-components';
import {Payload, trackEvent} from '../../utils/eventTracking';

interface CardSectionProps {
  cardBackgroundColor?: string;
  cards: SimpleCard[];
}

interface SimpleCard {
  image?: string;
  title?: string;
  body?: string;
  href?: string;
  altText?: string;
  eventTracking: Payload;
  cardBackgroundColor?: string;
  isExternal?: boolean;
  numCards: number;
}

const StyledSimpleCard = styled(ClickableElement)<{numCards: number}>`
  overflow: hidden;
  margin: 0;

  &:hover {
    img {
      transform: scale(1.05);
    }
  }

  &:focus {
    box-shadow: 0 0 0 4px #1db8f3;
  }

  @media (max-width: 450px) {
    display: inline-flex;
    flex: unset;
    max-width: initial;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.15);
    img {
      width: 30%;
    }
  }

  @media (min-width: 1200px) {
    ${({numCards}) =>
      numCards % 4 === 0
        ? `
          flex: 1 0 24%;
          max-width: 24%;
        `
        : `
          flex: 1 0 32%;
          max-width: 32%;
        `};
  }
`;

const StyledSimpleCardImage = styled.img`
  transition: transform 0.2s ease-out;
  width: 100%;
`;

const StyledCardContent = styled.div<{bgColor?: string}>`
  background-color: ${({bgColor}) => bgColor ?? '#fff'};
  padding: 24px;
  position: relative;
  z-index: 1;

  @media only screen and (max-width: 450px) {
    width: 100%;
    text-align: left;
    padding: 12px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    span {
      font-size: 16px;
    }
  }

  @supports (-webkit-touch-callout: none) {
    span {
      white-space: nowrap;
    }
  }
`;

const SimpleCard = ({
  image,
  title,
  body,
  href,
  eventTracking,
  altText,
  cardBackgroundColor,
  isExternal,
  numCards,
}: SimpleCard) => (
  <StyledSimpleCard
    numCards={numCards}
    href={href}
    external={isExternal}
    onClick={() => {
      trackEvent({
        eventAction: eventTracking?.eventAction,
        eventCategory: eventTracking?.eventCategory,
        eventLabel: eventTracking?.eventLabel,
        eventValue: eventTracking?.eventValue,
        interactionType: eventTracking?.interactionType,
      });
    }}>
    {image && <StyledSimpleCardImage src={image} alt={altText} />}
    <StyledCardContent bgColor={cardBackgroundColor}>
      {title && (
        <Text as="h2" color="#000" size="lg">
          {title}
        </Text>
      )}
      {body && (
        <Text as="p" color="#000">
          {body}
        </Text>
      )}
    </StyledCardContent>
  </StyledSimpleCard>
);

const StyledSimpleCardSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media (min-width: 600px) {
    display: grid;
    grid-template-columns: repeat(2, minmax(auto, 1fr));
  }

  @media (min-width: 1200px) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
  }
`;

const SimpleCardSection = ({cardBackgroundColor, cards}: CardSectionProps) => (
  <StyledSimpleCardSection>
    {cards.map((card, index) => (
      <SimpleCard
        key={index}
        {...card}
        cardBackgroundColor={cardBackgroundColor}
        numCards={cards.length}
      />
    ))}
  </StyledSimpleCardSection>
);

export default SimpleCardSection;
