import parse, {HTMLReactParserOptions} from 'html-react-parser';
import React, {CSSProperties} from 'react';

export interface HTMLReactParserOptionsHtmlTemplate extends HTMLReactParserOptions {
  trim: boolean;
}

export interface Props {
  Tag?: keyof JSX.IntrinsicElements;
  className?: string;
  html: string;
  options?: HTMLReactParserOptions;
  style?: CSSProperties;
}

/**
 * Replace template strings with actual values
 */
const HtmlTemplateComponent = ({Tag, className = '', html = '', options, style}: Props) => {
  if (!html) return null;
  const reactElement = parse(html, options);
  if (Tag) {
    return (
      <Tag className={className} style={style}>
        {reactElement}
      </Tag>
    );
  }
  return <>{reactElement}</>;
};

export default HtmlTemplateComponent;
