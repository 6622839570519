import PropTypes from 'prop-types';
import React, {useState} from 'react';
import VisibilitySensor from 'react-visibility-sensor';

import AnimateString from '../common/AnimateString';

const Items = ({data}) => {
  const [visible, setVisibility] = useState(false);
  const {title, paragraph, bgImg, items = [], images = []} = data;
  let list = null;
  //this needs to go away and deleted forever
  if (items && items.length > 0) {
    list = items.map((image, index) => {
      return <img key={`item-${index}`} src={image} alt={`item-${index}`} />;
    });
  }
  //moving foward with this implementation for next season
  if (images && images.length > 0) {
    list = images.map((item, index) => {
      return <img key={`item-${index}`} src={item.image} alt={item.imageAlt} />;
    });
  }

  return (
    <VisibilitySensor
      onChange={(visibility) => setVisibility(visibility)}
      active={!visible}
      partialVisibility
      minTopValue={50}>
      <div className={`battlepass-items ${visible ? 'visible' : ''}`}>
        <div className="items-content">
          <AnimateString
            key="bling-h1"
            text={title}
            type="h1"
            speed={1.5}
            delay={0.3}
            autoAlpha={0}
            yPos={100}
            minTop={20}
            scaleFrom={1}
            staggerDelay={0}
          />
          {paragraph ? (
            <AnimateString
              key={`bling-p`}
              text={paragraph}
              type="p"
              speed={1.5}
              delay={0.3}
              autoAlpha={0}
              yPos={100}
              minTop={20}
              scaleFrom={1}
              staggerDelay={0}
            />
          ) : null}
        </div>
        {bgImg && <img className="item-bg-image" src={bgImg} alt="items assets" />}
        <div className="items-images">{list}</div>
      </div>
    </VisibilitySensor>
  );
};

Items.propTypes = {
  data: PropTypes.object,
};
export default Items;
