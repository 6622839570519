import {getLocale} from '@epic-core/common';
import React from 'react';
import {Link, useLocation} from 'react-router-dom';
import styled from 'styled-components';

import {generateRouteHref, generateRouteTo} from '../../../utils/route';

export type ClickableElementProps = {
  children?: React.ReactNode;
  href?: string;
  external?: boolean;
  onClick?: () => void;
  to?: string;
  type?: 'button' | 'reset' | 'submit';
  style?: React.CSSProperties;
};

const StyledClickableElement = styled.div`
  appearance: none;
  background: transparent;
  border: 0;
  display: block;
  margin: 0;
  min-width: 0;
  padding: 0;
  position: relative;
  text-align: inherit;
  // white-space: nowrap; /* preventing text from wrapping when using Box */

  &[disabled] {
    opacity: 0.5;
    pointer-events: none;
  }
`;

export const ClickableElement = ({
  children,
  href,
  external,
  onClick,
  to,
  type,
  ...props
}: ClickableElementProps) => {
  const location = useLocation();
  const locale = getLocale();
  const commonProps = {
    onClick,
  };

  return to ? (
    <StyledClickableElement
      as={Link}
      to={generateRouteTo(to, location, locale)}
      {...(external && {target: '_blank'})}
      {...commonProps}
      {...props}>
      {children}
    </StyledClickableElement>
  ) : href ? (
    <StyledClickableElement
      as="a"
      href={generateRouteHref(href, location, locale)}
      {...(external && {target: '_blank'})}
      {...commonProps}
      {...props}>
      {children}
    </StyledClickableElement>
  ) : onClick || type ? (
    <StyledClickableElement as="button" type={type} {...commonProps} {...props}>
      {children}
    </StyledClickableElement>
  ) : (
    <StyledClickableElement {...props}>{children}</StyledClickableElement>
  );
};
