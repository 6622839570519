import {getLocale} from '@epic-core/common';
import {datasource} from 'alt-utils/lib/decorators';
import {seamlessBootstrap, seamlessImmutable} from 'epic-alt-utils';
import alt from 'epic-fortnite-react/alt';
import Immutable from 'seamless-immutable';

import CmsStoreActions from '../actions/CmsStoreActions';
import CmsStoreSource from '../sources/CmsStoreSource';

@datasource(CmsStoreSource)
@seamlessImmutable
class CmsStore {
  constructor() {
    this.on('error', (error) => {
      console.error(error);
    });
    this.on('bootstrap', seamlessBootstrap.bind(this, null));
    this.state = Immutable({
      pages: {},
    });
    this.bindActions(CmsStoreActions);
  }

  onGetCmsData(slug) {
    if (this.state.pages[slug]?.data) return;

    this.getInstance().getCmsData(getLocale(), slug);
    this.setState(
      this.state.merge({
        pages: {
          ...this.state.pages,
          [slug]: {
            loading: true,
            data: undefined,
            error: null,
          },
        },
      })
    );
  }

  onGetCmsDataSuccess(response) {
    const key = response.data._slug;
    this.setState(
      this.state.merge({
        pages: {
          ...this.state.pages,
          [key]: {
            loading: false,
            data: response.data,
            error: null,
          },
        },
      })
    );
  }

  onGetCmsDataFailure(error) {
    console.error(`failed to fetch cms page ${error}`);
    const {response, config} = error;
    const statusCode = response?.status ?? 500;

    // option 1: fetch slug from the HTTP error response
    let slug = response?.data?.slug;
    if (!slug) {
      // option 2: fetch slug from the error request
      const regex = /(?:cms\/)(?<request_slug>.*)/;
      const matches = regex.exec(config?.url);
      const extractedUrlSlug = matches?.groups?.request_slug;
      if (extractedUrlSlug) {
        slug = extractedUrlSlug;
      } else {
        throw new Error(
          `CmsStore.onGetCmsDataFailure: unable to extract slug from request url: ${config?.url}`
        );
      }
    }
    this.setState(
      this.state.merge({
        pages: {
          ...this.state.pages,
          [slug]: {
            loading: false,
            data: undefined,
            error: {statusCode},
          },
        },
      })
    );
  }
}

export default alt.createStore(CmsStore, 'CmsStore');
