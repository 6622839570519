import React from 'react';
import {Container, Grid, GridColumn, Stack, Text, TextLink} from 'epic-fortnite-react';
import {CoreSection} from './CoreSection';

import {trackEvent, Payload} from '../../utils/eventTracking';

type Link = {
  ctaLink: string;
  ctaString: string;
  disabled?: boolean;
  isCtaLinkExternal?: boolean;
  eventTracking: Payload;
};

type LinkItems = {
  title: string;
  subtitle: string;
  links: Link[];
};

export const LinkSection = ({coreSectionProps, linkItems}) => {
  return (
    <CoreSection coreSectionProps={coreSectionProps}>
      <Container maxWidth={1600} size="med">
        <Grid breakpoint={900} gap="2xl">
          {linkItems.map((linkItem: LinkItems, index: number) => {
            return (
              <GridColumn key={index} span={1 / 2}>
                <Stack gap="lg">
                  {linkItem.title && (
                    <Text align="center" as="h2" size="xl">
                      {linkItem.title}
                    </Text>
                  )}
                  {linkItem.subtitle && (
                    <Text align="center" as="p" size="med" transform="uppercase">
                      {linkItem.subtitle}
                    </Text>
                  )}

                  <Stack align="center" gap="med">
                    {linkItem.links.map((link: Link, index: number) => {
                      return (
                        <TextLink
                          key={index}
                          href={link?.ctaLink}
                          external={link?.isCtaLinkExternal}
                          onClick={() =>
                            trackEvent({
                              eventAction: link?.eventTracking?.eventAction,
                              eventCategory: link?.eventTracking?.eventCategory,
                              eventLabel: link?.eventTracking?.eventLabel,
                              eventValue: link?.eventTracking?.eventValue,
                              interactionType: link?.eventTracking?.interactionType,
                            })
                          }>
                          {link?.ctaString}
                        </TextLink>
                      );
                    })}
                  </Stack>
                </Stack>
              </GridColumn>
            );
          })}
        </Grid>
      </Container>
    </CoreSection>
  );
};
