import styled from 'styled-components';

const ArchiveName = styled.h2`
  color: black;
  margin-top: 1rem;

  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &&& {
    line-height: 100%;
  }
`;

export default ArchiveName;
