import styled, {css} from 'styled-components';

interface Props {
  position?: string;
  background?: string;
  reversed?: boolean;
  clipPath?: string;
}

const layouts = {
  top: css`
    clip-path: polygon(100% 100%, 0% 100%, 0% 0%, 70% 81%, 66% 48%, 100% 95%);
    bottom: calc(100% - 2px);
  `,
  bottom: css`
    top: calc(100% - 2px);
    clip-path: polygon(0% 0%, 100% 0%, 100% 5%, 24% 90%, 28% 55%, 0% 100%);
  `,
};

const SpikeDivider = styled.div<Props>`
  height: 65px;
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  z-index: 1;
  ${({clipPath}) => (clipPath ? `clip-path: ${(props) => clipPath};` : null)}

  ${({position = 'top'}) => layouts[position]}
    background: ${({background}) => background};
  ${({reversed}) => (reversed ? 'transform: rotateY(180deg);' : null)}
`;

export default SpikeDivider;
