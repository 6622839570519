import {generateRouteTo} from 'epic-fortnite-react';
import {getMatchGuide, isMatchGuideMenu} from 'epic-fortnite-react/utils/FortniteUtils';
import PropTypes from 'prop-types';
import React from 'react';
import {Link, withRouter} from 'react-router-dom';

import GuideMenuItem from './GuideMenuItem';

class GuideSubMenu extends React.Component {
  static propTypes = {
    menus: PropTypes.array,
    guides: PropTypes.array,
    path: PropTypes.string,
    match: PropTypes.object,
    locale: PropTypes.string,
  };

  render() {
    const {menus, guides, path, match, location, locale} = this.props;
    return (
      <div className="sub-menu-wrap">
        {menus.map((menu, index) => {
          const {link, items} = menu;
          const page = getMatchGuide({guides, id: link});
          const active = isMatchGuideMenu(match.url, page._slug);

          return (
            <div key={page._id} className={active ? 'sub-menu active' : 'sub-menu'}>
              {items ? (
                <div className="title">{page._title}</div>
              ) : (
                <Link
                  to={generateRouteTo(`/play-guide/${path}/${page._slug}`, location, locale)}
                  className="title">
                  {page._title}
                </Link>
              )}
              {items && (
                <GuideMenuItem
                  match={match}
                  items={items}
                  guides={guides}
                  path={`${path}/${page._slug}`}
                  locale={locale}
                />
              )}
            </div>
          );
        })}
      </div>
    );
  }
}

export default withRouter(GuideSubMenu);
