import styled from 'styled-components';

import {breakpoints} from '../../../../constants/Breakpoints';

const HalfMaskDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  color: white;
  position: absolute;
  bottom: 15%;

  @media ${breakpoints.tablet} {
    position: relative;
    bottom: unset;
  }

  @media ${breakpoints.mobileL} {
    position: absolute;
    bottom: 15%;
  }
`;

export default HalfMaskDetailsWrapper;
