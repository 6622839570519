import PropTypes from 'prop-types';
import React, {useState} from 'react';
import VisibilitySensor from 'react-visibility-sensor';

import AnimateString from '../common/AnimateString';

const XpProgression = ({data}) => {
  const [visible, setVisibility] = useState(false);
  const {title, paragraph, image} = data;
  return (
    <VisibilitySensor
      onChange={(visibility) => setVisibility(visibility)}
      active={!visible}
      partialVisibility
      minTopValue={200}>
      <div className={`battlepass-xp-progression ${visible ? 'visible' : ''}`}>
        <div className="xpPregression-content-wrapper">
          <div className="xpPregression-content">
            {title ? (
              <AnimateString
                key="XpPregression-h1"
                text={title}
                type="h1"
                speed={1.5}
                delay={0.3}
                autoAlpha={0}
                yPos={100}
                minTop={20}
                scaleFrom={1}
                staggerDelay={0}
              />
            ) : null}
            {paragraph ? (
              <AnimateString
                key="XpPregression-p"
                text={paragraph}
                type="p"
                speed={1.5}
                delay={0.3}
                autoAlpha={0}
                yPos={100}
                minTop={20}
                scaleFrom={1}
                staggerDelay={0}
              />
            ) : null}
          </div>
        </div>
        <div className="xpProgression-image-container">
          <img className="xpPregression-image" src={image} alt="xp Pregression" />
        </div>
      </div>
    </VisibilitySensor>
  );
};

XpProgression.propTypes = {
  data: PropTypes.object,
};
export default XpProgression;
