import PropTypes from 'prop-types';
import React from 'react';
import VideoJS from 'react-video-wrapper';

import Message from '../message/Message';

class Video extends React.Component {
  render() {
    const {media, gif, autoPlay, onMediaLoad, onMediaLoadError} = this.props;
    let videoSrc = '';

    media[0].video_info.variants.forEach((v) => {
      if (v.url.indexOf('.mp4') > -1) {
        videoSrc = v.url;
      }
    });

    const videoJs = typeof videojs !== 'undefined';

    return (
      <div className="AdaptiveMedia">
        {videoJs ? (
          <VideoJS
            src={videoSrc}
            controls={!gif}
            autoPlay={gif || autoPlay}
            loop={gif}
            className="video"
            muted={true}
            onLoadedData={onMediaLoad}
            onError={onMediaLoadError}>
            <Message code="epic.fortnite.twitter.videosupport" />
          </VideoJS>
        ) : (
          <video
            src={videoSrc}
            controls={!gif}
            autoPlay={gif || autoPlay}
            loop={gif}
            className="video"
            muted={true}
            onLoadedData={onMediaLoad}
            onError={onMediaLoadError}>
            <Message code="epic.fortnite.twitter.videosupport" />
          </video>
        )}
        {gif ? (
          <div className="AdaptiveMedia-badge">
            <Message code="epic.fortnite.twitter.gif" />
          </div>
        ) : null}
      </div>
    );
  }
}

Video.propTypes = {
  media: PropTypes.array,
  gif: PropTypes.bool,
  onMediaLoad: PropTypes.func,
  onMediaLoadError: PropTypes.func,
  autoPlay: PropTypes.bool,
};

Video.defaultProps = {
  media: [],
  gif: false,
};

Video.displayName = 'Video';

export default Video;
