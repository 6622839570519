import styled from 'styled-components';

import {breakpoints} from '../../../../constants/Breakpoints';

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  bottom: 10rem;

  @media ${breakpoints.mobileL} {
    bottom: 5rem;
  }

  &&&& h1 {
    font-size: 6rem;
    margin-bottom: 2rem;
  }

  @media ${breakpoints.tablet} {
    &&&& h1 {
      font-size: 4.8rem;
    }
  }

  @media ${breakpoints.mobileL} {
    &&&& h1 {
      font-size: 3.6rem;
    }
  }
`;

export default InfoWrapper;
