import {connectToStores} from 'epic-alt-utils';
import Error404 from 'epic-fortnite-react/components/error/Error404';
import Error500 from 'epic-fortnite-react/components/error/Error500';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React from 'react';
import {withRouter} from 'react-router';

import CosplayArchiveActions from '../../actions/CosplayArchiveActions';
import MetaTags from '../../components/common/MetaTags';
import ArchiveView from '../../components/cosplay/ArchiveView';
import {
  ArchiveOrderSelect,
  ArchivePageHeader,
} from '../../components/cosplay/ArchiveView/components';
import ArchivePageLayout from '../../components/cosplay/components/ArchivePageLayout';
import ArchivePageWrapper from '../../components/cosplay/components/ArchivePageWrapper';
import ArchiveSubHeader from '../../components/cosplay/components/ArchiveSubHeader';
import Container from '../../components/cosplay/components/Container';
import SpikeDivider from '../../components/cosplay/components/SpikeDivider';
import StyleBaseline from '../../components/cosplay/components/StyleBaseline';
import OverviewHeroSection from '../../components/cosplay/OverviewHeroSection';
import LoadScreen from '../../components/loadScreen/LoadScreen';
import {CosplayArchiveStore} from '../../stores';
import {escape} from '../../utils/HtmlEscape';

const fetchData = (props) => {
  const {location} = props;
  const {page, ordering} = queryString.parse(location.search);
  CosplayArchiveActions.getArchivePages({
    page: escape(page) ?? 1,
    ordering: escape(ordering) ?? 'date-descending',
  });
};

@connectToStores
class CosplayArchiveView extends React.Component {
  static propTypes = {
    archive: PropTypes.object,
    location: PropTypes.object,
    history: PropTypes.object,
  };

  static getStores() {
    return [CosplayArchiveStore];
  }

  static getInitialActions() {
    return [fetchData];
  }

  static getPropsFromStores() {
    return {
      archive: CosplayArchiveStore.getState(),
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.search !== this.props.location.search) {
      fetchData(this.props);
    }
  }

  render() {
    const store = this.props.archive || null;
    const {location, history} = this.props;
    const {page} = queryString.parse(location.search);
    const pageNumber = escape(page) ?? 1;

    if (store.error === true) {
      return <Error500 />;
    } else if (store.error) {
      switch (store.error?.statusCode) {
        case 404:
          return <Error404 />;
        case 500:
          return <Error500 />;
        default:
          console.error('unrecognised error', store.error);
          return <Error500 />;
      }
    }

    const {data} = store;
    if (!data || !data.hero || !data.header || !data.subheader) {
      return <LoadScreen show={true} />;
    }

    const {hero, header, subheader, orderingLabels} = data;
    const items = data.pages[pageNumber];

    const totalItems = data._totalItemsCount;
    const pageSize = data._pageSize;

    return (
      <StyleBaseline>
        <MetaTags pageModel={data} />
        <OverviewHeroSection {...hero} />
        <ArchivePageWrapper>
          <SpikeDivider position="top" background="white" />
          <Container>
            <ArchivePageLayout>
              <ArchivePageHeader>
                <div>
                  <h1>{header}</h1>
                  <ArchiveSubHeader>{subheader}</ArchiveSubHeader>
                </div>
                <ArchiveOrderSelect history={history} orderingLabels={orderingLabels} />
              </ArchivePageHeader>
              <ArchiveView
                difficultyLabels={orderingLabels}
                loading={store.loading}
                pages={items ?? []}
                totalItems={totalItems}
                pageSize={pageSize}
              />
            </ArchivePageLayout>
          </Container>
        </ArchivePageWrapper>
      </StyleBaseline>
    );
  }
}

export default withRouter(CosplayArchiveView);
