import {getLocale} from '@epic-core/common';
import {SlickWrapper} from 'epic-react-common';
import React from 'react';

import Container from '../components/Container';
import DifficultyInfoWithMaskType from '../components/DifficultyInfoWithMaskType';
import DownloadButton from '../components/DownloadButton';
import Grid from '../components/Grid';
import Section from '../components/Section';
import {SocialShareProps} from '../components/SocialShare';
import SpikeDivider from '../components/SpikeDivider';
import {Background, DownloadCTA, ResponsiveImage as IResponsiveImage} from '../types';

import {CarouselImage, CarouselWrapper} from './components';

export interface MaskCarouselSectionProps {
  accent: string;
  background: Background;
  description: string;
  difficulty: string;
  download: string;
  header: string;
  images: IResponsiveImage[];
  time: string;
  type: string;
  cta: DownloadCTA;
  alternate?: boolean;
  socialShare?: SocialShareProps['socialShare'];
}

const sliderOptions = {
  adaptiveHeight: false,
  infinite: true,
  centerMode: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  dots: false,
  autoplay: false,
  dotsClass: 'carousel-dots bottom',
  arrows: true,
  draggable: true,
  swipeToSlide: true,
  swipe: true,
  touchMove: true,
  fade: true,
  pauseOnHover: false,
};

const MaskCarouselSection = ({
  accent,
  background,
  description,
  difficulty,
  cta,
  header,
  images = [],
  time,
  type,
  alternate = false,
  socialShare = undefined,
}: MaskCarouselSectionProps) => {
  const locale = getLocale();

  const button = cta ? (
    <DownloadButton
      id={`${cta.text}-${cta.link}`}
      link={cta.link}
      text={cta.text}
      background={accent}
      locale={locale}
    />
  ) : undefined;
  return (
    <Section gradient={background}>
      <SpikeDivider position="top" background={background?.from} />
      <Container>
        <Grid>
          <DifficultyInfoWithMaskType
            accent={accent}
            description={description}
            difficulty={difficulty}
            name={header}
            time={time}
            type={type}
            button={button}
            socialShare={socialShare}
          />
          <CarouselWrapper alternate={alternate}>
            {images.length > 0 ? (
              <SlickWrapper options={sliderOptions}>
                {images.map((image, index) => (
                  <CarouselImage
                    key={`${index}:${image?.desktopImage}`}
                    desktopSrc={image?.desktopImage}
                    tabletSrc={image?.tabletImage}
                    mobileSrc={image?.mobileImage}
                    alt={image?.altText}
                  />
                ))}
              </SlickWrapper>
            ) : null}
          </CarouselWrapper>
        </Grid>
      </Container>
    </Section>
  );
};

export default MaskCarouselSection;
