import {getLocale} from '@epic-core/common';
import {DOMNode, Element} from 'html-react-parser';
import {domToReact} from 'html-react-parser';
import React from 'react';
import {Link, useLocation} from 'react-router-dom';
import {Location} from 'history';

import ButtonLink from './ButtonLink';
import DownloadButtonTemplate from './DownloadButtonTemplate';
import HtmlTemplateComponent, {
  HTMLReactParserOptionsHtmlTemplate,
  Props,
} from './HtmlTemplateComponent';
import {generateRouteTo} from '../../utils/route';

const defaultOptions = (location: Location, locale: string): HTMLReactParserOptionsHtmlTemplate => {
  return {
    replace: (dom: DOMNode) => {
      const element = dom as Element;
      if (!element?.attribs) {
        return;
      }
      const {name, attribs, children} = element;
      if (attribs.class === 'downloadButton') {
        return (
          <DownloadButtonTemplate
            className="btn btn-primary btn-hover-transform"
            rawCta={children && domToReact(children, defaultOptions(location))}
          />
        );
      }
      if (attribs.class === 'buttonLink') {
        return (
          <ButtonLink href={attribs.href}>
            {children && domToReact(children, defaultOptions(location))}
          </ButtonLink>
        );
      }
      if (name === 'a' && /^\//.test(attribs.href)) {
        return (
          <Link to={generateRouteTo(attribs.href, location, locale)} className={attribs.class}>
            {children && domToReact(children, defaultOptions(location))}
          </Link>
        );
      }
    },
    trim: true,
  };
};

const HtmlTemplate = ({options, ...props}: Props) => {
  const location = useLocation();
  const locale = getLocale();
  return <HtmlTemplateComponent {...props} options={options || defaultOptions(location, locale)} />;
};

export default HtmlTemplate;
