import styled from 'styled-components';

import {breakpoints} from '../../../constants/Breakpoints';

const HeroTitle = styled.h1`
  &&& {
    color: ${({color = undefined}) => color ?? 'white'};
    font-size: 5em;
    line-height: 1;
    margin-bottom: 0;
  }

  @media ${breakpoints.tablet} {
    &&& {
      font-size: 7.5rem;
    }
  }

  @media ${breakpoints.mobileL} {
    &&& {
      font-size: 2.7em;
      margin-bottom: 2rem;
      margin-left: 1rem;
      margin-right: 1rem;
    }
  }
`;

export default HeroTitle;
