import HtmlTemplate from 'epic-fortnite-react/components/common/HtmlTemplate';
import styled from 'styled-components';

const StyledSubscriptionNotificationFooter = styled(HtmlTemplate)`
  text-align: center;
  background-color: #e9e9e9;
  padding: 0.6em;
  &&& p {
    margin: 0px;
    font-family: 'OpenSans';
    font-size: 0.8em;
  }
`;

export default StyledSubscriptionNotificationFooter;
