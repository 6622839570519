import styled from 'styled-components';

import {breakpoints} from '../../../../constants/Breakpoints';

const HeroTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  font-size: 1.2em;
  text-align: left;
  margin-left: 3rem;
  z-index: 1;

  &&& h2 {
    line-height: 1.1;
  }

  @media ${breakpoints.tablet} {
    position: absolute;
    left: 0;
  }

  @media ${breakpoints.mobileL} {
    left: unset;
    bottom: 10rem;
    text-align: center;
    margin-left: 0;
  }
`;

export default HeroTextWrapper;
