import {ContextMessage as Message} from '@epic-core/localization';
import {connectToStores} from 'epic-alt-utils';
import HttpErrorScreen from 'epic-fortnite-react/components/error/HttpErrorScreen';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React from 'react';

import EulaActions from '../../actions/UefnEulaActions';
import CmsLoadScreen from '../../components/loadScreen/CmsLoadScreen';
import MetaTags from '../../components/common/MetaTags';
import LoadScreen from '../../components/loadScreen/LoadScreen';

import {CmsStore, UefnEulaStore} from '../../stores';

import {fetchCmsData} from '../../utils/CmsStoreUtils';

/**
 * UEFN Eula View
 */

const cmsUrlPattern = 'uefneula';

@connectToStores
class UefnEulaView extends React.Component {
  static propTypes = {
    uefnEulaStore: PropTypes.object,
    location: PropTypes.object,
    cmsStore: PropTypes.object,
  };

  static getStores() {
    return [UefnEulaStore, CmsStore];
  }

  static getPropsFromStores() {
    return {
      uefnEulaStore: UefnEulaStore.getState(),
      cmsStore: CmsStore.getState(),
    };
  }

  static getInitialActions() {
    return [EulaActions.fetchEula, fetchCmsData(cmsUrlPattern)];
  }

  state = {
    showDrop: false,
  };

  toggleDropdown = () => {
    this.setState({
      showDrop: !this.state.showDrop,
    });
  };

  showEula(eulaLink) {
    EulaActions.showEula(eulaLink);
  }

  UNSAFE_componentWillMount() {
    const query = queryString.parse(this.props.location.search) || '';
    const eulaParam = query.eula;
    if (eulaParam && this.props.eulaStore.isValidEula(eulaParam)) {
      EulaActions.showEula(eulaParam);
    }
  }

  getEulaLinks() {
    const {uefnEulaStore} = this.props;
    const eulaLinks = [];
    const {currentEula, availableEulas} = uefnEulaStore;
    for (const eula of availableEulas) {
      if (uefnEulaStore[eula] === currentEula) continue;
      const onClickEula = this.showEula.bind(this, eula);
      const eulaKey = uefnEulaStore[eula];
      const eulaMessage = `epic.fortnite.eula.${eulaKey}`;
      eulaLinks.push(
        <a role="presentation" key={eulaKey} onClick={onClickEula}>
          {' '}
          {/*eslint-disable-line*/}
          <Message code={eulaMessage} />
        </a>
      );
    }
    return <div className="container-contents">{eulaLinks}</div>;
  }

  render() {
    const {uefnEulaStore, cmsStore} = this.props;

    const uefnEulaData = cmsStore.pages.uefneula.data;

    if (cmsStore.pages[cmsUrlPattern].loading) {
      return <CmsLoadScreen page={cmsStore?.pages[cmsUrlPattern]} />;
    }

    if (!cmsStore?.pages[cmsUrlPattern] || !cmsStore.pages[cmsUrlPattern].data) {
      return <HttpErrorScreen statusCode={404} />;
    }

    if (cmsStore.pages[cmsUrlPattern].error) {
      return <HttpErrorScreen statusCode={cmsStore.pages[cmsUrlPattern].error?.statusCode} />;
    }

    const eula = uefnEulaStore.getCurrentEula();
    let eulaContainer = null;

    if (eula) {
      let btnStyle = 'dropDown-container ';
      const currentEulaMessage = `epic.fortnite.eula.${uefnEulaStore.currentEula}`;
      if (this.state.showDrop) {
        btnStyle = 'dropDown-container active';
      }
      const eulaLinks = this.getEulaLinks();
      eulaContainer = (
        <div className="site-block eula-container">
          <div className="site-block-header">
            <h1 className="site-block-title pull-left eula-title uefn">{eula.title}</h1>
            {uefnEulaStore.showDropdown && (
              <div
                role="presentation"
                className="eula-selector-container pull-right"
                onClick={this.toggleDropdown}>
                <div className="eula-button-container">
                  <div className={btnStyle}>
                    {eulaLinks}
                    <div className="btn btn-xs btn-primary">
                      <Message code={currentEulaMessage} />
                      <i className="icon-right-arrow" />
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="clearfix" />
          </div>
          <div className="site-block-body">
            <div
              className="site-block-body-text eula-text"
              dangerouslySetInnerHTML={{__html: eula.body}}
            />
          </div>
        </div>
      );
    }
    return (
      <div className="eula-view-container">
        <div className="light-bg">
          <MetaTags pageModel={uefnEulaData} />
          <div id="eulaContainer" className="container eula">
            {eulaContainer}
          </div>
        </div>
        <LoadScreen show={uefnEulaStore.loading} />
      </div>
    );
  }
}

export default UefnEulaView;
