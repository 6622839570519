import {Text, TextProps} from 'epic-fortnite-react';
import {useResponsiveBreakpoints} from 'epic-fortnite-react/hooks/useResponsiveBreakpoints';
import React from 'react';

export const RiftTourHeading = ({...props}: TextProps) => {
  const size = useResponsiveBreakpoints([{'2xl': 600}, {'3xl': 601}]);

  return (
    <Text
      as="h2"
      balance={false}
      color="#010821"
      lineLength="xs"
      size={size.breakSize}
      transform="uppercase"
      {...props}
    />
  );
};
