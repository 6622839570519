import {useMotionValue, useSpring, useTransform} from 'framer-motion';
import React from 'react';
import root from 'window-or-global';
import {motion} from 'framer-motion';
import styled, {css} from 'styled-components';
import {Device} from '../../../constants';
import {HeroSlide} from './Types';

interface GradientBackgroundProps {
  bkImage?: string;
  active: boolean;
  season?: string;
}

interface HeroBackgroundProps {
  items: HeroSlide[];
  activeSlide: number;
  isVisible: boolean;
  mobile: boolean;
  tablet: boolean;
  seasonKey: string;
}

const GradientBackground = styled.div<GradientBackgroundProps>`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: ${(props) => (props.active ? 1 : 0)};
  perspective: 600px;
  overflow: hidden;

  ${(props) =>
    props.bkImage
      ? css`
          background-image: url(${props.bkImage});
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
        `
      : null}

  &:after,
    &:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    height: 10vh;
    z-index: 1;
  }

  @media screen and (max-width: 940px) {
    padding-top: 15%;
  }
  @media screen and ${Device.mobileL} {
    padding-top: 3%;
    background-position: 50% -70px;
    &:after,
    &:before {
      height: 5vh;
    }
  }
`;

const Heroes = {
  ironman: css`
    font-size: 16vw !important;
    @media screen and ${Device.mobileL} {
      font-size: 4em !important;
    }
  `,
  storm: css`
    font-size: 20vw !important;
  `,
  groot: css`
    font-size: 20vw !important;
  `,
  mystique: css`
    font-size: 16vw !important;
  `,
  thor: css`
    font-size: 25vw !important;
  `,
  doom: css`
    font-size: 18vw !important;
  `,
  shehulk: css`
    font-size: 16vw !important;
  `,
  hero: css`
    font-size: 16vw !important;
  `,
  poseidon: css`
    font-size: 10vw !important;
    @media screen and ${Device.mobileL} {
      font-size: 12vw !important;
    }
  `,
  aphrodite: css`
    font-size: 16vw !important;
    @media screen and ${Device.mobileL} {
      font-size: 20vw !important;
    }
  `,
  hades: css`
    font-size: 8vw !important;
  `,
  aries: css`
    font-size: 8vw !important;
    @media screen and ${Device.mobileL} {
      font-size: 12vw !important;
    }
  `,
  hera: css`
    font-size: 12vw !important;
    @media screen and ${Device.mobileL} {
      font-size: 16vw !important;
    }
  `,
  hercules: css`
    font-size: 14vw !important;
    @media screen and ${Device.mobileL} {
      font-size: 16vw !important;
    }
  `,
  athena: css`
    font-size: 10vw !important;
    @media screen and ${Device.mobileL} {
      font-size: 10vw !important;
    }
  `,
  unidentified: css`
    font-size: 10vw !important;
    @media screen and ${Device.mobileL} {
      font-size: 14vw !important;
    }
  `,
};

const HeroName = styled(({...rest}) => <motion.h1 {...rest} />)`
  color: #fff;
  font-style: italic;
  white-space: nowrap;
  line-height: 1 !important;
  width: 100%;
  text-align: center;
  ${(props) => Heroes[props.keyId]}

  @media screen and (max-width: 940px) {
    top: 50%;
  }
`;

const HeroBackground = ({
  seasonKey,
  items,
  activeSlide,
  isVisible,
  mobile,
  tablet,
}: HeroBackgroundProps) => {
  const xPos = useMotionValue(root ? root.innerWidth / 2 : 1000);
  const x = useTransform(xPos, [0, root ? root.innerWidth : 2000], [-30, 30], {clamp: true});
  const xSpring = useSpring(x, {damping: 120});

  const handleMouse = (e) => {
    const {clientX, pageX} = e;
    if (!pageX && clientX) xPos.set(clientX); //x position within the element.
    if (pageX) xPos.set(pageX);
  };

  React.useEffect(() => {
    root.addEventListener('mousemove', handleMouse, true);
    return () => {
      root.removeEventListener('mousemove', handleMouse, true);
    };
  }, [root]);

  const displayHeroNameBackground = false;

  return (
    <>
      {items.map((item, index) => {
        const active = activeSlide === index && isVisible;
        return (
          <GradientBackground
            season={seasonKey}
            active={active}
            key={`background-${item.key}`}
            bkImage={item.slideBackground.desktopImage}>
            {displayHeroNameBackground && (
              <HeroName
                className={`heroName-${item.key}`}
                initial={{rotateY: 90, opacity: 0}}
                animate={active ? {opacity: 1, rotateY: 0} : {rotateY: -180, opacity: 0}}
                style={{x: active && !mobile && !tablet ? xSpring : 0}}
                transition={{
                  duration: 0.5,
                  delay: active ? 0.6 : 0,
                  ease: 'easeInOut',
                }}
                keyId={item.key}>
                {item.name}
              </HeroName>
            )}
          </GradientBackground>
        );
      })}
    </>
  );
};

export default HeroBackground;
