import {Stack, Text} from 'epic-fortnite-react';
import {CtaButton} from 'epic-fortnite-react/components/carousel/types';
import VideoOverlayContext from 'epic-fortnite-react/components/videooverlay/VideoOverlayContext';
import React from 'react';

import Background from '../../common/Background';
import FooterLogo from '../s6/FooterLogo';
import PlayVideo from '../s6/PlayVideo';
import PrimalCTAButton from '../s6/PrimalCTAButton';
import StyledBtnWrapper from '../s6/StyledBtnWrapper';
import {useVideoHeader, useVideoSize} from '../s6/useVideoHeader.hooks';

import Badge from './Badge';
import HeaderConent from './HeaderContent';
import HeaderContentWrapper from './HeaderContentWrapper';
import Logo from './Logo';
import MobileLogo from './MobileLogo';
import StyledTextWrapper from './StyledTextWrapper';
import StyledVideoHeaderC2s8 from './StyledVideoHeaderC2s8';

interface Data {
  logo: string;
  mobileLogo: string;
  badge?: string;
  bgImg: string;
  mobileBgImg: string;
  title?: string;
  title2?: string;
  title3?: string;
  title4?: string;
  videoId?: string;
  playText?: string;
  footerLogo?: string;
  footerAlt?: string;
  button: CtaButton;
  captionPos: string;
}

interface Props {
  className?: string;
  data: Data;
}

const VideoHeader = ({data, className}: Props) => {
  const {
    logo,
    mobileLogo,
    badge,
    bgImg,
    mobileBgImg,
    title,
    title2,
    title3,
    title4,
    videoId,
    playText,
    footerLogo,
    footerAlt,
    button,
    captionPos,
  } = data;
  const background = React.useMemo(
    () => ({
      type: 'image',
      desktopImage: bgImg,
      mobileImage: mobileBgImg,
    }),
    [bgImg, mobileBgImg]
  );

  const {setConfig} = React.useContext(VideoOverlayContext);
  const {playVideo, closeVideo, isPlay, show} = useVideoHeader();
  React.useEffect(() => {
    if (!setConfig) return;
    setConfig({
      videoID: videoId,
      isLauncher: false,
      play: isPlay,
      close: closeVideo,
      closeButton: true,
      show: isPlay,
    });
  }, [playVideo, closeVideo, isPlay]);
  const size = useVideoSize();

  const style = React.useMemo(() => ({width: size.width}), [size.width]);
  const contentClasses = show ? 'content active' : 'content';
  return (
    <StyledVideoHeaderC2s8
      className={`chapter2s8-header-wrapper ${className}`}
      style={style}
      captionPos={captionPos}>
      <Background className="header" background={background} breakPoint={425} />
      <HeaderContentWrapper>
        <HeaderConent className={contentClasses}>
          {show && (
            <div className="animated-content">
              <StyledTextWrapper>
                <Stack align="center" gap="med">
                  {logo && <Logo logo={logo} alt={title} />}
                  {mobileLogo && <MobileLogo logo={mobileLogo} alt={title} />}
                  {title2 && (
                    <Text as="h3" color="#fff" italic={true} size="lg" transform="uppercase">
                      {title2}
                    </Text>
                  )}
                  {badge && <Badge logo={badge} alt={title3} />}
                  {title4 && (
                    <Text as="h3" italic={true} size="2xl" transform="uppercase">
                      {title4}
                    </Text>
                  )}
                </Stack>
              </StyledTextWrapper>
            </div>
          )}
        </HeaderConent>
      </HeaderContentWrapper>
      {button && (
        <StyledBtnWrapper>
          <PrimalCTAButton ctaConfig={button} />
        </StyledBtnWrapper>
      )}
      {videoId && <PlayVideo playVideo={playVideo} playText={playText} videoId={videoId} />}
      {footerLogo && <FooterLogo src={footerLogo} alt={footerAlt} />}
    </StyledVideoHeaderC2s8>
  );
};

export default VideoHeader;
