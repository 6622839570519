import styled from 'styled-components';

const FeatureImageContainer = styled.div`
  position: relative;
  width: 100%;

  & img {
    width: 100%;
  }
`;

export default FeatureImageContainer;
