import styled from 'styled-components';

const HeroIcon = styled.img<{active: boolean}>`
  position: relative;
  width: 100%;
  filter: ${(props) => (props.active ? 'brightness(1.1)' : 'brightness(0.5)')};
  transition: all 300ms ease-in-out;
  z-index: 1;
  cursor: pointer;
  opacity: ${(props) => (props.active ? 1 : 0.8)};

  &:hover {
    filter: brightness(1.1);
    opacity: 1;
  }
`;

export default HeroIcon;
