import React from 'react';
import styled from 'styled-components';

export type StyledGridColumnProps = {
  $align?: 'center' | 'end' | 'start';
  $justify?: 'center' | 'end' | 'start';
  $span?: number;
};

export type GridColumnProps = {
  align?: 'center' | 'end' | 'start';
  justify?: 'center' | 'end' | 'start';
  children: React.ReactNode;
  span?: number;
};

const StyledGridColumn = styled.div<StyledGridColumnProps>`
  align-items: ${({$align}) =>
    $align === 'end'
      ? 'flex-end'
      : $align === 'start'
      ? 'flex-start'
      : $align === 'center'
      ? 'center'
      : 'initial'};
  display: ${({$span}) => ($span === 0 ? 'none' : 'flex')};
  flex-basis: ${({$span}) => ($span ? `${$span * 100}%` : 0)};
  flex-direction: column;
  flex-shrink: 1;
  justify-content: ${({$justify}) =>
    $justify === 'end'
      ? 'flex-end'
      : $justify === 'start'
      ? 'flex-start'
      : $justify === 'center'
      ? 'center'
      : 'initial'};
  min-width: 0;

  [data-display='block'] > &:not(:last-child):not(:empty) {
    margin-bottom: var(--grid-gapY);
  }
`;

export const GridColumn = ({
  align = 'center',
  children,
  justify = 'center',
  span,
}: GridColumnProps) => {
  return (
    <StyledGridColumn $align={align} $justify={justify} $span={span}>
      {children}
    </StyledGridColumn>
  );
};
