import capsize from 'capsize';
import React from 'react';
import BalanceText from 'react-balance-text';
import styled from 'styled-components';

import {useResponsiveBreakpoints} from '../../../hooks/useResponsiveBreakpoints';
import {font as fontTheme} from '../../../theme/theme';
import {FontSizes} from '../../../types/theme';

type TextAlign = 'center' | 'left' | 'right';
type TextFont = 'BurbankBigRegularBlack' | 'OpenSans';
type TextLineLength = 'xs' | 'sm' | 'med' | 'lg';
type TextLinkStyle = 'underline';
type TextTransform = 'capitalize' | 'lowercase' | 'uppercase';
type TextWeight = '100' | '200' | '300' | '400' | '500' | '600' | '700' | '800' | '900';

export type Capsize = {
  fontSize: string;
  lineHeight: string;
  cropBottom: string;
  cropTop: string;
};

export type StyledTextProps = {
  $align?: TextAlign;
  $capsize: Capsize | undefined;
  $color?: string;
  $font?: TextFont;
  $italic?: boolean;
  $lineLength?: TextLineLength;
  $linkStyle?: TextLinkStyle;
  $size?: FontSizes | string;
  $transform?: TextTransform;
  $weight?: TextWeight;
};

export type ResponsiveSize = {
  [key in FontSizes]?: number;
};

export type TextProps = {
  align?: TextAlign;
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'span';
  balance?: boolean;
  capsize?: Capsize;
  children: React.ReactNode;
  color?: string;
  font?: TextFont;
  icon?: React.ReactNode;
  id?: string;
  italic?: boolean;
  letterSpacing?: number;
  lineLength?: TextLineLength;
  linkStyle?: TextLinkStyle;
  size?: FontSizes | Array<ResponsiveSize>;
  transform?: TextTransform;
  truncate?: boolean | number;
  weight?: TextWeight;
};

const StyledText = styled.div<StyledTextProps>`
  color: ${({$color}) => $color};
  display: inline-block;
  font-family: ${({$font}) =>
    $font === 'BurbankBigRegularBlack' ? 'BurbankBigRegular-Black' : $font} !important;
  font-size: ${({$capsize}) => $capsize && `${$capsize.fontSize} !important`};
  font-style: ${({$italic}) => ($italic ? 'italic' : 'normal')};
  font-weight: ${({$weight}) => ($weight ? $weight : 'inherit')};
  letter-spacing: ${({$font, $size}) =>
    $font && $size && `${fontTheme[$font].fontSizes[$size].letterSpacing}em`};
  line-height: ${({$capsize}) => $capsize && `${$capsize.lineHeight} !important`};
  margin: 0 !important;
  margin-left: ${({$font}) => $font && `${fontTheme[$font].fontMetrics.trackingCrop}em !important`};
  max-width: ${({$lineLength}) =>
    $lineLength === 'xs'
      ? '16ch'
      : $lineLength === 'sm'
      ? '32ch'
      : $lineLength === 'med'
      ? '64ch'
      : $lineLength === 'lg'
      ? '80ch'
      : 'none'};
  min-width: 0;
  overflow-wrap: break-word;
  position: relative;
  text-align: ${({$align}) => $align};
  text-overflow: initial;
  text-transform: ${({$transform}) => $transform};
  transition: inherit;
  white-space: initial;

  &::before,
  &::after {
    content: '';
    display: table;
  }

  &::before {
    margin-bottom: ${({$capsize}) => $capsize?.cropTop};
  }

  &::after {
    margin-top: ${({$capsize}) => $capsize?.cropBottom};
  }

  a {
    // because of global ".fortnite-wrapper a" selector. refactor when able
    color: #0b85ec !important;
    font-family: inherit !important;
    font-size: inherit !important;
    font-weight: inherit !important;
    line-height: inherit !important;
    letter-spacing: inherit !important;
    text-decoration: ${({$linkStyle}) =>
      $linkStyle === 'underline' ? `underline !important` : 'none !important'};
  }

  html[lang='ja'],
  html[lang='ko'] & {
    word-break: keep-all;
  }

  html[lang='de'],
  html[lang='tr'],
  html[lang='pl'],
  & {
    word-break: break-word;
  }

  html[lang='ar'] & {
    font-family: NotoNaskhArabicUI, NotoNaskhArabic, sans-serif !important;
  }

  html[lang='ko'] & {
    font-family: aERIN, sans-serif !important;
  }

  html[lang='ja'] & {
    font-family: NotoSansCJKjp, sans-serif !important;
  }

  html[lang='ru'] & {
    font-family: BurbankBigCondensed-Black !important;
  }
`;

const StyledTextTruncate = styled.div<{truncate: number | boolean}>`
  -webkit-box-orient: ${({truncate}) => (truncate > 1 ? 'vertical' : 'initial')};
  -webkit-line-clamp: ${({truncate}) =>
    typeof truncate === 'number' && truncate > 1 ? truncate : 'initial'};
  display: ${({truncate}) => (truncate > 1 ? '-webkit-box' : 'block')};
  overflow: hidden;
  text-overflow: ${({truncate}) => (truncate > 1 ? 'initial' : 'ellipsis')};
  white-space: ${({truncate}) => (truncate > 1 ? 'initial' : 'nowrap')};
`;

export const Text = ({
  align,
  as,
  balance,
  children,
  color,
  font = 'BurbankBigRegularBlack',
  icon,
  id,
  italic,
  lineLength,
  linkStyle,
  size = 'med',
  transform,
  truncate,
  weight,
}: TextProps) => {
  const responsiveSizes = useResponsiveBreakpoints(typeof size === 'string' ? [{size: 0}] : size);

  const updatedSize = typeof size === 'string' ? size : responsiveSizes.breakSize;

  const getCapsizeStyles = (capHeight: number, lineGap: number) => {
    const fontMetrics = fontTheme[font].fontMetrics;
    if (capHeight && lineGap && fontMetrics) {
      return capsize({
        fontMetrics,
        capHeight,
        lineGap,
      });
    }
  };

  const capsizeSize = getCapsizeStyles(
    fontTheme[font].fontSizes[updatedSize].capHeight,
    fontTheme[font].fontSizes[updatedSize].lineGap
  );

  return (
    <StyledText
      $align={align}
      $capsize={
        capsizeSize && {
          fontSize: capsizeSize.fontSize,
          lineHeight: capsizeSize.lineHeight,
          cropBottom: capsizeSize['::after'].marginTop,
          cropTop: capsizeSize['::before'].marginBottom,
        }
      }
      $color={color}
      $font={font}
      $italic={italic}
      $lineLength={lineLength}
      $linkStyle={linkStyle}
      $size={updatedSize}
      $transform={transform}
      $weight={weight}
      as={as}
      id={id}>
      {truncate ? (
        <StyledTextTruncate truncate={truncate}>{children}</StyledTextTruncate>
      ) : balance ? (
        <BalanceText>{children}</BalanceText>
      ) : (
        <span>{children}</span>
      )}
      {icon}
    </StyledText>
  );
};
