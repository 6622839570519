import styled from 'styled-components';

import {Device} from '../../constants';

const StyledSubscriptionJoinStep = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  &:first-child {
    padding-left: 0px;
  }
  &:last-child {
    padding-right: 25px;
  }
  h6 {
    text-align: center;
    padding-left: 25px;
    a {
      color: rgb(247, 255, 4);
      font-weight: bold;
    }
  }

  @media screen and ${Device.mobileL} {
    margin-bottom: 2em;
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
    h6 {
      padding-left: 0;
    }
  }
`;

export default StyledSubscriptionJoinStep;
