import PropTypes from 'prop-types';
import React from 'react';

import Text from './Text';

class Quote extends React.Component {
  render() {
    const {data, linkProps} = this.props;

    return (
      <div className="QuoteTweet">
        <div className="QuoteTweet-innerContainer">
          <div className="QuoteTweet-originalAuthor">
            <a
              className="QuoteTweet-link"
              href={`https://twitter.com/${data.user.screen_name}/status/${data.id_str}`}
              {...linkProps}>
              <b>{data.user.name}</b>
              <span className="QuoteTweet-screenname">
                {' '}
                {'@'}
                {data.user.screen_name}
              </span>
            </a>
          </div>
          <div className="QuoteTweet-text">
            <Text data={data} />
          </div>
        </div>
      </div>
    );
  }
}

Quote.propTypes = {
  data: PropTypes.object,
  linkProps: PropTypes.object,
};

Quote.displayName = 'Quote';

export default Quote;
