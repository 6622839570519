import styled from 'styled-components';

import {Device} from '../../constants';

import StyledSubscriptionCoreTextWrapper from './StyledSubscriptionCoreTextWrapper';
import StyledSubscriptionHeading from './StyledSubscriptionHeading';
import SubscriptionHeroImage from './SubscriptionHero';

interface SubscriptionCoreImageDetailsInterface {
  imageLeft?: string;
  imageCenter?: string;
  imageRight?: string;
  imageSize?: string;
  imageMobile?: string;
  styleOverrides?: JSON;
  imagePosition?: string;
}

interface SubscriptionCoreProps {
  columnWidth: number;
  numColumns: string;
  imageDetails?: SubscriptionCoreImageDetailsInterface;
}

const StyledSubscriptionCoreContent = styled.div<{content: SubscriptionCoreProps}>`
  flex-basis: ${(props) => props.columnWidth || '100'}%;
  display: flex;

  .subscription-hero-left,
  .subscription-hero-right {
    position: relative;
    right: -15px;
    display: flex;
  }

  ${SubscriptionHeroImage} {
    width: 100%;
  }

  .subscription-hero-right {
    width: 100%;
    ${SubscriptionHeroImage} {
      right: -25px;
    }
  }

  @media screen and ${Device.laptop} {
    background-size: 100%;
    ${StyledSubscriptionCoreTextWrapper} {
      padding: 0;
      align-self: center;
      margin: 0 auto;
      ${StyledSubscriptionHeading} {
        font-size: 2.5em;
      }
    }
  }

  @media screen and ${Device.tablet} {
    ${StyledSubscriptionCoreTextWrapper} {
      padding: 0 1em;
      ${StyledSubscriptionHeading} {
        font-size: 2.2em;
      }
    }
  }

  @media screen and ${Device.mobileL} {
    background-size: contain;
  }
`;

export default StyledSubscriptionCoreContent;
