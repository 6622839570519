//eslint-disable-next-line
if (window && window.__webpack_asset_path) {
  __webpack_public_path__ = window['__webpack_asset_path']; //eslint-disable-line
}
import {setLocale} from '@epic-core/common';
import {SharedValueContext} from '@epic-core/hooks';
import {LocalizationContext, mergeLocalizationDataAltStore} from '@epic-core/localization';
import alt from 'epic-fortnite-react/alt';
import {bootstrapSharedValues, sharedStore} from 'epic-fortnite-shared-values';
import {ScrollToTopOnUpdate} from 'epic-react-common';
import Raven from 'raven-js';
import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import root from 'window-or-global';

import configureAxios from './axios/interceptors';
import Entry from './Entry';
import {AppSharedKeys} from './shared.keys';
import {decode} from './utils/encode';

if (process.env.NODE_ENV === 'production') {
  Raven.config('https://80be3f3a7c164c72b2d98af8169f3576@sentry.io/159034').install();
}

configureAxios();

const reactDomEle = root.document.getElementById('fortniteReactWrapper');

// only proceed if the react ele exists
if (reactDomEle) {
  const altStateEncoded = root.__epic_fortnite_state;
  const sharedValuesStateEncoded = root.__epic_fortnite_shared_values_state;
  if (altStateEncoded) {
    setLocale(root.__locale);

    const altState = decode(altStateEncoded);
    const sharedValuesState = decode(sharedValuesStateEncoded);
    root.__epic_fortnite_state = altState;
    root.__epic_fortnite_shared_values_state = sharedValuesState;
    alt.bootstrap(altState);
    const i18nContext = mergeLocalizationDataAltStore(altState.LocalizationStore);

    bootstrapSharedValues(sharedValuesState, AppSharedKeys).then(() => {
      ReactDOM.hydrate(
        <BrowserRouter forceRefresh={true} basename={root.appContext}>
          <SharedValueContext.Provider value={sharedStore}>
            <ScrollToTopOnUpdate>
              <LocalizationContext.Provider value={i18nContext}>
                <Entry languagePreference={root.navigator.languages} />
              </LocalizationContext.Provider>
            </ScrollToTopOnUpdate>
          </SharedValueContext.Provider>
        </BrowserRouter>,
        reactDomEle
      );
    });
  }
}
