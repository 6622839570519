import styled from 'styled-components';

import {Device} from '../../constants';

const SubscriptionHeroImage = styled.img`
  display: block;
  height: fit-content;
  align-self: flex-end;
  width: 100%;
  @media screen and ${Device.mobileL} {
    width: 110%;
    align-self: unset;
    height: unset;
  }
`;

export default SubscriptionHeroImage;
