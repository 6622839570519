import styled from 'styled-components';

interface Props {
  gradient?: {
    from?: string;
    to?: string;
  };
}
const HeroSection = styled.section<Props>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  min-height: 450px;
  position: relative;
  color: white;
  text-align: center;

  ${({gradient}) =>
    gradient?.from && gradient?.to
      ? `background: linear-gradient(180deg, ${gradient.from} 0%, ${gradient.to} 100%);`
      : null}
`;

export default HeroSection;
