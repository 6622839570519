import React from 'react';
import { Message } from '../../components';

/**
 * Loading Animation
 * reactProps {string} message
 * reactProps {string} customPositioning
 */
class LoadIndicator extends React.Component {
    render() {
        return (
            <div className="blog-load-indicator">
                <div className="container">
                    <div className="row text-center">
                        <div className="loading-icon" />
                        <h1 className="load-text">
                            <Message code="epic.blog.loading" />
                        </h1>
                    </div>
                </div>
            </div>
        );
    }
}

export default LoadIndicator;
