import {HttpErrorScreen, LoadScreen} from 'epic-fortnite-react';
import {getInitialCmsPageSharedValues, useCmsData, PageData} from 'epic-fortnite-shared-values';
import React from 'react';
import styled from 'styled-components';
import MobileLandingBaseplate from '../../components/baseplate/MobileLandingBaseplate';

import MetaTags from '../../components/common/MetaTags';
import MobileLandingHero from '../../components/mobile/MobileLandingHero';

const SLUG = 'mobile-landing-page';

export interface MobileLandingPageData extends PageData {
  hero: any;
  sections: any;
}

const StyledMobileLandingWrapper = styled.div`
  background-color: #fff;
`;

const MobileLanding = (props) => {
  const {pageData, isLoading, error} = useCmsData<MobileLandingPageData>(SLUG);
  const {hero, sections} = pageData;

  if (isLoading) {
    return <LoadScreen show />;
  }

  if (error) {
    return <HttpErrorScreen statusCode={error.response?.status ?? 500} />;
  }

  if (!pageData) {
    return <HttpErrorScreen statusCode={404} />;
  }

  return (
    <StyledMobileLandingWrapper>
      <MetaTags pageModel={pageData} />
      <MobileLandingHero data={hero} />
      <MobileLandingBaseplate blocks={sections?.blocks} />
    </StyledMobileLandingWrapper>
  );
};

MobileLanding.getInitialSharedValues = getInitialCmsPageSharedValues(SLUG);

export default MobileLanding;
