import styled from 'styled-components';

import {breakpoints} from '../../../constants/Breakpoints';

const DifficultyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    width: 100%; // Fix weird Safari bug causing random line breaks
  }

  @media ${breakpoints.mobileL} {
    grid-row: 2;
    margin-bottom: 3rem;

    h2 {
      max-width: 30rem;
    }
  }
`;

export default DifficultyWrapper;
