import React from 'react';

import {Redirect, RouteComponentProps} from 'react-router';
import queryString from 'query-string';

interface ExtractSlugLocaleRes {
  pageSlug: string;
  pageLocale: string | undefined;
}

// simplified version of extraxtSlugLocale to remove dependency on root.appContext via `getPath`
// to make testing easier
export function parseSlugAndLocaleFromUrlPath(pathname: string): ExtractSlugLocaleRes {
  const matches = new RegExp(/\/([a-zA-Z0-9]{0,2}(?:[-_][a-zA-Z0-9]{2,4}){0,2})(\/.*)?$/).exec(
    pathname
  );

  if (matches) {
    /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
    const [_, locale, slug] = matches;
    return {pageSlug: slug, pageLocale: locale};
  }

  return {pageSlug: pathname, pageLocale: undefined};
}

interface Props extends RouteComponentProps {
  basePath: string;
}

// TODO: do we need to add 301 redirect for SSR?
export function RewriteLocaleToQuery({basePath, location}: Props) {
  const {pageSlug, pageLocale} = parseSlugAndLocaleFromUrlPath(location.pathname);

  const qs = queryString.parse(location.search);
  if (!qs.lang && pageLocale) {
    qs.lang = pageLocale;
  }

  const pathname = `${basePath}${pageSlug}`;

  return (
    <Redirect
      to={{
        pathname,
        search: queryString.stringify(qs),
      }}
    />
  );
}
