import HtmlTemplate from 'epic-fortnite-react/components/common/HtmlTemplate';
import React from 'react';
import styled from 'styled-components';

import {Device} from '../../constants';

interface Props {
  content: string;
  className?: string;
  color?: string;
}

const Content = styled.div<Props>`
  ${({color}) => color && `color: ${color};`}
  padding: 0;
  max-width: 1200px;
  margin: 0 auto;
  && p {
    font-size: 1em;
    line-height: 1.2;
    padding: 0 2em;
  }
  @media only screen and ${Device.tablet} {
    && p {
      padding: 0;
    }
  }
`;

const ContentExtended = ({content, color, className, ...rest}: Props) => {
  return (
    <Content color={color} className={className}>
      <HtmlTemplate html={content} {...rest} />
    </Content>
  );
};

export default ContentExtended;
