import styled, {css} from 'styled-components';

import {Device} from '../../../constants';

const ContentBoxTitle = styled.h1`
  color: ${(props) => (props.color ? props.color : '#fff')};
  font-size: 3.5em !important;
  position: relative;
  z-index: 1;
  font-style: italic;
  transform: translateZ(1px);

  @media screen and ${Device.laptop} {
    font-size: 2em !important;
  }

  ${(props) =>
    props.smallText
      ? css`
          font-size: 2em !important;

          @media screen and ${Device.mobileM} {
            font-size: 1.5em !important;
          }
        `
      : null}
`;

export default ContentBoxTitle;
