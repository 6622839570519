import styled from 'styled-components';

import {breakpoints} from '../../../../constants/Breakpoints';

const ArchivePageHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr 25rem;

  @media ${breakpoints.mobileL} {
    display: block;
  }
`;

export default ArchivePageHeader;
