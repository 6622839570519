const HERORACE = {
  PAGE1: {
    heroName: '',
    currentPage: 0,
    itemsPerPage: 10,
    hasMore: true,
    isSuccess: true,
    leaderboard: [
      {
        level: 10,
        progressToNext: 100.0,
        accountId: 'ba5218b372d1466b81f095d05f99f3aa',
        date: 1470362870625,
        rank: 1,
        accountName: 'XxTHE_PATRIOTxX',
        twitch: 'xxthe_patriotxx',
      },
      {
        level: 10,
        progressToNext: 100.0,
        accountId: 'e83b530d5fda4074b8a9defd2edfcd64',
        date: 1470367037440,
        rank: 2,
        accountName: 'Beanid',
        twitch: '',
      },
      {
        level: 10,
        progressToNext: 100.0,
        accountId: 'd25e9e7aebe84a30bfc7940d1e6bd5a9',
        date: 1470436502791,
        rank: 3,
        accountName: 'BeCertified',
        twitch: 'be_certified',
      },
      {
        level: 10,
        progressToNext: 100.0,
        accountId: '3b23d8f72bc44c47bd7557a47af2f467',
        date: 1470556058174,
        rank: 4,
        accountName: 'Jimmy The Hat',
        twitch: '',
      },
      {
        level: 8,
        progressToNext: 81.26,
        accountId: 'b5b8274305934c7288b61390606aff45',
        date: 1470521408747,
        rank: 5,
        accountName: 'XxSyLissxX',
        twitch: 'dvs_xxsylissxx',
      },
      {
        level: 8,
        progressToNext: 76.81,
        accountId: '6ee8333f530247a2b8b5dedb49b2af04',
        date: 1470559571086,
        rank: 6,
        accountName: 'Amozzott24',
        twitch: 'amozzott24',
      },
      {
        level: 8,
        progressToNext: 65.81,
        accountId: 'e7878cd7c7f34535b7b47291275badc3',
        date: 1470626054342,
        rank: 7,
        accountName: 'CJforce',
        twitch: '',
      },
      {
        level: 8,
        progressToNext: 50.55,
        accountId: 'f9942cc3863b44a08fadde66d3b8831b',
        date: 1470606475931,
        rank: 8,
        accountName: 'xIII_Vamp_IIIx',
        twitch: '',
      },
      {
        level: 8,
        progressToNext: 27.44,
        accountId: '66450698d767450f90ecd19cb8544d7b',
        date: 1470616232504,
        rank: 9,
        accountName: 'Nick 06',
        twitch: '',
      },
      {
        level: 8,
        progressToNext: 21.84,
        accountId: '45b52f805be84628b6c8df99bca4a27f',
        date: 1470655730931,
        rank: 10,
        accountName: 'Mumfo83',
        twitch: '',
      },
    ],
  },
  PAGE2: {
    heroName: '',
    currentPage: 1,
    itemsPerPage: 10,
    hasMore: true,
    isSuccess: true,
    leaderboard: [
      {
        level: 8,
        progressToNext: 21.22,
        accountId: '73ff1196f7774acfb0f53ef003404eae',
        date: 1470705909996,
        rank: 11,
        accountName: 'Odoiel',
        twitch: '',
      },
      {
        level: 8,
        progressToNext: 20.32,
        accountId: '7437ee9091454b21ae6987ca9730001f',
        date: 1470700181321,
        rank: 12,
        accountName: 'Tragic_Astronaut',
        twitch: 'tragic_astronaut',
      },
      {
        level: 8,
        progressToNext: 13.54,
        accountId: 'cdbab89f8ff64a81b6f1de2ad0668002',
        date: 1470676330074,
        rank: 13,
        accountName: 'Sade5005',
        twitch: '',
      },
      {
        level: 8,
        progressToNext: 8.4,
        accountId: '857db0200d194ef0b8baa08156ab5688',
        date: 1470692448609,
        rank: 14,
        accountName: 'DIRTY-D55',
        twitch: '',
      },
      {
        level: 8,
        progressToNext: 6.23,
        accountId: '0408d60d9d0b44898ea215a1e344cd14',
        date: 1470697730601,
        rank: 15,
        accountName: 'Uzamaki89',
        twitch: '',
      },
      {
        level: 8,
        progressToNext: 6.2,
        accountId: '93086ab6054747889fb6f0e6d216aef9',
        date: 1470521809284,
        rank: 16,
        accountName: 'Haseo.Xaron',
        twitch: 'haseoxaron',
      },
      {
        level: 8,
        progressToNext: 5.49,
        accountId: 'f3b533dfb68d4049b44e099336a34541',
        date: 1470685367903,
        rank: 17,
        accountName: 'Cesar DeWitt',
        twitch: '',
      },
      {
        level: 8,
        progressToNext: 2.07,
        accountId: '3973969092b64906b3cc2ef6f6d0eb78',
        date: 1470661152317,
        rank: 18,
        accountName: 'Tenebryis',
        twitch: '',
      },
      {
        level: 7,
        progressToNext: 93.12,
        accountId: '5befe1c05cba40049ab4e2461b51b2c5',
        date: 1470409694038,
        rank: 19,
        accountName: null,
        twitch: '',
      },
      {
        level: 7,
        progressToNext: 70.3,
        accountId: 'e1c0af7bfa51474a8658c68dde110e3c',
        date: 1470596175844,
        rank: 20,
        accountName: null,
        twitch: '',
      },
    ],
  },
  PAGE3: {
    heroName: '',
    currentPage: 2,
    itemsPerPage: 10,
    hasMore: true,
    isSuccess: true,
    leaderboard: [
      {
        level: 7,
        progressToNext: 66.56,
        accountId: '5a0fa74a0b714929aab77f27d27797d2',
        date: 1470632690405,
        rank: 21,
        accountName: 'Danteplus',
        twitch: '',
      },
      {
        level: 7,
        progressToNext: 66.15,
        accountId: '09589b67f6064791b18c6bfdd455f91a',
        date: 1470586141090,
        rank: 22,
        accountName: 'xsuyenx',
        twitch: 'xsuyenx',
      },
      {
        level: 7,
        progressToNext: 48.27,
        accountId: '10e525c95e024eeb9e0d586e54ec2dbf',
        date: 1470681383148,
        rank: 23,
        accountName: 'AlecStark',
        twitch: '',
      },
      {
        level: 7,
        progressToNext: 42.64,
        accountId: '8f03a586701d47178424118aabe0b536',
        date: 1470551976809,
        rank: 24,
        accountName: 'ThETruueAlphaa',
        twitch: '',
      },
      {
        level: 7,
        progressToNext: 40.75,
        accountId: '0f2f4fa034344434aa20bc47f0d4af00',
        date: 1470589629302,
        rank: 25,
        accountName: 'Chewiee_77',
        twitch: 'chewiee77',
      },
      {
        level: 7,
        progressToNext: 39.79,
        accountId: '388ee501868e4d77a754a013fdd39d7e',
        date: 1470681493129,
        rank: 26,
        accountName: 'High-Will',
        twitch: 'lucky4thewin',
      },
      {
        level: 7,
        progressToNext: 38.5,
        accountId: '47e16cc2bfdc449b80a4448c4b7b5c8b',
        date: 1470540697961,
        rank: 27,
        accountName: 'Darth_blood',
        twitch: '',
      },
      {
        level: 7,
        progressToNext: 36.41,
        accountId: 'b0dcf1ee58594e26a93defd5310751a8',
        date: 1470431872932,
        rank: 28,
        accountName: 'Hypnotick',
        twitch: '',
      },
      {
        level: 7,
        progressToNext: 31.56,
        accountId: '94fd4ae0e927470180db3a500c499761',
        date: 1470357333126,
        rank: 29,
        accountName: 'FHVortex',
        twitch: '',
      },
      {
        level: 7,
        progressToNext: 29.61,
        accountId: '7a5ef7aacf7142bdaffc1d08a5b7d793',
        date: 1470455444716,
        rank: 30,
        accountName: 'Scubba420',
        twitch: '',
      },
    ],
  },
  PAGE4: {
    heroName: '',
    currentPage: 3,
    itemsPerPage: 10,
    hasMore: true,
    isSuccess: true,
    leaderboard: [
      {
        level: 7,
        progressToNext: 29.03,
        accountId: '635ebc389bf1453c9a7a5bc6502590ed',
        date: 1470506367400,
        rank: 31,
        accountName: 'BrownCoconut',
        twitch: '',
      },
      {
        level: 7,
        progressToNext: 28.58,
        accountId: '8a6fa0438fa34828be91d3bda6b31271',
        date: 1470292069122,
        rank: 32,
        accountName: 'xEyepatchx',
        twitch: '',
      },
      {
        level: 7,
        progressToNext: 19.65,
        accountId: '9d8102fea1bc42128311823b6f635367',
        date: 1470640758065,
        rank: 33,
        accountName: null,
        twitch: '',
      },
      {
        level: 7,
        progressToNext: 17.22,
        accountId: 'c25b1ab3b8244ffe9708c1e14cfb2fa9',
        date: 1470402700721,
        rank: 34,
        accountName: 'N/A',
        twitch: '',
      },
      {
        level: 7,
        progressToNext: 16.02,
        accountId: 'ba5be2d17b424b8ea6813bf84648e15f',
        date: 1470659343557,
        rank: 35,
        accountName: 'Aphostle',
        twitch: 'aphostle',
      },
      {
        level: 7,
        progressToNext: 10.28,
        accountId: '13ffecbc981a48b78e7760587d5559a7',
        date: 1470591927289,
        rank: 36,
        accountName: 'Exkath',
        twitch: 'exkath_',
      },
      {
        level: 7,
        progressToNext: 9.91,
        accountId: 'f56ee9bd91a5487f8827ef53a1d3d414',
        date: 1470697394053,
        rank: 37,
        accountName: 'Tupac301',
        twitch: '',
      },
      {
        level: 7,
        progressToNext: 9.24,
        accountId: 'aad40d35c7424995ba68752b78f53f73',
        date: 1470666893656,
        rank: 38,
        accountName: 'Amos163',
        twitch: '',
      },
      {
        level: 7,
        progressToNext: 8.23,
        accountId: '42f8e25134484c74ab452ea21003ebf8',
        date: 1470594277303,
        rank: 39,
        accountName: 'Qvazie',
        twitch: '',
      },
      {
        level: 7,
        progressToNext: 6.45,
        accountId: '7299adaf5c5a40c3a0d723c7f1904625',
        date: 1470680496895,
        rank: 40,
        accountName: 'Rellos247',
        twitch: '',
      },
    ],
  },
  PAGE5: {
    heroName: '',
    currentPage: 4,
    itemsPerPage: 10,
    hasMore: true,
    isSuccess: true,
    leaderboard: [
      {
        level: 7,
        progressToNext: 5.08,
        accountId: '23c98ba3604c44cf8c6511d968d544d0',
        date: 1470629504031,
        rank: 41,
        accountName: 'Shanaj22',
        twitch: '',
      },
      {
        level: 7,
        progressToNext: 4.15,
        accountId: '00ce127f430a4473aeeab6234434903b',
        date: 1470581957191,
        rank: 42,
        accountName: 'Th3Menac3',
        twitch: 'yougotpunked',
      },
      {
        level: 7,
        progressToNext: 3.46,
        accountId: '7bb0dc04b8da464982bc99dd0eac8b5a',
        date: 1470539875911,
        rank: 43,
        accountName: 'dlxlegend',
        twitch: 'dlxlegend',
      },
      {
        level: 7,
        progressToNext: 1.72,
        accountId: 'a09a634f87104688b0c954c5feeb4ded',
        date: 1470712520398,
        rank: 44,
        accountName: null,
        twitch: '',
      },
      {
        level: 7,
        progressToNext: 1.02,
        accountId: 'fc431bf5878245b1b24d567e76eb4eea',
        date: 1470698413511,
        rank: 45,
        accountName: 'hagges09',
        twitch: 'hagges09',
      },
      {
        level: 7,
        progressToNext: 0.83,
        accountId: '6d18a208ea384d339bb8be874f3113fe',
        date: 1470701172510,
        rank: 46,
        accountName: 'Dread89',
        twitch: 'dreadfb89',
      },
      {
        level: 6,
        progressToNext: 97.96,
        accountId: '52e452b0fb634021b62fe59a710c0231',
        date: 0,
        rank: 47,
        accountName: 'Mondo_Bobo',
        twitch: '',
      },
      {
        level: 6,
        progressToNext: 94.38,
        accountId: '7e479a264f7b41ae8c74146b834256bb',
        date: 1470510828836,
        rank: 48,
        accountName: 'MiSsSpArTaN420',
        twitch: 'missspartan420',
      },
      {
        level: 6,
        progressToNext: 93.94,
        accountId: 'a810a0476473467b8a365875ddc21a76',
        date: 0,
        rank: 49,
        accountName: 'I-Protec',
        twitch: '',
      },
      {
        level: 6,
        progressToNext: 89.49,
        accountId: 'ceb57f68c2424bcba67241f1b9f1cc94',
        date: 1470529938415,
        rank: 50,
        accountName: 'DBTDane',
        twitch: 'dbtteamdane',
      },
    ],
  },
  PAGE6: {
    heroName: '',
    currentPage: 5,
    itemsPerPage: 10,
    hasMore: true,
    isSuccess: true,
    leaderboard: [
      {
        level: 6,
        progressToNext: 87.67,
        accountId: '07b440d0389f40079bb677ec403f7a8f',
        date: 1470260217581,
        rank: 51,
        accountName: 'Kurprinz',
        twitch: 'telespiele',
      },
      {
        level: 6,
        progressToNext: 87.34,
        accountId: 'f70d5b4cf5324034896888909df426af',
        date: 1470428804780,
        rank: 52,
        accountName: 'In5anityDefined',
        twitch: 'in5anitydefined',
      },
      {
        level: 6,
        progressToNext: 85.94,
        accountId: 'e7c772930ef14211a36c2454aca12654',
        date: 1470518450586,
        rank: 53,
        accountName: null,
        twitch: '',
      },
      {
        level: 6,
        progressToNext: 82.91,
        accountId: 'cca392b1c58a470c9108b30312be116d',
        date: 1470535766490,
        rank: 54,
        accountName: 'Eveton',
        twitch: '',
      },
      {
        level: 6,
        progressToNext: 82.41,
        accountId: '2be3506ce30141e4813efe320ab6fc21',
        date: 0,
        rank: 55,
        accountName: 'Crim_Reafer',
        twitch: '',
      },
      {
        level: 6,
        progressToNext: 81.69,
        accountId: 'ec468d48699643d8a42a25b429ba632d',
        date: 1470524121527,
        rank: 56,
        accountName: 'Furys-revenge420',
        twitch: '',
      },
      {
        level: 6,
        progressToNext: 81.41,
        accountId: '3dbafacc757c4d669fa7c9b06dff14d7',
        date: 0,
        rank: 57,
        accountName: 'Icon709',
        twitch: 'icon709',
      },
      {
        level: 6,
        progressToNext: 79.56,
        accountId: 'cab1eb52ee524ab1ab91b59735695f73',
        date: 1470548027142,
        rank: 58,
        accountName: 'Aranion Elenloth',
        twitch: '',
      },
      {
        level: 6,
        progressToNext: 77.19,
        accountId: 'ced33c0c70154ffeb1508506ccc42f37',
        date: 1470444852164,
        rank: 59,
        accountName: 'mavericktnl',
        twitch: 'mavnl',
      },
      {
        level: 6,
        progressToNext: 75.79,
        accountId: '74edbeb47f3f4dc59e550d72bb65d768',
        date: 1470447195017,
        rank: 60,
        accountName: 'Strived',
        twitch: 'rumoredmurder',
      },
    ],
  },
  PAGE7: {
    heroName: '',
    currentPage: 6,
    itemsPerPage: 10,
    hasMore: true,
    isSuccess: true,
    leaderboard: [
      {
        level: 6,
        progressToNext: 74.79,
        accountId: '189076795ced44ef80078dc907b4d883',
        date: 1470539449216,
        rank: 61,
        accountName: 'Korr1999',
        twitch: '',
      },
      {
        level: 6,
        progressToNext: 72.4,
        accountId: '2d38f5e816d54593bd209e2305e8f75a',
        date: 1470405198337,
        rank: 62,
        accountName: 'Paholainen3',
        twitch: '',
      },
      {
        level: 6,
        progressToNext: 72.15,
        accountId: '43c2e90bf94947f2a241cfc5154eeaea',
        date: 1470533637167,
        rank: 63,
        accountName: 'Dankmuffinman',
        twitch: 'dankmuffinman',
      },
      {
        level: 6,
        progressToNext: 71.95,
        accountId: 'c86c8bff90664c6aa0651bc2bf7ef0b0',
        date: 0,
        rank: 64,
        accountName: 'Junkieman',
        twitch: '',
      },
      {
        level: 6,
        progressToNext: 71.18,
        accountId: '68f2b02dc06746be82acba57b822ea3c',
        date: 1470528263773,
        rank: 65,
        accountName: null,
        twitch: '',
      },
      {
        level: 6,
        progressToNext: 66.76,
        accountId: 'efa3f13dc757488fabbcb2551721ac01',
        date: 0,
        rank: 66,
        accountName: 'DarthChoda',
        twitch: '',
      },
      {
        level: 6,
        progressToNext: 65.22,
        accountId: '21d9156c608f4b86a73a89c041613085',
        date: 0,
        rank: 67,
        accountName: 'Serene Devil',
        twitch: 'serenedevil',
      },
      {
        level: 6,
        progressToNext: 64.01,
        accountId: 'cb4be484cf25436f9f2ee3a79ebbc071',
        date: 1470461474059,
        rank: 68,
        accountName: 'kaydeniro',
        twitch: 'kaydeniro',
      },
      {
        level: 6,
        progressToNext: 63.64,
        accountId: '117c3041ccb54238b2e8697221455e67',
        date: 0,
        rank: 69,
        accountName: 'DigIteL_DeviL',
        twitch: 'digitel_devil',
      },
      {
        level: 6,
        progressToNext: 62.43,
        accountId: '5a08bb230dfd45d4bb2f6df189084b41',
        date: 0,
        rank: 70,
        accountName: 'Gzylo',
        twitch: '',
      },
    ],
  },
  PAGE8: {
    heroName: '',
    currentPage: 7,
    itemsPerPage: 10,
    hasMore: true,
    isSuccess: true,
    leaderboard: [
      {
        level: 6,
        progressToNext: 61.85,
        accountId: '77850d5512b84c5c88c7c2d7486b9586',
        date: 1470515407921,
        rank: 71,
        accountName: 'Machtkampf13',
        twitch: '',
      },
      {
        level: 6,
        progressToNext: 60.81,
        accountId: 'e55d60f8c05f41b29687367591fef198',
        date: 1470454083696,
        rank: 72,
        accountName: 'Claudio_Matias',
        twitch: '',
      },
      {
        level: 6,
        progressToNext: 59.94,
        accountId: '5b4fdce0fbc44e5db82b14d1d6856d84',
        date: 1470530698784,
        rank: 73,
        accountName: 'CrzyTownisBack',
        twitch: '',
      },
      {
        level: 6,
        progressToNext: 59.83,
        accountId: 'a75c1a52ad1c4690bca0f91fc6a6bfab',
        date: 1470390363613,
        rank: 74,
        accountName: 'Jinqz',
        twitch: '',
      },
      {
        level: 6,
        progressToNext: 57.27,
        accountId: '0de07ddeb82b4c88924a253c74f95aa2',
        date: 0,
        rank: 75,
        accountName: 'Vishnu Guardian',
        twitch: 'vishnuguardian92',
      },
      {
        level: 6,
        progressToNext: 57.13,
        accountId: '096eaeb1768140f291446b14d713ac16',
        date: 1470416715122,
        rank: 76,
        accountName: 'Bros1981',
        twitch: '',
      },
      {
        level: 6,
        progressToNext: 55.73,
        accountId: 'b287c4fe3ee2413fa9f3ab49485c02bd',
        date: 0,
        rank: 77,
        accountName: 'Deadangel 7',
        twitch: '',
      },
      {
        level: 6,
        progressToNext: 55.19,
        accountId: 'dd25020a0f78471780e021999426587c',
        date: 1470540198026,
        rank: 78,
        accountName: 'ViRiS100_',
        twitch: '',
      },
      {
        level: 6,
        progressToNext: 55.05,
        accountId: '1401740704684fd5b433d355d2cc8ad8',
        date: 1470525652086,
        rank: 79,
        accountName: 'UncleJoon',
        twitch: '',
      },
      {
        level: 6,
        progressToNext: 54.61,
        accountId: '6151b492c6da45d49383bb9fa80ec5ba',
        date: 0,
        rank: 80,
        accountName: 'L1onheart13',
        twitch: '',
      },
    ],
  },
  PAGE9: {
    heroName: '',
    currentPage: 8,
    itemsPerPage: 10,
    hasMore: true,
    isSuccess: true,
    leaderboard: [
      {
        level: 6,
        progressToNext: 54.05,
        accountId: 'b6d36fcecf4b4cfa8bfb71689aaf85e2',
        date: 1470534975068,
        rank: 81,
        accountName: null,
        twitch: '',
      },
      {
        level: 6,
        progressToNext: 53.75,
        accountId: '6c5443a9f25f41dab0db2061c9c470a3',
        date: 1470506647820,
        rank: 82,
        accountName: 'Zemuns_',
        twitch: '',
      },
      {
        level: 6,
        progressToNext: 53.38,
        accountId: '4ed2508ca9474d728b036db8294b0114',
        date: 1470364695818,
        rank: 83,
        accountName: 'azolver',
        twitch: '',
      },
      {
        level: 6,
        progressToNext: 51.37,
        accountId: '59e59b4375da41e19efea1af6849692c',
        date: 1470537540646,
        rank: 84,
        accountName: null,
        twitch: '',
      },
      {
        level: 6,
        progressToNext: 50.46,
        accountId: '9c697d38e59e485e81db1dfc757c9bdf',
        date: 1470550612922,
        rank: 85,
        accountName: 'Alytrinix',
        twitch: 'alytrinix',
      },
      {
        level: 6,
        progressToNext: 49.92,
        accountId: '4de0236ebd814ae98b996409f7e1a705',
        date: 0,
        rank: 86,
        accountName: 'MsBeef89',
        twitch: '',
      },
      {
        level: 6,
        progressToNext: 49.89,
        accountId: '4b385e6c982848c5b0dd8238108c6486',
        date: 0,
        rank: 87,
        accountName: 'Talnoy79',
        twitch: '',
      },
      {
        level: 6,
        progressToNext: 49.59,
        accountId: '94e0aa3fd3474578ab395e00fff02b2d',
        date: 1470535637099,
        rank: 88,
        accountName: null,
        twitch: '',
      },
      {
        level: 6,
        progressToNext: 49.11,
        accountId: 'c3cb87a98e7d459f9edbeb6ecd81c5c9',
        date: 1470484121244,
        rank: 89,
        accountName: 'Potatogem',
        twitch: '',
      },
      {
        level: 6,
        progressToNext: 48.77,
        accountId: '0f83b5d074ce424590d877824c867dae',
        date: 0,
        rank: 90,
        accountName: 'Kobin04',
        twitch: '',
      },
    ],
  },
  PAGE10: {
    heroName: '',
    currentPage: 9,
    itemsPerPage: 10,
    hasMore: false,
    isSuccess: true,
    leaderboard: [
      {
        level: 6,
        progressToNext: 46.63,
        accountId: '5d2e0f759a24458987feed7b21215daa',
        date: 0,
        rank: 91,
        accountName: 'NoraNeko817',
        twitch: '',
      },
      {
        level: 6,
        progressToNext: 46.62,
        accountId: 'd73ae9f38b4d4a4685213957bdb525f1',
        date: 1470442671633,
        rank: 92,
        accountName: 'A_Valkyries_Kiss',
        twitch: 'a_valkyries_kiss',
      },
      {
        level: 6,
        progressToNext: 46.5,
        accountId: '059135a6b7494334bdbaaa9ed29b1471',
        date: 0,
        rank: 93,
        accountName: 'Лютый пельмень',
        twitch: '',
      },
      {
        level: 6,
        progressToNext: 46.3,
        accountId: 'c068ab58c438410aac07d46a83db7988',
        date: 0,
        rank: 94,
        accountName: null,
        twitch: '',
      },
      {
        level: 6,
        progressToNext: 44.4,
        accountId: 'a9c5f7cb307447a2944e4104605eb9c2',
        date: 1470304186504,
        rank: 95,
        accountName: 'JPeebs',
        twitch: 'jaypeebee',
      },
      {
        level: 6,
        progressToNext: 44.3,
        accountId: 'ea577dcb5f46404588d1430e903f6753',
        date: 0,
        rank: 96,
        accountName: null,
        twitch: '',
      },
      {
        level: 6,
        progressToNext: 43.93,
        accountId: 'f9c62cadee864a16b64ba54a8f9637e5',
        date: 1470459282361,
        rank: 97,
        accountName: 'X-INSECTICON-X',
        twitch: '',
      },
      {
        level: 6,
        progressToNext: 43.43,
        accountId: '111a69134ad64e9096580b5765aac8f9',
        date: 1470438113860,
        rank: 98,
        accountName: null,
        twitch: '',
      },
      {
        level: 6,
        progressToNext: 42.14,
        accountId: 'cf47a3f5501740f7a3034cd0cf7c8867',
        date: 1470537258499,
        rank: 99,
        accountName: 'Sneaky Snake 007',
        twitch: '',
      },
      {
        level: 6,
        progressToNext: 42.13,
        accountId: '14e03d300994482dba8343c0d36df2c5',
        date: 0,
        rank: 100,
        accountName: 'Octosnap',
        twitch: '',
      },
    ],
  },
};

export default HERORACE;
