import {getLocale} from '@epic-core/common';
import {generateRouteTo} from 'epic-fortnite-react';
import {connectToStores} from 'epic-alt-utils';
import PropTypes from 'prop-types';
import React from 'react';
import {Route, Redirect, Switch} from 'react-router';
import {withRouter} from 'react-router';

import {PlayGuideStore} from '../../stores';

import PlayGuideBasicView from './PlayGuideBasicView';
import PlayGuideHomeView from './PlayGuideHomeView';

@connectToStores
class PlayGuideView extends React.Component {
  static propTypes = {
    playGuideStore: PropTypes.object,
    location: PropTypes.object,
  };

  static getStores() {
    return [PlayGuideStore];
  }

  static getPropsFromStores() {
    return {
      playGuideStore: PlayGuideStore.getState(),
    };
  }

  render() {
    const {playGuideStore} = this.props;
    const location = this.props.location;
    const search = location.search || '';

    const locale = getLocale();

    if (!playGuideStore.playGuide) {
      return (
        <Redirect
          to={generateRouteTo(
            {
              pathname: '/',
              search,
            },
            location,
            locale
          )}
        />
      );
    }
    return (
      <Switch>
        <Route exact path="/play-guide" component={PlayGuideHomeView} />
        <Route exact path="/play-guide/basic">
          <Redirect
            to={generateRouteTo(
              {
                pathname: '/play-guide/basic/loot',
                search,
              },
              location,
              locale
            )}
          />
        </Route>
        <Route exact path="/play-guide/basic/item">
          <Redirect
            to={generateRouteTo(
              {
                pathname: '/play-guide/basic/loot',
                search,
              },
              location,
              locale
            )}
          />
        </Route>
        <Route exact path="/play-guide/play">
          <Redirect
            to={generateRouteTo(
              {
                pathname: '/play-guide/play/ui',
                search,
              },
              location,
              locale
            )}
          />
        </Route>
        <Route exact path="/play-guide/fortnite-school">
          <Redirect
            to={generateRouteTo(
              {
                pathname: '/play-guide/fortnite-school/tab-0',
                search,
              },
              location,
              locale
            )}
          />
        </Route>
        <Route path="/play-guide/:folder/tab-:activeTab" component={PlayGuideBasicView} />
        <Route path="/play-guide/:folder/:category?/:slug?" component={PlayGuideBasicView} />
      </Switch>
    );
  }
}
export default withRouter(PlayGuideView);
