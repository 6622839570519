import styled from 'styled-components';

interface Props {
  $$color?: string;
}

export default styled.h3<Props>`
  &&&& {
    color: ${({$color}) => $color || '#34dfe8'};
  }
  text-align: left;
  @media screen and (max-width: 1200px) {
    text-align: center;
  }
`;
