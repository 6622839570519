import React from 'react';
import styled from 'styled-components';

import {Inline, Text} from '../';
import {AllOrNone} from '../AllOrNone';

type CloseProps = AllOrNone<{closeLabel: string; onClose: () => void}>;

type AlertTone = 'critical' | 'neutral' | 'success' | 'warning';

export type StyledAlertProps = {
  $tone?: AlertTone;
};

export type AlertProps = {
  children: React.ReactNode;
  id?: string;
  tone?: AlertTone;
} & CloseProps;

const StyledAlert = styled.div<StyledAlertProps>`
  background-color: ${({$tone}) =>
    $tone === 'critical' ? '#F61212' : $tone === 'warning' ? '#FF6305' : '#e5e5e5'};
  border-radius: 2px;
  padding: 24px;
`;

const Icon = styled.svg`
  width: 24px;
  height: 24px;
`;

export const Alert = ({children, closeLabel, id, onClose, tone = 'neutral'}: AlertProps) => {
  return (
    <StyledAlert aria-live="polite" id={id} role="alert" $tone={tone}>
      <Inline align="flex-start" gap="xs" wrap={false}>
        <Icon
          viewBox="0 0 24 24"
          fill={tone === 'neutral' ? '#303030' : '#fff'}
          xmlns="http://www.w3.org/2000/svg"
          style={{margin: '-8px'}}>
          <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z" />
        </Icon>
        <Text
          color={tone === 'neutral' ? '#303030' : '#fff'}
          font="OpenSans"
          linkStyle="underline"
          size="sm"
          weight="700">
          {children}
        </Text>
      </Inline>
    </StyledAlert>
  );
};
