import styled from 'styled-components';

const ContentBox = styled.div`
  padding: 2em;
  margin: 0 auto;
  max-width: 940px;
  @media screen and (max-width: 768px) {
    font-size: 14px;
    padding: 5%;
  }
  &&&& {
    a,
    p {
      font-size: 100%;
    }
  }
  ol,
  ul {
    @media screen and (max-width: 768px) {
      padding-left: 3em;
    }
  }
  img {
    @media screen and (max-width: 768px) {
      width: 100%;
      height: auto;
    }
  }
  p > span {
    white-space: unset !important;
  }
`;

export default ContentBox;
