import styled from 'styled-components';

import {Device} from '../../constants';

import StyledSubscriptionCoreContent from './StyledSubscriptionCoreContent';
import StyledSubscriptionCoreWrapper from './StyledSubscriptionCoreWrapper';

const StyledSubscriptionVBucksSection = styled.div`
  @media screen and ${Device.laptopL} {
    ${StyledSubscriptionCoreWrapper} {
      ${StyledSubscriptionCoreContent} {
        background-size: 100%;
      }
    }
  }
`;

export default StyledSubscriptionVBucksSection;
