import styled from 'styled-components';

import {Device} from '../../constants';

const StyledSubscriptionHeader = styled.section<{
  backgroundImage?: string;
  backgroundImageMobile?: string;
}>`
  display: flex;
  padding: 50px;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 90vh;
  color: #fff;
  background-image: ${(props) => `url(${props.backgroundImage})`};

  .subscription-header-text {
    display: block;
    max-width: max-content;
    margin: 0 auto;
  }

  .subscription-header-article-link {
    width: 100%;
    margin-top: 1rem;
  }

  @media screen and ${Device.laptopL} {
    min-height: 700px;
  }

  @media screen and ${Device.laptop} {
    min-height: 550px;
    padding: 0;
  }

  @media screen and ${Device.tablet} {
    min-height: 400px;
  }

  @media screen and ${Device.mobileL} {
    flex-direction: column;
    padding: 1em;
    padding-top: 2em;
    min-height: 90vh;
    background-image: ${(props) => `url(${props.backgroundImageMobile})`};
  }
`;

export default StyledSubscriptionHeader;
