import {ContextMessage as Message} from '@epic-core/localization';
import {withLocalization} from '@epic-core/localization';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import root from 'window-or-global';

import {
  getDefault,
  getOption,
  getPlatforms,
  getRetailers,
  getTerritories,
  getTerritoryFromCountry,
} from '../../utils/PurchasePackConfig';
import {buyAssetOrSignIn} from '../../utils/PurchaseUtils';

const disabledCountries = [];

const stopPropagation = (e) => {
  e.stopPropagation();
};

const getInitialState = function getInitialState() {
  return {
    version: 'digital',
    territory: 'americas',
    platform: 'pc',
    retailer: 'epic',
    order: ['version', 'territory', 'platform', 'retailer'],
  };
};

/**
 * A function for showing a comparison table based on config
 * @param config
 * @returns {XML}
 */
class PackBuyDialog extends React.Component {
  static propTypes = {
    isFounder: PropTypes.bool,
    isLoggedIn: PropTypes.bool,
    isLauncher: PropTypes.bool,
    show: PropTypes.bool,
    countryCode: PropTypes.string,
    config: PropTypes.object,
    asset: PropTypes.object,
    onRequestClose: PropTypes.func.isRequired,
    legalTags: PropTypes.array,
    getMessage: PropTypes.func,
  };

  state = getInitialState();

  UNSAFE_componentWillReceiveProps(nextProps) {
    // Reset on show
    if (nextProps.show && !this.props.show) {
      const initialState = getInitialState();
      initialState.territory = getTerritoryFromCountry(
        initialState.version,
        this.props.countryCode
      );
      this.setState(initialState);
    }
  }

  requestPurchase = (e) => {
    const {asset, isLoggedIn, isLauncher, isFounder, onRequestClose} = this.props;
    const option = getOption(
      this.state.version,
      this.state.territory,
      this.state.platform,
      this.state.retailer
    );
    if (isFounder || !option) return;
    if (option.purchaseFlow) {
      buyAssetOrSignIn(asset, isLoggedIn, isLauncher);
      onRequestClose();
    } else if (option.link) {
      root.open(option.link, '_blank');
    } else if (option.useOfferLink && asset.customAttributes) {
      const offerLink = `${this.state.version}_${this.state.territory}_${this.state.platform}_${option.value}`;
      const fallbackOfferLink = `${this.state.version}_default_${this.state.platform}_${option.value}`;
      const offer = asset.customAttributes[offerLink] || asset.customAttributes[fallbackOfferLink];
      root.open(offer.value, '_blank');
    }
  };

  onChangeVersion = (e) => {
    this.onChange('version', e);
  };

  onChange = (type, e) => {
    const formOrder = this.state.order;
    const elementIndex = formOrder.indexOf(type);
    const newState = Object.assign({}, this.state);
    newState[type] = e.target.value;
    // Clear out the following elements in the form if a change is made
    for (let i = elementIndex + 1; i < formOrder.length; i++) {
      newState[formOrder[i]] = getDefault(
        newState.version,
        newState.territory,
        newState.platform,
        newState.retailer,
        formOrder[i]
      );
    }
    this.setState(newState);
  };

  createInput = (label, placeholder, options, type, hideIfSingleOption) => {
    const selectOptions = [];
    if (options) {
      for (const optionIdx in options) {
        if (!Object.prototype.hasOwnProperty.call(options, optionIdx)) continue;
        const option = options[optionIdx];
        selectOptions.push(
          <option value={option.value} key={option.value}>
            {this.props.getMessage(option.title)}
          </option>
        );
      }
    }
    if (hideIfSingleOption && selectOptions.length < 2) return null;
    const onChange = this.onChange.bind(this, type);
    const selectTag = `buy-dialog-${type}`;
    return (
      <div className="cta-step">
        <div className="cta-step-container">
          <label htmlFor={selectTag} className="step-label">
            {' '}
            {/*eslint-disable-line*/}
            <Message code={label} />
          </label>
          {/* eslint-disable-next-line jsx-a11y/no-onchange */}
          <select
            /*eslint-disable-line*/
            name={selectTag}
            id={selectTag}
            onChange={onChange}
            value={this.state[type]}>
            <option disabled value="">
              {this.props.getMessage(placeholder)}
            </option>
            {selectOptions}
          </select>
        </div>
      </div>
    );
  };
  // hide usk logo on purchase page
  processLegalTags(legalTags) {
    const hiddenKey = ['usk'];
    return legalTags.filter((tag) => !hiddenKey.includes(tag.key));
  }

  render() {
    const {config, asset, show, onRequestClose, legalTags} = this.props;
    if (!config || !asset) return <div />;
    const packBuyClass = classNames('pack-dialog-container', {visible: show});
    const buttonTag = config.tier ? `buy-dialog-tier-${config.tier}` : 'buy-dialog';
    const digitalTag = 'buy-dialog-version-digital';
    const retailTag = 'buy-dialog-version-retail';
    const enableRetail = asset.customAttributes && asset.customAttributes.enableRetailLinks;
    const option = getOption(
      this.state.version,
      this.state.territory,
      this.state.platform,
      this.state.retailer
    );
    const userDisallowed = disabledCountries.indexOf(this.props.countryCode) >= 0;
    let ratingLogos = null;
    if (legalTags && legalTags.length) {
      const processedLegalTags = this.processLegalTags(legalTags);
      ratingLogos = processedLegalTags.map((tag) => {
        const jsx = (
          <li key={tag.src}>
            <p>{tag.title}</p>
            <img alt={tag.title} src={tag.src} />
          </li>
        );
        return jsx;
      });
    }
    return (
      <div className={packBuyClass}>
        <div
          role="presentation"
          id="buy-dialog-close"
          className="pack-dialog-bg"
          onClick={onRequestClose}
        />
        <div role="presentation" className="pack-dialog" onClick={stopPropagation}>
          <div className="row">
            <div role="presentation" onClick={onRequestClose} className="close">
              <i className="icon-close" />
            </div>
          </div>
          <form>
            <h1 className="asset-dialog-title">{asset.title}</h1>
            {userDisallowed ? (
              <div>
                <Message code="epic.fortnite.founder.dialog.error.country" />
              </div>
            ) : (
              <div>
                {enableRetail ? (
                  <div className="cta-step cta-radio">
                    <div className="step-label">
                      <Message code="epic.fortnite.founder.dialog.step1" />
                    </div>
                    <label htmlFor={digitalTag}>
                      <input
                        id={digitalTag}
                        type="radio"
                        value="digital"
                        name="version"
                        checked={this.state.version === 'digital'}
                        onChange={this.onChangeVersion}
                      />
                      <Message code="epic.fortnite.founder.dialog.step1.digital" />
                    </label>
                    <label htmlFor={retailTag}>
                      <input
                        id={retailTag}
                        type="radio"
                        value="retail"
                        name="version"
                        checked={this.state.version === 'retail'}
                        onChange={this.onChangeVersion}
                      />
                      <Message code="epic.fortnite.founder.dialog.step1.retail" />
                    </label>
                  </div>
                ) : null}
                {this.createInput(
                  `epic.fortnite.founder.dialog.step2${enableRetail ? '' : '.alt'}`,
                  'epic.fortnite.founder.dialog.step2.placeholder',
                  getTerritories(this.state.version),
                  'territory'
                )}
                {this.createInput(
                  `epic.fortnite.founder.dialog.step3${enableRetail ? '' : '.alt'}`,
                  'epic.fortnite.founder.dialog.step3.placeholder',
                  getPlatforms(this.state.version, this.state.territory),
                  'platform'
                )}
                {this.createInput(
                  'epic.fortnite.founder.dialog.step4',
                  'epic.fortnite.founder.dialog.step4.placeholder',
                  getRetailers(this.state.version, this.state.territory, this.state.platform),
                  'retailer',
                  true
                )}
                {option && option.comingSoon ? (
                  <div className="coming-soon-container text-center">
                    <h2>
                      <Message code="epic.common.comingSoon" />
                    </h2>
                  </div>
                ) : (
                  <button
                    id={buttonTag}
                    type="button"
                    className="btn btn-primary"
                    onClick={this.requestPurchase}>
                    <Message code="epic.fortnite.button.buy" />
                  </button>
                )}
              </div>
            )}
          </form>
          <Message
            className="legal-disclaimer"
            code="epic.fortnite.founder.dialog.legal.disclaimer"
          />
          <ul className="pack-dialog-legalTags">{ratingLogos}</ul>
        </div>
      </div>
    );
  }
}

export default withLocalization(PackBuyDialog);
