import PropTypes from 'prop-types';
import React from 'react';

export default class GuideStock extends React.Component {
  static propTypes = {
    stocks: PropTypes.array,
    info: PropTypes.object,
  };

  render() {
    const {stocks} = this.props;
    if (!stocks) {
      return null;
    }
    const {stockName, stockImage, stockProperty} = this.props.info;

    return (
      <table className="guide-table">
        <thead>
          <tr>
            <th>{stockImage}</th>
            <th>{stockName}</th>
            <th>{stockProperty}</th>
          </tr>
        </thead>
        <tbody>
          {stocks.map((item, index) => {
            const key = `tr-${index}`;
            const rows = [
              <tr key={key} className={item.info ? 'has-info' : ''}>
                <td>
                  <img src={item.image} alt={item.name} />
                </td>
                <td>{item.name}</td>
                <td>{item.property}</td>
              </tr>,
            ];
            if (item.info) {
              rows.push(
                <tr key={`${key}-info`}>
                  <td colSpan="3">{item.info}</td>
                </tr>
              );
            }
            return rows;
          })}
        </tbody>
      </table>
    );
  }
}
