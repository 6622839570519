import {withLocalization} from '@epic-core/localization';
import {
  Container,
  Grid,
  GridColumn,
  Inline,
  Stack,
  Text,
  VisuallyHidden,
} from 'epic-fortnite-react';
import React, {useState} from 'react';
import styled from 'styled-components';

import {RiftTourColumnImage} from './components/RiftTourColumnImage';
import {RiftTourCTA} from './components/RiftTourCTA';
import {RiftTourHeading} from './components/RiftTourHeading';
import {RiftTourSection} from './components/RiftTourSection';
import {RiftTourSubheading} from './components/RiftTourSubheading';
import StyledRiftTourPlaceholder from './components/StyledRiftTourPlaceholder';
import {RiftTourVideoModal} from './RiftTourVideoModal';
import {RiftTourBackground} from './types';

interface Props {
  data: CMSData;
  getMessage: (string: string) => string;
}

interface CMSData {
  background: RiftTourBackground;
  headerLogo: string;
  headerSubtitle: string;
  heroImage: string;
  logoSubtitle?: string;
  textHeading: string;
  youtubeVideoId?: string;
}

const DropShadow = styled.div`
  filter: drop-shadow(0px 0px 16px rgba(0, 0, 0, 0.15))
    drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.375));
`;

const RiftTourHeader = ({data, getMessage}: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const {
    background,
    heroImage,
    headerLogo,
    textHeading,
    headerSubtitle,
    logoSubtitle,
    youtubeVideoId,
  } = data;

  const {
    backgroundImage,
    backgroundOption,
    backgroundVideo,
    hasBackgroundOverlay,
    gradientFrom,
    gradientTo,
    gradientSize,
    gradientPosition,
  } = background;

  const handleClick = () => {
    setIsModalOpen(true);
  };

  return (
    <RiftTourSection
      backgroundImage={backgroundImage}
      backgroundPosition={backgroundOption}
      backgroundVideo={backgroundVideo}
      hasBackgroundOverlay={hasBackgroundOverlay}
      gradient={{from: gradientFrom, to: gradientTo}}
      gradientPosition={gradientPosition}
      gradientSize={`${gradientSize}%`}>
      <Container maxWidth={1200} size="med">
        <Grid breakpoint={900} gapX="2xl" gapY="4xl" reverseOrder={false}>
          <GridColumn span={1 / 2}>
            {heroImage ? (
              <RiftTourColumnImage
                alt=""
                src={heroImage}
                style={{
                  marginTop: 'calc(var(--section__padding) * -1)',
                  maxWidth: 'min(75vw, 450px)',
                }}
              />
            ) : (
              <StyledRiftTourPlaceholder />
            )}
          </GridColumn>
          <GridColumn span={1 / 2}>
            <DropShadow>
              <Stack align="center" gap="lg" justify="center">
                <VisuallyHidden>
                  <RiftTourHeading>{textHeading}</RiftTourHeading>
                </VisuallyHidden>
                <RiftTourColumnImage alt="" src={headerLogo} style={{maxWidth: '400px'}} />
                {logoSubtitle && (
                  <GridColumn span={1 / 2}>
                    <RiftTourSubheading color="#FAC6DA" italic={true}>
                      {logoSubtitle}
                    </RiftTourSubheading>
                  </GridColumn>
                )}
                <RiftTourSubheading color="#fff">{headerSubtitle}</RiftTourSubheading>
                {youtubeVideoId && (
                  <>
                    <RiftTourCTA
                      label={
                        <Inline align="center" gap="2xs">
                          <Text color="#fff" size="lg" transform="uppercase">
                            {getMessage('epic.common.watch')}
                          </Text>
                          <svg
                            width="49"
                            height="49"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <circle
                              cx="24.5"
                              cy="24.5"
                              r="23.5"
                              fill="#000"
                              fillOpacity=".6"
                              stroke="#fff"
                            />
                            <path
                              d="M32.369 22.9a2 2 0 010 3.2L22.7 33.356c-1.319.99-3.201.049-3.201-1.6V17.244c0-1.648 1.882-2.589 3.2-1.6L32.37 22.9z"
                              fill="#fff"
                            />
                          </svg>
                        </Inline>
                      }
                      onClick={handleClick}
                      textLink={true}
                    />
                    <RiftTourVideoModal
                      ariaLabel={getMessage('epic.fortnite.trailer')}
                      isOpen={isModalOpen}
                      onDismiss={() => setIsModalOpen(false)}
                      video={`https://www.youtube.com/watch?v=${youtubeVideoId}`}
                    />
                  </>
                )}
              </Stack>
            </DropShadow>
          </GridColumn>
        </Grid>
      </Container>
    </RiftTourSection>
  );
};

export default withLocalization(RiftTourHeader);
