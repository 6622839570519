import {SlickWrapper} from 'epic-react-common';
import PropTypes from 'prop-types';
import React from 'react';
import VisibilitySensor from 'react-visibility-sensor';

import AnimateString from '../common/AnimateString';

class SkinSlider extends React.Component {
  static propTypes = {
    data: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      visible: false,
    };
    this.sliderWrapper = React.createRef(); // in case we need do DOM manipulation later
  }
  onVisibleChange = (isVisible) => {
    if (isVisible && !this.state.visible) {
      this.setState({visible: true});
    }
  };
  render() {
    const {currentSlide, visible} = this.state;
    const {slides, title, description, badge, bgImage, secondaryBackgroundImage, slideImages} =
      this.props.data;

    const sliderOptions = {
      adaptiveHeight: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: true,
      autoplay: true,
      autoplaySpeed: 3000,
      dotsClass: 'battlePass-paginations bottom',
      arrows: false,
      draggable: false,
      swipeToSlide: true,
      swipe: true,
      pauseOnHover: false,
      touchMove: true,
      fade: false,
    };

    let list = null;

    //this can be removed after season 13
    if (slides && slides.length > 0) {
      list = slides.map((slide, i) => {
        const key = `item-${i}`;
        const animateClass = i === currentSlide ? 'animate' : '';
        return (
          <div className={`${animateClass} slide`} key={key}>
            <img src={slide} alt="char" />
          </div>
        );
      });
    }

    if (slideImages && slideImages.length > 0) {
      list = slideImages.map((slide, i) => {
        const key = `item-${i}`;
        const animateClass = i === currentSlide ? 'animate' : '';
        return (
          <div className={`${animateClass} slide`} key={key}>
            <img src={slide.image} alt={slide.imageAlt} />
          </div>
        );
      });
    }

    return (
      <VisibilitySensor
        onChange={this.onVisibleChange}
        active={!visible}
        partialVisibility
        minTopValue={150}>
        <div className={`bp-skinslider ${visible ? 'visible' : ''}`} ref={this.sliderWrapper}>
          <div className="clipShape" />
          <div className="badge-container">
            {badge ? <img className="battlepass-badge" src={badge} alt="battlepass" /> : null}
          </div>
          {bgImage ? <img className="goldBolds" src={bgImage} alt="goldBolts" /> : null}
          {bgImage ? (
            <div className="bg-image" style={{backgroundImage: `url(${bgImage})`}} />
          ) : null}
          {secondaryBackgroundImage ? (
            <img
              className="bp-secondary-bg-img"
              src={secondaryBackgroundImage}
              alt="battle pass secondary"
            />
          ) : null}
          {list && (
            <div className="battlePass-slider">
              <SlickWrapper
                className="battlePass-chars"
                options={sliderOptions}
                ref={(c) => {
                  this.slider = c;
                }}>
                {list}
              </SlickWrapper>
            </div>
          )}
          <div className="battlepass-bottom-content">
            {title ? (
              <AnimateString
                key={`skin-h1`}
                text={title}
                type="h1"
                speed={1.5}
                delay={0.3}
                autoAlpha={0}
                yPos={100}
                minTop={20}
                scaleFrom={1}
                staggerDelay={0}
              />
            ) : null}
            {description ? (
              <AnimateString
                key={`skin-p`}
                text={description}
                type="p"
                speed={1.5}
                delay={0.3}
                autoAlpha={0}
                yPos={100}
                minTop={20}
                scaleFrom={1}
                staggerDelay={0}
              />
            ) : null}
          </div>
        </div>
      </VisibilitySensor>
    );
  }
}

export default SkinSlider;
