import PropTypes from 'prop-types';
import React from 'react';

export default class GuideSelection extends React.Component {
  static propTypes = {
    list: PropTypes.array,
    activeTab: PropTypes.number,
    onTabChange: PropTypes.func,
  };

  render() {
    const {list, activeTab, onTabChange} = this.props;

    if (!list) {
      return null;
    }

    return (
      <div className="guide-selection">
        {list.map((item, index) => {
          const cls = activeTab === index ? 'guide-btn active' : 'guide-btn';
          const key = `item-${index}`;
          return (
            <span
              role="presentation"
              key={key}
              className={cls}
              data-index={index}
              onClick={onTabChange}>
              {item.title}
            </span>
          );
        })}
      </div>
    );
  }
}
