import {motion} from 'framer-motion';
import React from 'react';
import styled, {css} from 'styled-components';

import {Device} from '../../../constants';

type Sizes = 'large' | 'small';

const spikes = {
  large: css`
    @media screen and ${Device.mobileL} {
      left: 0;
      transform: scale3d(0.8, 0.8, 0.8);
    }
  `,
  small: css`
    transform: rotateY(180deg) scale3d(0.6, 0.6, 0.6);
    @media screen and ${Device.mobileL} {
      right: 0;
    }
  `,
};

const TitleSpike = styled(({...rest}) => <motion.img {...rest} />)<{size?: Sizes}>`
  margin: 0;
  ${(props) => spikes[props.size]};
  @media screen and (max-width: 1140px) {
    width: 10vw;
  }
  @media screen and ${Device.mobileL} {
    position: absolute;
    bottom: 100%;
    width: 15vw;
  }
`;

export default TitleSpike;
