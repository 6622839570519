import styled from 'styled-components';

import {breakpoints} from '../../../../constants/Breakpoints';

const MaskName = styled.h2`
  text-align: center;

  &&& {
    font-size: 6rem;
  }

  @media ${breakpoints.tablet} {
    &&& {
      font-size: 4.8rem;
    }
  }

  @media ${breakpoints.mobileL} {
    &&& {
      font-size: 3.6rem;
    }
  }
`;

export default MaskName;
