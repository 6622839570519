import {Device} from 'epic-fortnite-styles/constants';
import styled from 'styled-components';

import Screens from '../Screens';

const StyledScreens = styled(Screens)`
  &&& {
    margin-top: 0%;
    background-color: white;
    color: black;
    .screen-section:first-child {
      margin-top: 0;
    }
    @media screen and ${Device.mobileL} {
      .screen-section {
        width: 90%;
      }
    }
    .screen-section:first-child img {
      margin-top: -5em;
      @media screen and ${Device.desktopS} {
        margin-top: -4em;
      }
      @media screen and ${Device.laptopL} {
        margin-top: -3.5em;
      }
      @media screen and ${Device.laptop} {
        margin-top: -3em;
      }
      @media screen and ${Device.mobileL} {
        margin-top: -4em;
      }
    }

    .screen-content {
      width: 100%;
      h1 {
        color: #781bb2;
        @media screen and ${Device.mobileL} {
          font-size: 2em;
        }
      }
      h4 {
        width: 70%;
        margin: auto;
        font-size: 1.2vw;
        @media screen and ${Device.laptop} {
          width: 90%;
          line-height: 1.25;
          font-size: 1em;
        }
        a {
          font-family: $font-0;
          font-size: 1.2vw;
          line-height: 1;
          @media screen and ${Device.laptop} {
            font-size: 1em;
          }
        }
      }
    }
  }
`;

export default StyledScreens;
