import {Device} from 'epic-fortnite-styles/constants';
import styled from 'styled-components';

const StyledPlayVideo = styled.div`
  position: absolute;
  height: 4em;
  width: 50em;
  @media screen and ${Device.laptopL} {
    width: 20em;
  }
  @media screen and ${Device.mobileL} {
    width: 100%;
  }
  > span {
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    @media screen and ${Device.mobileL} {
      justify-content: center;
    }
  }
  .animate-object span,
  .animate-object h4 {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    @media screen and ${Device.mobileL} {
      margin: 0;
    }
  }
  && .animate-object h4 {
    margin: 0.1em 0.5em 0 0.5em;
    font-size: 2.5em;
    color: #fff;
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.8), 0px 5px 20px rgba(0, 0, 0, 0.3);
    @media screen and ${Device.desktopS} {
      font-size: 2em;
    }
    @media screen and ${Device.laptopL} {
      font-size: 1em;
    }
    @media screen and ${Device.tablet} {
      font-size: 1em;
    }
    @media screen and ${Device.mobileL} {
      text-align: center;
      font-size: 1.5em;
    }
    @media screen and ${Device.mobileS} {
      font-size: 1em;
    }
  }
  .animate-object .videoPlayButton {
    font-size: 0.8vw;
    margin: 0;
    @media screen and ${Device.mobileL} {
      margin: 0;
      font-size: 0.5em;
    }
    &.bp-videoplay {
      border-color: #fff;
      background: rgba(0, 0, 0, 0.5);
      span {
        border-left: 1.8em solid #fff;
      }
    }
    &:hover {
      transform: scale(1.1) !important;
    }
  }
`;

export default StyledPlayVideo;
