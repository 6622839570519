import ClassNames from 'classnames';
import {YoutubeVideoEmbed} from 'epic-react-common';
import React, {useContext} from 'react';

import VideoOverlayContext from './VideoOverlayContext';

const VideoOverlay = () => {
  const {config} = useContext(VideoOverlayContext);
  if (!config) {
    return null;
  }
  const {videoID, isLauncher, play, close, closeButton, show} = config;
  if (!videoID) {
    return null;
  }
  const videoOverlayClass = ClassNames('video-overlay', {active: show});
  return (
    <div role="presentation" onClick={close} id="videoOverlay" className={videoOverlayClass}>
      <div className="container full-height">
        <div className="video-container embed-responsive embed-responsive-16by9">
          {show && <YoutubeVideoEmbed video={videoID} play={play} launcher={isLauncher} />}
          {closeButton && (
            <div role="presentation" className="close-video" onClick={close}>
              <div className="icon-close" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default VideoOverlay;
