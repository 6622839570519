import styled from 'styled-components';

import {breakpoints} from '../../../../constants/Breakpoints';
import ResponsiveImage, {Props} from '../../components/ResponsiveImage';

const CarouselImage = styled(ResponsiveImage)<Props>`
  img {
    max-height: 700px;
    max-width: 100%;
    margin: auto;
    width: auto;
  }

  @media ${breakpoints.mobileL} {
    img {
      max-height: unset;
      max-width: unset;
      width: 100%;
    }
  }
`;

export default CarouselImage;
