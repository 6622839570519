import StyledText from 'epic-fortnite-react/components/common/StyledText';
import Proptypes from 'prop-types';
import React from 'react';

const PosterItem = ({data, size}) => {
  if (!data) return null;
  const {header, poster, posterAlt, footer} = data;
  const posterClasses = `col-xs-12 col-sm-${size} PosterItem`;
  return (
    <div className={posterClasses}>
      {header && header.length > 0 && (
        <div className="header text-center">
          <StyledText data={header} />
        </div>
      )}
      <div className="poster">
        <img src={poster} alt={posterAlt} />
      </div>
      {footer && footer.length > 0 && (
        <div className="footer text-center">
          <StyledText data={footer} />
        </div>
      )}
    </div>
  );
};

PosterItem.propTypes = {
  data: Proptypes.shape({
    header: Proptypes.arrayOf(Proptypes.object),
    poster: Proptypes.string,
    posterAlt: Proptypes.string,
    footer: Proptypes.arrayOf(Proptypes.object),
  }),
  size: Proptypes.number,
};

export default PosterItem;
