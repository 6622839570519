import {Text, TextProps} from 'epic-fortnite-react';
import React from 'react';

export const RiftTourSubheading = ({...props}: TextProps) => {
  return (
    <Text
      balance={false}
      color="#010821"
      lineLength="sm"
      size="lg"
      transform="uppercase"
      {...props}
    />
  );
};
