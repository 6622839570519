import type {SearchQueryParams, SearchStoreConfig} from './types';

export function getParams(locationSearch: string): SearchQueryParams {
  const params = new URLSearchParams(locationSearch);
  const filter = params.get('filter') || '';
  const offset = parseInt(params.get('offset') || '0', 10);
  const query = params.get('keyword') || params.get('q') || '';

  return {filter, offset, query};
}

export function getParamsFilterOrDefault(
  paramsFilter: string | undefined,
  configSearchFilters: SearchStoreConfig['searchFilters']
): string {
  let searchFilter = '';

  if (paramsFilter) {
    searchFilter = paramsFilter;
  } else if (configSearchFilters && configSearchFilters.length) {
    searchFilter = configSearchFilters[0]?.filterName || '';
  }

  return searchFilter;
}

export function getSearchConfigOrDefaults(
  searchConfig: SearchStoreConfig | undefined
): Required<SearchStoreConfig> {
  const searchFilters = [];
  const enableCustomFilter = false;

  if (!searchConfig) {
    return {enableCustomFilter, searchFilters};
  }

  return {
    enableCustomFilter:
      typeof searchConfig.enableCustomFilter === 'undefined'
        ? enableCustomFilter
        : searchConfig.enableCustomFilter,
    searchFilters:
      typeof searchConfig.searchFilters === 'undefined'
        ? searchFilters
        : searchConfig.searchFilters,
  };
}
