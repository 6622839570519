import {RedeemView} from '@epic-code-redemption/client';
import {getLocale} from '@epic-core/common';
import {generateRouteHref, generateRouteTo} from 'epic-fortnite-react';
import {connectToStores} from 'epic-alt-utils';
import AppConfigStore from 'epic-fortnite-react/stores/AppConfigStore';
import LoginStore from 'epic-fortnite-react/stores/LoginStore';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React from 'react';
import Helmet from 'react-helmet';
import {Redirect} from 'react-router';

import Background from '../../components/common/Background';
import LoadScreen from '../../components/loadScreen/LoadScreen';
import {HeaderStore, RedeemPageStore} from '../../stores';

@connectToStores
class RedeemWrapperView extends React.Component {
  static propTypes = {
    loginStore: PropTypes.object,
    appConfigStore: PropTypes.object,
    redeemPageStore: PropTypes.object,
    location: PropTypes.object,
    altSuccessPath: PropTypes.string,
    isLoggedIn: PropTypes.bool,
    mobile: PropTypes.bool,
    tablet: PropTypes.bool,
  };

  static getStores() {
    return [LoginStore, AppConfigStore, RedeemPageStore, HeaderStore];
  }

  static getPropsFromStores() {
    return {
      loginStore: LoginStore.getState(),
      appConfigStore: AppConfigStore.getState(),
      redeemPageStore: RedeemPageStore.getState(),
      mobile: HeaderStore.getState().mobile(),
      tablet: HeaderStore.getState().tablet(),
    };
  }

  getRedeemConfig(appConfigStore) {
    const config = {
      epicgames: {
        host: appConfigStore.epicgamesHost,
        namespace: appConfigStore.epicgamesAccountManagementNamespace,
        codeRedemptionURI: 'code-redemption',
        talonFlowId: appConfigStore.codeRedemptionTalonFlowId,
      },
    };

    // Show different title and errorMsg for FN Mobile => /mobile/redeem
    const location = this.props.location || {};
    const pathname = location.pathname || '';

    if (pathname && pathname.indexOf('/mobile/redeem-code') !== -1) {
      config.messages = {
        'epic.code.redemption.validate.title':
          'epic.code.redemption.validate.title.fortnite.mobile',
        'epic.code.redemption.confirm.title': 'epic.code.redemption.confirm.title.fortnite.mobile',
      };

      // Show custom error message for FN mobile code
      const errorMsgHandler = (redeemError, ErrorMessage) => {
        let errorMsg = redeemError.message;
        let errorCode = redeemError.numericErrorCode;
        let args = [redeemError.args];

        if (redeemError.numericErrorCode === '19010') {
          errorMsg = 'errors.com.epicgames.code.redemption.code_used.mobile.fortnite.mobile';
          errorCode = null;
          args = null;
        }

        return (
          <ErrorMessage className="errorMessage" msg={errorMsg} code={errorCode} args={args} />
        );
      };

      config.errorMsgHandler = errorMsgHandler;
    }
    if (pathname && pathname.indexOf('/mobile/android/redeem-code') !== -1) {
      config.messages = {
        'epic.code.redemption.validate.title':
          'epic.code.redemption.validate.title.fortnite.android',
        'epic.code.redemption.confirm.title': 'epic.code.redemption.confirm.title.fortnite.android',
      };

      // Show custom error message for FN Android code
      const errorMsgHandler = (redeemError, ErrorMessage) => {
        let errorMsg = redeemError.message;
        let errorCode = redeemError.numericErrorCode;
        let args = [redeemError.args];

        if (redeemError.numericErrorCode === '19010') {
          errorMsg = 'errors.com.epicgames.code.redemption.code_used.mobile.fortnite.android';
          errorCode = null;
          args = null;
        }

        return (
          <ErrorMessage className="errorMessage" msg={errorMsg} code={errorCode} args={args} />
        );
      };

      config.errorMsgHandler = errorMsgHandler;
    }
    return config;
  }

  render() {
    const loadScreen = <LoadScreen show={true} />;

    const {
      mobile,
      tablet,
      appConfigStore,
      altSuccessPath,
      isLoggedIn,
      location,
      redeemPageStore = {},
    } = this.props;

    const {
      title,
      background,
      content,
      enablePosa,
      signInPosa,
      signOutPosa,
      meta = {},
      posaContent,
    } = redeemPageStore;

    const locale = getLocale();

    const {title: metaTitle, description: metaDescription, image: metaImage} = meta;

    const pathname = location.pathname || '';

    if (pathname && pathname.indexOf('/mobile/redeem-code') !== -1 && !isLoggedIn) {
      const query = queryString.parse(location.search);
      query.selectDevice = true;
      return (
        <Redirect
          to={generateRouteTo(`/mobile/redeem?${queryString.stringify(query)}`, location, locale)}
        />
      );
    }

    if (pathname && pathname.indexOf('/mobile/android/redeem-code') !== -1 && !isLoggedIn) {
      const query = queryString.parse(location.search);
      query.selectDevice = true;
      return (
        <Redirect
          to={generateRouteTo(
            `/mobile/android/redeem?${queryString.stringify(query)}`,
            location,
            locale
          )}
        />
      );
    }

    const tags = [
      {itemProp: 'name', content: metaTitle},
      {itemProp: 'description', content: metaDescription},
      {itemProp: 'image', content: metaImage},
      {name: 'description', content: metaDescription},
      {name: 'twitter:title', content: metaTitle},
      {name: 'twitter:description', content: metaDescription},
      {name: 'twitter:image', content: metaImage},
      {property: 'og:title', content: metaTitle},
      {property: 'og:image', content: metaImage},
      {property: 'og:description', content: metaDescription},
    ];

    return (
      <div id="redeemView" className="redeemContainer">
        {
          // only override default meta if there is meta data in CMS
          metaTitle && <Helmet meta={tags} title={metaTitle} />
        }
        <Background toRoot background={background} mobile={mobile} tablet={tablet} />
        <RedeemView
          isLoggedIn={this.props.loginStore.isLoggedIn}
          loadScreen={loadScreen}
          config={this.getRedeemConfig(appConfigStore)}
          altSuccessPath={altSuccessPath}
          title={title}
          content={content}
          enablePosa={enablePosa}
          signInPosa={signInPosa}
          signOutPosa={signOutPosa}
          posaData={posaContent}
          posaLink={generateRouteHref('/vbuckscard', location, locale)}
          {...this.props}
        />
      </div>
    );
  }
}

export default RedeemWrapperView;
