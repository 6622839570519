import {stripTrailingSlashes} from '@epic-core/common';
import {useSharedValue} from '@epic-core/hooks';
import {useState, useEffect, useMemo} from 'react';

import {cmsApi} from './cms.api';
import {CmsSharedKeys} from './cms.keys';
import {PageData, CmsData, CmsUrlLoadingState} from './cms.types';

const defaultLoadingState = {
  isLoading: false,
  hasFetchedPage: false,
  error: undefined,
  emptyPageData: true,
  emptyPageDataList: [],
};

// A record of URL's for which the request is in-flight so we can deduplicate multiple requests for the same page
const activePageFetches: Record<string, boolean> = {};

/**
 * useCmsFetch will return the data for `cmsUrlPattern`, intended to use in `useCmsData` or other hooks.
 * For direct usage refer to `useCmsData` instead
 *
 * If pageData is provided and valid it'll use this as a default value and skip the fetch call.
 *
 * If it has to make a fetch request it'll store this in the CmsStore in shared-value state so that
 * subsequent calls useCmsData with the same urlPattern will return the same data without performing a request
 *
 * If initially multiple calls are made to the same urlPattern without existing data it should deduplicate the calls
 */
export const useCmsFetch = <P extends PageData>(cmsUrlPattern: string, pageData: P): CmsData<P> => {
  const [urlLoadingState, setUrlLoadingState] = useState<CmsUrlLoadingState>({
    [cmsUrlPattern]: defaultLoadingState,
  });
  const loadingState = urlLoadingState[cmsUrlPattern];
  const [, setCmsStore] = useSharedValue(CmsSharedKeys.CmsStore);

  const isPageDataValid = !!(cmsUrlPattern && pageData && Object.keys(pageData).length !== 0);

  useEffect(() => {
    if (
      (pageData && isPageDataValid && !loadingState.error) ||
      loadingState.isLoading ||
      loadingState.hasFetchedPage ||
      activePageFetches[cmsUrlPattern] === true ||
      !cmsUrlPattern
    ) {
      return;
    }
    async function fetchData() {
      try {
        activePageFetches[cmsUrlPattern] = true;
        setUrlLoadingState({[cmsUrlPattern]: {isLoading: true, hasFetchedPage: false}});
        const fetchedPageData = (await cmsApi.getPageData(cmsUrlPattern)) || {};
        const emptyPageData = Object.keys(fetchedPageData).length === 0;
        setUrlLoadingState({
          [cmsUrlPattern]: {isLoading: false, hasFetchedPage: true, emptyPageData},
        });

        setCmsStore((latestCmsStore) =>
          Object.assign({}, latestCmsStore, {
            [cmsUrlPattern]: fetchedPageData || {},
          })
        );
        delete activePageFetches[cmsUrlPattern];
      } catch (ex) {
        console.error('Failed to fetch cms data for page', cmsUrlPattern, ex);
        setUrlLoadingState({
          [cmsUrlPattern]: {
            isLoading: false,
            hasFetchedPage: true,
            error: ex,
          },
        });
      }
    }
    fetchData();
  }, [pageData, isPageDataValid, loadingState.isLoading, loadingState.hasFetchedPage]);

  return useMemo(() => {
    return {
      pageData,
      // It's loading when a request is still in progress or if has not been started yet and doesn't have valid page data
      isLoading:
        loadingState.isLoading === true || (!loadingState.hasFetchedPage && !isPageDataValid),
      hasFetchedPage: loadingState.hasFetchedPage || isPageDataValid,
      error: loadingState.error,
    };
  }, [
    pageData && pageData._urlPattern,
    loadingState.isLoading,
    loadingState.hasFetchedPage,
    isPageDataValid,
    loadingState.error,
  ]);
};

/**
 * useCmsData will retrieve the cms data for `urlPattern`.
 *
 * Before fetching the data it will check if the data is present in
 * CmsStore in the shared-values state. If already present it'll skip the fetch
 * and return this data directly
 *
 * To preload data returned by `useCmsData` use `getInitialCmsPageSharedValues` for the same urlPattern
 */
export const useCmsData = <P extends PageData>(urlPattern: string): CmsData<P> => {
  const [cmsStore] = useSharedValue(CmsSharedKeys.CmsStore);
  const cmsUrlPattern = urlPattern.length > 1 ? stripTrailingSlashes(urlPattern) : urlPattern;
  const pageData = cmsStore[cmsUrlPattern] || {};

  return useCmsFetch<P>(cmsUrlPattern, pageData);
};
