import React from 'react';
import {CoreSectionProps} from '../bundles/types';
import {CoreSection} from './CoreSection';
import {Flex, Text} from 'epic-fortnite-react';

interface LegalTextProps {
  legalText: string;
  coreSectionProps?: CoreSectionProps;
}

const LegalTextSection = (props: LegalTextProps) => {
  const {legalText, coreSectionProps} = props;

  return (
    <CoreSection coreSectionProps={coreSectionProps}>
      <Flex align="center" justify="center">
        <Text
          as="p"
          color={coreSectionProps?.defaultTextColor ?? 'rgba(255, 255, 255, 0.5)'}
          size={coreSectionProps?.defaultTextColor ? 'xs' : 'med'}
          align={'center'}
          italic={!coreSectionProps?.defaultTextColor}
          font="OpenSans">
          {legalText}
        </Text>
      </Flex>
    </CoreSection>
  );
};

export default LegalTextSection;
