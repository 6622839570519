import generateWithAsyncActions from 'epic-alt-utils';
import {alt} from 'epic-react-common';

/**
 * @ignore BlogActions
 */
export default generateWithAsyncActions(
    alt,
    ['getBlogData', 'getBlogPost', 'getBlogSocialShareCount'],
    ['resetError', 'setStoreState']
);
