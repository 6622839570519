import {ContextMessage as Message} from '@epic-core/localization';
import PropTypes from 'prop-types';
import React from 'react';

export default class RetailAvailablePlatforms extends React.PureComponent {
  static propTypes = {
    data: PropTypes.object,
  };
  render() {
    const {title, description, available} = this.props.data;
    const platformsList = available.split(',') || [];
    const platforms = platformsList.map((platform) => {
      return (
        <li key={`platform-${platform}`}>
          <i className={`icon-${platform}`} />
          <Message code={`epic.fortnite.overlay.${platform}`} />
        </li>
      );
    });
    return (
      <div className="retail-available-platforms">
        <div className="container-fluid platformContainer text-center">
          <div className="row">
            <div className="col-xs-12">
              <h1>{title}</h1>
              <div className="description" dangerouslySetInnerHTML={{__html: description}} />
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12">
              <h3 className="black">
                <Message code="epic.fortnite.retailBundle.platforms.availableOn" />
              </h3>
              <ul>{platforms}</ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
