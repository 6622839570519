import PropTypes from 'prop-types';
import React from 'react';

export default class GuideTip extends React.Component {
  static propTypes = {
    tips: PropTypes.array,
    tipTitle: PropTypes.string,
  };

  render() {
    const {tips, tipTitle} = this.props;

    if (!tips) {
      return null;
    }

    return (
      <div className="guide-tip-wrap">
        <span className="guide-tip-title">{tipTitle}</span>
        {tips.map((item, index) => {
          const key = `tr-${index}`;
          return (
            <div className="guide-tip-content" key={key}>
              {item.title && <h5>{item.title}</h5>}
              <div dangerouslySetInnerHTML={{__html: item.subText}} />
            </div>
          );
        })}
      </div>
    );
  }
}
