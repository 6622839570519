import {Device} from 'epic-fortnite-styles/constants';
import styled from 'styled-components';

const StyledCreativeHeading = styled.h2<{color?: string}>`
  color: ${(props) => (props.color ? props.color : '#000')};
  &&& {
    font-size: 3.5em;
  }

  @media screen and ${Device.mobileL} {
    &&& {
      font-size: 2.5em;
    }
  }
`;

export default StyledCreativeHeading;
