import {withLocalization} from '@epic-core/localization';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import styled from 'styled-components';
import root from 'window-or-global';

import CTAButton from '../common/CTAButton';

import {Stack, Text} from 'epic-fortnite-react';

const StyledImageCta = styled.div`
  /* aspect-ratio: 1.5/1; */
  background-position: center;
  background-size: cover;
  display: grid;
  grid-template-rows: 1fr 1fr;
  min-height: 60vw;
  padding-block: 6rem;
  padding-inline: 2rem;

  @media (min-width: 768px) {
    /* aspect-ratio: 1.5/1; */
  }
`;

const StyledImageCtaContent = styled(Stack)`
  position: relative;
`;

const ImageCta = ({data}) => {
  const [isMobile, setMobile] = useState(false);
  const {title, subtitle, cta, desktopImg, mobileImg} = data;
  const bgImg = isMobile && mobileImg ? mobileImg : desktopImg;

  const isMobileCheck = React.useCallback(() => {
    const ww = root.innerWidth;
    if (ww <= 550) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  }, [setMobile, root]);

  React.useEffect(() => {
    isMobileCheck();
    root.addEventListener('resize', isMobileCheck, true);

    return () => {
      root.removeEventListener('resize', isMobileCheck, true);
    };
  }, [isMobileCheck, root]);

  return (
    <StyledImageCta style={{backgroundImage: `url(${bgImg})`}}>
      <StyledImageCtaContent gap="xl">
        {title && (
          <Text align="center" as="h2" color="#fff" size="4xl">
            {title}
          </Text>
        )}
        {subtitle && (
          <Text align="center" as="h3" color="#fff" size="2xl">
            {subtitle}
          </Text>
        )}
        {cta && <CTAButton ctaConfig={cta} theme="brightYellow" />}
      </StyledImageCtaContent>
    </StyledImageCta>
  );
};

ImageCta.propTypes = {
  data: PropTypes.object,
  mobile: PropTypes.bool,
  getMessage: PropTypes.func,
  tablet: PropTypes.bool,
};

export default withLocalization(ImageCta);
