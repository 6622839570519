import {getLocale} from '@epic-core/common';
import {generateRouteTo} from 'epic-fortnite-react';
import PropTypes from 'prop-types';
import React from 'react';
import {useLocation, withRouter} from 'react-router';

const SimpleTabListSection = ({items, theme, history}) => {
  const location = useLocation();
  const locale = getLocale();
  const bodyFontStyles = theme && theme.neutralFontColor ? {color: theme.neutralFontColor} : null;
  const headerStyles = theme && theme.headerFontColor ? {color: theme.headerFontColor} : null;
  const iconStyles = theme && theme.iconStyles ? {color: theme.iconStyles} : null;
  const routeTo = (path) => history.push(generateRouteTo(path, location, locale));
  const renderItem = (item, hasIcon) => {
    return (
      <React.Fragment>
        <h1 className="simple-tab-list-section-header" style={headerStyles}>
          {item.headerText}
        </h1>
        <div className="simple-tab-list-section-content-container">
          <span dangerouslySetInnerHTML={{__html: item.content}} />
          {hasIcon ? <span className="icon-reverse-right-arrow" style={iconStyles} /> : null}
        </div>
      </React.Fragment>
    );
  };

  return (
    <div className="simple-tab-list-section-wrapper">
      {items.map((item, index) => {
        const isLinkItem = item.hasLink && !!item.link;
        const isPdfLinkItem = item.hasPdfLink && item.pdfLink;
        const shouldDisplayLinkIcon = isLinkItem || isPdfLinkItem;
        const containerStyles = `simple-tab-list-item-container ${
          shouldDisplayLinkIcon ? 'item-has-link' : null
        }`;

        if (isPdfLinkItem) {
          return (
            <a
              key={`simple-tab-list-item-wrapper-${index}`}
              href={item.pdfLink}
              style={bodyFontStyles}>
              <div key={index} className={containerStyles}>
                {renderItem(item, shouldDisplayLinkIcon)}
              </div>
            </a>
          );
        }

        return (
          <div
            key={index}
            className={containerStyles}
            onClick={() => (isLinkItem ? routeTo(item.link) : null)}
            role="presentation">
            {renderItem(item, shouldDisplayLinkIcon)}
          </div>
        );
      })}
    </div>
  );
};

SimpleTabListSection.propTypes = {
  items: PropTypes.array,
  theme: PropTypes.object,
  history: PropTypes.object,
};

export default withRouter(SimpleTabListSection);
