import styled from 'styled-components';

const BackgroundImageClip = styled.div`
  clip-path: polygon(0 50%, 100% 0, 100% 105%, 0 105%);
  margin-bottom: -5px;
  height: 100px;
  background-color: white;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  z-index: 5;
`;

export default BackgroundImageClip;
