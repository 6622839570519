import React from 'react';

import SpikeLayer from '../../components/SpikeLayer';
import TwoGrid from '../../components/TwoGrid';
import {Mask} from '../../types';

import HalfExpose from './HalfExpose';

interface Props {
  accent: string;
  masks: [Mask, Mask];
  alternateOrder?: boolean;
}

const SideBySideExplore = ({masks, accent, alternateOrder}: Props) => {
  const left = masks[0];
  const right = masks[1];
  return (
    <SpikeLayer desktopOnly={true}>
      <TwoGrid>
        <HalfExpose alternateOrder={alternateOrder} accent={accent} {...left} />
        <HalfExpose
          alternateOrder={alternateOrder === true ? false : true}
          accent={accent}
          {...right}
        />
      </TwoGrid>
    </SpikeLayer>
  );
};

export default SideBySideExplore;
