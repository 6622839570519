import {generateRouteTo} from 'epic-fortnite-react';
import {getMatchGuide, isMatchGuideMenu} from 'epic-fortnite-react/utils/FortniteUtils';
import PropTypes from 'prop-types';
import React from 'react';
import {Link, withRouter} from 'react-router-dom';

class GuideMenuItem extends React.Component {
  static propTypes = {
    items: PropTypes.array,
    guides: PropTypes.array,
    path: PropTypes.string,
    match: PropTypes.object,
    locale: PropTypes.string,
  };

  render() {
    const {items, guides, path, match, location, locale} = this.props;
    return (
      <div className="leaf-wrap">
        {items.map((item, index) => {
          const page = getMatchGuide({guides, id: item});
          const active = isMatchGuideMenu(match.url, page._slug);

          return (
            <Link
              key={page._id}
              to={generateRouteTo(`/play-guide/${path}/${page._slug}`, location, locale)}
              className={active ? 'link active' : 'link'}>
              <span>{page._title}</span>
            </Link>
          );
        })}
      </div>
    );
  }
}

export default withRouter(GuideMenuItem);
