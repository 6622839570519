import {seamlessImmutable} from 'epic-alt-utils';
import Immutable from 'seamless-immutable';

import alt from '../alt';

@seamlessImmutable
class FooterStore {
  constructor() {
    this.on('error', (error) => {
      console.error(error);
    });
    this.state = Immutable({
      config: null,
    });
  }
}

export default alt.createStore(FooterStore, 'FooterStore');
