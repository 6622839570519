import {Container, FAQ, Section} from 'epic-fortnite-react';
import React from 'react';

import SimpleTextWithBackground from '../../components/blocks/SimpleTextWithBackground';
import SideBySide from '../../components/bundles/SideBySide';
import {CmsComponentMapper} from '@epic-mw/react-cms';
import SimpleCardSection from '../simpleCard/SimpleCardSection';
import MobileLandingHero from '../../components/mobile/MobileLandingHero';
import CloudStreamingHeader from '../../views/cloudStreaming/CloudStreamingHeader';
import {RichTextSection} from '../blocks/RichTextSection';
import CenteredHeroBlock from '../blocks/CenteredHeroBlock';
import DownloadBarBlock from '../blocks/DownloadBarBlock';
import LegalTextSection from '../blocks/LegalTextSection';

const SimpleLandingBaseplate = ({blocks}) => {
  return (
    <CmsComponentMapper
      dynamicPageDataArray={blocks}
      componentMapping={{
        defaultHero: (props: React.ComponentProps<typeof CloudStreamingHeader>) => (
          <CloudStreamingHeader data={props} />
        ),
        condensedMobileHero: (props: React.ComponentProps<typeof MobileLandingHero>) => (
          <MobileLandingHero data={props} />
        ),
        centeredHero: (props: React.ComponentProps<typeof CenteredHeroBlock>) => (
          <CenteredHeroBlock {...props} />
        ),
        cardSection: (props: React.ComponentProps<typeof SimpleCardSection>) => (
          <Section size="2xl">
            <Container maxWidth={1600} size="med">
              <SimpleCardSection {...props} />
            </Container>
          </Section>
        ),
        sideBySide: (props: React.ComponentProps<typeof SideBySide>) => <SideBySide {...props} />,
        textSection: (props: React.ComponentProps<typeof SimpleTextWithBackground>) => (
          <SimpleTextWithBackground {...props} />
        ),
        legalTextSection: (props: React.ComponentProps<typeof LegalTextSection>) => (
          <LegalTextSection {...props} />
        ),
        richTextSection: (props: React.ComponentProps<typeof RichTextSection>) => (
          <RichTextSection {...props} />
        ),
        faqSection: (props: React.ComponentProps<typeof FAQ>) => (
          <Section size="4xl">
            <Container maxWidth={800} size="med">
              <FAQ {...props} />
            </Container>
          </Section>
        ),
        downloadBar: (props: React.ComponentProps<typeof DownloadBarBlock>) => (
          <DownloadBarBlock {...props} />
        ),
      }}
    />
  );
};

export default SimpleLandingBaseplate;
