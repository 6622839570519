import HtmlTemplate from 'epic-fortnite-react/components/common/HtmlTemplate';
import React, {useState} from 'react';
import VisibilitySensor from 'react-visibility-sensor';

import {triggerAnalytic} from '../../utils/SubscriptionUtils';

import StyledSubscriptionJoinStep from './StyledSubscriptionJoinStep';
import StyledSubscriptionJoinStepImg from './StyledSubscriptionJoinStepImg';
import StyledSubscriptionJoinStepsContainer from './StyledSubscriptionJoinStepsContainer';
import StyledSubscriptionJoinWrapper from './StyledSubscriptionJoinWrapper';
import SubscriptionCoreText from './SubscriptionCoreText';

interface Props {
  content: any;
  mobile: boolean;
  tablet: boolean;
}

const SubscriptionJoinSection = (props: Props) => {
  const {
    content: {
      backgroundGradientColor1,
      backgroundGradientColor2,
      backgroundGradientColor3,
      stepsList: {steps},
      textSection: {header, textSection, theme},
    },
  } = props;
  const [isVisible, setVisibility] = useState(false);
  const onChange = (): void => {
    setVisibility(!isVisible);
    if (isVisible) {
      triggerAnalytic('SubscriptionJoinTheCrew');
    }
  };

  return (
    <VisibilitySensor onChange={onChange} partialVisibility minTopValue={10}>
      <StyledSubscriptionJoinWrapper
        id="join-section"
        bgGradientColor1={backgroundGradientColor1}
        bgGradientColor2={backgroundGradientColor2}
        bgGradientColor3={backgroundGradientColor3}>
        <SubscriptionCoreText header={header} textSection={textSection} theme={theme} />
        <StyledSubscriptionJoinStepsContainer>
          {steps.map((step, i) => (
            <StyledSubscriptionJoinStep key={`join-step-${i}`}>
              <StyledSubscriptionJoinStepImg src={step.image} alt="" />
              <HtmlTemplate Tag="h6" html={step.footerText} />
            </StyledSubscriptionJoinStep>
          ))}
        </StyledSubscriptionJoinStepsContainer>
      </StyledSubscriptionJoinWrapper>
    </VisibilitySensor>
  );
};

export default SubscriptionJoinSection;
