import {PosaCardRedeemView} from '@epic-code-redemption/client';
import {getLocale} from '@epic-core/common';
import {generateRouteTo} from 'epic-fortnite-react';
import {connectToStores} from 'epic-alt-utils';
import LoginActions from 'epic-fortnite-react/actions/LoginActions';
import Error404 from 'epic-fortnite-react/components/error/Error404';
import AppConfigStore from 'epic-fortnite-react/stores/AppConfigStore';
import LoginStore from 'epic-fortnite-react/stores/LoginStore';
import epicTracking from 'epic-tracking';
import PropTypes from 'prop-types';
import React from 'react';
import {withRouter} from 'react-router';
import root from 'window-or-global';

import LoadScreen from '../../components/loadScreen/LoadScreen';

@connectToStores
class PosaRedeemWrapperView extends React.Component {
  static propTypes = {
    loginStore: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object,
  };

  static getStores() {
    return [LoginStore, AppConfigStore];
  }

  static getPropsFromStores() {
    return {
      loginStore: LoginStore.getState(),
    };
  }
  goToBattlePassPage = () => {
    const {location} = this.props;

    const locale = getLocale();

    epicTracking.trackEvent('Interaction', {
      interactionType: 'click',
      eventCategory: 'PosaRedeem',
      eventAction: 'battlePass',
    });

    this.props.history.push(generateRouteTo('battle-pass', location, locale));
  };

  login = () => {
    epicTracking.trackEvent('Interaction', {
      interactionType: 'click',
      eventCategory: 'PosaRedeem',
      eventAction: 'login',
    });

    const redirectUrl = `${root.location.pathname}`;

    LoginActions.requestLogin(redirectUrl);
  };

  render() {
    const loadScreen = <LoadScreen show={true} />;

    const {
      isLoggedIn,
      accountInfo: {displayName = ''},
    } = this.props.loginStore;

    return (
      <PosaCardRedeemView
        isLoggedIn={isLoggedIn}
        accountName={displayName}
        loadScreen={loadScreen}
        notFoundRedirect={<Error404 />}
        ctaAction={this.goToBattlePassPage}
        loginAction={this.login}
        location={this.props.location}
        uppercaseCode
      />
    );
  }
}

export default withRouter(PosaRedeemWrapperView);
