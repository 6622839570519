function isBoolean(value): boolean {
    return typeof value === 'boolean';
}

export function checkConfig(service: string, envConfig: {[key: string]: string}, ...args: string[]): void {
    if (!envConfig || typeof envConfig !== 'object') {
        throw new Error(`Service configuration failed ${service} requires the environmental config to be passed`);
    }
    for (const arg of args) {
        if (!envConfig[arg] && !isBoolean(envConfig[arg])) {
            console.error(
                `%s Failed to configure service, one or more env configs is missing.
                Check your environmental config for %s`,
                service,
                arg
            );
            throw new Error(`Failed to configure ${service}`);
        }
    }
}

export function checkRequiredParams(method: string, params: {[key: string]: string}, ...args: string[]): void {
    if (!params || (typeof params !== 'object' && params !== null)) {
        throw new Error(`${method}() -> params must be an object`);
    }

    if (args && args.length > 0) {
        for (const arg of args) {
            if (!params[arg]) {
                console.error(
                    `Failed to to call method %s, one or more params is missing.
                    missing parameter %s`,
                    method,
                    arg
                );
                throw new Error(`Failed to call ${method}`);
            }
        }
    }
}

export function isHttpStatusSuccess(status: number): boolean {
    return !!(status && status >= 200 && status < 300);
}
