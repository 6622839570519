import React from 'react';
import styled from 'styled-components';
import {HttpErrorScreen, LoadScreen} from 'epic-fortnite-react/components';
import {getInitialCmsPageSharedValues, useCmsData, PageData} from 'epic-fortnite-shared-values';
import MetaTags from '../../components/common/MetaTags';
import {Locale} from '@epic-core/config';
import {withLocalization} from '@epic-core/localization';

const SLUG = 'posa-jppsa';

interface PosaJAData extends PageData {
  fundsSettlementActTitle: string;
  fundsSettlementAct: string;
}

interface LocalizationProps {
  locale: Locale;
}

const PosaBackground = styled.div`
  background-color: #fff;
`;

const PosaContainer = styled.div`
  min-height: 90vh;
  width: 100%;
  display: table;
  height: 80vh;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
`;

const PosaJAView = ({locale}: LocalizationProps) => {
  const {pageData, isLoading, error} = useCmsData<PosaJAData>(SLUG);

  if (isLoading) {
    return <LoadScreen show={true} />;
  }

  if (error) {
    return <HttpErrorScreen statusCode={error.response?.status ?? 500} />;
  }

  // return a 404 if the pageData is not found and if locale is not equal to 'ja'
  if (!pageData || locale !== 'ja') {
    return <HttpErrorScreen statusCode={404} />;
  }

  return (
    <PosaBackground>
      <MetaTags pageModel={pageData} />
      <div className="light-bg">
        <PosaContainer>
          <div className="site-block paymentsact-container">
            <div className="site-block-header">
              <h1 className="site-block-title pull-left paymentsact-title">
                {pageData.fundsSettlementActTitle}
              </h1>
              <div className="clearfix" />
            </div>
            <div className="site-block-body">
              <div
                className="site-block-body-text paymentsact-text"
                dangerouslySetInnerHTML={{__html: pageData.fundsSettlementAct}}
              />
            </div>
          </div>
        </PosaContainer>
      </div>
    </PosaBackground>
  );
};

PosaJAView.getInitialSharedValues = getInitialCmsPageSharedValues(SLUG);

export default withLocalization(PosaJAView);
