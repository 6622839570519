export interface CmsData {
  title: string;
  subtitle: string;
  items: HeroSlide[];
  badge: string;
  badgeAlt: string;
}

export interface HeroSlide {
  color: string;
  icon: string;
  name: string;
  title: string;
  subTitle: string;
  hero: ImageArray[];
  heroItems: ImageArray[];
  slideBackground: ResponsiveImage;
  spike: string;
  key: string;
  sound?: string;
}

export interface ResponsiveImage {
  desktopImage: string;
  tabletImage?: string;
  mobileImage?: string;
}

export interface ImageArray {
  image: string;
  imageAlt: string;
}

export enum HeroIds {
  'posionivy',
  'midas',
  'joker',
}
