import {getLocale} from '@epic-core/common';
import {generateRouteTo} from '../../utils/route';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import {withRouter} from 'react-router';
import styled from 'styled-components';

const StyledCTA = styled.button`
  && {
    background-color: ${({bgColor}) => bgColor || '#ff0'};
    color: ${({color}) => color || '#000'};
  }
`;

/**
 * CMS Content type => Fortnite - Common CTA Config
 */
class CommonCTABtn extends React.PureComponent {
  static propTypes = {
    ctaConfig: PropTypes.object.isRequired,
    className: PropTypes.string,
    history: PropTypes.object,
  };
  redirectInternalUrl = (path, locale) => {
    const {history, location} = this.props;
    history.push(generateRouteTo(path, location, locale));
  };
  render() {
    let res = null;
    const {
      isCtaLinkExternal,
      ctaString,
      ctaLink,
      disabled,
      theme,
      ctaColor,
      ctaTextColor,
      onExternalClickHandler,
    } = this.props.ctaConfig;
    const locale = getLocale();

    const ctaClass = classnames(
      'btn btn-primary',
      [this.props.className],
      {invisible: !ctaString},
      {disabled},
      theme || ''
    );
    if (isCtaLinkExternal) {
      res = (
        <StyledCTA
          as="a"
          bgColor={ctaColor}
          color={ctaTextColor}
          className={ctaClass}
          href={ctaLink}
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => {
            onExternalClickHandler && onExternalClickHandler();
          }}>
          <span>{ctaString}</span>
        </StyledCTA>
      );
    } else {
      res = (
        <StyledCTA
          bgColor={ctaColor}
          color={ctaTextColor}
          className={ctaClass}
          onClick={() => this.redirectInternalUrl(ctaLink, locale)}>
          <span>{ctaString}</span>
        </StyledCTA>
      );
    }
    return res;
  }
}

export default withRouter(CommonCTABtn);
