import {getLocale} from '@epic-core/common';
import epicTracking from 'epic-tracking';
import React from 'react';
import {RouteComponentProps, withRouter} from 'react-router';
import root from 'window-or-global';
import styled from 'styled-components';

import {generateMainSitePath} from '../../utils/FortniteUtils';
import {generateRouteHref} from '../../utils/route';
import Message from '../message/Message';
import {Location} from 'history';

const DownloadSelectionContainer = styled.div`
  text-align: center;
  max-width: 100%;
  width: 670px;
  margin: 0 auto;
  padding-bottom: 32px;

  .msg {
    color: black;
    font-size: 0.8rem;
    display: block;
    text-transform: uppercase;
    line-height: 1.36;
  }

  .sub-msg {
    font-size: 0.875rem;
    font-weight: 700;
    padding: 8px;
    text-transform: uppercase;

    @media only screen and (max-width: 550px) {
      display: none;
    }
  }
`;

const Header = styled.h1`
  margin: 0 auto 32px;

  span {
    font-style: normal;
    font-weight: 700;
    font-size: 3.4rem;
    line-height: 1;
    text-align: center;
    letter-spacing: 0.000125em;
    text-transform: uppercase;
  }

  @media only screen and (max-width: 550px) {
    span {
      font-size: 2rem;
      letter-spacing: 0.00025em;
    }
  }
`;

const SubHeader = styled.h2`
  margin: -16px auto 32px;

  span {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    font-family: OpenSans, sans-serif;
    line-height: 1;
    text-align: center;
    letter-spacing: 0.000125em;
    text-transform: none;
  }

  @media only screen and (max-width: 550px) {
    margin: 0 auto 32px;

    span {
      letter-spacing: 0.00025em;
    }
  }
`;

const PlatformButtonContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: center;
  max-width: 100%;
`;

const PlatformButtonGroup = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`;

const PlatformButton = styled.button`
  align-items: center;
  background-color: #ff0;
  border: none;
  display: flex;
  flex-direction: column;
  font-size: 0.8125rem;
  font-weight: 800;
  gap: 10px;
  height: 160px;
  justify-content: center;
  padding: 12px;
  width: 160px;

  &:hover {
    background-color: #ffff90;
    transition: background-color 0.3s ease-in-out;
  }
`;

const Icon = styled.i`
  font-size: 3.125rem;
  color: black;
  display: block;
  aspect-ratio: 1/1;
  overflow: hidden;
  line-height: 1.25;
  @supports (-webkit-appearance: none) and (stroke-color: transparent) {
    line-height: 1;
  }
`;

const getLinkForCountry = (platform, country, download, location) => {
  const downloadLinks = download[platform];
  const locale = getLocale();
  let downloadLink;
  let rowLink = generateRouteHref({pathname: '/download'}, location, locale);
  let foundLink = false;
  for (let i = 0; i < downloadLinks.length; i++) {
    const link = downloadLinks[i];
    if (link.country.indexOf(country) >= 0) {
      foundLink = true;
      downloadLink = link.link;
    }
    if (link.country === 'ROW') {
      rowLink = link.link;
    }
  }
  return foundLink ? downloadLink : rowLink;
};

const trackDownloadClick = (platform) => {
  epicTracking.trackEvent('Interaction', {
    interactionType: 'click',
    eventCategory: 'downloadPopOver',
    eventAction: 'platformDownloadButton-CTA1',
    eventLabel: platform,
  });
};

interface Props extends RouteComponentProps {
  appConfigStore: {
    platformDownloadLinks: string;
    pcDownloadUrl: string;
    macDownloadUrl: string;
  };
  preHeaderMsg: string;
  countryCode: string;
  location: Location;
}

const DownloadSelection = (props: Props): JSX.Element => {
  const {
    appConfigStore,
    appConfigStore: {platformDownloadLinks},
    preHeaderMsg,
    countryCode,
    location,
  } = props;

  const locale = getLocale();

  const isIosDevice =
    navigator.userAgent.toLowerCase().match('ipad') ||
    (!navigator.userAgent.toLowerCase().match('iphone') && navigator.maxTouchPoints > 1);

  const intLinkMobileIOS = () => {
    const goMobile = generateMainSitePath('mobile', locale);
    trackDownloadClick('ios');
    root.location.href = goMobile;
  };

  const intLinkMobileAndroid = () => {
    const goMobile = generateMainSitePath('mobile', locale);
    trackDownloadClick('android');
    root.location.href = goMobile;
  };

  const intLinkMobile = () => {
    if (isIosDevice) {
      intLinkMobileIOS();
    } else {
      intLinkMobileAndroid();
    }
  };

  const intLinkPC = () => {
    if (isIosDevice) {
      intLinkMobileIOS();
    } else if (root._epicGamesNav.browser.mac) {
      const {macDownloadUrl} = appConfigStore;
      trackDownloadClick('notWindowsPC');
      root.location.href = macDownloadUrl;
    } else {
      const {pcDownloadUrl} = appConfigStore;
      trackDownloadClick('windows');
      root.location.href = pcDownloadUrl;
    }
  };

  const extLinkConsole = (platform: string): void => {
    const extLink = getLinkForCountry(platform, countryCode, platformDownloadLinks, location);
    trackDownloadClick(platform);
    root.open(extLink);
  };

  const platformButtonsData = [
    {
      id: 'download-PCMac',
      onClick: intLinkPC,
      icon: 'icon-pc',
      msgCode: 'epic.common.pc',
      subMsgCode: 'epic.common.download_now',
    },
    {
      id: 'download-Ps4',
      onClick: () => extLinkConsole('psn'),
      icon: 'icon-ps4',
      msgCode: 'epic.fortnite.mobile.signIn.ps4',
      subMsgCode: 'epic.common.learnMore',
    },
    {
      id: 'download-Xbox',
      onClick: () => extLinkConsole('xbl'),
      icon: 'icon-xbox',
      msgCode: 'epic.fortnite.mobile.signIn.xbox',
      subMsgCode: 'epic.common.learnMore',
    },
    {
      id: 'download-nintendo',
      onClick: () => extLinkConsole('nintendo'),
      icon: 'icon-nintendo',
      msgCode: 'epic.fortnite.mobile.signIn.switch',
      subMsgCode: 'epic.common.learnMore',
    },
    {
      id: 'download-mobile',
      onClick: intLinkMobile,
      icon: 'icon-mobile',
      msgCode: 'epic.fortnite.mobile.signIn.mobile',
      subMsgCode: 'epic.common.learnMore',
    },
  ];

  return (
    <DownloadSelectionContainer>
      <Header>
        <span>{preHeaderMsg}</span>
      </Header>
      <SubHeader>
        <Message code="epic.fortnite.downloadOverlay.title" />
      </SubHeader>
      <PlatformButtonContainer>
        {platformButtonsData.map((button) => (
          <PlatformButtonGroup key={button.id}>
            <PlatformButton id={button.id} onClick={button.onClick}>
              <Icon className={button.icon} />
              <Message className="msg" code={button.msgCode} />
            </PlatformButton>
            <Message className="sub-msg" code={button.subMsgCode} />
          </PlatformButtonGroup>
        ))}
      </PlatformButtonContainer>
    </DownloadSelectionContainer>
  );
};

export default withRouter(DownloadSelection);
