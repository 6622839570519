import PropTypes from 'prop-types';
import React from 'react';

import FavoriteSVG from './Favorite';
import FavoritedSVG from './Favorited';
import ProfileIconSVG from './ProfileIcon';
import RetweetSVG from './Retweet';
import RetweetedSVG from './Retweeted';

class Footer extends React.Component {
  constructor(props) {
    super(props);
    if (this.props.onTweetAction !== undefined) {
      this.onReply = (e) => {
        e.preventDefault();
        this.props.onTweetAction('reply', this.props.data);
      };

      this.onRetweet = (e) => {
        e.preventDefault();
        this.props.onTweetAction('retweet', this.props.data);
      };

      this.onFavourite = (e) => {
        e.preventDefault();
        this.props.onTweetAction('favourite', this.props.data);
      };
    }
  }

  formatCount(count) {
    const readablize = (num) => {
      var e = Math.floor(Math.log(num) / Math.log(1000));
      return `${(num / Math.pow(1000, e)).toFixed(1)}K`;
    };

    if (count > 999) {
      return readablize(count);
    } else {
      return count;
    }
  }

  getRetweetCountTextStyle(data) {}

  render() {
    const {data, linkProps} = this.props;

    const retweetClasses =
      data.retweeted === true
        ? 'ProfileTweetActionCount ProfileTweetActionRetweetCountActive'
        : 'ProfileTweetActionCount';
    const retweetCountTextStyle = `ProfileTweet-actionCount ${retweetClasses}`;
    const favoriteClasses =
      data.favorited === true
        ? 'ProfileTweetActionCount ProfileTweetActionFavouriteCountActive'
        : 'ProfileTweetActionCount';
    const favoriteCountTextStyle = `ProfileTweet-actionCount ${favoriteClasses}`;

    return (
      <div className="footer">
        <a
          className="ProfileTweet-actionList"
          href={`https://twitter.com/${data.user.screen_name}/status/${data.id_str}`}
          {...linkProps}>
          <div className="ProfileTweet-action">
            <button className="ProfileTweet-actionButton" onClick={this.onReply}>
              <div className="IconContainer">
                <ProfileIconSVG />
              </div>
            </button>
          </div>
          <div className="ProfileTweet-action">
            <button className="ProfileTweet-actionButton" onClick={this.onRetweet}>
              <div className="IconContainer">
                {data.retweeted === true ? <RetweetedSVG /> : <RetweetSVG />}
              </div>
              <div className="IconTextContainer">
                <div className={retweetCountTextStyle}>
                  {data.retweet_count > 0 ? this.formatCount(data.retweet_count) : null}
                </div>
              </div>
            </button>
          </div>
          <div className="ProfileTweet-action">
            <button className="ProfileTweet-actionButton" onClick={this.onFavourite}>
              <div className="IconContainer">
                {data.favorited === true ? <FavoritedSVG /> : <FavoriteSVG />}
              </div>
              <div className="IconTextContainer">
                <div className={favoriteCountTextStyle}>
                  {data.favorite_count > 0 ? this.formatCount(data.favorite_count) : null}
                </div>
              </div>
            </button>
          </div>
        </a>
      </div>
    );
  }
}

Footer.propTypes = {
  data: PropTypes.object,
  onTweetAction: PropTypes.func,
  linkProps: PropTypes.object,
};

Footer.displayName = 'Footer';

export default Footer;
