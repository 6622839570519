import styled from 'styled-components';

import {breakpoints} from '../../../../constants/Breakpoints';
import DifficultyInfo, {Props} from '../../components/DifficultyInfo';

const Difficulty = styled(DifficultyInfo)<Props>`
  @media ${breakpoints.mobileL} {
    margin-top: -10rem;
    z-index: 1;
  }
`;

export default Difficulty;
