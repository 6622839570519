import styled from 'styled-components';

const StyledCreativeHomeHeader = styled.div`
  background-color: white;
  text-align: center;
  padding: 3em 1em;
  && p {
    max-width: 800px;
    margin: 0 auto;
    line-height: 1.25;
    font-size: 1.1em;
    font-weight: bold;
    font-family: BurbankBigRegular-Black, sans-serif;
  }
`;

export default StyledCreativeHomeHeader;
