import React from 'react';
import styled from 'styled-components';

import {Device} from '../../constants';

interface Props {
  directionDesktop?: string;
  directionMobile?: string;
  children: React.ReactNode;
}

const RowDirection = styled.div`
  display: flex;
  flex-direction: ${({directionDesktop}: Props) => directionDesktop || 'row'};
  justify-content: center;
  padding-top: 5em;
  @media only screen and ${Device.tablet} {
    flex-direction: ${({directionMobile}: Props) => directionMobile || 'column'};
  }
`;

const RowDirectionExtended = ({children, ...rest}: Props) => (
  <RowDirection {...rest}>{children}</RowDirection>
);

export default RowDirectionExtended;
