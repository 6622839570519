import {getLocale} from '@epic-core/common';
import {connectToStores} from 'epic-alt-utils';
import Error404 from 'epic-fortnite-react/components/error/Error404';
import PropTypes from 'prop-types';
import React from 'react';
import {withRouter} from 'react-router';

import CountriesContentContext from '../../components/vbuckscardterms/CountriesContentContext';
import CountryContentSelector from '../../components/vbuckscardterms/CountryContentSelector';
import {TermsStore, CountryStore, LoginStore} from '../../stores';
import MetaTags from '../../components/common/MetaTags';

/**
 * Safety and Security View
 */
@connectToStores
class VbucksCardTermsView extends React.Component {
  static propTypes = {
    loginStore: PropTypes.object,
    termsStore: PropTypes.object,
    countryStore: PropTypes.object,
    match: PropTypes.object,
  };

  static getStores() {
    return [TermsStore, CountryStore, LoginStore];
  }

  static getPropsFromStores() {
    return {
      loginStore: LoginStore.getState(),
      termsStore: TermsStore.getState(),
      countryStore: CountryStore.getState(),
    };
  }

  getCountry() {
    const {country} = this.props.match.params;
    // If country is selected by url then give this first priority. /vbuckscardterms/de
    if (country) return country.toLowerCase();
    // Use country from account.
    const {
      loginStore: {accountInfo},
    } = this.props;
    return ((accountInfo && accountInfo.country) || 'us').toLowerCase();
  }

  getLang() {
    const {lang} = this.props.match.params;
    // If lang is selected by url then give this first priority. /vbuckscardterms/de/en_US
    if (lang) return lang;
    // Use lang from locale selector.
    return getLocale();
  }

  getContracts() {
    if (this.props.termsStore.vbuckscardterms.contracts.contracts.asMutable) {
      return this.props.termsStore.vbuckscardterms.contracts.contracts.asMutable();
    }
    return this.props.termsStore.vbuckscardterms.contracts.contracts;
  }

  getMap(countriesContent) {
    try {
      return countriesContent.reduce((acc, {country, lang}) => {
        if (!acc[country]) {
          acc[country] = [];
        }
        acc[country].push(lang);
        return acc;
      }, {});
    } catch (ex) {
      // ignore
    }
    return {};
  }

  getCountries(countries) {
    try {
      const countryStore = this.props.countryStore;
      const countryOptions = countries.map((country) => ({
        label: countryStore[country.toUpperCase()] || country,
        value: country,
      }));
      return countryOptions;
    } catch (ex) {
      // ingore.
    }
  }
  getValue() {
    try {
      const countriesContent = this.getContracts();
      const countriesLanguagesMap = this.getMap(countriesContent);
      const countries = this.getCountries(Object.keys(countriesLanguagesMap));
      return {
        countries: countries.asMutable ? countries.asMutable() : countries,
        countriesLanguagesMap: countriesLanguagesMap.asMutable
          ? countriesLanguagesMap.asMutable()
          : countriesLanguagesMap,
        countriesContent: countriesContent.asMutable
          ? countriesContent.asMutable()
          : countriesContent,
      };
    } catch (ex) {
      // ignore.
    }
    return null;
  }

  render() {
    const metaData = this.props.termsStore.vbuckscardterms;
    const content = this.props.termsStore.vbuckscardterms?.content;
    if (content && !this.props.termsStore.vbuckscardterms?.contracts?.contracts) {
      return (
        <div className="vbuckcardTerms">
          <div className="content" dangerouslySetInnerHTML={{__html: content}} />
        </div>
      );
    }

    const value = this.getValue();
    if (!value) {
      return <Error404 />;
    }
    const country = this.getCountry();
    const lang = this.getLang();

    return (
      <div className="vbuckcardTerms">
        <MetaTags pageModel={metaData} />
        <CountriesContentContext.Provider value={value}>
          <CountryContentSelector
            defaultCountry={country || 'us'}
            defaultLanguage={lang || 'en_US'}
          />
        </CountriesContentContext.Provider>
      </div>
    );
  }
}

export default withRouter(VbucksCardTermsView);
