import React from 'react';
import styled from 'styled-components';

import {FAQItemInterface} from '../../../stores/types';
import {Button} from '../../resets/Button/Button';
import {Accordion} from '../Accordion/Accordion';
import HtmlTemplate from '../HtmlTemplate';
import {Inline} from '../Inline/Inline';
import {Section} from '../Section/Section';
import {Stack} from '../Stack/Stack';
import {Text} from '../Text/Text';

export type FAQProps = {
  linkColor?: string;
  questions?: FAQItemInterface[];
  title?: string;
};

const StyledHtmlTemplate = styled(HtmlTemplate)`
  /* undo annoying global styles... */
  .fortnite-wrapper & p,
  .fortnite-wrapper & ol,
  .fortnite-wrapper & ul,
  .fortnite-wrapper & a {
    font-size: 1em;
    line-height: 1.4;
  }

  .fortnite-wrapper & a {
    color: var(--link-color, #2c82c5) !important;
    text-decoration: none;

    &:hover,
    &:focus {
      color: var(--link-color, #2c82c5) !important;
      text-decoration: underline;
    }
  }
`;

const StyledTrigger = styled(Button)`
  width: 100%;
`;

const StyledIcon = styled.i`
  display: block;

  [data-state='open'] &.fa-plus {
    display: none;
  }

  [data-state='closed'] &.fa-minus {
    display: none;
  }
`;

export const FAQ = ({linkColor, title, questions = []}: FAQProps) => {
  let allItems: string[] = [];

  if (questions) {
    allItems = questions.map((item, index) => `faq-${index}`);
  }

  return (
    <Stack gap="xl">
      {title && (
        <Section textAlign="center">
          <Text as="h2" size="2xl">
            {title}
          </Text>
        </Section>
      )}
      <Accordion type="multiple" defaultValue={allItems}>
        <Stack gap="lg">
          {questions.map((item, index) => (
            <Accordion.Item key={`faq-${index}`} style={{width: '100%'}} value={`faq-${index}`}>
              <Stack gap="med">
                <Accordion.Trigger asChild={true}>
                  <StyledTrigger>
                    <Inline
                      align="flex-start"
                      flex="start"
                      gap="2xs"
                      justify="space-between"
                      wrap={false}>
                      <Text align="left" as="h3" size="lg">
                        {item.question}
                      </Text>
                      <div style={{marginTop: '-2px'}}>
                        <StyledIcon className="fa fa-minus" aria-hidden="true" />
                        <StyledIcon className="fa fa-plus" aria-hidden="true" />
                      </div>
                    </Inline>
                  </StyledTrigger>
                </Accordion.Trigger>
                <Accordion.Content style={{width: '100%'}}>
                  <StyledHtmlTemplate
                    Tag="div"
                    html={item.answer}
                    style={{'--link-color': linkColor, textAlign: 'left'} as React.CSSProperties}
                  />
                </Accordion.Content>
              </Stack>
            </Accordion.Item>
          ))}
        </Stack>
      </Accordion>
    </Stack>
  );
};
