import AnimateObject from 'epic-fortnite-react/components/common/AnimateObject';
import React from 'react';
import styled from 'styled-components';

import StyledSubscriptionCoreContent from './StyledSubscriptionCoreContent';
import StyledSubscriptionCoreWrapper from './StyledSubscriptionCoreWrapper';
import SubscriptionCoreText from './SubscriptionCoreText';
import SubscriptionHeroImage from './SubscriptionHero';

const StyledSubscriptionCoreImage = styled.img`
  width: 100%;
`;

const StyledSubscriptionCoreContentMobile = styled.div<{reverse?: boolean}>`
  width: 100%;
  padding-top: 2em;
  display: flex;
  ${({reverse}) => (reverse ? `flex-direction: column-reverse` : `flex-direction: column`)};
`;

interface Props {
  content: any; //SubscriptionCoreProps
  mobile: boolean;
  reverseOnMobile?: boolean;
}

const SubscriptionCoreContent = ({content, mobile, reverseOnMobile}: Props) => {
  const {
    numColumns,
    backgroundImage,
    mobileBackgroundImage,
    imageDetails,
    textContent: {header, textSection, placement, theme},
  } = content;
  const columnCount = parseInt(numColumns);
  const columnWidth = 100 / columnCount;

  return (
    <StyledSubscriptionCoreWrapper
      bgImg={mobile && mobileBackgroundImage ? mobileBackgroundImage : backgroundImage}>
      {mobile ? (
        <StyledSubscriptionCoreContentMobile reverse={reverseOnMobile}>
          <StyledSubscriptionCoreImage src={imageDetails.imageMobile} alt="" />
          <SubscriptionCoreText header={header} textSection={textSection} theme={theme} />
        </StyledSubscriptionCoreContentMobile>
      ) : (
        <React.Fragment>
          <StyledSubscriptionCoreContent columnWidth={columnWidth}>
            {imageDetails.imageLeft && (
              <AnimateObject
                className={'subscription-hero-left'}
                minTop={300}
                speed={2}
                from={{x: `-=100`, autoAlpha: 0}}
                to={{x: -15, autoAlpha: 1, delay: 0.4}}>
                <SubscriptionHeroImage src={imageDetails.imageLeft} alt="" />
              </AnimateObject>
            )}
            {placement === 'left' && (
              <AnimateObject
                className={'subscription-hero-left'}
                minTop={300}
                speed={2}
                from={{x: `-=100`, autoAlpha: 0}}
                to={{x: -15, autoAlpha: 1, delay: 0.8}}>
                <SubscriptionCoreText header={header} textSection={textSection} theme={theme} />
              </AnimateObject>
            )}
          </StyledSubscriptionCoreContent>
          {columnCount === 3 && (
            <StyledSubscriptionCoreContent columnWidth={columnWidth}>
              {imageDetails.imageCenter && (
                <SubscriptionHeroImage src={imageDetails.imageCenter} alt="" />
              )}
              {placement === 'center' && (
                <AnimateObject
                  className={'subscription-text-center'}
                  minTop={300}
                  speed={2}
                  from={{y: `+=100`, autoAlpha: 0}}
                  to={{y: 0, autoAlpha: 1, delay: 0.8}}>
                  <SubscriptionCoreText header={header} textSection={textSection} theme={theme} />
                </AnimateObject>
              )}
            </StyledSubscriptionCoreContent>
          )}
          <StyledSubscriptionCoreContent columnWidth={columnWidth}>
            {imageDetails.imageRight && (
              <AnimateObject
                className={'subscription-hero-right'}
                minTop={300}
                speed={2}
                from={{x: `+=100`, autoAlpha: 0}}
                to={{x: -15, autoAlpha: 1, delay: 0.4}}>
                <SubscriptionHeroImage src={imageDetails.imageRight} alt="" />
              </AnimateObject>
            )}
            {placement === 'right' && (
              <SubscriptionCoreText header={header} textSection={textSection} theme={theme} />
            )}
          </StyledSubscriptionCoreContent>
        </React.Fragment>
      )}
    </StyledSubscriptionCoreWrapper>
  );
};

export default SubscriptionCoreContent;
