import styled from 'styled-components';

interface Props {
  $color?: string;
}

export default styled.h4<Props>`
    &&&& {
        color: ${({$color}) => $color || '#34dfe8'};
    }
    font-family: BurbankBigRegular-Black, sans-serif;
    font-size: 20px;
    font-weight: 900;
    text-transform: uppercase;
    text-align: left
    margin-right: 500px
    @media screen and (max-width: 1200px) {
        margin-right: 0;
        text-align: center;
    }
`;
