import {withLocalization} from '@epic-core/localization';
import {connectToStores} from 'epic-alt-utils';
import StoreActions from 'epic-fortnite-react/actions/StoreActions';
import HttpErrorScreen from 'epic-fortnite-react/components/error/HttpErrorScreen';
import LoadScreen from 'epic-fortnite-react/components/loadScreen/LoadScreen';
import PropTypes from 'prop-types';
import React from 'react';
import Helmet from 'react-helmet';
import {withRouter} from 'react-router';

import Background from '../../components/common/Background';
import FoundersPackError from '../../components/stw/FoundersPackError';
import PackBlock from '../../components/stw/PackBlock';
import StwHeader from '../../components/stw/StwHeader';
import {
  FooterStore,
  HeaderStore,
  LoginStore,
  PlayerStore,
  StoreStore,
  CmsStore,
} from '../../stores';
import {fetchCmsData} from '../../utils/CmsStoreUtils';

const slug = 'save-the-world';

@connectToStores
class StwView extends React.Component {
  static getStores() {
    return [LoginStore, HeaderStore, StoreStore, PlayerStore, FooterStore, CmsStore];
  }

  static getInitialActions() {
    return [fetchCmsData(slug), StoreActions.getFoundersPacks];
  }

  static getPropsFromStores() {
    return {
      loginStore: LoginStore.getState(),
      mobile: HeaderStore.getState().mobile(),
      tablet: HeaderStore.getState().tablet(),
      storeStore: StoreStore.getState(),
      footerStore: FooterStore.getState(),
      playerStore: PlayerStore.getState(),
      cmsStore: CmsStore.getState(),
    };
  }

  static propTypes = {
    loginStore: PropTypes.object,
    getMessage: PropTypes.func,
    storeStore: PropTypes.object,
    playerStore: PropTypes.object,
    footerStore: PropTypes.object,
    mobile: PropTypes.bool,
    tablet: PropTypes.bool,
  };

  getLegalTags() {
    const legalTags = this.props.footerStore.config.legalTags || [];
    return legalTags;
  }

  render() {
    const {mobile, tablet, loginStore, storeStore, playerStore, getMessage, cmsStore} = this.props;
    const {pages} = cmsStore;

    if (!pages[slug]) return <HttpErrorScreen statusCode={404} />;

    const {loading: pageLoading, data: page, error: pageError} = pages[slug];
    const {loadingFounders, foundersError, foundersData} = storeStore;
    const isLoggedIn = loginStore.isLoggedIn || false;

    if (pageLoading || loadingFounders) return <LoadScreen show />;
    if (pageError || foundersError)
      return <HttpErrorScreen statusCode={pageError?.statusCode || foundersError} />;

    const legalTags = this.getLegalTags();
    const {
      background,
      title,
      description,
      description2,
      content: {pack, videos},
      meta = {},
    } = page;

    const {title: metaTitle, description: metaDescription, image: metaImage} = meta;

    const tags = [
      {itemProp: 'name', content: metaTitle},
      {itemProp: 'description', content: metaDescription},
      {itemProp: 'image', content: metaImage},
      {name: 'description', content: metaDescription},
      {name: 'twitter:title', content: metaTitle},
      {name: 'twitter:description', content: metaDescription},
      {name: 'twitter:image', content: metaImage},
      {property: 'og:title', content: metaTitle},
      {property: 'og:image', content: metaImage},
      {property: 'og:description', content: metaDescription},
    ];

    return (
      <div id="stw">
        {
          // only override default meta if there is meta data in CMS
          metaTitle && <Helmet meta={tags} title={metaTitle} />
        }
        <Background mobile={mobile} tablet={tablet} background={background} />
        <StwHeader
          title={title}
          description={description}
          description2={description2}
          videos={videos}
          isLoggedIn={isLoggedIn}
        />
        {foundersError === true ? (
          <FoundersPackError onRetry={() => StoreActions.getFoundersPacks()} />
        ) : (
          <PackBlock
            packs={pack}
            isLoggedIn={isLoggedIn}
            isFounder={foundersData?.isFounder}
            storeStore={storeStore}
            countryCode={playerStore.countryCode}
            legalTags={legalTags}
            getMessage={getMessage}
          />
        )}
      </div>
    );
  }
}

export default withLocalization(withRouter(StwView));
