import styled from 'styled-components';

interface Props {
  $color?: string;
}

export default styled.span<Props>`
  &&&& {
    color: ${({$color}) => $color || '#34dfe8'};
  }
`;
