import styled from 'styled-components';

import {Device} from '../../../constants';

const FeatureCarouselItemContainer = styled.div<{active: boolean}>`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100vw;
  perspective: 1200px;

  @media screen and ${Device.mobileL} {
    overflow: ${(props) => (props.active ? 'visible' : 'hidden')};
  }
`;

export default FeatureCarouselItemContainer;
