import PropTypes from 'prop-types';
import React from 'react';
import {animated, useSpring} from 'react-spring';
import root from 'window-or-global';

const calc = (x, y) => [x - root.innerWidth / 2, y - root.innerHeight / 2];
const translation = (x, y) => `translate3d(${x / 25}px,${y / 25}px, 0)`;
const config = {
  mass: 40,
  tension: 300,
  friction: 300,
};

const ParallaxImage = (props) => {
  const [coords, setCoords] = useSpring(() => ({xy: [0, 0], config}));

  //if you want to transition to root rather than element container
  const handler = React.useCallback(
    ({clientX: x, clientY: y}) => {
      setCoords({xy: calc(x, y)});
    },
    [setCoords, props.toRoot]
  );

  //add and removes event listener from root when toRoot is true
  React.useEffect(() => {
    if (props.toRoot) {
      root.addEventListener('mousemove', handler);
    }
    return () => {
      root.removeEventListener('mousemove', handler);
    };
  }, [props.toRoot, root, handler]);

  return (
    <div
      className={`parallax-container ${props.className || ''}`}
      onMouseMove={props.toRoot ? null : ({clientX: x, clientY: y}) => setCoords({xy: calc(x, y)})}>
      <animated.img
        src={props.image}
        className="parallax-image"
        alt=""
        style={{transform: coords.xy.interpolate(translation)}}
      />
    </div>
  );
};

ParallaxImage.propTypes = {
  xy: PropTypes.object,
  toRoot: PropTypes.bool,
  image: PropTypes.string,
  className: PropTypes.string,
};

export default ParallaxImage;
