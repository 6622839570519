import {getAssetPath} from 'epic-fortnite-react/utils/FortniteUtils';
import React from 'react';
import styled from 'styled-components';

import Medal from '../../../../../images/cosplay/medal.png';
import Stopwatch from '../../../../../images/cosplay/stopwatch.png';
import {breakpoints} from '../../../constants/Breakpoints';
import {SocialShareProps} from '../components/SocialShare';

import DifficultyDescription from './DifficultyDescription';
import DifficultyIcon from './DifficultyIcon';
import DifficultyLabel from './DifficultyLabel';
import DifficultyLevelDisplay from './DifficultyLevelDisplay';
import DifficultyWrapper from './DifficultyWrapper';
import MaskType from './MaskType';
import SocialShare from './SocialShare';

interface Props {
  name?: string;
  description?: string;
  time?: string;
  difficulty?: string;
  button?: React.ReactElement;
  accent?: string;
  className?: string;
  type?: string;
  socialShare?: SocialShareProps['socialShare'];
}

const InfoWithMaskType = ({
  name,
  description,
  time,
  difficulty,
  button,
  accent,
  className,
  type,
  socialShare = undefined,
}: Props) => {
  return (
    <DifficultyWrapper className={className}>
      <MaskType accent={accent}>{type}</MaskType>
      <h1>{name}</h1>
      <DifficultyDescription>{description}</DifficultyDescription>
      <DifficultyLevelDisplay>
        <DifficultyIcon src={getAssetPath(Stopwatch)} alt="" />
        <DifficultyLabel>{time}</DifficultyLabel>
        <DifficultyIcon src={getAssetPath(Medal)} alt="" />
        <DifficultyLabel>{difficulty}</DifficultyLabel>
      </DifficultyLevelDisplay>
      {button}
      <SocialShare socialShare={socialShare} />
    </DifficultyWrapper>
  );
};

const DifficultyInfoWithMaskType = styled(InfoWithMaskType)`
  @media ${breakpoints.mobileL} {
    position: absolute;
    bottom: 10rem;
    z-index: 1;
  }
`;

export default DifficultyInfoWithMaskType;
