import styled from 'styled-components';

import {breakpoints} from '../../../../constants/Breakpoints';

const HalfExposeWrap = styled.div`
  @media ${breakpoints.tablet} {
    margin-top: -3.5rem;
    clip-path: polygon(0 5%, 28% 2%, 24% 5%, 100% 0, 100% 100%, 0 100%);
  }

  @media ${breakpoints.mobileL} {
    && {
      margin-top: -7rem;
    }
  }

  @media ${breakpoints.mobileM} {
    &:nth-child(2) {
      margin-top: -3rem;
    }
  }

  @media (max-width: 500px) {
    &:nth-child(2) {
      margin-top: -5rem;
    }
  }
`;

export default HalfExposeWrap;
