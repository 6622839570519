import {getLocale} from '@epic-core/common';
import React from 'react';

import StatusCode from '../common/StatusCode';
import Message from '../message/Message';
import {generateMainSitePath} from '../../utils/FortniteUtils';

/**
 * 500 component.  Shown user runs into a site error.
 *
 */
const Error500 = () => {
  const locale = getLocale();
  return (
    <StatusCode code={500}>
      <div className="error500 errorPages">
        <div className="content">
          <div className="errorBody">
            <h1>
              <Message code="epic.fortnite.500.oops" />
            </h1>
            <h2>
              <Message code="epic.fortnite.500.copy" />
            </h2>
            <p>
              <Message code="epic.fortnite.500.msg1" />
            </p>
            <p>
              <Message code="epic.fortnite.500.msg2" />
            </p>
            <a className="btn btn-display btn-error" href={generateMainSitePath('/', locale)}>
              <Message code="epic.common.home_page" />
            </a>
          </div>
        </div>
        <div className="zombie" />
      </div>
    </StatusCode>
  );
};

export default Error500;
