import styled from 'styled-components';

import {Device} from '../../constants';

import StyledSubscriptionCoreContent from './StyledSubscriptionCoreContent';
import StyledSubscriptionCoreTextWrapper from './StyledSubscriptionCoreTextWrapper';
import StyledSubscriptionCoreWrapper from './StyledSubscriptionCoreWrapper';
import SubscriptionHeroImage from './SubscriptionHero';

const StyledSubscriptionPhasedSection = styled.div`
  height: 100vh;

  @media screen and ${Device.mobileL} {
    position: relative;
  }
  ${StyledSubscriptionCoreWrapper} {
    height: 100%;
  }
  ${StyledSubscriptionCoreTextWrapper} {
    display: flex;
    flex-direction: column;
    width: 60%;
    @media screen and ${Device.mobileL} {
      position: absolute;
      bottom: 7em;
      width: 100%;
    }
  }
  ${StyledSubscriptionCoreContent} {
    width: 50%;
    .subscription-hero-right {
      padding: 50px;
    }

    ${SubscriptionHeroImage} {
      height: 100%;
      width: unset;
    }
  }
`;

export default StyledSubscriptionPhasedSection;
