import {withLocalization} from '@epic-core/localization';
import classNames from 'classnames';
import {generateRouteTo} from 'epic-fortnite-react';
import LoginActions from 'epic-fortnite-react/actions/LoginActions';
import {generateMainSitePath} from 'epic-fortnite-react/utils/FortniteUtils';
import epicTracking from 'epic-tracking';
import PropTypes from 'prop-types';
import React from 'react';
import {withRouter} from 'react-router';
import root from 'window-or-global';

import CTAButton from './CTAButton';

/**
 * Presents a title, description and button for a call to action with no background image.
 */
const BlankCallToAction = (props) => {
  const signIn = (url) => {
    const {isLoggedIn, history, locale} = props;
    if (isLoggedIn) {
      LoginActions.closeLoginModal();
      if (/^http/.test(url)) {
        root.location.href = url;
      } else {
        history.push(generateMainSitePath(url, locale));
      }
    } else {
      LoginActions.requestRegister({returnPath: generateMainSitePath(url, locale)});
    }
  };

  const onClick = () => {
    const {history, data, location, locale} = props;
    epicTracking.trackEvent('Interaction', {
      interactionType: 'click',
      eventCategory: 'marketingPage022019',
      eventAction: 'playButton',
      eventLabel: 'jumpIn',
    });
    if (data.ctaLoginRequired) {
      signIn(data.ctaLink);
      return;
    }
    const link = data.ctaLink || '/getfortnite';
    history.push(generateRouteTo(link, location, locale));
  };

  const {data, align} = props;
  const {title, theme, cta} = data;
  const backgroundStyle = {};

  if (data?.backgroundColor) {
    backgroundStyle.backgroundColor = data.backgroundColor;
  }
  if (data?.fontColor) {
    backgroundStyle.color = data.fontColor;
  }
  const descriptionStyle = {};
  if (data?.descriptionFontColor) {
    descriptionStyle.color = data.descriptionFontColor;
  }

  const ctaString = cta.toUpperCase() || this.getMessage('epic.fortnite.battleRoyale.playFreeNow');

  const linkStyle = JSON.parse(JSON.stringify(descriptionStyle));
  linkStyle.fontWeight = 'bold';
  // If props align is passed then use as default. Other wise default to bottom-left.
  const defaultButtonClasses = align || 'bottom-left';
  const buttonClasses = classNames(data.buttonAlign || defaultButtonClasses, 'text-center');
  const containerClasses = classNames('cta-container', (theme || '').split(','));
  const wrapperClasses = classNames('cta-wrapper');

  return (
    <div className={containerClasses} style={backgroundStyle}>
      <div className={wrapperClasses}>
        <div className={buttonClasses}>
          {title ? (
            <div>
              <h1 style={{color: data?.titleFontColor || 'white'}}>{title}</h1>
            </div>
          ) : (
            ''
          )}
          {data.description ? (
            <p dangerouslySetInnerHTML={{__html: data.description}} style={descriptionStyle} />
          ) : (
            ''
          )}
          <CTAButton onClick={onClick}>{ctaString}</CTAButton>
          {data.exitLink && data.exitLinkText ? (
            <p className="exitLink">
              <a style={linkStyle} href={data.exitLink}>
                {data.exitLinkText}
              </a>
            </p>
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  );
};

BlankCallToAction.propTypes = {
  data: PropTypes.object,
  align: PropTypes.string,
  isLoggedIn: PropTypes.bool,
  history: PropTypes.object,
  buttonStyle: PropTypes.string,
};

export default withLocalization(withRouter(BlankCallToAction));
