import {ContextMessage as Message} from '@epic-core/localization';
import PropTypes from 'prop-types';
import React from 'react';
import root from 'window-or-global';

import animateScrollToY from '../../utils/AnimateScrollToY';

export default class RetailHeader extends React.PureComponent {
  static propTypes = {
    data: PropTypes.object,
    isMobile: PropTypes.bool,
  };
  scrollTo = () => {
    const jumpToId = 'bundleSection';
    const bodyTop = root.document.body.getBoundingClientRect().top;
    const element = root.document.getElementById(jumpToId);
    const eScrollOffset = element.getBoundingClientRect().top - bodyTop;
    animateScrollToY(eScrollOffset, 500, 'easeInOutCubic');
  };
  render() {
    const {data, isMobile} = this.props;
    const {bundleTitle, description, hero, background} = data;
    const bkImageSrc = isMobile ? background.tabletImage : background.desktopImage;
    const backgroundImage = background ? {backgroundImage: `url(${bkImageSrc})`} : {};
    const heroImage = hero ? {backgroundImage: `url(${hero})`} : {};
    return (
      <div className="retail-header">
        <div className="backgroundImage" style={backgroundImage} />
        <div className="container-fluid contentContainer">
          <div className="row">
            <div className="col-sm-6 text-center">
              <div className="content-left">
                <div className="logoContainer">
                  <div className="logo" />
                  <h3>{bundleTitle}</h3>
                </div>
                <div className="content">
                  <div
                    className="bundle-description"
                    dangerouslySetInnerHTML={{__html: description}}
                  />
                  <div onClick={this.scrollTo} role="presentation" className="btn btn-border">
                    <Message code="epic.fortnite.retailBundle.header.seeBundle" />
                    <i className="icon-reverse-right-arrow" />
                  </div>
                </div>
              </div>
            </div>
            <div className="heroContainer">
              <div className="hero" style={heroImage} />
              <div className="hero" style={heroImage} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
