import {AllHtmlEntities} from 'html-entities';
import PropTypes from 'prop-types';
import React from 'react';
import Helmet from 'react-helmet';

import {getHelmetTags} from '../../utils/metaTags';

const htmlEntities = new AllHtmlEntities();

/**
    Best with CMS Content Type => Epic Common Share Meta
*/
const MetaTags = ({meta, pageModel}) => {
  /** New Global way we handle cms meta tags going foward. */

  if (pageModel) {
    const metaData = getHelmetTags(pageModel);
    return (
      <Helmet
        link={metaData.links}
        meta={metaData.meta}
        title={htmlEntities.decode(metaData.title)}
      />
    );
  }

  if (!meta) return null;

  const {title, description, image, ogDescription, ogTitle, twitterTitle, twitterDescription} =
    meta;
  const tags = [];
  if (title) {
    tags.push(
      {itemProp: 'name', content: title},
      {name: 'twitter:title', content: twitterTitle || title},
      {property: 'og:title', content: ogTitle || title}
    );
  }

  if (description) {
    tags.push(
      {itemProp: 'description', content: description},
      {name: 'description', content: description},
      {name: 'twitter:description', content: twitterDescription || description},
      {property: 'og:description', content: ogDescription || description}
    );
  }

  if (image) {
    tags.push({name: 'twitter:image', content: image}, {property: 'og:image', content: image});
  }

  return <Helmet meta={tags} title={htmlEntities.decode(title)} />;
};

MetaTags.propTypes = {
  meta: PropTypes.object,
  pageModel: PropTypes.object,
};

export default MetaTags;
