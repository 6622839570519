import React from 'react';

import {CmsComponentMapper} from '@epic-mw/react-cms';
import SimpleTextWithBackground from '../blocks/SimpleTextWithBackground';

import SideBySide from '../bundles/SideBySide';
import {CoreSection} from '../blocks/CoreSection';

const NestedBlocksBaseplate = (props) => {
  const {blocks, shared, condensed} = props;

  return (
    <CoreSection coreSectionProps={props?.coreSectionProps}>
      <CmsComponentMapper
        dynamicPageDataArray={blocks}
        componentMapping={{
          sideBySide: (props: React.ComponentProps<typeof SideBySide>) => <SideBySide {...props} />,
          textSection: (props: React.ComponentProps<typeof SimpleTextWithBackground>) => (
            <SimpleTextWithBackground
              alt={shared && shared === 'true'}
              {...props}
              condensed={condensed}
            />
          ),
        }}
      />
    </CoreSection>
  );
};

export default NestedBlocksBaseplate;
