import styled from 'styled-components';

const StyleBaseline = styled.div`
  background: white;

  & p,
  & div {
    font-family: BurbankBigRegular-Black, sans-serif;
    text-transform: uppercase;
  }

  .ru & p,
  .ru & div {
    font-family: BurbankBigCondensed-Black, sans-serif;
  }

  .ko & p {
    font-family: NotoSansCJKkr, sans-serif;
  }
  .ko & div {
    font-family: aERIN, sans-serif;
  }

  .ja & p {
    font-family: NotoSansCJKjp, sans-serif;
  }
  .ja & div,
  .ja & button {
    font-family: BurbankBigRegular-black, Tazugane, sans-serif;
  }

  .ar & p,
  .ar & div,
  .ar & button {
    font-family: NotoNaskhArabicUI, sans-serif;
  }
  .ar & div,
  .ar & button.btn {
    font-weight: 700;
  }
`;

export default StyleBaseline;
