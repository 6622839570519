/* eslint-disable import/no-extraneous-dependencies */
import {Eula} from 'epic-graphql-types';

interface Replacement {
    pattern: RegExp;
    replacement: string;
}

export const replace = (title: string, replacements: Replacement[]): string => {
    let next = title;
    replacements.forEach(obj => {
        next = next.replace(obj.pattern, obj.replacement);
    });
    return next;
};

const titleTagsReplacements = [
    {
        pattern: /\n/gi,
        replacement: ''
    },
    {
        pattern: /<EULA\.HighlightItalic>([^<]*)<\/>/gi,
        replacement: '$1'
    },
    {
        pattern: /<EULA\.Header>([^<]*)<\/>/gi,
        replacement: '$1'
    },
    {
        pattern: /<EULA\.SubHeader>([^<]*)<\/>/gi,
        replacement: '$1'
    },
    {
        pattern: /<EULA\.Highlight>([^<]*)<\/>/gi,
        replacement: '$1'
    }
];

const contentTagsReplacements = [
    {
        pattern: /<a.*href="([^"]*)".*>([^<]*)<\/>/gi,
        replacement: '[$2]($1)'
    },
    {
        pattern: /<EULA\.HighlightItalic>([^<]*)<\/>/gi,
        replacement: '**__$1__**'
    },
    {
        pattern: /<EULA\.Header>([^<]*)<\/>\n?\n?/gi,
        replacement: '# $1\n\n'
    },
    {
        pattern: /<EULA\.SubHeader>([^<]*)<\/>\n?\n?/gi,
        replacement: '## $1\n\n'
    },
    {
        pattern: /<EULA\.Highlight>([^<]*)<\/>/gi,
        replacement: '**$1**'
    },
    {
        // Prevent accidental lists from being created
        // 1. License Grant
        pattern: /(\d+)\.([\s\\])/gi,
        replacement: '$1\\.$2'
    }
];

export const eulaTitleTagsToMarkdown = (title: string): string => replace(title, titleTagsReplacements);
export const eulaContentTagsToMarkdown = (title: string): string => replace(title, contentTagsReplacements);

export const formatEulaAsMarkdown = (eulaResponse: Eula): Eula => {
    const convertedEula = {...eulaResponse};

    if (convertedEula.title) {
        convertedEula.title = eulaTitleTagsToMarkdown(convertedEula.title);
    }
    if (convertedEula.body) {
        convertedEula.body = eulaContentTagsToMarkdown(convertedEula.body);
    }
    return convertedEula;
};
