import styled from 'styled-components';

const HowToSection = styled.section`
  padding: 5rem 0 7.5rem;
  background: white;
  position: relative;
  display: flex;
  justify-content: center;
`;

export default HowToSection;
