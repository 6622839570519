import React from 'react';
import VisibilitySensor from 'react-visibility-sensor';

import BattlePassSubTitle from './BattlePassSubTitle';
import BattlePassTitle from './BattlePassTitle';
import FeatureTitleContainer from './FeatureTitleContainer';
import FlexContainer from './FlexContainer';

interface Props {
  title: string;
  subtitle: string;
  mobile: boolean;
  tablet?: boolean;
  active: boolean;
}
const FeatureHeroTitle = ({title, subtitle, active, mobile}: Props) => {
  const [visible, setVisibility] = React.useState(false);

  return (
    <VisibilitySensor
      active={!visible}
      onChange={(visibility) => setVisibility(visibility)}
      partialVisibility>
      <FeatureTitleContainer active={active}>
        <FlexContainer>
          <BattlePassTitle
            className="heroCarousel-title"
            initial={{opacity: 0, y: 100}}
            animate={visible ? {opacity: 1, y: 0} : {opacity: 0, y: 100}}
            transition={{ease: 'easeInOut', duration: 0.5, delay: 0.1}}
            large>
            {title}
          </BattlePassTitle>
          <BattlePassSubTitle
            className="heroCarousel-subtitle"
            initial={{opacity: 0, y: 100}}
            animate={visible ? {opacity: 1, y: 0} : {opacity: 0, y: 100}}
            transition={{ease: 'easeInOut', duration: 0.5, delay: 0.2}}>
            {subtitle}
          </BattlePassSubTitle>
        </FlexContainer>
      </FeatureTitleContainer>
    </VisibilitySensor>
  );
};

export default FeatureHeroTitle;
