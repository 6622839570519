/**
 * @ignore All styles are supposed to be here, and then be bundled together by webpack
 */
import '../styles/icons.sass';
import './views/blogView.sass';
import './views/ArticleView.sass';
import './components/common/categoryFilter.sass';
import './components/blog/blogDetail.sass';
import './components/blog/FeatureBanner.sass';
import './components/blog/GridLayout.sass';
import './components/blog/GridItem.sass';
import './components/loadScreen/LoadScreen.sass';
import './components/message/Alert.sass';
import './components/common/LinkNavigator.sass';
import 'font-awesome/css/font-awesome.min.css';
