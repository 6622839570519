import {getLocale} from '@epic-core/common';
import {datasource} from 'alt-utils/lib/decorators';
import {seamlessImmutable, seamlessBootstrap} from 'epic-alt-utils';
import alt from 'epic-fortnite-react/alt';
import Immutable from 'seamless-immutable';

import PatchNotesActions from '../actions/PatchNotesActions';
import PatchNotesSource from '../sources/PatchNotesSource';

const initialState = Immutable({
  slug: null,
  patchNotes: null,
  loading: false,
  error: false,
});

@datasource(PatchNotesSource)
@seamlessImmutable
class PatchNotesStore {
  constructor() {
    this.on('error', (error) => {
      console.error(error);
    });
    this.on('bootstrap', seamlessBootstrap.bind(this, null));
    this.state = initialState;
    this.bindActions(PatchNotesActions);
  }
  /**
   * Get blog list info
   * @param {object} blogOptions
   */
  onGetPatchNotes(key) {
    if (key === this.state.slug) return;
    this.getInstance().getPatchNotes(key, getLocale());
    this.setState(this.state.set('loading', true));
  }

  onClearErrorStatus() {
    if (this.state.patchNotes && this.state.patchNotes.error) {
      this.setState(this.state.set('patchNotes', null));
    }
  }

  onGetPatchNotesSuccess(response) {
    this.setState(
      this.state.merge({
        loading: false,
        error: false,
        slug: response.data.slug,
        patchNotes: response.data,
      })
    );
  }

  onGetPatchNotesFailure(error) {
    this.setState(
      this.state.merge({
        loading: false,
        slug: null,
        patchNotes: null,
        error: true,
      })
    );
  }
}

export default alt.createStore(PatchNotesStore, 'PatchNotesStore');
