import PropTypes from 'prop-types';
import React from 'react';
import { Route } from 'react-router-dom';

const StatusCode = ({ code, children }) => {
    return (
        <Route
            render={({ staticContext }) => {
                if (staticContext) staticContext.statusCode = code;
                return children;
            }}
        />
    );
};

StatusCode.propTypes = {
    code: PropTypes.number,
    children: PropTypes.element
};

export default StatusCode;
