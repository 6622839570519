import styled from 'styled-components';

const StyledSubscriptionCTAContainer = styled.div<{backgroundImage?: string}>`
  margin-top: 25px;
  width: 100%;
  height: 50px;
  position: relative;

  .cta-background {
    height: 100%;
    width: unset;
    position: absolute;
    top: 0;
    bottom: 0;
  }

  .cta-background-left {
    left: -20px;
  }
  .cta-background-right {
    right: -20px;
  }
`;

export default StyledSubscriptionCTAContainer;
