import React from 'react';
import {HttpErrorScreen, LoadScreen} from 'epic-fortnite-react';
import {
  useCmsData,
  PageData,
  getInitialCmsPageSharedValues,
  URLPatternBuilder,
} from 'epic-fortnite-shared-values';
import {RouteComponentProps, withRouter} from 'react-router';
import styled from 'styled-components';
import {CoreSectionProps} from '../../components/bundles/types';
import {CTA as CTAProps} from '../../components/cosplay/types';
import MobileLandingHero from '../../components/mobile/MobileLandingHero';
import MetaTags from '../../components/common/MetaTags';
import MobileLandingBaseplate from '../../components/baseplate/MobileLandingBaseplate';

interface MobilePageData extends PageData {
  hero: any;
  sections: MobilePageSections;
}

interface MobilePageSections {
  blocks: Block[];
}

interface Block {
  [key: string]: SideBySideBlock | TextSectionBlock | FaqBlock;
}

interface SideBySideBlock {
  heroImage: string;
  layout: string;
  heroBackgroundImage: string;
  imageAnimated: boolean;
  backgroundImageAltText: string;
  heroImageAltText: string;
  textSection: TextSectionBlock;
  coreSectionProps: CoreSectionProps;
}
interface TextSectionBlock {
  textContent: string;
  textAlignment: 'left' | 'center' | 'right';
  textHeader: string;
  subtitle: string;
  headerColor: string;
  textContentColor: string;
  logoAsHeader: string;
  legalText: string;
  coreSectionProps: CoreSectionProps;
  ctaConfig: CTAProps;
}
interface FaqBlock {
  title: string;
  description: string;
  questions: FaqQuestion[];
}

interface FaqQuestion {
  question: string;
  answer: string;
}

interface MobilePlatformParams {
  platform: string;
}

type EventDetailsProps = RouteComponentProps<MobilePlatformParams>;

const StyledMobilePlatformWrapper = styled.div`
  background-color: #fff;
`;

const MobilePlatformPage = ({match}) => {
  const {pageData, isLoading, error} = useCmsData<MobilePageData>(
    `mobile-landing-page/${match?.params?.platform}`
  );

  const {hero, sections} = pageData;

  if (isLoading) {
    return <LoadScreen show />;
  }

  if (error) {
    return <HttpErrorScreen statusCode={error.response?.status ?? 500} />;
  }

  if (!pageData) {
    return <HttpErrorScreen statusCode={404} />;
  }

  return (
    <StyledMobilePlatformWrapper>
      <MetaTags pageModel={pageData} />
      {hero && <MobileLandingHero data={hero} />}
      <MobileLandingBaseplate blocks={sections?.blocks} />
    </StyledMobilePlatformWrapper>
  );
};

const slugBuilder: URLPatternBuilder = (match: EventDetailsProps['match']) => {
  const slug = match.params.platform;
  return `mobile-landing-page/${slug}`;
};

MobilePlatformPage.getInitialSharedValues = getInitialCmsPageSharedValues(slugBuilder);

export default withRouter(MobilePlatformPage);
