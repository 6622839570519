import {getLocale} from '@epic-core/common';
import {generateRouteTo} from 'epic-fortnite-react';
import {connectToStores} from 'epic-alt-utils';
import {getMatchGuide} from 'epic-fortnite-react/utils/FortniteUtils';
import {YoutubeVideoEmbed} from 'epic-react-common';
import PropTypes from 'prop-types';
import React from 'react';
import Helmet from 'react-helmet';
import {Link, withRouter} from 'react-router-dom';

import {PlayGuideStore} from '../../stores';

@connectToStores
class PlayGuideHomeView extends React.Component {
  static propTypes = {
    playGuideStore: PropTypes.object,
  };

  static getStores() {
    return [PlayGuideStore];
  }

  static getPropsFromStores() {
    return {
      playGuideStore: PlayGuideStore.getState(),
    };
  }

  render() {
    const {title, videoId, subText, backImage, leftImage, menu, guides, location} =
      this.props.playGuideStore.playGuide;
    const backgroundImage = `url(${leftImage || ''}), url(${backImage || ''})`;

    const locale = getLocale();

    return (
      <div className="play-guide-view home" style={{backgroundImage}}>
        <Helmet title={title} />
        <div className="content">
          <div className="guides">
            {menu.list.map((item) => {
              const guide = getMatchGuide({guides, id: item.link});
              const style = {backgroundImage: `url(${item.image})`};
              return (
                <Link
                  to={generateRouteTo(`/play-guide/${guide._slug}`, location, locale)}
                  className="guide"
                  key={guide._id}
                  style={style}>
                  <span>{guide._title}</span>
                </Link>
              );
            })}
          </div>
          <div className="guide-info">
            <h2>{title}</h2>
            <div>
              <YoutubeVideoEmbed video={videoId} play={false} mute={true} launcher={false} />
            </div>
            <div className="guide-text" dangerouslySetInnerHTML={{__html: subText}} />
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(PlayGuideHomeView);
