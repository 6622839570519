import {AnimateIn, Container, Section, Stack, Text} from 'epic-fortnite-react';
import React from 'react';

import {CoreSectionProps, FunctionalThemeProps, SimpleTextProps} from './types';

interface SpotlightProps {
  heroImage: string;
  heroImageAltText?: string;
  textSection: SimpleTextProps;
  coreSectionProps: CoreSectionProps;
  theme: FunctionalThemeProps;
}

const Spotlight = (props: SpotlightProps) => {
  const {coreSectionProps, heroImage, heroImageAltText, textSection, theme} = props;
  return (
    <Section
      backgroundColor={theme.pageBackgroundColor ? theme.pageBackgroundColor : '#fff'}
      backgroundImage={coreSectionProps?.backgroundImage}
      backgroundPosition={coreSectionProps?.backgroundPosition}
      backgroundSize={coreSectionProps?.backgroundSize}
      size="4xl"
      textAlign="center">
      <Container maxWidth={800} size="lg">
        <Stack align="center" gap="lg">
          {heroImage && (
            <AnimateIn>
              <img
                src={heroImage}
                alt={heroImageAltText ? heroImageAltText : ''}
                style={{height: 'auto', width: '100%'}}
              />
            </AnimateIn>
          )}
          {(textSection?.textHeader || textSection?.textContent || textSection?.subtitle) && (
            <Stack align="center" animateIn={true} gap="lg">
              {textSection?.textHeader && (
                <Text
                  as="h2"
                  color="var(--bundle-text-color--theme)"
                  size="3xl"
                  transform="uppercase">
                  {textSection?.textHeader}
                </Text>
              )}
              {textSection?.textContent && (
                <Text
                  color="var(--bundle-text-color--body)"
                  lineLength="lg"
                  size="lg"
                  transform="uppercase">
                  {textSection?.textContent}
                </Text>
              )}
              {textSection?.subtitle && (
                <Text
                  color="var(--bundle-text-color--body)"
                  font="OpenSans"
                  lineLength="lg"
                  size="xs">
                  {textSection?.subtitle}
                </Text>
              )}
            </Stack>
          )}
        </Stack>
      </Container>
    </Section>
  );
};

export default Spotlight;
