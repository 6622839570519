import {ContextMessage as Message} from '@epic-core/localization';
import Error404 from 'epic-fortnite-react/components/error/Error404';
import Error500 from 'epic-fortnite-react/components/error/Error500';
import React from 'react';

import {ContentContainer, LoadingContainer, LoadingImage, LoadingTitle} from './components';

interface PageData {
  error: any;
  data: any;
  loading: boolean;
}

interface Props {
  page: PageData;
}
const CmsLoadScreen = ({page}: Props) => {
  //show page
  if (page && page?.loading && page?.error) return null;

  //show error
  if (page && !page?.loading && page.error) {
    switch (page.error.statusCode) {
      case 404:
        return <Error404 />;
      case 500:
        return <Error500 />;
      default:
        return null;
    }
  }
  return (
    <LoadingContainer>
      <ContentContainer>
        <LoadingImage />
        <LoadingTitle>
          <Message code="epic.common.loading" />
        </LoadingTitle>
      </ContentContainer>
    </LoadingContainer>
  );
};

export default CmsLoadScreen;
