import styled from 'styled-components';

import {breakpoints} from '../../../constants/Breakpoints';

const StepList = styled.ol`
  margin: 3rem 2rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 2rem;
  padding-left: 0;

  @media ${breakpoints.mobileL} {
    grid-template-columns: 1fr;
  }
`;

export default StepList;
