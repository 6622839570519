import axios from 'axios';
import {getLocale} from '@epic-core/common';
import root from 'window-or-global';

import LoginActions from '../actions/LoginActions';
import Url from '../constants/Url';

/**
 * LoginSource
 *
 * Used to get user login info
 *
 */
const LoginSource = {
  getAccountInfo: {
    remote(state) {
      return axios.get(Url().LOGIN.INFO(getLocale()));
    },
    success: LoginActions.getAccountInfoSuccess,
    error: LoginActions.getAccountInfoFailure,
    shouldFetch() {
      return !root.__server_side_render;
    },
  },
  preRegister: {
    remote(state) {
      return axios.get(Url().LOGIN.PREREGISTER, {
        headers: {'Accept-Language': getLocale()},
      });
    },
    success: LoginActions.requestPreRegisterSuccess,
    error: LoginActions.requestPreRegisterFailure,
    shouldFetch() {
      return !root.__server_side_render;
    },
  },
};

export default LoginSource;
