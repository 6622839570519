import React from 'react';

import MetaTags from '../common/MetaTags';

import ArchiveLinkSection, {ArchiveLinkSectionProps} from './ArchiveLinkSection';
import {SocialShareProps} from './components/SocialShare';
import StyleBaseline from './components/StyleBaseline';
import DetailsHeroSection, {DetailsHeroSectionProps} from './DetailsHeroSection';
import ExploreMasksSection, {ExploreMasksSectionProps} from './ExploreMasksSection';
import GenericExploreSection, {GenericExploreSectionProps} from './GenericExploreSection';
import HowItWorksSection, {HowItWorksSectionProps} from './HowItWorksSection';
import MaskCarouselSection, {MaskCarouselSectionProps} from './MaskCarouselSection';

interface Props {
  data: {
    hero: Omit<DetailsHeroSectionProps, 'accent'>;
    howItWorks: HowItWorksSectionProps;
    accentColor: string;
    carousel: Omit<MaskCarouselSectionProps, 'accent' | 'socialShare'>;
    additionalCarousel: Omit<MaskCarouselSectionProps, 'accent' | 'socialShare'>;
    exploreFoldables: Omit<ExploreMasksSectionProps, 'accent' | 'layout'>;
    explore: Omit<GenericExploreSectionProps, 'accent'>;
    socialShare?: SocialShareProps['socialShare'];
    isArchived?: boolean;
    archiveLink?: ArchiveLinkSectionProps;
  };
  additionalCarouselSection?: boolean;
}

const FoldableDetailPage = ({data, additionalCarouselSection = false}: Props) => {
  const {
    hero = {},
    howItWorks,
    accentColor: accent,
    carousel,
    additionalCarousel,
    exploreFoldables,
    explore,
    socialShare = undefined,
    isArchived,
    archiveLink,
  } = data;

  return (
    <StyleBaseline>
      <MetaTags pageModel={data} />
      <DetailsHeroSection {...hero} accent={accent} />
      <HowItWorksSection {...howItWorks} />
      <MaskCarouselSection {...carousel} alternate socialShare={socialShare} accent={accent} />
      {additionalCarouselSection ? (
        <MaskCarouselSection {...additionalCarousel} socialShare={socialShare} accent={accent} />
      ) : null}
      {!isArchived ? (
        <ExploreMasksSection layout="tight" masks={exploreFoldables?.masks} accent={accent} />
      ) : null}
      {!isArchived ? <GenericExploreSection {...explore} accent={accent} /> : null}
      {isArchived === true && archiveLink ? <ArchiveLinkSection {...archiveLink} /> : null}
    </StyleBaseline>
  );
};

export default FoldableDetailPage;
