import PropTypes from 'prop-types';
import React from 'react';

const SimpleTabHeader = ({filteredTabs, onSetActiveTab, activeTab, theme}) => {
  const tabItems = React.useMemo(() => filteredTabs.map((tab) => tab.tabLabel), [filteredTabs]);
  const headerStyles = theme && theme.neutralHeaderColor ? {color: theme.neutralHeaderColor} : null;
  const activeHeaderStyle =
    theme && theme.accentFontColor
      ? {color: theme.accentFontColor, borderBottomColor: theme.accentFontColor}
      : null;

  return (
    <div className="simple-tab-header-container">
      {tabItems.map((item, index) => {
        const isActive = activeTab === index;
        const {component, redirectTo} = filteredTabs[index];
        if (component && component === 'Redirect' && /^http/.test(redirectTo)) {
          return (
            <div key={index} className="tab-item-container">
              <a
                href={redirectTo}
                className={`tab-item ${isActive ? 'tab-item-active' : null}`}
                style={isActive ? activeHeaderStyle : headerStyles}>
                {item}
                <div className={`tab-item-border-container`}>
                  <div
                    className={`tab-item-border`}
                    style={{backgroundColor: theme.accentFontColor}}></div>
                </div>
              </a>
            </div>
          );
        }
      })}
    </div>
  );
};

SimpleTabHeader.propTypes = {
  filteredTabs: PropTypes.array,
  onSetActiveTab: PropTypes.func,
  activeTab: PropTypes.number,
  theme: PropTypes.object,
};

export default SimpleTabHeader;
