import PropTypes from 'prop-types';
import React from 'react';
import root from 'window-or-global';

import animateScrollToY from '../../utils/AnimateScrollToY';

/**
 * Quick Link  Component
 *
 */
class QuickLink extends React.PureComponent {
  scrollTo = () => {
    const jumpToId = this.props.hashTag;
    const bodyTop = root.document.body.getBoundingClientRect().top;
    const element = root.document.getElementById(jumpToId);
    const eScrollOffset = element.getBoundingClientRect().top - bodyTop;
    animateScrollToY(eScrollOffset, 500, 'easeInOutCubic');
  };

  render() {
    return (
      <li role="presentation" className="sectionLink" onClick={this.scrollTo}>
        {this.props.title}
        {this.props.children}
      </li>
    );
  }
}

QuickLink.propTypes = {
  title: PropTypes.string,
  hashTag: PropTypes.string,
  children: PropTypes.object,
};

export default QuickLink;
