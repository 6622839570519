import styled from 'styled-components';

import {breakpoints} from '../../constants/Breakpoints';
interface Props {
  fontColor: string;
  titleColor: string;
  descriptionFontColor: string;
}

const ImageOverlay = styled.div<Props>`
  height: 100%;
  position: absolute;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${({fontColor}) => fontColor};

  @media ${breakpoints.mobileL} {
    justify-content: flex-end;
  }

  & > {
    z-index: 1;
  }
`;

export default ImageOverlay;
