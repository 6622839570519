import {Device} from 'epic-fortnite-styles/constants';
import styled from 'styled-components';

const FooterLogo = styled.img`
  position: absolute;
  height: 4em;
  top: 3em;
  left: 6em;
  @media screen and ${Device.laptopL} {
    height: 40px;
    top: 27px;
    left: 55px;
  }
  @media screen and ${Device.laptop} {
    height: 35px;
    top: 15px;
    left: 39px;
  }
  @media screen and ${Device.mobileL} {
    display: none;
  }
`;

export default FooterLogo;
