import {Container, FAQ, Section} from 'epic-fortnite-react';
import React from 'react';

import {RichTextSection} from '../../components/blocks/RichTextSection';
import {LinkSection} from '../../components/blocks/LinkSection';

import SimpleTextWithBackground from '../../components/blocks/SimpleTextWithBackground';
import SimpleCardSection from '../../components/simpleCard/SimpleCardSection';
import {CmsComponentMapper} from '@epic-mw/react-cms';

const MobileLandingBaseplate = ({blocks}) => {
  return (
    <CmsComponentMapper
      dynamicPageDataArray={blocks}
      componentMapping={{
        cardSection: (props: React.ComponentProps<typeof SimpleCardSection>) => (
          <Section size="2xl">
            <Container maxWidth={1600} size="med">
              <SimpleCardSection {...props} />
            </Container>
          </Section>
        ),
        linkSection: (props: React.ComponentProps<typeof LinkSection>) => (
          <LinkSection {...props} />
        ),
        textSection: (props: React.ComponentProps<typeof SimpleTextWithBackground>) => (
          <SimpleTextWithBackground {...props} />
        ),
        richTextSection: (props: React.ComponentProps<typeof RichTextSection>) => (
          <RichTextSection {...props} />
        ),
        faqSection: (props: React.ComponentProps<typeof FAQ>) => (
          <Section size="4xl">
            <Container maxWidth={800} size="med">
              <FAQ {...props} />
            </Container>
          </Section>
        ),
      }}
    />
  );
};

export default MobileLandingBaseplate;
