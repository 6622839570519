import {getLocale} from '@epic-core/common';
import {datasource} from 'alt-utils/lib/decorators';
import {seamlessBootstrap, seamlessImmutable} from 'epic-alt-utils';
import {alt} from 'epic-react-common';
import Immutable from 'seamless-immutable';
import root from 'window-or-global';

import BlogActions from '../actions/BlogActions';
import BlogSource from '../sources/BlogSource';

const initialState = Immutable({
    newsInitCount: 0,
    incrementCount: 8,
    nextLoadCount: 0,
    stickyArticles: 0,
    articlesToLoad: 16,
    commentCount: 0,
    blogTotal: 0,
    category: null,
    loadedSlug: null,
    blogList: [],
    categoryTotals: {},
    topFeatured: null,
    previewData: {},
    hasMoreArticles: true,
    initLoading: true,
    loadingArticles: false,
    requestedCategory: null,
    isCategoryFilter: false,
    loading: false,
    error: false,
    config: {},
    loadingMore: false,
    _metaTags: null
});

/**
 * Returns the top featured blog and mutates the allBlogs parameter to remove it from
 * the list
 * @param {*} topFeatured, allBlogs
 */
const getTopFeatured = function getTopFeatured(topFeatured, allBlogs) {
    if (!topFeatured) {
        let featuredIndex = 0;
        while (featuredIndex < allBlogs.length) {
            if (allBlogs[featuredIndex].featured) {
                break;
            }
            featuredIndex++;
        }
        return featuredIndex === allBlogs.length ? null : allBlogs.splice(featuredIndex, 1);
    }
    if (!topFeatured || !topFeatured.length) {
        console.warn(
            'getTopFeatured() failed to retrieve the first trending/featured post',
            allBlogs.length,
            topFeatured
        );
    }
    return topFeatured;
};

/**
 * Store
 */
@datasource(BlogSource)
@seamlessImmutable
class BlogStore {
    initialState = initialState;
    getStateFunctions() {
        return {
            getArticle(slug) {
                if (!slug) return {};
                if (
                    this.topFeatured &&
                    this.topFeatured.length > 0 &&
                    this.topFeatured[0].slug === slug
                ) {
                    return this.topFeatured[0];
                }
                for (let i = 0; i < this.blogList.length; i++) {
                    const blogEntry = this.blogList[i];
                    if (blogEntry.slug === slug) {
                        return blogEntry;
                    }
                }
                return {};
            },
            countStickyArticles() {
                let stickyArticles = 0;
                if (this.topFeatured && this.topFeatured.length > 0) {
                    stickyArticles = this.topFeatured.length;
                }
                for (let i = 0; i < this.blogList.length; i++) {
                    const blogEntry = this.blogList[i];
                    if (blogEntry.sticky) {
                        stickyArticles++;
                    }
                }
                return stickyArticles;
            },
            totalArticles() {
                if (!this.blogList && !this.topFeatured) return 0;
                let total = 0;
                if (this.topFeatured) {
                    total += this.topFeatured.length;
                }
                if (this.blogList) {
                    total += this.blogList.length;
                }
                return total;
            },
            getInitialState() {
                return initialState;
            }
        };
    }

    constructor() {
        this.on('error', (error) => {
            console.error(error);
        });
        this.on(
            'bootstrap',
            seamlessBootstrap.bind(this, (state) => {
                const defaultState = this.initialState.merge(this.getStateFunctions());
                if (state.blogPreloadData) {
                    // Server-side
                    return defaultState.merge(
                        this.processBlogData(state.blogPreloadData || {}, defaultState)
                    );
                }
                return defaultState.merge(state);
            })
        );
        this.state = this.initialState;
        this.state = this.state.merge(this.getStateFunctions());
        this.bindActions(BlogActions);
        this.registerAsync(BlogSource);
    }

    /**
     * Get blog list info
     * @param {object} blogOptions
     */
    onGetBlogData(blogOptions) {
        if (this.state.loadingArticles && !this.state.initLoading) return false;
        const previewId = this.state.previewData ? this.state.previewData.id : null;
        const category = blogOptions.category ? blogOptions.category.toLowerCase() : 'all';
        const loadMore = blogOptions.loadMore;
        const rootPageSlug = blogOptions.rootPageSlug;
        const changedBlogRootSlug =
            this.state.loadedSlug && blogOptions.rootPageSlug !== this.state.loadedSlug;
        const changedBlogType =
            this.state.config.type && this.state.config.type !== blogOptions.rootPageSlug;
        const changedCategory = category !== this.state.category;

        //storeCategory is null and newCategory is all and we already have data, we don't want to fetch
        if (
            !loadMore &&
            !this.state.initLoading &&
            category === 'all' &&
            !this.state.category &&
            this.state.blogList &&
            this.state.blogList.length
        ) {
            return false;
        }
        if (changedCategory || changedBlogRootSlug || changedBlogType) {
            // switch categories if new category is received
            this.setState(
                this.state.merge({
                    loadingArticles: !root.__server_side_render,
                    initLoading: !!root.__server_side_render,
                    nextLoadCount: 0,
                    isCategoryFilter: blogOptions.isCategoryFilter,
                    topFeatured: null,
                    blogList:
                        changedBlogRootSlug || changedBlogType
                            ? Immutable([])
                            : this.state.blogList, // Reset blog list if type changes
                    requestedCategory: category
                })
            );

            return this.getInstance().getBlogData(
                blogOptions.initCount || 0, // articles to fetch, 0 to allow service to initialize from blog config in cms
                0, // offset, 0 to allow service to initialize from blog config in cms
                category,
                getLocale(),
                previewId,
                rootPageSlug
            );
        } else if (loadMore) {
            // load next results in current category
            this.setState(
                this.state.merge({
                    loadingArticles: true,
                    loadingMore: true,
                    initLoading: !!root.__server_side_render
                })
            );
            return this.getInstance().getBlogData(
                blogOptions.postsPerPage || this.state.incrementCount,
                this.state.nextLoadCount,
                category,
                getLocale(),
                previewId,
                rootPageSlug
            );
        } else if (this.state.initLoading) {
            this.setState(this.state.set('initLoading', !!root.__server_side_render));
        }

        return true;
    }

    /**
     * Check the slugs of each item in the blogList array and return an array of unique blogs
     */
    getUniqueBlogList(blogList) {
        if (!blogList || !blogList.length) {
            return [];
        }

        const blogMap = {};
        const uniqueBlogList = [];
        blogList.forEach((blog) => {
            const slug = blog.slug;
            if (blog.title && slug && !blogMap[slug]) {
                blogMap[slug] = true;
                uniqueBlogList.push(blog);
            }
        });

        return uniqueBlogList;
    }

    /**
     * Merge response to state once getBlogData Succeeds
     * @param  {object} responseData
     * @param  {object} blogState
     * @return {object} new blogState
     */
    processBlogData(responseData, blogState) {
        if (typeof responseData !== 'object') {
            console.warn('processBlogData() returned bad response');
            this.onGetBlogDataFailure();
            return {};
        }

        const state = blogState || this.state;
        const clientLoad = state.nextLoadCount !== 0;
        const category =
            responseData.category || state.requestedCategory || state.category || 'all';
        const articlesToLoad = responseData.articlesToLoad || state.articlesToLoad;
        const incrementCount = responseData.incrementCount || state.incrementCount;
        const blogList = responseData.blogList;
        const categoryTotal =
            category === 'all' ? responseData.blogTotal : responseData.categoryTotals[category];
        let allBlogs = clientLoad ? state.blogList.concat(blogList) : blogList;
        allBlogs = this.getUniqueBlogList(allBlogs);
        const totalBlogs = state.topFeatured
            ? allBlogs.length + state.topFeatured.length
            : allBlogs.length;
        const hasMoreArticles = categoryTotal > totalBlogs;
        const config = responseData.config ? responseData.config : state.config;
        const loadedSlug = responseData.loadedSlug || state.loadedSlug;
        const nextLoadOffset = clientLoad ? state.nextLoadCount + incrementCount : articlesToLoad;
        const _metaTags = responseData._metaTags;

        // Setup the top featured if we found an element in the list
        const topFeatured = getTopFeatured(state.topFeatured, allBlogs);

        const newState = {
            initLoading: !!root.__server_side_render,
            loadingArticles: false,
            requestedCategory: null,
            isCategoryFilter: false,
            blogList: allBlogs,
            blogTotal: responseData.blogTotal,
            categoryTotals: responseData.categoryTotals || {},
            hasMoreArticles,
            topFeatured,
            nextLoadCount: nextLoadOffset,
            category,
            config,
            articlesToLoad,
            incrementCount,
            loadedSlug,
            catLocaleMap: responseData.catLocaleMap || {},
            _metaTags
        };
        return newState;
    }

    onGetBlogDataSuccess(res) {
        const config = res.config || {};
        const params = config.params || {};
        const rootPageSlug = params.rootPageSlug;
        res.data.loadedSlug = rootPageSlug;

        const newState = this.processBlogData(res.data);

        //force the categoryTotals to be empty so previous keys don't remain when it gets updated with a different object
        this.setState(this.state.merge({categoryTotals: null}, {deep: true}));

        this.setState(this.state.merge(newState, {deep: true}));
    }

    onGetBlogDataFailure(res) {
        console.warn('onGetBlogData method failed');
        this.setState(
            this.state.merge({
                initLoading: false,
                loadingArticles: false
            })
        );
    }

    /**
     * Gets a single blog post
     * @param {object} params property used => object.slug && object.previewId
     */
    onGetBlogPost(params) {
        const blogParams = params;
        if (
            this.state.previewData &&
            this.state.previewData.id &&
            this.state.previewData.slug === params.slug
        ) {
            blogParams.previewId = this.state.previewData.id;
        } else {
            const blog = this.state.getArticle(params.slug);
            if (blog && blog.slug) {
                return; //already have it
            }
        }

        this.setState(
            this.state.merge({
                loading: true,
                error: false
            })
        );
        this.getInstance().getBlogPost(blogParams);
    }

    onGetBlogPostSuccess(res) {
        const blogData = res.data || {};

        if (blogData.slug && blogData.urlPattern) {
            this.setState(
                this.state.merge(
                    {
                        blogList: this.state.blogList.concat([blogData]),
                        loading: false
                    },
                    {deep: true}
                )
            );
        } else {
            this.setState(
                this.state.merge({
                    loading: false,
                    error: true
                })
            );
        }
    }

    onGetBlogPostFailure() {
        this.setState(
            this.state.merge({
                loading: false,
                error: true
            })
        );
    }

    /**
     * Get social metrics
     * @param {object} params params.slug will be used
     */
    onGetBlogSocialShareCount(params) {
        const {slug} = params;
        const blogParams = params;
        const article = this.state.getArticle(slug);
        if (this.state.blogList.length && article && article.totalSocialCount) {
            return;
        }
        this.setState(
            this.state.merge({
                error: false,
                loadingSocialCount: true
            })
        );
        this.getInstance().getBlogSocialShareCount(blogParams);
    }

    onGetBlogSocialShareCountSuccess(res) {
        const {slug, totalCount} = res;
        let totalSocialCount = 0;
        // only set social count if data is valid and has totalCount
        if (res.dataIsValid && totalCount) {
            totalSocialCount = totalCount;
        }
        let blogIndex = -1;
        for (let i = 0; i < this.state.blogList.length; i++) {
            const blog = this.state.blogList[i];
            if (blog.slug === slug) {
                blogIndex = i;
                break;
            }
        }
        if (blogIndex >= 0) {
            this.setState(
                this.state.setIn(['blogList', blogIndex, 'totalSocialCount'], totalSocialCount)
            );
        }
    }

    onGetBlogSocialShareCountFailure(res) {
        console.warn(`Failure: ${res.message}, responded with ${res.status}`);
    }

    onResetError() {
        this.setState(
            this.state.merge({
                error: false
            })
        );
    }

    onSetStoreState(newState) {
        const freshState = initialState.merge(newState);
        this.setState(this.state.merge(freshState));
    }
}

export default alt.createStore(BlogStore, 'BlogStore');
