import {FortniteNavigation} from '@wf-components/navigation-react';
import {getLocale} from '@epic-core/common';
import React, {useCallback} from 'react';
import {useHistory, useLocation} from 'react-router';
import root from 'window-or-global';

import {generateRouteHref, generateRouteTo} from '../../utils/route';
import {trackLink} from './nav.tracking';

import type {
  NavAccountLinkClickEvent,
  NavCtaClickEvent,
  NavLinkClickEvent,
  NavLocaleClickEvent,
  NavPropertyLogoClickEvent,
  NavSearchEvent,
} from '@wf-components/navigation-consumer';

const ISLAND_CODE_PATTERN = /^\d{4}-\d{4}-\d{4}$/;

type NavWrapperProps = {
  appOwner: 'esports' | 'legacy';
  displayName: string | undefined;
  isLoggedIn: boolean;
  locale: string;
};

type NavClickEvent =
  | NavAccountLinkClickEvent
  | NavCtaClickEvent
  | NavLinkClickEvent
  | NavPropertyLogoClickEvent;

const LINK_ACCOUNT_ID_KEYS = ['psn', 'switch', 'xbl'];

export const NavWrapper: React.FC<NavWrapperProps> = ({
  displayName,
  isLoggedIn,
  locale: appProp,
}) => {
  const history = useHistory();
  const location = useLocation();
  const locale = appProp || getLocale();
  const clientConfig = root.clientEnvConfig || {};
  const serverUrl = clientConfig.EPIC_SERVER_URL;

  const handleNavSearch = useCallback(
    (e: CustomEvent<NavSearchEvent>) => {
      const query = e.detail.value?.trim() || '';
      const isIslandCodeQuery = ISLAND_CODE_PATTERN.test(query?.trim() || '');
      const path = isIslandCodeQuery ? `/creative/island-codes/${query}` : `/search`;

      if (isIslandCodeQuery) {
        /**
         * In MW-3594 we started detecting 12-digit island codes in user input so that
         * we redirect users directly to an island page without them having to go
         * through the regular search results page. However, since both remix and
         * legacy apps live under the same hostname, they both "compete" for the same
         * browser session history, leading to conflicts and errors when not using the
         * native browser APIs and rely on React router instead. For that reason is important
         * to not use React Router's `history.push()` when navigating to URL that belongs to
         * a remix app page (and vise versa).
         */

        window.location.assign(generateRouteHref({pathname: path}, location, locale));
      } else {
        const searchParams = new URLSearchParams();
        searchParams.set('q', query);

        const to = {pathname: path, search: searchParams?.toString()};

        history.push(generateRouteTo(to, location, locale));
      }
    },
    [history]
  );

  const handleLocaleChange = useCallback(
    (e: CustomEvent<NavLocaleClickEvent>): void => {
      const event = e.detail.originalEvent;
      const link = e.detail.link;

      trackLink(link, event, locale);

      event.preventDefault();

      const {pathname, search} = location;
      const localeSearchParams = new URLSearchParams(search);

      // Retain all existing URL params, but since we're
      // changing the app locale, always override/set `lang`.
      localeSearchParams.set('lang', link.langCode);

      window.location.assign(`${pathname}?${localeSearchParams}`);
    },
    [locale, location]
  );

  const handleNavElementClick = useCallback(
    (e: CustomEvent<NavClickEvent>): void => {
      const event = e.detail.originalEvent;
      const link = e.detail.link;
      const isOwner = link.owner === 'remix' || link.owner === 'legacy';

      trackLink(link, event, locale);

      if (isOwner && link.relativePath) {
        event.preventDefault();

        const linkUrl = new URL(link.relativePath, serverUrl);

        // Ensure account "link PSN / Twitch / XBL" links are given a `redirectUrl`
        // param so users are redirected back to where the journey began while maintaining
        // all needed params.
        if (LINK_ACCOUNT_ID_KEYS.includes(link['key'])) {
          const redirectUrl = generateRouteHref(
            {pathname: location.pathname, search: location.search},
            location,
            locale
          );

          linkUrl.searchParams.set('redirectUrl', `${serverUrl}${redirectUrl}`);
        }

        // We're using `generateRouteHref` despite no client-side routing taking place
        // in order to retain lang, utm_* and all params that should be retained, as
        // they are defined in routes util module.
        const href = generateRouteHref(
          {pathname: linkUrl.pathname, search: linkUrl.searchParams.toString()},
          location,
          locale
        );

        window.location.assign(href);
      }
    },
    [locale, location]
  );

  const redirectTo = `${location.pathname}${location.search}`;
  const params = new URLSearchParams({redirectTo, locale});

  return (
    <FortniteNavigation
      accountLinkOnClick={handleNavElementClick}
      ctaOnClick={handleNavElementClick}
      displayName={displayName}
      domain={serverUrl}
      isLoggedIn={isLoggedIn}
      locale={locale}
      localeOnClick={handleLocaleChange}
      navLinkOnClick={handleNavElementClick}
      navOnSearch={handleNavSearch}
      propLogoOnClick={handleNavElementClick}
      signInLinkHref={`/login?${params}`}
      signOutLinkHref={`/logout?${params}`}
      skipNavContentId="main"
    />
  );
};
