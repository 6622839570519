import {RedeemSuccessView} from '@epic-code-redemption/client';
import {connectToStores} from 'epic-alt-utils';
import LoginActions from 'epic-fortnite-react/actions/LoginActions';
import PropTypes from 'prop-types';
import React from 'react';

import Background from '../../components/common/Background';
import {LoginStore, AppConfigStore} from '../../stores';
import {RedeemPageStore} from '../../stores';

@connectToStores
class RedeemSuccessWrapperView extends React.Component {
  static propTypes = {
    loginStore: PropTypes.object,
    appConfigStore: PropTypes.object,
    redeemPageStore: PropTypes.object,
  };

  static getStores() {
    return [LoginStore, AppConfigStore, RedeemPageStore];
  }

  static getInitialActions() {
    return [
      () => {
        LoginActions.getAccountInfo.defer({reload: true});
      },
    ];
  }

  static getPropsFromStores() {
    return {
      loginStore: LoginStore.getState(),
      redeemPageStore: RedeemPageStore.getState(),
      appConfigStore: AppConfigStore.getState(),
    };
  }

  render() {
    const downloadUrlPc = this.props.appConfigStore.pcDownloadUrl;
    const downloadUrlMac = this.props.appConfigStore.macDownloadUrl;
    const iosDownloadUrl = '/download';
    const androidDownloadUrl = '/mobile/android';
    const {redeemPageStore = {}} = this.props;
    const {background} = redeemPageStore;
    return (
      <div id="redeemSuccessView" className="redeemContainer">
        <Background background={background} />
        <RedeemSuccessView
          isLoggedIn={this.props.loginStore.isLoggedIn}
          macDownloadUrl={downloadUrlMac}
          pcDownloadUrl={downloadUrlPc}
          iosDownloadUrl={iosDownloadUrl}
          androidDownloadUrl={androidDownloadUrl}
          supportedOS={['windows', 'mac', 'android', 'ios']}
          {...this.props}
        />
      </div>
    );
  }
}

export default RedeemSuccessWrapperView;
