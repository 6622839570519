import PropTypes from 'prop-types';
import React from 'react';

import QAsection from './QAsection';

/**
 * FaqSection  Component
 *
 */
const FaqSection = function FaqSection(props) {
  const sectContentData = props.sectContent;
  const {title} = props;
  const titleID = title.replace(/ |\(|\)/g, '-').toLowerCase();
  let sectContent;
  if (sectContentData) {
    sectContent = sectContentData.map((item, index) => {
      const {question, answer} = item;
      return <QAsection key={question} question={question} answer={answer} />;
    });
  }

  return (
    <section id={titleID} className="section">
      <div className="row content">
        <div className="col-xs-12">
          <h2>{title}</h2>
        </div>
      </div>
      {sectContent}
    </section>
  );
};

FaqSection.propTypes = {
  title: PropTypes.string,
  sectContent: PropTypes.array,
};

export default FaqSection;
