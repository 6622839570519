import root from 'window-or-global';

export default function () {
  /**
   * Enum for URLs for the API endpoints.
   */
  let appContext = '';
  if (root.appContext && root.appContext !== '/') {
    ({appContext} = root);
  }

  if (!root.location.origin) {
    const port = root.location.port ? `:${root.location.port}` : '';
    root.location.origin = `${root.location.protocol}//${root.location.hostname}${port}`;
  }
  const origin = root.apiHost ? root.apiHost : root.location.origin + appContext;

  const URL = {
    //Add URLs for ajax calls here
    ORIGIN: `${origin}`,
    //EXAMPLE: `${origin}/api/data/example`
    PLAYERS: {
      USERNAME: `${origin}/api/userName`,
    },

    ERRORS: {
      NOTFOUND: `${origin}/api/notFound`,
      FAILED: `${origin}/api/unexpectedError`,
    },

    APPLICATION: {
      LOGIN: `${origin}/login`,
    },

    EULA: {
      LATEST: `${origin}/ajax/eula/get/latest`,
    },

    SFS: {
      COMMANDERS: `${origin}/api/commanders`,
      COMMANDER: `${origin}/api/commander`,
    },

    BLOG: {
      GETPOSTS: `${origin}/api/blog/getPosts`,
      GETPOST: `${origin}/api/blog/getPost`,
      GETFEATURED: `${origin}/api/blog/getFeatured`,
      GETCOMMENTCOUNT: `${origin}/assets/ajax-get-comment-count`,
    },

    HERORACE: {
      RACES: `${origin}/api/heroRaces`,
      LEADERBOARD: `${origin}/api/leaderboard`,
      WINNER: `${origin}/api/leaderboard/winner`,
      CUMULATIVE: `${origin}/api/leaderboard/cumulative`,
      CUMULATIVE_ACTIVE: `${origin}/api/leaderboard/cumulative/active`,
    },

    SUBSCRIBE: {
      INTEREST_LIST: `${origin}/api/interest-list`,
      ADD_USER_NEWSLETTER: `${origin}/api/add-user-newsletter`,
    },

    FRIEND_INVITE: `${origin}/api/friend`,

    PATCH_NOTES: {
      GET(key, locale) {
        return `${origin}/${locale}/api/patch-notes/${key}`;
      },
    },

    USER_ISLANDS: `${origin}/api/creative/user-islands`,
    PAGE: {
      GET(locale, slug, type) {
        return `${origin}/${locale}/api/page?slug=${slug}&type=${type}`;
      },
    },
    HELP_CENTER: {
      GET(locale) {
        return `${origin}/${locale}/api/help-center`;
      },
    },
    COSPLAY: {
      OVERVIEW(locale) {
        return `${origin}/${locale}/api/cosplay`;
      },
      MASK(locale, mask) {
        return `${origin}/${locale}/api/cosplay/${mask}`;
      },
      ARCHIVE(locale, page, ordering) {
        return `${origin}/${locale}/api/cosplay/archive?page=${page}&ordering=${ordering}`;
      },
    },
    CMS_STORE: {
      GET(locale, slug) {
        return `${origin}/${locale}/api/cms/${slug}`;
      },
    },
    BATTLEPASS: {
      GET(locale) {
        return `${origin}/${locale}/api/battle-pass-data`;
      },
    },
    RIFTTOUR: {
      GET(locale) {
        return `${origin}/${locale}/api/rift-tour-data`;
      },
    },
    CREATIVE: {
      FAVORITE_ISLAND: `${origin}/api/creative/islands/add-favorites`,
      CHECK_PLAYLIST_QUEUE: `${origin}/api/creative/islands/check-playlist-queue`,
      ADD_TO_PLAYLIST: `${origin}/api/creative/islands/add-to-playlist-queue`,
      REMOVE_FROM_PLAYLIST: `${origin}/api/creative/islands/remove-from-playlist-queue`,
      YOUTUBE_IMG: 'https://img.youtube.com/vi/{videoId}/maxresdefault.jpg',
    },
  };

  return URL;
}
