import VideoOverlay from 'epic-fortnite-react/components/common/VideoOverlay';
import {Inline, ResetButton, Text} from 'epic-fortnite-react';
import styled from 'styled-components';
import React, {useState} from 'react';
import {localizedMessage} from 'epic-fortnite-react/components/message/Message';
import * as Portal from '@radix-ui/react-portal';

type WatchVideoProps = {
  videoId?: string;
  text?: string;
  textColor?: string;
};

const StyledVideoPlayButton = styled(ResetButton)`
  margin: 0 auto;
  background-color: #000;
  border: 2px solid transparent;
  transition: background-color 0.3s, border-color 0.3s;
  outline: none;
  padding: 0.8rem;

  &:hover {
    background-color: #1f2937;
  }

  @media screen and (max-width: 550px) {
    .fr &,
    .ar & {
      span {
        font-size: 1rem;
      }
    }
  }

  body .ar & {
    left: 24px;
    right: unset;
  }
`;

export const WatchVideo = ({videoId, text, textColor = '#fff'}: WatchVideoProps) => {
  const [showVideo, setShowVideo] = useState(false);

  return (
    <>
      {videoId && (
        <StyledVideoPlayButton className="play-wrapper" onClick={() => setShowVideo(true)}>
          <Inline flex="start" gap="2xs" justify="center" wrap={false}>
            <Text align="center" color={textColor} size="lg" transform="uppercase">
              {text ? text : localizedMessage('epic.fortnite.trailer')}
            </Text>
            {/** Commented out play svg button below incase this is needed in the future. */}
            {/* <svg width="48" height="48" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="24" cy="24" r="23.5" fill="#000" fillOpacity=".6" stroke="#fff" />
              <path
                d="M31.869 22.4a2 2 0 010 3.2L22.2 32.856c-1.319.99-3.201.049-3.201-1.6V16.744c0-1.648 1.882-2.589 3.2-1.6L31.87 22.4z"
                fill="#fff"
              />
            </svg> */}
          </Inline>
        </StyledVideoPlayButton>
      )}
      {showVideo && (
        <Portal.Root>
          <VideoOverlay
            videoID={videoId}
            isLauncher={false}
            play={showVideo}
            close={() => setShowVideo(false)}
            closeButton
            show={showVideo}
          />
        </Portal.Root>
      )}
    </>
  );
};
