import styled from 'styled-components';

import {breakpoints} from '../../../../constants/Breakpoints';

const ImageWrapper = styled.div`
  position: relative;
  width: 100%;

  img {
    width: 100%;
  }

  @media ${breakpoints.tablet} {
    width: unset;

    img {
      width: unset;
    }
  }

  @media ${breakpoints.mobileL} {
    width: 100%;

    img {
      width: 100%;
    }
  }
`;

export default ImageWrapper;
