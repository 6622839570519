import {withLocalization} from '@epic-core/localization';
import React from 'react';
import Select from 'react-select';

import DropdownIndicator from './DropdownIndicator';

const components = {DropdownIndicator};

const styles = {
  indicatorSeparator: () => ({
    backgroundColor: 'unset',
  }),
  menu: (provided) => ({
    ...provided,
    width: '100%',
    marginTop: -2,
    marginRight: 0,
    marginLeft: 0,
    boxShadow: 'none',
    borderTop: 'none',
    borderRight: '1px solid hsl(0,0%,80%)',
    borderLeft: '1px solid hsl(0,0%,80%)',
    borderBottom: '1px solid hsl(0,0%,80%)',
  }),
  control: (provided) => ({
    ...provided,
    borderColor: 'hsl(0,0%,80%)',
    '&:hover': {
      borderColor: 'hsl(0,0%,80%)',
    },
    boxShadow: 'none',
  }),
};

interface Option {
  label: string;
  value: string;
  disabled?: boolean;
}

interface Props {
  options: Option[];
  setValue: (code: string) => void;
  placeholder?: string;
  getMessage: (code: string) => string;
  width?: number;
  className?: string;
}

const DropDown = ({options, setValue, placeholder, getMessage, ...rest}: Props) => {
  const onChange = React.useCallback((item) => setValue(item.value), [setValue]);
  const noOptionsMessage = React.useCallback(
    () => getMessage('epic.common.no_results'),
    [getMessage]
  );
  return (
    <Select
      placeholder={placeholder}
      isOptionDisabled={(option) => option.disabled}
      onChange={onChange}
      noOptionsMessage={noOptionsMessage}
      options={options}
      styles={styles}
      components={components}
      {...rest}
    />
  );
};

export default withLocalization(DropDown);
