import {getLocale} from '@epic-core/common';
import {generateRouteTo} from 'epic-fortnite-react';
import {isSamsung} from 'epic-fortnite-react/utils/PlatformUtils';
import PropTypes from 'prop-types';
import React from 'react';
import Helmet from 'react-helmet';
import {Redirect, Route, Switch} from 'react-router';
import {withRouter} from 'react-router';
import root from 'window-or-global';

import AndroidDownload from '../../components/fortniteMobile/AndroidDownload';
import ConfirmPage from '../../components/fortniteMobile/ConfirmPage';
import DeviceSelect from '../../components/fortniteMobile/DeviceSelect';
import SignUpPage from '../../components/fortniteMobile/SignUpPage';

class AndroidView extends React.Component {
  static propTypes = {
    cmsData: PropTypes.object,
    isLoggedIn: PropTypes.bool,
    mobile: PropTypes.bool,
    tablet: PropTypes.bool,
    location: PropTypes.object,
    status: PropTypes.object,
    downloadData: PropTypes.object,
    bowser: PropTypes.object,
    androidLink: PropTypes.string,
  };

  render() {
    const {isLoggedIn, mobile, tablet, cmsData, status, downloadData, bowser, androidLink} =
      this.props;

    const hideSignUp = cmsData.createAccount || false;
    const hideDownload = cmsData.hideDownload || false;
    const tags = [
      {itemProp: 'name', content: cmsData.metaTitle},
      {itemProp: 'description', content: cmsData.metaDescription},
      {itemProp: 'image', content: cmsData.metaImage},
      {name: 'description', content: cmsData.metaDescription},
      {name: 'twitter:title', content: cmsData.metaTitle},
      {name: 'twitter:description', content: cmsData.metaDescription},
      {name: 'twitter:image', content: cmsData.metaImage},
      {property: 'og:title', content: cmsData.metaTitle},
      {property: 'og:image', content: cmsData.metaImage},
      {property: 'og:description', content: cmsData.metaDescription},
    ];

    const isAndroid = bowser.android;
    const osVersion = bowser?.os.version;
    const download = !hideDownload;

    //check if Samsung Devices with useragent
    const {userAgent} = root.navigator;
    const samsung = isSamsung(userAgent);

    //redirects to correct download link
    const location = this.props.location;
    const locale = getLocale();
    let redirect = '/mobile/android/sign-up';
    const search = location.search || '';
    if (hideSignUp && !download) {
      redirect = '/page-not-found';
    } else if (hideSignUp) {
      redirect = '/mobile/android/new-device';
    }
    return (
      <div className="android-view theEnd-background-override" data-osversion={osVersion}>
        <Helmet meta={tags} title={cmsData.metaTitle} />
        <Switch>
          {!hideSignUp
            ? [
                <Route key="AndroidSignup" path="/mobile/android/sign-up">
                  <SignUpPage
                    android
                    downloadActive={download}
                    isSamsung={samsung}
                    title={cmsData.title}
                    isLoggedIn={isLoggedIn}
                    content={cmsData.signup}
                    mobile={mobile}
                    tablet={tablet}
                    location={location}
                    status={status}
                    locale={locale}
                  />
                </Route>,
                <Route key="androidDeviceSelect" path="/mobile/android/device-select">
                  <DeviceSelect
                    android
                    content={cmsData.device}
                    isLoggedIn={isLoggedIn}
                    status={status}
                    title={cmsData.title}
                    locale={locale}
                  />
                </Route>,
                <Route key="androidConfirm" path="/mobile/android/confirm">
                  <ConfirmPage
                    android
                    content={cmsData.confirm}
                    title={cmsData.title}
                    status={status}
                    locale={locale}
                  />
                </Route>,
              ]
            : null}
          {download
            ? [
                <Route key="androidNewDevice" path="/mobile/android/new-device">
                  <AndroidDownload
                    newFlow
                    supportedDevices={downloadData.supportDevices}
                    isSamsung={samsung}
                    samsungData={downloadData.samsung}
                    osVersion={osVersion}
                    data={downloadData.newDevices}
                    link={androidLink}
                    isAndroid={isAndroid}
                    locale={locale}
                  />
                </Route>,
                <Route key="androidOldDevice" path="/mobile/android/old-device">
                  <AndroidDownload
                    oldFlow
                    supportedDevices={downloadData.supportDevices}
                    isSamsung={samsung}
                    samsungData={downloadData.samsung}
                    osVersion={osVersion}
                    isAndroid={isAndroid}
                    link={androidLink}
                    data={downloadData.oldDevices}
                    locale={locale}
                  />
                </Route>,
                <Route key="androidNotSupported" path="/mobile/android/not-supported">
                  <AndroidDownload
                    notSupported
                    osVersion={osVersion}
                    isAndroid={isAndroid}
                    link={androidLink}
                    data={downloadData.notSupported}
                    locale={locale}
                  />
                </Route>,
                <Route key="androidDownload" path="/mobile/android/download">
                  <Redirect
                    to={generateRouteTo(
                      {
                        pathname: redirect,
                        search,
                      },
                      location,
                      locale
                    )}
                  />
                </Route>,
              ]
            : null}
        </Switch>
      </div>
    );
  }
}

export default withRouter(AndroidView);
