import React, {useState} from 'react';
import VisibilitySensor from 'react-visibility-sensor';

import {triggerAnalytic} from '../../utils/SubscriptionUtils';

import StyledSubscriptionVBucksSection from './StyledSubscriptionVBucksSection';
import SubscriptionCoreContent from './SubscriptionCoreContent';

interface Props {
  content: any;
  mobile: boolean;
  tablet: boolean;
}

const SubscriptionVBucksSection = (props: Props) => {
  const {content, mobile} = props;
  const [isVisible, setVisibility] = useState(false);
  const onChange = (): void => {
    setVisibility(!isVisible);
    if (isVisible) {
      triggerAnalytic('SubscriptionVBucks');
    }
  };

  return (
    <VisibilitySensor onChange={onChange} partialVisibility minTopValue={10}>
      <StyledSubscriptionVBucksSection>
        <SubscriptionCoreContent content={content} mobile={mobile} />
      </StyledSubscriptionVBucksSection>
    </VisibilitySensor>
  );
};

export default SubscriptionVBucksSection;
