import epicTracking from 'epic-tracking';
import React from 'react';
import root from 'window-or-global';

import SocialButton from './SocialButton';
import SocialButtonGroup from './SocialButtonGroup';
import SocialIcon from './SocialIcon';
import SocialWrapper from './SocialWrapper';

type Provider = 'twitter' | 'facebook' | 'vk' | 'reddit';

const share = (provider: Provider) => {
  const currentPageURL = root.location.href;
  const openShareWindow = (url: string) =>
    root.open(url, '_blank', 'toolbar=no, resizeable=yes, width=600, height=450');

  epicTracking.trackEvent('Interaction', {
    interactionType: 'click',
    eventCategory: 'social-cosplay-button',
    eventAction: 'share',
    eventLabel: provider,
  });

  switch (provider) {
    case 'twitter':
      return openShareWindow(
        `https://twitter.com/intent/tweet?url=${encodeURIComponent(currentPageURL)}`
      );
    case 'facebook':
      return openShareWindow(
        `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(currentPageURL)}`
      );
    case 'vk':
      return openShareWindow(`http://vk.com/share.php?url=${encodeURIComponent(currentPageURL)}`);
    case 'reddit':
      return openShareWindow(
        `https://www.reddit.com/submit?url=${encodeURIComponent(currentPageURL)}`
      );
    default:
      console.error(`Could not match social media share provider "${provider}"`);
  }
};

export interface SocialShareProps {
  socialShare?: {
    providers?: Provider[];
    label?: string;
  };
}

const SocialShare = ({socialShare = undefined}: SocialShareProps) => {
  if (!socialShare?.providers || socialShare.providers.length === 0) {
    return null;
  }

  const {providers, label} = socialShare;

  return (
    <SocialWrapper>
      {/* silly fragment use to get the &nbsp; rendering correctly, as the character code doesn't work in template literal */}
      {label ? <>{`${label}`}&nbsp;</> : null}
      <SocialButtonGroup>
        {providers.map((provider) => (
          <SocialButton
            key={provider}
            id={`sharebutton-${provider}`}
            onClick={() => share(provider)}>
            <SocialIcon className={`icon-${provider}`} role="presentation" id={provider} />
          </SocialButton>
        ))}
      </SocialButtonGroup>
    </SocialWrapper>
  );
};

export default SocialShare;
