import {getLocale} from '@epic-core/common';
import axios from 'axios';
import root from 'window-or-global';

import SubscribeActions from '../actions/SubscribeActions';
import Url from '../constants/Url';

/**
 * SubscribeSource
 *
 */

const SubscribeSource = {
  addUserToNewsletter: {
    remote(state, interests) {
      return axios.get(Url().SUBSCRIBE.ADD_USER_NEWSLETTER, {
        headers: {'Accept-Language': getLocale()},
      });
    },
    success: SubscribeActions.addUserToNewsletterSuccess,
    error: SubscribeActions.addUserToNewsletterFailure,
    shouldFetch() {
      return !root.__server_side_render;
    },
  },
};

export default SubscribeSource;
