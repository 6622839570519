import PropTypes from 'prop-types';
import React from 'react';

/**
 * Question/Answer  Component
 *
 */
const QAsection = function QAsection(props) {
  return (
    <div className="row">
      <div className="col-xs-12">
        <div className="question">
          <p>{props.question}</p>
        </div>
        <div className="answer">
          <div dangerouslySetInnerHTML={{__html: props.answer}} />
        </div>
      </div>
    </div>
  );
};

QAsection.propTypes = {
  question: PropTypes.string,
  answer: PropTypes.string,
};

export default QAsection;
