import {motion} from 'framer-motion';
import React from 'react';

import {SlashIcon} from '../../../icons';

import SlashContainer from './SlashContainer';

interface Props {
  hide: (value: boolean) => void;
  animate: boolean;
}
const SlashySlash = ({hide, animate}: Props) => {
  const [slashComplete, setSlashComplete] = React.useState(false);
  const onComplete = React.useCallback(() => {
    if (slashComplete) hide(false);
  }, [hide, slashComplete]);

  const onSlashComplete = React.useCallback(() => {
    setSlashComplete(true);
  }, [setSlashComplete]);

  return (
    <SlashContainer
      flicker={animate}
      animate={slashComplete && animate ? {opacity: 0} : {opacity: 1}}
      transition={{duration: 0.5, ease: 'easeOut'}}
      onAnimationComplete={onComplete}>
      {animate ? (
        <>
          <motion.div
            initial={{x: 200, y: -200, opacity: 0}}
            animate={{x: [200, 0, 0], y: [-200, 0, 0], opacity: [1, 1, 0]}}
            transition={{duration: 0.2, times: [0, 0.1, 0.2]}}>
            <SlashIcon id="first" killGradient />
          </motion.div>
          <motion.div
            initial={{x: -200, y: -200, opacity: 0, rotateY: 180}}
            animate={{
              x: [-200, 0, 0],
              y: [-200, 0, 0],
              opacity: [1, 1, 0],
              rotateY: 180,
            }}
            transition={{duration: 0.2, times: [0, 0.1, 0.2], delay: 0.1}}
            onAnimationComplete={onSlashComplete}>
            <SlashIcon id="second" killGradient />
          </motion.div>
        </>
      ) : null}
    </SlashContainer>
  );
};

export default SlashySlash;
