import {Text} from 'epic-fortnite-react';
import {SlickWrapper} from 'epic-react-common';
import React from 'react';
import Slider from 'react-slick';
import styled, {css, keyframes} from 'styled-components';
import epicTracking from 'epic-tracking';

import {Device} from '../../../constants';

import FeatureCarouselItem from './FeatureCarouselItem';
import HeroBackground from './HeroBackground';
import {HeroSlide} from './Types';

interface SlickWrapper extends Slider {
  slickGoTo: (value: number) => void;
}
interface FeatureIconProps {
  data: HeroSlide;
  active: boolean;
  index: number;
  goToSlide: (value: number) => void;
}

interface FeaturedCarouselProps {
  items: HeroSlide[];
  setCurrentSlide: (value: number) => void;
  activeSlide: number;
  isVisible: boolean;
  mobile: boolean;
  tablet: boolean;
  seasonKey: string;
}

const FeatureHeroCarouselContainer = styled.div`
  position: relative;
  width: 100%;
  height: 80vh;
  min-height: 950px;
  z-index: 2;
  margin: 0;

  .slick-wrapper {
    height: 90%;
  }

  .slick-slider {
    position: relative;
    width: 100%;
    height: 100%;

    .slick-list {
      height: 100%;
      overflow: visible !important;
    }
    .slick-track {
      display: flex;
      flex-wrap: no-wrap;
      justify-content: flex-start;
      align-items: center;
      padding: 0 0;
      height: 100%;
    }
    .slick-slide {
      box-sizing: content-box;
      position: relative;
      user-select: none;
      z-index: 0;
    }
  }

  @media screen and (max-width: 940px) {
    height: 60vh;
    min-height: 675px;
    margin-top: -4em;
  }
  @media screen and ${Device.mobileL} {
    min-height: 645px;
    height: 60vh;
  }
`;

const StyledHeroNameWrapper = styled.div`
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: 275px;
  display: flex;
  justify-content: center;
  z-index: 2;

  @media screen and ${Device.tablet} {
    bottom: 235px;
  }

  @media screen and ${Device.mobileL} {
    bottom: 210px;
  }
`;

const ThumbContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 300px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  z-index: 100;
  padding-bottom: 80px;

  @media screen and ${Device.mobileL} {
    height: 250px;
  }
`;

const ScrollContainer = styled.div`
  position: relative;
  width: 925px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  ::-webkit-scrollbar {
    display: none;
  }

  @media screen and (max-width: 940px) {
    width: 760px;
    margin-bottom: -46px;
  }

  @media screen and (max-width: 740px) {
    overflow-x: scroll;
    justify-content: flex-start;
    touch-action: pan-x;
    -webkit-overflow-scrolling: touch;
    padding-bottom: 25px;
  }
`;

const glow = keyframes`
    from {
        box-shadow: 0 0 5px 5px rgba(255, 255, 255, 0.2);
    }
    to {
        box-shadow: 0 0 12px 14px rgba(255, 255, 255, 0.2);
    }
`;

const FeatureIconContainer = styled.div<{active: boolean}>`
  position: relative;
  height: 100px;
  width: 100px;
  border-radius: 50%;
  cursor: pointer;
  flex: 0 0 auto;
  margin: 0 1em;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    z-index: 0;
    border-radius: 50%;

    ${(props) =>
      props.active
        ? css`
            transform: scale3d(1.05, 1.05, 1.05);
            transition: transform 1s ease-in-out;
          `
        : css`
            transform: scale3d(0, 0, 0);
          `};
  }

  ${(props) =>
    props.active
      ? css`
          animation: ${glow} 1.5s ease-in-out infinite;
          animation-direction: alternate;
          animation-delay: 1s;
        `
      : null}

  @media screen and (max-width: 940px) {
    width: 85px;
    height: 85px;
    margin: 0 0.5em;
  }
`;

const HeroIcon = styled.img<{active: boolean}>`
  position: relative;
  width: 100%;
  filter: ${(props) => (props.active ? 'brightness(1.1)' : 'brightness(0.5)')};
  transition: all 300ms ease-in-out;
  z-index: 1;
  cursor: pointer;
  opacity: ${(props) => (props.active ? 1 : 0.8)};
  padding-bottom: 5px;

  &:hover {
    filter: brightness(1.1);
    opacity: 1;
  }
`;

const FeatureIcon = ({data, active, index, goToSlide}: FeatureIconProps) => {
  const {icon, name} = data;
  const getIndex = React.useCallback(() => {
    goToSlide(index);
    epicTracking.trackEvent('Interaction', {
      interactionType: 'click',
      eventCategory: 'clickHeroIcon',
      eventAction: 'view-hero',
      eventLabel: name,
    });
  }, [goToSlide, index, name]);
  return (
    <FeatureIconContainer onClick={getIndex} active={active}>
      <HeroIcon active={active} src={icon} alt={name} />
    </FeatureIconContainer>
  );
};

const FeaturedCarousel = ({
  seasonKey,
  items,
  activeSlide,
  setCurrentSlide,
  isVisible,
  tablet,
  mobile,
}: FeaturedCarouselProps) => {
  const [sliderRef, setSliderRef] = React.useState<SlickWrapper | null>(null);
  const [currentCharacter, setCurrentCharacter] = React.useState(0);

  const sliderSettings = React.useMemo(() => {
    return {
      dots: false,
      arrows: false,
      infinite: false,
      swipeToSlide: false,
      useCSS: false,
      useTransform: false,
      touchMove: false,
      swipe: false,
      slidesToShow: 1,
      afterChange: (current) => setCurrentSlide(current),
    };
  }, [setCurrentSlide]);

  const goToSlide = React.useCallback(
    (index) => {
      setCurrentCharacter(index);
      if (index > items.length - 1) {
        setCurrentCharacter(0);
        sliderRef?.slickGoTo(0);
        return;
      }
      if (activeSlide !== index) {
        sliderRef?.slickGoTo(index);
      }
    },
    [sliderRef, activeSlide]
  );

  return (
    <FeatureHeroCarouselContainer>
      <HeroBackground
        seasonKey={seasonKey}
        mobile={mobile}
        tablet={tablet}
        items={items}
        activeSlide={activeSlide}
        isVisible={isVisible}
      />
      <SlickWrapper ref={setSliderRef} options={sliderSettings}>
        {items.map((item, index) => {
          return (
            <FeatureCarouselItem
              isVisible={isVisible}
              active={index === activeSlide}
              key={`heroSlide-${item.key}`}
              data={item}
              mobile={mobile}
              tablet={tablet}
              index={index}
              goToSlide={goToSlide}
            />
          );
        })}
      </SlickWrapper>
      <StyledHeroNameWrapper>
        <Text
          align="center"
          color="#fff"
          lineLength="sm"
          size={mobile ? 'xl' : '3xl'}
          transform="uppercase">
          {items[currentCharacter]?.name || ''}
        </Text>
      </StyledHeroNameWrapper>
      <ThumbContainer>
        <ScrollContainer>
          {items.map((item, index) => {
            return (
              <FeatureIcon
                goToSlide={goToSlide}
                index={index}
                active={index === activeSlide}
                key={`heroThumb-${item.key}`}
                data={item}
              />
            );
          })}
        </ScrollContainer>
      </ThumbContainer>
    </FeatureHeroCarouselContainer>
  );
};

export default FeaturedCarousel;
