import epicTracking from 'epic-tracking';
import {useCallback, useEffect, useState} from 'react';
import root from 'window-or-global';

export const useVideoHeader = () => {
  const [isPlay, setPlay] = useState(false);
  const [show, setShow] = useState(false);
  const playVideo = useCallback(
    (e) => {
      setPlay(true);
      epicTracking.trackEvent('Interaction', {
        interactionType: 'click',
        eventCategory: 'Overview Video',
        eventAction: 'play Video',
        eventLabel: e.currentTarget.id,
      });
    },
    [setPlay]
  );

  const closeVideo = useCallback(
    (e) => {
      setPlay(false);
      epicTracking.trackEvent('Interaction', {
        interactionType: 'click',
        eventCategory: 'Overview Video',
        eventAction: 'close Video',
        eventLabel: e.currentTarget.id,
      });
    },
    [setPlay]
  );

  useEffect(() => setShow(true), []);
  return {isPlay, show, closeVideo, playVideo};
};

export const useVideoSize = () => {
  const [size, setSize] = useState({width: '100%', height: root.innerHeight});

  useEffect(() => {
    const handleResize = () => setSize({width: root.innerWidth, height: root.innerHeight});
    handleResize();
    root.addEventListener('resize', handleResize);
    return () => root.removeEventListener('resize', handleResize);
  }, []);
  return size;
};
