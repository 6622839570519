import {connectToStores} from 'epic-alt-utils';
import Error404 from 'epic-fortnite-react/components/error/Error404';
import PropTypes from 'prop-types';
import React from 'react';
import Helmet from 'react-helmet';

import SecureAccountStore from '../../stores/SecureAccountStore';

/**
 * Safety and Security View
 */
@connectToStores
class SecureAccountView extends React.Component {
  static propTypes = {
    secureAccountStore: PropTypes.object,
  };

  static getStores() {
    return [SecureAccountStore];
  }

  static getPropsFromStores() {
    return {
      secureAccountStore: SecureAccountStore.getState(),
    };
  }
  render() {
    if (!this.props.secureAccountStore || !this.props.secureAccountStore.data) {
      return <Error404 />;
    }
    const {
      data: {content, image, metaTitle, metaDescription, metaImage},
    } = this.props.secureAccountStore;
    const style = {
      backgroundImage: `url(${image})`,
    };
    const tags = [
      {itemProp: 'name', content: metaTitle},
      {itemProp: 'description', content: metaDescription},
      {itemProp: 'image', content: metaImage},
      {name: 'description', content: metaDescription},
      {name: 'twitter:title', content: metaTitle},
      {name: 'twitter:description', content: metaDescription},
      {name: 'twitter:image', content: metaImage},
      {property: 'og:title', content: metaTitle},
      {property: 'og:image', content: metaImage},
      {property: 'og:description', content: metaDescription},
    ];

    return (
      <div className="safetySecurityView">
        {
          // only override default meta if there is meta data in CMS
          metaTitle && <Helmet meta={tags} title={metaTitle} />
        }
        <div className="header-image" style={style} />
        <div className="content" dangerouslySetInnerHTML={{__html: content}} />
      </div>
    );
  }
}

export default SecureAccountView;
