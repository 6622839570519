import styled from 'styled-components';

const DifficultyHeader = styled.h2`
  width: 100%; // Fix for weird Safari bug entering random line breaks
  &&& {
    font-size: 2em;
  }
`;

export default DifficultyHeader;
