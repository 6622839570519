import React from 'react';
import styled from 'styled-components';

import {Device} from '../../constants';

import Content from './Content';
import DivAbsoluteBottom from './DivAbsoluteBottom';
import DivRelative from './DivRelative';
import ImageOnlyDesktop from './ImageOnlyDesktop';
import ImageOnlyMobile from './ImageOnlyMobile';

interface Images {
  src: string;
  alt: string;
}

interface Props {
  bottomImages: Images[];
  desktopBackgroundImage: string;
  mobileBackgroundImage: string;
  alt: string;
  content: string;
}

const Wrapper = styled(DivRelative)`
  margin: -6em auto -2em auto;
  z-index: 15;
  position: relative;
`;

const BottomContent = styled(DivAbsoluteBottom)`
  margin-bottom: 1.5em;
  && h3 {
    font-size: 4.65em;
    line-height: 1;
    margin: 0;
    @media screen and ${Device.tablet} {
      font-size: 7vw !important;
    }
    @media screen and ${Device.mobileL} {
      font-size: 8.1vw !important;
    }
  }
`;

const ImageTextBottom = ({desktopBackgroundImage, mobileBackgroundImage, alt, content}: Props) => {
  return (
    <Wrapper>
      <ImageOnlyDesktop src={desktopBackgroundImage} alt={alt} />
      <ImageOnlyMobile src={mobileBackgroundImage} alt={alt} />
      <BottomContent>
        <Content content={content} />
      </BottomContent>
    </Wrapper>
  );
};

export default ImageTextBottom;
