import styled from 'styled-components';

import {breakpoints} from '../../../constants/Breakpoints';

const HeroSubtitle = styled.h2`
  max-width: 94rem;
  margin-top: 0;

  &&& {
    font-size: 1.875em;
  }

  @media ${breakpoints.mobileL} {
    &&& {
      font-size: 1.25em;
    }
  }
`;

export default HeroSubtitle;
