import React from 'react';

import StyledSubscriptionCoreTextWrapper from './StyledSubscriptionCoreTextWrapper';
import StyledSubscriptionHeading from './StyledSubscriptionHeading';
import StyledSubscriptionText from './StyledSubscriptionText';

enum TextAlignEnum {
  left,
  center,
  right,
}

interface SubscriptionTextThemeInterface {
  primaryColor?: string;
  secondaryColor?: string;
  textAlign?: TextAlignEnum;
  styleOverrides?: JSON;
}

interface Props {
  header?: string;
  textSection?: string;
  theme?: SubscriptionTextThemeInterface;
}

const SubscriptionCoreText = ({header, textSection, theme}: Props) => {
  const {primaryColor, secondaryColor, textAlign, styleOverrides} = theme || {};

  return (
    <StyledSubscriptionCoreTextWrapper textAlign={textAlign} overrides={styleOverrides}>
      {header && (
        <StyledSubscriptionHeading color={primaryColor}>{header}</StyledSubscriptionHeading>
      )}
      {textSection && (
        <StyledSubscriptionText color={secondaryColor} Tag="div" html={textSection} />
      )}
    </StyledSubscriptionCoreTextWrapper>
  );
};

export default SubscriptionCoreText;
