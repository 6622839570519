import React from 'react';

interface Props {
  styles?: React.CSSProperties;
  id?: string;
}

const JaggedSection = ({styles, id}: Props) => (
  <svg style={styles} id={id} viewBox="0 0 133.1 67.6" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M133.1 0L19.6 58.8l4.9-4.2L0 67.6h133.1V0z"
      fill="#fff"
      fillRule="evenodd"
      clipRule="evenodd"
    />
  </svg>
);

export default JaggedSection;
