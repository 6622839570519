import {DialogContent, DialogOverlay, DialogOverlayProps} from '@reach/dialog';
import React from 'react';
import styled from 'styled-components';

import {Text, VisuallyHidden} from '../';

type StyledDialogOverlayProps = {
  $overlayColor?: 'light' | 'dark';
};

export interface ModalProps extends DialogOverlayProps {
  ariaLabel: string;
  closeButton?: React.ReactNode | null;
  overlayColor?: 'light' | 'dark';
  className?: string;
}

const StyledDialogOverlay = styled(DialogOverlay)<StyledDialogOverlayProps>`
  align-items: center;
  backdrop-filter: blur(8px);
  background-color: ${({$overlayColor}) =>
    $overlayColor === 'light' ? 'rgba(255, 255, 255, 0.8)' : 'rgba(0, 0, 0, 0.8)'};
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  max-height: 100vh;
  overflow: hidden;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 999999;
`;

const StyledDialogCloseButton = styled.button`
  appearance: none;
  background-color: rgba(0, 0, 0, 0.4);
  border: 0;
  height: 32px;
  position: absolute;
  right: 16px;
  text-align: center;
  top: 16px;
  width: 32px;
  z-index: 99999; // should be above global header

  &.focus-visible {
    box-shadow: inset 0px 0px 0px 4px #000;
  }
`;

export const Modal = ({
  ariaLabel,
  children,
  className,
  closeButton,
  overlayColor,
  ...props
}: ModalProps) => {
  return (
    <StyledDialogOverlay $overlayColor={overlayColor} {...props}>
      {closeButton === undefined ? (
        closeButton
      ) : closeButton !== null ? (
        <StyledDialogCloseButton onClick={props.onDismiss}>
          <VisuallyHidden>Close</VisuallyHidden>
          <Text aria-hidden="true" color="#fff" size="lg">
            X
          </Text>
        </StyledDialogCloseButton>
      ) : null}
      <DialogContent className={className} aria-label={ariaLabel}>
        {children}
      </DialogContent>
    </StyledDialogOverlay>
  );
};
