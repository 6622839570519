import {getLocale} from '@epic-core/common';
import {generateRouteTo} from 'epic-fortnite-react';
import {getAssetPath} from 'epic-fortnite-react/utils/FortniteUtils';
import React from 'react';
import {useLocation} from 'react-router';

import Medal from '../../../../../images/cosplay/medal.png';
import Stopwatch from '../../../../../images/cosplay/stopwatch.png';
import {CTA} from '../types';

import {default as CTAButton} from './CTA';
import DifficultyDescription from './DifficultyDescription';
import DifficultyHeader from './DifficultyHeader';
import DifficultyIcon from './DifficultyIcon';
import DifficultyLabel from './DifficultyLabel';
import DifficultyLevelDisplay from './DifficultyLevelDisplay';
import DifficultyWrapper from './DifficultyWrapper';
import Link from './Link';

export interface Props {
  name?: string;
  description?: string;
  time?: string;
  difficulty?: string;
  cta?: CTA;
  accent?: string;
  className?: string;
}

const DifficultyInfo = ({name, description, time, difficulty, cta, accent, className}: Props) => {
  const location = useLocation();
  const locale = getLocale();
  return (
    <DifficultyWrapper className={className}>
      {cta?.ctaLink ? (
        <Link to={generateRouteTo(cta?.ctaLink, location, locale) ?? ''}>
          <DifficultyHeader>{name}</DifficultyHeader>
        </Link>
      ) : (
        <DifficultyHeader>{name}</DifficultyHeader>
      )}
      <DifficultyDescription>{description}</DifficultyDescription>
      <DifficultyLevelDisplay>
        <DifficultyIcon src={getAssetPath(Stopwatch)} alt="" />
        <DifficultyLabel>{time}</DifficultyLabel>
        <DifficultyIcon src={getAssetPath(Medal)} alt="" />
        <DifficultyLabel>{difficulty}</DifficultyLabel>
      </DifficultyLevelDisplay>
      {cta ? <CTAButton ctaConfig={cta} background={accent} /> : null}
    </DifficultyWrapper>
  );
};

export default DifficultyInfo;
