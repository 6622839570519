import {getLocale} from '@epic-core/common';
import {datasource} from 'alt-utils/lib/decorators';
import {seamlessBootstrap, seamlessImmutable} from 'epic-alt-utils';
import alt from 'epic-fortnite-react/alt';
import Immutable from 'seamless-immutable';

import CosplayArchiveActions from '../actions/CosplayArchiveActions';
import CosplayArchiveSource from '../sources/CosplayArchiveSource';

@datasource(CosplayArchiveSource)
@seamlessImmutable
class CosplayArchiveStore {
  constructor() {
    this.on('error', console.error);
    this.on('bootstrap', seamlessBootstrap.bind(this, null));
    this.state = Immutable({
      data: null,
      loading: false,
    });
    this.bindActions(CosplayArchiveActions);
  }

  onGetArchivePages({page, ordering}) {
    if (this.state.data?.ordering === ordering && this.state.data?.pages[page]) return;

    this.getInstance().getArchivePages(getLocale(), {page, ordering});
    this.setState(
      this.state.merge({
        loading: true,
        data: {
          ...this.state.data,
          pages: {
            ...this.state.data?.pages,
            [page]: undefined,
          },
        },
        error: false,
      })
    );
  }

  onClearErrorStatus() {
    if (this.state.data && this.state.data.error) {
      this.setState(this.state.set('data', null));
    }
  }

  onGetArchivePagesSuccess(response) {
    const page = response.data._pageNumber;
    const ordering = response.data._ordering;
    if (response.data.error) {
      return this.setState(
        this.state.merge({
          loading: false,
          data: undefined,
          error: response.data.error,
        })
      );
    }
    const {items, ...data} = response.data;
    if (this.state.data?.ordering === ordering) {
      this.setState(
        this.state.merge({
          loading: false,
          error: false,
          data: {
            ...data,
            ordering,
            pages: {
              ...this.state.data?.pages,
              [page]: items,
            },
          },
        })
      );
    } else {
      // drop all cached pages with different ordering
      this.setState(
        this.state.merge({
          loading: false,
          error: false,
          data: {
            ...data,
            ordering,
            pages: {
              [page]: items,
            },
          },
        })
      );
    }
  }

  onGetArchivePagesFailure(error) {
    this.setState(
      this.state.merge({
        loading: false,
        data: null,
        error: true,
      })
    );
  }
}

export default alt.createStore(CosplayArchiveStore, 'CosplayArchiveStore');
