import {getLocale} from '@epic-core/common';
import {stripLeadingSlash} from '@epic-core/common';
import axios, {AxiosResponse} from 'axios';

import {getOrigin} from '../shared-values/utils';

import {PageData} from './cms.types';

export interface CmsUrls {
  CMS_PAGE_DATA: (locale, urlPattern) => string;
}

let customUrl: CmsUrls;

const url = () => {
  if (customUrl) return customUrl;

  const origin = getOrigin();

  const url: CmsUrls = {
    CMS_PAGE_DATA: (locale, urlPattern) =>
      `${origin}/${locale}/api/cms/${encodeURIComponent(stripLeadingSlash(urlPattern))}`,
  };
  return url;
};

class CmsApi {
  public setUrl(url: CmsUrls) {
    customUrl = url;
  }

  public async getPageData<P extends PageData>(urlPattern: string): Promise<P> {
    const api = url().CMS_PAGE_DATA(getLocale(), urlPattern);
    try {
      const response: AxiosResponse<P> = await axios.get(api);
      return response.data;
    } catch (e) {
      console.error(
        'getPageData() failed to load cms page',
        url().CMS_PAGE_DATA(getLocale(), urlPattern)
      );
      throw e;
    }
  }
}

export const cmsApi = new CmsApi();
