import React from 'react';

import Container from '../../components/Container';
import Gradient from '../../components/Gradient';
import ResponsiveImage from '../../components/ResponsiveImage';
import SpikeLayer from '../../components/SpikeLayer';
import {MaskProps} from '../../types';

import MaskDetails from './FullMaskDetails';
import Grid from './Grid';
import MaskDetailsWrapper from './MaskDetailsWrapper';
import MaskImageWrapper from './MaskImageWrapper';

interface Props extends MaskProps {
  alternateOrder?: boolean;
}
const FullwidthExplore = ({
  accent,
  name,
  description,
  image,
  backgroundGradient,
  type,
  cta,
  alternateOrder = false,
}: Props) => {
  return (
    <SpikeLayer>
      <Gradient gradient={backgroundGradient}>
        <Container>
          <Grid alternateOrder={alternateOrder}>
            <MaskDetailsWrapper>
              <MaskDetails
                accent={accent}
                type={type}
                name={name}
                description={description}
                cta={cta}
              />
            </MaskDetailsWrapper>
            <MaskImageWrapper alternateOrder={alternateOrder}>
              <ResponsiveImage
                desktopSrc={image.desktopImage}
                tabletSrc={image.tabletImage}
                mobileSrc={image.mobileImage}
                alt={image.altText}
              />
            </MaskImageWrapper>
          </Grid>
        </Container>
      </Gradient>
    </SpikeLayer>
  );
};

export default FullwidthExplore;
