import styled from 'styled-components';

import {Device} from '../../constants';

const StyledSubscriptionContentRight = styled.div`
  flex-basis: 50%;
  text-align: center;
  display: flex;
  align-items: center;

  @media screen and ${Device.laptop} {
    margin-top: -10rem;
  }

  @media screen and ${Device.tablet} {
    margin-top: -8rem;
  }

  @media screen and ${Device.mobileL} {
    margin-top: -5rem;
  }
`;

export default StyledSubscriptionContentRight;
