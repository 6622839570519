import styled from 'styled-components';

import Content from './Content';

const ContentRow100 = styled(Content)`
  padding: 4em 1em 1em 1em;
  max-width: 1000px;
  margin: 0 auto;
  width: 90%;
  p {
    font-size: 0.8em;
    line-height: 1.2;
  }
`;

export default ContentRow100;
