import queryString from 'query-string';
import {HttpErrorScreen, LoadScreen} from 'epic-fortnite-react';
import {
  getInitialCmsPageSharedValues,
  useCmsData,
  PageData,
  URLPatternBuilder,
} from 'epic-fortnite-shared-values';
import React, {useEffect, useState} from 'react';
import {withRouter, RouteComponentProps} from 'react-router';
import styled from 'styled-components';
import SimpleLandingBaseplate from '../../components/baseplate/SimpleLandingBaseplate';

import MetaTags from '../../components/common/MetaTags';

import CloudStreamingSeasonUAHeader from './CloudStreamingSeasonUAHeader';

const StyledCloudStreamingWrapper = styled.div`
  background-color: #fff;
  color: #000;
`;

export interface CmsDataProps extends PageData {
  hero: any;
  sections: any;
}

interface UAParams {
  uaPage: string;
}

type BundleDetailsProps = RouteComponentProps<UAParams>;

const UTM_MAP = {
  tttakeover: 12,
  ytmasthead: 7,
  youtube: 4,
  facebook: 6,
  instagram: 6,
  twitter: 11,
  tiktok: 5,
};

const CloudStreamingSeasonUAView = ({match, location}) => {
  const [uaBlocks, setUaBlocks] = useState([]);

  const {pageData, isLoading, error} = useCmsData<CmsDataProps>(`ua/${match?.params?.uaPage}`);
  const {hero, sections} = pageData;

  useEffect(() => {
    const {utm_source} = queryString.parse(location.search);

    if (typeof utm_source !== 'string' || !UTM_MAP[utm_source.toLowerCase()]) {
      return setUaBlocks(sections?.blocks);
    }

    sections?.blocks.forEach((block) => {
      if (block.cardSection) {
        const {cards = []} = block.cardSection;
        const xboxCloudCard = cards.find((card) => card.identifier === 'xbox_cloud');

        if (!xboxCloudCard) return;

        xboxCloudCard.href = `${xboxCloudCard.href}&utm_source=${
          UTM_MAP[utm_source.toLowerCase()]
        }`;
      }
    });

    setUaBlocks(sections?.blocks);
  }, [pageData]);

  if (isLoading) {
    return <LoadScreen show />;
  }

  if (error) {
    return <HttpErrorScreen statusCode={error.response?.status ?? 500} />;
  }

  if (!pageData) {
    return <HttpErrorScreen statusCode={404} />;
  }

  return (
    <StyledCloudStreamingWrapper>
      <MetaTags pageModel={pageData} />
      {hero ? <CloudStreamingSeasonUAHeader data={hero} /> : null}
      {uaBlocks && uaBlocks.length ? <SimpleLandingBaseplate blocks={sections?.blocks} /> : null}
    </StyledCloudStreamingWrapper>
  );
};

const slugBuilder: URLPatternBuilder = (match: BundleDetailsProps['match']) => {
  const slug = match.params.uaPage;
  return `ua/${slug}`;
};

CloudStreamingSeasonUAView.getInitialSharedValues = getInitialCmsPageSharedValues(slugBuilder);

export default withRouter(CloudStreamingSeasonUAView);
