import React from 'react';

import {StyledCreativeCoreTextWrapper, StyledCreativeHeading, StyledCreativeText} from './';

interface Props {
  header?: string;
  textSection?: string;
}

const CreativeCoreText = ({header, textSection}: Props) => {
  return (
    <StyledCreativeCoreTextWrapper>
      {header && <StyledCreativeHeading>{header}</StyledCreativeHeading>}
      {textSection && <StyledCreativeText Tag="div" html={textSection} />}
    </StyledCreativeCoreTextWrapper>
  );
};

export default CreativeCoreText;
