import StatusCode from 'epic-fortnite-react/components/common/StatusCode';
import BlackoutView from 'epic-fortnite-react/views/blackout/BlackoutView';
import React from 'react'; //eslint-disable-line no-unused-vars
import {Redirect, Route, Switch} from 'react-router';

import DownloadView from '../views/download/DownloadView';
import FnMobileView from '../views/fortniteMobile/FnMobileView';
import MobileLanding from '../views/mobile/MobileLanding';
import MobilePlatformPage from '../views/mobile/MobilePlatformPage';
import PosaRedeemWrapperView from '../views/redeem/PosaRedeemWrapperView';
import VbucksCardTermsView from '../views/redeem/VbucksCardTermsView';
import HelpCenterView from '../views/helpCenter/HelpCenterView';
import SecureAccountView from '../views/safetySecurity/SecureAccountView';
import FaqView from '../views/faq/FaqView';

const redirectLocale = (destination, route) => {
  let destPath = null;
  if (typeof destination === 'function') {
    destPath = destination(route.match.params);
  } else {
    destPath = destination;
  }
  const location = route.location || {};
  const search = location.search || '';

  const redirectUrl = `${destPath}${search}`;

  return <Redirect to={redirectUrl} />;
};

const goAndroid = redirectLocale.bind(null, '/mobile/android');

const getBlackoutRoutes = () => {
  const routes = (
    <Switch>
      <Route exact path={`/vbuckscard`} component={PosaRedeemWrapperView} />
      <Route exact path={`/vbuckscardterms`} component={VbucksCardTermsView} />
      <Route path={`/download`} component={DownloadView} />
      <Route path={`/android`} render={goAndroid} />
      <Route path={`/mobile/android/new-device`} component={FnMobileView} />
      <Route path={`/mobile/android/old-device`} component={FnMobileView} />
      <Route path={`/mobile/android/not-supported`} component={FnMobileView} />
      <Route path={`/mobile/:platform`} component={MobilePlatformPage} />
      <Route path={`/mobile`} component={MobileLanding} />
      <Route exact path={`/help-center`} component={HelpCenterView} />
      <Route exact path={`/secure-your-fortnite-account`} component={SecureAccountView} />
      <Route path={`/faq`} component={FaqView} />

      <StatusCode code={503}>
        <Route component={BlackoutView} />
      </StatusCode>
    </Switch>
  );
  return routes;
};

export default getBlackoutRoutes;
