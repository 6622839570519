import classNames from 'classnames';
import Proptypes from 'prop-types';
import React from 'react';

const StyledImage = ({data, styles}) => {
  if (!data || !data.image) return null;
  const {imageAlt, image, theme, wrapper} = data;
  // Themes are pre created css classes which can apply sizing.
  const className = classNames((theme || '').split(','));
  if (wrapper) {
    const wrapClassName = classNames('wrapimg', (theme || '').split(','));
    return (
      <div className={wrapClassName} styles={styles}>
        <img className={className} alt={imageAlt} src={image} />
      </div>
    );
  }
  return <img className={className} alt={imageAlt} src={image} styles={styles} />;
};

StyledImage.propTypes = {
  data: Proptypes.shape({
    image: Proptypes.string.isRequired,
    imageAlt: Proptypes.string,
    theme: Proptypes.string,
    wrapper: Proptypes.bool,
  }).isRequired,
  styles: Proptypes.object,
};

export default StyledImage;
