import styled from 'styled-components';

import {breakpoints} from '../../../constants/Breakpoints';
import {Background} from '../types';

interface Props {
  gradient?: Background;
}
const Section = styled.section<Props>`
  position: relative;
  display: flex;
  justify-content: center;
  color: white;

  margin-top: -7.5rem;

  @media ${breakpoints.desktop} {
    margin-top: -3.5rem;
  }

  @media ${breakpoints.tablet} {
    margin-top: -3rem;
  }

  @media ${breakpoints.mobileL} {
    margin-top: -5rem;
  }
  clip-path: polygon(0 2%, 28% 1%, 22% 3%, 100% 0, 100% 100%, 0 100%);

  ${({gradient}) =>
    gradient?.from && gradient?.to
      ? `background: linear-gradient(180deg, ${gradient.from} 0%, ${gradient.to} 100%);`
      : null}
`;

export default Section;
