import {seamlessBootstrap, seamlessImmutable} from 'epic-alt-utils';
import alt from 'epic-fortnite-react/alt';
import Immutable from 'seamless-immutable';

@seamlessImmutable
class CountryStore {
  constructor() {
    this.on('error', (error) => {
      console.error(error);
    });
    this.on('bootstrap', seamlessBootstrap.bind(this, null));
    this.state = Immutable({});
  }
}

export default alt.createStore(CountryStore, 'CountryStore');
