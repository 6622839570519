import {RedeemStore} from '@epic-code-redemption/client';
import {connectToStores} from 'epic-alt-utils';
import AppConfigActions from 'epic-fortnite-react/actions/AppConfigActions';
import RegisterActions from 'epic-fortnite-react/actions/RegisterActions';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React from 'react';
import Helmet from 'react-helmet';
import {withRouter} from 'react-router';
import root from 'window-or-global';

import LoadScreen from '../../components/loadScreen/LoadScreen';
import {AppConfigStore, HeaderStore, LoginStore, MobileStore} from '../../stores';

import AndroidView from './AndroidView';

@connectToStores
class FnMobileView extends React.Component {
  static propTypes = {
    mobileStore: PropTypes.object,
    appConfigStore: PropTypes.object,
    redeemStore: PropTypes.object,
    loginStore: PropTypes.object,
    location: PropTypes.object,
    mobile: PropTypes.bool,
    tablet: PropTypes.bool,
    match: PropTypes.object,
    bowser: PropTypes.object,
  };

  static getStores() {
    return [MobileStore, HeaderStore, RedeemStore, AppConfigStore];
  }

  static getPropsFromStores() {
    return {
      mobileStore: MobileStore.getState(),
      loginStore: LoginStore.getState(),
      appConfigStore: AppConfigStore.getState(),
      redeemStore: RedeemStore.getState(),
      mobile: HeaderStore.getState().mobile(),
      tablet: HeaderStore.getState().tablet(),
      bowser: HeaderStore.getState().bowser(),
    };
  }

  static getInitialActions() {
    return [
      (props) => {
        RegisterActions.getStatus(props.match.params.locale);
        const query = queryString.parse(props.location.search) || {};
        if (query.code) {
          RegisterActions.setMobileCode(query.code);
        }
      },
      () => {
        AppConfigActions.fetchEpicSid();
      },
    ];
  }

  generateAndroidLink = () => {
    const {androidDownloadUrl, epicSid} = this.props.appConfigStore;
    let link = androidDownloadUrl;
    const DNT =
      typeof root.navigator.doNotTrack === 'undefined'
        ? root.doNotTrack
        : root.navigator.doNotTrack;
    if (epicSid && DNT !== 1 && DNT !== '1') {
      // if DNT is OFF
      link = `${androidDownloadUrl}?id=${epicSid}`;
    }

    return link;
  };

  render() {
    const {mobileStore, loginStore, mobile, tablet, redeemStore, bowser, appConfigStore} =
      this.props;
    const location = this.props.location || {};
    const {androidMobileData, androidDownloadData, mobileData, status} = mobileStore;
    const {isLoggedIn} = loginStore;
    const search = location.search || '';
    const query = queryString.parse(search) || {};
    const data = mobileData || {};
    const code =
      query.code && typeof query.code === 'string'
        ? query.code.replace(/-/g, '').substring(0, 20)
        : undefined;
    const {locale} = this.props.match.params;

    query.code = code;
    query.mode = query.mode || undefined;
    const {loading: appConfigLoading} = appConfigStore;
    if (appConfigLoading) return <LoadScreen show={true} />;
    const androidLink = this.generateAndroidLink();

    //make redirect null when we switch back and unblock if / else
    const tags = [
      {itemProp: 'name', content: data.metaTitle},
      {itemProp: 'description', content: data.metaDescription},
      {itemProp: 'image', content: data.metaImage},
      {name: 'description', content: data.metaDescription},
      {name: 'twitter:title', content: data.metaTitle},
      {name: 'twitter:description', content: data.metaDescription},
      {name: 'twitter:image', content: data.metaImage},
      {property: 'og:title', content: data.metaTitle},
      {property: 'og:image', content: data.metaImage},
      {property: 'og:description', content: data.metaDescription},
    ];
    return (
      <div className="fn-mobile-view theEnd-background-override">
        <Helmet meta={tags} title={data.metaTitle} />
        <AndroidView
          downloadData={androidDownloadData}
          cmsData={androidMobileData}
          isLoggedIn={isLoggedIn}
          mobile={mobile}
          tablet={tablet}
          status={status}
          locale={locale}
          code={code}
          androidLink={androidLink}
          bowser={bowser}
        />
        <LoadScreen show={mobileStore.loading || redeemStore.loading} />
      </div>
    );
  }
}

export default withRouter(FnMobileView);
