import React from 'react';

import ContentBox from './ContentBox';
import ContentBoxDescription from './ContentBoxDescription';
import ContentBoxTitle from './ContentBoxTitle';

interface Props {
  title: string;
  description: string;
  animate: boolean;
}
const VBucksContentBox = ({title, description, animate}: Props) => {
  return (
    <ContentBox
      animate={animate ? {y: 0, opacity: 1} : {y: 100, opacity: 0}}
      transition={{duration: 0.3, type: 'spring', delay: 0.3}}>
      <ContentBoxTitle className="vbucks-content-title" color="#001731">
        {title}
      </ContentBoxTitle>
      <ContentBoxDescription>{description}</ContentBoxDescription>
    </ContentBox>
  );
};

export default VBucksContentBox;
