import epicTracking from 'epic-tracking';

import type {
  NavAccountLinkClickEvent,
  NavCtaClickEvent,
  NavLinkClickEvent,
  NavLocaleClickEvent,
  NavPropertyLogoClickEvent,
} from '@wf-components/navigation-consumer';

type LinkTrackingMetrics = {
  trackEventAction: string;
  trackEventCategory: string;
  trackEventLabel: string;
  trackNavDestinationLocale: string;
  trackNavDestinationUrl: string | undefined;
};

type EventValue = {
  clientX: PointerEvent['clientX'];
  clientY: PointerEvent['clientY'];
  innerHeight: number | null;
  innerWidth: number | null;
  pageX: PointerEvent['pageX'];
  pageY: PointerEvent['pageY'];
};

type LinkFragment =
  | NavAccountLinkClickEvent['link']
  | NavCtaClickEvent['link']
  | NavLinkClickEvent['link']
  | NavLocaleClickEvent['link']
  | NavPropertyLogoClickEvent['link'];

const removeNamespacePrefix = (str: string): string => str.replace(/^(track)/, '');

/**
 * Format the keys of an object by removing a namespace prefix and converting them to camel case.
 *
 * @param {Record<string, any>} obj - The input object with keys to be formatted.
 */
const objectKeyFormatter = (obj: Record<string, any>): Record<string, any> => {
  const target: Record<string, any> = {};

  Object.entries(obj).forEach(([key, value]) => {
    const keyWithoutPrefix = removeNamespacePrefix(key);
    const camelCaseKey = keyWithoutPrefix.charAt(0).toLowerCase() + keyWithoutPrefix.slice(1);

    target[camelCaseKey] = value;
  });

  return target;
};

const sendAnalyticEvent = (
  dataset: Record<string, unknown>,
  extra?: Record<string, unknown>
): void => {
  const {
    trackEventCategory: eventCategory,
    trackEventAction: eventAction,
    trackEventLabel: eventLabel,
    trackAnalyticsId: analyticsId,
    trackTestName: testName,
    ...rest
  } = dataset;

  epicTracking.trackEvent('Interaction', {
    ...(analyticsId ? {analyticsId} : {}),
    ...(testName ? {testName} : {}),
    eventCategory,
    eventAction,
    eventLabel,
    eventValue: JSON.stringify(objectKeyFormatter({...rest, ...extra})),
    interactionType: 'click',
  });
};

/**
 * Generates a tracking dataset for link tracking metrics and an event value object.
 *
 * @param {string | undefined} destinationUrl - The URL of the tracked destination page
 * @param {string} destinationLocale - The locale of the tracked destination page.
 * @param {PointerEvent} pointerEvent
 * @param {string} label - The label associated with the tracking event.
 */
const trackingDataset = (
  destinationUrl: string | undefined,
  destinationLocale: string,
  pointerEvent: PointerEvent,
  label: string,
  standardLabel: string
): {
  dataset: LinkTrackingMetrics;
  eventValue: EventValue;
} => {
  const {pageX, pageY, clientX, clientY} = pointerEvent ?? {};

  const eventValue = {
    clientX,
    clientY,
    innerHeight: window?.innerHeight ?? null,
    innerWidth: window?.innerWidth ?? null,
    pageX,
    pageY,
    standardLabel,
  };

  const dataset = {
    trackEventAction: 'navigation',
    trackEventCategory: 'headerNav',
    trackEventLabel: label,
    trackNavDestinationLocale: destinationLocale,
    trackNavDestinationUrl: destinationUrl,
  };

  return {dataset, eventValue};
};

/**
 * Track a link click event and send analytics data.
 *
 * @param {LinkFragment | undefined} linkFragment - The link fragment object (or undefined if not available).
 * @param {PointerEvent} pointerEvent - The pointer event object associated with the click event.
 * @param {string} locale - The current locale.
 */
export const trackLink = (
  linkFragment: LinkFragment | undefined,
  pointerEvent: PointerEvent,
  locale: string
): void => {
  if (!linkFragment) return;

  let relativePath: string | undefined;
  let label = '';
  let standardLabel = '';
  let eventLocale: string = locale;

  if ('relativePath' in linkFragment) {
    relativePath = linkFragment.relativePath || undefined;
  }

  if ('label' in linkFragment) {
    label = linkFragment.label || '';
  }

  if ('key' in linkFragment) {
    standardLabel = linkFragment.key || '';
  } else if ('langCode' in linkFragment) {
    eventLocale = linkFragment.langCode;
    standardLabel = linkFragment.langCode;
  } else if ('logo' in linkFragment) {
    label = 'logo';
    standardLabel = 'logo';
  }

  const {dataset, eventValue} = trackingDataset(
    relativePath,
    eventLocale,
    pointerEvent,
    label,
    standardLabel
  );

  sendAnalyticEvent(dataset, eventValue);
};
