import styled from 'styled-components';

const StyledCreativeDocsLink = styled.a`
  && {
    font-family: BurbankBigRegular-Black, sans-serif;
    font-style: italic;
    color: #0b85ec;
  }
`;

export default StyledCreativeDocsLink;
