import {useMotionValue, useSpring, useTransform} from 'framer-motion';
import React from 'react';
import root from 'window-or-global';

import {PlusIcon} from '../../../icons';

import HeroContainer from './HeroContainer';
import HeroImage from './HeroImage';
import IconButton from './IconButton';
import {HeroIds, ImageArray} from './Types';

interface Props {
  images: ImageArray[];
  active: boolean;
  keyId: string;
  mobile: boolean;
  tablet: boolean;
  sound?: string;
}

const Hero = ({images, active, keyId, mobile, tablet, sound}: Props) => {
  const [activeSkin, setSkin] = React.useState(0);
  const switchSkin = React.useCallback(() => {
    const max = images.length - 1;
    if (activeSkin < max) setSkin((prev) => prev + 1);
    if (activeSkin >= max) setSkin(0);
  }, [images, setSkin, activeSkin]);

  const xPos = useMotionValue(root ? root.innerWidth / 2 : 1000);
  const x = useTransform(xPos, [0, root ? root.innerWidth : 2000], [50, -50], {clamp: true});
  const xRotate = useTransform(xPos, [0, root ? root.innerWidth : 2000], [-10, 17], {
    clamp: true,
  });
  const xSpring = useSpring(x, {damping: 100});
  const rSpring = useSpring(xRotate, {damping: 100});

  const handleMouse = (e) => {
    const {clientX, pageX} = e;
    if (!pageX && clientX) xPos.set(clientX); //x position within the element.
    if (pageX) xPos.set(pageX);
  };

  React.useLayoutEffect(() => {
    root.addEventListener('mousemove', handleMouse, true);
    return () => {
      root.removeEventListener('mousemove', handleMouse, true);
    };
  }, [root]);

  //special styles for certain heroes
  const yPos =
    HeroIds[keyId] === HeroIds.storm || (HeroIds[keyId] === HeroIds.groot && !mobile && !tablet)
      ? -65
      : 0;

  const scale = 1;
  const xp = 0;
  return (
    <HeroContainer
      initial={{
        scale,
        opacity: 0,
      }}
      style={{
        rotateY: active && !mobile && !tablet ? rSpring : 0,
        x: active && !mobile && !tablet ? xSpring : 0,
      }}
      animate={active ? {y: yPos, scale, opacity: 1} : {y: yPos, scale, opacity: 0}}
      transition={{opacity: {duration: 0.8, ease: 'easeInOut', delay: active ? 0.8 : 0}}}>
      {images.map((item, index) => {
        return (
          <HeroImage
            key={`hero-image-${keyId}-${index}`}
            keyId={keyId}
            active={index === activeSkin}
            src={item.image}
            alt={item.imageAlt}
            initial={{x: 150, z: -200}}
            animate={
              active
                ? {x: xp, z: 1}
                : {
                    x: !mobile && !tablet ? 150 : 20,
                    z: !mobile && !tablet ? -200 : -50,
                  }
            }
            transition={{duration: 0.8, ease: 'easeInOut', delay: active ? 0.8 : 0}}
          />
        );
      })}
      {images.length > 1 || sound ? (
        <IconButton keyId={keyId} onClick={switchSkin}>
          <PlusIcon />
        </IconButton>
      ) : null}
    </HeroContainer>
  );
};

export default Hero;
