import styled from 'styled-components';

import {Device} from '../../constants';

interface Props {
  $color?: string;
}

export default styled.div<Props>`
  margin-left: 0.5em;
  margin-bottom: 1em;
  font-weight: 500;
  font-family: OpenSans, icomoon, sans-serif;
  &&&& {
    color: ${({$color}) => $color || '#ffffff'};
  }
  text-align: left;
  @media screen and ${Device.laptop} {
    text-align: center;
  }
`;
