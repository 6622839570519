import {getLocale} from '@epic-core/common';
import {generateRouteTo} from 'epic-fortnite-react';
import PropTypes from 'prop-types';
import React from 'react';
import {Redirect} from 'react-router';

import LoadIndicator from '../loadScreen/LoadIndicator';
import {addForwardSlash} from '../../utils/UrlPropUtils';
import Error404 from '../../views/Error404';

/**
 * ArticleRedirect
 * Pre-render checks before rendering the ArticleView component
 * @implements {ArticleView}
 * @reactProps {bool} blogStoreError
 * @reactProps {object} blog {}
 * @reactProps {object} location  {}
 * @reactProps {object} children
 * @reactProps {string} routerRootUrl
 * @reactProps {bool} hideLoadIndicator
 * @reactProps {bool} showLoading
 * @reactProps {element} notFoundComponent
 */
class ArticleRedirect extends React.Component {
    static propTypes = {
        children: PropTypes.object,
        blogStoreError: PropTypes.bool,
        blog: PropTypes.object,
        location: PropTypes.object,
        routerRootUrl: PropTypes.string,
        hideLoadIndicator: PropTypes.bool,
        showLoading: PropTypes.bool,
        showNotFound: PropTypes.bool,
        notFoundComponent: PropTypes.element
    };

    static defaultProps = {
        routerRootUrl: '',
        blogStoreError: false,
        location: {},
        showLoading: false,
        hideLoadIndicator: false
    };

    render() {
        let redirect = null;
        const blogStoreError = this.props.blogStoreError;
        const blog = this.props.blog;
        const location = this.props.location;
        const routerRootUrl = this.props.routerRootUrl;
        const hideLoadIndicator = this.props.hideLoadIndicator;
        const showLoading = this.props.showLoading;
        const {showNotFound} = this.props;
        const children = this.props.children;
        const notFoundComponent = this.props.notFoundComponent;

        const locale = getLocale();

        if (blog && blog.externalLink) {
            redirect = generateRouteTo(
                {pathname: blog.externalLink, search: location.search},
                location,
                locale
            );
        } else if (location && location.query && location.query.lang && blogStoreError) {
            // Change locale & page doesn't exist
            redirect = generateRouteTo(
                {
                    pathname: addForwardSlash(routerRootUrl.replace(/[^\w]/g, ''), 0),
                    search: location.search
                },
                location,
                locale
            );
        } else if (blogStoreError || showNotFound) {
            if (notFoundComponent) {
                return notFoundComponent;
            }
            return <Error404 />;
        }
        if (redirect) {
            return (
                <div className="article-view" id="articleView">
                    <div id="redirect-link" data-redirect-path={redirect}>
                        <Redirect to={redirect} />
                    </div>
                </div>
            );
        } else if (showLoading) {
            return (
                <div className="article-view" id="articleView">
                    {!hideLoadIndicator ? <LoadIndicator /> : ''}
                </div>
            );
        }

        return children;
    }
}

export default ArticleRedirect;
