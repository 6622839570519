export enum ProductCustomAttributeType {
    STRING = 'STRING',
    JSON = 'JSON'
}

export interface ProductCustomAttribute {
    productId: string;
    type: ProductCustomAttributeType;
    value: string;
    key: string;
}
