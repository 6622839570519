import styled from 'styled-components';

import {Device} from '../../constants';

const StyledSubscriptionJoinStepsContainer = styled.div`
  display: flex;
  margin: 25px;

  @media screen and ${Device.mobileL} {
    flex-direction: column;
  }
`;

export default StyledSubscriptionJoinStepsContainer;
