import {connectToStores} from 'epic-alt-utils';
import HttpErrorScreen from 'epic-fortnite-react/components/error/HttpErrorScreen';
import LoadScreen from 'epic-fortnite-react/components/loadScreen/LoadScreen';
import Message from 'epic-fortnite-react/components/message/Message';
import PropTypes from 'prop-types';
import React from 'react';
import root from 'window-or-global';

import CommonCTABtn from '../../components/common/CommonCTABtn';
import MetaTags from '../../components/common/MetaTags';
import {CmsStore} from '../../stores';
import animateScrollToY from '../../utils/AnimateScrollToY';
import {fetchCmsData} from '../../utils/CmsStoreUtils';

const slug = 'darkfire';

const DarkfireView = (props) => {
  const {
    cmsStore: {pages},
  } = props;

  if (!pages[slug]) return <HttpErrorScreen statusCode={404} />;

  const {loading: pageLoading, data: page, error: pageError} = pages[slug];

  if (pageLoading) return <LoadScreen show />;
  if (pageError) return <HttpErrorScreen statusCode={pageError?.statusCode} />;

  const scrollTo = () => {
    const jumpToId = 'darkfire-bundle';
    const bodyTop = root.document.body.getBoundingClientRect().top;
    const element = root.document.getElementById(jumpToId);
    const eScrollOffset = element.getBoundingClientRect().top - bodyTop;
    animateScrollToY(eScrollOffset, 500, 'easeInOutCubic');
  };
  return (
    <div className="darkfireView">
      <MetaTags meta={page.meta} />
      <section className="header-section">
        <div className="header-section-left">
          <img src={page.headerImage} alt="darkfire" />
        </div>
        <div className="header-section-right">
          <img src={page.logo} alt="fortnite" />
          <h1>{page.headerTitle}</h1>
          <h2>{page.headerDesc}</h2>
          <button className="btn btn-primary btn-hover-transform" onClick={scrollTo}>
            {page.btnText}
          </button>
        </div>
      </section>
      {page.enablePlatfrom ? (
        <section className="platform-section">
          <div className="platform-section-wrapper">
            <header className="platforms-header">
              <h1>{page.platformTitle}</h1>
              <h3>{page.platformDesc}</h3>
            </header>
            <div className="platform-icons">
              <div className="platform-icon note">
                <i className="icon-pc" />
                <Message code="epic.fortnite.overlay.pcMac" />
              </div>
              <div className="platform-icon">
                <i className="icon-ps4" />
                <Message code="epic.fortnite.overlay.ps4" />
              </div>
              <div className="platform-icon">
                <i className="icon-xbox" />
                <Message code="epic.fortnite.overlay.xbox" />
              </div>
              <div className="platform-icon">
                <i className="icon-nintendo" />
                <Message code="epic.fortnite.overlay.switch" />
              </div>
              <div className="platform-icon note">
                <i className="icon-mobile" />
                <Message code="epic.fortnite.overlay.mobile" />
              </div>
            </div>
            <h5 className="platform-note">{page.platformNote}</h5>
          </div>
        </section>
      ) : null}

      <section className="bundle-section" id="darkfire-bundle">
        <header>
          <h1>{page.bundleTitle}</h1>
        </header>
        <section className="outfits">
          <h1>{page.outfitsTitle}</h1>
          <img src={page.outfitsImage} alt="outfits" />
          <div className="outfit-names">
            {page.outitsNames
              ? page.outitsNames.split(',').map((name) => <h5 key={name}>{name.trim()}</h5>)
              : null}
          </div>
        </section>

        <section className="guns">
          <h1>{page.wrapsTitle}</h1>
          <img src={page.wrapsImage} alt="guns" />
          <div className="guns-names">
            {page.wrapsNames
              ? page.wrapsNames.split(',').map((name) => <h5 key={name}>{name.trim()}</h5>)
              : null}
          </div>
        </section>

        <section className="emote">
          <div className="emote-wrapper">
            <h3>{page.emoteName}</h3>
            <img src={page.emoteImage} alt="emote" />
          </div>
        </section>

        <section className="backBlings">
          <img src={page.backBlingsImage} alt="back blings" />
          <div className="text-wrapper">
            <h1>{page.backBlingsTitle}</h1>
            <div className="backBlings-names">
              {page.backBlingsNames
                ? page.backBlingsNames.split(',').map((name) => <h5 key={name}>{name.trim()}</h5>)
                : null}
            </div>
          </div>
        </section>

        <section className="axes">
          <h1>{page.axesTitle}</h1>
          <div className="axes-wrapper">
            <img src={page.axesImage} alt="axes" />
            <div className="axes-names">
              {page.axexNames
                ? page.axexNames.split(',').map((name) => <h5 key={name}>{name.trim()}</h5>)
                : null}
            </div>
          </div>
        </section>
      </section>

      <section className="disks-section">
        <section className="disks-wrapper">
          <img className="disks-pic" src={page.disksImage} alt="disks" />
          <div className="disks-content">
            <h1>{page.availableNow}</h1>
            <h3>{page.availableNowSubtitle}</h3>
            <CommonCTABtn className="btn-hover-transform" ctaConfig={page.needHelpConfig} />
          </div>
          <h4 className="legal-terms">
            <img src={page.legalTag} alt="legal icon" />
            <div className="legal-content">{page.bundleNote}</div>
          </h4>
        </section>
      </section>
    </div>
  );
};

DarkfireView.propTypes = {
  cmsStore: PropTypes.object,
};

DarkfireView.getStores = () => [CmsStore];
DarkfireView.getInitialActions = () => [fetchCmsData(slug)];
DarkfireView.getPropsFromStores = () => ({
  cmsStore: CmsStore.getState(),
});

export default connectToStores(DarkfireView);
