import {connectToStores} from 'epic-alt-utils';
import {Section} from 'epic-fortnite-react';
import HttpErrorScreen from 'epic-fortnite-react/components/error/HttpErrorScreen';
import React from 'react';

import RiftTourActions from '../../actions/RiftTourActions';
import MetaTags from '../../components/common/MetaTags';
import LoadScreen from '../../components/loadScreen/LoadScreen';
import RiftTourSection from '../../components/riftTour/RiftTourSection';
import {RiftTourStore} from '../../stores';
import {usePrivatePageViewTracking} from '../../utils/usePrivatePageViewTracking.hook';

const fetchData = () => {
  RiftTourActions.getPage.defer();
};

const RiftTourView = ({riftTourStore}: any) => {
  const {loading, data, error} = riftTourStore;

  usePrivatePageViewTracking({
    interactionType: 'pageView',
    eventCategory: 'fn-web',
    eventAction: 'buffet-view',
    eventValue: 1,
  });

  if (loading) {
    return <LoadScreen show={true} />;
  }

  if (error?.statusCode) {
    return <HttpErrorScreen statusCode={error?.statusCode} />;
  }

  const {sections: stateSections, callout} = data;

  const sections: JSX.Element[] = [];

  for (let i = 0; i < stateSections.length; i++) {
    const section = stateSections[i];

    sections.push(
      <RiftTourSection key={`rift-tour-section-${i}`} section={section} callout={callout} />
    );
  }

  return (
    <Section backgroundColor="#fff" textAlign="center">
      <MetaTags
        pageModel={
          data.shareVideo
            ? {
                ...data,
                _metaTags: data._metaTags.concat(
                  `<meta property="og:video" content="${data.shareVideo}" />`
                ),
              }
            : data
        }
      />
      {sections}
    </Section>
  );
};

RiftTourView.getStores = () => [RiftTourStore];
RiftTourView.getPropsFromStores = () => ({
  riftTourStore: RiftTourStore.getState(),
});

RiftTourView.getInitialActions = () => [fetchData];

export default connectToStores(RiftTourView);
