import classNames from 'classnames';
import {SlickWrapper} from 'epic-react-common';
import PropTypes from 'prop-types';
import React from 'react';
import VisibilitySensor from 'react-visibility-sensor';

class AndroidDownloadNewFlow extends React.Component {
  static propTypes = {
    data: PropTypes.object,
  };

  state = {
    inView: false,
  };

  inView = (isVisible) => {
    if (isVisible && !this.state.inView) {
      this.setState({inView: isVisible});
    }
  };

  render() {
    const {data} = this.props;

    const sliderOptions = {
      infinite: true,
      speed: 500,
      draggable: true,
      touchMove: true,
      focusOnSelect: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: true,
      arrows: false,
      autoplay: true,
      autoplaySpeed: 5000,
      dotsClass: 'pagination',
    };
    const slides = [];

    for (let i = 0; i < data.slider.length; i++) {
      const slide = data.slider[i];
      slides.push(
        <div className="flow-slider" key={`flow-slide-${i}`}>
          <div className="slide-top">
            <div className="slide-step">{slide.slideStep}</div>
            <div className="slide-title">{slide.slideTitle}</div>
          </div>

          <div className="slide-image">
            <div className="slide-placeholder" />
            <object data={slide.slideImage} className="animate-image">
              {slide.slideTitle}
            </object>
            <img src={data.slideFrame} alt={slide.slideTitle} className="placeholder-image" />
          </div>
        </div>
      );
    }
    const inViewClass = classNames('flow-slide-container', {inView: this.state.inView});
    return (
      <div className="row">
        <VisibilitySensor
          onChange={this.inView}
          partialVisibility
          delayedCall={true}
          active={!this.state.inView}>
          <div className="col-xs-12 text-center">
            <div className={inViewClass}>
              <SlickWrapper
                ref={(c) => {
                  this.exclusiveSlider = c;
                }}
                options={sliderOptions}>
                {slides}
              </SlickWrapper>
            </div>
          </div>
        </VisibilitySensor>
      </div>
    );
  }
}
export default AndroidDownloadNewFlow;
