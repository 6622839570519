import PropTypes from 'prop-types';
import React from 'react';

import AnimateString from '../common/AnimateString';

const MoreRewards = ({data}) => {
  const {title, description, image} = data;
  return (
    <div>
      <div className="clearfix" />
      <div className={`battlepass-more-rewards`}>
        <div className="more-rewards-content">
          <AnimateString
            key="morerewards-h1"
            text={title}
            type="h1"
            speed={1.5}
            delay={0.3}
            autoAlpha={0}
            yPos={100}
            minTop={20}
            scaleFrom={1}
            staggerDelay={0}
          />
          {description ? (
            <AnimateString
              key="morerewards-h3"
              text={description}
              type="p"
              speed={1.5}
              delay={0.3}
              autoAlpha={0}
              yPos={100}
              minTop={20}
              scaleFrom={1}
              staggerDelay={0}
            />
          ) : null}
        </div>
        <img src={image} className="more-rewards-image" alt="" />
      </div>
    </div>
  );
};

MoreRewards.propTypes = {
  data: PropTypes.object,
};
export default MoreRewards;
