import root from 'window-or-global';

export default function animateScrollToY(to: number, duration: number): Promise<void> {
  /** The parameters of the animateScrollToY are 'to' which targets vertical
   * position and duration which will run the animation for a specified time frame */

  const start = root.scrollY || root.document.documentElement.scrollTop;
  const change = to - start;
  const increment = 20;
  let currentTime = 0;

  const animateScroll = function () {
    currentTime += increment;
    const value = easeInOutQuad(currentTime, start, change, duration);
    root.scrollTo(0, value);
    if (currentTime < duration) {
      setTimeout(animateScroll, increment);
    }
  };

  return new Promise((resolve) => {
    animateScroll();
    setTimeout(resolve, duration);
  });
}

/** Quadratic easing function, easeInOutQuad to animate the scrolling over a specific
 * time scale. It takes four parameters, currentTime, startPosition, changePosition, duration. */

function easeInOutQuad(
  currentTime: number,
  startPosition: number,
  changePosition: number,
  duration: number
): number {
  let ct = currentTime;
  ct /= duration / 2;
  if (ct < 1) return (changePosition / 2) * ct * ct + startPosition;
  ct--;
  return (-changePosition / 2) * (ct * (ct - 2) - 1) + startPosition;
}
