import axios from 'axios';
import {getLocale} from '@epic-core/common';
import root from 'window-or-global';

import StoreActions from '../actions/StoreActions';
import Url from '../constants/Url';

/**
 * StoreSource
 *
 * Ajax calls for searches. Just applies to Bing right now.
 *
 * For more information on source usage:
 * http://alt.js.org/docs/async/
 *
 */

const StoreSource = {
  getFeatured: {
    remote() {
      return axios.get(`${Url().STORE.FEATURED}`, {
        headers: {'Accept-Language': getLocale()},
      });
    },
    success: StoreActions.getFeaturedSuccess,
    error: StoreActions.getFeaturedFailure,
    shouldFetch() {
      return !root.__server_side_render;
    },
  },
  getCategories: {
    remote() {
      return axios.get(`${Url().STORE.CATEGORIES}`, {
        headers: {'Accept-Language': getLocale()},
      });
    },
    success: StoreActions.getCategoriesSuccess,
    error: StoreActions.getCategoriesFailure,
    shouldFetch() {
      return !root.__server_side_render;
    },
  },
  getCategory: {
    remote(state, category) {
      return axios.get(`${Url().STORE.CATEGORY(category)}`, {
        headers: {'Accept-Language': getLocale()},
      });
    },
    success: StoreActions.getCategorySuccess,
    error: StoreActions.getCategoryFailure,
    shouldFetch() {
      return !root.__server_side_render;
    },
  },
  getAssetDetail: {
    remote(state, slug) {
      return axios.get(`${Url().STORE.ASSET(slug)}`, {
        headers: {'Accept-Language': getLocale()},
      });
    },
    success: StoreActions.getAssetDetailSuccess,
    error: StoreActions.getAssetDetailFailure,
    shouldFetch() {
      return !root.__server_side_render;
    },
  },
  resetPurchased: {
    remote() {
      return axios.get(`${Url().STORE.RESETPURCHASED}`, {
        headers: {'Accept-Language': getLocale()},
      });
    },
    success: StoreActions.refreshAssetSuccess,
    error: StoreActions.refreshAssetFailure,
    shouldFetch() {
      return !root.__server_side_render;
    },
  },
  getFoundersPacks: {
    remote() {
      return axios.get(`${Url().STORE.FOUNDERS}`, {
        headers: {'Accept-Language': getLocale()},
      });
    },
    success: StoreActions.getFoundersPacksSuccess,
    error: StoreActions.getFoundersPacksFailure,
    shouldFetch() {
      return !root.__server_side_render;
    },
  },
  getSubscriptionData: {
    remote() {
      return axios.get(`${Url().STORE.SUBSCRIPTION}`, {
        headers: {'Accept-Language': getLocale()},
      });
    },
    success: StoreActions.getSubscriptionDataSuccess,
    error: StoreActions.getSubscriptionDataFailure,
    shouldFetch() {
      return !root.__server_side_render;
    },
  },
  sendWelcome: {
    remote(state) {
      return axios.get(Url().STORE.WELCOME, {
        headers: {'Accept-Language': getLocale()},
      });
    },
    success: StoreActions.sendWelcomeSuccess,
    error: StoreActions.sendWelcomeFailure,
    shouldFetch() {
      return !root.__server_side_render;
    },
  },
};

export default StoreSource;
