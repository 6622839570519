import React from 'react';

import Content40 from './Content40';
import DivRelative from './DivRelative';
import Image from './Image';
import Image40 from './Image40';
import RowDirection from './RowDirection';

interface Props {
  content: string;
  src: string;
  alt: string;
  color: string;
}
const ImageTextHorizVert = ({content, color, src, alt}: Props) => {
  return (
    <DivRelative>
      <RowDirection>
        <Image40>
          <Image src={src} alt={alt} />
        </Image40>
        <Content40 content={content} color={color} />
      </RowDirection>
    </DivRelative>
  );
};
export default ImageTextHorizVert;
