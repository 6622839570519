/**
 * This file is used to aggregate all the css files that are used by our views.  This is necessary because with webpack chunks,
 * extract text is unable to create a style sheet for individual chunks.  To get around this, we load all the chunked styles
 * here.
 */

//common
import './common/FeaturedItem.sass';
import './common/FeaturedSection.sass';
import './common/imageHover.sass';
import 'epic-fortnite-react/components/common/pageJumbotron.sass';
import './common/SelectElement.sass';
import './common/SlickWrapper.sass';
import './common/TwitchChatEmbed.sass';
import './common/TwitchVideoEmbed.sass';
import './common/YoutubeVideoEmbed.sass';
import './common/VideoOverlay.sass';
import './common/MousePosition.sass';
import './common/DownloadButton.sass';
import './common/AnimateString.sass';
import './common/Background.sass';
import './common/CommonOverlay.sass';
import './common/ParallaxImage.sass';
import './common/VideoPlayButton.sass';
import 'epic-fortnite-react/components/common/LoginOverlay.sass';
//faq
import './faq/faqSection.sass';
import './faq/qasection.sass';
import './faq/quickLink.sass';

//loadScreen
import './loadScreen/LoadScreen.sass';
//search
import 'epic-fortnite-react/components/search/BingResult.sass';
import 'epic-fortnite-react/components/search/BingResultsList.sass';
//squareButton
import './squareButton/SquareButton.sass';

//Search for Survivors
import './common/TextBlock.sass';

//BattlePass
import './battlePass/VideoHeader.sass';
import './battlePass/SkinSlider.sass';
import './battlePass/VBucksSection.sass';
import './battlePass/GunWraps.sass';
import './battlePass/Gliders.sass';
import './battlePass/Axes.sass';
import './battlePass/Emotes.sass';
import './battlePass/Items.sass';
import './battlePass/LoadingScreens.sass';
import './battlePass/MoreRewards.sass';
import './battlePass/XpProgression.sass';
//mobile signup
import './fortniteMobile/SignUpPage.sass';
import './fortniteMobile/CodeRedeem.sass';
import './fortniteMobile/ConfirmPage.sass';
import './fortniteMobile/DeviceSelect.sass';
import './fortniteMobile/Header.sass';
import './fortniteMobile/AndroidDownload.sass';
import './fortniteMobile/AndroidDownloadNewFlow.sass';
//PatchNotes
import './patchNotes/PatchNotesHeader.sass';
import './patchNotes/PatchNotesNavigation.sass';
import './patchNotes/PatchNotesSocialShare.sass';
import './patchNotes/PatchNotesCarousel.sass';
import './patchNotes/PatchNotesText.sass';
import './patchNotes/PatchNotesOverview.sass';
// Play Guide
import './playGuide/GuideMenu.sass';
//Retail Bundle Page
import './retailBundle/RetailHeader.sass';
import './retailBundle/RetailAvailablePlatforms.sass';
import './retailBundle/RetailBundleSection.sass';
import './retailBundle/RetailHelpSection.sass';
import './simpleTabs/SimpleTabHeader.sass';
import './simpleTabs/SimpleTabListSection.sass';
import './simpleTabs/SimpleTabSection.sass';
//Landing Pages.
import './landingpages/Video.sass';
import './landingpages/BlankCallToAction.sass';
import './landingpages/StyledImage.sass';
import './landingpages/StyledImage.sass';
import './landingpages/BackgroundImageVideo.sass';
import './landingpages/BackgroundWithSections.sass';
import './landingpages/PosterList.sass';
import './landingpages/ScheduleList.sass';
// Save the world
import './stw/VideoBlock.sass';
import './stw/PackBlock.sass';
import './stw/StwHeader.sass';
import './stw/PackBuyDialog.sass';
// Help Center
import './helpCenter/HelpCenterSection.sass';
// chapter2
import './chapter2/VideoHeader.sass';
import './chapter2/VideoHeaderS2c2.sass';
import './chapter2/VideoHeaderS2c3.sass';
import './chapter2/VideoHeaderC2S4.sass';
import './chapter2/VideoHeaderC2S5.sass';
import './chapter2/Screens.sass';
import './chapter2/CtaFooter.sass';
import 'epic-fortnite-react/components/componentStyles';
// creative mode
import './creativeMode/CreativeHomeFeatured.sass';
import './creativeMode/CreativeImageCard.sass';
import './creativeMode/CreativeCard.sass';
import './creativeMode/CreatorCard.sass';
import './creativeMode/CreativeHomeHeader.sass';
import './creativeMode/BackgroundSideCharacters.sass';
import './creativeMode/SocialButtons.sass';
import './creativeMode/CreativeHeader.sass';
import './creativeMode/videoOverlay/VideoOverlay.sass';
import './creativeMode/islands/SocialShareBanner.sass';
import './creativeMode/islands/CopyToClipboardCode.sass';
import './creativeMode/islands/CodeNotFound.sass';
import './creativeMode/islands/Steps.sass';
import './creativeMode/islands/HeaderSection.sass';
