import {datasource} from 'alt-utils/lib/decorators';
import {seamlessImmutable, seamlessBootstrap} from 'epic-alt-utils';
import alt from 'epic-fortnite-react/alt';
import Immutable from 'seamless-immutable';
import qs from 'query-string';

import EulaActions from '../actions/UefnEulaActions';
import EulaSource from '../sources/UefnEulaSource';

@datasource(EulaSource)
@seamlessImmutable
class UefnEulaStore {
  getStateFunctions() {
    return {
      getCurrentEula() {
        return this[this.currentEula];
      },
      isValidEula(eula) {
        if (!eula) return false;
        return this.availableEulas.indexOf(eula) >= 0;
      },
    };
  }
  constructor() {
    this.on('error', (error) => {
      console.error(error);
    });
    this.on('bootstrap', seamlessBootstrap.bind(this, null));
    const initialState = Immutable({
      availableEulas: ['uefn'],
      currentEula: 'uefn',
      loading: false,
      errorMessage: null,
      currentLocale: 'en-US',
      showDropdown: false,
    });

    this.state = initialState.merge(this.getStateFunctions());
    this.bindActions(EulaActions);
  }

  onFetchEula(request) {
    if (this.state[this.state.currentEula] && this.state[this.state.currentEula].title) {
      return false;
    }

    // Get lang/locale from query params
    if (request) {
      const queryParams = qs.parse(request?.location?.search);
      const locale = queryParams?.lang || this.state.currentLocale;

      this.setState(this.state.set('currentLocale', locale));
    }

    this.getInstance().getLatest();
    return this.setState(this.state.set('loading', true));
  }

  onFetchEulaSuccess(response) {
    const newState = {
      loading: false,
      error: null,
    };
    if (response.data && response.data.key) {
      newState[this.state.currentEula] = response.data;
    }
    this.setState(this.state.merge(newState));
  }

  onFetchEulaFailure(response) {
    console.error('Failed to send 404 not found message.');
    const error = response.data.message;
    this.setState(
      this.state.merge({
        loading: false,
        error,
      })
    );
  }

  onShowEula(eulaName) {
    if (!eulaName) return;
    this.setState(this.state.set('currentEula', this.state[eulaName]));
    if (!this.state.loading && !this.state[this.state[eulaName]]) {
      this.onFetchEula();
    }
  }
}

export default alt.createStore(UefnEulaStore, 'UefnEulaStore');
