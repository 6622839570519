import {ContextMessage as Message} from '@epic-core/localization';
import PropTypes from 'prop-types';
import React from 'react';

export default class RetailHelpSection extends React.PureComponent {
  static propTypes = {
    link: PropTypes.string,
    background: PropTypes.object,
    isMobile: PropTypes.bool,
  };
  render() {
    const {link, background, isMobile} = this.props;
    const bkImageSrc = isMobile ? background.tabletImage : background.desktopImage;
    const backgroundImage = background ? {backgroundImage: `url(${bkImageSrc})`} : {};
    return (
      <div className="retail-help-section" style={backgroundImage}>
        <div className="container text-center">
          <div className="row">
            <div className="col-xs-12">
              <h1>
                <Message code="epic.fortnite.retailBundle.help.needHelp" />
              </h1>
              <a href={link} className="btn btn-display no-animate">
                <Message code="epic.fortnite.retailBundle.help.contactUs" />
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
