import {motion} from 'framer-motion';
import React from 'react';
import styled, {css} from 'styled-components';

import {Device} from '../../../constants';
import {ruCss} from '../styles';

const BattlePassTitle = styled(({...rest}) => <motion.h2 {...rest} />)<{
  color?: string;
  italics?: boolean;
  large?: boolean;
}>`
  position: relative;
  z-index: 1;
  && {
    font-size: 4em;
    max-width: 60%;
    margin: 0 auto;
  }
  color: ${(props) => (props.color ? props.color : '#fff')};
  text-align: center;
  font-size: ${(props) => (props.large ? '4em' : 'auto')};
  margin: 0;
  line-height: 1;
  ${(props) =>
    props.italics
      ? css`
          font-style: italic;
        `
      : null}

  @media screen and (max-width: 1140px) {
    font-size: ${(props) => (props.large ? '3em' : '2em')};
    padding: 0 0.5em;
  }
  @media screen and ${Device.tablet} {
    font-size: ${(props) => (props.large ? '6vw' : '3vw')};
  }
  @media screen and ${Device.mobileL} {
    font-size: ${(props) => (props.large ? '7vw' : '1.5em')};
    max-width: 100%;
    padding: 0;
  }
  &&& {
    ${(props) => (props.theme.locale === 'ru' ? ruCss : '')}
  }
`;

export default BattlePassTitle;
