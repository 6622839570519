import AnimateObject from 'epic-fortnite-react/components/common/AnimateObject';
import React from 'react';

import StyledLogo from './StyledMobileLogo';

const from = {
  y: `+=150`,
  autoAlpha: 0,
};

const to = {
  y: 0,
  autoAlpha: 1,
  delay: 0.3,
};

interface Props {
  logo: string;
  alt?: string;
}

const Logo = ({logo, alt}: Props) => (
  <AnimateObject minTop={20} speed={1.5} from={from} to={to}>
    <StyledLogo src={logo} alt={alt} />
  </AnimateObject>
);

export default Logo;
