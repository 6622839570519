import React from 'react';

import ScreenItem from '../chapter3/ScreenItem';
import {CmsComponentMapper} from '../common/CmsComponentMapper';

const Baseplate = ({blocks}) => {
  return (
    <CmsComponentMapper
      dynamicPageDataArray={blocks}
      elementMapping={{
        screen: <ScreenItem />,
      }}
    />
  );
};

export default Baseplate;
