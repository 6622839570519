import React from 'react';

import Container from './components/Container';
import HowToHeader from './components/HowToHeader';
import HowToSection from './components/HowToSection';
import Step from './components/HowToStep';
import HowToSubheader from './components/HowToSubheader';
import SpikeDivider from './components/SpikeDivider';
import StepList from './components/StepList';

interface Step {
  image: string;
  alt: string | undefined;
  title: string;
  description: string;
}

export interface HowItWorksSectionProps {
  steps: Step[];
  header: string;
  subheader: string;
  color: string;
}

const HowItWorksSection = ({steps = [], header, subheader, color}: HowItWorksSectionProps) => {
  return (
    <HowToSection>
      <SpikeDivider position="top" background="white" reversed={true} />
      <Container>
        <HowToHeader>{header}</HowToHeader>
        <HowToSubheader>{subheader}</HowToSubheader>
        <StepList>
          {steps.map(({image, alt, description, title}, index) => (
            <Step
              accentColor={color}
              key={index}
              stepNumber={index + 1}
              image={image}
              alt={alt}
              description={description}
              title={title}
            />
          ))}
        </StepList>
      </Container>
    </HowToSection>
  );
};

export default HowItWorksSection;
