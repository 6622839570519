import React from 'react';
import styled from 'styled-components';

import {space} from '../../../theme/theme';
import {Spaces} from '../../../types/theme';

export interface StyledContainerProps {
  $maxWidth?: number;
  $size?: Spaces;
}

export interface ContainerProps {
  children: React.ReactNode;
  maxWidth?: number;
  size?: Spaces;
}

const StyledContainer = styled.div<StyledContainerProps>`
  ${({$size}) => $size && `--container__padding: ${space[`${$size}`]}px;`}

  max-width: ${({$maxWidth}) => ($maxWidth ? `${$maxWidth}px` : 'none')};
  padding-left: var(--container__padding);
  padding-right: var(--container__padding);
  flex-grow: 1;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
`;

export const Container = ({children, maxWidth, size}: ContainerProps) => {
  return (
    <StyledContainer $maxWidth={maxWidth} $size={size}>
      {children}
    </StyledContainer>
  );
};
