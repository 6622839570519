import {Section, SectionProps, Video} from 'epic-fortnite-react';
import {getAssetPath} from 'epic-fortnite-react/utils/FortniteUtils';
import React from 'react';
import styled from 'styled-components';

export type StyledRiftTourSectionProps = {
  $clipPathDirection?: 'slope-down' | 'slope-up';
  $clipPathSpike?: boolean;
  $overlapTop?: number;
};

export type StyledRiftTourSectionBackgroundImage = {
  $backgroundPosition?: string;
  $clipPathDirection?: 'slope-down' | 'slope-up';
  $clipPathSpike?: boolean;
};

export type StyledRiftTourSectionGradientProps = {
  $gradient?: {
    from: string;
    to: string;
  };
  $gradientPosition?: 'bottom' | 'top';
  $gradientSize?: '100%' | '50%' | '25%';
};

export interface RiftTourSectionProps extends SectionProps {
  backgroundImage?: string;
  backgroundPosition?: string;
  backgroundVideo?: string;
  clipPathDirection?: 'slope-down' | 'slope-up';
  clipPathSpike?: boolean;
  gradient?: {
    from: string;
    to: string;
  };
  gradientPosition?: 'bottom' | 'top';
  gradientSize?: '100%' | '50%' | '25%';
  hasBackgroundOverlay?: boolean;
  overlapTop?: number;
}

const slopeDownNoSpike = 'polygon(0 100%, 0 0, 100vw 3vw, 100vw 100%)';
const slopeUpNoSpike = 'polygon(0 100%,0 3vw, 100vw 0, 100vw 100%)';

const slopeDownSpike = 'polygon(0 0, 76vw 3vw, 52vw 0, 100vw 1.5vw, 100vw 100%, 0 100%)';
const slopeUpSpike = 'polygon(0 1.5vw, 48vw 0, 24vw 3vw, 100vw 0, 100vw 100%, 0 100%)';

const StyledRiftTourSection = styled(Section)<StyledRiftTourSectionProps>`
  margin-top: ${({$clipPathDirection}) => ($clipPathDirection ? 'calc(-3vw - 1px)' : '-1px')};
`;

const StyledRiftTourSectionBackgroundImage = styled.img<StyledRiftTourSectionBackgroundImage>`
  clip-path: ${({$clipPathDirection, $clipPathSpike}) =>
    $clipPathDirection === 'slope-down' && $clipPathSpike
      ? slopeDownSpike
      : $clipPathDirection === 'slope-up' && $clipPathSpike
      ? slopeUpSpike
      : $clipPathDirection === 'slope-down' && !$clipPathSpike
      ? slopeDownNoSpike
      : $clipPathDirection === 'slope-up' && !$clipPathSpike
      ? slopeUpNoSpike
      : 'none'};
  height: 100%;
  left: 0;
  object-fit: cover;
  object-position: ${({$backgroundPosition}) =>
    $backgroundPosition ? `${$backgroundPosition}` : 'center'};
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%;
`;

const StyledRiftTourSectionBackgroundVideoOverlay = styled.div`
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAMAAAADCAYAAABWKLW/AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyBpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBXaW5kb3dzIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjkxNjgyMDA2NjJBNzExRTBCNjU1QzhBRDY1RUJCOUU4IiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjkxNjgyMDA3NjJBNzExRTBCNjU1QzhBRDY1RUJCOUU4Ij4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6OTE2ODIwMDQ2MkE3MTFFMEI2NTVDOEFENjVFQkI5RTgiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6OTE2ODIwMDU2MkE3MTFFMEI2NTVDOEFENjVFQkI5RTgiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz7UWbiJAAAAHUlEQVR42mJiYGD4DwQggoEJSDAyQAETAxIACDAAJMMJ/r07h+kAAAAASUVORK5CYII=);
  bottom: 0;
  left: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
`;

const StyledRiftTourSectionGradient = styled.div<StyledRiftTourSectionGradientProps>`
  bottom: ${({$gradientPosition}) => ($gradientPosition === 'bottom' ? '0' : 'initial')};
  position: absolute;
  width: 100%;
  height: ${({$gradientSize}) => $gradientSize};
  top: ${({$gradientPosition}) => ($gradientPosition === 'top' ? '0' : 'initial')};
  left: 0;
  pointer-events: none;
  z-index: 2;

  ${({$gradient}) =>
    $gradient?.from &&
    $gradient?.to &&
    `background-image: linear-gradient(180deg, ${$gradient.from} 0%, ${$gradient.to} 100%);`}
`;

export const RiftTourSection = ({
  backgroundImage,
  backgroundPosition,
  backgroundVideo,
  clipPathDirection,
  clipPathSpike,
  gradient,
  gradientPosition,
  gradientSize,
  hasBackgroundOverlay,
  overlapTop,
  ...props
}: RiftTourSectionProps) => {
  return (
    <StyledRiftTourSection
      $clipPathDirection={clipPathDirection}
      $clipPathSpike={clipPathSpike}
      $overlapTop={overlapTop}
      backgroundImage={backgroundImage}
      background={
        <>
          {backgroundImage && (
            <StyledRiftTourSectionBackgroundImage
              alt=""
              $backgroundPosition={backgroundPosition}
              $clipPathDirection={clipPathDirection}
              $clipPathSpike={clipPathSpike}
              src={getAssetPath(backgroundImage)}
            />
          )}
          {backgroundVideo && (
            <Video
              controls={false}
              height="100%"
              loop={true}
              muted={true}
              playing={true}
              playsinline={true}
              url={backgroundVideo}
              width="100%"
            />
          )}
          {backgroundVideo && hasBackgroundOverlay && (
            <StyledRiftTourSectionBackgroundVideoOverlay />
          )}
          {gradient && (
            <StyledRiftTourSectionGradient
              $gradient={gradient}
              $gradientPosition={gradientPosition}
              $gradientSize={gradientSize}
            />
          )}
        </>
      }
      size="5xl"
      {...props}
    />
  );
};
