import React from 'react';
import {animated, SpringConfig, useSpring} from 'react-spring';
import root from 'window-or-global';

const calc = (x: number, y: number) => [x - root.innerWidth / 2, y - root.innerHeight / 2];
const translation = (x: number, y: number) => `translate3d(${x / 25}px,${y / 25}px, 0)`;
const config: SpringConfig = {
  mass: 40,
  tension: 300,
  friction: 300,
};

interface HelpCenterHeroProps {
  xy: any;
  heroImage: string;
}

const HelpCenterHero = (props: HelpCenterHeroProps) => {
  const [coords, setCoords] = useSpring(() => ({xy: [0, 0], config}));

  return (
    <div onMouseMove={({clientX: x, clientY: y}) => setCoords({xy: calc(x, y)})}>
      <animated.img
        src={props.heroImage}
        alt=""
        style={{transform: coords.xy.interpolate(translation)}}
      />
    </div>
  );
};

export default HelpCenterHero;
