import axios from 'axios';
import root from 'window-or-global';

import PageActions from '../actions/PageActions';
import Url from '../constants/Url';

/**
 * PageSource
 *
 * Responsible for making the ajax calls to retrieve page contents.
 *
 * For more information on source usage:
 * http://alt.js.org/docs/async/
 *
 */
const PageSource = {
  getPage: {
    remote(state, locale, slug, type) {
      //the store state is accessible here
      return axios.get(Url().PAGE.GET(locale, slug, type));
    },

    //On success call the following action:
    success: PageActions.getPageSuccess,

    //On failure call the following action:
    error: PageActions.getPageFailure,

    shouldFetch() {
      return !root.__server_side_render;
    },
  },
};

export default PageSource;
