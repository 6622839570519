import HtmlTemplate from 'epic-fortnite-react/components/common/HtmlTemplate';
import React from 'react';

import Img from './Img';
import StepDescription from './StepDescription';
import StepHeader from './StepHeader';
import StepListItem from './StepListItem';

const Step = ({
  image,
  alt,
  description,
  title,
  stepNumber,
  accentColor,
}: {
  image: string;
  alt: string | undefined;
  description: string;
  title: string;
  stepNumber: number;
  accentColor: string;
}) => {
  return (
    <StepListItem>
      <Img src={image} alt={alt} />
      <StepHeader color={accentColor}>
        {stepNumber}: {title}
      </StepHeader>
      <StepDescription>
        <HtmlTemplate html={description} />
      </StepDescription>
    </StepListItem>
  );
};

export default Step;
