import {Container, Grid, GridColumn, Stack, Video} from 'epic-fortnite-react';
import React from 'react';

import {RiftTourCTA} from './components/RiftTourCTA';
import {RiftTourHeading} from './components/RiftTourHeading';
import {RiftTourSection} from './components/RiftTourSection';
import {RiftTourSubheading} from './components/RiftTourSubheading';
import StyledRiftTourPlaceholder from './components/StyledRiftTourPlaceholder';
import {RiftTourBackground, RiftTourCtaInterface} from './types';

interface Props {
  data: CMSData;
}

interface CMSData {
  background: RiftTourBackground;
  cta: RiftTourCtaInterface;
  heroVideo: string;
  subtitle: string;
  textHeading: string;
}

const RiftTourTiktokPromo = ({data}: Props) => {
  const {textHeading, subtitle, heroVideo, background, cta} = data;
  const {
    backgroundImage,
    gradientFrom,
    gradientPosition,
    backgroundOption,
    gradientSize,
    gradientTo,
  } = background;

  return (
    <RiftTourSection
      backgroundImage={backgroundImage}
      backgroundPosition={backgroundOption}
      clipPathDirection={background?.overlayDirection}
      clipPathSpike={background?.hasSpike}
      gradient={{from: gradientFrom, to: gradientTo}}
      gradientPosition={gradientPosition}
      gradientSize={`${gradientSize}%`}
      overlapTop={74}>
      <Container maxWidth={1200} size="med">
        <Grid breakpoint={900} gap="2xl">
          <GridColumn span={1 / 2}>
            <Stack align="center" gap="lg" justify="center">
              <RiftTourHeading color="#000">{textHeading}</RiftTourHeading>
              <RiftTourSubheading color="#000">{subtitle}</RiftTourSubheading>
              <RiftTourCTA label={cta?.label} to={cta?.linkTo} theme={cta.theme} />
            </Stack>
          </GridColumn>
          <GridColumn span={1 / 2}>
            {heroVideo ? (
              <Video aspectRatio="9:16" maxWidth={400} videoUrl={heroVideo} />
            ) : (
              <StyledRiftTourPlaceholder />
            )}
          </GridColumn>
        </Grid>
      </Container>
    </RiftTourSection>
  );
};

export default RiftTourTiktokPromo;
