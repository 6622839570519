import styled from 'styled-components';

const BattlePassSection = styled.section<{backgroundColor?: string}>`
  position: relative;
  z-index: 1;
  background-color: ${(props) => (props.backgroundColor ? props.backgroundColor : 'transparent')};
  padding: 0 0 11em;
`;

export default BattlePassSection;
