import {generateRouteTo} from 'epic-fortnite-react';
import {ContextMessage as Message} from '@epic-core/localization';
import classNames from 'classnames';
import RegisterActions from 'epic-fortnite-react/actions/RegisterActions';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React from 'react';
import {Redirect, withRouter} from 'react-router';

class DeviceSelect extends React.Component {
  static propTypes = {
    content: PropTypes.object,
    match: PropTypes.object,
    status: PropTypes.object,
    //history: PropTypes.object,
    location: PropTypes.object,
    isLoggedIn: PropTypes.bool,
    android: PropTypes.bool,
    locale: PropTypes.string,
  };

  state = {
    device: 'noop',
  };

  onClick = () => {
    const selectValue = this.state.device;
    console.log(selectValue);
    if (selectValue !== 'noop') {
      RegisterActions.register({
        locale: this.props.match.params.locale,
        platform: selectValue,
      });
    }
  };

  handleChange = (e) => {
    this.setState({device: e.target.value});
  };

  render() {
    const {content, android, isLoggedIn, status, location, locale} = this.props;
    const query = queryString.parse(location.search);

    if (android && !isLoggedIn) {
      query.selectDevice = true;
      return (
        <Redirect
          to={generateRouteTo(
            `/mobile/android/sign-up?${queryString.stringify(query)}`,
            location,
            locale
          )}
        />
      );
    } else if (android && status && status.registered) {
      return (
        <Redirect
          to={generateRouteTo(
            `/mobile/android/confirm?${queryString.stringify(query)}`,
            location,
            locale
          )}
        />
      );
    }

    const options = [];
    const devices = content.devices || [];
    const androidDeviceList = content.deviceList.split(',') || [];
    const defaultMessage = android ? androidDeviceList[0] : devices[0];
    if (android) {
      for (let x = 0; x < androidDeviceList.length; x++) {
        const item = androidDeviceList[x];
        if (x === 0) {
          options.push(
            <option key={`option-${x}`} disabled={true}>
              {item}
            </option>
          );
        } else {
          options.push(
            <option key={`option-${x}`} value={`android-${item}`}>
              {item}
            </option>
          );
        }
      }
    } else {
      for (let x = 0; x < devices.length; x++) {
        const item = devices[x];
        options.push(
          <option key={`option-${x}`} disabled={item.disabled} value={item.platform}>
            {item.device}
          </option>
        );
      }
    }
    const deviceClass = classNames('device-select', {android});
    /* eslint-disable jsx-a11y/no-onchange */
    return (
      <div className={deviceClass}>
        <div className="container-fluid content-container">
          <div className="container">
            <div className="row">
              <div className="col-xs-12 col-md-9 col-md-offset-3 text-center">
                <div className="content">
                  <h2>{content.title}</h2>
                  <div
                    className="sub-text"
                    dangerouslySetInnerHTML={{__html: content.description}}
                  />
                  <h4>{content.note}</h4>
                  <select /*eslint-disable-line*/
                    ref={(c) => {
                      this.deviceSelect = c;
                    }}
                    id="deviceSelect"
                    defaultValue={defaultMessage}
                    className="form-control input-lg"
                    onChange={this.handleChange}>
                    {options}
                  </select>
                  <button
                    id="fnMobile-deviceSelect"
                    className="btn btn-primary"
                    onClick={this.onClick}>
                    <Message code="epic.fortnite.sfs.commander.select" />
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="heroes" />
        </div>
      </div>
    );
  }
}

export default withRouter(DeviceSelect);
