import PropTypes from 'prop-types';
import React from 'react';
import root from 'window-or-global';

import EGSDownloadButtons from './images/EGSDownloadButtons';

const formatLocale = (input) => {
  if (!input) {
    return 'EN';
  }
  const locale = input.replace('-', '_');

  if (locale === 'en_US') {
    return 'EN';
  }

  // Use Traditional Chinese
  if (locale === 'zh_HK' || locale === 'zh_TW') {
    return 'ZH_HANT';
  }
  return locale.toUpperCase();
};

export function getAssetPath(asset) {
  return `${root.__webpack_asset_path || ''}${asset}`;
}

const EGSDownloadImage = ({locale = 'en-US', theme = 'DARK', className = 'egsDownloadImg'}) => {
  const formatedLocale = formatLocale(locale);
  const imageName = `EGS_${formatedLocale}_${theme}`;
  return (
    <img className={className} src={getAssetPath(EGSDownloadButtons[imageName])} alt={imageName} />
  );
};

EGSDownloadImage.propTypes = {
  locale: PropTypes.string,
  theme: PropTypes.oneOf(['DARK', 'LIGHT ']),
  className: PropTypes.string,
};

export default EGSDownloadImage;
