import styled from 'styled-components';

import {Device} from '../../../constants';

const ContentBoxDescription = styled.p`
  color: ${(props) => (props.color ? props.color : '#fff')};
  font-size: 1.2em !important;
  margin: 0;
  position: relative;
  z-index: 1;
  transform: translateZ(1px);
  position: relative;

  @media screen and ${Device.laptop} {
    font-size: 1em !important;
  }
`;

export default ContentBoxDescription;
