import {generateRouteTo} from 'epic-fortnite-react';
import classNames from 'classnames';
import LoginActions from 'epic-fortnite-react/actions/LoginActions';
import {BackgroundVideo} from 'epic-react-common';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React from 'react';
import {withRouter, Redirect} from 'react-router';
import {Link} from 'react-router-dom';

import Header from './Header';

class SignUpPage extends React.Component {
  static propTypes = {
    content: PropTypes.object,
    isLoggedIn: PropTypes.bool,
    status: PropTypes.object,
    history: PropTypes.object,
    title: PropTypes.string,
    mobile: PropTypes.bool,
    tablet: PropTypes.bool,
    location: PropTypes.object,
    android: PropTypes.bool,
    isSamsung: PropTypes.bool,
    downloadActive: PropTypes.bool,
    locale: PropTypes.string,
  };

  componentDidUpdate(prevProps) {
    if (this.props.status !== prevProps.status) {
      const {status, android, history, location, locale} = this.props;
      if (status && status.registered) {
        const query = queryString.parse(location.search) || {};
        if (android) {
          history.push(
            generateRouteTo(
              {pathname: `/mobile/android/confirm`, search: `?${queryString.stringify(query)}`},
              location,
              locale
            )
          );
        } else {
          history.push(
            generateRouteTo(
              {pathname: `/mobile/confirm`, search: `?${queryString.stringify(query)}`},
              location,
              locale
            )
          );
        }
      }
    }
  }

  getReturnPath = () => {
    const {android, location} = this.props;
    const query = queryString.parse(location.search) || {};
    if (android) {
      return `/mobile/android/device-select?${queryString.stringify(query)}`;
    }
    return `/mobile/device-select?${queryString.stringify(query)}`;
  };

  showOverlay = () => {
    LoginActions.requestLoginModal({
      redirectUrl: this.getReturnPath(),
    });
  };

  render() {
    const {
      content,
      isLoggedIn,
      title,
      mobile,
      tablet,
      android,
      isSamsung,
      downloadActive,
      location,
      locale,
    } = this.props;

    const query = queryString.parse(this.props.location.search) || {};
    const androidDeviceSelect = generateRouteTo(
      `/mobile/android/device-select?${queryString.stringify(query)}`,
      location,
      locale
    );
    const iosDeviceSelect = generateRouteTo(
      `/mobile/device-select?${queryString.stringify(query)}`,
      location,
      locale
    );
    const deviceSelect = android ? androidDeviceSelect : iosDeviceSelect;

    //redirect samsung users to get started flows //
    if (isSamsung && android && downloadActive) {
      return (
        <Redirect
          to={generateRouteTo(
            `/mobile/android/download?${queryString.stringify(query)}`,
            location,
            locale
          )}
        />
      );
    }
    const signUpClass = classNames('sign-up-page', {android});
    return (
      <div className={signUpClass}>
        <div className="header-wrapper">
          <div className="container-fluid content-wrapper">
            <div className="head">
              <div className="content text-center">
                <Header android title={title} />
                <div className="main-content" dangerouslySetInnerHTML={{__html: content.title}} />
                <div className="btn-container text-center">
                  {!isLoggedIn ? (
                    <button
                      id="fnMobile-signUp-btn"
                      onClick={this.showOverlay}
                      className="btn btn-primary height">
                      <span>{content.cta}</span>
                      <span className="small">{content.emailReminder}</span>
                    </button>
                  ) : (
                    <Link
                      id="fnMobile-signUp-btn-signedIn"
                      to={deviceSelect}
                      className="btn btn-primary height">
                      <span>{content.cta}</span>
                      <span className="small">{content.emailReminder}</span>
                    </Link>
                  )}
                </div>
              </div>
            </div>
            <div className="space-man" />
          </div>
        </div>
        <div className="container-fluid white-bk">
          <div className="container text-section text-center">
            <div className="row">
              <div className="col-xs-12">
                <div className="requirements" dangerouslySetInnerHTML={{__html: content.note}} />
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12">
                <div className="embed-responsive embed-responsive-16by9 media-container">
                  <BackgroundVideo
                    background={content.video}
                    play={false}
                    noOverlay
                    playsInline={mobile || tablet}
                    loop
                    enableRatioHelper={false}
                    mobile={mobile}
                    tablet={tablet}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12">
                <div dangerouslySetInnerHTML={{__html: content.description}} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(SignUpPage);
