import React from 'react';

import Gradient from '../../components/Gradient';
import ResponsiveImage from '../../components/ResponsiveImage';
import {MaskProps} from '../../types';

import MaskDetails from './FullMaskDetails';
import HalfExposeWrap from './HalfExposeWrap';
import HalfImageWrap from './HalfImageWrap';
import HalfMaskDetailsWrapper from './HalfMaskDetailsWrapper';
import SideToFullGrid from './SideToFullGrid';

interface Props extends MaskProps {
  alternateOrder?: boolean;
}

const HalfExpose = ({
  backgroundGradient,
  accent,
  name,
  description,
  image,
  type,
  cta,
  alternateOrder,
}: Props) => {
  return (
    <HalfExposeWrap>
      <Gradient gradient={backgroundGradient}>
        <SideToFullGrid alternateOrder={alternateOrder}>
          <HalfMaskDetailsWrapper>
            <MaskDetails
              accent={accent}
              name={name}
              description={description}
              type={type}
              cta={cta}
            />
          </HalfMaskDetailsWrapper>
          <HalfImageWrap alternateOrder={alternateOrder}>
            <ResponsiveImage
              desktopSrc={image.desktopImage}
              tabletSrc={image.tabletImage}
              mobileSrc={image.mobileImage}
            />
          </HalfImageWrap>
        </SideToFullGrid>
      </Gradient>
    </HalfExposeWrap>
  );
};

export default HalfExpose;
