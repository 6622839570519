import {Container, Section, Text} from 'epic-fortnite-react';
import React from 'react';

import {FunctionalThemeProps} from './types';
export interface SimpleTextModuleProps {
  textContent: string;
  alignment: 'left' | 'center' | 'right';
  theme: FunctionalThemeProps;
}

const SimpleTextModule: React.FC<SimpleTextModuleProps> = ({
  textContent,
  theme,
}: SimpleTextModuleProps) => {
  return (
    textContent && (
      <Section
        backgroundColor={theme.pageBackgroundColor ? theme.pageBackgroundColor : '#fff'}
        size="3xl">
        <Container maxWidth={800} size="med">
          <Text
            align="center"
            color="var(--bundle-text-color--body)"
            lineLength="med"
            size="xl"
            transform="uppercase">
            {textContent}
          </Text>
        </Container>
      </Section>
    )
  );
};

export default SimpleTextModule;
