import PropTypes from 'prop-types';
import React from 'react';
import {Route} from 'react-router-dom';

interface Props {
  code: number;
  children: React.ReactElement;
}

const StatusCode = ({code, children}: Props) => {
  return (
    <Route
      render={({staticContext}) => {
        if (staticContext) staticContext.statusCode = code;
        return children;
      }}
    />
  );
};

StatusCode.propTypes = {
  code: PropTypes.number,
  children: PropTypes.element,
};

export default StatusCode;
