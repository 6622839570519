import axios from 'axios';
import root from 'window-or-global';

import CosplayMaskActions from '../actions/CosplayMaskActions';
import Url from '../constants/Url';

const CosplayMaskSource = {
  getMask: {
    remote(state, locale, mask) {
      return axios.get(Url().COSPLAY.MASK(locale, mask));
    },

    success: CosplayMaskActions.getMaskSuccess,
    error: CosplayMaskActions.getMaskFailure,

    shouldFetch() {
      return !root.__server_side_render;
    },
  },
};

export default CosplayMaskSource;
