import React from 'react';

import {CmsComponentMapper} from '@epic-mw/react-cms';
import SimpleTextWithBackground from '../blocks/SimpleTextWithBackground';

const ArchivedEventBaseplate = (props) => {
  const {blocks} = props;

  return (
    <CmsComponentMapper
      dynamicPageDataArray={blocks}
      componentMapping={{
        textSection: (props: React.ComponentProps<typeof SimpleTextWithBackground>) => (
          <SimpleTextWithBackground {...props} />
        ),
      }}
    />
  );
};

export default ArchivedEventBaseplate;
