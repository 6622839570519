import {Container, Section, Stack, Text} from 'epic-fortnite-react';
import React from 'react';
import styled from 'styled-components';

const StyledXpBeyond = styled(Section)`
  position: relative;
  z-index: 2;
`;

const StyledLogo = styled.img`
  width: 10em;
  margin: 0 auto;
`;

const StyledContentWrapper = styled.div`
  padding: 0 25px;
`;

const XpBeyond = ({data, mobile}) => {
  const {coreSectionProps, heroImage, text} = data;
  const headingSize = mobile ? '2xl' : '5xl';
  const contentSize = mobile ? 'med' : 'lg';
  const bgImg = mobile ? coreSectionProps.mobileBackgroundImage : coreSectionProps.backgroundImage;

  return (
    <StyledXpBeyond
      backgroundImage={bgImg}
      backgroundPosition={coreSectionProps?.backgroundPosition}
      backgroundSize={coreSectionProps?.backgroundSize}
      size="6xl">
      <Container>
        <Stack gap="lg" align="center">
          <StyledLogo src={heroImage} />
          <Text
            color="#c777fa"
            align="center"
            lineLength="med"
            size={headingSize}
            transform="uppercase">
            {text.header}
          </Text>
          <Text
            color="#c777fa"
            align="center"
            lineLength="med"
            size={headingSize}
            transform="uppercase">
            {text.subtitle}
          </Text>
          <StyledContentWrapper>
            <Text
              color="#FFFFFF"
              lineLength="med"
              size={contentSize}
              transform="uppercase"
              align="center">
              {text.textContent}
            </Text>
          </StyledContentWrapper>
        </Stack>
      </Container>
    </StyledXpBeyond>
  );
};

export default XpBeyond;
