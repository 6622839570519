import EGS_AR_DARK from './egsDownloadBtns/AR_EG_Store_Button_Prim_Dark.png';
import EGS_AR_LIGHT from './egsDownloadBtns/AR_EG_Store_Button_Prim_Light.png';
import EGS_DE_DARK from './egsDownloadBtns/DE_EG_Store_Button_Prim_Dark.png';
import EGS_DE_LIGHT from './egsDownloadBtns/DE_EG_Store_Button_Prim_Light.png';
import EGS_EN_DARK from './egsDownloadBtns/EN_EG_Store_Button_Prim_Dark.png';
import EGS_EN_LIGHT from './egsDownloadBtns/EN_EG_Store_Button_Prim_Light.png';
import EGS_ES_ES_DARK from './egsDownloadBtns/ES_ES_EG_Store_Button_Prim_Dark.png';
import EGS_ES_ES_LIGHT from './egsDownloadBtns/ES_ES_EG_Store_Button_Prim_Light.png';
import EGS_ES_MX_DARK from './egsDownloadBtns/ES_MX_EG_Store_Button_Prim_Dark.png';
import EGS_ES_MX_LIGHT from './egsDownloadBtns/ES_MX_EG_Store_Button_Prim_Light.png';
import EGS_FR_DARK from './egsDownloadBtns/FR_EG_Store_Button_Prim_Dark.png';
import EGS_FR_LIGHT from './egsDownloadBtns/FR_EG_Store_Button_Prim_Light.png';
import EGS_IT_DARK from './egsDownloadBtns/IT_EG_Store_Button_Prim_Dark.png';
import EGS_IT_LIGHT from './egsDownloadBtns/IT_EG_Store_Button_Prim_Light.png';
import EGS_JA_DARK from './egsDownloadBtns/JP_EG_Store_Button_Prim_Dark.png';
import EGS_JA_LIGHT from './egsDownloadBtns/JP_EG_Store_Button_Prim_Light.png';
import EGS_KO_DARK from './egsDownloadBtns/KR_EG_Store_Button_Prim_Dark.png';
import EGS_KO_LIGHT from './egsDownloadBtns/KR_EG_Store_Button_Prim_Light.png';
import EGS_PL_DARK from './egsDownloadBtns/PL_EG_Store_Button_Prim_Dark.png';
import EGS_PL_LIGHT from './egsDownloadBtns/PL_EG_Store_Button_Prim_Light.png';
import EGS_PT_BR_DARK from './egsDownloadBtns/PT_BR_EG_Store_Button_Prim_Dark.png';
import EGS_PT_BR_LIGHT from './egsDownloadBtns/PT_BR_EG_Store_Button_Prim_Light.png';
import EGS_RU_DARK from './egsDownloadBtns/RU_EG_Store_Button_Prim_Dark.png';
import EGS_RU_LIGHT from './egsDownloadBtns/RU_EG_Store_Button_Prim_Light.png';
import EGS_TH_DARK from './egsDownloadBtns/TH_EG_Store_Button_Prim_Dark.png';
import EGS_TH_LIGHT from './egsDownloadBtns/TH_EG_Store_Button_Prim_Light.png';
import EGS_TR_DARK from './egsDownloadBtns/TR_EG_Store_Button_Prim_Dark.png';
import EGS_TR_LIGHT from './egsDownloadBtns/TR_EG_Store_Button_Prim_Light.png';
import EGS_ZH_CN_DARK from './egsDownloadBtns/ZH_CN_EG_Store_Button_Prim_Dark.png';
import EGS_ZH_CN_LIGHT from './egsDownloadBtns/ZH_CN_EG_Store_Button_Prim_Light.png';
import EGS_ZH_HANT_DARK from './egsDownloadBtns/ZH_HANT_EG_Store_Button_Prim_Dark.png';
import EGS_ZH_HANT_LIGHT from './egsDownloadBtns/ZH_HANT_EG_Store_Button_Prim_Light.png';

export default {
  EGS_AR_DARK,
  EGS_AR_LIGHT,

  EGS_EN_DARK,
  EGS_EN_LIGHT,

  EGS_DE_DARK,
  EGS_DE_LIGHT,

  EGS_ES_ES_DARK,
  EGS_ES_ES_LIGHT,

  EGS_ES_MX_DARK,
  EGS_ES_MX_LIGHT,

  EGS_FR_DARK,
  EGS_FR_LIGHT,

  EGS_IT_DARK,
  EGS_IT_LIGHT,

  EGS_JA_DARK,
  EGS_JA_LIGHT,

  EGS_KO_DARK,
  EGS_KO_LIGHT,

  EGS_PL_DARK,
  EGS_PL_LIGHT,

  EGS_PT_BR_DARK,
  EGS_PT_BR_LIGHT,

  EGS_RU_DARK,
  EGS_RU_LIGHT,

  EGS_TH_DARK,
  EGS_TH_LIGHT,

  EGS_TR_DARK,
  EGS_TR_LIGHT,

  EGS_ZH_CN_DARK,
  EGS_ZH_CN_LIGHT,

  EGS_ZH_HANT_DARK,
  EGS_ZH_HANT_LIGHT,
};
