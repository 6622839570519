import {getLocale} from '@epic-core/common';
import {generateRouteHref} from 'epic-fortnite-react';
import React from 'react';
import {useLocation} from 'react-router';

import {CreativeLinkIcon, StyledCreativeDocsLink} from './';

interface Props {
  title?: string;
  url?: string;
}

const CreativeHomeHeaderDocsLink = ({title = '', url = ''}: Props) => {
  const location = useLocation();
  const locale = getLocale();
  return (
    <h2>
      <StyledCreativeDocsLink href={generateRouteHref(url, location, locale)}>
        {title}
        <CreativeLinkIcon />
      </StyledCreativeDocsLink>
    </h2>
  );
};

export default CreativeHomeHeaderDocsLink;
