import React from 'react';

import HeroLoadoutContainer from './HeroLoadoutContainer';
import LoadoutItem from './LoadoutItem';
import {ImageArray} from './Types';
interface Props {
  items: ImageArray[];
  active: boolean;
  reverse?: boolean;
  keyId: string;
  mobile: boolean;
  tablet: boolean;
}

const HeroLoadout = ({items, active, reverse, keyId, mobile, tablet}: Props) => {
  return (
    <HeroLoadoutContainer reverse={reverse}>
      {items.map((item, index) => {
        return (
          <LoadoutItem
            mobile={mobile}
            tablet={tablet}
            keyId={`${keyId}-${index}-${reverse ? 'left' : 'right'}`}
            leftSide={reverse}
            active={active}
            image={item}
            key={item.imageAlt}
          />
        );
      })}
    </HeroLoadoutContainer>
  );
};

export default HeroLoadout;
