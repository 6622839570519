import React from 'react';
import styled from 'styled-components';

import FeatureImageContainer from '../components/FeatureImageContainer';
import HeroSection from '../components/HeroSection';
import HeroSubtitle from '../components/HeroSubtitle';
import HeroTitle from '../components/HeroTitle';
import ImageOverlay from '../components/ImageOverlay';
import Logo from '../components/Logo';

import ResponsiveImage from './components/ResponsiveImage';

export interface OverviewHeroSectionProps {
  header?: string;
  headerColor?: string;
  subheader?: string;
  backgroundGradient: {
    from: string;
    to: string;
  };
  featureImage?: {
    desktopImage: string;
    tabletImage: string;
    mobileImage: string;
    altText: string;
  };
  logo?: string;
}

const TextBox = styled.div`
  position: absolute;
  bottom: 15%;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

const OverviewHeroSection = ({
  header,
  headerColor = undefined,
  subheader,
  backgroundGradient,
  featureImage,
  logo,
}: OverviewHeroSectionProps) => {
  const {desktopImage, mobileImage, tabletImage, altText} = featureImage ?? {};

  return (
    <HeroSection gradient={backgroundGradient}>
      <FeatureImageContainer>
        <ResponsiveImage
          alt={altText}
          desktopSrc={desktopImage}
          mobileSrc={mobileImage}
          tabletSrc={tabletImage}
        />
      </FeatureImageContainer>
      <ImageOverlay>
        {logo ? <Logo alt="" src={logo} /> : null}
        <TextBox>
          {header ? <HeroTitle color={headerColor}>{header}</HeroTitle> : null}
          {subheader ? <HeroSubtitle>{subheader}</HeroSubtitle> : null}
        </TextBox>
      </ImageOverlay>
    </HeroSection>
  );
};

export default OverviewHeroSection;
