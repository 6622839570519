import {withLocalization} from '@epic-core/localization';
import {connectToStores} from 'epic-alt-utils';
import epicTracking from 'epic-tracking';
import PropTypes from 'prop-types';
import React from 'react';
import {withRouter} from 'react-router';
import root from 'window-or-global';

import {AppConfigStore, LoginStore, MobileStore} from '../../stores';

import DownloadButton from './DownloadButton';

/**
 * Download button wrapper to pass in store data.
 */
@connectToStores
class DownloadButtonTemplate extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    rawCta: PropTypes.string,
    cta: PropTypes.string,
    appConfigStore: PropTypes.object,
    mobileStore: PropTypes.object,
    messages: PropTypes.object,
    isLoggedIn: PropTypes.bool,
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    children: PropTypes.node,
  };

  static getStores() {
    return [LoginStore, AppConfigStore, MobileStore];
  }

  static getPropsFromStores() {
    return {
      loginStore: LoginStore.getState(),
      appConfigStore: AppConfigStore.getState(),
      mobileStore: MobileStore.getState(),
    };
  }

  constructor(props) {
    super(props);
    const currentOS = ['windows', 'mac', 'ios', 'android', 'xbl', 'psn', 'nintendo'].reduce(
      (accumulator, os) => {
        if (root._epicGamesNav.browser[os.toLowerCase()]) {
          return os.toLowerCase();
        }
        return accumulator;
      },
      ''
    );
    this.state = {
      currentOS,
      selectedOS: '',
    };
  }

  getBrowserOS() {
    return root._epicGamesNav.browser.osname.toLowerCase();
  }

  onSelect = (e) => {
    epicTracking.trackEvent('Interaction', {
      interactionType: 'click',
      eventCategory: 'downloadStaticPage',
      eventAction: 'selectDropDown',
      eventLabel: e.target.value,
    });
    this.setState({currentOS: e.target.value, selectedOS: e.target.value});
  };

  render() {
    const {currentOS} = this.state;
    const {messages, isLoggedIn, history, match, cta, rawCta} = this.props;
    const {appConfigStore, mobileStore, className} = this.props;
    const {countryCode} = appConfigStore;
    const isMobile =
      root._epicGamesNav.browser.ios ||
      root._epicGamesNav.browser.ipad ||
      root._epicGamesNav.browser.android;
    return (
      <div className="downloadButtons">
        <DownloadButton
          className={className}
          os={currentOS}
          download={isLoggedIn}
          isLoggedIn={isLoggedIn}
          countryCode={countryCode}
          appConfigStore={appConfigStore}
          messages={messages}
          mobile={isMobile}
          mobileStore={mobileStore}
          history={history}
          match={match}
          cta={cta}
          rawCta={rawCta}
          disableText={true}
        />
      </div>
    );
  }
}

export default withLocalization(withRouter(DownloadButtonTemplate));
