import React, {forwardRef, useRef} from 'react';
import styled from 'styled-components';

import {Text} from '../';
import {CharacterLimitStatus} from '../CharacterLimitStatus/CharacterLimitStatus';
import {ClearField} from '../Field/ClearField';
import {Field, FieldBaseProps, FieldLabelVariant} from '../Field/Field';

const validTypes = {
  text: 'text',
  password: 'password',
  email: 'email',
  search: 'search',
  number: 'number',
  tel: 'tel',
  url: 'url',
};

type InputProps = React.AllHTMLAttributes<HTMLInputElement>;

export type TextFieldBaseProps = Omit<FieldBaseProps, 'value' | 'labelId' | 'secondaryMessage'> & {
  characterLimit?: number;
  onBlur?: InputProps['onBlur'];
  onChange?: NonNullable<InputProps['onChange']>;
  onClear?: () => void;
  onFocus?: InputProps['onFocus'];
  placeholder?: InputProps['placeholder'];
  type?: keyof typeof validTypes;
  value: NonNullable<InputProps['value']>;
  background?: string;
};

export type TextFieldLabelProps = FieldLabelVariant;
export type TextFieldProps = TextFieldBaseProps & TextFieldLabelProps;

type StyledFieldInputWrapper = {
  $disabled?: boolean;
  $readOnly?: boolean;
};

const StyledFieldInputWrapper = styled.div<StyledFieldInputWrapper>`
  display: flex;

  ${({$disabled, $readOnly}) => ({
    cursor: $disabled && !$readOnly ? 'default' : 'initial',
  })}
`;

const StyledInput = styled.input<{background?: string}>`
  appearance: none;
  background: ${({background}) => (background ? background : 'transparent')};
  border: 0;
  border-radius: 0;
  cursor: inherit !important; // undo FN global styles
  padding: 16px 20px;
  font-size: 14px;
  flex: 1;
  flex-shrink: 1;
  min-width: 0;
  line-height: 24px;
  width: 100%;
`;

export const TextField = forwardRef<HTMLInputElement, TextFieldProps>(
  (
    {
      characterLimit,
      onBlur,
      onChange,
      onClear,
      onFocus,
      placeholder,
      type = 'text',
      value,
      ...props
    },
    forwardedRef
  ) => {
    // We need a ref regardless so we can imperatively
    // focus the field when clicking the clear button
    const defaultRef = useRef<HTMLInputElement | null>(null);
    const inputRef = forwardedRef || defaultRef;

    const clearable = Boolean(
      typeof onClear !== 'undefined' && typeof value === 'string' && value.length > 0
    );

    return (
      <Field
        labelId={undefined}
        secondaryHint={
          characterLimit && <CharacterLimitStatus value={value} characterLimit={characterLimit} />
        }
        secondaryIcon={
          onClear &&
          !props.disabled && <ClearField hide={!clearable} onClear={onClear} inputRef={inputRef} />
        }
        value={value}
        {...props}>
        {(fieldProps, icon, secondaryIcon, prefix) => (
          <StyledFieldInputWrapper $disabled={props.disabled} $readOnly={props.readOnly}>
            {icon}
            {prefix}
            {!props.readOnly ? (
              <StyledInput
                background={props?.background}
                onBlur={onBlur}
                onChange={onChange}
                onFocus={onFocus}
                placeholder={placeholder}
                ref={inputRef}
                type={validTypes[type]}
                value={value}
                {...fieldProps}
              />
            ) : (
              <Text font="OpenSans" size="sm">
                {value}
              </Text>
            )}
            {secondaryIcon}
          </StyledFieldInputWrapper>
        )}
      </Field>
    );
  }
);

TextField.displayName = 'TextField';
