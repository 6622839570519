import styled from 'styled-components';

import {breakpoints} from '../../../../constants/Breakpoints';

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-gap: 2rem;
  margin: 4rem 0;

  @media ${breakpoints.tablet} {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @media ${breakpoints.mobileL} {
    grid-template-columns: minmax(0, 1fr);
  }
`;

export default Grid;
