import styled from 'styled-components';

import {breakpoints} from '../../../../constants/Breakpoints';

interface Props {
  alternate?: boolean;
}

const CarouselWrapper = styled.div<Props>`
  ${({alternate = false}) => (alternate === true ? 'order: -1;' : null)}

  width: 100%;
  min-height: 0;
  position: relative;

  .slick-arrow i::before {
    color: white;
  }

  .slick-arrow:hover i::before {
    color: #fffe54;
  }

  @media ${breakpoints.desktop} {
    .slick-list {
      padding: 0 !important;
      height: unset;
    }

    .slick-slider .slick-arrow.slick-next,
    .slick-slider .slick-arrow.slick-prev {
      position: absolute;
      top: 0;
      left: unset;
      right: unset;
      bottom: unset;
      height: 100%;
    }

    .slick-slider .slick-arrow.slick-next {
      right: 0;
    }

    .slick-slider .slick-arrow.slick-prev {
      left: 0;
    }
  }

  @media ${breakpoints.tablet} {
    && .slick-slider .slick-control {
      width: 5rem;
    }
  }
`;

export default CarouselWrapper;
