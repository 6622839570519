import React from 'react';
import VisibilitySensor from 'react-visibility-sensor';

import {SlashIcon} from '../../../icons';

import CalloutBox from './CalloutBox';
import SlashySlash from './SlashySlash';
import VBucksContentBox from './VBucksContentBox';
import VBucksContentWrapper from './VBucksContentWrapper';
import VBucksFeatureContainer from './VBucksFeatureContainer';
import VBucksImage from './VBucksImage';

interface VBucksCmsData {
  calloutDescription: string;
  calloutTitle: string;
  description: string;
  title: string;
  vBuckImg: string;
  vBucksImageAlt: string;
}
interface Props {
  data: VBucksCmsData;
  mobile: boolean;
}
const VBucksFeature = ({data, mobile}: Props) => {
  const {vBuckImg, vBucksImageAlt, title, description, calloutTitle, calloutDescription} = data;
  const [showSlash, toggleSlash] = React.useState(true);
  const [visible, setVisibility] = React.useState(false);
  return (
    <VisibilitySensor
      active={!visible}
      partialVisibility
      minTopValue={600}
      onChange={(visibility) => setVisibility(visibility)}>
      <VBucksFeatureContainer className="VBucksFeature">
        {showSlash && <SlashySlash animate={visible} hide={toggleSlash} />}
        <SlashIcon id="slash-corner" />
        <VBucksContentWrapper>
          <VBucksContentBox animate={!showSlash} title={title} description={description} />
          <VBucksImage
            initial={{x: '100%'}}
            animate={!showSlash ? {x: '0%'} : {x: '100%'}}
            transition={{
              x: {type: 'spring', damping: 20, stiffness: 300},
            }}
            src={vBuckImg}
            alt={vBucksImageAlt}
          />
          <CalloutBox animate={!showSlash} title={calloutTitle} description={calloutDescription} />
        </VBucksContentWrapper>
      </VBucksFeatureContainer>
    </VisibilitySensor>
  );
};

export default VBucksFeature;
