import styled from 'styled-components';

import {breakpoints} from '../../../constants/Breakpoints';

const Logo = styled.img`
  width: 50rem;

  @media ${breakpoints.tablet} {
    width: 40rem;
  }

  @media ${breakpoints.mobileL} {
    width: 30rem;
  }
`;

export default Logo;
