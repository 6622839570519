export enum ModStatus {
    DRAFT = 'DRAFT',
    SUBMITTED = 'SUBMITTED',
    APPROVED = 'APPROVED',
    PUBLISHED = 'PUBLISHED',
    REJECTED = 'REJECTED',
    REMOVED = 'REMOVED',
    CHANGES_NEEDED = 'CHANGES_NEEDED',
    IN_REVIEW = 'IN_REVIEW'
}

export enum ModAction {
    APPROVE = 'APPROVE',
    PUBLISH = 'PUBLISH',
    REJECT = 'REJECT',
    SUBMIT = 'SUBMIT',
    RECALL = 'RECALL',
    REQUEST_CHANGES = 'REQUEST_CHANGES',
    UNPUBLISH = 'UNPUBLISH',
    REMOVE = 'REMOVE',
    COMMENT = 'COMMENT',
    RECOMMEND = 'RECOMMEND',
    UNRECOMMEND = 'UNRECOMMEND',
    LOCK = 'LOCK',
    UNLOCK = 'UNLOCK',
    CLEAR_REPORTS = 'CLEAR_REPORTS',
    CREATE_NEW_VERSION = 'CREATE_NEW_VERSION',
    AUTO_PUBLISHING_ENABLE = 'AUTO_PUBLISHING_ENABLE',
    AUTO_PUBLISHING_DISABLE = 'AUTO_PUBLISHING_DISABLE'
}

export enum ModActionStatus {
    SUCCEEDED = 'SUCCEEDED',
    FAILED = 'FAILED',
    IN_PROGRESS = 'IN_PROGRESS'
}
export enum Platform {
    Win64 = 'Windows',
    Win32 = 'Win32',
    Mac = 'Mac'
}
