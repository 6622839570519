import React from 'react';

import {Text} from '../';

interface CharacterLimitProps {
  characterLimit: number;
  value: string | number | ReadonlyArray<string>;
}

export const CharacterLimitStatus = ({characterLimit, value}: CharacterLimitProps) => {
  const inputLength = String(value).length;

  if (inputLength < Math.ceil((characterLimit * 0.7) / 10) * 10) {
    return null;
  }

  const diff = characterLimit - inputLength;
  const valid = diff >= 0;

  return (
    <Text font="OpenSans" size="sm" color={valid ? '#919191' : 'red'}>
      {diff}
    </Text>
  );
};
