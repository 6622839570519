import {Slider} from 'epic-fortnite-react';
import {useResponsiveBreakpoints} from 'epic-fortnite-react/hooks/useResponsiveBreakpoints';
import {isEmpty} from 'epic-fortnite-react/utils/isEmpty';
import React, {useRef} from 'react';
import styled from 'styled-components';

export type StyledScheduleCardProps = {
  $background: string;
};

export type ScheduleCardSliderProps = {
  slides: {
    background: string;
    content: React.ReactNode;
  }[];
};

const StyledScheduleCard = styled.div<StyledScheduleCardProps>`
  background-image: ${({$background}) => `url('${$background}')`};
  background-position: center;
  background-size: cover;
  height: 0;
  padding-top: 150%;
  position: relative;
  width: 100%;
`;

const StyledScheduleCardContent = styled.div`
  color: #fff;
  filter: drop-shadow(0px 0px 16px rgba(0, 0, 0, 0.15));
  left: 0;
  margin: auto;
  padding: 16px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
`;

const ScheduleCard = ({background, content}) => {
  return (
    <StyledScheduleCard $background={background}>
      <StyledScheduleCardContent>{content}</StyledScheduleCardContent>
    </StyledScheduleCard>
  );
};

export const ScheduleCardSlider = ({slides}: ScheduleCardSliderProps) => {
  const sliderRef = useRef(null);

  const slidesInView = useResponsiveBreakpoints(
    [{1: 650}, {2: 900}, {3: 1100}, {4: 1300}, {5: 1400}],
    sliderRef
  );

  return (
    <Slider
      align="center"
      ref={sliderRef}
      slideGap={24}
      slideOffset={64}
      slideOverflowX={32}
      slideOverflowY={0}
      slidesInView={parseInt(slidesInView.breakSize)}
      slidesPerClick={1}>
      {!isEmpty(slides) &&
        slides.map((slide, key) => {
          return <ScheduleCard background={slide.background} content={slide.content} key={key} />;
        })}
    </Slider>
  );
};
