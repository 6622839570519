import axios from 'axios';
import root from 'window-or-global';
import Url from '../constants/Url';
import SearchActions from '../actions/SearchActions';


const SearchSource = {
    bingSearch: {
        remote(state) {
            return axios.get(
                `${Url().SEARCH.BING}?keyword=${encodeURIComponent(state.query)}&offset=${state.offset}${state.filter ? `&filter=${encodeURIComponent(state.filter)}` : ''}`
            );
        },
        success: SearchActions.bingSearchSuccess,
        error: SearchActions.bingSearchFailure,
        shouldFetch() {
            return !root.__server_side_render;
        }
    }
};

export default SearchSource;
