import styled from 'styled-components';

interface Props {
  color?: string;
}

const ErrorText = styled.div<Props>`
  color: ${({color}: Props) => color};
  font-family: BurbankBigRegular-Black, sans-serif;
  font-size: 3rem;
  font-style: italic;
  text-transform: uppercase;
  text-align: center;
  margin: 2rem;
  max-width: 70rem;
`;

export default ErrorText;
