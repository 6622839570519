import {seamlessImmutable, seamlessBootstrap} from 'epic-alt-utils';
import Immutable from 'seamless-immutable';
import root from 'window-or-global';

import alt from '../alt';

@seamlessImmutable
class HeaderStore {
  getStateFunctions() {
    return {
      mobile() {
        return root._epicGamesNav ? root._epicGamesNav.browser.mobile : false;
      },
      tablet() {
        return root._epicGamesNav ? root._epicGamesNav.browser.tablet : false;
      },
      msie() {
        return root._epicGamesNav ? root._epicGamesNav.browser.msie : false;
      },
      msedge() {
        return root._epicGamesNav ? root._epicGamesNav.browser.msedge : false;
      },
      firefox() {
        return root._epicGamesNav ? root._epicGamesNav.browser.firefox : false;
      },
      android() {
        return root._epicGamesNav ? root._epicGamesNav.browser.android : false;
      },
      ios() {
        return root._epicGamesNav ? root._epicGamesNav.browser.ios : false;
      },
      bowser() {
        const bowser = root._epicGamesNav ? root._epicGamesNav.browser : {};
        return bowser;
      },
    };
  }

  constructor() {
    this.on('error', (error) => {
      console.error(error);
    });
    this.on('bootstrap', seamlessBootstrap.bind(this, null));
    const initialState = this.getStateFunctions();
    initialState.config = {};
    initialState.noHeaderPages = {};
    this.state = Immutable(initialState);
  }
}

export default alt.createStore(HeaderStore, 'HeaderStore');
