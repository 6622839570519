import styled, {css, keyframes} from 'styled-components';

const sideScrollStartLeft = keyframes`
    0% {
        transform: translate3d(0%, 0%, 1px);
    }
    50% {
        transform: translate3d(-50%, 0%, 1px);
    }
    100% {
        transform: translate3d(0%, 0%, 1px);
    }
`;

const sideScrollStartRight = keyframes`
    0% {
        transform: translate3d(0%, 0%, 1px);
    }
    50% {
        transform: translate3d(50%, 0%, 1px);
    }
    100% {
        transform: translate3d(0%, 0%, 1px);
    }
`;

const StyledSubscriptionPatternSlice = styled.div<{bgSlice: string; offset?: boolean}>`
  background: ${(props) => `url(${props.bgSlice})`} repeat-x top;
  background-size: cover;
  height: 100px;
  width: 200%;
  animation: ${sideScrollStartLeft} 200s linear infinite both;
  ${(props) =>
    props.offset &&
    css`
      animation: ${sideScrollStartRight} 200s linear infinite both;
      margin-left: -100%;
    `}
`;

export default StyledSubscriptionPatternSlice;
