import {withLocalization} from '@epic-core/localization';
import AnimateObject from 'epic-fortnite-react/components/common/AnimateObject';
import PropTypes from 'prop-types';
import React from 'react';

import AnimateString from '../common/AnimateString';

import VideoBlock from './VideoBlock';

class StwHeader extends React.PureComponent {
  static propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    description2: PropTypes.string,
    videos: PropTypes.array,
    isLoggedIn: PropTypes.bool,
    getMessage: PropTypes.func,
  };

  render() {
    const {title, description, description2, videos} = this.props;
    const ctaFrom = {
      opacity: 0,
      rotationX: 60,
      rotationY: 60,
      transformOrigin: 'center bottom',
    };
    const ctaTo = {
      delay: 0.6,
      opacity: 1,
      rotationX: 0,
      rotationY: 0,
    };

    return (
      <div className="hero-section">
        <div className="call-out">
          <AnimateString
            key="title"
            text={title}
            type="h1"
            speed={1.5}
            delay={0.3}
            autoAlpha={0}
            yPos={100}
            minTop={20}
            scaleFrom={1}
            staggerDelay={0}
          />
          <AnimateString
            key="desc"
            text={description}
            type="h3"
            speed={1.5}
            delay={0.6}
            autoAlpha={0}
            yPos={100}
            minTop={20}
            scaleFrom={1}
            staggerDelay={0}
          />
          <AnimateObject minTop={20} speed={1} from={ctaFrom} to={ctaTo}></AnimateObject>
        </div>
        <VideoBlock videos={videos} />
        <div className="stw-header__text">{description2}</div>
      </div>
    );
  }
}

export default withLocalization(StwHeader);
