const colors = {
    darkGray: '#1e1e1e',
    lightGray: '#f8f8f8',
    white: '#ffffff',
    bingBrandSecondary: '#505050',
    titleBlue: '#077aa8',
    linkGreen: '#006621',
    accentBlue: '#0aaff1'
};

export default colors;
