'use strict';

import React from 'react';
import {InnerSlider} from './inner-slider';
import assign from 'object-assign';
import defaultProps from './default-props';

export class Slider extends React.Component {
  constructor(props) {
    super(props)
    this.innerSliderRefHandler = this.innerSliderRefHandler.bind(this)
  }

  innerSliderRefHandler(ref) {
    this.innerSlider = ref;
  }

  slickPrev() {
    this.innerSlider.slickPrev();
  }

  slickNext() {
    this.innerSlider.slickNext();
  }

  slickGoTo(slide) {
    this.innerSlider.slickGoTo(slide)
  }

  render() {
    var settings = assign({}, defaultProps, this.props);

    var children = this.props.children;
    if(!Array.isArray(children)) {
      children = [children]
    }

    // Children may contain false or null, so we should filter them
    children = children.filter(function(child){
      return !!child
    })

    if (settings === 'unslick') {
      // if 'unslick' responsive breakpoint setting used, just return the <Slider> tag nested HTML
      return (
        <div className={`${this.props.className} unslicked`}>
          {children}
        </div>
      );
    } else {
      return (
        <InnerSlider ref={this.innerSliderRefHandler} {...settings}>
          {children}
        </InnerSlider>
      );
    }
  }
}