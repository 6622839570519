import {withLocalization} from '@epic-core/localization';
import {connectToStores} from 'epic-alt-utils';
import React from 'react';

import PageJumbotron from '../../components/common/PageJumbotron';
import BingResultsList from '../../components/search/BingResultsList';
import BingSearchStore from '../../stores/BingSearchStore';

import {getParams, getParamsFilterOrDefault, getSearchConfigOrDefaults} from './helpers';
import type {SearchStoreState} from './types';

type Props = {
  locale: string;
  location?: {search: string};
  messages: Record<string, string>;
  searchStore?: SearchStoreState;
  theme: Record<string, string>;
};

/**
 * SearchView.
 *
 * Rendered when the Route matches /search
 */
@connectToStores
class SearchView extends React.Component<Props> {
  static getStores() {
    return [BingSearchStore];
  }

  static getPropsFromStores() {
    return {
      searchStore: BingSearchStore.getState(),
    };
  }

  render() {
    const {searchStore, theme, messages, location, locale} = this.props;
    const queryString = location?.search || '';
    const {filter: paramsFilter, offset, query} = getParams(queryString);

    const config = getSearchConfigOrDefaults(searchStore?.config);
    const filter = getParamsFilterOrDefault(paramsFilter, config?.searchFilters);

    return (
      <div className="search-ctr">
        <PageJumbotron sectionName="epic.common.search" />

        <div className="searchView">
          <div className="container">
            <BingResultsList
              enableCustomFilter={config.enableCustomFilter}
              searchFilters={config.searchFilters}
              messages={messages}
              theme={theme}
              searchStore={searchStore}
              query={query}
              offset={offset}
              filter={filter}
              locale={locale}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withLocalization(SearchView);
