import epicTracking from 'epic-tracking';
import React from 'react';
import VisibilitySensor from 'react-visibility-sensor';

import {
  StyledBattleStarsContainer,
  StyledBattleStarsContentContainer,
  StyledBattleStarsTextContent,
  StyledBattleStarsHeroImage,
  StyledBattleStarsContentBackground,
} from './components/StyledBattleStarsComponents';

interface CmsData {
  header: string;
  textContent: string;
  textBgImg: string;
  bgImg: string;
  heroImg: string;
  mobileImg: string;
}

interface Props {
  mobile: boolean;
  tablet: boolean;
  data: CmsData;
}

const BattleStarsFeature = ({data, mobile, tablet}: Props) => {
  const [visible, setVisibility] = React.useState(false);

  const setSectionVisibility = React.useCallback(
    (visibility) => {
      setVisibility(visibility);
      if (visibility) {
        epicTracking.trackEvent('Interaction', {
          interactionType: 'scrollViewSection',
          eventCategory: 'BattlePass Battle Stars Section',
          eventAction: 'BattleStarsSection-inView',
        });
      }
    },
    [setVisibility]
  );

  return (
    <VisibilitySensor
      active={!visible}
      onChange={setSectionVisibility}
      partialVisibility
      minTopValue={0}>
      <StyledBattleStarsContainer bgImg={data.bgImg}>
        <StyledBattleStarsContentContainer>
          {!mobile && <img src={data.textBgImg} alt={''} />}
          <StyledBattleStarsContentBackground>
            <StyledBattleStarsTextContent>
              <h2>{data.header}</h2>
              <p>{data.textContent}</p>
            </StyledBattleStarsTextContent>
          </StyledBattleStarsContentBackground>
        </StyledBattleStarsContentContainer>
        <StyledBattleStarsHeroImage src={mobile ? data.mobileImg : data.heroImg} alt={''} />
      </StyledBattleStarsContainer>
    </VisibilitySensor>
  );
};

export default BattleStarsFeature;
