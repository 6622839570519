import PropTypes from 'prop-types';
import React, {useContext, useRef} from 'react';
import Slider from 'react-slick';

import FeaturedGridSliderControls from './FeaturedGridSliderControls';
import ScreenSizeContext from './ScreenSizeContext';

const FeaturedGridWithSlider = ({
  children,
  direction = 'row',
  mobileWidth = 767,
  mobileItemsMax = 2,
  desktopItemsMax = 8,
  gridColumns = 4,
}) => {
  const {innerWidth: size} = useContext(ScreenSizeContext);
  const ref = useRef();
  const mobileItemsMaxCalc =
    mobileItemsMax + 1 === children.length ? mobileItemsMax + 1 : mobileItemsMax;
  const isMobile = size && size < mobileWidth;
  const maxItems = !isMobile ? desktopItemsMax : mobileItemsMaxCalc;
  const swiperItems = React.useMemo(() => {
    if (!isMobile) {
      return [];
    }
    // Keep the number of items the same for both mobile and desktop by limiting max length to the desktop size.
    const items = children?.length > 0 ? children.slice(mobileItemsMaxCalc, desktopItemsMax) : [];
    return items.map((item, index) => <div key={`slide${index}`}>{item}</div>);
  }, [children, isMobile, mobileItemsMaxCalc, desktopItemsMax, size]);
  const gridItems = React.useMemo(
    () => (children && children.length > 0 ? children.slice(0, maxItems) : []),
    [children, maxItems]
  );

  const grid = React.useMemo(() => {
    if (!direction || direction === 'row' || size < mobileWidth) {
      return <div className="row">{gridItems}</div>;
    }
    const columns = [];
    const columnSize = desktopItemsMax / gridColumns;
    // Clone gridItems to prevent mutating.
    const items = (gridItems.asMutable ? gridItems.asMutable() : gridItems).slice(0);
    for (let column = 1; column <= gridColumns; column++) {
      const col = items.splice(0, columnSize);
      columns.push(
        <div className="row column-row" key={`row-column${column}`}>
          {col}
        </div>
      );
    }
    return columns;
  }, [direction, gridItems, desktopItemsMax, gridColumns, size, mobileWidth]);

  const gridClass = `grid container size${gridItems?.length}`;
  return (
    <div className="container FeaturedGridWithSlider">
      <div className={gridClass}>{grid}</div>
      {swiperItems?.length > 0 && (
        <div className="row">
          <div>
            <Slider
              ref={ref}
              infinite={false}
              arrows={false}
              slidesToShow={1.25}
              slidesToScroll={1}>
              {swiperItems}
            </Slider>
            <FeaturedGridSliderControls swiperRef={ref} />
          </div>
        </div>
      )}
    </div>
  );
};

FeaturedGridWithSlider.defaultProps = {
  children: [],
};

FeaturedGridWithSlider.propTypes = {
  direction: PropTypes.string,
  mobileWidth: PropTypes.number,
  mobileItemsMax: PropTypes.number,
  desktopItemsMax: PropTypes.number,
  gridColumns: PropTypes.number,
  children: PropTypes.arrayOf(PropTypes.object),
};

export default FeaturedGridWithSlider;
