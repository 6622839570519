import {Container, Grid, GridColumn, LoadScreen, Stack, Text} from 'epic-fortnite-react';
import React from 'react';
import styled from 'styled-components';

import {trackEvent} from '../../utils/eventTracking';
import CTAButton from '../landingpages/CTAButton';

// because you can't combine clip-path and drop-shadow
const HeaderContentShadow = styled.div`
  filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.25));
  width: 100%;
`;

const HeaderContent = styled.div`
  background-color: #fff;
  clip-path: polygon(0% 0%, 100% 0%, calc(100% - 12px) 100%, 0% calc(100% - 8px));
  text-align: center;
  width: 100%;

  @media (max-width: 899px) {
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% calc(100% - 8px));
    padding: 32px;
  }

  @media (min-width: 900px) {
    margin-right: 32px;
    margin-left: -32px;
    padding: 48px;
  }
`;

const HeaderImage = styled.div`
  aspect-ratio: 16/9;
  position: relative;
  width: 100%;

  @media (min-width: 900px) {
    clip-path: polygon(0% 0%, 100% 2%, 100% 98%, 2% 100%);
    left: 24px;
    margin: 24px;
  }

  img {
    height: 100%;
    position: absolute;
    object-fit: cover;
    width: 100%;
  }
`;

const MobileLandingHero = ({data}) => {
  if (!data) {
    return <LoadScreen show={true} />;
  }
  return (
    <Container maxWidth={1600}>
      <Grid breakpoint={900}>
        <GridColumn span={3 / 5}>
          <HeaderImage>
            <img alt={data?.heroAltText ?? ''} src={data?.heroImage ?? ''} />
          </HeaderImage>
        </GridColumn>
        <GridColumn span={2 / 5}>
          <HeaderContentShadow>
            <HeaderContent>
              <Stack gap="lg">
                <Text as="h1" size="2xl">
                  {data?.textHeading}
                </Text>
                <Text as="p" font="OpenSans" size="med" weight="600">
                  {data.textContent}
                </Text>

                {data?.cta.ctaString && data?.cta.ctaLink && (
                  <a href={data?.cta.ctaLink}>
                    <CTAButton
                      onClick={() =>
                        trackEvent({
                          eventCategory: data?.cta?.eventTracking?.eventCategory,
                          eventAction: data?.cta?.eventTracking?.eventAction,
                          eventLabel: data?.cta?.eventTracking?.eventLabel,
                          eventValue: data?.cta?.eventTracking?.eventValue,
                          interactionType: data?.cta?.eventTracking?.interactionType,
                        })
                      }
                      style={{
                        color: '#000',
                        backgroundColor: '#ffff00',
                        textTransform: 'uppercase',
                        width: '175px',
                        height: '50px',
                        fontSize: '1.1rem',
                      }}>
                      <span>{data?.cta.ctaString}</span>
                    </CTAButton>
                  </a>
                )}
              </Stack>
            </HeaderContent>
          </HeaderContentShadow>
        </GridColumn>
      </Grid>
    </Container>
  );
};

export default MobileLandingHero;
