import styled from 'styled-components';

const CreativeLinkIcon = styled.span`
  & {
    font-family: 'icomoon';
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    margin-left: 0.2em;
    font-size: 0.8em;
  }
  &:before {
    content: '\\e90f';
  }
`;

export default CreativeLinkIcon;
