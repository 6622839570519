import PropTypes from 'prop-types';
import React from 'react';
import {Redirect} from 'react-router';

import CreativeHomeSection from '../creativeMode/CreativeHomeSection';
import CreativeFaqSection from '../creativeMode/faq/CreativeFaqSection';

import SimpleTabListSection from './SimpleTabListSection';

class SimpleTabSection extends React.Component {
  static propTypes = {
    store: PropTypes.object,
    mobile: PropTypes.bool,
    tablet: PropTypes.bool,
    activeTab: PropTypes.number,
    theme: PropTypes.object,
  };

  _handleComponentRender = () => {
    const {store, mobile, tablet, activeTab, theme} = this.props;
    const {blog = {}, filteredTabs, loginStore} = store;
    const {contentRef, component, redirectTo} = filteredTabs[activeTab]
      ? filteredTabs[activeTab]
      : {contentRef: '', component: ''};
    const content = store && !!contentRef ? store[contentRef] : null;

    switch (component) {
      case 'Redirect':
        if (/^\//.test(redirectTo)) {
          return <Redirect to={redirectTo} />;
        }
        return (
          <CreativeHomeSection
            data={blog}
            mobile={mobile}
            tablet={tablet}
            theme={theme}
            loginStore={loginStore}
            {...store}
          />
        );
      case 'HomeSection':
        return (
          <CreativeHomeSection
            data={blog}
            mobile={mobile}
            tablet={tablet}
            theme={theme}
            loginStore={loginStore}
            {...store}
          />
        );
      case 'FaqSection':
        return (
          <CreativeFaqSection
            mobile={mobile}
            tablet={tablet}
            theme={theme}
            loginStore={loginStore}
            {...store}
          />
        );
      case 'SimpleTabListSection':
        return <SimpleTabListSection items={content.items} theme={theme} />;
      default:
        return <div />;
    }
  };

  render() {
    return (
      <div className="simple-tab-wrapper">
        <div className="simple-tab-content-section">{this._handleComponentRender()}</div>
      </div>
    );
  }
}

export default SimpleTabSection;
