import React from 'react';

import {DifficultyIcon, DifficultyLabel, DifficultyWrapper} from './';

interface Props {
  image?: string;
  text?: string;
}

const DifficultyIndicator = ({image, text}: Props) => {
  return (
    <DifficultyWrapper>
      <DifficultyIcon src={image} alt="" />
      <DifficultyLabel>{text}</DifficultyLabel>
    </DifficultyWrapper>
  );
};

export default DifficultyIndicator;
