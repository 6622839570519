import React from 'react';

interface Props {
  styles?: React.CSSProperties;
}

const CloseIcon = ({styles}: Props) => (
  <svg style={styles} viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
    <path
      fill="#000"
      d="M2.6 27.4c4.5-3.7 7.8-6.5 10-8.3l-7-7.9C9.1 8.4 12 5.8 14 3.9l6.9 8.4 9.7-8.6 7.2 8.7c-4.9 4-8.1 6.6-9.7 8.1l7.2 8.3-8.4 6.9-6.9-8.3-9.6 8.4-7.8-8.4z"
    />
  </svg>
);

export default CloseIcon;
