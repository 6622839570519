import {Container, Grid, GridColumn, Stack} from 'epic-fortnite-react';
import React from 'react';

import {RiftTourColumnImage} from './components/RiftTourColumnImage';
import {RiftTourCTA} from './components/RiftTourCTA';
import {RiftTourHeading} from './components/RiftTourHeading';
import {RiftTourSection} from './components/RiftTourSection';
import {RiftTourSubheading} from './components/RiftTourSubheading';
import {RiftTourBackground} from './types';

interface Props {
  data: CMSData;
}

interface CMSData {
  background: RiftTourBackground;
  heroImage: string;
  subtitle: string;
  textHeading: string;
}

const RiftTourItems = ({data}: Props) => {
  const {background, heroImage, subtitle, textHeading} = data;
  const {backgroundImage, gradientPosition} = background;

  return (
    <RiftTourSection
      backgroundImage={backgroundImage}
      clipPathDirection={background?.overlayDirection}
      clipPathSpike={background?.hasSpike}
      gradientPosition={gradientPosition}>
      <Container maxWidth={1200} size="med">
        <Grid breakpoint={900} gapX="2xl" gapY="6xl" reverseOrder={true}>
          <GridColumn align="center" justify="center" span={1}>
            <Stack align="center" gap="lg" justify="center">
              <RiftTourHeading color="#fff">{textHeading}</RiftTourHeading>
              <RiftTourSubheading color="#fff">{subtitle}</RiftTourSubheading>
              <RiftTourCTA label="Visit the Store In-Game" textLink={true} />
            </Stack>
          </GridColumn>
          <GridColumn align="center" justify="center" span={1}>
            <RiftTourColumnImage
              alt=""
              src={heroImage}
              style={{
                marginBottom: 'calc(var(--section__padding) * -1)',
                marginTop: 'calc(var(--section__padding) * -1.25)',
                maxWidth: '500px',
              }}
            />
          </GridColumn>
        </Grid>
      </Container>
    </RiftTourSection>
  );
};

export default RiftTourItems;
