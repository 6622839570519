import {Container, Section, Stack, Text} from 'epic-fortnite-react';
import HtmlTemplate from 'epic-fortnite-react/components/common/HtmlTemplate';
import React from 'react';
import styled from 'styled-components';

import {RiftTourHeading} from './components/RiftTourHeading';

interface Props {
  data: CMSData;
}

interface CMSData {
  textHeading: string;
  faqItems: {
    faqs: FaqItem[];
  };
}

interface FaqItem {
  question: string;
  answer: string;
}

const StyledFAQSection = styled(Section)`
  color: #fff;
  margin: -16px;
  padding: 16px;
`;

const RiftTourFaq = ({data}: Props) => {
  const {textHeading, faqItems} = data;

  return (
    <Section backgroundColor="#000" size="4xl">
      <Container maxWidth={800} size="med">
        <Stack align="center" gap="2xl">
          <RiftTourHeading color="#fff">{textHeading}</RiftTourHeading>
          <StyledFAQSection textAlign="left">
            <Stack gap="2xl">
              {faqItems?.faqs?.length &&
                faqItems.faqs.map((item, index) => (
                  <Stack key={`rift-tour-faq-${index}`} gap="lg">
                    <Text color="#fff" size="lg" transform="uppercase">
                      {item.question}
                    </Text>
                    <HtmlTemplate html={item.answer ?? ''} />
                  </Stack>
                ))}
            </Stack>
          </StyledFAQSection>
        </Stack>
      </Container>
    </Section>
  );
};

export default RiftTourFaq;
