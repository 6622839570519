import React from 'react';

interface Props {
  styles?: React.CSSProperties;
}

const PlayIcon = ({styles}: Props) => (
  <svg style={styles} viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
    <circle cx="20.04" cy="20.02" r="18.38" fill="#010101" fillOpacity=".3" />
    <path
      fill="#FFF"
      fillRule="evenodd"
      d="M16.34 12.15l11.57 7.87-11.57 8.36V12.15z"
      clipRule="evenodd"
    />
    <path
      fill="#FFF"
      d="M20.04 38.85c-10.38 0-18.83-8.45-18.83-18.83S9.66 1.19 20.04 1.19s18.83 8.45 18.83 18.83-8.45 18.83-18.83 18.83zm0-36.76c-9.89 0-17.93 8.04-17.93 17.93s8.04 17.93 17.93
         17.93 17.93-8.04 17.93-17.93S29.93 2.09 20.04 2.09z"
    />
  </svg>
);

export default PlayIcon;
