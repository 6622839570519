import {withLocalization} from '@epic-core/localization';
import {generateRouteTo} from 'epic-fortnite-react';
import Proptypes from 'prop-types';
import React from 'react';
import {Link, useLocation} from 'react-router-dom';

import PartyRoyaleCalendarPicker from './PartyRoyaleCalendarPicker';
import ScheduleItemDate from './ScheduleItemDate';
import {icon, useScheduleEvent} from './ScheduleItemDate.hooks';

const ScheduleItem = ({data, columnCount, getMessage, locale}) => {
  const location = useLocation();

  const {event, items} = useScheduleEvent(data, getMessage);

  if (!data) return null;

  const {name, column2, moreInfo, moreInfoUrl} = data;
  const columns = [name, <ScheduleItemDate key="date" data={data} />];

  if (column2) {
    columns.push(column2);
  } else {
    columns.push('');
  }

  if (moreInfoUrl) {
    const moreInfoText = moreInfo || getMessage('epic.common.learnMore');

    if (/^\//.test(moreInfoUrl)) {
      columns.push(<Link to={generateRouteTo(moreInfoUrl, location, locale)}>{moreInfoText}</Link>);
    } else if (/^http/.test(moreInfoUrl)) {
      columns.push(<a href={moreInfoUrl}>{moreInfoText}</a>);
    } else if (!moreInfoUrl && moreInfo) {
      // Localized in CMS.
      columns.push(moreInfo);
    }
  } else {
    columns.push('');
  }

  const colSize = 12 / columns.length;
  const columnSizes = Number(columnCount) === 4 ? [3, 5, 2, 2] : [colSize, colSize, colSize];

  return (
    <div className="row ScheduleItem">
      <div className="mobile-date">
        <PartyRoyaleCalendarPicker
          buttonTemplate={icon}
          event={event}
          buttonLabel=""
          listItems={items}
        />
      </div>
      {columns.map((column, index) => {
        const colClass = `col-xs-12 col-sm-${columnSizes[index]}`;
        return (
          <div key={`cs${index}`} className={colClass}>
            {column}
          </div>
        );
      })}
    </div>
  );
};

ScheduleItem.propTypes = {
  data: Proptypes.shape({
    name: Proptypes.string,
    moreInfo: Proptypes.string,
    moreInfoUrl: Proptypes.string,
    column2: Proptypes.string,
    date: Proptypes.oneOfType([Proptypes.instanceOf(Date), Proptypes.string]),
    maxLocalDate: Proptypes.oneOfType([Proptypes.instanceOf(Date), Proptypes.string]),
    minLocalDate: Proptypes.oneOfType([Proptypes.instanceOf(Date), Proptypes.string]),
  }),
  columnCount: Proptypes.string,
  getMessage: Proptypes.func,
};

export default withLocalization(ScheduleItem);
