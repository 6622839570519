import {datasource} from 'alt-utils/lib/decorators';
import {seamlessBootstrap, seamlessImmutable} from 'epic-alt-utils';
import {getLocale} from '@epic-core/common';
import Immutable from 'seamless-immutable';
import root from 'window-or-global';

import LoginActions from '../actions/LoginActions';
import alt from '../alt';
import LoginSource from '../sources/LoginSource';
import {generateRouteHref} from '../utils/route';

@datasource(LoginSource)
@seamlessImmutable
class LoginStore {
  constructor() {
    this.on('error', (error) => {
      console.error(error);
    });
    this.on(
      'bootstrap',
      seamlessBootstrap.bind(this, (state) => {
        if (!root.__disable_epic_SSO) {
          try {
            const {isLoggedIn} = this.getInstance().state;
            if (isLoggedIn !== undefined) {
              root.localStorage.login = isLoggedIn;
            }
            if (typeof root.addEventListener === 'function') {
              root.addEventListener('storage', LoginActions.loginListener);
            }
          } catch (e) {
            console.log('Failed to connect to local storage');
          }
        }
        return state;
      })
    );
    this.state = Immutable({
      isLoggedIn: false,
      isFounder: false,
      isRegistered: false,
      isEntitledMobile: false,
      isEntitledAndroid: false,
      loginModal: false,
      loginModalReturn: null,
      errorModal: false,
      downloadModal: false,
      accountInfo: null,
      loaded: false,
    });
    this.bindActions(LoginActions);
  }

  handleLoginError(response) {
    if (response.status === '401') {
      this.setState(
        this.state.merge({
          isLoggedIn: false,
        })
      );
      root.localStorage.login = false;
    } else {
      console.log(response);
    }
  }

  handleLogout() {
    // set login to false if login was true
    this.setState(
      this.state.merge({
        isLoggedIn: false,
      })
    );
    root.localStorage.login = false;
  }

  loginListener(event) {
    // refresh current page if login Status has been changed
    if (event.key === 'login' && event.newValue !== event.oldValue) {
      let loggedInValue = event.newValue;
      // Fix for IE storing values as strings rather than bools
      if (typeof loggedInValue === 'string') {
        loggedInValue = loggedInValue === 'true';
      }
      // Fix for IE this is always fired immediately upon loading the page so only act on it
      // if the value is not what we expect
      if (this.state.isLoggedIn !== loggedInValue) {
        if (loggedInValue) {
          setImmediate(root.location.reload.bind(root.location));
        } else {
          this.setState(this.state.set('isLoggedIn', loggedInValue));
          setImmediate(() => {
            this.onRequestLogout(root.location.pathname);
          });
        }
      }
    }
  }

  onGetAccountInfo(options) {
    if (this.state.accountInfo && !(options && options.reload)) return false;
    return this.getInstance().getAccountInfo();
  }

  onGetAccountInfoSuccess({data}) {
    this.setState(
      this.state.merge({
        accountInfo: data.accountInfo,
        isLoggedIn: data.isLoggedIn,
        isFounder: data.isFounder,
        loaded: true,
      })
    );
  }

  onGetAccountInfoFailure(error) {
    console.error('Failed to get account info');
  }

  onRequestLogout(returnPath) {
    const locale = getLocale();
    const [pathname, search] = (returnPath ?? root.location.pathname).split('?');
    const redirectUrlParams = new URLSearchParams(search || '');
    const redirectTo = generateRouteHref(
      {
        pathname,
        search: redirectUrlParams.toString(),
      },
      root.location,
      locale
    );

    const logoutUrl = `${root.appContext || ''}/logout?${new URLSearchParams({
      lang: locale,
      redirectTo,
    })}`;
    root.location.href = logoutUrl;
  }

  onRequestLogin(returnPath) {
    const locale = getLocale();
    const [pathname, search] = (returnPath ?? root.location.pathname).split('?');
    const redirectUrlParams = new URLSearchParams(search || '');
    const redirectTo = generateRouteHref(
      {
        pathname,
        search: redirectUrlParams.toString(),
      },
      root.location,
      locale
    );

    const loginUrl = `${root.appContext || ''}/login?${new URLSearchParams({
      lang: locale,
      redirectTo,
    })}`;
    root.location.href = loginUrl;
  }

  /** @deprecated */
  onRequestRegister(options) {
    this.onRequestLogin(options);
  }

  onRequestPreRegister() {
    this.getInstance().preRegister();
  }

  onRequestLoginModal(options) {
    this.setState(
      this.state.merge({
        loginModal: true,
        downloadModal: false,
        loginModalReturn: options && options.redirectUrl,
        answerNoContent: options && options.answerNoContent,
      })
    );
  }

  onRequestDownloadModal(options) {
    this.setState(
      this.state.merge({
        loginModal: true,
        downloadModal: true,
        loginModalReturn: options && options.redirectUrl,
      })
    );
  }

  onErrorLoginModal() {
    this.setState(
      this.state.merge({
        errorModal: true,
      })
    );
  }

  onCloseLoginModal() {
    this.setState(
      this.state.merge({
        loginModal: false,
        errorModal: false,
        downloadModal: true,
        loginModalReturn: null,
      })
    );
  }
}

export default alt.createStore(LoginStore, 'LoginStore');
