import styled from 'styled-components';

import {breakpoints} from '../../../constants/Breakpoints';

const HowToSubheader = styled.div`
    &&& {
        font-size: 3.75rem;
    }

    @media (max-width: 550px) {
        max-width: 44rem;
    }

    @media ${breakpoints.mobileL} {
        &&& {
            font-size: 2.4rem;
        }
    }}
`;

export default HowToSubheader;
