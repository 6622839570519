import React from 'react';
import {CoreSectionProps} from '../bundles/types';
import styled from 'styled-components';
import {AnimateIn, Container, Section, Flex, FlexItem, Text, Stack} from 'epic-fortnite-react';

interface TwoColumnHeroProps {
  coreSectionProps?: CoreSectionProps;
  featureImage: string;
  logo: string;
  subtitle?: string;
  cta?: {
    // CTA isn't currently used for this view.
    ctaLink: string;
  };
}

const StyledImage = styled.img`
  width: 100%;
`;

const StyledSection = styled(Section)`
  min-height: 500px;
`;

const TwoColumnHero = (props: TwoColumnHeroProps) => {
  const {coreSectionProps, featureImage, logo, subtitle} = props;

  return (
    <StyledSection
      backgroundColor={
        coreSectionProps?.backgroundColor ? coreSectionProps.backgroundColor : '#fff'
      }
      size="sm"
      backgroundImage={coreSectionProps?.backgroundImage}
      backgroundPosition={coreSectionProps?.backgroundPosition}
      backgroundSize={coreSectionProps?.backgroundSize}
      mobileBackgroundImage={coreSectionProps?.mobileBackgroundImage}
      mobileBackgroundPosition={coreSectionProps?.mobileBackgroundPosition}>
      <Container maxWidth={1400} size="2xl">
        <Flex gap="med" breakpoint={900} justify="center">
          <FlexItem flex={true}>
            <Stack gap="med">
              {logo && <StyledImage src={logo} alt="" />}
              {subtitle && (
                <Text as="h2" align="center" color="white" size="lg">
                  {subtitle}
                </Text>
              )}
            </Stack>
          </FlexItem>
          <FlexItem flex={true} justify="center">
            {featureImage && (
              <AnimateIn>
                <StyledImage src={featureImage} alt="" />
              </AnimateIn>
            )}
          </FlexItem>
        </Flex>
      </Container>
    </StyledSection>
  );
};

export default TwoColumnHero;
