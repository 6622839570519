import styled from 'styled-components';

const ScrollContainer = styled.div`
  position: relative;
  width: 925px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 940px) {
    width: 760px;
    margin-bottom: -46px;
  }

  @media screen and (max-width: 740px) {
    overflow-x: scroll;
    touch-action: pan-x;
    -webkit-overflow-scrolling: touch;
  }
`;

export default ScrollContainer;
