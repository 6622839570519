import styled from 'styled-components';

import {breakpoints} from '../../../constants/Breakpoints';

interface Props {
  desktopOnly?: boolean;
}

const SpikeLayer = styled.div<Props>`
  margin-top: -7rem;
  clip-path: polygon(0 5%, 28% 2%, 24% 5%, 100% 0, 100% 100%, 0 100%);

  ${({desktopOnly = false}) =>
    desktopOnly === true
      ? `
    @media ${breakpoints.tablet} {
        margin-top: 0;
        clip-path: unset;
    }
    `
      : `
    @media ${breakpoints.tablet} {
        margin-top: -5rem;
    }

    @media ${breakpoints.mobileL} {
        margin-top: -8.5rem;
        clip-path: polygon(0 2%, 28% 1%, 22% 3%, 100% 0, 100% 100%, 0 100%);
    }
    `}
`;

export default SpikeLayer;
