import {datasource} from 'alt-utils/lib/decorators';
import {seamlessImmutable} from 'epic-alt-utils';
import alt from 'epic-fortnite-react/alt';
import Immutable from 'seamless-immutable';

import PlayerActions from '../actions/PlayerActions';
import PlayerSource from '../sources/PlayerSource';

/**
 * PlayerStore holds the players data.
 *
 * The ES7 @datasource decorator binds the PlayerSource to this store.
 * The PlayerSource methods become available statically on this class. i.e. this.getInstance().getPlayers()
 *
 * The @immutable decorator binds some utility methods to the store to make it easy store
 * state data as immutable objects.
 *
 */
@datasource(PlayerSource)
@seamlessImmutable
class PlayerStore {
  getStateFunctions() {
    return {
      isNorthAmerica() {
        return this.countryCode === 'US' || this.countryCode === 'CA';
      },
    };
  }

  constructor() {
    this.on('error', (error) => {
      console.error(error);
    });
    const initialState = Immutable({
      userName: null,
      countryCode: null,
    });
    this.state = initialState.merge(this.getStateFunctions());

    //scaffolds methods for all the actions
    this.bindActions(PlayerActions);
  }

  onGetUserName() {
    this.getInstance().getUserName();
  }

  onGetUserNameSuccess(response) {
    this.setState(this.state.set('userName', response.data.username));
  }

  onGetUserNameFailure(error) {
    this.setState(this.state.set('userName', null));
  }
}

export default alt.createStore(PlayerStore, 'PlayerStore');
