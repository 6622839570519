import {seamlessImmutable} from 'epic-alt-utils';
import alt from 'epic-fortnite-react/alt';
import LoginStore from 'epic-fortnite-react/stores/LoginStore';
import LauncherIntegrationUtils from 'epic-launcher-integration/app/scripts/utils/LauncherIntegrationUtils';
import Immutable from 'seamless-immutable';
import root from 'window-or-global';

import PortalActions from '../actions/PortalActions';
import {onPurchaseSuccess} from '../utils/PurchaseUtils';

@seamlessImmutable
class PortalStore {
  getStateFunctions() {
    return {
      isLauncherClient() {
        return LauncherIntegrationUtils.isLauncherClient();
      },
    };
  }

  constructor() {
    this.on('error', (error) => {
      console.error(error);
    });
    this.state = Immutable({}).merge(this.getStateFunctions());
    this.bindActions(PortalActions);
  }

  onRequestSignIn() {
    LauncherIntegrationUtils.requestSignIn();
  }

  onInitWebIntegration(integrationOptions) {
    if (root.__server_side_render) return;
    // Skip purchase in progress if user is already a founder.
    const {isFounder, isLoggedIn} = LoginStore.state;
    if (isLoggedIn && !this.state.isLauncherClient() && !isFounder) {
      if (integrationOptions) {
        LauncherIntegrationUtils.initWebIntegration(
          integrationOptions.initConfig,
          integrationOptions.initOffer,
          integrationOptions.success || onPurchaseSuccess
        );
      } else {
        LauncherIntegrationUtils.initWebIntegration();
      }
    }
  }

  onPauseEntitlementRefresh() {
    if (this.state.isLauncherClient()) {
      LauncherIntegrationUtils.pauseEntitlementRefresh();
    }
  }

  onResumeEntitlementRefresh() {
    if (this.state.isLauncherClient()) {
      LauncherIntegrationUtils.resumeEntitlementRefresh();
    }
  }
}

export default alt.createStore(PortalStore, 'PortalStore');
