import styled from 'styled-components';

import {Device} from '../../../constants';

const StyledLogo = styled.img`
  && {
    height: 4rem;
    width: auto;
    margin-bottom: -2rem;

    @media screen and ${Device.mobileL} {
      display: none;
    }
  }
`;

export default StyledLogo;
