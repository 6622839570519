import styled from 'styled-components';

import {breakpoints} from '../../../../constants/Breakpoints';

const CarouselArrow = styled.div`
  @media ${breakpoints.tablet} {
    display: none;
  }
`;

export default CarouselArrow;
