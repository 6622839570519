import {motion} from 'framer-motion';
import React from 'react';
import styled from 'styled-components';

import {Device} from '../../../constants';

const HeroImage = styled(({...rest}) => <motion.img {...rest} />)`
  position: absolute;
  height: 100%;
  width: auto;
  z-index: 1;
  pointer-events: none;
  opacity: ${(props) => (props.active ? 1 : 0)};
  transition: opacity 500ms ease-in-out;
  @media screen and (max-width: 940px) {
    height: 70%;
    align-self: flex-end;
  }
  @media screen and ${Device.tablet} {
    height: 89%;
  }
`;

export default HeroImage;
