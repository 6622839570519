import {getLocale} from '@epic-core/common';
import {datasource} from 'alt-utils/lib/decorators';
import {seamlessBootstrap, seamlessImmutable} from 'epic-alt-utils';
import alt from 'epic-fortnite-react/alt';
import Immutable from 'seamless-immutable';

import CosplayActions from '../actions/CosplayActions';
import CosplaySource from '../sources/CosplaySource';

@datasource(CosplaySource)
@seamlessImmutable
class CosplayStore {
  constructor() {
    this.on('error', console.error);
    this.on('bootstrap', seamlessBootstrap.bind(this, null));
    this.state = Immutable({
      loading: true,
      data: null,
      error: false,
    });
    this.bindActions(CosplayActions);
  }

  onGetOverview() {
    if (this.state.data) return;
    this.getInstance().getOverview(getLocale());
    this.setState(
      this.state.merge({
        loading: true,
        data: null,
        error: false,
      })
    );
  }

  onGetOverviewSuccess(response) {
    if (response.data.error) {
      return this.setState(
        this.state.merge({
          loading: false,
          data: null,
          error: response.data.error,
        })
      );
    }
    this.setState(
      this.state.merge({
        loading: false,
        error: false,
        data: response.data,
      })
    );
  }

  onGetOverviewFailure(error) {
    this.setState(
      this.state.merge({
        loading: false,
        data: null,
        error: true,
      })
    );
  }
}

export default alt.createStore(CosplayStore, 'CosplayStore');
