import styled from 'styled-components';

import {Device} from '../../../constants';

const StyledTextWrapper = styled.div`
  filter: drop-shadow(0px 0px 44px rgba(255, 3, 238, 0.8));
  height: 100%;
  padding: 3rem;
  padding-bottom: 0;
  width: 100%;

  @media screen and ${Device.mobileL} {
    margin-top: -3rem;
  }
`;

export default StyledTextWrapper;
