import React from 'react';
import styled from 'styled-components';

type StyledHrProps = {
  $color?: string;
  $size?: number;
};

type HrProps = {
  color?: string;
  size?: number;
};

const StyledHr = styled.hr<StyledHrProps>`
  background-color: ${({$color}) => ($color ? `${$color}` : '#000')} !important;
  border: 0 !important;
  height: ${({$size}) => ($size ? `${$size}px` : '1px')} !important;
  margin: 0 !important;
  padding: 0 !important;
  width: 100%;
`;

export const Hr = ({color, size}: HrProps) => {
  return <StyledHr $color={color} $size={size} />;
};
