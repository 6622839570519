import styled from 'styled-components';

import {Device} from '../../constants';

interface Props {
  $color?: string;
}

export default styled.p<Props>`
  &&&& {
    color: ${({$color}) => $color || '#ffff00'};
  }
  font-family: BurbankBigRegular-Black, sans-serif;
  text-transform: uppercase;
  text-align: left;
  font-size: 20px;
  font-style: italic;
  font-weight: 900;
  line-height: 1.1;
  width: 50%;
  @media screen and ${Device.laptopL} {
    width: unset;
  }
  @media screen and ${Device.laptop} {
    width: 100%;
    text-align: center;
  }
`;
