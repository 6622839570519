import PropTypes from 'prop-types';
import React from 'react';
import root from 'window-or-global';

export default class PatchNotesSocialShare extends React.Component {
  static propTypes = {
    content: PropTypes.object,
  };

  share = (e) => {
    let shareUrl = root.location.href;
    switch (e.currentTarget.id) {
      case 'twitter':
        shareUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(shareUrl)}`;
        break;
      case 'facebook':
        shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareUrl)}`;
        break;
      case 'vk':
        shareUrl = `http://vk.com/share.php?url=${encodeURIComponent(shareUrl)}`;
        break;
      case 'google':
        shareUrl = `https://plus.google.com/share?url=${encodeURIComponent(shareUrl)}`;
        break;
      case 'reddit':
        shareUrl = `https://www.reddit.com/submit?url=${encodeURIComponent(shareUrl)}`;
        break;
      default:
        break;
    }
    root.open(shareUrl, '_blank', 'toolbar=no, resizeable=yes, width=600, height=450');
  };

  render() {
    const {content} = this.props;
    if (!content.shareIcons) return null;
    const buttons = [];
    for (let i = 0; i < content.shareIcons.length; i++) {
      const social = content.shareIcons[i];
      const key = `social-${social}`;
      buttons.push(
        <li key={key} className={social}>
          <i role="presentation" id={social} onClick={this.share} className={`icon-${social}`} />
        </li>
      );
    }
    return (
      <div className="patch-notes-social-share">
        <ul className="share-container">{buttons}</ul>
      </div>
    );
  }
}
