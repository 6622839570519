import {Container, Inline, Section, Stack, Text, useBreakpoints} from 'epic-fortnite-react';
import {FontSizes} from 'epic-fortnite-react/types/theme';
import epicTracking from 'epic-tracking';
import React from 'react';
import styled from 'styled-components';
import root from 'window-or-global';

import {CmsComponentMapper} from '../../components/common/CmsComponentMapper';
import CTAButton from '../../components/common/CTAButton';

const FlexibleHeaderWrapper = styled(Section)`
  position: relative;
  color: #fff;
  height: 80vh;

  @media (min-width: 900px) {
    aspect-ratio: 2/1;
    height: auto;
  }
`;

const SimpleImage = styled.img<{size?: string; position?: string}>`
  width: ${({size}) => size ?? '100%'};
`;

type OverviewBadgeProps = {
  image?: string;
  size?: string;
  position?: string;
};

const OverviewBadge = ({image, size, position}: OverviewBadgeProps) => {
  return <SimpleImage src={image} size={'100px'} position={position} />;
};

type OverviewLogoProps = {
  image?: string;
  logoAltText?: string;
  size?: string;
  position?: string;
  mobileImage?: string;
};

const OverviewLogo = ({image, logoAltText, size, position, mobileImage}: OverviewLogoProps) => {
  const imageSrc: string = useBreakpoints(mobileImage ? mobileImage : image, {900: image}).value;

  const StyledOverviewImage = styled(SimpleImage)`
    display: block;
    margin: auto;
    max-height: 6rem;
    width: auto;
  `;

  return (
    <StyledOverviewImage
      alt={logoAltText ? logoAltText : ''}
      src={imageSrc}
      size={size}
      position={position}
    />
  );
};

type OverviewTextProps = {
  header?: string;
  textContent?: string;
};

const trackCtaClick = (item, utmSource) => {
  if (item.eventTracking && root._epicTracking) {
    const {
      interactionType = '',
      eventAction = '',
      eventLabel = '',
      eventValue = '',
      eventCategory = '',
    } = item.eventTracking;

    const utmEventValue = `${eventValue}&${utmSource}`;

    epicTracking.trackEvent('Interaction', {
      interactionType,
      eventAction,
      eventLabel,
      eventValue: utmEventValue,
      eventCategory,
    });
  }
};

const CTAButtons = (data) => {
  const arr = [];

  Object.keys(data).map((item) => {
    const int = parseInt(item);
    if (typeof int === 'number' && !Number.isNaN(int)) {
      arr.push(data[item]);
    }
  });

  return (
    <Inline justify="center" gap="med">
      {arr.map((item, index) => {
        const utmLink = `${item?.ctaLink}&${data.utmSource}`;

        return (
          <CTAButton
            ctaConfig={{
              isCtaLinkExternal: item?.isCtaLinkExternal,
              ctaString: item?.ctaString,
              ctaLink: utmLink,
              disabled: item?.disabled,
              onExternalClickHandler: () => {
                trackCtaClick(item, data.utmSource);
              },
            }}
            key={index}
            theme="yellow"
          />
        );
      })}
    </Inline>
  );
};

const OverviewText = ({header, textContent}: OverviewTextProps) => {
  const textSize: FontSizes = useBreakpoints('lg', {1280: '2xl'}).value;

  return (
    <>
      {(header || textContent) && (
        <Stack align="center" gap="2xl">
          {header && (
            <Text
              align="center"
              as="h1"
              color="var(--bundle-text-color--theme)"
              lineLength="sm"
              size={textSize}
              transform="uppercase">
              {header}
            </Text>
          )}
          {textContent && (
            <Text
              as="p"
              align="center"
              color="var(--bundle-text-color--theme)"
              size="xs"
              font="OpenSans">
              {textContent}
            </Text>
          )}
        </Stack>
      )}
    </>
  );
};

const CloudStreamingHeader = ({data = {}, utmSource = ''}) => {
  const {blocks, coreSectionProps} = data;
  const backgroundImage: string = useBreakpoints(
    coreSectionProps.mobileBackgroundImage
      ? coreSectionProps.mobileBackgroundImage
      : coreSectionProps.backgroundImage,
    {900: coreSectionProps.backgroundImage}
  ).value;

  return (
    <FlexibleHeaderWrapper
      backgroundColor={coreSectionProps?.backgroundColor}
      backgroundImage={backgroundImage}
      backgroundPosition="top"
      backgroundSize={coreSectionProps?.backgroundSize}
      size={coreSectionProps?.size}>
      <div style={{paddingBottom: '2rem', height: '100%', display: 'flex', alignItems: 'flex-end'}}>
        <Container size="med" maxWidth={800}>
          <div style={{display: 'flex', flexDirection: 'column', gap: '2rem'}}>
            <CmsComponentMapper
              dynamicPageDataArray={blocks}
              elementMapping={{
                logo: <OverviewLogo />,
                badge: <OverviewBadge />,
                textContent: <OverviewText />,
                ctaGroup: <CTAButtons utmSource={utmSource} />,
              }}
            />
          </div>
        </Container>
      </div>
    </FlexibleHeaderWrapper>
  );
};

export default CloudStreamingHeader;
