import styled from 'styled-components';

const SocialWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 2rem 0;
`;

export default SocialWrapper;
