import {BackgroundVideo} from 'epic-react-common';
import PropTypes from 'prop-types';
import React, {useState} from 'react';

import VideoPlayButton from '../common/VideoPlayButton';

const EmoteVideo = ({video, visible, useBackground, useClick, autoPlay}) => {
  const [isPlaying, setPlay] = useState(false);
  let background = {
    _type: 'htmlVideo',
    type: 'htmlVideo',
    content: [video.mp4Video, video.webMVideo],
  };
  if (useBackground) {
    background = video;
  }

  React.useEffect(() => {
    if (visible && !isPlaying && autoPlay && !useClick) setPlay(true);
    if (!visible && isPlaying) setPlay(false);
  }, [visible, autoPlay, setPlay, isPlaying, useClick]);

  const _toggleIsPlaying = React.useCallback(() => {
    setPlay((prev) => !prev);
  }, [setPlay]);

  return (
    <div className="col-xs-12 col-sm-6 mobile-no-padding">
      <div
        role="presentation"
        onMouseEnter={autoPlay ? null : () => _toggleIsPlaying()}
        onMouseLeave={autoPlay ? null : () => _toggleIsPlaying()}
        onTouchEnd={useClick ? () => _toggleIsPlaying() : null}
        className="battlepass-emote-video">
        {isPlaying && visible ? (
          <BackgroundVideo
            background={background}
            play={isPlaying}
            playPauseOverlay={true}
            noOverlay={true}
            mute
            loop={false}
            playsInline
            forceVideoMobile
            mobile={false}
            tablet={false}
          />
        ) : null}
        <div
          className={`video-still ${isPlaying ? 'fade' : ''}`}
          style={{backgroundImage: `url(${background.tabletImage})`}}>
          <VideoPlayButton className="bp-videoplay" />
        </div>
      </div>
    </div>
  );
};

EmoteVideo.propTypes = {
  video: PropTypes.object,
  visible: PropTypes.bool,
  useBackground: PropTypes.bool,
  useClick: PropTypes.bool,
  autoPlay: PropTypes.bool,
};

export default EmoteVideo;
