import styled from 'styled-components';

const DifficultyDescription = styled.h2`
  &&& {
    width: 80%;
    line-height: 1;
  }
`;

export default DifficultyDescription;
