import React from 'react';
import { withRouter} from 'react-router'
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import {generateRoutePath} from '@epic-core/common';
import SearchActions from '../actions/SearchActions';
import BingResult from './BingResult';
import BingBranding from './BingBranding';
import Themes from '../constants/Themes';
import styled from 'styled-components';

const Results = styled.div`
    .bing-result-content {
        width: 80%
        ${(p) => p.theme.breakpoints.down('sm')} {
            width: 100%;
        }
    }
`;

const SearchBar = styled.div`
    background-color: ${p => p.theme.bingSearch.accentBlue};
    height: 45px;
    border-radius: 5px;
    padding-left: 2px;
    overflow: hidden;
    margin: 0;
    position: relative;

    input {
        display: block;
        border: none;
        padding: 0 0 0 1em;
        width: calc(100% - 108px);
        height: 41px;
        margin: 2px 0;
        outline: none;
        ${(p) => p.theme.breakpoints.down('xs')} {
            width: calc(100% - 70px);
        }
    }
    button {
        position: absolute;
        padding: 0;
        border: 0;
        height: 100%;
        width: 100px;
        right: 0;
        top: 0;
        font-size: 0.8em;
        font-weight: 500;
        text-align: center;
        transition: background 0.2s ease-in-out;
        outline: none;
        background: transparent;
        color: white;
        font-size: 1.1em;
        ${(p) => p.theme.breakpoints.down('xs')} {
            font-size: 0.6em;
            width: 60px;
        }
    }
`;

const Pagination = styled.div`
    clear: all;
    width: 100%;
    height: 100px;
    padding: 1em 0;
    button {
        border: 0;
        font-size: 3em;
        background: transparent;
        transition: 0.2s ease-in-out;
        outline: none;
        &:hover {
            transform: scale(1.1);
        }
        &:active, &:focus, &:visited {
            outline: none;
            text-decoration: none;
        }
    }
    .nextResults {
        float: right;
        span:after {
            content: '';
            position: relative;
            width: 22px;
            font-weight: 400;
            border-right: 3px solid black;
            border-bottom: 3px solid black;
            transform: rotate(-45deg);
            display: block;
            height: 22px;
        }
    }
    .prevResults {
        float: left;
        span:after {
            content: '';
            position: relative;
            width: 22px;
            font-weight: 400;
            border-left: 3px solid black;
            border-bottom: 3px solid black;
            transform: rotate(-315deg);
            display: block;
            height: 22px;
        }
    }
`;

const SearchFilters = styled.ul`
    margin: 1em 0 2em;
    text-align: left;
    padding: 0;
    list-style: none;
    li.search-filter-element {
        padding-left: 0;
        display: inline-block;
        border-bottom: none;
        font-family: Brutal_Bold,sans-serif;
        float: none;
        margin-right: 20px;
        color: black;
        transition: color 0.2s ease-in-out;
        text-decoration: none;
        a {
            color: ${p => p.theme.bingSearch.darkGray};
        }
        &.active a {
            color: ${p => p.theme.bingSearch.accentBlue};
            pointer-events: none;
        }
        &:hover a {
            color: ${p => p.theme.bingSearch.accentBlue};
            text-decoration: none;
        }
    }
`;

class BingResultsList extends React.Component {
    static propTypes = {
        history: PropTypes.func,
        searchStore: PropTypes.object,
        query: PropTypes.string,
        offset: PropTypes.number,
        filter: PropTypes.string,
        messages: PropTypes.object,
        theme: PropTypes.oneOf([
            Themes.DARK,
            Themes.LIGHT
        ]),
        searchFilters: PropTypes.array,
        enableCustomFilter: PropTypes.bool,
        privacyStatement: PropTypes.string
    };

    constructor(props) {
        super(props);
        this.state = {
            query: props.query || ''
        };
    }

    /**
    * Event handler for form field input
    */
    handleInput = (evt) => {
        this.setState({
            query: evt.target.value
        });
    };

    /**
    * Event handler for submitting form with Enter key
    */
    handleEnterSubmit = (evt) => {
        if (evt.keyCode === 13) {
            this.handleSubmit();
        }
    };

    handleSubmit = () => {
        this.props.history.push({
            path: generateRoutePath('/bing-search'),
            search: `?keyword=${this.state.query}&offset=0&filter=${encodeURIComponent(this.props.searchStore.filter)}`
        });
    };

    handleNextClick = () => {
        this.props.history.push({
            path: generateRoutePath('/bing-search'),
            search: `?keyword=${this.state.query}&offset=${this.props.searchStore.nextOffset}&filter=${encodeURIComponent(this.props.searchStore.filter)}`
        });
    };

    handlePrevClick = () => {
        this.props.history.push({
            path: generateRoutePath('/bing-search'),
            search: `?keyword=${this.state.query}&offset=${this.props.searchStore.prevOffset}&filter=${encodeURIComponent(this.props.searchStore.filter)}`
        });
    };

    componentDidMount() {
        const {offset, filter} = this.props;
        SearchActions.bingSearch({query: this.state.query, offset, filter});
    }

    componentWillReceiveProps(newProps) {
        if (this.props.query !== newProps.query || this.props.offset !== newProps.offset || this.props.filter !== newProps.filter) {
            const {query, offset, filter} = newProps;
            this.setState({
                query
            });
            SearchActions.bingSearch({query, offset, filter});
        }
    }

    generateCustomFilters(searchFilters, currentFilter = '') {
        let jsx = null;
        if (searchFilters.length) {
            const elements = searchFilters.map((filter) => {
                const {filterName} = filter;
                const linkObj = {
                    pathname: generateRoutePath('/bing-search'),
                    search: `?keyword=${this.state.query}&offset=0&filter=${encodeURIComponent(filterName)}`
                };
                const isActive = filterName === currentFilter;
                return (
                    <li className={`search-filter-element ${isActive ? 'active' : null}`} key={filterName}>
                        <Link to={linkObj}>
                            {filterName}
                        </Link>
                    </li>
                );
            });
            jsx = (<SearchFilters>{elements}</SearchFilters>);
        }
        return jsx;
    }

    render() {
        const {enableCustomFilter, searchFilters = {}, theme, messages = {}, privacyStatement} = this.props;
        const {bingResults, config, hasMoreResults, offset, loading, bingFirstRun, filter} = this.props.searchStore;
        let customFilters;
        if (enableCustomFilter || config.enableCustomFilter) {
            customFilters = this.generateCustomFilters(searchFilters || config.searchFilters || [], filter);
        }

        let nextPage = null;
        // show/hide next page button
        if (hasMoreResults) {
            nextPage = (
                <button className="nextResults" onClick={this.handleNextClick}>
                    <span />
                </button>
            );
        }
        let prevPage = null;
        // show/hide prev page button
        if (offset) {
            prevPage = (
                <button className="prevResults" onClick={this.handlePrevClick}>
                    <span />
                </button>
            );
        }
        const bingPagination = (
            <Pagination>
                {prevPage}
                {nextPage}
            </Pagination>
        );

        let bingResultContent;
        // loading animation
        const showLoading = loading || typeof loading === 'undefined';
        const showNoResults = bingResults && !bingResults.length && !bingFirstRun;
        const showResults = bingResults && bingResults.length;
        const showBingBranding = !loading && (showNoResults || showResults);
        if (showLoading) {
            bingResultContent = (
                <div className="bingLoader" />
            );
        // display bing search results
        } else if (bingResults) {
            // show "no results"
            if (showNoResults) {
                bingResultContent = <p className="noResults">{messages['epic.common.search.no_results']}</p>;
            // show results
            } else if (showResults) {
                const results = bingResults.map((result, index) => {
                    return (
                        <BingResult
                            title={result.name}
                            url={result.url}
                            displayUrl={result.displayUrl}
                            description={result.snippet}
                            key={result.url} />
                    );
                });
                bingResultContent = <div className={`bing-result-list ${showLoading ? 'loading' : ''}`}>{results}</div>;
            }
        }

        return (
            <Results>
                <SearchBar className="bingSearchBar" id="bingSearchBar">
                    <input
                        type="text"
                        value={this.state.query}
                        onChange={this.handleInput}
                        onKeyDown={this.handleEnterSubmit} />
                    <button onClick={this.handleSubmit}>{messages['epic.common.search']}</button>
                </SearchBar>
                {customFilters}
                <div className={`bing-result-content ${showLoading ? 'loading' : ''}`}>
                    {bingResultContent}
                    {showBingBranding &&
                        <BingBranding theme={theme} privacyStatement={privacyStatement} />}
                </div>
                {!loading && bingPagination}
            </Results>
        );
    }
}

export default withRouter(BingResultsList);
