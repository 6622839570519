import PropTypes from 'prop-types';
import React from 'react';

const VideoPlayButton = ({className, onClick, id}) => {
  return (
    <span id={id} className={`videoPlayButton ${className}`} onClick={onClick} role="presentation">
      <span />
    </span>
  );
};
VideoPlayButton.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  id: PropTypes.string,
};
export default VideoPlayButton;
