import styled from 'styled-components';

import {breakpoints} from '../../../../constants/Breakpoints';

interface Props {
  selected?: boolean;
  accent?: string;
}
const ThumbnailWrapper = styled.div<Props>`
  img {
    background: black;
    width: 75px;
  }

  margin: 0 2rem;
  border-radius: 100%;
  border-width: 2px;
  border-style: solid;
  border-color: ${({selected, accent}) => (selected ? accent : 'transparent')};
  overflow: hidden;

  @media ${breakpoints.mobileL} {
    margin: 0 1rem;
  }
`;

export default ThumbnailWrapper;
