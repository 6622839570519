import LoginActions from 'epic-fortnite-react/actions/LoginActions';
import StoreActions from 'epic-fortnite-react/actions/StoreActions';
import LauncherIntegrationUtils from 'epic-launcher-integration/app/scripts/utils/LauncherIntegrationUtils';
import root from 'window-or-global';

import PortalActions from '../actions/PortalActions';

export const purchaseUrl = '/play-now/battle-royale';

export const onPurchaseSuccess = function onPurchaseSuccess() {
  StoreActions.clearCaches();
  // Temp send welcome email for founders packs
  StoreActions.getFoundersPacks.defer();
  StoreActions.sendWelcome.defer();
  LoginActions.requestPreRegister.defer();
  LoginActions.getAccountInfo.defer({reload: true});
};

export const onPurchaseRejected = function onPurchaseRejected() {
  // Purchase rejected
};

export const onClickBuy = function onClickBuy(props) {
  const catalogItem = props.asset.item || props.asset;
  const {isLoggedIn, isLauncher} = props;
  const onSuccess = onPurchaseSuccess;
  if (!isLoggedIn) {
    if (isLauncher) {
      PortalActions.requestSignIn();
    } else {
      LoginActions.requestLogin(
        `${root.location.pathname}?offers=${catalogItem.id}&namespace=${catalogItem.namespace}&highlightColor=c57adb`
      );
    }
  } else if (catalogItem.canPurchase) {
    PortalActions.pauseEntitlementRefresh();
    LauncherIntegrationUtils.requestPurchase(
      catalogItem.namespace,
      catalogItem.id,
      1,
      onSuccess,
      onPurchaseRejected,
      null,
      'c57adb'
    );
  } // Else do nothing, the item is unavailable
};

export const buyAssetOrSignIn = function buyAssetOrSignIn(asset, isLoggedIn, isLauncher) {
  return onClickBuy({
    asset,
    isLoggedIn,
    isLauncher,
  });
};
