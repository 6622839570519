const addForwardSlash = (url, elementIndex) => {
    if (elementIndex === -1) {
        return url.slice(elementIndex) === '/' ? url : `${url}/`;
    } else if (elementIndex === 0) {
        return url.slice(elementIndex) === '/' ? url : `/${url}`;
    }
    // return home by default
    return '/';
};

const getRootPageSlug = (blogRootUrl) => {
    let modifiedUrl = blogRootUrl;

    if (blogRootUrl) {
        const notCharacters = /[^\w]/g;
        modifiedUrl = blogRootUrl.replace(notCharacters, '');
    }

    //allow enterprise to set rootPageUrl for post LinkNavigation but drop the string when fetching for posts
    if (modifiedUrl.match('enterprise')) {
        modifiedUrl = modifiedUrl.replace(/enterprise/g, '');
    }
    return modifiedUrl;
};

export {
    addForwardSlash,
    getRootPageSlug
};
