import root from 'window-or-global';

/* shouldCacheState determines whether a state (partial)
 * should be cached based on the environment (browser or SSR)
 * and the status of the state (data present or possible error state)
 */
export function shouldCacheState(state: {data: any}) {
  // In SSR store will be bootstrapped outside of component lifecycle events,
  // so no need to reload regardless of status
  if (root.__server_side_render === true) {
    return true;
  }

  // In browser cache when data is successfully loaded
  if (state?.data) {
    return true;
  }

  // If no data is present or a potential error state we want to reload the state
  return false;
}
