import React from 'react';
import {CoreSection} from './CoreSection';
import styled from 'styled-components';
import {Inline, Stack} from 'epic-fortnite-react';
import {CoreSectionProps} from '../bundles/types';
import {WatchVideo} from '../common/WatchVideo';
import CTAButton from '../common/CTAButton';

interface CenteredHeroProps {
  coreSectionProps: CoreSectionProps;
  heroImage: string;
  cta: {
    isCtaLinkExternal?: boolean;
    ctaString: string;
    ctaLink: string;
    disabled?: boolean;
    textOnly?: boolean;
    theme?: string;
    onExternalClickHandler?: any;
  };
  trailerConfig: {
    text: string;
    textColor?: string;
    videoId: string;
    isActive: boolean;
    isCtaLinkExternal: boolean;
  };
}

const StyledStackedContentContainer = styled.div`
  height: 80vh;
  padding-left: 1rem;
  padding-right: 1rem;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

const StyledImage = styled.img`
  max-width: 35rem;
  width: 80vw;
  filter: drop-shadow(8px 8px 10px rgba(0, 0, 0, 0.5));
`;

const CenteredHeroBlock = ({
  coreSectionProps,
  heroImage,
  cta,
  trailerConfig,
}: CenteredHeroProps) => {
  return (
    <CoreSection
      coreSectionProps={{
        backgroundImage: coreSectionProps?.backgroundImage,
        backgroundPosition: coreSectionProps?.backgroundPosition,
        mobileBackgroundImage: coreSectionProps?.mobileBackgroundImage,
        mobileBackgroundPosition: coreSectionProps?.mobileBackgroundPosition,
        size: coreSectionProps?.size,
      }}>
      <StyledStackedContentContainer>
        <Stack align="center" gap="lg">
          <StyledImage src={heroImage} alt="" />
          <Inline gapY="xs" gapX="med" justify="center">
            {cta.ctaString && cta.ctaLink && <CTAButton ctaConfig={cta} theme="brightYellow" />}
            {trailerConfig.videoId && trailerConfig.isActive && trailerConfig.isCtaLinkExternal ? (
              <CTAButton
                ctaConfig={{
                  isCtaLinkExternal: true,
                  ctaString: trailerConfig.text,
                  ctaLink: trailerConfig.videoId,
                  disabled: undefined,
                  textOnly: undefined,
                  onExternalClickHandler: undefined,
                }}
                theme="black"
              />
            ) : null}

            {trailerConfig.videoId && trailerConfig.isActive && !trailerConfig.isCtaLinkExternal ? (
              <WatchVideo
                isCtaLinkExternal={trailerConfig.isCtaLinkExternal}
                videoId={trailerConfig.videoId}
                text={trailerConfig.text}
                textColor={trailerConfig.textColor || coreSectionProps.defaultTextColor}
              />
            ) : null}
          </Inline>
        </Stack>
      </StyledStackedContentContainer>
    </CoreSection>
  );
};

export default CenteredHeroBlock;
