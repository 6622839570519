import React from 'react';

import DivAbsoluteBottom from './DivAbsoluteBottom';
import DivRelative from './DivRelative';
import ImageDesktopLogo from './ImageDesktopLogo';
import ImageOnlyDesktop from './ImageOnlyDesktop';
import ImageOnlyMobile from './ImageOnlyMobile';

interface Images {
  src: string;
  alt: string;
}

interface Props {
  bottomImages: Images[];
  desktopBackgroundImage: string;
  mobileBackgroundImage: string;
  alt: string;
  bottomAlt: string;
  desktopBottomImage: string;
  mobileBottomImage: string;
}

const ImageListBottom = ({
  desktopBackgroundImage,
  mobileBackgroundImage,
  alt,
  bottomAlt,
  desktopBottomImage,
  mobileBottomImage,
}: Props) => {
  return (
    <DivRelative>
      <ImageOnlyDesktop src={desktopBackgroundImage} alt={alt} />
      <ImageOnlyMobile src={mobileBackgroundImage} alt={alt} />
      <DivAbsoluteBottom>
        {desktopBottomImage && <ImageDesktopLogo src={desktopBottomImage} alt={bottomAlt} />}
        {mobileBottomImage && <ImageOnlyMobile src={mobileBottomImage} alt={bottomAlt} />}
      </DivAbsoluteBottom>
    </DivRelative>
  );
};

export default ImageListBottom;
