import generateWithAsyncActions from 'epic-alt-utils';

import alt from '../alt';

/**
 * LoginActions
 */
export default generateWithAsyncActions(
  alt,
  ['getAccountInfo', 'requestPreRegister', 'setRegistered'],
  [
    'handleLogout',
    'loginListener',
    'requestLogin',
    'requestLogout',
    'requestRegister',
    'requestLoginModal',
    'requestDownloadModal',
    'errorLoginModal',
    'closeLoginModal',
  ]
);
