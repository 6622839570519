import {generateRouteTo} from 'epic-fortnite-react';
import PropTypes from 'prop-types';
import React from 'react';
import {Link, withRouter} from 'react-router-dom';

class GuideTip extends React.Component {
  static propTypes = {
    slug: PropTypes.string,
    tabs: PropTypes.array,
    path: PropTypes.string,
    activeTab: PropTypes.number,
    locale: PropTypes.string,
  };

  render() {
    const {tabs, path, activeTab, slug, location, locale} = this.props;
    return (
      <div className="sub-tab">
        {tabs &&
          tabs.map((tab, index) => {
            const key = `${path}-${index}`;
            return (
              <Link
                key={key}
                to={generateRouteTo(`/play-guide/${path}/tab-${index}`, location, locale)}
                className={path === slug && activeTab === index ? 'title active' : 'title'}>
                <span>{tab}</span>
              </Link>
            );
          })}
      </div>
    );
  }
}
export default withRouter(GuideTip);
