export const eulaTitleTagsToHtml = title => {
    const replacements: any = [];

    replacements.push(
        {
            pattern: /\n/gi,
            replacement: ''
        },
        {
            pattern: /<EULA\.HighlightItalic>([^<]*)<\/>/gi,
            replacement: '$1'
        },
        {
            pattern: /<EULA\.Header>([^<]*)<\/>/gi,
            replacement: '$1'
        },
        {
            pattern: /<EULA\.SubHeader>([^<]*)<\/>/gi,
            replacement: '$1'
        },
        {
            pattern: /<EULA\.Highlight>([^<]*)<\/>/gi,
            replacement: '$1'
        }
    );

    let revisedTitle = title;
    replacements.forEach(obj => {
        revisedTitle = revisedTitle.replace(obj.pattern, obj.replacement);
    });

    return revisedTitle;
};

export const eulaContentTagsToHtml = content => {
    const replacements: any = [];

    replacements.push(
        {
            pattern: /\n/gi,
            replacement: '<br />'
        },
        {
            pattern: /<a ([^<]*)<\/>/gi,
            replacement: '<a $1</a>'
        },
        {
            pattern: /<EULA\.HighlightItalic>([^<]*)<\/>/gi,
            replacement: '<strong><em class="highlight-italic">$1</em></strong>'
        },
        {
            pattern: /<EULA\.Header>([^<]*)<\/>/gi,
            replacement: '<strong class="header">$1</strong>'
        },
        {
            pattern: /<EULA\.SubHeader>([^<]*)<\/>/gi,
            replacement: '<strong class="subheader"><em>$1</em></strong>'
        },
        {
            pattern: /<EULA\.Highlight>([^<]*)<\/>/gi,
            replacement: '<strong class="highlight"><em>$1</em></strong>'
        }
    );

    let revisedContent = content;
    replacements.forEach(obj => {
        revisedContent = revisedContent.replace(obj.pattern, obj.replacement);
    });

    return revisedContent;
};

export const formatEula = eulaResponse => {
    let convertedEula = eulaResponse;
    if (eulaResponse) {
        convertedEula = Object.assign({}, eulaResponse);
        if (convertedEula.title) {
            convertedEula.title = eulaTitleTagsToHtml(convertedEula.title);
        }
        if (convertedEula.body) {
            convertedEula.body = eulaContentTagsToHtml(convertedEula.body);
        }
        if (convertedEula.description) {
            convertedEula.description = eulaTitleTagsToHtml(convertedEula.description);
        }
    }

    return convertedEula;
};
