import styled from 'styled-components';

import {Device} from '../../constants';

interface Props {
  textColor?: string;
}

const StyledSubscriptionContentContainer = styled.div<Props>`
  color: ${({textColor}) => textColor || 'inherit'};

  img {
    width: 70%;
  }
  h2 {
    font-size: 1.5em;
  }
  p {
    font-size: 1em;
    margin: 0 auto;
    width: 50%;
  }

  @media screen and ${Device.tablet} {
    &&& p {
      font-size: 0.8em;
      width: 100%;
    }
  }

  @media screen and ${Device.mobileL} {
    width: 100%;
    img {
      width: 100%;
    }
    h2 {
      font-size: 1.2em;
    }
    &&& p {
      font-size: 0.8em;
      width: 100%;
    }
  }
`;

export default StyledSubscriptionContentContainer;
