import styled from 'styled-components';

import {breakpoints} from '../../../constants/Breakpoints';

const StepListItem = styled.li`
  position: relative;
  list-style: none;

  @media ${breakpoints.mobileL} {
    margin-bottom: 2rem;
  }
`;

export default StepListItem;
