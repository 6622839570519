import {withLocalization} from '@epic-core/localization';
import {getDataAttribute} from '@epic-core/common';
import classNames from 'classnames';
import {generateRouteTo} from 'epic-fortnite-react';
import map from 'lodash.map';
import PropTypes from 'prop-types';
import React from 'react';
import {withRouter} from 'react-router';

import BlogActions from '../../actions/BlogActions';
import {Message} from '../../components';

/**
 * @implements {Message}
 * @reactProps {object} router 'news/'
 * @reactProps {bool} disableCategoryCount
 * @reactProps {object} categorySummary
 * @reactProps {string} currentCategory
 * @reactProps {string} routerRootUrl
 * @reactProps {string} rootPageSlug
 */
class CategoryFilter extends React.Component {
    static defaultProps = {
        routerRootUrl: 'news'
    };

    static propTypes = {
        history: PropTypes.object,
        disableCategoryCount: PropTypes.bool,
        categorySummary: PropTypes.object,
        currentCategory: PropTypes.string,
        routerRootUrl: PropTypes.string,
        rootPageSlug: PropTypes.string,
        categoryOverlay: PropTypes.bool,
        getMessage: PropTypes.func
    };

    state = {
        toggleFilter: false
    };

    toggle = () => {
        this.setState({
            toggleFilter: !this.state.toggleFilter
        });
    };

    onClick = (e) => {
        const {location, locale} = this.props;
        const routerRootUrl = this.props.routerRootUrl;
        const key = getDataAttribute(e.currentTarget, 'key');
        this.toggle();

        if (key === 'all') {
            BlogActions.getBlogData.defer({
                category: key,
                isCategoryFilter: true,
                rootPageSlug: this.props.rootPageSlug
            });
            this.props.history.push(generateRouteTo(`/${routerRootUrl}`, location, locale));
        } else {
            this.props.history.push(
                generateRouteTo(`/${routerRootUrl}/category/${key}`, location, locale)
            );
        }
    };

    /**
     * Convert object to array in alphabetical order
     * @param  {object} summary {tip: 28, all: 128}
     * @return {array}         [{key: 'all', value: 128},{key: 'tips', value: 28}]
     */
    sortSummary(summary) {
        return map(summary, (value, key) => {
            return {key, value};
        }).sort((a, b) => {
            if (a.key === 'all') return -1;
            if (b.key === 'all') return 1;
            if (a.key > b.key) return 1;
            if (a.key < b.key) return -1;
            return 0;
        });
    }
    render() {
        const {getMessage} = this.props;
        const summary = this.props.categorySummary || {};
        const summarySorted = this.sortSummary(summary);
        const categories = [];

        map(summarySorted, (object) => {
            const messageKey = `epic.category.${object.key.replaceAll('-', '_')}`;
            const categoryName = <Message code={messageKey} />;
            const categoryClass = classNames({active: this.props.currentCategory === object.key});
            const categoryCount = this.props.disableCategoryCount ? (
                ''
            ) : (
                <span className="category-count">{`${object.value}`}</span>
            );
            //TON-32323
            let categoryStr = categoryName;
            if (categoryName && categoryName.length === 2) {
                categoryStr = categoryName.toUpperCase();
            }
            const category = (
                <li
                    id={`news-cat-${object.key}`}
                    role="presentation"
                    data-key={object.key}
                    onClick={this.onClick}
                    key={`category-${object.key}-${object.value}`}
                    className={categoryClass}>
                    <span>{categoryStr}</span>
                    {categoryCount}
                </li>
            );
            categories.push(category);
        });

        const filterClass = classNames('category-filter', {
            open: this.state.toggleFilter,
            overlayCat: this.props.categoryOverlay,
            listCat: !this.props.categoryOverlay
        });
        const categoriesLabel = getMessage('epic.blog.activity.categories') || '';

        return (
            <div className={filterClass}>
                <div className="filter-container">
                    <div
                        role="presentation"
                        title={categoriesLabel}
                        className="toggleButton"
                        onClick={this.toggle}>
                        {this.props.currentCategory !== 'all' ? (
                            <span className="count">
                                <span>!</span>
                            </span>
                        ) : null}
                        <i className="epic-blog-icon-filter" />
                    </div>
                    <div className="filter-list">
                        <div role="presentation" onClick={this.toggle} className="bkgrd" />
                        <div className="filter-content">
                            <div role="presentation" className="closeButton" onClick={this.toggle}>
                                <i className="epic-blog-icon-close" />
                            </div>
                            <h3>
                                <Message code="epic.blog.activity.categories" />
                            </h3>
                            <ul>{categories}</ul>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withLocalization(withRouter(CategoryFilter));
