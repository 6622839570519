import React from 'react';
import PropTypes from 'prop-types';
import Message from './Message';
import styled from 'styled-components';

const Jumbo = styled.div`
    padding: 1.25em 0;
`;


class PageJumbotron extends React.PureComponent {
    static propTypes = {
        sectionName: PropTypes.string,
        sectionNameCode: PropTypes.string
    };

    render() {
        const sectionName = this.props.sectionName || '';
        const sectionNameCode = this.props.sectionNameCode || '';
        return (
            <Jumbo>
                <div className="section-header-imagebg" />
                <div className="name-container">
                    <h1>
                        {sectionName || <Message code={sectionNameCode} />}
                    </h1>
                </div>
            </Jumbo>
        );
    }
}

export default PageJumbotron;
